import { Field, Input } from "@fluentui/react-components";

type Props = {
  label: string;
  required?: boolean;
  value: string;
  onChange: (value: string) => void;
};

export const BasicInput = (props: Props) => {
  const fieldProps = {
    required: props.required,
    label: props.label,
  };
  return (
    <Field {...fieldProps}>
      <Input
        style={{ width: "100%" }}
        size="medium"
        {...props}
        onChange={(_, data) => props.onChange.bind(null, data.value)()}
      />
    </Field>
  );
};
