import { unknown } from "sydneyeval-shared";
import { getAPIAccessToken } from "../../../../helpers/accessTokenHelper";
import { createRequestForHCS } from "../../../../helpers/createRequest";
import { TaskType } from "../models/TaskType";
import type {
  ICompleteConversationResponse,
  IFeedback,
  IFeedbackResponse,
  LLMJudgeEvaluationTaskData,
  LLMJudgeEvaluationTaskJudges,
  LLMJudgeEvaluationTaskStatus,
} from "./models/LLMJudgeEvaluationTaskData";

const baseUrl = "https://sydneyeval.azurewebsites.net";

function fetchBearerToken(): Promise<string | undefined> {
  return getAPIAccessToken();
}

async function getBasicHeaders() {
  const token = await fetchBearerToken();
  return {
    Authorization: "Bearer " + token,
  };
}

/**
 * get task info
 * @param taskId if undefined, get info of all tasks
 */
export async function GetTaskHistory(
  taskId: string,
): Promise<LLMJudgeEvaluationTaskData[]> {
  const url = `/EvaluationTask?task_id=${taskId}`;

  const ret = (await createRequestForHCS({
    api: url,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as LLMJudgeEvaluationTaskData[];
  return ret;
}

export async function GetTaskHistoryByFilterAndPagination(
  page: number,
  pageSize: number,
  taskName?: string,
  taskStatus?: string,
  taskType?: string,
  createdUserUpn?: string,
  debuggingMode?: boolean,
): Promise<LLMJudgeEvaluationTaskData[]> {
  let url = `/EvaluationTask/pagination`;

  url += `?page=${page}&page_size=${pageSize}`;
  if (taskName) url += `&name=${taskName}`;
  if (taskStatus) url += `&task_status=${taskStatus}`;
  if (debuggingMode) url += `&debug=${debuggingMode.toString()}`;
  if (taskType) url += `&task_type=${taskType}`;
  if (createdUserUpn) url += `&created_user_upn=${createdUserUpn}`;

  const ret = (await createRequestForHCS({
    api: url,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as LLMJudgeEvaluationTaskData[];
  return ret;
}

export async function SaveCreateTaskInfo(
  file: File,
  description: string,
  taskName: string,
  desiredCompletionDate: string,
  type: string,
  targetLabelCount: string,
  labelCountPerConversation: string,
  guideline: string,
  adminList: string,
  allowAnyoneToLabel: boolean,
  labelContributorList: string,
  urlParameters?: string,
  taskType: TaskType = TaskType.Distribution,
) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("description", description);
  formData.append("task_name", taskName);
  formData.append("type", type);

  if (taskType === TaskType.Distribution) {
    formData.append("desired_completion_date", desiredCompletionDate);

    formData.append("target_labelled_conversation_count", targetLabelCount);
    formData.append(
      "target_label_count_per_conversation",
      labelCountPerConversation,
    );
  }

  formData.append("task_guideline", guideline);
  formData.append("administrators", adminList);
  formData.append("allow_anyone_to_label", allowAnyoneToLabel.toString());
  if (!allowAnyoneToLabel)
    formData.append("label_contributors", labelContributorList);
  if (urlParameters) formData.append("url_parameters", urlParameters);

  const url =
    taskType === "Distribution" ? "/EvaluationTask" : "/EvaluationTask/Shared";
  const ret = (await createRequestForHCS({
    api: url,
    requestData: formData,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as LLMJudgeEvaluationTaskData | string;
  return ret;
}

export async function DeleteEvaluationTaskById(
  taskId: string,
): Promise<undefined> {
  const url = `/EvaluationTask?task_id=${taskId}`;

  const ret = (await createRequestForHCS({
    api: url,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
    method: "DELETE",
  })) as undefined;
  return ret;
}

export async function GetTaskStatistics(taskId: string, userUpn?: string) {
  let url = `/EvaluationTask/Annotation/Statistic?task_id=${taskId}`;
  if (userUpn) url = url + `&upn=${userUpn}`;

  const ret = (await createRequestForHCS({
    api: url,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as LLMJudgeEvaluationTaskStatus;
  return ret;
}

/**
 * get the list of judges being assigned in a task
 */
export async function GetTaskJudgesList(taskId: string) {
  const url = `/EvaluationTask/Detail/${taskId}`;

  const ret = (await createRequestForHCS({
    api: url,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as LLMJudgeEvaluationTaskJudges[];
  return {
    status: "success",
    response: ret,
  };
}

export async function GetAnnotationRawData(taskId: string) {
  const url = `/EvaluationTask/AnnotationRawData?task_id=${taskId}`;

  const ret = (await createRequestForHCS({
    api: url,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as LLMJudgeEvaluationTaskData[];

  return ret[0].annotation_raw_data;
}

export async function UpdateEvaluationTask(
  taskId: string,
  description?: string,
  desiredCompletionDate?: string,
  targetLabelledConversationCount?: string,
  administrators?: string,
  allowAnyoneToLabel?: string,
  labelContributors?: string,
) {
  const url = `/EvaluationTask?task_id=${taskId}`;

  const formData = new FormData();
  if (description !== undefined)
    formData.append("description", description === "" ? " " : description);
  if (desiredCompletionDate)
    formData.append("desired_completion_date", desiredCompletionDate);
  if (targetLabelledConversationCount)
    formData.append(
      "target_labelled_conversation_count",
      targetLabelledConversationCount,
    );
  if (administrators !== undefined) {
    if (administrators === "") {
      formData.append("administrators", " "); // hack by sending a space to avoid failure to update
    } else {
      formData.append("administrators", administrators);
    }
  }
  if (allowAnyoneToLabel)
    formData.append("allow_anyone_to_label", allowAnyoneToLabel);
  if (labelContributors !== undefined) {
    if (labelContributors === "") {
      formData.append("label_contributors", " ");
    } else {
      formData.append("label_contributors", labelContributors);
    }
  }

  const ret = (await createRequestForHCS({
    api: url,
    requestData: formData,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as LLMJudgeEvaluationTaskData;
  return ret;
}

/**
 * get all or part of feedback by specific params
 */
export async function GetFeedbackEntity(taskId: string, upn?: string) {
  const url = `/Annotation/FeedbackEntity`;
  const body = {
    task_id: taskId,
    upn: upn,
  };

  const ret = (await createRequestForHCS({
    api: url,
    requestData: body,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as IFeedbackResponse[];
  return ret;
}

// get only completed and in progress conversation feedback
export async function GetValidFeedback(taskId: string) {
  const url = `/Annotation/ValidFeedback/${taskId}`;

  const ret = (await createRequestForHCS({
    api: url,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as IFeedbackResponse[];
  return ret;
}

// get all/only completed conversation feedback of a task
export async function GetFeedback(taskId: string, needComplete?: boolean) {
  let url = `/Annotation/Feedback?task_id=${taskId}`;
  if (needComplete) {
    url += `&need_complete=${needComplete}`;
  }

  const ret = (await createRequestForHCS({
    api: url,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as IFeedbackResponse[];
  return ret;
}

export async function GetFeedbackForSharedTask(
  taskId: string,
  allowScraping?: boolean,
  upn?: string,
) {
  const url = `/Annotation/Feedback/Shared${allowScraping ? "/Scraping" : ""}`;

  const body: Record<string, string> = {
    task_id: taskId,
  };
  if (allowScraping && upn) {
    body["upn"] = upn;
  }

  const ret = (await createRequestForHCS({
    api: url,
    requestData: body,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as IFeedbackResponse[];
  return ret;
}

export async function SaveFeedback(feedbacks?: IFeedback[]) {
  const url = `/Annotation/Feedback`;
  if (!feedbacks || feedbacks.length === 0) return Promise.resolve([]);

  const body = {
    Feedback: feedbacks,
  };

  const ret = (await createRequestForHCS({
    api: url,
    requestData: body,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as IFeedbackResponse[];
  return ret;
}

export async function GetNextConversation(taskId: string) {
  const url = `/EvaluationTask/Annotation/Conversation/Next?task_id=${taskId}`;

  const ret = (await createRequestForHCS({
    api: url,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as ICompleteConversationResponse;
  return ret;
}

export async function CompleteConversation(
  conversationId: string,
  needNext?: boolean,
  feedbackId?: string,
  feedback?: IFeedback,
) {
  let url = `/EvaluationTask/Annotation/Conversation/complete?id=${conversationId}&need_next=${
    needNext ?? "false"
  }`;
  if (feedbackId) url += `&feedback_id=${feedbackId}`;

  const body = {
    Feedback: [feedback],
  };

  const ret = (await createRequestForHCS({
    api: url,
    requestData: body,
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as ICompleteConversationResponse;
  return ret;
}

export async function CancelConversation(
  conversationId: string,
  needNext?: boolean,
) {
  const url = `/EvaluationTask/Annotation/Conversation/cancel?id=${conversationId}&need_next=${
    needNext ?? "false"
  }`;

  const ret = (await createRequestForHCS({
    api: url,
    requestData: {},
    typeGuard: unknown,
    headers: await getBasicHeaders(),
    host: baseUrl,
  })) as ICompleteConversationResponse;
  return ret;
}
