import { unknown } from "sydneyeval-shared";
import { createRequestForHCS } from "../../../../helpers/createRequest";
import { uuidv4 } from "../utils/Guid";
import type { ISydneyResponse } from "./SydneyResponse";
import { getAvalonResponse } from "../../../../helpers/apiHelper";

const baseUrl = "https://sydneyeval.azurewebsites.net";
const baseSydneyUrl = "https://substrate.office.com/m365Copilot";

export const DEFAULT_VARIANTS =
  "feature.responseTokenLimit700,feature.PreserveOriginalReferences,3s.DisableRMS,3s.DisableCAP,feature.EnableDiag,feature.Full3SResponse";

export function getResponse(
  headers: Record<string, string>,
  body: object,
): Promise<ISydneyResponse> {
  return (async () => {
    try {
      const avalonResponse = await getAvalonResponse({
        AvalonRequestHeader: headers,
        AvalonRequestBody: body,
      });
      return avalonResponse as ISydneyResponse;
    } finally {
      // Handle any cleanup if necessary
    }
  })();
}

export async function ScrapeConversation(
  query: string,
  variants: string,
  token: string,
  config?: string,
) {
  const url = getProxyUrl(
    `${baseSydneyUrl}/TuringBot?debug=true&fulldebug3s=true&TrafficType=ClientSideExp`,
  );

  const { body, headers } = createRequestBody(query, token, variants, config);

  if (token.length === 0) {
    // it seems that getAvalonAccessToken has a race condition problem. So need to deal with it here.
    return getResponse(headers, body);
  }
  const ret = await createRequestForHCS({
    api: url,
    requestData: body,
    typeGuard: unknown,
    headers,
    host: baseUrl,
  });
  return ret as ISydneyResponse;
}

export function getProxyUrl(url: string) {
  return `/Proxy?url=${encodeURIComponent(url)}`;
}

export const DEFAULT_MCHAT_PLUGINS = [
  {
    Id: "BingWebSearch",
    source: "BuiltIn",
  },
];

export const generateMChatAvalonRequest = (
  utterance: string,
  queryAnnotations: object[] = [],
  sssVariants: string[] = [],
  plugins?: typeof DEFAULT_MCHAT_PLUGINS,
  option_sets?: string[],
) => {
  const requestId = uuidv4();

  // Create a copy and never update const, or SBS portal can be broken
  const requestPlugins = (plugins ?? DEFAULT_MCHAT_PLUGINS).map(
    function (item) {
      return item;
    },
  ) as Record<string, string | object>[];

  // The EnterpriseSearch must be in the plugins list for Avalon requests
  const hasEnterpriseSearch = requestPlugins.some(
    (p) => p.Id === "EnterpriseSearch",
  );
  if (!hasEnterpriseSearch) {
    requestPlugins.push({
      Id: "EnterpriseSearch",
      Version: "1.0",
      Source: "1PBuiltIn",
      Data: {
        SerializedOptions: JSON.stringify({ SubstrateFlights: sssVariants }),
      },
    });
  }
  const additionalOptions = option_sets ?? [
    // "enterprise_with_diagnostics",
    "enterprise_flux_work",
    "enterprise_flux_web",
  ];
  const finalOptions = [...new Set(additionalOptions)];
  const request = {
    allowedMessageTypes: [
      "Chat",
      "Suggestion",
      "InternalLoaderMessage",
      "ConfirmationCard",
      "InternalSearchQuery",
      "InternalSearchResult",
      "SemanticSerp",
      "Progress",
    ],
    isStartOfSession: true,
    message: {
      text: utterance,
      author: "user",
      createdAt: new Date().toISOString().replace("Z", "+00:00"),
      timestamp: new Date().toISOString().replace("Z", "+00:00"),
      locale: "en-US",
      locationInfo: {
        timeZoneOffset: new Date().getTimezoneOffset() / -60,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      experienceType: "Default",
      requestId: requestId,
      messageType: "Chat",
      offense: "Unknown",
      feedback: {
        type: "None",
      },
      inputMethod: "Keyboard",
      queryAnnotations: queryAnnotations,
    },
    plugins: requestPlugins,
    ignoreCache: true,
    conversationAction: "Upsert",
    traceId: requestId,
    source: "m365chat",
    conversationId: uuidv4(),
    verbosity: "verbose",
    optionsSets: finalOptions,
    privacy: "General",
  };
  return request;
};

export const createRequestBody = (
  utterance: string,
  token: string,
  customVariants?: string | null,
  config?: string | null,
  queryAnnotations: object[] = [],
  plugins?: typeof DEFAULT_MCHAT_PLUGINS,
) => {
  let variants: string;
  if (!customVariants) {
    variants = DEFAULT_VARIANTS;
  } else {
    variants = customVariants + "," + DEFAULT_VARIANTS;
  }

  const sssVariants = variants
    .split(",")
    .filter(function (v) {
      return v.match(/^3s\./i);
    })
    .map(function (v) {
      return v.replace(/^3s\./i, "");
    });
  const sydneyVariants = variants.split(",");

  let option_sets: string[] | undefined = undefined;
  if (config) {
    try {
      option_sets = (
        JSON.parse(config) as Record<string, string>
      ).option_sets.split(",");
    } catch (e) {
      option_sets = undefined;
    }
  }
  const body = generateMChatAvalonRequest(
    utterance,
    queryAnnotations,
    [...new Set(sssVariants)],
    plugins,
    option_sets,
  );

  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    "X-ModelProvider": "PolymerLLM",
    "X-Variants": [...new Set(sydneyVariants)].join(","),
  };

  if (token.length !== 0) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return {
    body,
    headers,
  };
};
