import { Link } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import type { FallbackProps } from "react-error-boundary";
import { ErrorBoundary } from "react-error-boundary";
import { telemetryHelper } from "../../helpers/telemetryHelper";
import type { WrapperProps } from "./WrapperProps";

export const renderErrorDetails = (error: unknown) => {
  if (error instanceof Error) {
    return (
      <div>
        <h2>{error.name}</h2>
        <h3>{error.message}</h3>
        <div>{error.stack}</div>
      </div>
    );
  }
  return <div>{JSON.stringify(error)}</div>;
};

export const ErrorBoundaryProvider = observer((props: WrapperProps) => {
  const customizedFallbackRender = (faillbackProps: FallbackProps) => {
    const error: unknown = faillbackProps.error;

    telemetryHelper.logErrorEvent("MinifiedReacterror", {
      URL: window.location.pathname,
      error: error,
    });

    return (
      <div>
        <h1>Something went wrong:</h1>
        {renderErrorDetails(error)}
        <br />
        <Link
          onClick={() => {
            window.open("https://aka.ms/sevalbug", "_blank");
            telemetryHelper.logUserActionEvent("ClickProvideFeedback");
          }}
        >
          Submit a bug
        </Link>
      </div>
    );
  };

  return (
    <ErrorBoundary fallbackRender={customizedFallbackRender}>
      {props.children}
    </ErrorBoundary>
  );
});
