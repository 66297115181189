export const GroundLabelOptions = [
  { value: "Grounded", label: "Grounded (accurate)" },
  { value: "Borderline", label: "Borderline logical leap" },
  { value: "Ungrounded", label: "Ungrounded (hallucination)" },
  {
    value: "NotRequireSource",
    label: "Doesn't require source (common sense, opinion, or creative)",
  },
  { value: "DontKnow", label: "Not sure" },
];

export const ClaimImportanceOptions = [
  {
    value: "Critical",
    label:
      "Critical: directly addresses part of the query; without it, the response would be severely incomplete or lacking",
  },
  {
    value: "SomewhatImportant",
    label:
      "Somewhat important: can be defined as 'quite good to know' in context of the query - relevant and contributes to the completeness of the response",
  },
  {
    value: "Minor",
    label:
      "Minor: leaving this claim out would not significantly compromise the response",
  },
];
