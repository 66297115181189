import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Link,
  Toast,
  ToastBody,
  Toaster,
  ToastFooter,
  ToastTitle,
  ToastTrigger,
  useId,
  useToastController,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import {
  SharedToasterId,
  SharedToasterPrefix,
} from "../../../../../components/Wrappers/ResponsiveProvider";
import { contributionStore } from "../../../../../store/contributionStore";
import { updateIsDispatchedErrorToast } from "../../../mutators/jobCreationMutators";
import { getJobCreationErrorMessages } from "../../../selectors/creationError/getJobCreationError";
import { jobCreationStore } from "../../../store/jobCreationStore";

export const JobErrorToast = observer(() => {
  const toasterId = useId(SharedToasterPrefix, SharedToasterId);
  const toastId = useId("JobErrorToast");
  const { dispatchToast, updateToast, dismissToast } =
    useToastController(toasterId);

  const errorMessage = getJobCreationErrorMessages.get();
  const maxLength = 40;

  window.onpopstate = () => {
    dismissToast(toastId);
  };

  const renderToast = () => (
    <Toast>
      <ToastTitle data-testid="Mock-ToastTitle">Current Errors</ToastTitle>
      <ToastBody style={{ gridColumn: "1/3" }}>
        <Accordion collapsible={true}>
          {errorMessage &&
            errorMessage?.length > 0 &&
            errorMessage?.map((message, index) => (
              <AccordionItem
                style={{ wordBreak: "break-all" }}
                key={index}
                value={index}
              >
                <AccordionHeader>
                  {message.substring(0, maxLength)}
                  {message.length > maxLength && "..."}
                </AccordionHeader>
                {message.length > maxLength && (
                  <AccordionPanel>
                    <div style={{ maxHeight: "200px", overflow: "scroll" }}>
                      {message}
                    </div>
                  </AccordionPanel>
                )}
              </AccordionItem>
            ))}
        </Accordion>
      </ToastBody>
      <ToastFooter>
        <ToastTrigger>
          <Link
            onClick={() => {
              updateIsDispatchedErrorToast(false);
              clickDismiss();
            }}
          >
            Dismiss
          </Link>
        </ToastTrigger>
      </ToastFooter>
    </Toast>
  );

  const clickDismiss = () => dismissToast(toastId);
  const notify = () => {
    if (jobCreationStore.isDispatchedErrorToast) {
      updateToast({ toastId, content: renderToast() });
      return;
    }
    dispatchToast(renderToast(), {
      intent: "warning",
      timeout: -1,
      toastId,
    });
  };

  const location = contributionStore.currentPath;
  useEffect(() => {
    if (location !== "/create") {
      dismissToast(toastId);
    }
  }, [location]);

  useEffect(() => {
    if (errorMessage && errorMessage.length > 0) {
      notify();
      updateIsDispatchedErrorToast(true);
    } else {
      dismissToast(toastId);
      updateIsDispatchedErrorToast(false);
    }
  }, [errorMessage.join(",")]);

  return <Toaster toasterId={toasterId} />;
});
