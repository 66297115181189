import {
  Spinner,
  Switch,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  block: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
    justifyContent: "start",
  },
  blockTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  spinner: {
    maxWidth: "200px",
  },
});

type Props = {
  isSBSEnabled: boolean | undefined;
  onChange: (value: boolean) => void;
  disabled: boolean;
  isLoading?: boolean | null | undefined;
};

export const JobSBSExpView = (props: Props) => {
  const styles = useStyles();
  if (props.isSBSEnabled === undefined) {
    return <></>;
  }

  return (
    <div className={styles.block}>
      <div className={styles.blockTitle}>SBS Experiment</div>
      {props.isLoading && (
        <Spinner
          label={"Checking compliance..."}
          className={styles.spinner}
          size="tiny"
        />
      )}
      {!props.isLoading && (
        <div>
          <Switch
            disabled={props.disabled}
            checked={props.isSBSEnabled}
            label={
              props.disabled
                ? "You cannot create SBS experiment by compliance"
                : "Turn on Create SBS Experiment"
            }
            onChange={(ev) => {
              props.onChange(ev.target.checked);
            }}
          />
        </div>
      )}
    </div>
  );
};
