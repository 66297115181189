import {
  Badge,
  Body1,
  Button,
  Caption1,
  Card,
  CardHeader,
  Checkbox,
  DialogTitle,
  Field,
  makeStyles,
  Spinner,
  Switch,
  Tab,
  TabList,
  Text,
  Textarea,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { SettingFileValues } from "sydneyeval-shared";
import {
  selectAppConfigurationFile,
  selectAppConfigurationSnapshot,
  updateAppConfigurationContent,
} from "../../actions/appConfigurationActions";
import type { AppConfigurationSnapshot } from "../../models/AppConfigurationSnapshot";
import { updateAppConfiguration } from "../../mutators/updateAppConfigutation";
import {
  getConfigurationError,
  isCurrentConfig,
  isUpdateEnabled,
} from "../../selectors/appConfiguration";
import {
  appConfigurationStore,
  isSelectedSnapshot,
} from "../../store/appConfigurationStore";
import { SettingsDialog } from "./SettingsDialog";

type AppConfigurationDialogProps = {
  isOpen: boolean;
  onComplete: () => void;
};

const useStyle = makeStyles({
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
    height: "600px",
  },
  leftContent: {
    minWidth: "380px",
    width: "380px",
    flexGrow: 0,
    overflowY: "scroll",
  },
  rightContent: {
    flex: 1,
  },
  cardWrapper: {
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    flexWrap: "wrap",
  },
  editorWrapper: {
    padding: "10px",
    height: "100%",
  },
  card: {
    width: "400px",
    maxWidth: "100%",
    height: "fit-content",
  },
  caption: {
    color: tokens.colorNeutralForeground3,
  },
  textarea: {
    height: "100%",
    "> textarea": {
      maxHeight: "inherit",
    },
  },
});

const SnapshotCard = observer(
  (props: { snapshot: AppConfigurationSnapshot }) => {
    const styles = useStyle();

    const isSelected = isSelectedSnapshot(props.snapshot);

    return (
      <Card
        className={styles.card}
        selected={isSelected}
        onSelectionChange={() => {
          selectAppConfigurationSnapshot(props.snapshot);
        }}
        floatingAction={<Checkbox onChange={undefined} checked={isSelected} />}
      >
        <CardHeader
          image={undefined}
          header={<Text weight="semibold">{props.snapshot.name}</Text>}
          description={
            <Caption1 className={styles.caption}>
              {props.snapshot.properties.lastModified}
            </Caption1>
          }
        />
        {isCurrentConfig(props.snapshot) && (
          <Badge color="success" shape="rounded" appearance="tint">
            Current
          </Badge>
        )}
        {props.snapshot.blobTags?.blobTagSet.map((tag) => {
          const tagStr = `${tag.key}:${tag.value}`;
          return (
            <Badge
              key={tagStr}
              color={tagStr.includes("identifier:") ? "severe" : "brand"}
              shape="rounded"
              appearance="tint"
            >
              {tagStr}
            </Badge>
          );
        })}
      </Card>
    );
  },
);

const SnapshotView = observer(() => {
  const styles = useStyle();

  if (appConfigurationStore.snapshots === undefined) {
    return <Spinner />;
  }

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.leftContent}>
        <div className={styles.cardWrapper}>
          {appConfigurationStore.snapshots.map((snapshot) => {
            return (
              <SnapshotCard
                key={`${snapshot.name}_${snapshot.snapshot}`}
                snapshot={snapshot}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.rightContent}>
        <SnapshotDetails />
      </div>
    </div>
  );
});

const SnapshotDetails = observer(() => {
  const styles = useStyle();

  if (appConfigurationStore.selectedSnapshot === undefined) {
    return null;
  }

  if (appConfigurationStore.selectedSnapshotContent === undefined) {
    return <Spinner />;
  }

  const errorMessage = getConfigurationError.get();
  return (
    <Field
      style={{
        height: "100%",
        boxSizing: "border-box",
        gridTemplateRows: "1fr auto",
      }}
      className={styles.editorWrapper}
      validationState={errorMessage === undefined ? "none" : "error"}
      validationMessage={errorMessage}
    >
      <Card style={{ height: "100%", boxSizing: "border-box" }}>
        <Textarea
          className={styles.textarea}
          appearance="filled-lighter"
          aria-label="app configuration editor."
          value={appConfigurationStore.selectedSnapshotContent}
          onChange={(_, data) => {
            updateAppConfiguration({
              ...appConfigurationStore,
              selectedSnapshotContent: data.value,
            });
          }}
        />
      </Card>
    </Field>
  );
});

const AppConfigurationMenu = observer(() => {
  return (
    <TabList selectedValue={appConfigurationStore.selectedFileName} vertical>
      {SettingFileValues.map((_) => {
        return (
          <Tab
            key={_[0]}
            value={_[0]}
            onClick={() => {
              selectAppConfigurationFile(_[0]);
            }}
          >
            {_[0]}
          </Tab>
        );
      })}
    </TabList>
  );
});

const AppConfigurationContent = observer(() => {
  return (
    <>
      <DialogTitle>{"App Configuration Settings"}</DialogTitle>
      <Body1>{"This dialog is used to manage the App Configuration."}</Body1>
      <div>
        <Switch
          label="Show Edit History"
          checked={appConfigurationStore.showConfigEditHistory}
          onChange={(_, data) => {
            updateAppConfiguration({
              ...appConfigurationStore,
              showConfigEditHistory: data.checked,
            });
            selectAppConfigurationFile(
              appConfigurationStore.selectedFileName ?? "aml_eyeson_staging",
            );
          }}
        />
        <Switch
          label="Show Configuration With Identitier"
          checked={appConfigurationStore.showConfigWithIdentifiers}
          onChange={(_, data) => {
            updateAppConfiguration({
              ...appConfigurationStore,
              showConfigWithIdentifiers: data.checked,
            });
            selectAppConfigurationFile(
              appConfigurationStore.selectedFileName ?? "aml_eyeson_staging",
            );
          }}
        />
      </div>
      <SnapshotView />
    </>
  );
});

const AppConfigurationButtons = observer(() => {
  return (
    <>
      <Button
        disabled={!isUpdateEnabled.get()}
        appearance="secondary"
        onClick={() => {
          if (appConfigurationStore.selectedSnapshotContent !== undefined) {
            try {
              updateAppConfiguration({
                ...appConfigurationStore,
                selectedSnapshotContent: JSON.stringify(
                  JSON.parse(appConfigurationStore.selectedSnapshotContent),
                  null,
                  2,
                ),
              });
            } catch {
              // Do nothing
            }
          }
        }}
      >
        Format JSON
      </Button>
      <Button
        disabled={!isUpdateEnabled.get()}
        appearance="primary"
        onClick={() => {
          updateAppConfigurationContent();
        }}
      >
        Update App Configuration
      </Button>
    </>
  );
});

export const AppConfigurationDialog = observer(
  (props: AppConfigurationDialogProps) => {
    return (
      <SettingsDialog
        isOpen={props.isOpen}
        onComplete={props.onComplete}
        renderMenu={() => <AppConfigurationMenu />}
        renderContent={() => <AppConfigurationContent />}
        renderButtons={() => <AppConfigurationButtons />}
      />
    );
  },
);
