import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { switchMChatScenarioAction } from "../../../actions/resultActions";
import { showNoPermissionDialog } from "../../../selectors/jobDetailHelper";
import { resultStore } from "../../../store/resultStore";

export const NoPermissionDialog = observer(() => {
  const shouldBlockUser = showNoPermissionDialog.get();
  return (
    <Dialog open={shouldBlockUser}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Restricted Access</DialogTitle>
          <DialogContent>
            Please ask job creator
            {` ${resultStore.resultJob?.CreatedBy}(${resultStore.resultJob?.CreatorSmtpAddress}) `}
            to grant the access.
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              onClick={() => {
                switchMChatScenarioAction("Scorecard Comparison");
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
});
