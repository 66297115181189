import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Tab,
  TabList,
  makeStyles,
} from "@fluentui/react-components";
import React from "react";

type JobSettingsDialogProps = {
  isOpen: boolean;
  jobName: string;
  settings: string | undefined;
  dataSets: string | undefined;
  lastSelectedTab: TabValue;
  onClose: (selectedTab: TabValue) => void;
};

const useStyles = makeStyles({
  dialog: {
    maxWidth: "800px",
  },
  body: {
    whiteSpace: "pre",
  },
});

export type TabValue = "settings" | "datasets";

const getFormatted = (jsonString: string | undefined) => {
  if (!jsonString) {
    return "";
  }

  try {
    return JSON.stringify(JSON.parse(jsonString), null, 2);
  } catch {
    return jsonString;
  }
};

export const JobSettingsDialog = (props: JobSettingsDialogProps) => {
  const styles = useStyles();
  const { isOpen, jobName, lastSelectedTab, onClose } = props;
  const [selectedTab, setSelectedTab] =
    React.useState<TabValue>(lastSelectedTab);
  const descriptionElementRef = React.useRef<HTMLElement>(null);

  const formattedSettings = getFormatted(props.settings);
  const formattedDataSets = getFormatted(props.dataSets);
  React.useEffect(() => {
    if (isOpen) {
      setSelectedTab(lastSelectedTab);
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  const renderJson = (content: string) => {
    return content.split("\n").map((line, index) => {
      return (
        <Body1 key={index} tabIndex={0} className={styles.body}>
          {`${line}`}
          <br />
        </Body1>
      );
    });
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        onClose(selectedTab);
      }}
    >
      <DialogSurface className={styles.dialog}>
        <DialogBody>
          <DialogTitle>{jobName}</DialogTitle>
          <DialogContent>
            <TabList
              defaultSelectedValue={selectedTab}
              onTabSelect={(_, data) => {
                switch (data.value) {
                  case "settings":
                    setSelectedTab("settings");
                    break;
                  case "datasets":
                    setSelectedTab("datasets");
                    break;
                  default:
                    break;
                }
              }}
            >
              {props.settings !== undefined && (
                <Tab value="settings">Settings</Tab>
              )}
              {props.dataSets !== undefined && (
                <Tab value="datasets">DataSets</Tab>
              )}
            </TabList>
            {selectedTab === "settings" && renderJson(formattedSettings)}
            {selectedTab === "datasets" && renderJson(formattedDataSets)}
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                onClose(selectedTab);
              }}
              appearance="secondary"
            >
              Close
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
