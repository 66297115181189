import {
  Body1,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Option,
  Switch,
  makeStyles,
  mergeClasses,
} from "@fluentui/react-components";
import {
  ArrowLeft24Regular,
  ArrowReset20Regular,
  ArrowRight24Regular,
  CaretDown12Filled,
  CaretUp12Filled,
  ChevronLeftRegular,
  Filter24Regular,
  Search20Regular,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { Tip } from "../../../../../components/Shared/Tip";
import { openSidePane } from "../../../../../mutators/sidePaneMutators";
import { store } from "../../../../../store/store";
import type { PassFailRateByQuery } from "../../../helpers/lmchecklistMetricsHelper";
import { getPFValue } from "../../../helpers/lmchecklistMetricsHelper";
import type { Job } from "../../../models/Job";
import type { LMChecklistAssertionsResponse } from "../../../models/LMChecklistAssertionsResponse";
import type {
  LMChecklistDetails,
  LMChecklistDetailsResponse,
} from "../../../models/LMChecklistDetailsResponse";

import type { PassFailRate } from "../../../models/LMChecklistPassFailRates";
import type { Sort } from "../../../models/TableSort";
import {
  DevUIDialog,
  type IDevUIDialogKeyProps,
} from "../../Dialog/DevUIDialog";
import { AssertionDetails } from "../SidePane/AssertionDetails";
import { useSharedTableStyles } from "./shardTableStyles";

const useStyles = makeStyles({
  avgValueCell: {
    fontWeight: "600",
  },
  sorting: {
    display: "flex",
    flexDirection: "column",
  },
  caretUp: {
    marginBottom: "-12px",
  },
  caretDown: {
    marginBottom: "-4px",
  },
  criticalFailure: {
    backgroundColor: "rgba(221, 66, 66, 0.30)",
  },
  rowHover: {
    cursor: "pointer",
  },
});

type LMChecklistQueryTableProps = {
  job: Job;
  jobId: string;
  details: LMChecklistDetailsResponse;
  assertions: LMChecklistAssertionsResponse;
  pfRates: PassFailRateByQuery[];
};

type Filters = {
  segment: string[];
  query: string;
  incompleteDataOnly: boolean;
};

const ITEMS_PER_PAGE = 20;

export const LMChecklistQueryTable = observer(
  (props: LMChecklistQueryTableProps) => {
    const sharedStyles = useSharedTableStyles();
    const styles = useStyles();

    const valueCell = mergeClasses(
      sharedStyles.cell,
      sharedStyles.valueCell,
      sharedStyles.rightCell,
    );

    const hasAccess =
      props.job.Properties?.IsOwner === true ||
      props.job.Properties?.IsTestTenant === true ||
      props.job.CreatorSmtpAddress?.includes(store.account?.username ?? "");

    const [isDevUIDialogOpen, setIsDevUIDialogOpen] = useState<boolean>(false);
    const [devUIDialogProps, setDevUIDialogProps] = useState<
      IDevUIDialogKeyProps | undefined
    >(undefined);

    const metricsHeaderCell = mergeClasses(
      sharedStyles.cell,
      sharedStyles.centerCell,
    );

    const metricsSubHeaderCell = mergeClasses(
      sharedStyles.cell,
      sharedStyles.centerCell,
      sharedStyles.subTitleCell,
    );

    const avgValueCell = mergeClasses(styles.avgValueCell, valueCell);

    const rowStyle = mergeClasses(sharedStyles.rowStyle, styles.rowHover);

    const [queryData, setQueryData] = useState<LMChecklistDetailsResponse>(
      props.details,
    );
    const [pfRates, setPFRates] = useState<PassFailRateByQuery[]>(
      props.pfRates,
    );
    const [showScores, setShowScores] = useState<boolean>(false);
    const [sorting, setSorting] = useState<Sort>({
      field: undefined,
      ascending: true,
    });
    const [filters, setFilters] = useState<Filters>({
      segment: [],
      query: "",
      incompleteDataOnly: false,
    });

    const sortKeys = [
      "criticalControl",
      "criticalExperiment",
      "expectedControl",
      "expectedExperiment",
      "aspirationalControl",
      "aspirationalExperiment",
      "tControl",
      "tExperiment",
    ] as const;

    type SortKey = (typeof sortKeys)[number];

    const emptyFilters = () =>
      filters.segment.length === 0 &&
      filters.query === "" &&
      !filters.incompleteDataOnly;

    const filteredQueryData = useMemo(() => {
      if (emptyFilters()) {
        return props.details;
      }

      return props.details.filter((record) => {
        const segmentFilter =
          filters.segment.length === 0
            ? true
            : filters.segment.includes(record.segment);

        const queryFilter = record.query
          .toLowerCase()
          .includes(filters.query.toLowerCase());

        const incompleteDataFilter = () => {
          if (filters.incompleteDataOnly) {
            const pfRateRecord = pfRates.find(
              (pfRate) => pfRate.queryHash === record.queryHash,
            );

            if (pfRateRecord) {
              for (const key of sortKeys) {
                const pfRate = pfRateRecord[key];
                if (pfRate !== undefined && pfRate.missing > 0) {
                  return true;
                }
              }
            }
            return false;
          }
          return true;
        };

        return segmentFilter && queryFilter && incompleteDataFilter();
      });
    }, [filters, props.details]);

    const filteredPFRates = useMemo(() => {
      if (emptyFilters()) {
        return props.pfRates;
      }

      return props.pfRates.filter((record) => {
        const segmentFilter =
          filters.segment.length === 0
            ? true
            : filters.segment.includes(record.segment);

        const queryFilter = record.query
          .toLowerCase()
          .includes(filters.query.toLowerCase());

        const incompleteDataFilter = () => {
          if (filters.incompleteDataOnly) {
            for (const key of sortKeys) {
              const pfRate = record[key];
              if (pfRate !== undefined && pfRate.missing > 0) {
                return true;
              }
            }
            return false;
          }
          return true;
        };

        return segmentFilter && queryFilter && incompleteDataFilter();
      });
    }, [filters, props.pfRates]);

    const sortQueryData = () => {
      const sortedQueryData = [...filteredQueryData].sort(
        (a: LMChecklistDetails, b: LMChecklistDetails) => {
          let valA = a[sorting.field as keyof LMChecklistDetails];
          let valB = b[sorting.field as keyof LMChecklistDetails];

          if (valA === undefined) {
            return 1;
          } else if (valB === undefined) {
            return -1;
          }

          if (!isNaN(Number(valA)) && !isNaN(Number(valB))) {
            // Floats may not be sorted correctly with localeCompare()
            valA = parseFloat(valA.toString());
            valB = parseFloat(valB.toString());
            return (valA - valB) * (sorting.ascending ? 1 : -1);
          }

          return (
            valA
              .toString()
              .toLowerCase()
              .localeCompare(valB.toString().toLowerCase(), "en", {
                numeric: true,
              }) * (sorting.ascending ? 1 : -1)
          );
        },
      );
      setQueryData(sortedQueryData);
    };

    const sortPFRates = () => {
      const sortedPFRates = [...filteredPFRates].sort(
        (a: PassFailRateByQuery, b: PassFailRateByQuery) => {
          let valA = a[sorting.field as keyof PassFailRateByQuery];
          let valB = b[sorting.field as keyof PassFailRateByQuery];

          if (valA === undefined) {
            return 1;
          } else if (valB === undefined) {
            return -1;
          }

          if (sortKeys.includes(sorting.field as SortKey)) {
            valA = valA as PassFailRate;
            valB = valB as PassFailRate;

            const pfRateA = valA.passed / valA.total;
            const pfRateB = valB.passed / valB.total;

            return (pfRateA - pfRateB) * (sorting.ascending ? 1 : -1);
          }

          return (
            valA
              .toString()
              .toLowerCase()
              .localeCompare(valB.toString().toLowerCase(), "en", {
                numeric: true,
              }) * (sorting.ascending ? 1 : -1)
          );
        },
      );
      setPFRates(sortedPFRates);
    };

    useEffect(() => {
      setQueryData(filteredQueryData);
      setPFRates(filteredPFRates);
      if (sorting.field) {
        sortQueryData();
        sortPFRates();
      }
    }, [filteredQueryData, filteredPFRates, sorting]);

    const [page, setPage] = useState<number>(1);

    const [numPages, setNumPages] = useState<number>(
      Math.ceil(queryData.length / ITEMS_PER_PAGE),
    );

    const slicedData = useMemo(() => {
      const start = (page - 1) * ITEMS_PER_PAGE;
      const end = page * ITEMS_PER_PAGE;
      setNumPages(Math.ceil(queryData.length / ITEMS_PER_PAGE));
      return queryData.slice(start, end);
    }, [queryData, page]);

    const slicedPFRates = useMemo(() => {
      const start = (page - 1) * ITEMS_PER_PAGE;
      const end = page * ITEMS_PER_PAGE;
      setNumPages(Math.ceil(pfRates.length / ITEMS_PER_PAGE));
      return pfRates.slice(start, end);
    }, [pfRates, page]);

    const renderPagination = () => (
      <div className={sharedStyles.filtersGroup}>
        <Button
          onClick={() => {
            setPage(page - 1);
          }}
          disabled={page === 1}
          appearance="subtle"
          icon={<ArrowLeft24Regular />}
        />
        <span>{`${Math.min(page, numPages)} of ${numPages}`}</span>
        <Button
          onClick={() => {
            setPage(page + 1);
          }}
          disabled={page === numPages || numPages === 0}
          appearance="subtle"
          icon={<ArrowRight24Regular />}
        />
      </div>
    );

    const getScoreValue = (score: number | undefined) =>
      score !== undefined ? score.toFixed(2) : "";

    const getScoreAvgValue = (scoreAvg: number) =>
      scoreAvg >= 0 ? scoreAvg.toFixed(2) : "";

    const getPFToolTip = (pfRate: PassFailRate | undefined) => {
      if (pfRate !== undefined) {
        if (pfRate.missing > 0 && pfRate.missing >= pfRate.total) {
          return `${pfRate.missing} assertions missing from result.`;
        }
        return `${pfRate.passed} of ${
          pfRate.total - pfRate.missing
        } assertions passed. ${pfRate.missing} assertions missing from result.`;
      }

      return "No assertions.";
    };

    const getCriticalCell = (score?: number, pfRate?: PassFailRate) => {
      if (score !== undefined) {
        return score < 2
          ? mergeClasses(valueCell, styles.criticalFailure)
          : valueCell;
      } else if (pfRate !== undefined) {
        return pfRate.passed < pfRate.total
          ? mergeClasses(valueCell, styles.criticalFailure)
          : valueCell;
      }
    };

    const handleSegmentFilterChange = (segment: string) => {
      let currentSegments = [...filters.segment];
      if (filters.segment.includes(segment)) {
        currentSegments = currentSegments.filter((item) => item !== segment);
      } else {
        currentSegments.push(segment);
      }
      setFilters({
        ...filters,
        segment: currentSegments,
      });
    };

    const segments = () => {
      const segmentsSet = new Set<string>();
      props.details.forEach((record) => {
        segmentsSet.add(record.segment);
      });
      return Array.from(segmentsSet).sort((a, b) => a.localeCompare(b));
    };

    const segmentFilterValue = (segmentsList: string[]) =>
      segmentsList.map((segment) => segment.split(" - ")[0]).join(", ");

    const renderFilterBar = () => {
      return (
        <div className={sharedStyles.filterBar}>
          <div className={sharedStyles.filtersGroup}>
            <Filter24Regular className={sharedStyles.filterIcon} />
            <Dropdown
              className={sharedStyles.filterBarItem}
              multiselect={true}
              placeholder="Segment"
              data-testid="segment-dropdown"
              positioning={"below"}
              value={segmentFilterValue(filters.segment)}
              selectedOptions={filters.segment}
              onOptionSelect={(_, option) =>
                handleSegmentFilterChange(option.optionValue as string)
              }
            >
              {segments().map((segment) => (
                <Option key={segment} value={segment} data-testid={segment}>
                  {segment}
                </Option>
              ))}
            </Dropdown>
            <Input
              className={sharedStyles.filterBarItem}
              placeholder="Search queries"
              aria-label="Search queries"
              value={filters.query}
              contentBefore={<Search20Regular />}
              onChange={(_, data) =>
                setFilters({
                  ...filters,
                  query: data.value,
                })
              }
            />
            {!emptyFilters() && (
              <Button
                className={sharedStyles.reset}
                onClick={() => {
                  setFilters({
                    segment: [],
                    query: "",
                    incompleteDataOnly: false,
                  });
                }}
              >
                <ArrowReset20Regular className={sharedStyles.resetIcon} />
                <span>Reset</span>
              </Button>
            )}
          </div>
          <div className={sharedStyles.filtersGroup}>
            <Checkbox
              label="Incomplete data only"
              checked={filters.incompleteDataOnly}
              onChange={() => {
                setFilters({
                  ...filters,
                  incompleteDataOnly: !filters.incompleteDataOnly,
                });
              }}
            />
            <Switch
              checked={showScores}
              onChange={() => setShowScores(!showScores)}
              label="Scores"
              data-testid="scores-switch"
            />
            {renderPagination()}
          </div>
        </div>
      );
    };

    const getAvgScores = () => {
      const avgs = queryData.reduce(
        (acc, item) => {
          for (const key of sortKeys) {
            const score = item[key];
            if (score !== undefined) {
              acc[key].sum += score;
              acc[key].count += 1;
            }
          }

          return acc;
        },
        {
          criticalControl: { sum: 0, count: 0 },
          criticalExperiment: { sum: 0, count: 0 },
          expectedControl: { sum: 0, count: 0 },
          expectedExperiment: { sum: 0, count: 0 },
          aspirationalControl: { sum: 0, count: 0 },
          aspirationalExperiment: { sum: 0, count: 0 },
          tControl: { sum: 0, count: 0 },
          tExperiment: { sum: 0, count: 0 },
        },
      );

      return avgs;
    };

    const getTotalPFRates = () => {
      const totals = pfRates.reduce(
        (acc, item) => {
          for (const key of sortKeys) {
            const pfRate = item[key];
            if (pfRate) {
              acc[key].passed += pfRate.passed;
              acc[key].total += pfRate.total;
              acc[key].missing += pfRate.missing;
            }
          }

          return acc;
        },
        {
          criticalControl: { passed: 0, total: 0, missing: 0 },
          criticalExperiment: { passed: 0, total: 0, missing: 0 },
          expectedControl: { passed: 0, total: 0, missing: 0 },
          expectedExperiment: { passed: 0, total: 0, missing: 0 },
          aspirationalControl: { passed: 0, total: 0, missing: 0 },
          aspirationalExperiment: { passed: 0, total: 0, missing: 0 },
          tControl: { passed: 0, total: 0, missing: 0 },
          tExperiment: { passed: 0, total: 0, missing: 0 },
        },
      );

      return totals;
    };

    const sortIconVisibility = (sortedField: string, sortDirection: boolean) =>
      (sortedField === sorting.field && sortDirection) ||
      sortedField !== sorting.field
        ? "visible"
        : "hidden";

    const getSortingIcon = (sortedField: string) => (
      <div className={styles.sorting}>
        <div
          className={styles.caretUp}
          style={{
            visibility: sortIconVisibility(sortedField, sorting.ascending),
          }}
        >
          <CaretUp12Filled />
        </div>
        <div
          className={styles.caretDown}
          style={{
            visibility: sortIconVisibility(sortedField, !sorting.ascending),
          }}
        >
          <CaretDown12Filled />
        </div>
      </div>
    );

    const handleSortingChange = (sortedField: string) => {
      if (sortedField === sorting.field) {
        setSorting({ field: sortedField, ascending: !sorting.ascending });
      } else {
        setSorting({ field: sortedField, ascending: sorting.ascending });
      }
    };

    const renderTableHeader = () => (
      <thead className={sharedStyles.thead}>
        <tr className={sharedStyles.rowStyle} key="main-cols">
          <th
            className={metricsHeaderCell}
            rowSpan={2}
            onClick={() => handleSortingChange("query")}
          >
            <div className={sharedStyles.sortHeader}>
              Utterance
              {getSortingIcon("query")}
            </div>
          </th>
          <th
            className={metricsHeaderCell}
            rowSpan={2}
            onClick={() => handleSortingChange("segment")}
          >
            <div
              className={sharedStyles.sortHeader}
              data-testid="segment-header"
            >
              Segment
              {getSortingIcon("segment")}
            </div>
          </th>
          <th className={metricsHeaderCell} colSpan={2}>
            Critical
          </th>
          <th className={metricsHeaderCell} colSpan={2}>
            Expected
          </th>
          <th className={metricsHeaderCell} colSpan={2}>
            Aspirational
          </th>
          <th className={metricsHeaderCell} colSpan={2}>
            {showScores ? "Score" : "Overall Pass Rate"}
          </th>
          <th className={metricsHeaderCell} colSpan={2}>
            DevUI links
          </th>
        </tr>
        <tr className={sharedStyles.rowStyle} key="c-t-cols">
          {sortKeys.map((key) => (
            <th
              key={`${key}-header`}
              className={metricsSubHeaderCell}
              onClick={() => handleSortingChange(key)}
            >
              <div className={sharedStyles.sortHeader}>
                {key.includes("Control") ? "Control" : "Treatment"}
                {getSortingIcon(key)}
              </div>
            </th>
          ))}
          <th className={metricsSubHeaderCell}>Control</th>
          <th className={metricsSubHeaderCell}>Treatment</th>
        </tr>
      </thead>
    );

    const renderAssertionDetails = (
      record: LMChecklistDetails | PassFailRateByQuery,
    ) => {
      const assertions = props.assertions.filter(
        (a) => a.queryHash === record.queryHash,
      );
      return (
        <AssertionDetails
          JobId={props.jobId}
          query={record.query}
          queryHash={record.queryHash}
          assertions={assertions}
          sydneyReply={record.sydneyReply}
        />
      );
    };

    const renderScoreTableBody = () => (
      <tbody className={sharedStyles.tbody}>
        {slicedData.map((record) => {
          return (
            <tr
              key={`${record.queryHash}-scores`}
              className={rowStyle}
              onClick={() => openSidePane(() => renderAssertionDetails(record))}
            >
              <td className={sharedStyles.cell}>
                <div className={sharedStyles.longTextWrapper}>
                  <div
                    className={sharedStyles.expand}
                    onClick={(event) => {
                      openSidePane(() => renderAssertionDetails(record));
                      event?.stopPropagation();
                    }}
                  >
                    <ChevronLeftRegular />
                  </div>
                  <Tip withArrow content={record.query} relationship="label">
                    <Body1 className={sharedStyles.longText}>
                      {record.query}
                    </Body1>
                  </Tip>
                </div>
              </td>
              <td className={sharedStyles.cell}>
                <div className={sharedStyles.longTextWrapper}>
                  <Tip withArrow content={record.segment} relationship="label">
                    <Body1 className={sharedStyles.longText}>
                      {record.segment}
                    </Body1>
                  </Tip>
                </div>
              </td>
              {sortKeys.map((key) => {
                const score = record[key];
                return key.includes("critical") ? (
                  <td
                    className={getCriticalCell(score)}
                    key={`${record.queryHash}-${key}-scores`}
                  >
                    {getScoreValue(score)}
                  </td>
                ) : (
                  <td className={valueCell} key={`${record.queryHash}-${key}`}>
                    {getScoreValue(score)}
                  </td>
                );
              })}
              <td className={valueCell}>
                {hasAccess ? (
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsDevUIDialogOpen(true);
                      setDevUIDialogProps({
                        query: record.query,
                        queryHash: record.queryHash,
                        jobId: props.jobId,
                        exp_name: "control",
                      });
                      event.stopPropagation();
                    }}
                  >
                    Control link
                  </a>
                ) : (
                  "No Access"
                )}
              </td>
              <td className={valueCell}>
                {hasAccess ? (
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsDevUIDialogOpen(true);
                      setDevUIDialogProps({
                        query: record.query,
                        queryHash: record.queryHash,
                        jobId: props.jobId,
                        exp_name: "experiment",
                      });
                      event.stopPropagation();
                    }}
                  >
                    Treatment link
                  </a>
                ) : (
                  "No Access"
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    );

    const renderPFTableBody = () => (
      <tbody className={sharedStyles.tbody}>
        {slicedPFRates.map((record) => {
          return (
            <tr
              key={`${record.queryHash}-pfrates`}
              className={rowStyle}
              onClick={() => openSidePane(() => renderAssertionDetails(record))}
            >
              <td className={sharedStyles.cell}>
                <div className={sharedStyles.longTextWrapper}>
                  <div
                    className={sharedStyles.expand}
                    onClick={(event) => {
                      openSidePane(() => renderAssertionDetails(record));
                      event?.stopPropagation();
                    }}
                  >
                    <ChevronLeftRegular />
                  </div>
                  <Tip withArrow content={record.query} relationship="label">
                    <Body1 className={sharedStyles.longText}>
                      {record.query}
                    </Body1>
                  </Tip>
                </div>
              </td>
              <td className={sharedStyles.cell}>
                <div className={sharedStyles.longTextWrapper}>
                  <Tip withArrow content={record.segment} relationship="label">
                    <Body1 className={sharedStyles.longText}>
                      {record.segment}
                    </Body1>
                  </Tip>
                </div>
              </td>
              {sortKeys.map((key) => {
                const pfRate = record[key];
                return key.includes("critical") ? (
                  <td
                    className={getCriticalCell(undefined, pfRate)}
                    key={`${record.queryHash}-${key}-pfrates`}
                  >
                    <Tip
                      withArrow
                      content={getPFToolTip(pfRate)}
                      relationship="label"
                    >
                      <Body1>{getPFValue(pfRate)}</Body1>
                    </Tip>
                  </td>
                ) : (
                  <td
                    className={valueCell}
                    key={`${record.queryHash}-${key}-pfrates`}
                  >
                    <Tip
                      withArrow
                      content={getPFToolTip(pfRate)}
                      relationship="label"
                    >
                      <Body1>{getPFValue(pfRate)}</Body1>
                    </Tip>
                  </td>
                );
              })}
              <td className={valueCell}>
                {hasAccess ? (
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsDevUIDialogOpen(true);
                      setDevUIDialogProps({
                        query: record.query,
                        queryHash: record.queryHash,
                        jobId: props.jobId,
                        exp_name: "control",
                      });
                      event.stopPropagation();
                    }}
                  >
                    Control link
                  </a>
                ) : (
                  "No Access"
                )}
              </td>
              <td className={valueCell}>
                {hasAccess ? (
                  <a
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      setIsDevUIDialogOpen(true);
                      setDevUIDialogProps({
                        query: record.query,
                        queryHash: record.queryHash,
                        jobId: props.jobId,
                        exp_name: "experiment",
                      });
                      event.stopPropagation();
                    }}
                  >
                    Treatment link
                  </a>
                ) : (
                  "No Access"
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    );

    const renderScoreTableFooter = () => {
      const avgs = getAvgScores();
      return (
        <tfoot>
          <tr className={sharedStyles.rowStyle} key="score-footer">
            <td className={avgValueCell} colSpan={2}>
              Averages:
            </td>
            {sortKeys.map((key) => {
              const avgObj = avgs[key];
              const avg = avgObj.sum / avgObj.count;
              return (
                <td key={`${key}-scores-footer`} className={avgValueCell}>
                  {getScoreAvgValue(avg)}
                </td>
              );
            })}
            <td className={avgValueCell} colSpan={2}>
              Total {emptyFilters() ? "" : "filtered"} queries:{" "}
              {queryData.length}
            </td>
          </tr>
        </tfoot>
      );
    };

    const renderPFTableFooter = () => {
      const totals = getTotalPFRates();
      return (
        <tfoot>
          <tr className={sharedStyles.rowStyle} key="pf-footer">
            <td className={avgValueCell} colSpan={2}>
              Totals:
            </td>
            {sortKeys.map((key, index) => (
              <td key={`${key}-pfrates-footer`} className={avgValueCell}>
                <Tip
                  key={index}
                  withArrow
                  content={getPFToolTip(totals[key])}
                  relationship="label"
                >
                  <Body1 className={styles.avgValueCell}>
                    {getPFValue(totals[key])}
                  </Body1>
                </Tip>
              </td>
            ))}
            <td className={avgValueCell} colSpan={2}>
              Total {emptyFilters() ? "" : "filtered"} queries: {pfRates.length}
            </td>
          </tr>
        </tfoot>
      );
    };

    return (
      <>
        {renderFilterBar()}
        <table className={sharedStyles.table}>
          {renderTableHeader()}
          {showScores ? (
            <>
              {renderScoreTableBody()}
              {renderScoreTableFooter()}
            </>
          ) : (
            <>
              {renderPFTableBody()}
              {renderPFTableFooter()}
            </>
          )}
        </table>
        <DevUIDialog
          key={JSON.stringify(devUIDialogProps)}
          isOpen={isDevUIDialogOpen}
          content={devUIDialogProps}
          onComplete={() => setIsDevUIDialogOpen(false)}
        />
      </>
    );
  },
);
