import { observer } from "mobx-react-lite";
import type { FeatureSettingsDialogContributionProps } from "../../../../contribution/FeatureSettingsContribution";
import { getTokenName } from "../../../../helpers/accountHelper";
import { store } from "../../../../store/store";
import { UploadRefreshTokenDialog } from "./UploadRefreshTokenDialog";
import { GuidanceLink3S } from "../Other/GuidanceLink";

export const Upload3SRefreshTokenDialog = observer(
  (props: FeatureSettingsDialogContributionProps) => {
    const tokenName = getTokenName(store.account?.username ?? "", "3s") ?? "";
    return (
      <UploadRefreshTokenDialog
        tokenType={"3S refresh token"}
        tokenName={tokenName}
        dialogTitle={"Upload 3S Refresh Token"}
        loggingSource={"Upload3SRefreshTokenDialog"}
        guidanceLink={<GuidanceLink3S />}
        {...props}
      />
    );
  },
);
