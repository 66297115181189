import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Textarea,
} from "@fluentui/react-components";
import React, { useEffect } from "react";
import { getPerfMetricsQueryResult } from "../../helpers/apiHelper";

type KustoQueryDialogProps = {
  isOpen: boolean;
  onComplete: () => void;
};

export const KustoQueryDialog = (props: KustoQueryDialogProps) => {
  const [query, setQuery] = React.useState<string>("");
  const [result, setResult] = React.useState<string>("");

  useEffect(() => {
    if (props.isOpen) {
      setResult("");
      setQuery("");
    }
  }, [props.isOpen]);

  return (
    <Dialog
      open={props.isOpen}
      onOpenChange={() => {
        props.onComplete();
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{"Run Perf Metrics Query"}</DialogTitle>
          <DialogContent>
            <Body1>
              {
                "This should only used while development, please do not try to drop tables or records"
              }
            </Body1>
            <Field label={"Query"}>
              <Textarea
                style={{ width: "100%" }}
                size="medium"
                value={query}
                onChange={(_, data) => {
                  setQuery(data.value);
                }}
              />
            </Field>

            <Field label={"Result"}>
              <Textarea
                style={{ width: "100%" }}
                size="medium"
                value={result}
                onChange={(_, data) => {
                  setResult(data.value);
                }}
              />
            </Field>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={query.trim() === ""}
              appearance="primary"
              onClick={() => {
                setResult("");
                getPerfMetricsQueryResult({ Query: query })
                  .then((response) => {
                    const responseString = JSON.stringify(response);
                    setResult(responseString);
                  })
                  .catch((error) => {
                    setResult(error.message);
                  });
              }}
            >
              Run Query
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
