import {
  array,
  constStr,
  num,
  object,
  optional,
  or,
  str,
} from "sydneyeval-shared";

//the validation status which show on UX
export const JobPreValidationStepStatus = or([
  constStr("scrapping passed"),
  constStr("scrapping failed"),
  constStr("skipped"),
  constStr("in progress"),
  constStr("not started"),
]);
export type JobPreValidationStepStatus = ReturnType<
  typeof JobPreValidationStepStatus
>;

export const JobPreValidationResult = object({
  statusCode: optional(num),
  requestBody: optional(str),
  responseBody: optional(str),
  errorMessage: optional(str),
  scrap_user_id: optional(str),
  scrap_user: optional(str),
  client_request_id: optional(str),
});
export type JobPreValidationResult = ReturnType<typeof JobPreValidationResult>;

export const JobPreValidationResults = array(JobPreValidationResult);
export type JobPreValidationResults = ReturnType<
  typeof JobPreValidationResults
>;

export const JobPreValidationStep = object({
  stepName: str,
  status: JobPreValidationStepStatus,
  statusCode: optional(num),
  results: JobPreValidationResults,
  exp_config: str,
  sydney_url: str,
});
export type JobPreValidationStep = ReturnType<typeof JobPreValidationStep>;
