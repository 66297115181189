import { Button } from "@fluentui/react-button";
import type { CheckboxProps } from "@fluentui/react-components";
import {
  Checkbox,
  Link,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-dialog";
import { Body1 } from "@fluentui/react-text";
import { observer } from "mobx-react-lite";
import * as React from "react";

type ConsentDialogProps = {
  onAgreeClick: () => void;
};

const useStyles = makeStyles({
  dialog: {
    marginTop: "30px",
    height: "calc(100% - 40px)",
    width: "672px",
    maxWidth: "100%",
  },
  dialogBody: {
    height: "calc(100% - 40px)",
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    ...shorthands.gap("6px"),
    color: tokens.colorBrandForeground1,
  },
  dialogSubTitle: {
    display: "flex",
    flexDirection: "column",
    marginTop: "5px",
    marginBottom: "3px",
    fontSize: "16px",
    ...shorthands.gap("6px"),
    color: tokens.colorBrandForeground1,
  },
  itemTitle: {
    fontWeight: 700,
  },
  dialogContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    ...shorthands.overflow("auto"),
  },
  dialogItem: {
    marginTop: "10px",
  },
  checkbox: {
    width: "500px",
    maxWidth: "100%",
    "> label": {
      userSelect: "none",
    },
  },
  agreeButton: {
    marginBottom: "30px",
  },
  underline: {
    ...shorthands.textDecoration("underline"),
  },
});

export const ConsentDialog = observer(
  ({ onAgreeClick }: ConsentDialogProps) => {
    const styles = useStyles();
    const [checked, setChecked] =
      React.useState<CheckboxProps["checked"]>(false);
    const onAgree = () => {
      onAgreeClick();
    };

    return (
      <Dialog modalType="alert" open={true}>
        <DialogSurface className={styles.dialog}>
          <DialogBody className={styles.dialogBody}>
            <DialogTitle>{"SEVAL User Consent Form"}</DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <Body1 className={styles.dialogItem}>
                <DialogTitle className={styles.dialogTitle}>
                  {"Introduction"}
                </DialogTitle>
                Welcome to our internal platform for running quality evaluation
                tasks for your features on the Sydney-Powered Copilot app, e.g.,
                MChat. This empowers you to develop features for Copilot more
                efficiently. We appreciate your interest in our services and
                would like to inform you about the security, privacy, and
                compliance commitments related to this program. Your employment
                will not be impacted by whether you choose to participate or
                not.
              </Body1>

              <Body1 className={styles.dialogItem}>
                <DialogTitle className={styles.dialogTitle}>
                  {"Evaluation Experiences"}
                </DialogTitle>
                The evaluation platform offers the following experiences:
                <ul>
                  <li>
                    <strong>SEVAL UX</strong> (
                    <Link href="https://aka.ms/seval">aka.ms/seval</Link>): Used
                    by Copilot engineers to trigger evaluation jobs, visualize
                    the metric reports, shared the results with all engineers to
                    be accessed through SEVAL UX.
                  </li>
                  <li>
                    <strong>SBS</strong> (
                    <Link href={"https://aka.ms/srsbs"}>aka.ms/srsbs</Link>):
                    Side by Side tool used by Copilot engineers and annotators
                    to debug MChat response and annotate results.
                  </li>
                </ul>
              </Body1>

              <Body1 className={styles.dialogItem}>
                <DialogTitle className={styles.dialogTitle}>
                  {"Privacy and Compliance"}
                </DialogTitle>
                <div className={styles.dialogSubTitle}>Scope</div>
                <Body1 className={styles.dialogItem}>
                  This platform enables Microsoft Employees to trigger copilot
                  evaluation jobs and provide eyes-on access to their results
                  (e.g. Microsoft tenant only data, other customer data is
                  excluded). Given the risks associated with those tasks, only
                  the employees are allowed to use SEVAL:
                </Body1>
                <ul>
                  <li className={styles.dialogItem}>
                    WW Microsoft engineers working on the development of Copilot
                    based features.
                  </li>

                  <li className={styles.dialogItem}>No Vendors</li>

                  <li className={styles.dialogItem}>
                    Employees Level 67 or lower
                  </li>

                  <li className={styles.dialogItem}>
                    Not part of HR, Business Development, CELA, Finance, Sales
                    organizations
                  </li>
                </ul>
              </Body1>

              <Body1 className={styles.dialogItem}>
                <div className={styles.dialogSubTitle}>Data Handling</div>
                The following data generated as part of the evaluation job will
                be collected by Sydney Evaluation Platform: job link, alias, job
                submission time, status, metrics, utterances, Sydney Responses,
                LLM Reasoning data. Sydney responses and LLM Reasoning data are
                grounded in M365 content that you have access to. Your data is
                stored and processed in accordance with the Microsoft Data
                Protection Notice.
              </Body1>

              <Body1 className={styles.dialogItem}>
                <div className={styles.dialogSubTitle}>Data Sharing</div>
                <div className={styles.dialogItem}>
                  In SEVAL, utterances, Sydney Responses, LLM Reasoning data are
                  processed consistent with MSD Governance Obligations and only
                  visible to you and users listed in the Data Access section. If
                  you want to share the data with other SEVAL users, please
                  click the share button and share with specific aliases. Once
                  shared with SEVAL users, they can view the data, but they
                  cannot download such data.
                </div>
                <div className={styles.dialogItem}>
                  For MChat evaluation jobs, you can enable creating the SBS
                  experiment when submitting evaluation job. Your utterances and
                  Sydney Responses will be available in SBS Portal if SBS
                  experiment is created. The data in SBS are also processed
                  consistent with MSD Governance Obligations and only visible to
                  you and users listed in the Data Access section.
                </div>
                <div
                  className={mergeClasses(styles.dialogItem, styles.underline)}
                >
                  Please note that Sydney Response and LLM Reasoning data are
                  filtered by SEVAL privacy filter. In case it may still contain
                  sensitive information, please be very careful if you choose to
                  share these data. Below are some examples of sensitive
                  information we are talking about you should not be sharing:
                </div>
                <div
                  className={mergeClasses(styles.dialogItem, styles.underline)}
                >
                  Attorney Client Privilege, merger and acquisition discussions,
                  tented projects, employee health, pay, and insurance and other
                  HR information, non-public financial reports, discussions with
                  external customers.
                </div>
              </Body1>
              <Body1 className={styles.dialogItem}>
                <div className={styles.dialogSubTitle}>Data Access</div>
                <div className={styles.dialogItem}>
                  Your personal data in SEVAL can be accessed by:
                </div>
                <ul>
                  <li className={styles.dialogItem}>
                    <strong>Yourself</strong>: You can view, download, and
                    delete the data. For data deletion from SEVAL and SBS UX,
                    use the delete option available on SEVAL and SBS UX.
                  </li>
                  <li className={styles.dialogItem}>
                    <strong>Selected Engineers</strong> that you share the data
                    with following the instructions in{" "}
                    <span className={styles.underline}>Data Sharing</span>. Once
                    shared, such data can be viewed by engineers, but they{" "}
                    <strong>cannot</strong> download the data.
                  </li>
                  <li className={styles.dialogItem}>
                    <strong>Platform engineers (evaldri@microsoft.com)</strong>:
                    to debug possible issues with evaluating jobs. Such access
                    requires manual JIT elevation approved by GEM of Copilot
                    Evaluation Platform.
                  </li>
                  <li className={styles.dialogItem}>
                    <strong>Evaluation Scientist</strong>: to debug instability
                    with LLM metrics. Such access will require Such access
                    requires manual JIT elevation approved by GEM of Copilot
                    Evaluation Platform.
                  </li>
                  <li className={styles.dialogItem}>
                    <strong>Data Export</strong>: You can export your metrics,
                    utterances, Sydney Responses, LLM Reasoning data from the
                    metrics result page, by clicking the download button.
                  </li>
                  <li className={styles.dialogItem}>
                    <strong>Logging</strong>: By using this platform, you
                    acknowledge that metadata around your interaction with the
                    process will be logged for the purpose of running/improving
                    the service.
                  </li>
                  <li className={styles.dialogItem}>
                    <strong>Retention</strong>: All the data collected by the
                    platform data will be retained in SEVAL and SBS (if SBS
                    experiment is enabled) for <strong>90 days</strong>, unless
                    you explicitly delete it by revoking the consent.
                  </li>
                  <li className={styles.dialogItem}>
                    <strong>Revoke Consent</strong>: This user consent can be
                    revoked at any time. Once that happens, the data collected
                    when you were using and shared through the platform will be
                    deleted within 30 days. Please follow the screenshot below
                    to revoke your consent if needed.
                  </li>
                  <li className={styles.dialogItem}>
                    For more information about how your data is being handled,
                    please refer to the{" "}
                    <Link href="https://privacy.microsoft.com/en-US/data-privacy-notice">
                      Data Privacy Notice - Microsoft privacy
                    </Link>
                    .
                  </li>
                </ul>
              </Body1>

              <Body1 className={styles.dialogItem}>
                <DialogTitle className={styles.dialogTitle}>
                  {"Future Improvements"}
                </DialogTitle>
                Our team is actively working on enhancing the security, privacy,
                and compliance aspects of this platform. User feedback is
                valuable in this process, and we encourage you to provide
                insights that can contribute to the overall improvement of our
                platform, for any issue or suggestion please raise from{" "}
                <Link href="https://aka.ms/seval-request">
                  aka.ms/seval-request
                </Link>
                .
              </Body1>
              <Body1 className={styles.dialogItem}>
                <DialogTitle className={styles.dialogTitle}>
                  {"Contact Information"}
                </DialogTitle>
                By continuing to use this platform, you agree to the terms
                outlined in this consent. If you have any questions or concerns,
                please contact our support team on{" "}
                <Link href="mailto:evaldri@microsoft.com">
                  evaldri@microsoft.com
                </Link>
                .
              </Body1>
            </DialogContent>

            <DialogActions position="start">
              <Checkbox
                className={styles.checkbox}
                label="Share your account information and conversation data as described above"
                checked={checked}
                onChange={(_, data) => setChecked(data.checked)}
              />
            </DialogActions>

            <DialogActions className={styles.agreeButton}>
              <Button
                onClick={onAgree}
                disabled={!checked}
                appearance="primary"
              >
                Agree
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);
