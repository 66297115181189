import { array, bool, object, str } from "sydneyeval-shared";

const SBSExp = object({
  name: str,
  isMainExperiment: bool,
});

export const JobSBSExpResult = object({
  sbsExperiments: array(SBSExp),
  controlAnnotationExperiments: array(SBSExp),
  treatmentAnnotationExperiments: array(SBSExp),
});

export type JobSBSExpResult = ReturnType<typeof JobSBSExpResult>;
export type SBSExp = ReturnType<typeof SBSExp>;

export type SBSLink = {
  link: string;
  text: string;
};
