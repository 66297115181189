import Papa from "papaparse";
import type { TypeGuard } from "sydneyeval-shared";
import {
  array,
  csvFileHelper,
  mapOf,
  optional,
  parseJsonStr,
  parseJsonStrOptional,
  str,
  uniqByObject,
} from "sydneyeval-shared";
import {
  bingfile_DiagnosisFileName,
  citeFileSuffix_baseline,
  citeFileSuffix_treatment,
} from "../../../constants/constants";
import {
  getAzureMLFileContent,
  getJobOutputFileContent,
} from "../../../helpers/apiHelper";
import { updateBingDiagnosisSummaryAction } from "../actions/resultActions";
import type { BingDiagnosisUtteranceResult } from "../models/BingDiagnosisFile";
import {
  BingDiagnosisMetricsData,
  BingDiagnosisSBSView,
  BingDiagnosisSummary,
} from "../models/BingDiagnosisFile";
import { BingConfigFileSchema } from "../models/BingJobConfig";
import {
  BingFirstColumnArrayInConvTsvFile,
  BingSeventhColumnArrayInConvTsvFile,
  type BingControlVsTreatment,
  type BingJobFile,
} from "../models/BingJobFile";
import {
  updateBingAnswerTriggerData,
  updateBingControlAndTreatment,
  updateBingPluginTriggerData,
  updateConciseTable,
  updateDiagnosisSummaries,
  updateDownloadedBingTables,
  updateLoadedCitationLinks,
  updateLoadedMetricData,
  updateLoadedSBSView,
  updateSummaryTable,
} from "../mutators/jobResultMutators";
import { resultStore } from "../store/resultStore";

/***
 * This is the data structure of the csv file
 * For Experiment, the exp_name is like "Experiment(win_copilot_creative_treatment:win_copilot_creative)"
 * For Baseline, the exp_name is like "Baseline(win_copilot_creative_treatment)"
 * If there are pair experiment, we should display Experiment record and Baseline record
 * For No Treatment experiment, the exp_name is like "win_copilot_creative_treatment". this is called circled version
 * If no pair experiment, we should display circle version
 */
const BingResultBaseData = mapOf(str);

export type BingResultBaseData = ReturnType<typeof BingResultBaseData>;

const parseBingResultBaseData = (
  data: any[],
  typeGuard: TypeGuard<BingResultBaseData[]>,
): BingResultBaseData[] | undefined => {
  try {
    return typeGuard(data, "BingResultBaseData");
  } catch (error) {
    return undefined;
  }
};

const convertExperimentRecord = (
  exp_name: string,
): BingControlVsTreatment | undefined => {
  if (exp_name === undefined || exp_name.trim().length === 0) {
    return undefined;
  }

  const regex = /Experiment\(([^:]+):([^)]+)\)/;
  const match = exp_name.match(regex);
  if (match) {
    const value0 = match[1];
    const value1 = match[2];
    return {
      control: value1,
      treatment: value0,
    };
  }
  return undefined;
};

const isBaselineRecord = (exp_name: string) => {
  if (exp_name === undefined || exp_name.trim().length === 0) {
    return false;
  }

  const regex = /Baseline\(([^:]+)\)/;
  const match = exp_name.match(regex);
  if (match) {
    return true;
  }
  return false;
};

const isExperimentRecord = (exp_name: string) => {
  if (exp_name === undefined || exp_name.trim().length === 0) {
    return false;
  }

  const regex = /Experiment\(([^:]+):([^)]+)\)/;
  const match = exp_name.match(regex);
  if (match) {
    return true;
  }
  return false;
};

const shouldAddSingleControlRecord = (
  exp_name: string,
  CTpairs: BingControlVsTreatment[],
) => {
  if (
    isExperimentRecord(exp_name) ||
    isBaselineRecord(exp_name) ||
    exp_name === ""
  ) {
    return false;
  }

  // filter out circled version
  const hasTreatment = CTpairs.find(
    (item) => item?.control === exp_name || item?.treatment === exp_name,
  );
  return hasTreatment ? false : true;
};

export const getControlTreatmentPairs = (data: any[]) => {
  // This is a CT pair like Experiment(win_copilot_creative_treatment:win_copilot_creative)
  const CTpairs: BingControlVsTreatment[] = data
    .map((row) => convertExperimentRecord(row.exp_name))
    .filter((x): x is BingControlVsTreatment => x !== undefined);

  // Or only control experiment
  const controls = data
    .map((row) => {
      if (shouldAddSingleControlRecord(row.exp_name, CTpairs)) {
        return { control: row.exp_name };
      }
      return undefined;
    })
    .filter((x): x is BingControlVsTreatment => x !== undefined);

  const allCT = CTpairs.concat(controls);
  return uniqByObject(allCT);
};

export const convertBingCSVFileToJson = async (csvData: string) => {
  const data = parseBingResultBaseData(
    await csvFileHelper(csvData),
    array(BingResultBaseData),
  );

  if (data === undefined) {
    return undefined;
  }
  // backfill metrics
  let lastMetric = "";
  const data2 = data.map((row) => {
    if (row.Metric === undefined) {
      return row;
    }
    if (row.Metric.trim().length > 0) {
      lastMetric = row.Metric;
      return row;
    }
    return {
      ...row,
      Metric: lastMetric,
    };
  });

  return data2;
};

const getComputedData = async (
  file: BingJobFile | undefined,
): Promise<Record<string, string>[] | undefined> => {
  if (file === undefined) {
    return undefined;
  }
  const csvData = await getJobOutputFileContent(file?.fullPath);

  return convertBingCSVFileToJson(csvData);
};

export const getMetricsSummaryData = async (filelist: BingJobFile[]) => {
  const E2E_metrics = filelist.filter((file) =>
    file.fullPath.includes("E2E_metrics"),
  )[0];

  const computedData = await getComputedData(E2E_metrics);
  if (computedData === undefined) {
    return;
  }

  const distinctCTArray = getControlTreatmentPairs(computedData);
  updateBingControlAndTreatment(distinctCTArray);

  updateSummaryTable(computedData);
  updateDownloadedBingTables(
    `${resultStore.seletedBingFile}/summary`,
    computedData,
  );
};

export const getE2EConciseMetricsData = async (filelist: BingJobFile[]) => {
  const E2E_Concise_metrics = filelist.filter((file) =>
    file.fullPath.includes("E2E_Concise_metrics"),
  )[0];

  const computedData = await getComputedData(E2E_Concise_metrics);

  if (computedData === undefined) {
    return;
  }
  updateConciseTable(computedData);
  updateDownloadedBingTables(
    `${resultStore.seletedBingFile}/concise`,
    computedData,
  );
};

export const getAnswerTriggerData = async (filelist: BingJobFile[]) => {
  const answer_trigger_rate_metrics = filelist.filter((file) =>
    file.fullPath.includes("answer_trigger_rate_metrics"),
  )[0];

  if (answer_trigger_rate_metrics === undefined) {
    return;
  }
  const csvData = await getJobOutputFileContent(
    answer_trigger_rate_metrics?.fullPath,
  );
  const firstLine = csvData.split("\n")[0];
  const replaceDot = firstLine.replaceAll(".", "_");
  const replaceFirstLine = csvData.replace(firstLine, replaceDot);
  const data = await csvFileHelper(replaceFirstLine);

  const getExpName = (value: string) => {
    if (value.split(":").length === 2) {
      return value.split(":")[0];
    }
    return value;
  };

  const getCategory = (value: string) => {
    if (value.split(":").length === 2) {
      return value.split(":")[1];
    }
    return value;
  };

  const wrappedCompareData = data.map((row) => {
    return {
      ...row,
      name: getExpName(row.exp_name),
      Category: getCategory(row.exp_name),
    };
  });

  updateBingAnswerTriggerData(wrappedCompareData);
  updateDownloadedBingTables(
    `${resultStore.seletedBingFile}/answer`,
    wrappedCompareData,
  );
};

export const getPluginTriggerData = async (filelist: BingJobFile[]) => {
  const plugin_trigger_rate_metrics = filelist.filter((file) =>
    file.fullPath.includes("plugin_trigger_rate_metrics"),
  )[0];

  if (plugin_trigger_rate_metrics === undefined) {
    return;
  }

  const csvData = await getJobOutputFileContent(
    plugin_trigger_rate_metrics?.fullPath,
  );
  const firstLine = csvData.split("\n")[0];
  const replaceDot = firstLine.replaceAll(".", "_dot_");
  const replaceFirstLine = csvData.replace(firstLine, replaceDot);
  const data = await csvFileHelper(replaceFirstLine);

  const getExpName = (value: string) => {
    if (value.split(":").length === 2) {
      return value.split(":")[0];
    }
    return value;
  };

  const getMetric = (value: string) => {
    if (value.split(":").length === 2) {
      return value.split(":")[1];
    }
    return value;
  };

  const wrappedCompareData = data.map((row) => {
    return {
      ...row,
      name: getExpName(row.exp_name),
      Metric: getMetric(row.exp_name),
    };
  });

  updateBingPluginTriggerData(wrappedCompareData);

  updateDownloadedBingTables(
    `${resultStore.seletedBingFile}/plugin`,
    wrappedCompareData,
  );
};

export const getDiagnosisSummaryData = async (filelist: BingJobFile[]) => {
  const DiagnosisFile = filelist.filter((file) =>
    file.fullPath.includes(bingfile_DiagnosisFileName),
  )[0];

  if (DiagnosisFile === undefined) {
    return undefined;
  }

  const jsonFile = await getJobOutputFileContent(DiagnosisFile.fullPath);
  if (jsonFile === undefined) {
    return undefined;
  }

  const result = parseJsonStr(
    jsonFile,
    BingDiagnosisSummary,
    "BingDiagnosisSummary",
  );
  updateDiagnosisSummaries(DiagnosisFile.folderName, result);

  updateBingDiagnosisSummaryAction(result);

  return result;
};

export const getDiagnosisSBSViewData = async (key: string) => {
  const currentFilePath = resultStore.bingJobOutputFiles?.filter(
    (item) =>
      item.folderName === resultStore.seletedBingFile &&
      item.fileName.indexOf(bingfile_DiagnosisFileName) >= 0,
  )[0];

  if (currentFilePath === undefined) {
    return;
  }
  const filePath = currentFilePath.fullPath.replace(
    bingfile_DiagnosisFileName,
    `paged_sbs_view/${key}`,
  );

  const jsonFile = await getJobOutputFileContent(filePath);

  if (jsonFile === undefined) {
    return undefined;
  }

  const loadedMetric = parseJsonStr(
    jsonFile,
    BingDiagnosisMetricsData,
    "BingDiagnosisMetricsData",
  );
  const loadedSBSViews = parseJsonStr(
    jsonFile,
    BingDiagnosisSBSView,
    "BingDiagnosisSBSView",
  );

  updateLoadedMetricData(key, loadedMetric);
  updateLoadedSBSView(key, loadedSBSViews);

  return {
    Key: key,
    Conversation:
      resultStore.loadedSBSViews.get(key)?.baseline[0].Request ?? "Loading...",
    Metrics: resultStore.loadedMetrics.get(key) ?? {},
  };
};

function parseTsvToQueryAttributionsMap(
  tsvData: string,
): Map<string, Map<string, string>> {
  const queryToAttributions: Map<string, Map<string, string>> = new Map();

  Papa.parse(tsvData, {
    delimiter: "\t",
    skipEmptyLines: true,
    complete: (result) => {
      result.data.forEach((row) => {
        // The tsvData is from the Bing job output file, which is expected to have 7 columns in each row
        // The first column is an array of objects, it save the query text that human input. We use it as the key of the outer map
        // The seventh column is an array of objects, it save the attribution links that bot output. We use it as the value of the inner map
        if (Array.isArray(row) && row.length >= 7) {
          try {
            const firstColumnArrayData = parseJsonStrOptional(
              row[0],
              BingFirstColumnArrayInConvTsvFile,
            );
            const seventhColumnArrayData = parseJsonStrOptional(
              row[6],
              BingSeventhColumnArrayInConvTsvFile,
            );

            if (
              firstColumnArrayData !== undefined &&
              seventhColumnArrayData !== undefined &&
              firstColumnArrayData.length > 0 &&
              seventhColumnArrayData.length > 0
            ) {
              const key = firstColumnArrayData[0].text;
              const valueMap: Map<string, string> = new Map();

              seventhColumnArrayData.forEach((item) => {
                if (item.Human && item.attributions) {
                  valueMap.set(item.Human, JSON.stringify(item.attributions));
                }
              });

              if (valueMap.size > 0 && key !== undefined) {
                queryToAttributions.set(key, valueMap);
              }
            }
          } catch (error) {
            return;
          }
        }
      });
    },
  });

  return queryToAttributions;
}

export const getAndUpdateCitationLinks = async () => {
  const controlTreatmentFilter =
    resultStore?.selectedFilter.get("Control/Treatment");
  const currentFilePath = resultStore.bingJobOutputFiles?.find(
    (item) =>
      item.folderName === resultStore.seletedBingFile &&
      item.fileName.indexOf(bingfile_DiagnosisFileName) >= 0,
  );

  if (currentFilePath === undefined || controlTreatmentFilter === undefined) {
    return;
  }

  const loadedCitationLinksMapkey =
    currentFilePath.folderName + "_" + controlTreatmentFilter;
  const loaded = resultStore.loadedCitationLinks.get(loadedCitationLinksMapkey);
  // if already loaded, skip
  if (loaded !== undefined) {
    return;
  }
  // only do for single turn in bing job for now
  const isBingJob = resultStore?.resultJob?.Properties?.IsBingJob;
  if (isBingJob === undefined || !isBingJob) {
    return;
  }
  const settings = resultStore?.resultJob?.Settings;
  if (!settings) {
    return;
  }

  const config = parseJsonStrOptional(settings, BingConfigFileSchema);
  if (
    config === undefined ||
    config.config_file === undefined ||
    config.config_file.path === undefined
  ) {
    return;
  }
  const setttingsContent: string = await getAzureMLFileContent(
    config.config_file.path,
  );

  const containsMultiturn = setttingsContent.indexOf("multi_turn") >= 0;
  if (containsMultiturn) {
    return;
  }

  const match = controlTreatmentFilter?.match(/\([^:]+:([^)]+)\)/);
  if (!match) {
    return;
  }

  const filePrefix = match[1];
  const baselineConversationFilePath = currentFilePath.fullPath.replace(
    bingfile_DiagnosisFileName,
    filePrefix + citeFileSuffix_baseline,
  );
  const treatmentConversationFilePath = currentFilePath.fullPath.replace(
    bingfile_DiagnosisFileName,
    filePrefix + citeFileSuffix_treatment,
  );

  if (
    baselineConversationFilePath !== undefined &&
    treatmentConversationFilePath !== undefined
  ) {
    Promise.all([
      getJobOutputFileContent(baselineConversationFilePath),
      getJobOutputFileContent(treatmentConversationFilePath),
    ]).then(([baselineConversationTsvFile, treatmentConversationTsvFile]) => {
      const baselineCitationLinkMap = parseTsvToQueryAttributionsMap(
        baselineConversationTsvFile,
      );
      const treatmentCitationLinkMap = parseTsvToQueryAttributionsMap(
        treatmentConversationTsvFile,
      );

      updateLoadedCitationLinks(
        currentFilePath.folderName,
        controlTreatmentFilter,
        baselineCitationLinkMap,
        treatmentCitationLinkMap,
      );
    });
  }
};

export const getLoadedMetricsWithCitations = (
  key: string,
  metrics: BingDiagnosisUtteranceResult[],
): BingDiagnosisUtteranceResult[] => {
  const controlTreatmentFilter =
    resultStore?.selectedFilter.get("Control/Treatment");
  const currentFilePath = resultStore.bingJobOutputFiles?.filter(
    (item) =>
      item.folderName === resultStore.seletedBingFile &&
      item.fileName.indexOf(bingfile_DiagnosisFileName) >= 0,
  )[0];

  if (currentFilePath === undefined) {
    return metrics;
  }

  const loadedCitationLinksMapkey =
    currentFilePath.folderName + "_" + controlTreatmentFilter;
  const loadedCitationLinks = resultStore.loadedCitationLinks?.get(
    loadedCitationLinksMapkey,
  );
  const citationLinkMap =
    key === "baseline"
      ? loadedCitationLinks?.baselineCitationMap
      : loadedCitationLinks?.treatmentCitationMap;

  if (
    !Array.isArray(metrics) ||
    metrics.length <= 0 ||
    !citationLinkMap ||
    citationLinkMap.size === 0
  ) {
    return metrics;
  }

  const baseRequest = metrics[0].Request;
  const queryToCitationMap = citationLinkMap?.get(baseRequest);
  if (queryToCitationMap === undefined || queryToCitationMap.size === 0) {
    return metrics;
  }

  const cloneMetrics: BingDiagnosisUtteranceResult[] = [];
  for (let i = 0; i < metrics.length; i++) {
    const item: BingDiagnosisUtteranceResult = Object.assign({}, metrics[i]); // shallow copy
    if (item.Request) {
      let citationLinkJson = queryToCitationMap?.get(item.Request);
      if (citationLinkJson === undefined && queryToCitationMap.size === 1) {
        citationLinkJson = optional(str)(
          queryToCitationMap?.values().next().value,
          "citationLinkJson",
        );
      } else if (citationLinkJson) {
        try {
          const attributionsObj = parseJsonStrOptional(
            citationLinkJson,
            mapOf(str),
          );
          if (attributionsObj === undefined) {
            continue;
          }
          item.Response = item.Response.replace(
            /\[\^(\d+)\^\]/g,
            (match, n) => {
              const url = attributionsObj[String(n)];
              return url ? `[${n}](${url})` : match;
            },
          );
        } catch (error) {
          continue;
        }
      }

      cloneMetrics.push(item);
    }
  }

  return cloneMetrics;
};
