export const MChatDisplayedMetrics = [
  "sbsleo_sbsleo_score",
  "one_centric",
  "search_enterprise_calendar_NDCG@10",
  "search_enterprise_files_NDCG@10",
  "search_enterprise_people_NDCG@10",
  "search_enterprise_emails_NDCG@10 ",
  "search_enterprise_teams_NDCG@10",
  "groundleo_claimbreak_score",
  "hasCitation",
];
