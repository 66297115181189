import {
  Caption1,
  Card,
  CardHeader,
  CardPreview,
  Tab,
  TabList,
  Tag,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { useState } from "react";
import JsonView from "react18-json-view";
import type {
  ISubstrateSearchBaseResult,
  ISubstrateSearchEmailResult,
  ISubstrateSearchFileResult,
  ISubstrateSearchTeamsMessageResult,
  ISydneySearchBaseSnippet,
  ISydneySearchEmailSnippet,
  ISydneySearchExternalSnippet,
  ISydneySearchFileSnippet,
  ISydneySearchTeamsMesssage,
  IWebSearchResult,
} from "../bizChatEvalDataProvider";

interface IResourceCardProps {
  source: "sydney" | "substrate" | "web" | "other";
  snippet:
    | ISydneySearchBaseSnippet
    | ISubstrateSearchBaseResult
    | IWebSearchResult
    | string;
  tags?: string[];
  index?: number;
}

interface IBasicCardProps {
  icon: string;
  iconTitle: string;
  title: string;
  link?: string;
  metadata: string[];
  shortSnippet?: string;
  longSnippet?: string;
  tags: string[];
}

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "720px",
    maxWidth: "100%",
    backgroundColor: tokens.colorBrandBackground2Hover,
  },
});

const useJsonViewerStyles = makeStyles({
  jsonViewer: {
    backgroundColor: "#fff",
    height: "30vh",
    width: "100%",
    overflowX: "auto",
    overflowY: "auto",
    overflowWrap: "anywhere",
  },
});

const useTabListStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    rowGap: "20px",
    backgroundColor: tokens.colorNeutralBackground4Hover,
  },
});

function getProductIconUrl(productName: string) {
  return `https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/${productName}_16x1.svg`;
}

function getFileIconUrl(extension: string) {
  if (!extension) return "";
  extension = extension.toLowerCase();
  if (extension === "aspx") {
    return "https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/sharepoint_48x1.svg";
  }
  return `https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/item-types/48/${extension}.svg`;
}

function convertSydneySearchSnippetToCardProps(
  snippet: ISydneySearchBaseSnippet,
): IBasicCardProps {
  let title = snippet.Subject || snippet.Title || snippet.DisplayName || "";
  let icon = snippet.Type;
  let iconTitle = snippet.Type;
  let longSnippet = snippet.Snippet || "";
  const metadata: string[] = [];
  if (snippet.Type === "File") {
    const fileSnippet = snippet as ISydneySearchFileSnippet;
    title = fileSnippet.Title;
    icon = getFileIconUrl(fileSnippet.FileType);
    iconTitle = fileSnippet.FileType;
    fileSnippet.Author && metadata.push(`Author: ${fileSnippet.Author}`);
    fileSnippet.LastModifiedTime &&
      metadata.push(`Last modified: ${fileSnippet.LastModifiedTime}`);
  }
  if (snippet.Type === "Email" || snippet.Type === "EmailMessage") {
    const emailSnippet = snippet as ISydneySearchEmailSnippet;
    title = emailSnippet.Subject;
    icon = getProductIconUrl("outlook");
    iconTitle = "Email";
    emailSnippet.From &&
      metadata.push(
        `From: ${emailSnippet.From.Name} <${emailSnippet.From.Address}>`,
      );
    emailSnippet.To && metadata.push(`To: ${emailSnippet.To}`);
    metadata.push(`Sent: ${emailSnippet.DateTimeSent}`);
  }
  if (snippet.Type === "External") {
    const externalSnippet = snippet as ISydneySearchExternalSnippet;
    title = externalSnippet.Title;
    icon = externalSnippet.SourceJson.Label_iconUrl;
    iconTitle = "External";
  }
  if (snippet.Type === "TeamsMessage") {
    const teamsMessageSnippet = snippet as ISydneySearchTeamsMesssage;
    title = teamsMessageSnippet.Subject;
    icon = getProductIconUrl("teams");
    iconTitle = "Teams Message";
    if (teamsMessageSnippet.From) {
      metadata.push(
        `From: ${teamsMessageSnippet.From?.Name} <${teamsMessageSnippet.From?.Address}>`,
      );
    }
    teamsMessageSnippet.To && metadata.push(`To: ${teamsMessageSnippet.To}`);
    metadata.push(`Sent: ${teamsMessageSnippet.DateTimeSent}`);
  }

  if (snippet.Snippet?.startsWith('"') && snippet.Snippet?.endsWith('"')) {
    longSnippet = snippet.Snippet.slice(1, -1);
  }

  const snippetTitle =
    longSnippet.length > 100
      ? longSnippet.substring(0, 100) + "..."
      : longSnippet;

  return {
    icon: icon,
    title: title ? title : snippetTitle,
    iconTitle: iconTitle,
    metadata: metadata,
    longSnippet: longSnippet,
    tags: [snippet.Type],
  };
}

function convertSubstrateSearchResultToCardProps(
  result: ISubstrateSearchBaseResult,
): IBasicCardProps {
  let title = "";
  let link: string | undefined = undefined;
  let icon = result.ContentSource;
  let iconTitle = result.ContentSource;
  let longSnippet: string | undefined = undefined;
  let shortSnippet: string | undefined = undefined;
  const metadata: string[] = [];
  if (
    result.ContentSource === "SharePoint" ||
    result.ContentSource === "OneDriveBusiness" ||
    result.ContentSource === "MicrosoftSearch"
  ) {
    const fileResult = result as ISubstrateSearchFileResult;
    title = fileResult.Title || "";
    link = fileResult.Path;
    longSnippet = fileResult.Summary;
    shortSnippet = fileResult.HitHighlightedSummary;
    let extension = fileResult.Path?.split(".").pop();
    if (extension && extension.length > 4) extension = undefined;
    icon = extension ? getFileIconUrl(extension) : "";
    iconTitle = extension || "File";
    fileResult.Author && metadata.push(`Author: ${fileResult.Author}`);
    fileResult.LastModifiedTime &&
      metadata.push(`Last modified: ${fileResult.LastModifiedTime}`);
  }
  if (result.ContentSource === "Exchange") {
    const emailResult = result as ISubstrateSearchEmailResult;
    title = emailResult.Subject;
    link = emailResult.Url;
    icon = getProductIconUrl("outlook");
    iconTitle = "Email";
    emailResult.Sender &&
      metadata.push(
        `From: ${emailResult.Sender.EmailAddress.Name} <${emailResult.Sender.EmailAddress.Address}>`,
      );
    emailResult.DisplayTo && metadata.push(`To: ${emailResult.DisplayTo}`);
    metadata.push(`Sent: ${emailResult.DateTimeSent}`);
  }

  if (result.ContentSource === "Teams") {
    const teamsResult = result as ISubstrateSearchTeamsMessageResult;
    title = teamsResult.Subject || "";
    icon = getProductIconUrl("teams");
    iconTitle = "Teams Message";
    longSnippet = teamsResult.HitHighlightedSummary;
    if (teamsResult.Sender) {
      metadata.push(
        `From: ${teamsResult.Sender.EmailAddress.Name} <${teamsResult.Sender.EmailAddress.Address}>`,
      );
    }
    metadata.push(`Sent: ${teamsResult.DateTimeSent}`);
    if (teamsResult.TeamName) {
      metadata.push(`Team: ${teamsResult.TeamName}`);
    }
    if (teamsResult.ChannelName) {
      metadata.push(`Channel: ${teamsResult.ChannelName}`);
    }
  }

  if (!longSnippet && result.HitHighlightedSummary) {
    longSnippet = result.HitHighlightedSummary;
  }

  return {
    icon: icon,
    iconTitle: iconTitle,
    title: title,
    link: link,
    metadata: metadata,
    longSnippet: longSnippet,
    shortSnippet: shortSnippet,
    tags: [result.ContentSource],
  };
}

function convertWebSearchResultToCardProps(
  result: IWebSearchResult,
): IBasicCardProps {
  return {
    icon: "",
    iconTitle: "bing",
    title: result.title,
    link: result.url,
    metadata: [],
    longSnippet: result.snippets?.join("\n"),
    tags: [],
  };
}

function convertOtherToCardProps(result: string): IBasicCardProps {
  return {
    icon: "",
    iconTitle: "",
    title: result,
    metadata: [],
    longSnippet: result,
    tags: [],
  };
}

export function convertResourceToCardProps(
  source: "sydney" | "substrate" | "web" | "other",
  snippet:
    | ISydneySearchBaseSnippet
    | ISubstrateSearchBaseResult
    | IWebSearchResult
    | string,
  tags?: string[],
  index?: number,
): IBasicCardProps {
  let cardProps: IBasicCardProps;
  if (source === "substrate") {
    cardProps = convertSubstrateSearchResultToCardProps(
      snippet as ISubstrateSearchBaseResult,
    );
  } else if (source === "sydney") {
    cardProps = convertSydneySearchSnippetToCardProps(
      snippet as ISydneySearchBaseSnippet,
    );
  } else if (source === "web") {
    cardProps = convertWebSearchResultToCardProps(snippet as IWebSearchResult);
  } else {
    cardProps = convertOtherToCardProps(snippet as string);
  }

  try {
    cardProps.longSnippet = cardProps.longSnippet
      ? decodeURIComponent(cardProps.longSnippet)
      : "";
  } catch (e) {
    // ignore
  }
  if (index) {
    cardProps.tags = [index.toString(), ...cardProps.tags, ...(tags || [])];
  }
  return cardProps;
}

export const ResourceCard = (props: IResourceCardProps) => {
  const [showPreview, setShowPreview] = useState(true);

  const basicCardProps = convertResourceToCardProps(
    props.source,
    props.snippet,
    props.tags,
    props.index,
  );
  const styles = useStyles();
  const jsonStyle = useJsonViewerStyles();
  const tabListStyle = useTabListStyles();

  return (
    <Card className={styles.card}>
      <CardHeader
        image={
          basicCardProps.icon ? (
            <img
              width="40px"
              src={basicCardProps.icon}
              alt={basicCardProps.iconTitle}
              title={basicCardProps.iconTitle}
            />
          ) : undefined
        }
        header={
          <b>
            {basicCardProps.link ? (
              <a href={basicCardProps.link} target="_blank">
                {basicCardProps.title}
              </a>
            ) : (
              basicCardProps.title
            )}
            {basicCardProps.tags.map((tag, i) => (
              <Tag
                key={"tag-" + i}
                style={{
                  backgroundColor: tokens.colorNeutralForeground2BrandHover,
                  color: "white",
                  marginLeft: "0.5em",
                }}
                size="small"
              >
                {tag}
              </Tag>
            ))}
          </b>
        }
        description={
          <div>
            <Caption1 style={{ whiteSpace: "pre-line" }}>
              {basicCardProps.metadata.join("\n")}
            </Caption1>
          </div>
        }
      />

      <CardPreview>
        <div className={tabListStyle.root}>
          <TabList defaultSelectedValue={"preview"}>
            <Tab
              value="preview"
              onClick={() => {
                setShowPreview(true);
              }}
            >
              Preview
            </Tab>
            <Tab
              value="json"
              onClick={() => {
                setShowPreview(false);
              }}
            >
              Json
            </Tab>
          </TabList>
        </div>
        {showPreview && (
          <div
            style={{
              width: "98%",
              paddingLeft: "1em",
              whiteSpace: "pre-line",
              maxHeight: "30vh",
              overflowY: "auto",
              backgroundColor: "#fff",
            }}
          >
            {basicCardProps.longSnippet}
          </div>
        )}
        {!showPreview && (
          <div className={jsonStyle.jsonViewer}>
            <JsonView src={props.snippet} collapseStringsAfterLength={5000} />
          </div>
        )}
      </CardPreview>
    </Card>
  );
};
