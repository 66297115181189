"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRestrictedSharingUser = exports.isVendorUser = void 0;
const userSharingBlockerHelper_1 = require("./userSharingBlockerHelper");
const isValidString = (param) => {
    return (typeof param === "string" &&
        param.trim() !== "" &&
        param.trim().toLocaleUpperCase() !== "N/A");
};
const splitUpperCaseString = (input) => {
    return input.toLocaleUpperCase().split(/[-_;.,&/ ]+/);
};
/**
 * return the user is a vendor user or not
 * @param userAlias
 * @returns true if the user is a vendor user
 */
const isVendorUser = (userAlias) => {
    return userSharingBlockerHelper_1.VendorUserAliasPrefixes.some((prefix) => userAlias.startsWith(prefix));
};
exports.isVendorUser = isVendorUser;
/**
 * For L68+(include 68) and vendor users, we restrict them from sharing data and creats SBS experiment.
 * @param userAlias
 * @param response
 * @returns true if the user is restricted from sharing data and creating SBS experiment
 */
const isRestrictedSharingUser = (userAlias, response) => {
    if ((0, exports.isVendorUser)(userAlias)) {
        return true;
    }
    // TODO: remove bypass for empty level after the user profile service is ready to get level
    if (!isValidString(response.jobTitle) ||
        (response.level !== "" && !isValidString(response.level)) ||
        !isValidString(response.department)) {
        return true;
    }
    // block user if the user is above level 6
    if (!(+response.level <= 6)) {
        return true;
    }
    // block user if the user's job title has keyword in the blocked list
    const splitJobTitle = splitUpperCaseString(response.jobTitle);
    const isTitleInBlockList = splitJobTitle.some((jobTitleString) => {
        return userSharingBlockerHelper_1.BlockedTitle.includes(jobTitleString);
    });
    if (isTitleInBlockList) {
        return true;
    }
    // block user if the user's department contains phrase substring in the blocked list
    const isDepartmentContainingBlockPhrase = userSharingBlockerHelper_1.BlockedDepartmentPharse.some((blockPhrase) => {
        return response.department.toLocaleUpperCase().includes(blockPhrase);
    });
    if (isDepartmentContainingBlockPhrase) {
        return true;
    }
    // block user if the user's department has keyword in the blocked list
    const splitDepartment = splitUpperCaseString(response.department);
    const isDepartmentContainingBlockKeyword = splitDepartment.some((departmentString) => {
        return userSharingBlockerHelper_1.BlockedDepartmentKeyword.includes(departmentString);
    });
    if (isDepartmentContainingBlockKeyword) {
        return true;
    }
    return false;
};
exports.isRestrictedSharingUser = isRestrictedSharingUser;
