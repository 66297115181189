import { bool, object, optional } from "sydneyeval-shared";

export const Permission = object({
  isAdmin: optional(bool),
  isDRI: optional(bool),
  isDev: optional(bool),
  isDogfooder: optional(bool),
  isBingDev: optional(bool),
  isSydneyDev: optional(bool),
  isUser: optional(bool),
  isScientist: optional(bool),
  isBingAdmin: optional(bool),
  isQueryAdmin: optional(bool),
  isProductAdmin: optional(bool),
  isSchedulerManager: optional(bool),
});

export type Permission = ReturnType<typeof Permission>;
