import {
  Field,
  Textarea,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { updateDataSetsStringAction } from "../../../actions/jobActions";
import { getJobCreationError } from "../../../selectors/creationError/getJobCreationError";
import { jobStore } from "../../../store/jobStore";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    dataSetsPreviewer: {
      height: "100px",
      "> span": {
        ...shorthands.flex(1),
      },
      "> textarea": {
        maxHeight: "inherit",
      },
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobDataSetView = observer(() => {
  const styles = useStyles();

  if (
    jobStore.selectedTemplate?.Datasets === null ||
    jobStore.selectedTemplate?.Datasets === "null" ||
    jobStore.selectedTemplate?.Datasets === undefined ||
    jobStore.selectedTemplate?.Datasets.trim() === ""
  ) {
    return <></>;
  }

  const datasets = jobStore.selectedTemplate.Datasets;
  return (
    <div className={styles.block}>
      <Field
        required
        label={<span className={styles.blockTitle}>DataSets</span>}
        validationState={getJobCreationError.get().dataSetsError.state}
        validationMessage={getJobCreationError.get().dataSetsError.message}
      >
        <Textarea
          aria-label="DataSets editor"
          defaultValue={datasets}
          className={styles.dataSetsPreviewer}
          value={jobStore.dataSetsString}
          onChange={(_, data) => {
            updateDataSetsStringAction(data.value);
          }}
        />
      </Field>
    </div>
  );
});
