import { makeStyles, tokens } from "@fluentui/react-components";

export const useStyles = makeStyles({
  // overwrite all v8 labels to v9 style
  label: {
    fontWeight: "500",
    display: "block",
    marginBottom: ".3rem",
    marginTop: ".3rem",
  },

  // replace Stack in v8 -> div with styles
  stackVertical: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    width: "auto",
    height: "auto",
    boxSizing: "border-box",
    "> *": {
      textOverflow: "ellipsis",
    },
    "> :not(:first-child)": {
      marginTop: "0px",
    },
    "> *:not(.ms-StackItem)": {
      flexShrink: 1,
    },
  },
  stackVerticalWithGap: {
    "> :not(:last-child)": {
      marginBottom: ".5em",
    },
  },
  stackHorizontal: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "auto",
    height: "auto",
    boxSizing: "border-box",
    "> *": {
      textOverflow: "ellipsis",
    },
    "> :not(:first-child)": {
      marginTop: "0px",
    },
    "> *:not(.ms-StackItem)": {
      flexShrink: 1,
    },
  },
  stackHorizontalWithGap: {
    "> :not(:last-child)": {
      marginRight: "1em",
    },
  },
  treeIcon: {
    color: tokens.colorNeutralForeground2BrandHover,
  },
  treeIconOwn: {
    color: tokens.colorPaletteGreenForeground1,
  },
});
