import type { IPublicClientApplication } from "@azure/msal-browser";
import { action } from "satcheljs";

export const logoutAction = action(
  "logoutAction",
  (instance: IPublicClientApplication, actionType: "popup" | "redirect") => ({
    instance,
    actionType,
  }),
);
