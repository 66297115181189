import { makeStyles } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { JobCreationViewContent } from "./JobCreationViewContent";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  },
});

export const JobCreationView = observer(() => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <JobCreationViewContent />
    </div>
  );
});
