import {
  AirplaneTakeOff16Filled,
  AirplaneTakeOff16Regular,
  Flag16Filled,
  Flag16Regular,
} from "@fluentui/react-icons";
import { merge } from "lodash";
import { computed } from "mobx";
import type { ClientFeatureFlag } from "sydneyeval-shared";
import { store } from "../store/store";

const features = computed(() => {
  // Feature priority: overrides > flights > default
  return merge(
    {},
    store.features.default,
    store.features.flights,
    store.features.overrides,
  );
});

export const isFeatureEnabled = (feature: ClientFeatureFlag) => {
  return features.get()[feature] === true;
};

type FeatureFlagStatus =
  | "Default"
  | "FlightToON"
  | "FlightToOFF"
  | "OverrideToON"
  | "OverrideToOFF";

export const getFeatureFlagStatus = (
  feature: ClientFeatureFlag,
): FeatureFlagStatus => {
  if (
    store.features.overrides[feature] !== undefined &&
    store.features.overrides[feature] === true
  ) {
    return "OverrideToON";
  }

  if (
    store.features.overrides[feature] !== undefined &&
    store.features.overrides[feature] === false
  ) {
    return "OverrideToOFF";
  }

  if (
    store.features.flights[feature] !== undefined &&
    store.features.flights[feature] === true
  ) {
    return "FlightToON";
  }

  if (
    store.features.flights[feature] !== undefined &&
    store.features.flights[feature] === false
  ) {
    return "FlightToOFF";
  }

  return "Default";
};

export const getFeatureFlagStatusIcon = (feature: ClientFeatureFlag) => {
  switch (getFeatureFlagStatus(feature)) {
    case "Default":
      return <></>;
    case "FlightToON":
      return <AirplaneTakeOff16Filled />;
    case "FlightToOFF":
      return <AirplaneTakeOff16Regular />;
    case "OverrideToON":
      return <Flag16Filled />;
    case "OverrideToOFF":
      return <Flag16Regular />;
  }
};

export const getFeatureFlagsJSON = () => {
  return JSON.stringify(features.get());
};
