import { computed } from "mobx";
import {
  mergeBingQuerySets,
  mergeMChatQuerySets,
} from "../helpers/querySetHelper";
import { QuerySetTableFilter } from "../models/QuerySets";
import { querySetsStore } from "../store/querySetsStore";

export const getQuerySets = computed(() => {
  switch (querySetsStore.selectedTab) {
    case QuerySetTableFilter.all:
      return mergeMChatQuerySets(
        querySetsStore.MChatPublicQuerySets,
        querySetsStore.MChatCustomQuerySets,
        querySetsStore.MChatGeneratedQuerySets,
      );

    case QuerySetTableFilter.shared:
      return mergeMChatQuerySets(querySetsStore.MChatPublicQuerySets);

    case QuerySetTableFilter.myQuerySets:
      return mergeMChatQuerySets(
        [],
        querySetsStore.MChatCustomQuerySets,
        querySetsStore.MChatGeneratedQuerySets,
      );

    case QuerySetTableFilter.myBingQuerySets:
      return mergeBingQuerySets(
        querySetsStore.BingCustomQuerySets,
        querySetsStore.BingGeneratedQuerySets,
      );
  }
});
