export const ExpandIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.14645 3.64645C1.34171 3.45118 1.65829 3.45118 1.85355 3.64645L5 6.79289L8.14645 3.64645C8.34171 3.45118 8.65829 3.45118 8.85355 3.64645C9.04882 3.84171 9.04882 4.15829 8.85355 4.35355L5.35355 7.85355C5.15829 8.04882 4.84171 8.04882 4.64645 7.85355L1.14645 4.35355C0.951184 4.15829 0.951184 3.84171 1.14645 3.64645Z"
        fill="#605E5C"
      />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.08859 4.21569L4.14645 4.14645C4.32001 3.97288 4.58944 3.9536 4.78431 4.08859L4.85355 4.14645L10 9.293L15.1464 4.14645C15.32 3.97288 15.5894 3.9536 15.7843 4.08859L15.8536 4.14645C16.0271 4.32001 16.0464 4.58944 15.9114 4.78431L15.8536 4.85355L10.707 10L15.8536 15.1464C16.0271 15.32 16.0464 15.5894 15.9114 15.7843L15.8536 15.8536C15.68 16.0271 15.4106 16.0464 15.2157 15.9114L15.1464 15.8536L10 10.707L4.85355 15.8536C4.67999 16.0271 4.41056 16.0464 4.21569 15.9114L4.14645 15.8536C3.97288 15.68 3.9536 15.4106 4.08859 15.2157L4.14645 15.1464L9.293 10L4.14645 4.85355C3.97288 4.67999 3.9536 4.41056 4.08859 4.21569L4.14645 4.14645L4.08859 4.21569Z"
        fill="#292827"
      />
    </svg>
  );
};
