import { mutatorAction } from "satcheljs";
import type { RenderManifestItem } from "../../../constants/renderManifest";
import type { MetricsData } from "../helpers/metricsHelper";
import type {
  BingDiagnosisMetricsData,
  BingDiagnosisSBSView,
  BingDiagnosisSummary,
} from "../models/BingDiagnosisFile";
import type {
  BingControlVsTreatment,
  BingJobFile,
} from "../models/BingJobFile";
import type { Job } from "../models/Job";
import type { SBSLink } from "../models/JobSBSExpResult";
import type { ResultPageLoadingMessage } from "../orchestrators/resultOrchestrators";
import type { ResultScenarioType } from "../store/resultStore";
import { resultStore } from "../store/resultStore";
export const updateTemplateType = mutatorAction(
  "updateTemplateType",
  (templateType: string | undefined) => {
    resultStore.templateType = templateType;
  },
);

export const updateResultJob = mutatorAction(
  "updateResultJob",
  (job: Job | undefined) => {
    resultStore.resultJob = job;
  },
);

export const addLoadingMessage = mutatorAction(
  "addLoadingMessage",
  (message: ResultPageLoadingMessage) => {
    resultStore.loadingQueue.push(message);
  },
);

export const removeLoadingMessage = mutatorAction(
  "removeLoadingMessage",
  (message: ResultPageLoadingMessage) => {
    resultStore.loadingQueue = resultStore.loadingQueue.filter(
      (msg) => msg !== message,
    );
  },
);

export const switchScenario = mutatorAction(
  "switchScenario",
  (scenario: ResultScenarioType) => {
    resultStore.scenario = scenario;
  },
);

export const updateResultSegments = mutatorAction(
  "updateResultSegments",
  (segments: string[]) => {
    resultStore.resultSegments = segments;
  },
);

export const updateResultSourceMap = mutatorAction(
  "updateResultSourceMap",
  (sourceMap: Map<string, any>) => {
    resultStore.sourceMap = sourceMap;
  },
);

export const updateSBSLinks = mutatorAction(
  "updateSBSLinks",
  (links: SBSLink[] | undefined) => {
    resultStore.sbsLinks = links;
  },
);

export const updateManifest = mutatorAction(
  "updateManifest",
  (manifest: RenderManifestItem) => {
    resultStore.manifestItem = manifest;
  },
);

export const updateSelectedFilter = mutatorAction(
  "updateSelectedFilter",
  (filter: string, value: string | undefined) => {
    if (value) {
      resultStore.selectedFilter.set(filter, value);
    } else {
      resultStore.selectedFilter.delete(filter);
    }
  },
);

export const resetSelectedFilter = mutatorAction("resetSelectedFilter", () => {
  resultStore.selectedFilter = new Map<string, string | undefined>();
});

export const updateSummaryTable = mutatorAction(
  "updateSummaryTable",
  (table: any[]) => {
    resultStore.summaryTable = table;
  },
);

export const updateConciseTable = mutatorAction(
  "updateConciseTable",
  (table: any[]) => {
    resultStore.conciseTable = table;
  },
);

export const updateBingDiagnosisSummary = mutatorAction(
  "updateBingDiagnosisSummary",
  (table: BingDiagnosisSummary) => {
    resultStore.diagnosisSummary = table;
  },
);

export const updateBingJobOutputfiles = mutatorAction(
  "updateBingJobOutputfiles",
  (files: BingJobFile[] | undefined) => {
    resultStore.bingJobOutputFiles = files;
  },
);

export const updateSelectBingFile = mutatorAction(
  "updateSelectBingFile",
  (path: string | undefined) => {
    resultStore.seletedBingFile = path;
  },
);

export const updateDownloadedBingTables = mutatorAction(
  "updateDownloadedBingTables",
  (file: string, content: any[]) => {
    const newMap = new Map<string, any[]>(resultStore.downloadedBingTables);
    newMap.set(file, content);
    resultStore.downloadedBingTables = newMap;
  },
);

export const updateDiagnosisSummaries = mutatorAction(
  "updateDiagnosisSummaries",
  (file: string, content: BingDiagnosisSummary) => {
    const newMap = new Map<string, BingDiagnosisSummary>(
      resultStore.diagnosisSummaries,
    );
    newMap.set(file, content);
    resultStore.diagnosisSummaries = newMap;
  },
);

export const updateBingControlAndTreatment = mutatorAction(
  "updateBingControlAndTreatment",
  (CTs: BingControlVsTreatment[] | undefined) => {
    resultStore.controlTreatmentPairs = CTs;
  },
);

export const updateBingAnswerTriggerData = mutatorAction(
  "updateBingAnswerTriggerData",
  (data: any[] | undefined) => {
    resultStore.answerTriggerData = data;
  },
);

export const updateBingPluginTriggerData = mutatorAction(
  "updateBingPluginTriggerData",
  (data: any[] | undefined) => {
    resultStore.pluginTriggerData = data;
  },
);

export const updatePerfMetricsData = mutatorAction(
  "updatePerfMetricsData",
  (data: MetricsData[]) => {
    resultStore.perfMetrics = data;
  },
);

export const updateLoadedMetricData = mutatorAction(
  "updateLoadedMetricData",
  (key: string, value: BingDiagnosisMetricsData) => {
    resultStore.loadedMetrics.set(key, value);
  },
);

export const updateLoadedSBSView = mutatorAction(
  "updateLoadedSBSView",
  (key: string, value: BingDiagnosisSBSView) => {
    resultStore.loadedSBSViews.set(key, value);
  },
);

export const updateLoadedCitationLinks = mutatorAction(
  "updateLoadedCitationLinks",
  (
    dataset: string,
    controlTreatmentFilter: string,
    baselineCitationLinkMap: Map<string, Map<string, string>>,
    treatmentCitationLinkMap: Map<string, Map<string, string>>,
  ) => {
    resultStore.loadedCitationLinks.set(
      dataset + "_" + controlTreatmentFilter,
      {
        dataset: dataset,
        currentToneFilter: controlTreatmentFilter,
        baselineCitationMap: baselineCitationLinkMap,
        treatmentCitationMap: treatmentCitationLinkMap,
      },
    );
  },
);

export const updateDiagnosisSortBy = mutatorAction(
  "updateDiagnosisSortBy",
  (key: string | undefined) => {
    if (key) {
      resultStore.diagnosisSortBy = {
        column: key,
        direction: "asc",
      };
    } else {
      resultStore.diagnosisSortBy = undefined;
    }
  },
);

export const updateDiagnosisSortByOrder = mutatorAction(
  "updateDiagnosisSortByOrder",
  (order: "asc" | "desc", column: string) => {
    resultStore.diagnosisSortBy = {
      column: column,
      direction: order,
    };
  },
);
