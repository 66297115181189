export const TARGET_SYDNEY_SECRET =
  "resource=https://substrate.office.com/sydney&client_id=d3590ed6-52b3-4102-aeff-aad2292ab01c&grant_type=refresh_token&username={username}&refresh_token={search_refreshtoken}&scope=openid";

export const checkSydneyClientSecret = (
  value: string | undefined,
  path: string,
) => {
  if (value === undefined) {
    return undefined;
  }

  return value === TARGET_SYDNEY_SECRET
    ? undefined
    : {
        message: `Sydney client secret is invalid, the expected value is ${TARGET_SYDNEY_SECRET}`,
        path,
      };
};
