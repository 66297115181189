"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapOf = exports.strictObject = exports.object = exports.array = exports.anyObject = exports.none = exports.arrayBuffer = exports.unknown = exports.bool = exports.dateStr = exports.constStr = exports.num = exports.str = exports.nil = exports.or = exports.undefinedOr = exports.optional = void 0;
const optional = (guard) => {
    return (value, trace) => {
        try {
            return guard(value, trace);
        }
        catch {
            return undefined;
        }
    };
};
exports.optional = optional;
const undefinedOr = (guard) => {
    return (value, trace) => {
        if (value === undefined) {
            return undefined;
        }
        return guard(value, trace);
    };
};
exports.undefinedOr = undefinedOr;
const or = (guards) => {
    return (value, trace) => {
        for (let i = 0; i < guards.length; i++) {
            try {
                return guards[i](value, trace);
            }
            catch {
                continue;
            }
        }
        throw new Error(`[${trace}] Both type not match.`);
    };
};
exports.or = or;
const nil = (value, trace) => {
    if (value === null) {
        return null;
    }
    throw new Error(`[${trace}] Expected null, got ${value}`);
};
exports.nil = nil;
const str = (value, trace) => {
    if (typeof value === "string") {
        return value;
    }
    throw new Error(`[${trace}] Expected string, got ${value}`);
};
exports.str = str;
const num = (value, trace) => {
    if (typeof value !== "number") {
        throw new Error(`[${trace}] Expected number, got ${value}`);
    }
    if (isNaN(value)) {
        throw new Error(`[${trace}] Number is not a valid number. [NaN]`);
    }
    if (!isFinite(value)) {
        throw new Error(`[${trace}] Number is not a valid number. [Infinity]`);
    }
    return value;
};
exports.num = num;
const constStr = (constValue) => {
    return (value, trace) => {
        if ((0, exports.str)(value, trace) === constValue) {
            return constValue;
        }
        throw new Error(`[${trace}] Expected const string ${constValue}, got ${value}`);
    };
};
exports.constStr = constStr;
const dateStr = (value, trace) => {
    const inputStr = (0, exports.str)(value, trace);
    if (isNaN(new Date(inputStr).getTime())) {
        throw new Error(`[${trace}] Expected date string, got ${value}`);
    }
    return inputStr;
};
exports.dateStr = dateStr;
const bool = (value, trace) => {
    if (typeof value === "boolean") {
        return value;
    }
    throw new Error(`[${trace}] Expected bool, got ${value}`);
};
exports.bool = bool;
const unknown = (value) => {
    return value;
};
exports.unknown = unknown;
const arrayBuffer = (value, trace) => {
    if (value instanceof ArrayBuffer) {
        return value;
    }
    throw new Error(`[${trace}] Expected ArrayBuffer, got ${value}`);
};
exports.arrayBuffer = arrayBuffer;
const none = () => {
    return;
};
exports.none = none;
const anyObject = (value, trace) => {
    if (typeof value === "object" && value !== null) {
        return value;
    }
    throw new Error(`[${trace}] Expected any object, got ${value}`);
};
exports.anyObject = anyObject;
const array = (inner) => {
    return (value, trace) => {
        if (Array.isArray(value)) {
            return value.map((element, index) => inner(element, trace + `[${index}]`));
        }
        throw new Error(`[${trace}] Expected array, got ${value}`);
    };
};
exports.array = array;
const objectInternal = (inner, caseSensitive) => {
    return (value, trace) => {
        if (value === null) {
            throw new Error(`[${trace}] Expected object, got null`);
        }
        if (value === undefined) {
            throw new Error(`[${trace}] Expected object, got undefined`);
        }
        if (typeof value !== "object") {
            throw new Error(`[${trace}] Expected object, got ${value}`);
        }
        const out = {};
        const convertKey = (_) => (caseSensitive ? _ : _.toLowerCase());
        const get = (k, v) => {
            const key = Object.keys(v).find((_) => convertKey(_) === convertKey(k));
            return key ? v[key] : undefined;
        };
        const keys = Object.keys(inner);
        for (const k of keys) {
            out[k] = inner[k](get(k, value), trace + `.${k}`);
        }
        return out;
    };
};
const object = (inner) => {
    return objectInternal(inner, false);
};
exports.object = object;
const strictObject = (inner) => {
    return objectInternal(inner, true);
};
exports.strictObject = strictObject;
const mapOf = (inner) => {
    return (value, trace) => {
        if (value === null) {
            throw new Error(`[${trace}] Expected object, got null`);
        }
        if (value === undefined) {
            throw new Error(`[${trace}] Expected object, got undefined`);
        }
        if (typeof value !== "object") {
            throw new Error(`[${trace}] Expected object, got ${value}`);
        }
        const out = {};
        const entries = Object.entries(value);
        for (const [k, v] of entries) {
            out[k] = inner(v, trace + `.${k}`);
        }
        return out;
    };
};
exports.mapOf = mapOf;
