import { Button } from "@fluentui/react-button";
import {
  AddSquare24Regular,
  SubtractSquare24Regular,
} from "@fluentui/react-icons";
import * as React from "react";
import { useStyles } from "../styles";

interface Props {
  title: string;
  defaultCollapsed?: boolean;
  bold?: boolean;
  children: React.ReactNode;
  itemKey?: string;
  turnKey?: string;
  onClickExpand?: (
    itemKey: string,
    expanded: boolean,
    turnKey?: string,
  ) => void;
  thumbnail?: React.ReactNode;
}

export const CollapsedResource: React.FC<Props> = ({
  title,
  defaultCollapsed = true,
  bold = true,
  children,
  itemKey,
  turnKey,
  onClickExpand,
  thumbnail,
}) => {
  const [collapsed, setCollapsed] = React.useState(defaultCollapsed);
  React.useEffect(() => setCollapsed(defaultCollapsed), [title]);
  const styles = useStyles();

  return (
    <div>
      <div className={styles.stackHorizontal}>
        <Button
          appearance="transparent"
          icon={
            collapsed ? <AddSquare24Regular /> : <SubtractSquare24Regular />
          }
          style={{ fontWeight: bold ? "bold" : "normal" }}
          onClick={() => {
            onClickExpand?.(itemKey || "", collapsed, turnKey);
            setCollapsed(!collapsed);
          }}
        >
          {title}
        </Button>
        {thumbnail}
      </div>
      {!collapsed && (
        <div
          style={{
            paddingLeft: 8,
            borderBottom: "1px solid #eee",
            marginBottom: 16,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};
