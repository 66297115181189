import { computed } from "mobx";
import { jobPreValidationStore } from "../store/jobPreValidationStore";

export const getJobPreValidationSteps = computed(() => {
  return jobPreValidationStore.jobPreValidationSteps;
});

export const getJobPreValidationIsCopyLinkClicked = computed(() => {
  return jobPreValidationStore.isCopyLinkClicked;
});

export const getJobPreValidationToastTitle = computed(() => {
  return jobPreValidationStore.toastTitle;
});

export const getJobPreValidationToastBody = computed(() => {
  return jobPreValidationStore.toastBody;
});

export const getJobPreValidationProgressbarValue = computed(() => {
  return jobPreValidationStore.progressbarValue;
});

export const getJobPreValidationIsDispatched = computed(() => {
  return jobPreValidationStore.isDispatched;
});

export const getJobPreValidationIsFinishedVerifing = computed(() => {
  return jobPreValidationStore.isFinishedVerifing;
});
