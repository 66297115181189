import type { BizChatQuerySetValidationResult } from "../../../store/jobStore";

export const checkBizChatQuerySetHeader = (
  value: BizChatQuerySetValidationResult | undefined,
  path: string,
) => {
  if (value === undefined) {
    return undefined;
  }

  if (value.state === "error") {
    return { path, message: value.message ?? "Unknown error" };
  }

  return undefined;
};
