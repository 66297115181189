import { mutatorAction } from "satcheljs";
import type { PrivacyFilterTemplateContentArray } from "sydneyeval-shared";
import type { GeneralMetricsResponse } from "../models/GeneralMetricsResponse";
import type { LMChecklistAssertionsResponse } from "../models/LMChecklistAssertionsResponse";
import type { LMChecklistDetailsResponse } from "../models/LMChecklistDetailsResponse";
import type { MChatSValueResponse } from "../models/UtteranceResponse";
import type {
  MChatUtteranceMetrics,
  MChatUtteranceMetricsSingleData,
} from "../store/jobDetailStore";
import { jobDetailStore } from "../store/jobDetailStore";

export const updateGeneralMetricsResponse = mutatorAction(
  "updateGeneralMetricsResponse",
  (response: GeneralMetricsResponse | undefined) => {
    jobDetailStore.generalMetricsResponse = response;
  },
);

export const updateLMChecklistDetailsResponse = mutatorAction(
  "updateLMChecklistDetailsResponse",
  (response: LMChecklistDetailsResponse | undefined) => {
    jobDetailStore.lmchecklistDetailsResponse = response;
  },
);

export const updateLMChecklistAssertionsResponse = mutatorAction(
  "updateLMChecklistAssertionsResponse",
  (response: LMChecklistAssertionsResponse | undefined) => {
    jobDetailStore.lmchecklistAssertionsResponse = response;
  },
);

export const updatePrivacyFilterResponse = mutatorAction(
  "updatePrivacyFilterResponse",
  (response: PrivacyFilterTemplateContentArray | undefined) => {
    jobDetailStore.privacyFilterResponse = response;
  },
);

export const updateFilters = mutatorAction(
  "updateFilters",
  (selected: string[]) => {
    jobDetailStore.filters = selected;
  },
);

export const updateUtterNum = mutatorAction(
  "updateUtterNum",
  (uttrNum: number) => {
    jobDetailStore.utterNum = uttrNum;
  },
);

export const updateExpandIndex = mutatorAction(
  "updateExpandIndex",
  (index: number | undefined) => {
    jobDetailStore.expandedIndex = index;
  },
);

export const updatedMetricsToDisplay = mutatorAction(
  "updatedMetricsToDisplay",
  (res: string) => {
    jobDetailStore.metricsToDisplay = [...jobDetailStore.metricsToDisplay, res];
  },
);

export const updatedDebugLinkList = mutatorAction(
  "updatedDebugLinkList",
  (res: MChatSValueResponse) => {
    jobDetailStore.debuglinkList = res;
  },
);

export const updatedAnnotationDebuglinkList = mutatorAction(
  "updatedAnnotationDebuglinkList",
  (res: MChatSValueResponse) => {
    jobDetailStore.annotationDebuglinkList = res;
  },
);

export const updatedUtteranceList = mutatorAction(
  "updatedUtteranceList",
  (res: Map<string, MChatUtteranceMetrics>) => {
    jobDetailStore.utteranceList = res;
  },
);

export const updatedUtteranceDetail = mutatorAction(
  "updatedUtteranceDetail",
  (queryHash: string, res: MChatUtteranceMetricsSingleData[]) => {
    jobDetailStore.utteranceMetricDetail.set(queryHash, res);
  },
);

export const updatedselectedMChatMetrics = mutatorAction(
  "updatedselectedMChatMetrics",
  (selected: string[]) => {
    jobDetailStore.selectedMChatMetrics = selected;
  },
);
