import type { SinglePageFeatureContribution } from "../../contribution/FeatureContribution";
import { isFeatureEnabled } from "../../selectors/features";
import { ExperimentList } from "./components/ExperimentList";

export const shadowABContribution: SinglePageFeatureContribution = {
  type: "single-page",
  name: "ShadowAB",
  isEnabled: () => isFeatureEnabled("shadowAB"),
  defaultPath: "/shadowab",
  pathRegistry: {
    "/shadowab": {
      render: () => <ExperimentList />,
    },
  },
};
