import { compact } from "lodash";
import { orchestrator } from "satcheljs";
import { uniqByObject } from "sydneyeval-shared";
import { getSubstrateToken } from "../../../helpers/accessTokenHelper";
import {
  addJobPermission,
  getJobPermission,
  getSevalUserList,
  searchSGById,
} from "../../../helpers/apiHelper";
import { getRandomUUID } from "../../../helpers/getRandomUUID";
import {
  inviteUsersAction,
  openJobShareDialog,
} from "../actions/jobShareActions";
import { getUserProfileResponse } from "../helpers/getUserProfileResponseHelper";
import {
  setIsOpenShareDialog,
  updateJobPermission,
  updateJobShareInvalidErrorMessage,
  updateSevalUserList,
  updateShareIsLoading,
  updateUserProfile,
} from "../mutators/jobShareMutators";
import {
  getCurrentScenario,
  getSharedRecordId,
} from "../selectors/getJobShareInfo";
import { jobShareStore } from "../store/jobShareStore";
import type { SubstrateSingleUserInfor } from "../../../models/SearchUserByIdResponse";

const checkCurrentPermission = (jobId: string | undefined) => {
  if (jobId === undefined) {
    return Promise.resolve(undefined);
  }
  return getJobPermission({
    JobId: jobId,
  });
};
const convertDisplayUser = async (
  oid: string,
  userlist: SubstrateSingleUserInfor[],
) => {
  const validUser = userlist.find((item) => item.ObjectId === oid);
  if (validUser) {
    return validUser;
  }
  try {
    const validSG = await getSubstrateToken().then((_) => {
      return searchSGById({
        SubstrateToken: _,
        CallerFileNameLine: "ShareButton",
        ClientRequestId: getRandomUUID(),
        ProcessName: "SEVAL",
        groupId: oid,
      });
    });
    return validSG ?? undefined;
  } catch {
    return undefined;
  }
};

const getCurrentPermissionList = (
  objectIds: string[] | undefined,
  sevaluserlist: SubstrateSingleUserInfor[],
) => {
  const validArray =
    objectIds ?? jobShareStore.permissionList.map((item) => item.ObjectId);
  const convertedArray = validArray.map((item) =>
    convertDisplayUser(item, sevaluserlist),
  );
  return convertedArray;
};

orchestrator(openJobShareDialog, () => {
  updateJobShareInvalidErrorMessage(undefined);
  updateShareIsLoading(true);
  const recordId = getSharedRecordId.get();
  // 1. check current user has share permission
  // 2. get sevel user list
  Promise.all([
    checkCurrentPermission(recordId),
    getUserProfileResponse(),
    getSubstrateToken().then((_) => {
      return getSevalUserList({
        SubstrateToken: _,
        CallerFileNameLine: "ShareButton",
        ClientRequestId: getRandomUUID(),
        ProcessName: "SEVAL",
      });
    }),
  ])
    .then(([jobPermission, userProfile, userList]) => {
      const jobPermissionToUserList = getCurrentPermissionList(
        jobPermission,
        userList.userlist ?? [],
      );
      Promise.all(jobPermissionToUserList).then((result) => {
        const filteredJobPermission = compact(
          result.filter((item) => item !== undefined),
        );
        updateJobPermission(filteredJobPermission);
      });
      updateUserProfile(userProfile);
      updateSevalUserList(uniqByObject(userList.userlist ?? []) ?? []);
    })
    .finally(() => {
      updateShareIsLoading(false);
    });
  // 3. open dialog
  setIsOpenShareDialog(true);
});

orchestrator(inviteUsersAction, () => {
  const recordId = getSharedRecordId.get();
  updateShareIsLoading(true);
  const newUserList = jobShareStore.selectUser;
  const newPermissionList = jobShareStore.permissionList.concat(newUserList);

  const currentScenario = getCurrentScenario();
  if (currentScenario === "create") {
    const cachedSharedList = uniqByObject(newPermissionList);
    updateJobPermission(cachedSharedList);
    updateShareIsLoading(false);
  } else {
    if (recordId === undefined) {
      updateShareIsLoading(false);
      return;
    }
    const newUserIdList = newUserList.map((item) => item.ObjectId);
    addJobPermission({
      JobId: recordId,
      UserIds: newUserIdList,
    })
      .then(() => {
        updateJobPermission(newPermissionList);
        updateShareIsLoading(false);
      })
      .catch(() => {
        updateShareIsLoading(false);
      });
  }
});
