import { array, num, object, optional, str } from "sydneyeval-shared";

// New APIs
export const MChatMetricsByMetrics = object({
  segment: str,
  metric_key: optional(str),
  exp_name: optional(str),
  value: optional(num),
  query: str,
  query_hash: str,
});

export const MChatMetricsByHash = object({
  segment: str,
  metric_key: str,
  exp_name: str,
  value: num,
});

export const MChatSvalue = object({
  query: str,
  metric_key: str,
  exp_name: str,
  value: str,
});

export const MChatMetricsByMetricsResponse = array(MChatMetricsByMetrics);
export const MChatMetricsByHashResponse = array(MChatMetricsByHash);
export const MChatSValueResponse = array(MChatSvalue);

export type MChatMetricsByMetricsResponse = ReturnType<
  typeof MChatMetricsByMetricsResponse
>;

export type MChatMetricsByHashResponse = ReturnType<
  typeof MChatMetricsByHashResponse
>;

export type MChatSValueResponse = ReturnType<typeof MChatSValueResponse>;
