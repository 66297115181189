import {
  Button,
  InfoLabel,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import {
  getBingJobRunMode,
  getBingJobTestSetsList,
} from "../../../selectors/getJobPropV2";
import { jobCreationStore } from "../../../store/jobCreationStore";
import { JobClusterView } from "./JobClusterView";
import { JobEnginesView } from "./JobEnginesView";
import { JobScenarioView } from "./JobScenarioView";
import { JobTestSetBlockView } from "./JobTestSetBlockView";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    columnWithSmallerGap: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      ...shorthands.gap("8px"),
    },
    rowWithSmallGap: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      ...shorthands.gap("16px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobBingView = observer(() => {
  const styles = useStyles();

  if (jobCreationStore.bingMetaData === undefined) {
    return <></>;
  }

  const metaData = jobCreationStore.bingMetaData;
  const runMode = getBingJobRunMode.get();

  return (
    <div className={styles.columnWithSmallerGap}>
      <JobClusterView />
      <JobScenarioView metadata={metaData} />
      <div className={styles.block}>
        <InfoLabel className={styles.blockTitle}>Run Type</InfoLabel>
        <div className={styles.rowWithSmallGap}>
          <Button
            appearance={runMode === "single_turn" ? "primary" : "outline"}
            shape="square"
            onClick={() => {
              const testSets = getBingJobTestSetsList.get();
              updatePropValueActionV2({
                prop: "single_turn",
                newData:
                  testSets === undefined
                    ? undefined
                    : Object.fromEntries(
                        Object.entries(testSets).map(([key, value]) => [
                          key,
                          {
                            ...value,
                            metrics: value.metrics?.filter(
                              (_) => metaData.metrics.singleturn?.includes(_),
                            ),
                          },
                        ]),
                      ),
              });
              updatePropValueActionV2({
                prop: "multi_turn",
                newData: undefined,
              });
            }}
          >
            Single Turn
          </Button>
          <Button
            appearance={runMode === "multi_turn" ? "primary" : "outline"}
            shape="square"
            onClick={() => {
              const testSets = getBingJobTestSetsList.get();
              updatePropValueActionV2({
                prop: "multi_turn",
                newData:
                  testSets === undefined
                    ? undefined
                    : Object.fromEntries(
                        Object.entries(testSets).map(([key, value]) => [
                          key,
                          {
                            ...value,
                            metrics: value.metrics?.filter(
                              (_) => metaData.metrics.multiturn?.includes(_),
                            ),
                          },
                        ]),
                      ),
              });

              updatePropValueActionV2({
                prop: "single_turn",
                newData: undefined,
              });
            }}
          >
            Multi Turn
          </Button>
        </div>
        <JobTestSetBlockView metadata={metaData} />
        <JobEnginesView />
      </div>
    </div>
  );
});
