import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import LLMJudge from "./LLMJudge";
import { TaskAnnotationMainLayout } from "./TaskAnnoationMainLayout";
import { LLMJudgeEvaluationTaskDashboard } from "./evaluationTaskDashboard/LLMJudgeEvaluationTaskDashboard";
import { LLMJudgeEvaluationTaskDetail } from "./evaluationTaskDashboard/LLMJudgeEvaluationTaskDetailPage";
import { useEffect } from "react";

export const HumanCorrelationStudyEntry = observer(() => {
  useEffect(() => {
    document.title = "Human Correlation Study";
  }, []);

  return (
    <Routes>
      <Route
        path="task/detail/:taskId"
        element={<LLMJudgeEvaluationTaskDetail />}
      />
      <Route path="task/detail" element={<LLMJudgeEvaluationTaskDetail />} />
      <Route
        path="task/debuggingMode"
        element={<LLMJudgeEvaluationTaskDashboard debuggingMode={true} />}
      />
      <Route path="task" element={<LLMJudgeEvaluationTaskDashboard />} />
      <Route path="annotation/:taskId" element={<TaskAnnotationMainLayout />} />
      <Route path="annotation" element={<TaskAnnotationMainLayout />} />
      <Route path="*" element={<LLMJudge />} />
    </Routes>
  );
});
