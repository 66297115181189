export const checkBingExpOptionSet = (
  value: string | undefined,
  path: string,
) => {
  if (value === undefined) {
    return {
      path,
      message: "Bing OptionSet should not be undefined!",
    };
  }

  if (value === "") {
    return { path, message: "Bing OptionSet should not be empty!" };
  }

  return undefined;
};
