import {
  checkValidAvalonSydneyURL,
  checkVariantsForTemporalQueries,
} from "../../../helpers/formatHelper";
import { getEvalTimeOverride } from "../../getJobPropV2";

export const checkEvalTimeOverride = (
  value: string | undefined,
  path: string,
) => {
  if (path.includes("eval_time_override")) {
    return checkEvalTimeOverrideDate(value, path);
  } else if (path.includes("url")) {
    return checkEvalTimeOverrideUrl(value, path);
  } else if (path.includes("variants")) {
    return checkEvalTimeOverrideVariants(value, path);
  }

  return undefined;
};

export const checkEvalTimeOverrideDate = (
  value: string | undefined,
  path: string,
) => {
  if (value === "") {
    return {
      path,
      message: "Date should not be empty!",
    };
  }

  return undefined;
};

export const checkEvalTimeOverrideUrl = (
  value: string | undefined,
  path: string,
) => {
  if (
    getEvalTimeOverride.get() !== undefined &&
    !checkValidAvalonSydneyURL(value)
  ) {
    return {
      path,
      message:
        "Avalon endpoint (https://substrate.office.com/m365Copilot) should be used to override eval time!",
    };
  }

  return undefined;
};

export const checkEvalTimeOverrideVariants = (
  value: string | undefined,
  path: string,
) => {
  if (
    getEvalTimeOverride.get() !== undefined &&
    !checkVariantsForTemporalQueries(value)
  ) {
    const temporalVariants = [
      "feature.IsFullDebug3SEnabled",
      "feature.EnableUseRelativeDateTimeForSearchResults",
    ];
    return {
      path,
      message: `Temporal query variants (${temporalVariants.join(
        ", ",
      )}) should be used when overriding eval time!`,
    };
  }

  return undefined;
};
