import {
  Button,
  Checkbox,
  Label,
  MessageBar,
  MessageBarBody,
  Spinner,
  Textarea,
  Tooltip,
} from "@fluentui/react-components";
import { Copy24Regular, Info24Regular } from "@fluentui/react-icons";
import Papa from "papaparse";
import { useRef, useState } from "react";
import { store } from "../../../store/store";
import { CreateTaskConsentText } from "./ConsentConstants";
import { GetSimplifiedRow } from "./ContentPanel";
import { type IBasicRow } from "./bizChatEvalDataProvider";
import { type IFeedback } from "./evaluationTaskDashboard/models/LLMJudgeEvaluationTaskData";
import {
  SaveCreateTaskInfo,
  SaveFeedback,
} from "./evaluationTaskDashboard/sydneyEvalManagement";
import { TaskType } from "./models/TaskType";
import { concatUpn } from "./utils/UpnHelper";
import { getUserAlias } from "./utils/utilities";

export const ShareTaskDashboard = (props: {
  metricType: string;
  csvString: string;
  feedbackRows: IBasicRow<object>[]; // Feedbacks to be saved after task creation
}) => {
  const defaultTaskName = useRef(
    `${getUserAlias()}_${props.metricType}_${new Date()
      .toISOString()
      .replace(/:/g, "-")}`,
  );

  const [allowAnyoneToLabel, setAllowAnyoneToLabel] = useState(true);
  const [loading, setLoading] = useState(false);
  const [finish, setFinish] = useState(false); // whether the task is created
  const [url, setUrl] = useState("");
  const [newTaskId, setNewTaskId] = useState("");
  const [error, setError] = useState("");
  const [taskName, setTaskName] = useState(defaultTaskName.current);

  const labelContributorList = useRef("");

  async function createTask() {
    // convert props.csvString to file
    const blob = new Blob([props.csvString], { type: "text/csv" });
    const file = new File([blob], "conversation.csv");

    const urlParameters =
      props.metricType === "GroundLeoClaimBreak"
        ? "showVerifiableExternallyOption=true"
        : undefined;
    setLoading(true);
    setError("");
    try {
      const newTaskId = (await SaveCreateTaskInfo(
        file,
        "",
        taskName,
        "",
        props.metricType,
        "0",
        "0",
        "",
        store.account?.username || "",
        allowAnyoneToLabel,
        labelContributorList.current,
        urlParameters,
        TaskType.Collection,
      )) as string; // /EvaluationTask/Shared only returns taskId

      if (!newTaskId) {
        throw new Error("Failed to create task");
      }

      // Save feedbacks for the new task
      if (props.feedbackRows.length > 0) {
        const feedbacks = props.feedbackRows.map((row) => {
          const feedback: IFeedback = {
            ConversationId: undefined, // no EvaluationConversation table for shared task
            TaskId: newTaskId,
            Type: props.metricType,
            Feedback: Papa.unparse([GetSimplifiedRow(row)]),
            FeedbackTime: new Date().toISOString(),
            FeedbackId: undefined, // new feedback record
            RawConversationId: row.ConversationId,
          };
          return feedback;
        });
        await SaveFeedback(feedbacks);
      }
      const taskUrl = `${window.location.origin}/llmjudge/annotation?taskId=${newTaskId}`;
      setUrl(taskUrl);
      setNewTaskId(newTaskId);
      setFinish(true);
    } catch (e: any) {
      setError(e.toString());
    }
    setLoading(false);
  }

  return (
    <>
      <div style={{ color: "blue" }}>
        Your saved feedbacks will also be shared.
      </div>
      <div style={{ display: "flex", marginTop: "0.5em" }}>
        <Label style={{ width: "18em" }}>Task Name</Label>
        <Textarea
          disabled={loading || finish}
          style={{ width: "15em" }}
          defaultValue={defaultTaskName.current}
          onChange={(_e, newValue) => {
            setTaskName(newValue.value);
          }}
        />
      </div>
      <div style={{ display: "flex", marginTop: "0.5em" }}>
        <Label style={{ width: "18em" }}>
          Allow all SEVAL users to label shared conversations and see others'
          labels
        </Label>
        <Checkbox
          disabled={loading || finish}
          checked={allowAnyoneToLabel}
          onChange={(ev, data) => {
            setAllowAnyoneToLabel(!!data.checked);
            if (data.checked) {
              labelContributorList.current = "";
            }
          }}
          label=""
        />
      </div>

      {!allowAnyoneToLabel && (
        <>
          <div style={{ display: "flex", marginTop: "0.5em" }}>
            <div style={{ display: "flex", width: "18em" }}>
              <Label>Authorized Annotators</Label>
              <Tooltip
                content="Authorized annotators can annotate the task"
                relationship="description"
              >
                <Button
                  style={{ height: "1.4em" }}
                  icon={<Info24Regular />}
                  appearance="transparent"
                />
              </Tooltip>
            </div>
            <Textarea
              disabled={loading || finish}
              style={{ width: "15em" }}
              resize="vertical"
              placeholder="Input alias split by comma. e.g. aliasA,aliasB"
              onChange={(_e, newValue) => {
                labelContributorList.current = concatUpn(newValue.value);
              }}
            ></Textarea>
          </div>
        </>
      )}

      <div style={{ marginTop: ".5em", fontSize: "12px", color: "blue" }}>
        {CreateTaskConsentText}
      </div>

      <div style={{ display: "flex", marginTop: "1em" }}>
        <Button
          disabled={loading || finish}
          style={{ width: "12em", marginRight: "1em" }}
          onClick={createTask}
          appearance="primary"
        >
          Share
        </Button>
        {loading && (
          <div>
            Generating link...<Spinner></Spinner>
          </div>
        )}
        {finish && (
          <MessageBar intent="success">
            <MessageBarBody>Task created successfully!</MessageBarBody>
          </MessageBar>
        )}
        {error && (
          <MessageBar intent="error">
            <MessageBarBody>{error}</MessageBarBody>
          </MessageBar>
        )}
      </div>

      {url && (
        <>
          <div style={{ marginTop: "1em", color: "green", fontWeight: "bold" }}>
            Share link
          </div>
          <div style={{ display: "flex", marginTop: "0.5em" }}>
            <Textarea
              contentEditable={false}
              value={url || ""}
              style={{ width: "70%" }}
            ></Textarea>
            <Button
              icon={<Copy24Regular />}
              appearance="transparent"
              title="Copy task annotation URL to clipboard"
              onClick={() => {
                navigator.clipboard.writeText(url);
              }}
            ></Button>
          </div>
          You can edit metadata of the shared task on the{" "}
          <a
            target="_blank"
            href={`${window.location.origin}/llmjudge/task/detail/${newTaskId}`}
          >
            detail page
          </a>
        </>
      )}
    </>
  );
};
