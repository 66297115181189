import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { QueryGenerator } from "../QueryGenerator";
import {
  checkRequiredValues,
  getCurrentConfig,
  GetSimplifiedRow,
} from "../ContentPanel";
import type {
  IBasicRow,
  IConversation,
  IPluginItem,
  IPluginReasoning,
  IRowExtendedProperties,
  NDCGRow,
} from "../bizChatEvalDataProvider";
import type { MetricDefinition } from "../MetricDefinition";
import { getUrlParameterValue, getUserAlias } from "../utils/utilities";
import type { LLMJudgeEvaluationTaskData } from "../evaluationTaskDashboard/models/LLMJudgeEvaluationTaskData";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Divider,
  Field,
  Label,
  makeStyles,
  Radio,
  RadioGroup,
  Textarea,
  tokens,
} from "@fluentui/react-components";
import { Button } from "@fluentui/react-button";
import {
  CheckmarkCircle20Regular,
  ChevronDown24Regular,
  ChevronUp24Regular,
} from "@fluentui/react-icons";
import { useStyles } from "../styles";
import { RenderConversation } from "../groundleo/GroundLeoDefinition";
import SplitView from "../sharedComponents/SplitView";
import {
  CollapseButtons,
  type CollapseButtonItemProps,
} from "./components/CollapseButtons";
import { ndcgStore } from "./ndcgStore";
import { ResourceCard } from "../conversation/ResourceCard";
import type { AccordionItemValue } from "@fluentui/react-accordion";

type NDCGPageProps<TRow extends IRowExtendedProperties<TRow>> = {
  debuggingMode: boolean;
  expandedClaims?: Set<string>;
  token?: string;
  allowScraping?: boolean;
  forceInputVariants: boolean;
  metricDefinition: MetricDefinition<TRow>;
  task?: LLMJudgeEvaluationTaskData;
  currentRow?: TRow;
  controlVariants: string;
  saveFeedbackTrigger: number;
};

const ndcgStyles = makeStyles({
  resourceItem: {
    margin: "1em",
    transition: "background-color 0.3s ease",
    ":hover": {
      backgroundColor: "rgba(224, 248, 232, 1)",
    },
  },
});

export type IPluginItemWithStatus = IPluginItem & {
  is_edit?: boolean;
};

const NDCGPage = <TRow extends IBasicRow<TRow>>(
  props: NDCGPageProps<NDCGRow>,
) => {
  const styles = ndcgStyles();

  const urls = [window.location.search, props.task?.url_parameters ?? ""];
  const forceInputVariants =
    getUrlParameterValue(urls, "forceInputVariants", "false") === "true";
  const callAvalonAutomatically =
    getUrlParameterValue(urls, "callAvalonAutomatically", "false") === "true";
  const commonStyles = useStyles();

  const [showRawData, setShowRawData] = useState<boolean>(false);

  const [iterationCount, setIterationCount] = useState<number>(0);
  const [plugins, setPlugins] = useState<IPluginReasoning[]>([]);
  const [resourceList, setResourceList] = useState<IPluginItemWithStatus[]>([]);

  const [selectedIterationIndex, setSelectedIterationIndex] = useState<
    number | undefined
  >(undefined);

  const [selectedPlugin, setSelectedPlugin] = useState<
    IPluginReasoning | undefined
  >(undefined);

  const [openedPluginKey, setOpenedPluginKey] = useState<
    string | AccordionItemValue
  >("");

  const [currentScrapingCount, setCurrentScrapingCount] = useState<number>(0);

  const currentRowRef = React.useRef(props.currentRow);
  const currentConversationRef = React.useRef<IConversation | undefined>(
    undefined,
  );

  useEffect(() => {
    currentRowRef.current = props.currentRow;
  }, [props.currentRow]);

  useEffect(() => {
    // customized the properties when saving feedback
    if (props.currentRow && props.saveFeedbackTrigger > 0) {
      props.currentRow.submitted = true;
      props.currentRow.last_labelled_by = getUserAlias();
    }
  }, [props.saveFeedbackTrigger]);

  const hasRequiredValues = () => {
    return checkRequiredValues(
      props.token,
      props.allowScraping,
      forceInputVariants,
      props.metricDefinition.name,
      props.controlVariants,
      undefined,
      callAvalonAutomatically,
    );
  };

  const resetConversation = () => {
    currentConversationRef.current = undefined;
    setIterationCount(0);
    setPlugins([]);
    setResourceList([]);
    ndcgStore.cleanCache();
  };

  const resetFeedback = (row: NDCGRow) => {
    row.submitted = undefined;
    row.last_labelled_by = undefined;
    row.has_plugins = false;
    row.label_ndcg = undefined;
  };

  const onConversationsChanged = (conversations: IConversation[]) => {
    // Fluent UI2 Accordion will always render RenderConversation when open and close the accordion, so need to check if need to re-render the page
    if (conversations.length > 0) {
      if (
        currentConversationRef.current?.ConversationId ===
        conversations[0].ConversationId
      ) {
        return;
      }

      currentConversationRef.current = conversations[0];
      ndcgStore.cleanCache();
      ndcgStore.setConversation(conversations[0]);
      const count = ndcgStore.getIterationCount(conversations[0]);
      setIterationCount(count);
      if (count > 0) {
        setSelectedIterationIndex(0);
      }
      const currentPlugins = ndcgStore.getPlugins(conversations[0], 0);
      setPlugins(currentPlugins ?? []);
      if (currentPlugins && currentPlugins.length > 0) {
        setSelectedPlugin(currentPlugins[0]);
        setResourceList(
          ndcgStore.getResources(props.currentRow, 0, currentPlugins[0]),
        );
        if (props.currentRow) {
          props.currentRow.has_plugins = true;
        }
      }
    } else if (
      currentRowRef.current?.ConversationId !== props.currentRow?.ConversationId
    ) {
      resetConversation();
    }
  };

  const renderContentArea = () => {
    return (
      <Accordion
        collapsible
        multiple
        defaultOpenItems={["SydneyReply", "PluginResources"]}
      >
        <AccordionItem value="SydneyReply">
          <AccordionHeader>
            <strong>Sydney Reply</strong>
          </AccordionHeader>
          <AccordionPanel>
            <Divider />
            <div style={{ marginLeft: "2em" }}>
              {props.allowScraping && props.currentRow && (
                <QueryGenerator
                  row={props.currentRow}
                  metricDefinition={props.metricDefinition}
                  triggerScraping={() => {
                    if (hasRequiredValues()) {
                      if (forceInputVariants) {
                        const config = getCurrentConfig(
                          props.currentRow,
                          props.controlVariants,
                        );
                        if (props.currentRow) {
                          props.currentRow.config = JSON.stringify(config);
                          // reset the feedback when we are trying to get new scraping data
                          resetFeedback(props.currentRow);
                        }
                      }
                      resetConversation();
                      setCurrentScrapingCount((pre) => pre + 1);
                    }
                  }}
                ></QueryGenerator>
              )}
              {props.currentRow && (
                <RenderConversation
                  key={props.currentRow.filledQuery || props.currentRow.query}
                  metricName={props.metricDefinition.name}
                  row={props.currentRow}
                  debuggingMode={props.debuggingMode}
                  expandedClaims={props.expandedClaims}
                  allowScraping={props.allowScraping}
                  token={props.token}
                  variants={props.controlVariants}
                  triggerScraping={currentScrapingCount}
                  onConversationsChanged={onConversationsChanged}
                />
              )}
              <div>
                <div
                  style={{
                    display: "flex",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <Label className={commonStyles.label}>Raw data</Label>
                  <Button
                    onClick={() => {
                      setShowRawData(!showRawData);
                    }}
                    icon={
                      showRawData ? (
                        <ChevronUp24Regular />
                      ) : (
                        <ChevronDown24Regular />
                      )
                    }
                    appearance="transparent"
                  ></Button>
                </div>
                {showRawData && props.currentRow && (
                  <props.metricDefinition.renderRawData
                    row={GetSimplifiedRow(props.currentRow) as TRow}
                  />
                )}
              </div>
            </div>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem value="PluginResources">
          <AccordionHeader>
            <strong>Resource List</strong>
          </AccordionHeader>
          <AccordionPanel>{renderResources()}</AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  };

  const renderIterations = () => {
    return new Array(iterationCount).fill(0).map((_, i) => {
      const item: CollapseButtonItemProps = {
        id: i.toString(),
        title: `Iteration ${i}`,
        onClick: () => {
          setSelectedIterationIndex(i);
          if (currentConversationRef.current !== undefined) {
            const currentPlugins = ndcgStore.getPlugins(
              currentConversationRef.current,
              i,
            );
            setPlugins(currentPlugins ?? []);
            setSelectedPlugin(currentPlugins?.[0]);
            updateResourceList(currentPlugins?.[0]);
          }
        },
      };
      return item;
    });
  };

  const updateResourceList = (plugin: IPluginReasoning | undefined) => {
    if (plugin === undefined) {
      setResourceList([]);
      setOpenedPluginKey("");
      return;
    }

    const resources = ndcgStore.getResources(
      props.currentRow,
      selectedIterationIndex,
      plugin,
    );
    setResourceList(resources);
    if (resources.length > 0) {
      // default to open the first one
      setOpenedPluginKey("pluginContent" + 0);
    }
  };

  const convertPlugins = () => {
    if (selectedIterationIndex === undefined) {
      return [];
    }
    return plugins.map((plugin) => {
      const title = plugin.PluginName;
      const item: CollapseButtonItemProps = {
        id: title,
        title: title,
        onClick: () => {
          setSelectedPlugin(plugin);
          updateResourceList(plugin);
        },
      };
      return item;
    });
  };

  const setLabel = (
    plugin: IPluginReasoning,
    index: number,
    key: string,
    label: string,
  ) => {
    ndcgStore.setResourceLabel(
      props.currentRow,
      selectedIterationIndex,
      plugin,
      index,
      key,
      label,
    );
    if (resourceList.length > index) {
      if (key === "comment" || label === "") {
        return;
      }
      resourceList[index].is_edit = true;
      setResourceList([...resourceList]);
    }
  };

  const getLabel = (index: number, key: string) => {
    return ndcgStore.getResourceLabel(
      props.currentRow,
      selectedIterationIndex,
      selectedPlugin,
      index,
      key,
    );
  };

  function renderResources() {
    if (resourceList.length === 0) {
      return <div key="ContentAreaNoResourceList">No resources</div>;
    }

    const query = selectedPlugin?.Query ?? "";
    return (
      <>
        {selectedPlugin !== undefined && (
          <div>
            <Divider />
            <div style={{ marginLeft: "2em" }}>
              {Object.entries(selectedPlugin).map(([key, value]) => {
                if (key === "Results") return null;
                if (!value) return null;
                const valueString = JSON.stringify(value);
                if (valueString.length > 200) {
                  return (
                    <div key={key}>
                      <b>{key}: </b>
                      {valueString.slice(0, 200)}...
                    </div>
                  );
                } else {
                  return (
                    <div key={key}>
                      <b>{key}: </b>
                      {valueString}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )}
        {selectedPlugin !== undefined && (
          <Accordion
            collapsible
            openItems={openedPluginKey}
            onToggle={(_, data) => {
              if (data.openItems.length > 0) {
                setOpenedPluginKey(data.openItems[0]);
              } else {
                setOpenedPluginKey("");
              }
            }}
          >
            {resourceList.map((resource, index) => (
              <AccordionItem
                value={"pluginContent" + index}
                key={"pluginContent" + index}
              >
                <AccordionHeader>
                  {ndcgStore.getResourceTitle(selectedPlugin, resource)}
                </AccordionHeader>
                <AccordionPanel className={styles.resourceItem}>
                  <ResourceCard
                    source="substrate"
                    snippet={resource}
                    index={index + 1}
                  ></ResourceCard>
                  <div style={{ marginTop: "1em", marginLeft: "2em" }}>
                    <Field
                      label={{
                        children: (
                          <span>
                            Is this document relevant to the iterative
                            query?&nbsp;
                            <strong>{query}</strong>
                          </span>
                        ),
                      }}
                    >
                      <RadioGroup
                        layout="horizontal"
                        key={`${selectedPlugin?.PluginName}-${index}-iterativeQueryRelevance`}
                        onChange={(_, data) =>
                          setLabel(
                            selectedPlugin,
                            index,
                            "iterativeQueryRelevance",
                            data.value,
                          )
                        }
                        value={getLabel(index, "iterativeQueryRelevance")}
                      >
                        <Radio value="Perfect" label="Perfect" />
                        <Radio value="Excellent" label="Excellent" />
                        <Radio value="Good" label="Good" />
                        <Radio value="Poor" label="Poor" />
                        <Radio value="Bad" label="Bad" />
                      </RadioGroup>
                    </Field>
                    <Field
                      label={{
                        children: (
                          <span>
                            Is this document relevant to the utterance?&nbsp;
                            <strong>{query}</strong>
                          </span>
                        ),
                      }}
                    >
                      <RadioGroup
                        layout="horizontal"
                        key={`${selectedPlugin?.PluginName}-${index}-utteranceQueryRelevance`}
                        onChange={(_, data) =>
                          setLabel(
                            selectedPlugin,
                            index,
                            "utteranceQueryRelevance",
                            data.value,
                          )
                        }
                        value={getLabel(index, "utteranceQueryRelevance")}
                      >
                        <Radio value="Perfect" label="Perfect" />
                        <Radio value="Excellent" label="Excellent" />
                        <Radio value="Good" label="Good" />
                        <Radio value="Poor" label="Poor" />
                        <Radio value="Bad" label="Bad" />
                      </RadioGroup>
                    </Field>
                    <Field style={{ margin: "1em 0" }} label="Comment">
                      <Textarea
                        resize="vertical"
                        onChange={(_, value) =>
                          setLabel(
                            selectedPlugin,
                            index,
                            "comment",
                            value.value,
                          )
                        }
                        value={getLabel(index, "comment")}
                      />
                    </Field>
                  </div>
                  <Divider style={{ marginTop: "1em" }} />
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </>
    );
  }

  const renderResourceThumbnail = () => {
    if (selectedPlugin === undefined) {
      return <div>No plugin selected</div>;
    }
    return resourceList.map((resource, index) => {
      return (
        <>
          <div
            key={`resourceList-${selectedIterationIndex}-${selectedPlugin?.PluginName}-${index}`}
            onClick={() => {
              setOpenedPluginKey("pluginContent" + index);
            }}
            style={{ display: "flex" }}
          >
            {resourceList[index].is_edit && (
              <div>
                <CheckmarkCircle20Regular
                  style={{
                    color: tokens.colorPaletteGreenForeground1,
                  }}
                />
              </div>
            )}
            {ndcgStore.getResourceTitle(selectedPlugin, resource)}
          </div>
          <hr />
        </>
      );
    });
  };

  const renderControlArea = () => {
    return (
      <>
        <h2>Iterations</h2>
        {iterationCount === 0 && <div>No iterations</div>}
        {iterationCount > 0 && (
          <CollapseButtons
            key={`${currentConversationRef.current?.ConversationId}`}
            items={renderIterations()}
          />
        )}
        <h2>Plugins</h2>
        {!plugins || (plugins.length === 0 && <div>No plugins</div>)}
        {plugins && plugins.length > 0 && (
          <>
            <CollapseButtons
              key={`${currentConversationRef.current?.ConversationId}-pluginList-${selectedIterationIndex}`}
              items={convertPlugins()}
            />
            <h3>Resource List</h3>
            {resourceList.length === 0 && (
              <div
                key={`resourceList-noResult-${selectedIterationIndex}-${selectedPlugin?.PluginName}`}
              >
                No resources
              </div>
            )}
            {resourceList.length > 0 && renderResourceThumbnail()}
          </>
        )}
      </>
    );
  };

  return (
    <SplitView
      left={renderContentArea()}
      right={renderControlArea()}
      orientation={"horizontal"}
    />
  );
};

export default observer(NDCGPage);
