import { action } from "satcheljs";
import type { ScheduledJob } from "sydneyeval-shared";

export const onScheduleJobAction = action(
  "onScheduleJobAction",
  (
    job: ScheduledJob | undefined,
    intent: "Create" | "Edit" | "ReadOnly" | "Stop" | "Enable",
  ) => ({
    job,
    intent,
  }),
);

export const searchScheduleJobsAcion = action(
  "searchScheduleJobsAcion",
  () => ({}),
);

export const updateSearchCriteriaAcion = action(
  "updateSearchCriteriaAcion",
  (
    key: "Product" | "Name" | "CreateBy" | "Status",
    value: string | undefined,
  ) => ({ key, value }),
);
