import {
  Button,
  Checkbox,
  Combobox,
  Field,
  Input,
  Label,
  Option,
  Subtitle2,
  makeStyles,
  shorthands,
  tokens,
  useId,
} from "@fluentui/react-components";
import React, { useEffect } from "react";

import type { InputProps } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import type { EditorRule } from "../../models/Types";
import { UploadingFileDialog } from "../Dialog/UploadingFileDialog";

interface JSONEditorViewProps {
  object: any | undefined;
  path: string;
  rules: EditorRule[];
  onChange: (event: any) => void;
}

interface IStatefulTextFieldProps {
  path: string;
  rules: EditorRule[];
  tag: string;
  value: number | string;
  onChange: (result: number | string) => void;
}

const isArray = (value: any): boolean => {
  return Array.isArray(value);
};

const isStringArray = (value: any): boolean => {
  const arrayData = Array.from(value);
  const allString = arrayData.filter((item) => typeof item !== "string");
  return allString.length === 0;
};

const isObject = (value: any): boolean => {
  return typeof value === "object" && value !== null && !Array.isArray(value);
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("2px"),
    ...shorthands.borderLeft("4px", "solid", tokens.colorBrandBackground2),
    paddingLeft: "10px",
    paddingRight: "10px",
    boxSizing: "border-box",
  },
  booleanConatiner: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  singleLineInput: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  checkbox: {
    width: "33.33%",
    maxWidth: "33.33%",
    wordBreak: "break-all",
    "> label": {
      height: "100%",
      boxSizing: "border-box",
      ...shorthands.margin("0"),
    },
  },
  title: {
    color: tokens.colorBrandForeground1,
  },
  object: {
    width: "100%",
  },
  array: {
    width: "100%",
  },
  arrayContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  arrayElement: {
    width: "50%",
  },
  listbox: {
    maxHeight: "400px",
  },
});

const FileSelectView = observer(
  (props: {
    rule: EditorRule;
    objectKey: string;
    object: any;
    onChange: (event: any) => void;
  }) => {
    const styles = useStyles();
    const { rule, objectKey, object, onChange } = props;
    const [isFileDialogOpen, setIsFileDialogOpen] = React.useState(false);
    const toast = useToast();

    const onFileUploadStart = () => {
      toast.onToastStart("Uploading file...");
    };

    const onFileUploadSuccess = () => {
      toast.onToastSuccess("File uploaded successfully");
    };

    const onFileUploadFailure = (error: Error) => {
      toast.onToastFailure(`File upload failed with message: ${error.message}`);
    };

    useEffect(() => {
      switch (rule.type) {
        case "file":
          onChange({ ...object, [objectKey]: rule.options[0] });
          break;
        default:
          break;
      }
    }, []);

    switch (rule.type) {
      case "ignore":
        return <></>;

      case "disable":
        return <></>;

      case "file":
        return (
          <Field label={objectKey} required style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "10px",
              }}
            >
              <Combobox
                style={{ flex: 1 }}
                listbox={{ className: styles.listbox }}
                value={object[objectKey]}
                selectedOptions={[object[objectKey]]}
                freeform={false}
                onOptionSelect={(_, data) => {
                  if (data.optionValue === undefined) {
                    return;
                  }
                  const newObj = { ...object, [objectKey]: data.optionValue };
                  onChange(newObj);
                }}
              >
                {rule.options.map((option) => (
                  <Option text={option} key={option}>
                    {option}
                  </Option>
                ))}
              </Combobox>
              <Button
                appearance="primary"
                onClick={() => {
                  setIsFileDialogOpen(true);
                }}
              >
                Upload File
              </Button>
              <UploadingFileDialog
                type="Query"
                isOpen={isFileDialogOpen}
                onCancel={() => setIsFileDialogOpen(false)}
                onStart={onFileUploadStart}
                onSuccess={(result) => {
                  const newObj = { ...object, [objectKey]: result };
                  onChange(newObj);
                  setIsFileDialogOpen(false);
                  onFileUploadSuccess();
                }}
                onFailure={(error) => {
                  setIsFileDialogOpen(false);
                  onFileUploadFailure(error);
                }}
              />
            </div>
          </Field>
        );
    }
  },
);

const handleRules = (
  path: string,
  rules: EditorRule[],
  objectKey: string,
  object: any,
  onChange: (event: any) => void,
): JSX.Element | undefined => {
  const rule = rules.find((rule) => rule.path === path);

  if (rule === undefined) {
    return undefined;
  }

  if (rule.type === "disable") {
    return undefined;
  }

  return <FileSelectView {...{ object, rule, objectKey, onChange }} />;
};

const SingleLineInputView = (props: IStatefulTextFieldProps) => {
  const styles = useStyles();
  const id = useId("input-medium");

  const [tempString, setTempString] = React.useState(`${props.value}`);

  useEffect(() => {
    setTempString(`${props.value}`);
  }, [props.value]);

  useEffect(() => {
    const number = Number(tempString);
    switch (typeof props.value) {
      case "string":
        props.onChange(tempString);
        return;

      case "number":
        if (tempString !== "" && !isNaN(number)) {
          props.onChange(number);
        }
    }
  }, [tempString]);

  const onChange: InputProps["onChange"] = (ev, data) => {
    setTempString(data.value);
  };

  const disabled =
    props.rules.find(
      (rule) => rule.path === props.path && rule.type === "disable",
    ) !== undefined;

  return (
    <div className={styles.singleLineInput}>
      <Label size="medium" htmlFor={id}>
        {props.tag}
      </Label>
      <Input
        size="medium"
        disabled={disabled}
        id={id}
        value={tempString}
        onChange={onChange}
      />
    </div>
  );
};

export const JSONEditorView = (props: JSONEditorViewProps) => {
  const styles = useStyles();

  if (props.object === undefined) {
    return <></>;
  }

  const object = props.object;
  const booleanKeys = Object.keys(props.object).filter(
    (key) => typeof object[key] === "boolean",
  );
  const stringKeys = Object.keys(props.object).filter(
    (key) => typeof object[key] === "string",
  );
  const numberKeys = Object.keys(props.object).filter(
    (key) => typeof object[key] === "number",
  );
  const objectKeys = Object.keys(props.object).filter((key) =>
    isObject(object[key]),
  );
  const arrayKeys = Object.keys(props.object).filter((key) =>
    isArray(object[key]),
  );

  const renderBoolean = () => {
    return booleanKeys.map((key) => {
      const value = Object.entries(props.object).find((_) => _[0] === key)?.[1];
      const path = props.path + "." + key;

      const node = handleRules(
        path,
        props.rules,
        key,
        props.object,
        props.onChange,
      );

      if (node !== undefined) {
        return node;
      }

      const rule = props.rules.find((_) => _.path === path);

      const isDisabled = rule !== undefined && rule.type === "disable";

      if (typeof value === "boolean") {
        return (
          <Checkbox
            key={key}
            className={styles.checkbox}
            checked={value}
            onChange={(event) => {
              props.onChange({
                ...props.object,
                [key]: event.target.checked,
              });
            }}
            label={key}
            disabled={isDisabled}
          />
        );
      }

      return null;
    });
  };

  const renderString = () => {
    return stringKeys.map((key) => {
      const value = Object.entries(props.object).find((_) => _[0] === key)?.[1];
      const path = props.path + "." + key;

      const node = handleRules(
        path,
        props.rules,
        key,
        props.object,
        props.onChange,
      );
      if (node !== undefined) {
        return node;
      }

      if (typeof value === "string") {
        return (
          <SingleLineInputView
            path={path}
            rules={props.rules}
            key={key}
            tag={key}
            value={value}
            onChange={(result) => {
              props.onChange({
                ...props.object,
                [key]: result,
              });
            }}
          />
        );
      }

      return null;
    });
  };

  const renderNumber = () => {
    return numberKeys.map((key) => {
      const value = Object.entries(props.object).find((_) => _[0] === key)?.[1];
      const path = props.path + "." + key;

      const node = handleRules(
        path,
        props.rules,
        key,
        props.object,
        props.onChange,
      );
      if (node !== undefined) {
        return node;
      }

      if (typeof value === "number") {
        return (
          <SingleLineInputView
            path={path}
            rules={props.rules}
            key={key}
            tag={key}
            value={value}
            onChange={(result) => {
              props.onChange({
                ...props.object,
                [key]: result,
              });
            }}
          />
        );
      }

      return null;
    });
  };

  const renderObject = () => {
    return objectKeys.map((key) => {
      const value = Object.entries(props.object).find((_) => _[0] === key)?.[1];
      const path = props.path + "." + key;

      const node = handleRules(
        path,
        props.rules,
        key,
        props.object,
        props.onChange,
      );
      if (node !== undefined) {
        return node;
      }

      if (isObject(value)) {
        return (
          <div key={key} className={styles.object}>
            <Subtitle2 className={styles.title}>{key}</Subtitle2>
            <JSONEditorView
              path={path}
              rules={props.rules}
              object={value}
              onChange={(result) => {
                props.onChange({
                  ...props.object,
                  [key]: result,
                });
              }}
            />
          </div>
        );
      }

      return null;
    });
  };

  const renderArray = () => {
    return arrayKeys.map((key) => {
      const value = Object.entries(props.object).find((_) => _[0] === key)?.[1];
      const path = props.path + "." + key;

      const node = handleRules(
        path,
        props.rules,
        key,
        props.object,
        props.onChange,
      );

      if (node !== undefined) {
        return node;
      }

      if (isStringArray(value)) {
        const arrayStr = (value as string[]).join(",");
        return (
          <SingleLineInputView
            path={path}
            rules={props.rules}
            key={key}
            tag={key}
            value={arrayStr}
            onChange={(result) => {
              const strResult = result.toString();
              props.onChange({
                ...props.object,
                [key]: strResult.split(","),
              });
            }}
          />
        );
      }

      if (isArray(value)) {
        const list = value as any[];
        return (
          <div key={key} className={styles.array}>
            <Subtitle2 className={styles.title}>{key}</Subtitle2>
            <div className={styles.arrayContainer}>
              {list.map((item, index) => {
                return (
                  <div key={index} className={styles.arrayElement}>
                    <JSONEditorView
                      path={path}
                      rules={props.rules}
                      object={item}
                      onChange={(result) => {
                        const newList = [...list];
                        newList[index] = result;
                        props.onChange({
                          ...props.object,
                          [key]: newList,
                        });
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.booleanConatiner}>{renderBoolean()}</div>
      {renderString()}
      {renderNumber()}
      {renderArray()}
      {renderObject()}
    </div>
  );
};
