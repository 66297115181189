import { computed } from "mobx";
import { jobStore } from "../../store/jobStore";

export const getDataSetsError = computed(() => {
  // If the selected template does not have a dataset
  // then we don't need to validate the dataset
  if (
    jobStore.selectedTemplate?.Datasets === undefined ||
    jobStore.selectedTemplate?.Datasets.trim() === ""
  ) {
    return undefined;
  }

  if (jobStore.dataSetsString === undefined) {
    return "DataSets should not be empty!";
  }

  try {
    JSON.parse(jobStore.dataSetsString);
    return undefined;
  } catch (e) {
    if (e instanceof Error) {
      return e.message;
    } else {
      return "Invalid json format!";
    }
  }
});
