import { Dropdown, Option } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import React from "react";
import type {
  LMChecklistAssertionViewType,
  PassFailRateByQuery,
} from "../../../helpers/lmchecklistMetricsHelper";
import { getPassFailRatesByQuery } from "../../../helpers/lmchecklistMetricsHelper";
import type { Job } from "../../../models/Job";
import type { LMChecklistAssertionsResponse } from "../../../models/LMChecklistAssertionsResponse";
import type { LMChecklistDetailsResponse } from "../../../models/LMChecklistDetailsResponse";
import { LMChecklistAssertionsTable } from "../Table/LMChecklistAssertionsTable";
import { LMChecklistQueryTable } from "../Table/LMChecklistQueryTable";
import { useResultViewStyles } from "../sharedStyles";

type View = "Query" | "Assertion";

type MetricsDiagnosisTableProps = {
  job: Job;
  jobId: string;
  jobName: string;
  lmchecklistDetailsResponse?: LMChecklistDetailsResponse;
  lmchecklistAssertionsResponse?: LMChecklistAssertionsResponse;
  assertionViewType: LMChecklistAssertionViewType;
};

export const LMChecklistView = observer((props: MetricsDiagnosisTableProps) => {
  // TODO: Support Segmnent
  const {
    job,
    jobId,
    jobName,
    lmchecklistDetailsResponse,
    lmchecklistAssertionsResponse,
    assertionViewType,
  } = props;
  const styles = useResultViewStyles();

  const [selectedView, setSelectedView] = React.useState<View>(
    assertionViewType === "Regressions" ? "Assertion" : "Query",
  );
  const [pfRates, setPFRates] = React.useState<PassFailRateByQuery[]>([]);

  React.useEffect(() => {
    if (lmchecklistDetailsResponse && lmchecklistAssertionsResponse) {
      const assertions = lmchecklistAssertionsResponse;
      const pfMap = lmchecklistDetailsResponse.map((record) =>
        getPassFailRatesByQuery(assertions, record),
      );
      setPFRates(pfMap);
    }
  }, [lmchecklistDetailsResponse, lmchecklistAssertionsResponse]);

  const handleViewSelect = (view: View) => {
    setSelectedView(view);
  };

  const renderTables = () => (
    <div className={styles.root}>
      <div className={styles.actionBar}>
        <Dropdown
          className={styles.dropdown}
          value={`${selectedView} View`}
          selectedOptions={[selectedView]}
          onOptionSelect={(_, option) =>
            handleViewSelect(option.optionValue as View)
          }
        >
          <Option value={"Query"}>Query View</Option>
          <Option value={"Assertion"}>Assertion View</Option>
        </Dropdown>
      </div>
      {selectedView === "Query" &&
        lmchecklistDetailsResponse &&
        lmchecklistAssertionsResponse && (
          <LMChecklistQueryTable
            job={job}
            jobId={jobId}
            details={lmchecklistDetailsResponse}
            assertions={lmchecklistAssertionsResponse}
            pfRates={pfRates}
          />
        )}
      {selectedView === "Assertion" && lmchecklistAssertionsResponse && (
        <LMChecklistAssertionsTable
          jobName={jobName}
          assertions={lmchecklistAssertionsResponse}
          assertionViewType={assertionViewType}
        />
      )}
    </div>
  );

  return (
    <>
      {lmchecklistDetailsResponse &&
        lmchecklistAssertionsResponse &&
        renderTables()}
    </>
  );
});
