import { orchestrator } from "satcheljs";
import { loginAction } from "../actions/loginAction";
import { logoutAction } from "../actions/logoutAction";
import { loginRequest } from "../authConfig";
import { telemetryHelper } from "../helpers/telemetryHelper";

orchestrator(loginAction, ({ instance, actionType }) => {
  telemetryHelper.logUserActionEvent("Login", { actionType });

  switch (actionType) {
    case "popup":
      instance.loginPopup({ ...loginRequest }).catch((e) => {
        telemetryHelper.logErrorEvent("UserLoginError", {
          actionType,
          message: e.message,
        });
      });
      break;

    case "redirect":
      instance.loginRedirect({ ...loginRequest }).catch((e) => {
        telemetryHelper.logErrorEvent("UserLogoutError", {
          actionType,
          message: e.message,
        });
      });
      break;
  }
});

orchestrator(logoutAction, ({ instance, actionType }) => {
  telemetryHelper.logUserActionEvent("Logout", { actionType });

  switch (actionType) {
    case "popup":
      instance
        .logoutPopup({
          postLogoutRedirectUri: `/logout`,
          mainWindowRedirectUri: `/logout`,
        })
        .catch((e) => {
          telemetryHelper.logErrorEvent("UserLogoutError", {
            actionType,
            message: e.message,
          });
        });
      break;

    case "redirect":
      instance
        .logoutRedirect({
          postLogoutRedirectUri: `/logout`,
        })
        .catch((e) => {
          telemetryHelper.logErrorEvent("UserLogoutError", {
            actionType,
            message: e.message,
          });
        });
      break;
  }
});
