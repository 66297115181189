import { array, object, optional, str } from "sydneyeval-shared";

export const Metric = object({
  metrics_key: str,
  segment: str,
  score_control: str,
  score_experiment: str,
  score_delta: str,
  p_value: optional(str),
  n: optional(str),
  dropped_narows: optional(str),
});

export const GeneralMetricsResponse = array(Metric);

export type Metric = ReturnType<typeof Metric>;
export type GeneralMetricsResponse = ReturnType<typeof GeneralMetricsResponse>;
