"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkFakeTenantValidation = exports.checkEmailPatternValidation = void 0;
const checkEmailPatternValidation = (account) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(account);
};
exports.checkEmailPatternValidation = checkEmailPatternValidation;
const checkFakeTenantValidation = (account) => {
    return (!account.includes("@microsoft.com") && (0, exports.checkEmailPatternValidation)(account));
};
exports.checkFakeTenantValidation = checkFakeTenantValidation;
