import { array, mapOf, object, optional, or, str } from "sydneyeval-shared";

export const SearchUserSuccessResponse = object({
  UserPrincipalName: str,
  PrimarySmtpAddress: str,
  DisplayName: str,
  RecipientType: str,
  RecipientTypeDetails: str,
  ObjectId: str,
  ExchangeObjectId: str,
});

export const SearchUserErrorResponse = object({
  error: object({
    code: str,
    message: str,
  }),
});

export const SubstrateSingleUserInfor = object({
  PrimarySmtpAddress: optional(str),
  DisplayName: optional(str),
  ObjectId: str,
  RecipientType: optional(str),
});

export const SearchUserListResponse = object({
  userlist: array(SubstrateSingleUserInfor),
});

export const SearchUserResponse = mapOf(
  or([SearchUserSuccessResponse, SearchUserErrorResponse]),
);

export type SearchUserSuccessResponse = ReturnType<
  typeof SearchUserSuccessResponse
>;
export type SearchUserErrorResponse = ReturnType<
  typeof SearchUserErrorResponse
>;
export type SearchUserResponse = ReturnType<typeof SearchUserResponse>;

export type SubstrateSingleUserInfor = ReturnType<
  typeof SubstrateSingleUserInfor
>;
export type SearchUserListResponse = ReturnType<typeof SearchUserListResponse>;
export const isSearchUserSuccessResponse = (
  result: SearchUserSuccessResponse | SearchUserErrorResponse,
): result is SearchUserSuccessResponse => {
  return "UserPrincipalName" in result;
};

export const isSearchUserErrorResponse = (
  result: SearchUserSuccessResponse | SearchUserErrorResponse,
): result is SearchUserErrorResponse => {
  return "error" in result;
};
