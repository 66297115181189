import { array, object, optional, str } from "sydneyeval-shared";

export const AppConfigurationSnapshot = object({
  name: str,
  snapshot: optional(str),
  properties: object({
    createdOn: str,
    lastModified: str,
  }),
  blobTags: optional(
    object({
      blobTagSet: array(object({ key: str, value: str })),
    }),
  ),
});

export const AppConfigurationSnapshotsResponse = array(
  AppConfigurationSnapshot,
);

export type AppConfigurationSnapshot = ReturnType<
  typeof AppConfigurationSnapshot
>;
export type AppConfigurationSnapshotsResponse = ReturnType<
  typeof AppConfigurationSnapshotsResponse
>;
