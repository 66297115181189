import { Card, mergeClasses } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { ResponsiveRow } from "../../../../components/Responsive/ResponsiveRow";
import { StatusTile } from "../../../../components/Shared/StatusTile";
import { formatCreateTime } from "../../helpers/formatHelper";
import { scheduleJobStore } from "../../store/scheduleJobStore";
import { JobsTable } from "../JobList/JobsTable";
import { sharedStyles } from "./sharedStyles";

export const ScheduleJobDetailView = observer(() => {
  const styles = sharedStyles();
  const { selectedScheduledJob } = scheduleJobStore;
  if (!selectedScheduledJob) {
    return <></>;
  }

  return (
    <div>
      <Card
        className={mergeClasses(styles.inforContainer, styles.marginBotton)}
      >
        <ResponsiveRow maxColumnCount={2} maxColumnCountSmall={1} columnGap={8}>
          <div className={styles.row}>
            <div className={styles.blockTitle}>Template Name</div>
            <div>{selectedScheduledJob.TemplateName}</div>
          </div>

          <div className={styles.row}>
            <div className={styles.blockTitle}>Status</div>
            <StatusTile
              hasClickEvent={false}
              status={selectedScheduledJob.Status}
            />
          </div>
          <div className={styles.row}>
            <div className={styles.blockTitle}>Interval In Hours</div>

            {selectedScheduledJob.IntervalInHours.toString()}
          </div>

          <div className={styles.row}>
            <div className={styles.blockTitle}>Start Time</div>

            <div>{formatCreateTime(selectedScheduledJob.StartTime)}</div>
          </div>
          {selectedScheduledJob.EndTime && (
            <div className={styles.row}>
              <div className={styles.blockTitle}>End Time</div>

              <div>{formatCreateTime(selectedScheduledJob.EndTime)}</div>
            </div>
          )}
          {selectedScheduledJob.CreatedBy && (
            <div className={styles.row}>
              <div className={styles.blockTitle}>Created By</div>

              <div>{selectedScheduledJob.CreatedBy ?? ""}</div>
            </div>
          )}
          {selectedScheduledJob.CreatedAt && (
            <div className={styles.row}>
              <div className={styles.blockTitle}>Created At</div>

              <div>{formatCreateTime(selectedScheduledJob.CreatedAt)}</div>
            </div>
          )}
        </ResponsiveRow>
      </Card>

      <JobsTable
        selectedStatus={""}
        jobNameKeyword={`${selectedScheduledJob.Name}-`}
        creator={""}
        filter={""}
        product={""}
      />
    </div>
  );
});
