import { checkUrlValidation } from "../../../helpers/formatHelper";

export const checkUrl = (value: string | undefined, path: string) => {
  if (value === undefined) {
    return { path, message: "Sydney url should not be undefined!" };
  }

  if (value === "") {
    return { path, message: "Sydney url should not be empty!" };
  }

  if (!checkUrlValidation(value ?? "")) {
    return { path, message: "Sydney url is invalid!" };
  }
  return undefined;
};
