import { Fragment, useEffect, useState } from "react";
import type {
  ExportedMetricsData,
  MetricsData,
} from "../../helpers/metricsHelper";

import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { telemetryHelper } from "../../../../helpers/telemetryHelper";
import { closeSidePane } from "../../../../mutators/sidePaneMutators";
import { switchMChatScenarioAction } from "../../actions/resultActions";
import type { LMChecklistAssertionViewType } from "../../helpers/lmchecklistMetricsHelper";
import { hasCriticalRegression } from "../../helpers/lmchecklistMetricsHelper";
import { convertJSONToMetricsData } from "../../helpers/metricsHelper";
import { MetricsGroup } from "../../helpers/metricsMapping";
import { disableDiagnosisView } from "../../selectors/jobDetailHelper";
import { jobDetailStore } from "../../store/jobDetailStore";
import { ResultScenarioType, resultStore } from "../../store/resultStore";
import { FilterButton } from "../Other/FilterButton";
import { LMChecklistCriticalFailureBanner } from "./Banner/LMChecklistCriticalFailureBanner";
import { MessageNotificationBar } from "./Banner/MessageNotificationBar";
import { DetailCardHeader } from "./components/DetailCardHeader";
import { LMChecklistView } from "./components/LMChecklistView";
import { useResultViewStyles } from "./sharedStyles";
import { MChatMetricsTable } from "./Table/MChatMetricsTable";
import { ScorecardTable } from "./Table/ScorecardTable";

const Scenario = [
  "Scorecard Comparison",
  "Metric Diagnosis",
  "LM Checklist",
] as const;

export const DetailCard = observer(() => {
  const styles = useResultViewStyles();
  const { selectedscenario } = useParams();
  const job = resultStore.resultJob;
  const jobName = job?.JobName ?? "";
  const jobId = job?.JobId ?? "";
  const segments = resultStore.resultSegments;
  const sourceMap = resultStore.sourceMap;

  const [selectedSegment, setSelectedSegment] = useState<string>(segments[0]);

  const handleSegmentUpdate = (segment: string) => {
    setSelectedSegment(segment);
  };

  const perfMetrics = resultStore.perfMetrics;

  const metricsData = computed(() => [
    ...convertJSONToMetricsData(sourceMap.get(selectedSegment) ?? []),
    ...perfMetrics,
  ]);

  const exportedData = computed(() =>
    metricsData.get().map(convertMetricsDataToExportData),
  );

  const convertMetricsDataToExportData = (
    metricsData: MetricsData,
  ): ExportedMetricsData => {
    return {
      segment: metricsData.segment,
      category: metricsData.category,
      metric: metricsData.metric,
      group: metricsData.group,
      control: metricsData.control,
      treatment: metricsData.treatment,
      delta: metricsData.delta,
      pairedN: String(
        Number(metricsData.n) - Number(metricsData.droppedNarrows),
      ),
      pValue: metricsData.pValue,
    };
  };

  const downloadData = (metricsData: ExportedMetricsData[]) => {
    const csvData: string[][] = [];
    csvData.push(Object.keys(metricsData[0]));
    metricsData.forEach((metric) => {
      csvData.push(Object.values(metric));
    });
    const csvContent = csvData.map((row) => row.join(",")).join("\n");

    // Create a Blob object and create a download link
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${jobName}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleCoreDataDownload = () => {
    downloadData(exportedData.get());
    telemetryHelper.logUserActionEvent("ClickDownloadMetrics");
  };

  // Currently it's specific to NDCG metric
  // TODO: make it more generic
  const generateNotificationText = (): string | undefined => {
    const percLlmFailedAnnotation = metricsData
      .get()
      .find((row) => row.metric === "NDCG_LLM_labeler_failure_rate");
    const isNDCGInvalid =
      Number(percLlmFailedAnnotation?.control) > 0 ||
      Number(percLlmFailedAnnotation?.treatment) > 0;
    if (isNDCGInvalid) {
      return "* NDCG could be noise when LLM Label coverage is lower than 100%.";
    }
    return undefined;
  };
  const notificationText = generateNotificationText();
  const parseSelectedscenario = computed(() => {
    if (selectedscenario !== undefined) {
      try {
        return ResultScenarioType(selectedscenario, "ResultScenarioType");
      } catch {
        return undefined;
      }
    }
    return resultStore.scenario;
  });

  const scenario = resultStore.scenario;

  const [lmchecklistAssertionViewType, setLMChecklistAssertionViewType] =
    useState<LMChecklistAssertionViewType>("All");

  const handleBannerClick = () => {
    switchMChatScenarioAction("LM Checklist");
    setLMChecklistAssertionViewType("Regressions");
  };

  useEffect(() => {
    closeSidePane();
  }, [scenario]);

  const hasDownloadFilesButton =
    resultStore.resultJob?.JobTemplateType !== "BingV2" &&
    resultStore.resultJob?.JobTemplateType !== "CWC";

  const hasView3SSummaryDashboardButton = hasDownloadFilesButton;
  const hasViewPerfInfoButton = hasDownloadFilesButton;

  const hasLMChecklistView =
    jobDetailStore.lmchecklistDetailsResponse &&
    jobDetailStore.lmchecklistDetailsResponse.length > 0;

  const blockDiagnosisView = disableDiagnosisView.get();

  useEffect(() => {
    const selectedScenario = parseSelectedscenario.get();
    if (selectedScenario !== undefined) {
      if (selectedScenario === "LM Checklist") {
        hasLMChecklistView
          ? switchMChatScenarioAction(selectedScenario)
          : switchMChatScenarioAction(resultStore.scenario);
      } else {
        switchMChatScenarioAction(selectedScenario);
      }
    }
  }, [parseSelectedscenario.get()]);
  return (
    <>
      <div className={styles.scenarioRow}>
        <div className={styles.buttonRow}>
          {Scenario.map((s) => {
            if (!hasLMChecklistView && s === "LM Checklist") {
              return <Fragment key={s.toString()}></Fragment>;
            }
            return (
              <FilterButton
                value={s.toString()}
                text={s.toString()}
                selectedTab={scenario}
                key={s.toString()}
                clickTab={() => {
                  switchMChatScenarioAction(s);
                  setLMChecklistAssertionViewType("All");
                }}
                activeIcon={undefined}
                inactionIcon={undefined}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.detailedCard}>
        {scenario === "Scorecard Comparison" && (
          <div className={styles.dataPane}>
            <MessageNotificationBar />
            {hasLMChecklistView &&
              !jobDetailStore.lmchecklistAssertionsResponse && (
                <LMChecklistCriticalFailureBanner checkingForFailures={true} />
              )}
            {jobDetailStore.lmchecklistAssertionsResponse &&
              hasCriticalRegression(
                jobDetailStore.lmchecklistAssertionsResponse,
              ) && (
                <LMChecklistCriticalFailureBanner
                  handleBannerClick={handleBannerClick}
                />
              )}
            {MetricsGroup.map((group) => {
              return {
                data: metricsData.get().filter((data) => data.group === group),
                group,
              };
            })
              .filter((result) => result.data.length > 0)
              .map((result, index) => {
                if (resultStore.resultJob === undefined) {
                  return <></>;
                }
                return (
                  <div key={index} className={styles.tableContainer}>
                    {notificationText && index === 0 && (
                      <span style={{ marginBottom: "10px" }}>
                        {notificationText}
                      </span>
                    )}

                    <>
                      {index === 0 ? (
                        <DetailCardHeader
                          job={resultStore.resultJob}
                          title={result.group}
                          jobName={jobName}
                          jobId={jobId}
                          segments={segments}
                          selectedSegment={selectedSegment}
                          onSegmentUpdated={handleSegmentUpdate}
                          hasView3SSummaryDashboardButton={
                            hasView3SSummaryDashboardButton
                          }
                          hasViewPerfInfoButton={hasViewPerfInfoButton}
                          hasDownloadMetricsButton={true}
                          onDownloadMetricsClick={handleCoreDataDownload}
                        />
                      ) : (
                        <DetailCardHeader
                          job={resultStore.resultJob}
                          title={result.group}
                          jobName={jobName}
                          jobId={jobId}
                        />
                      )}

                      <div className={styles.tableScrollContainer}>
                        <ScorecardTable metricsData={result.data} />
                      </div>
                    </>
                  </div>
                );
              })}
          </div>
        )}
        {!blockDiagnosisView && scenario === "Metric Diagnosis" && (
          <div className={styles.dataPane}>
            <MChatMetricsTable />
          </div>
        )}
        {!blockDiagnosisView && scenario === "LM Checklist" && (
          <div className={styles.dataPane}>
            <div className={styles.tableContainer}>
              {resultStore.resultJob && (
                <div className={styles.tableScrollContainer}>
                  <LMChecklistView
                    job={resultStore.resultJob}
                    lmchecklistDetailsResponse={
                      jobDetailStore.lmchecklistDetailsResponse
                    }
                    lmchecklistAssertionsResponse={
                      jobDetailStore.lmchecklistAssertionsResponse
                    }
                    jobId={jobId}
                    jobName={jobName}
                    assertionViewType={lmchecklistAssertionViewType}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
});
