import { mutatorAction } from "satcheljs";
import type { Job } from "../models/Job";
import { jobStore } from "../store/jobStore";

export const updateEmailToUrl = mutatorAction(
  "updateEmailToUrl",
  (email: string, url: string) => {
    jobStore.caches.emailToUrl.set(email, url);
  },
);

export const updateJobIdToJob = mutatorAction(
  "updateJobIdToMetrics",
  (jobId: number, job: Job) => {
    jobStore.caches.jobIdToJob.set(jobId, job);
  },
);
