export const checkBingJsonFields = (
  value: string | undefined,
  path: string,
) => {
  if (value === undefined || value === "") {
    return undefined;
  }

  try {
    const parsed: unknown = JSON.parse(value);
    if (typeof parsed !== "object" || parsed === null) {
      return {
        path,
        message: path + ":This field should be an object!",
      };
    }
    return undefined;
  } catch (e) {
    return {
      path,
      message: path + ":This field should be a valid JSON!",
    };
  }
};
