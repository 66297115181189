import {
  Field,
  SpinButton,
  Textarea,
  mergeClasses,
} from "@fluentui/react-components";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import type { MetricDefinition } from "../MetricDefinition";
import type { ReasoningLeoRow } from "../bizChatEvalDataProvider";
import {
  ControllableNav,
  renderRawData,
} from "../groundleo/GroundLeoDefinition";
import { useStyles } from "../styles";
import { getUserAlias } from "../utils/utilities";

const RenderHumanLabel: FunctionComponent<{
  row: ReasoningLeoRow;
  triggerNavRerender: () => void;
  saveFeedbackTrigger: number;
  disabled?: boolean;
}> = ({ row, triggerNavRerender, saveFeedbackTrigger, disabled }) => {
  const [comment, setComment] = useState<string>("");
  const [reasoningleoScore, setReasoningleoScore] = useState<
    number | undefined
  >();

  const metricMap = {
    reasoningleo: reasoningleoScore,
  };

  const setMetricMap = {
    reasoningleo: setReasoningleoScore,
  };

  type metricNameType = keyof typeof metricMap;

  useEffect(() => {
    setComment(row.human_comment || "");
    setReasoningleoScore(row.label_reasoningleo_score);
  }, [row]);

  useEffect(() => {
    if (saveFeedbackTrigger === 0) return;
    saveHumanFeedback();
  }, [saveFeedbackTrigger]);

  function saveHumanFeedback() {
    row.human_comment = comment;
    row.submitted = true;
    row.last_labelled_by = getUserAlias();
    row.label_reasoningleo_score = reasoningleoScore;
    triggerNavRerender();
  }

  const styles = useStyles();

  // column should be one of metricMap's key
  function renderScoreLabel(column: metricNameType) {
    return (
      <div
        className={styles.stackHorizontal}
        key={`label_${column}_score` + row.ConversationId}
      >
        <SpinButton
          disabled={disabled}
          style={{ width: "10em" }}
          min={0}
          max={10}
          value={metricMap[column] ?? 0}
          onChange={(ev, value) =>
            setMetricMap[column](value.value ?? undefined)
          }
        ></SpinButton>
      </div>
    );
  }

  return (
    <>
      <div>
        Score should be integer between 0 and 10. You are labeling the
        conversation, not labeling the LLM label.
      </div>
      <div
        className={mergeClasses(
          styles.stackVertical,
          styles.stackVerticalWithGap,
        )}
        style={{ margin: "1em 1em 0 0", flexWrap: "wrap" }}
      >
        {Object.keys(metricMap).map((name) => {
          return renderScoreLabel(name as metricNameType);
        })}
      </div>
      <Field label="Comment" style={{ margin: "1em 1em 0 0" }}>
        <Textarea
          disabled={disabled}
          resize="vertical"
          value={comment}
          onChange={(ev, value) => setComment(value.value)}
        ></Textarea>
      </Field>
    </>
  );
};

const renderLLMLabel = (row: ReasoningLeoRow) => {
  const reasoningLeoRow = row as any;
  const response = reasoningLeoRow["response"];

  return (
    <div>
      <b>
        ReasoningLeo score&nbsp;
        {(reasoningLeoRow as ReasoningLeoRow).reasoningleo_score}
      </b>
      <ReactMarkdown linkTarget="_blank">{response}</ReactMarkdown>
    </div>
  );
};

export const getCustomizedExportData = (rows: ReasoningLeoRow[]) => {
  const data = rows.map((row) => {
    return {
      conversation_id: row.ConversationId,
      reasoningleo_score: row.reasoningleo_score,
      label_reasoningleo_score: row.label_reasoningleo_score,
      comment: row.human_comment,
      last_labelled_by: row.last_labelled_by,
    };
  });
  return data;
};

export const ReasoningLeoDefinition: MetricDefinition<ReasoningLeoRow> = {
  name: "ReasoningLeo",
  url: "reasoningleo",
  fetchData: undefined,
  nav: ControllableNav,
  renderHumanLabel: RenderHumanLabel,
  renderLLMLabel: renderLLMLabel,
  renderRawData: renderRawData,
  allowLocalUpload: true,
  getCustomizedExportData: getCustomizedExportData,
  humanLabelFieldNames: ["human_comment", "label_reasoningleo_score"],
};
