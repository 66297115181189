import type { EditorRule } from "../models/Types";

const createRules = (paths: string[]): EditorRule[] => {
  return paths.map((path) => ({
    type: "ignore",
    path: `.configs_in_json.${path}`,
  }));
};

export const getSkipScrapingRules = () => {
  const paths = [
    "options",
    "conversations.output_folder",
    "conversations.language",
    "conversations.has_header",
    "conversations.exp_configs",
    "parsers",
  ];
  return createRules(paths);
};

export const getNewVersionRules = () => {
  const rules = new Array<EditorRule>();
  rules.push({
    type: "ignore",
    path: ".configs_in_json.options",
  });
  return rules;
};

export const getOPGRules = () => {
  const rules = new Array<EditorRule>();
  rules.push({
    type: "ignore",
    path: ".configs_in_json.options",
  });
  rules.push({
    type: "ignore",
    path: ".configs_in_json.conversations.output_folder",
  });
  rules.push({
    type: "ignore",
    path: ".configs_in_json.conversations.language",
  });
  rules.push({
    type: "ignore",
    path: ".configs_in_json.conversations.has_header",
  });
  rules.push({
    type: "ignore",
    path: ".configs_in_json.conversations.exp_configs",
  });
  rules.push({
    type: "ignore",
    path: ".configs_in_json.parsers",
  });
  return rules;
};
