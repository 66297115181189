import { computed } from "mobx";
import { isFeatureEnabled } from "../../../selectors/features";
import { jobStore } from "../store/jobStore";
import { getQuickFilterTemplates } from "./getQuickFilterTemplates";
import { PRODUCT_ID_CWC, PRODUCT_ID_M365_CHAT } from "./productSelectors";

export const getQuickFilterDefaultTemplate = computed(() => {
  if (!isFeatureEnabled("product-registration")) {
    const templates = getQuickFilterTemplates.get();
    switch (jobStore.quickTemplateTypeFilter) {
      case "All":
      case "BizChat":
        return (
          templates.find((template) =>
            template.Name.includes(
              "BizChat_Evaluation_Flight_Review_For_Avalon",
            ),
          ) ?? templates[0]
        );
      case "CWC":
        return (
          templates.find(
            (template) => template.Name.toLowerCase() === "multiturn",
          ) ?? templates[0]
        );
      default:
        return templates[0];
    }
  } else {
    const templates = getQuickFilterTemplates.get();

    switch (jobStore.productFilter) {
      case PRODUCT_ID_M365_CHAT:
        return (
          templates.find(
            (template) =>
              template.ExperimentName ===
              "BizChat_Evaluation_Flight_Review_For_Avalon",
          ) ?? templates[0]
        );
      case PRODUCT_ID_CWC:
        return (
          templates.find(
            (template) => template.Name.toLowerCase() === "multiturn",
          ) ?? templates[0]
        );
      default:
        return templates[0];
    }
  }
});
