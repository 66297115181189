import {
  Button,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { ChevronRight16Regular } from "@fluentui/react-icons";

const useStyles = makeStyles({
  statusContainer: {
    height: "20px",
    width: "120px",
    justifyContent: "center",
    alignjobs: "center",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F3F2F1",
    ...shorthands.borderRadius("4px"),
    ...shorthands.borderWidth("0px"),
  },
  statusText: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "16px",
    fontStyle: "normal",
    opacity: 1,
  },
});
const getStatusColor = (status: string | undefined) => {
  switch (status) {
    case "Running":
    case "Active":
      return `rgb(0, 94, 166)`;
    case "Created":
      return `rgb(119, 25, 170)`;
    case "Submitted":
      return `rgb(119, 25, 170)`;
    case "Completed":
      return `rgb(13, 102, 53)`;
    case "Failed":
      return `rgb(164, 55, 58)`;
    case "SubmitFailed":
      return `rgb(164, 55, 58)`;
    case "DatasetNotFound":
      return `rgb(164, 55, 58)`;
    case "Canceled":
      return `rgb(179, 48, 0)`;
    case "CancelRequested":
      return `rgb(179, 48, 0)`;
    case "Deleted":
      return `rgb(179, 48, 0)`;
    default:
      return;
  }
};

export const StatusTile = (props: {
  hasClickEvent: boolean;
  status: string;
  onClick?: () => void;
}) => {
  const styles = useStyles();
  return (
    <Button
      tabIndex={props.hasClickEvent ? 0 : -1}
      aria-label={props.hasClickEvent ? props.status + "Reason" : undefined}
      className={styles.statusContainer}
      data-testid="mock-status-title"
      style={{
        cursor: props.hasClickEvent ? "pointer" : "default",
      }}
      onClick={props.onClick}
    >
      <span
        className={styles.statusText}
        style={{
          color: getStatusColor(props.status),
        }}
      >
        {props.status}
      </span>
      {props.hasClickEvent && (
        <ChevronRight16Regular primaryFill={getStatusColor(props.status)} />
      )}
    </Button>
  );
};
