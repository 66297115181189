import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import {
  onCancelClick,
  onReleaseClick,
} from "../../actions/productSettingsActions";
import { productSettingsStore } from "../../store/productSettingsStore";

export const ProductReleaseDialog = observer(() => {
  const product = (() => {
    if (productSettingsStore.scenario.scenarioType !== "EditingProduct") {
      return undefined;
    }
    return productSettingsStore.scenario.isReleasing;
  })();

  return (
    <Dialog open={product !== undefined} onOpenChange={onCancelClick}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{"Ready to release?"}</DialogTitle>
          <DialogContent>
            {"Are you sure you want to release this product to all users?"}
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              onClick={onReleaseClick.bind(null, true)}
            >
              Yes, release
            </Button>
            <Button appearance="secondary" onClick={onCancelClick}>
              Cancel
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
});
