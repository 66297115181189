import { computed } from "mobx";
import { resultStore } from "../store/resultStore";

const hasNoReadPermission = computed(
  () => resultStore.resultJob?.Properties?.HasReadPermission === false,
);

export const disableDiagnosisView = computed(() => {
  return hasNoReadPermission.get();
});

export const showNoPermissionDialog = computed(() => {
  if (resultStore.scenario === "Scorecard Comparison") {
    return false;
  }
  return hasNoReadPermission.get();
});
