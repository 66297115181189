import { get } from "lodash";
import { computed } from "mobx";
import { JobConfiguration } from "sydneyeval-shared";
import type { JobConfigurationUpdateAction } from "../models/JobConfigurationAction";
import { jobStore } from "../store/jobStore";

type Handle<
  A extends JobConfigurationUpdateAction,
  P extends A["prop"],
> = A extends object
  ? { prop: P } extends Omit<A, "newData">
    ? A["newData"] | undefined
    : never
  : never;

export type JobConfigurationValue<
  T extends JobConfigurationUpdateAction["prop"],
> = Handle<JobConfigurationUpdateAction, T> | undefined;

// Test cases
// type Test1 = Handle<JobConfigurationUpdateAction, "configs_in_json">;
// type Test2 = Handle<JobConfigurationUpdateAction, "configs_in_json.conversations">;
// type Test3 = Handle<JobConfigurationUpdateAction, "configs_in_json.conversations.user_id">;
// type Test4 = Handle<JobConfigurationUpdateAction, "exp_configs">;
// type Test5 = Handle<JobConfigurationUpdateAction, "exp_configs[0]">;
// type Test6 = Handle<JobConfigurationUpdateAction, "exp_configs[0].exp_name">;

export const getValue = <T extends JobConfigurationUpdateAction["prop"]>(
  path: T,
): Handle<JobConfigurationUpdateAction, T> => {
  const config = JobConfiguration(jobStore.configuration, "JobConfiguration");
  return get(config, path) as Handle<JobConfigurationUpdateAction, T>;
};

export const getBingJobEngines = computed(() => {
  return getValue("exp_configs");
});

export const getBingJobTestSetsListMultiTurn = computed(() => {
  return getValue("multi_turn");
});

export const getBingJobTestSetsListSingleTurn = computed(() => {
  return getValue("single_turn");
});

export const getUserId = computed(() => {
  return getValue("configs_in_json.conversations.user_id");
});

export const getInputFile = computed(() => {
  return getValue("configs_in_json.conversations.input_file");
});

export const getInputFolder = computed(() => {
  return getValue("configs_in_json.conversations.input_folder");
});

export const getExpConfigs = computed(() => {
  return getValue("configs_in_json.conversations.exp_configs");
});

export const getEvalTimeOverride = computed(() => {
  return getValue("configs_in_json.conversations.eval_time_override");
});

export const getBizChatMetrics = computed(() => {
  return {
    scleo: getValue("configs_in_json.scleo"),
    apology: getValue("configs_in_json.apology"),
    stewieleo: getValue("configs_in_json.stewieleo"),
    pileo: getValue("configs_in_json.pileo"),
    groundleo: getValue("configs_in_json.groundleo"),
    lmchecklist: getValue("configs_in_json.lmchecklist"),
    stewieleosbs: getValue("configs_in_json.stewieleosbs"),
    tcr: getValue("configs_in_json.tcr"),
    groundleo_claimbreak: getValue("configs_in_json.groundleo_claimbreak"),
    sbsleov2: getValue("configs_in_json.sbsleov2"),
    sbsleo: getValue("configs_in_json.sbsleo"),
    reasoningleo: getValue("configs_in_json.reasoningleo"),
    teamsgroundleo: getValue("configs_in_json.teamsgroundleo"),
    stewieleov5: getValue("configs_in_json.stewieleov5"),
    codeleo: getValue("configs_in_json.codeleo"),
  };
});

export const getBingJobTestSetsList = computed(() => {
  return (
    getBingJobTestSetsListMultiTurn.get() ??
    getBingJobTestSetsListSingleTurn.get()
  );
});

export const getBingJobRunMode = computed(() => {
  if (getBingJobTestSetsListMultiTurn.get() !== undefined) {
    return "multi_turn" as const;
  } else {
    return "single_turn" as const;
  }
});

export const getBingCluster = computed(() => {
  return getValue("cluster") ?? "";
});

export const getBingScenario = computed(() => {
  return getValue("scenario") ?? "default";
});

export const getConfigure3pPlugins = computed(() => {
  return {
    sideload_3p_plugins: getValue("sideload_3p_plugins"),
    unsideload_3p_plugins: getValue("unsideload_3p_plugins"),
  };
});
