// Return a string of upns from a string of aliases or upns
export function concatUpn(aliasString: string) {
  if (aliasString.trim() === "") {
    return "";
  }
  const aliasList = aliasString.replaceAll(" ", "").split(",");
  const upns = [] as string[];
  for (let i = 0; i < aliasList.length; i++) {
    let r = aliasList[i];
    if (!r.includes("@")) {
      r = r.concat("@microsoft.com");
    }
    upns.push(r);
  }
  return upns.join();
}
