import { action } from "satcheljs";
import type { BingDiagnosisSummary } from "../models/BingDiagnosisFile";
import type { ResultScenarioType } from "../store/resultStore";

export const updateSelectBingFileAction = action(
  "updateSelectBingFileAction",
  (path: string | undefined) => ({
    path,
  }),
);

export const updateSelectedFilterAction = action(
  "updateSelectedFilterAction",
  (filter: string, value: string | undefined) => ({
    filter,
    value,
  }),
);

export const updateBingDiagnosisSummaryAction = action(
  "updateBingDiagnosisSummaryAction",
  (table: BingDiagnosisSummary) => ({
    table,
  }),
);

export const switchMChatScenarioAction = action(
  "switchMChatScenarioAction",
  (scenario: ResultScenarioType) => ({
    scenario,
  }),
);
