import { checkVariantValidation } from "../../../helpers/formatHelper";

export const checkVariant = (value: string | undefined, path: string) => {
  if (value === undefined) {
    return undefined;
  }

  if (!checkVariantValidation(value)) {
    return {
      path,
      message:
        "Variant should be feature variants separated by comma and not containing space!",
    };
  }
  return undefined;
};
