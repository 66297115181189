import { action } from "satcheljs";

export const uploadFolderAction = action(
  "uploadFolderAction",
  (
    folderType: "OPG" | "Bing" | "CWC" | "GeneralSkipScraping",
    folderName: string,
    folder: File[],
    oldFolderName: string,
    onSuccess?: () => void,
    onError?: (error: any) => void,
    folderPath?: string[],
  ) => ({
    folderType,
    folderName,
    folder,
    oldFolderName,
    folderPath,
    onSuccess,
    onError,
  }),
);
