import {
  Body1,
  Button,
  Combobox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Input,
  Option,
  Spinner,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { Checkmark24Regular, DocumentTs16Regular } from "@fluentui/react-icons";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { object, optional, str } from "sydneyeval-shared";
import { getFilePrefix, getTokenName } from "../../helpers/accountHelper";
import {
  createJob,
  getJobTemplates,
  verifyToken,
} from "../../helpers/apiHelper";

import { compact } from "lodash";
import { parseJsonStrOptional } from "sydneyeval-shared";
import { Default_QuerySet_Template, QuerySet_Templates } from "../../constants";
import { telemetryHelper } from "../../helpers/telemetryHelper";
import { getGraphTokenInfo } from "../../helpers/tokenHelper";
import { generateFormattedDateString } from "../../partners/OfflineEval/helpers/formatHelper";
import type { JobTemplate } from "../../partners/OfflineEval/models/JobTemplate";
import { isFeatureEnabled } from "../../selectors/features";
import { store } from "../../store/store";
import { useToast } from "../Wrappers/ToasterProvider";

const useStyle = makeStyles({
  root: {
    height: "45px",
  },
  comboboxWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    ...shorthands.gap("10px"),
  },
  combobox: {
    ...shorthands.flex(1),
  },
  selectedTabTitle: {
    fontSize: "14px",
    fontWeight: 600,
    color: "rgba(36, 36, 36, 1)",
  },
  normalTabTitle: {
    fontSize: "14px",
    fontWeight: 400,
    color: "rgba(66, 66, 66, 1)",
  },
  titleDescription: {
    fontSize: "14px",
    fontWeight: 400,
    color: "rgba(96, 94, 92, 1)",
  },
  subtitleField: {
    marginTop: "10px",
  },
  subtitleLabel: {
    fontSize: "16px",
  },
  checkMarkIcon: {
    marginBottom: "-5px",
    marginRight: "10px",
    color: "rgba(33, 141, 81, 1)",
  },
  documentIcon: {
    ...shorthands.margin("0px", "5px"),
  },
  submittedDescription: {
    marginTop: "26px",
    marginLeft: "33px",
    marginRight: "10px",
  },
});

interface IUploadingFileDialogProps {
  isOpen: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

const QuerySetTemplateSchema = optional(
  object({
    querypattern_dataset: str,
  }),
);

export const QuerySetGenerationDialog = observer(
  (props: IUploadingFileDialogProps) => {
    const styles = useStyle();
    const { isOpen } = props;

    const [querySetName, setQueryName] = React.useState<string>("");
    const [puid, setPuid] = React.useState<string>("");
    const [tenantId, setTenantId] = React.useState<string>("");
    const [xAnchorMailbox, setXAnchorMailbox] = React.useState<string>("");
    const [refreshTokenKey, setRefreshTokenKey] = React.useState<string>("");

    const [selectedQuerySetTemplate, setSelectedQuerySetTemplate] =
      React.useState<string>("");
    const [querySetTemplateSchemas, setQuerySetTemplateSchemas] =
      React.useState<string[]>([]);

    const [isSubmittingQuerySet, setIsSubmittingQuerySet] =
      React.useState<boolean>(false);
    const [isSubmittedQuerySet, setIsSubmittedQuerySet] =
      React.useState<boolean>(false);

    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const [currentJobTemplate, setCurrentJobTemplate] = React.useState<
      JobTemplate | undefined
    >(undefined);

    const [hasSetRefreshToken, setHasSetRefreshToken] =
      React.useState<boolean>(false);
    const [refreshTokenError, setRefreshTokenError] =
      React.useState<string>("");

    const toaster = useToast();

    const getUniqueMergedArray = (querypattern_dataset: string | undefined) => {
      return Array.from(
        new Set(compact([...QuerySet_Templates, querypattern_dataset])),
      );
    };

    React.useEffect(() => {
      if (isOpen && currentJobTemplate) {
        const querySetTemplate = parseJsonStrOptional(
          currentJobTemplate?.Datasets,
          QuerySetTemplateSchema,
        );
        // Set default value
        setIsSubmittedQuerySet(false);
        setIsSubmittedQuerySet(false);

        setQueryName(
          `personalized-query-set-${generateFormattedDateString()}.tsv`,
        );
        setTenantId(store.account?.tenantId ?? "");
        setXAnchorMailbox(store.account?.username ?? "");
        setQuerySetTemplateSchemas(
          getUniqueMergedArray(querySetTemplate?.querypattern_dataset),
        );
        setSelectedQuerySetTemplate(
          querySetTemplate?.querypattern_dataset ?? Default_QuerySet_Template,
        );

        setRefreshTokenKey(
          getTokenName(
            store.account?.username ?? "",
            isFeatureEnabled("refresh-token-migration") ? "seval" : "3s",
          ),
        );
      }
    }, [isOpen, currentJobTemplate]);

    React.useEffect(() => {
      if (isOpen) {
        setIsLoading(true);
        verifyToken({
          Email: store.account?.username ?? "",
          TokenType: isFeatureEnabled("refresh-token-migration")
            ? "SEVAL refresh token"
            : "3S refresh token",
        })
          .then((result) => {
            setHasSetRefreshToken(result.isValid);
            if (!result.isValid && !!result.message) {
              setRefreshTokenError(result.message);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }, [isOpen]);

    React.useEffect(() => {
      Promise.all([
        getJobTemplates(),
        getGraphTokenInfo(),
        verifyToken({
          Email: store.account?.username ?? "",
          TokenType: isFeatureEnabled("refresh-token-migration")
            ? "SEVAL refresh token"
            : "3S refresh token",
        }),
      ])
        .then(([jobTemplates, graphTokenInfo, tokenResult]) => {
          setHasSetRefreshToken(tokenResult.isValid);
          if (!tokenResult.isValid && !!tokenResult.message) {
            setRefreshTokenError(tokenResult.message);
          }

          setCurrentJobTemplate(
            jobTemplates.find(
              (_) => _.Name === "BizChat-Personalized-Query-Set-Generator-V2",
            ),
          );

          setPuid(graphTokenInfo?.puid ?? "");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, []);

    const jobConfig = computed(() => {
      return {
        query_set_name: `${getFilePrefix(store.account)}-${querySetName}`,
        user_puid: puid,
        tenant_id: tenantId,
        X_AnchorMailbox: xAnchorMailbox,
        refresh_token_key: refreshTokenKey,
      };
    });

    const jobParameters = computed(() => {
      return {
        JobName: currentJobTemplate?.Name + "-" + generateFormattedDateString(),
        CreatedBy: store.account?.name ?? "Unknown",
        ExperimentName: currentJobTemplate?.ExperimentName ?? "",
        JobTemplateId: currentJobTemplate?.Id ?? 0,
        Settings: JSON.stringify(jobConfig.get()),
        DataSets: JSON.stringify({
          querypattern_dataset: selectedQuerySetTemplate,
          es_request: "EntityServeRequestForPersonalization",
        }),
      };
    });

    const onSubmitButtonClick = () => {
      setIsSubmittingQuerySet(true);
      toaster.onToastStart("Submitting Query Set Generation");

      telemetryHelper.logUserActionEvent("SubmitMChatQuerySetGenerationJob", {
        querySetName,
        selectedQuerySetTemplate,
      });

      createJob(jobParameters.get())
        .then(() => {
          toaster.onToastSuccess("Successfully submitted Query Set Generation");
          telemetryHelper.logUserActionEvent(
            "SubmitMChatQuerySetGenerationJobSuccess",
            {
              querySetName,
              selectedQuerySetTemplate,
            },
          );
          setIsSubmittedQuerySet(true);
        })
        .catch(() => {
          telemetryHelper.logUserActionEvent(
            "SubmitMChatQuerySetGenerationJobFailure",
            {
              querySetName,
              selectedQuerySetTemplate,
            },
          );

          toaster.onToastFailure("Failed to submit Query Set Generation");
        })
        .finally(() => {
          setIsSubmittingQuerySet(false);
        });
    };

    const handleDialogCancel = () => {
      props.onCancel();
    };

    const isSubmitButtonDisabled = () => {
      return (
        querySetName.trim() === "" ||
        puid.trim() === "" ||
        tenantId.trim() === "" ||
        xAnchorMailbox.trim() === "" ||
        refreshTokenKey.trim() === "" ||
        selectedQuerySetTemplate.trim() === "" ||
        currentJobTemplate === undefined ||
        isSubmittingQuerySet === true
      );
    };

    const renderQuerySetUploadingDialogIfNeeded = () => {
      if (!hasSetRefreshToken || isSubmittedQuerySet) {
        return <></>;
      }

      return (
        <>
          <DialogTitle>{`Query Set Generation`}</DialogTitle>
          <DialogContent>
            <Field
              className={styles.subtitleField}
              required
              label={"Query Set Name"}
              validationMessage={
                querySetName.trim() === ""
                  ? "Query Set Name is required"
                  : `Your file will be generated with name ${getFilePrefix(
                      store.account,
                    )}-${querySetName}`
              }
              validationState={querySetName.trim() === "" ? "error" : "success"}
            >
              <Input
                style={{ width: "100%" }}
                size="medium"
                value={querySetName}
                onChange={(_, data) => {
                  setQueryName(data.value);
                }}
              />
            </Field>
            <Field
              className={styles.subtitleField}
              required
              label={"Query Set Template"}
            >
              <Combobox
                className={styles.combobox}
                placeholder="Select Query Set Template"
                value={selectedQuerySetTemplate}
                selectedOptions={[`${selectedQuerySetTemplate}`]}
                onOptionSelect={(_, data) => {
                  if (data.optionValue !== undefined) {
                    setSelectedQuerySetTemplate(data.optionValue);
                  }
                }}
              >
                {querySetTemplateSchemas.map((option, index) => {
                  return (
                    <Option text={option} key={index} value={option}>
                      <DocumentTs16Regular className={styles.documentIcon} />
                      {option}
                    </Option>
                  );
                })}
              </Combobox>
            </Field>
            <br />
            <br />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onSubmitButtonClick}
              disabled={isSubmitButtonDisabled()}
              appearance="primary"
            >
              Submit
            </Button>
            <Button onClick={handleDialogCancel} appearance="secondary">
              Close
            </Button>
          </DialogActions>
        </>
      );
    };

    const onOkButtonClick = () => {
      props.onSuccess();
    };

    const renderQuerySetSubmittedDialogIfNeeded = () => {
      if (!hasSetRefreshToken || !isSubmittedQuerySet) {
        return <></>;
      }

      return (
        <>
          <DialogTitle>
            <Checkmark24Regular className={styles.checkMarkIcon} />
            {`Query set generation submitted`}
          </DialogTitle>
          <DialogContent className={styles.submittedDescription}>
            <Body1>
              Query Set generation process has been submitted. Please be patient
              and give it a little time to work it. You can keep an eye on the
              progress by checking the Query Set list.
            </Body1>
            <br />
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={onOkButtonClick} appearance="primary">
              OK
            </Button>
          </DialogActions>
        </>
      );
    };

    const renderRefreshTokenNotSetDialogIfNeeded = () => {
      if (hasSetRefreshToken) {
        return <></>;
      }

      const errorHint = isFeatureEnabled("refresh-token-migration")
        ? `SEVAL Refresh Token is not well set for your account with error: ${refreshTokenError}`
        : `3S Refresh Token is not well set for your account with error: ${refreshTokenError}`;

      return (
        <>
          <DialogTitle>{`Refresh Token not set`}</DialogTitle>
          <DialogContent>
            <Body1>
              {errorHint}
              <br />
              Please click the Setting icon in the top right to set it up first.
            </Body1>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                props.onCancel();
              }}
              appearance="primary"
            >
              OK
            </Button>
          </DialogActions>
        </>
      );
    };

    return (
      <>
        <Dialog
          open={isOpen}
          onOpenChange={(_, data) => {
            if (data.open === false) {
              props.onCancel();
            }
          }}
        >
          <DialogSurface>
            {isLoading && <Spinner />}
            {!isLoading && (
              <DialogBody>
                {renderRefreshTokenNotSetDialogIfNeeded()}
                {renderQuerySetUploadingDialogIfNeeded()}
                {renderQuerySetSubmittedDialogIfNeeded()}
              </DialogBody>
            )}
          </DialogSurface>
        </Dialog>
      </>
    );
  },
);
