import {
  fileUrlPrefix_bingJobConfig_new,
  fileUrlPrefix_bingJobConfig_old,
  fileUrlPrefix_bingQuerySet_new,
  fileUrlPrefix_bingQuerySet_old,
} from "../../../constants/constants";

/**
 *
 * @param currentUrl
 * Old Job Config	azureml://datastores/sevaldatastore/paths/jobs/bing/{user_id}/{job_name}.json
 * New Job Config	azureml://datastores/workspaceblobstore/paths/SevalPortal/jobs/{user_id}/{job_name}.json
 * @returns
 */
export const updatedUrl_bingJobConfig = (currentUrl: string) => {
  if (currentUrl.startsWith(fileUrlPrefix_bingJobConfig_old)) {
    return currentUrl.replaceAll(
      fileUrlPrefix_bingJobConfig_old,
      fileUrlPrefix_bingJobConfig_new,
    );
  }

  return currentUrl;
};

/**
 *
 * @param settings
 * Old Query Set	azureml://datastores/sevaldatastore/paths/queryset/{user_id}/{query_set_name}.json
 * New Query Set	azureml://datastores/workspaceblobstore/paths/SevalPortal/queryset/{user_id}/{query_set_name}.json
 * @returns
 */
export const updatedJobSettingsIfNeeded = (settings: string) => {
  if (settings.indexOf(fileUrlPrefix_bingQuerySet_old) >= 0) {
    return settings.replaceAll(
      fileUrlPrefix_bingQuerySet_old,
      fileUrlPrefix_bingQuerySet_new,
    );
  }

  return settings;
};
