import type {
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from "@fluentui/react-components";
import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  Spinner,
  Tab,
  TabList,
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import React from "react";
import { TemplateType } from "sydneyeval-shared";
import { StatusTile } from "../../../../../components/Shared/StatusTile";
import { Tip } from "../../../../../components/Shared/Tip";
import {
  getErrorMessageForFailedJob,
  getErrorNodeForFailedJob,
  getJobOutputFileContent,
} from "../../../../../helpers/apiHelper";
import type { Job } from "../../../models/Job";
const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "50px 20px",
    rowGap: "20px",
  },
  panels: {
    padding: "0 10px",
    "& th": {
      textAlign: "left",
      padding: "0 30px 0 0",
    },
  },
  dialogSurface: {
    width: "672px",
    maxHeight: "588px",
    maxWidth: "672px",
  },
  dialogBody: {
    width: "624px",
    maxHeight: "492px",
  },
  dismissButton: {
    border: "none",
    float: "right",
  },
});

type JobStatusCellProps = {
  job: Job | undefined;
};

type ErrorMsg = {
  message: string;
};

const SelectedTabContent = (prop: ErrorMsg) => {
  return (
    <div role="tabpanel" aria-labelledby="ErrorMessage">
      {prop.message}
    </div>
  );
};

export const JobStatusCell = (prop: JobStatusCellProps) => {
  const styles = useStyles();
  const { job } = prop;
  const [isOpened, setIsOpened] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>(
    job?.Message ?? "",
  );
  const [errorNode, setErrorNode] = React.useState<string>("unknown");
  const [selectedTab, setSelectedTab] =
    React.useState<TabValue>("ErrorMessage");

  const errormsg = { message: errorMessage };
  const logText = { message: errorNode };
  const onTabSelect = (_event: SelectTabEvent, data: SelectTabData) => {
    setSelectedTab(data.value);
  };
  if (job === undefined) {
    return <></>;
  }
  let shouldShowErrorDetailForBingProduct =
    (job.JobTemplateType === TemplateType.BingV2 && job.Status === "Failed") ||
    (job.JobTemplateType === TemplateType.CWC && job.Status === "Failed");
  const status = job.Status;

  let toolTipContent = "";

  if (status === "SubmitFailed") {
    toolTipContent = "Job submit failed error message";
  } else if (status === "Failed") {
    toolTipContent = "Job failed error message";
  }

  if (status === undefined) {
    return <></>;
  }

  if (toolTipContent.length === 0) {
    return <StatusTile hasClickEvent={false} status={status} />;
  }

  const getError = () => {
    if (
      status === "Failed" &&
      job.JobId !== undefined &&
      job.JobTemplateType !== undefined
    ) {
      setIsLoading(true);
      // Get Error Message
      getErrorMessageForFailedJob({
        JobId: job.JobId,
        JobTemplateType: job.JobTemplateType,
      }).then((res) => {
        setErrorMessage(res ?? "Unknown reason");
      });

      // Get Error Detail for Bing Product
      if (shouldShowErrorDetailForBingProduct) {
        getErrorNodeForFailedJob({
          JobId: job.JobId,
          JobTemplateType: job.JobTemplateType,
        })
          .then((jobid) => {
            if (jobid) {
              getJobOutputFileContent(
                "../azureml/ExperimentRun/dcid." +
                  jobid +
                  "/user_logs/std_log.txt",
              )
                .then((res) => {
                  setErrorNode(res ?? "Unavailable");
                })
                .catch(() => {
                  shouldShowErrorDetailForBingProduct = false;
                  setIsLoading(false);
                });
            }
          })
          .catch(() => {
            shouldShowErrorDetailForBingProduct = false;
            setIsLoading(false);
          });
      }
      setIsLoading(false);
    }
  };

  const renderError = () => {
    if (shouldShowErrorDetailForBingProduct) {
      return (
        <DialogBody className={styles.dialogBody}>
          <DialogTitle>
            <DialogTrigger disableButtonEnhancement>
              <Button
                className={styles.dismissButton}
                icon={<DismissRegular />}
              />
            </DialogTrigger>
            <TabList
              defaultSelectedValue="ErrorMessage"
              onTabSelect={onTabSelect}
            >
              <Tab value="ErrorMessage">Error Message</Tab>
              <Tab value="LogText">Error Details</Tab>
            </TabList>
          </DialogTitle>
          <DialogContent>
            <div className={styles.panels}>
              {selectedTab === "ErrorMessage" && (
                <SelectedTabContent {...errormsg} />
              )}
              {selectedTab === "LogText" && <SelectedTabContent {...logText} />}
            </div>
          </DialogContent>
        </DialogBody>
      );
    } else {
      return (
        <DialogBody className={styles.dialogBody}>
          <DialogTitle>
            Error Message
            <DialogTrigger disableButtonEnhancement>
              <Button
                className={styles.dismissButton}
                icon={<DismissRegular />}
              />
            </DialogTrigger>
          </DialogTitle>
          <DialogContent>{errorMessage}</DialogContent>
        </DialogBody>
      );
    }
  };

  // TODO: This is the temporary UX solution for showing error message.
  // Need to update it after we have the final UX design.
  return (
    <Dialog
      modalType="modal"
      open={isOpened}
      onOpenChange={(_, data) => {
        if (data.open !== isOpened) {
          setIsOpened(data.open);
        }
      }}
    >
      <Tip content={toolTipContent} relationship={"label"}>
        <StatusTile
          hasClickEvent={true}
          status={status}
          onClick={() => {
            setIsOpened(true);
            getError();
          }}
        />
      </Tip>
      <DialogSurface className={styles.dialogSurface}>
        {isLoading && <Spinner />}
        {!isLoading && renderError()}
      </DialogSurface>
    </Dialog>
  );
};
