import { compact } from "lodash";
import { computed } from "mobx";
import { isFeatureEnabled } from "../../../selectors/features";
import type { JobTemplate } from "../models/JobTemplate";
import { jobStore } from "../store/jobStore";
import { productSettingsStore } from "../store/productSettingsStore";
import { getBuildInProducts } from "./productSelectors";

const removeGeneratorTemplates = (templates: JobTemplate[]) => {
  return templates
    .filter(
      (_) =>
        ![
          "Bing_Test_Set_Generator",
          "BizChat-Personalized-Query-Set-Generator",
          "BizChat-Personalized-Query-Set-Generator_eyesoff",
          "BizChat-Personalized-Query-Set-Generator-V2",
          "BizChat-Personalized-Query-Set-Generator-V2_eyesoff",
        ].includes(_.ExperimentName),
    )
    .sort((a, b) => a.Name.localeCompare(b.Name));
};

export const getQuickFilterTemplates = computed(() => {
  if (!isFeatureEnabled("product-registration")) {
    const templates = (() => {
      switch (jobStore.quickTemplateTypeFilter) {
        case "All":
          return jobStore.availableTemplates;
        case "BizChat":
          return jobStore.availableTemplates.filter(
            (_) => _.Type !== "BingV2" && _.Type !== "CWC" && _.Type !== "OPG",
          );
        case "CWC":
          return jobStore.availableTemplates.filter((_) => _.Type === "CWC");
        case "OPG":
          return jobStore.availableTemplates.filter((_) => _.Type === "OPG");
      }
    })();

    return removeGeneratorTemplates(templates);
  } else {
    const builtInProducts = getBuildInProducts.get();
    const registeredProducts = productSettingsStore.products;
    const products = builtInProducts.concat(registeredProducts);
    const productFilter = jobStore.productFilter;
    const templates = jobStore.availableTemplates;

    const targetProducts = (() => {
      if (productFilter === undefined) {
        return products;
      } else {
        return products.filter((_) => _.id === productFilter);
      }
    })();

    // Generate Product Templates based on products
    const productTemplates = targetProducts.flatMap((product) => {
      return compact(
        product.templates.map((template) => {
          const targetTemplate = templates.find(
            (_) => _.ExperimentName === template.pipelineExpName,
          );

          if (targetTemplate === undefined) {
            return undefined;
          }

          return {
            ...targetTemplate,
            Name: template.displayName,
            ProductId: product.id,
            ProductPipelineId: template.id,
          };
        }),
      );
    });

    return removeGeneratorTemplates(productTemplates);
  }
});

export const getQuickFilterGroupedTemplates = computed(() => {
  if (!isFeatureEnabled("product-registration")) {
    return undefined;
  }

  const builtInProducts = getBuildInProducts.get();
  const registeredProducts = productSettingsStore.products;
  const products = builtInProducts.concat(registeredProducts);
  const productFilter = jobStore.productFilter;
  const templates = jobStore.availableTemplates;

  const targetProducts = (() => {
    if (productFilter === undefined) {
      return products;
    } else {
      return products.filter((_) => _.id === productFilter);
    }
  })();

  if (targetProducts.length === 0) {
    return undefined;
  }

  const targetProduct = targetProducts[0];

  if (targetProduct.groupedTemplates === undefined) {
    return undefined;
  }

  return targetProduct.groupedTemplates.map((group) => {
    return {
      group: group.group,
      templates: compact(
        group.templates.map((template) => {
          const targetTemplate = templates.find(
            (_) => _.ExperimentName === template.pipelineExpName,
          );

          if (targetTemplate === undefined) {
            return undefined;
          }

          return {
            ...targetTemplate,
            Name: template.displayName,
            ProductId: targetProduct.id,
            ProductPipelineId: template.id,
          };
        }),
      ),
    };
  });
});
