require("./orchestrators/index");

import {
  registerContribution,
  registerDebugGroupContribution,
  registerSettingsContribution,
} from "../../contribution/register";
import {
  offlineEvalContribution,
  offlineEvalDebugGroupContribution,
  offlineEvalSettingsContribution,
} from "./contribution";

registerContribution(offlineEvalContribution);
registerSettingsContribution(offlineEvalSettingsContribution);
registerDebugGroupContribution(offlineEvalDebugGroupContribution);
