import React, { type ReactNode, useState, useRef } from "react";

// Implment a draggable and resizable split view component
const SplitView = (props: {
  left: ReactNode;
  right: ReactNode;
  orientation: "horizontal" | "vertical";
  defaultSize?: number;
  height?: number | string;
  width?: number | string;
  leftStyle?: React.CSSProperties;
  rightStyle?: React.CSSProperties;
}) => {
  const [size, setSize] = useState(props.defaultSize || 70);

  const onMouseMove = (e: MouseEvent) => {
    if (!ref.current) {
      return;
    }
    const rect = ref.current.getBoundingClientRect();
    const newSize =
      props.orientation === "horizontal"
        ? ((e.clientX - rect.left) / rect.width) * 100
        : ((e.clientY - rect.top) / rect.height) * 100;
    setSize(newSize);
  };

  const onMouseUp = () => {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", onMouseUp);
  };

  const onMouseDown = () => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: props.orientation === "horizontal" ? "row" : "column",
        height: props.height || "100%",
        width: props.width || "100%",
      }}
      ref={ref}
    >
      <div
        style={{
          flex: `${size} 0 0`,
          overflow: "auto",
          ...props.leftStyle,
        }}
      >
        {props.left}
      </div>
      <div
        style={{
          width: props.orientation === "horizontal" ? "6px" : "100%",
          height: props.orientation === "horizontal" ? "100%" : "6px",
          color: "grey",
          cursor:
            props.orientation === "horizontal" ? "ew-resize" : "ns-resize",
          borderTop: props.orientation === "vertical" ? "double" : "",
          borderLeft: props.orientation === "horizontal" ? "double" : "",
          margin: "2px",
        }}
        onMouseDown={onMouseDown}
        data-testid="resizer"
      />
      <div
        style={{
          flex: `${100 - size} 0 0`,
          overflow: "auto",
          ...props.rightStyle,
        }}
      >
        {props.right}
      </div>
    </div>
  );
};

export default SplitView;
