import { useEffect, useState } from "react";
import type { MetricDefinition } from "./MetricDefinition";
import type { IBasicRow } from "./bizChatEvalDataProvider";
import { Button, Input, Label } from "@fluentui/react-components";
import { useStyles } from "./styles";

interface IQueryGeneratorProps<TRow> {
  metricDefinition: MetricDefinition<TRow>;
  row: IBasicRow<TRow>;
  triggerScraping: () => void;
}

const AllowedPlaceholders = [
  "<topic>",
  "<document>",
  "<file>",
  "<person>",
  "<meeting>",
  "<email>",
];

export function isEmptyString(value: string | undefined) {
  return value === undefined || value.trim() === "";
}

export function QueryGenerator<TRow extends IBasicRow<TRow>>(
  props: IQueryGeneratorProps<TRow>,
) {
  const [filledQuery, setFilledQuery] = useState<string>("");
  const [placeholders, setPlaceholders] = useState<string[]>([]);
  const [placeholderValues, setPlaceholderValues] = useState<{
    [key: string]: string;
  }>({});
  const { row, triggerScraping } = props;

  const styles = useStyles();

  useEffect(() => {
    const _placeholders = AllowedPlaceholders.filter((p) =>
      row.query.includes(p),
    );
    setFilledQuery(
      isEmptyString(row.filledQuery) ? row.query : row.filledQuery ?? "",
    );
    row.filledQuery = isEmptyString(row.filledQuery)
      ? row.query
      : row.filledQuery;
    if (_placeholders.length > 0) {
      setPlaceholderValues(row.placeholderValues || {});
    }
    setPlaceholders(_placeholders);

    // Triger auto scraping if not scrapped before and no placeholder.
    if (
      (_placeholders.length === 0 ||
        _placeholders.length ===
          Object.entries(row.placeholderValues || {}).length) &&
      !row.conversation
    ) {
      triggerScraping();
    }
  }, [row]);

  if (!row) return <></>;
  return (
    <>
      {placeholders.length > 0 && (
        <>
          <div style={{ display: "flex" }}>
            <Label className={styles.label} style={{ width: "10em" }}>
              Query template
            </Label>
            <div style={{ marginTop: "0.3rem" }}>{row.query}</div>
          </div>
          {placeholders.map((p) => (
            <div key={p} style={{ display: "flex" }}>
              <Label className={styles.label} style={{ width: "10em" }}>
                {p}
              </Label>
              <Input
                key={"placeholder" + p}
                value={placeholderValues[p] || ""}
                onChange={(ev, value) => {
                  const newPlaceholderValues = { ...placeholderValues };
                  newPlaceholderValues[p] = value.value;
                  setPlaceholderValues(newPlaceholderValues);
                  let _filledQuery = row.query;
                  for (const key in newPlaceholderValues) {
                    _filledQuery = _filledQuery.replace(
                      key,
                      newPlaceholderValues[key],
                    );
                  }
                  row.filledQuery = _filledQuery;
                  row.placeholderValues = newPlaceholderValues;
                  if (row.ControlRow) {
                    row.ControlRow.filledQuery = _filledQuery;
                    row.ControlRow.placeholderValues = newPlaceholderValues;
                  }
                  setFilledQuery(_filledQuery);
                }}
              ></Input>
            </div>
          ))}
        </>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <Label className={styles.label} style={{ width: "10em" }}>
          Query
        </Label>
        <div
          style={{
            marginTop: "0.3rem",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "0.5rem",
            minWidth: "10em",
          }}
        >
          {filledQuery ?? row.query}
        </div>
        <Button
          style={{ marginLeft: "1em", maxHeight: "2.5em" }}
          onClick={() => {
            // Before click the button to trigger a force scraping, check if there's any existent not resolved scraping promise.
            if (
              (!row.conversation && row.scrapingPromise) ||
              (props.metricDefinition.name === "SBSLeo" &&
                !row.ControlRow?.conversation &&
                row.ControlRow?.scrapingPromise)
            ) {
              alert("Please wait for current running to finish");
              return;
            }
            row.conversation = "";
            row.scrapingPromise = undefined;
            if (props.metricDefinition.name === "SBSLeo" && row.ControlRow) {
              row.ControlRow.conversation = "";
              row.ControlRow.scrapingPromise = undefined;
            }

            triggerScraping();
          }}
        >
          Run
        </Button>
      </div>
    </>
  );
}
