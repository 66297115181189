import {
  Overflow,
  OverflowItem,
  type OverflowItemProps,
} from "@fluentui/react-components";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  useIsOverflowItemVisible,
  useOverflowMenu,
} from "@fluentui/react-components";
import React from "react";
import { Button } from "@fluentui/react-button";

export interface CollapseButtonItemProps extends Pick<OverflowItemProps, "id"> {
  title: string;
  onClick: () => void;
}

export interface OverflowMenuItemProps extends Pick<OverflowItemProps, "id"> {
  title: string;
  index: number; // to keep the original index of the item
  priority: number;
  isVisible?: boolean;
  onClick?: () => void;
}

interface OverflowMenuProps {
  items: OverflowMenuItemProps[];
  onSelectItemChange: (index: number) => void;
  onDisplayItemsChange: (items: OverflowMenuItemProps[]) => void;
}

export const getDisplayItems = <T extends OverflowMenuItemProps>(
  items: T[],
  selectedIndex: number,
  overflowCount: number,
) => {
  const showingCount = items.length - overflowCount;
  const endIndex = Math.min(selectedIndex + showingCount, items.length);
  const startIndex =
    endIndex < items.length ? selectedIndex : items.length - showingCount;
  const displayItems: T[] = [];
  const menuItems: T[] = [];
  for (const item of items) {
    if (item.index >= startIndex && item.index < endIndex) {
      displayItems.push({
        ...item,
        isVisible: true,
      });
    } else {
      menuItems.push({
        ...item,
        isVisible: false,
      });
    }
  }

  // sort the items by the original order
  displayItems.sort((a, b) => a.index - b.index);
  menuItems.sort((a, b) => a.index - b.index);
  // update the priority of the items
  const newOrderItems = [...displayItems, ...menuItems].map((item, index) => {
    return {
      ...item,
      priority: items.length - 1 - index,
    };
  });
  // newOrderItems.sort((a, b) => a.index - b.index);
  return newOrderItems;
};

const OverflowMenuItem: React.FC<OverflowMenuItemProps> = (props) => {
  const { id, isVisible, onClick } = props;

  if (isVisible ?? useIsOverflowItemVisible(id)) {
    return null;
  }

  return <MenuItem onClick={onClick}>{props.title}</MenuItem>;
};

export const OverflowMenu: React.FC<OverflowMenuProps> = ({
  items,
  onSelectItemChange,
  onDisplayItemsChange,
}) => {
  const { ref, overflowCount, isOverflowing } =
    useOverflowMenu<HTMLButtonElement>();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [displayItems, setDisplayItems] = React.useState([...items]);

  React.useEffect(() => {
    const newDisplayItems = getDisplayItems(
      items,
      selectedIndex,
      overflowCount,
    );
    setDisplayItems(newDisplayItems);
    onDisplayItemsChange(newDisplayItems);
  }, [overflowCount]);

  const handleItemClick = (item: OverflowMenuItemProps) => {
    const originalItems = items.slice().sort((a, b) => a.index - b.index);
    const index = originalItems.indexOf(item);
    setSelectedIndex(index);
    onSelectItemChange(index);
    const newDisplayItems = getDisplayItems(
      originalItems,
      index,
      overflowCount,
    );
    setDisplayItems(newDisplayItems);
    onDisplayItemsChange(newDisplayItems);
    if (item.onClick) {
      item.onClick();
    }
  };

  if (!isOverflowing) {
    return null;
  }

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <MenuButton ref={ref}>+{overflowCount} items</MenuButton>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          {displayItems.map((item) => {
            return (
              <OverflowMenuItem
                key={item.id}
                id={item.id}
                title={item.title}
                isVisible={item.isVisible}
                index={item.index}
                priority={item.priority}
                onClick={() => handleItemClick(item)}
              />
            );
          })}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export const CollapseButtons: React.FC<{
  items: CollapseButtonItemProps[];
}> = ({ items }) => {
  const [displayItems, setDisplayItems] = React.useState<
    OverflowMenuItemProps[]
  >([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0); // default selected 0

  React.useEffect(() => {
    const formattedItems = items.map((item, index) => {
      return {
        ...item,
        index: index,
        priority: items.length - index,
      };
    });
    setDisplayItems([...formattedItems]);
  }, []);

  return (
    <Overflow>
      <div>
        {displayItems.map((item) => (
          <OverflowItem
            key={`display${item.id}`}
            id={`display${item.id}`}
            priority={item.priority}
          >
            <Button
              appearance={item.index === selectedIndex ? "primary" : undefined}
              onClick={() => {
                setSelectedIndex(item.index);
                if (item.onClick) {
                  item.onClick();
                }
              }}
            >
              {item.title}
            </Button>
          </OverflowItem>
        ))}
        <OverflowMenu
          items={displayItems}
          onSelectItemChange={setSelectedIndex}
          onDisplayItemsChange={setDisplayItems}
        />
      </div>
    </Overflow>
  );
};
