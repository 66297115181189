import { orchestrator } from "satcheljs";
import { fetchUserInfo } from "../actions/substrateActions";
import { getSubstrateToken } from "../helpers/accessTokenHelper";
import { searchUserById } from "../helpers/apiHelper";
import { getRandomUUID } from "../helpers/getRandomUUID";
import { isSearchUserSuccessResponse } from "../models/SearchUserByIdResponse";
import {
  cleanCachedUser,
  updateCachedUserLoading,
  updateCachedUserSuccess,
} from "../mutators/updateSubstrateCache";
import { substrateStore } from "../store/substrateStore";

orchestrator(fetchUserInfo, ({ id }) => {
  // If the user info already exists in the cache, skip it.
  if (substrateStore.userCache[id] !== undefined) {
    return;
  }

  // Otherwise, fetch it from the server.
  updateCachedUserLoading(id);

  getSubstrateToken().then((token) => {
    searchUserById({
      SubstrateToken: token,
      UserIds: [id],
      CallerFileNameLine: "ShareButton",
      ClientRequestId: getRandomUUID(),
      ProcessName: "SEVAL",
    })
      .then((searchResponse) => {
        const searchResults = Object.values(searchResponse);
        for (const searchResult of searchResults) {
          if (isSearchUserSuccessResponse(searchResult)) {
            const userId = searchResult.ObjectId;
            updateCachedUserSuccess(userId, searchResult);
          }
        }
      })
      .catch(() => {
        cleanCachedUser(id);
      });
  });
});
