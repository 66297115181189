import { makeStyles, shorthands } from "@fluentui/react-components";

export const tableCellSharedStyle = makeStyles({
  metricNameCell: {
    width: "200px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
  },
  subTitleCell: {
    fontSize: "12px",
  },
  utteranceHeaderCell: {
    paddingLeft: "13px",
  },
  subTableCell: {
    paddingLeft: "calc(0.25rem + 36px)",
    paddingRight: "0.75rem",
  },
  positiveCell: {
    backgroundColor: "rgba(85, 177, 126, 0.30)",
  },
  negativeCell: {
    backgroundColor: "rgba(221, 66, 66, 0.30)",
  },
  tbody: {
    backgroundColor: "white",
  },
  searchIcon: {
    verticalAlign: "bottom",
  },
  utteranceCell: {
    minWidth: "200px",
  },
  utterance: {
    justifyContent: "space-between",
    ":hover Button": {
      display: "inline-flex",
    },
    ":not(:hover) Button": {
      display: "none",
    },
  },
  metricFeedbackButton: {
    marginLeft: "auto",
    height: "16px",
  },
  privacyFilterFeedbackButton: {
    height: "16px",
    width: "16px",
    display: "inline-flex",
    alignItems: "center",
    marginLeft: "10px",
  },
  fitContentWidth: {
    width: "fit-content",
  },
  spaceBetweenRow: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  errorIcon: {
    display: "flex",
    alignItems: "center",
  },
  spinner: {
    marginTop: "20px",
    height: "100px",
  },
});
