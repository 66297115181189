export type VoteType = "Majority" | "Consensus";

export const SBSLeoRightWinLabels = ["RightBetter", "RightSlightlyBetter"];
export const SBSLeoLeftWinLabels = ["LeftBetter", "LeftSlightlyBetter"];

export interface MetricsModel {
  treatmentWins?: number;
  controlWins?: number;
  same?: number;
  total?: number;
  isMultiLabels?: boolean;
  errorMsg?: string;
  isReady?: boolean;
}

const calculateMetricsByConversationGroup = (
  metricsResultMap: Map<string, MetricsModel>,
  isMultiLabels: boolean,
  voteType?: VoteType,
) => {
  const totalCount = metricsResultMap.size;
  let treatmentWinsCount = 0;
  let controlWinsCount = 0;
  let sameCount = 0;
  for (const metrics of metricsResultMap.values()) {
    if (metrics.same === metrics.total) {
      sameCount++;
      continue;
    }

    if (isMultiLabels && voteType === "Majority") {
      // treatmentWins and controlWins must have a value, it's just to resolve eslint errors
      if (
        (metrics?.treatmentWins ?? 0) > 0 &&
        (metrics?.treatmentWins ?? 0) >= (metrics?.controlWins ?? 0)
      ) {
        treatmentWinsCount++;
      } else if (
        (metrics?.controlWins ?? 0) > 0 &&
        (metrics?.controlWins ?? 0) > (metrics?.treatmentWins ?? 0)
      ) {
        controlWinsCount++;
      }
    } else if (isMultiLabels && voteType === "Consensus") {
      if ((metrics.treatmentWins ?? 0) > 0 && metrics.controlWins === 0) {
        treatmentWinsCount++;
      } else if (
        (metrics.controlWins ?? 0) > 0 &&
        metrics.treatmentWins === 0
      ) {
        controlWinsCount++;
      }
    } else {
      // one conversation one label, so one is 1, and another is 0
      treatmentWinsCount += metrics.treatmentWins ?? 0;
      controlWinsCount += metrics.controlWins ?? 0;
    }
  }

  const metrics = {
    treatmentWins: treatmentWinsCount,
    controlWins: controlWinsCount,
    same: sameCount,
    total: totalCount,
    isMultiLabels: isMultiLabels,
    isReady: true,
  } as MetricsModel;

  return metrics;
};

export const calculateMetrics = (
  feedbackList: Record<string, string | number>[],
  voteType?: VoteType,
  rightWinsLabels = SBSLeoRightWinLabels,
  leftWinsLabels = SBSLeoLeftWinLabels,
): MetricsModel => {
  const metricsResultMap = new Map<string, MetricsModel>();
  let isMultiLabels = false;
  for (const feedback of feedbackList) {
    if (!feedback.comparison_result || !feedback.unique_key_treatment) {
      continue;
    }
    const key = feedback.unique_key_treatment as string;
    let metrics = metricsResultMap.get(key);
    if (!metrics) {
      metrics = {
        treatmentWins: 0,
        controlWins: 0,
        same: 0,
        total: 1,
      };
      metricsResultMap.set(key, metrics);
    } else {
      isMultiLabels = true;
      metrics.total = (metrics.total ?? 0) + 1;
    }

    const comparisonResult = feedback.comparison_result as string;
    if (comparisonResult === "Same") {
      metrics.same = (metrics.same ?? 0) + 1;
      continue;
    }

    let treatmentWinsLabels = new Set(rightWinsLabels);
    let controlWinsLabels = new Set(leftWinsLabels);
    if (feedback.random_flip === "1") {
      treatmentWinsLabels = new Set(leftWinsLabels);
      controlWinsLabels = new Set(rightWinsLabels);
    }
    if (treatmentWinsLabels.has(comparisonResult)) {
      metrics.treatmentWins = (metrics.treatmentWins ?? 0) + 1;
    } else if (controlWinsLabels.has(comparisonResult)) {
      metrics.controlWins = (metrics.controlWins ?? 0) + 1;
    }
  }

  return calculateMetricsByConversationGroup(
    metricsResultMap,
    isMultiLabels,
    voteType,
  );
};
