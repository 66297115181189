import { computed } from "mobx";

import type { AccountInfo } from "@azure/msal-browser";
import { createStore } from "satcheljs";
import type { ClientFeatureFlagSets } from "sydneyeval-shared";
import { ClientFeatureFlagDefaults } from "sydneyeval-shared";
import {
  getFeatureFlagOverrides,
  getFlightedFeatureFlags,
} from "../helpers/cacheHelper";
import { getRandomUUID } from "../helpers/getRandomUUID";
import type { Permission } from "../models/Permission";

export type HumanCorrelationStudyScenario = {
  path: string;
};

export const Themes = ["Light", "Dark"] as const;
export type Theme = (typeof Themes)[number];

interface IStore {
  account: AccountInfo | undefined;
  tokenRefreshError: string | undefined;
  permission: Permission | undefined;
  consented: boolean | undefined;
  sessionId: string;
  features: {
    default: ClientFeatureFlagSets;
    flights: ClientFeatureFlagSets;
    overrides: ClientFeatureFlagSets;
  };
  sidePane: {
    render: (() => JSX.Element) | undefined;
    size: "narrow" | "wide";
  };
  develop: {
    isSettingSubstrateToken: boolean;
    isSettingHeronAccessToken: boolean;
    isSettingAvalonAccessToken: boolean;
    tokenResolver: ((token: string) => void) | undefined;
  };
}

export const initialStore: IStore = {
  account: undefined,
  tokenRefreshError: undefined,
  permission: undefined,
  consented: undefined,
  sessionId: getRandomUUID(),
  features: {
    default: ClientFeatureFlagDefaults,
    flights: getFlightedFeatureFlags(),
    overrides: getFeatureFlagOverrides(),
  },
  sidePane: {
    render: undefined,
    size: "wide",
  },
  develop: {
    isSettingSubstrateToken: false,
    isSettingHeronAccessToken: false,
    isSettingAvalonAccessToken: false,
    tokenResolver: undefined,
  },
};

export const store = createStore<IStore>(
  "store",
  Object.assign({}, initialStore),
)();

export const computedUserId = computed(() => store.account?.idTokenClaims?.oid);
