import type { MetricDefinition } from "../MetricDefinition";
import type { NDCGRow } from "../bizChatEvalDataProvider";
import {
  ControllableNav,
  renderRawData,
} from "../groundleo/GroundLeoDefinition";

export const getCustomizedExportData = (rows: NDCGRow[]) => {
  return rows.map((row) => {
    return {
      ...row,
      conversation: "",
      label_ndcg: row.label_ndcg || "",
    };
  });
};

export const NDCGDefinition: MetricDefinition<NDCGRow> = {
  name: "NDCG",
  url: "ndcg",
  fetchData: undefined,
  nav: ControllableNav,
  renderHumanLabel: undefined,
  renderLLMLabel: undefined,
  renderRawData: renderRawData,
  allowLocalUpload: true,
  getCustomizedExportData: getCustomizedExportData,
  humanLabelFieldNames: ["label_ndcg"],
};
