import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Dropdown,
  Field,
  InfoLabel,
  Input,
  Option,
  Switch,
  Textarea,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import {
  Checkmark24Regular,
  CloudCheckmark16Regular,
  Delete16Regular,
  Info16Regular,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { parseJsonStrToObject } from "sydneyeval-shared";
import {
  BingQuerySetGenerationRAIMetrics,
  bingAzureStorageUrlRoot,
  folderPath_bingQueryGenerate,
  folderPath_bingQuerySet,
} from "../../constants/constants";
import { createJob, getJobTemplates } from "../../helpers/apiHelper";
import { telemetryHelper } from "../../helpers/telemetryHelper";
import type { BingGenerateTestSet } from "../../models/BingGenerateTestSet";
import { uploadFile } from "../../partners/OfflineEval/actions/uploadFile";
import { generateFormattedDateString } from "../../partners/OfflineEval/helpers/formatHelper";
import type { JobTemplate } from "../../partners/OfflineEval/models/JobTemplate";
import { store } from "../../store/store";
import { Tip } from "../Shared/Tip";
import { useToast } from "../Wrappers/ToasterProvider";

const useStyle = makeStyles({
  root: {
    height: "45px",
  },
  comboboxWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    ...shorthands.gap("10px"),
  },
  combobox: {
    ...shorthands.flex(1),
  },
  selectedTabTitle: {
    fontSize: "14px",
    fontWeight: 600,
    color: "rgba(36, 36, 36, 1)",
  },
  normalTabTitle: {
    fontSize: "14px",
    fontWeight: 400,
    color: "rgba(66, 66, 66, 1)",
  },
  titleDescription: {
    fontSize: "14px",
    fontWeight: 400,
    color: "rgba(96, 94, 92, 1)",
  },
  subtitleField: {
    marginTop: "10px",
  },
  subtitleLabel: {
    fontSize: "16px",
  },
  checkMarkIcon: {
    marginBottom: "-5px",
    marginRight: "10px",
    color: "rgba(33, 141, 81, 1)",
  },
  documentIcon: {
    ...shorthands.margin("0px", "5px"),
  },
  submittedDescription: {
    marginTop: "26px",
    marginLeft: "33px",
    marginRight: "10px",
  },
  divider: {
    ...shorthands.margin("10px", "0px"),
  },
  rowWithSmallGap: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    ...shorthands.gap("16px"),
  },
  ruleTitleRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ruleRow: {
    "> Button": {
      visibility: "hidden",
    },
    ":hover Button": {
      visibility: "visible",
    },
    alignItems: "center",
  },
  margin: {
    ...shorthands.margin("10px", "0px"),
  },
  inputWidth: {
    width: "100%",
    minWidth: "90%",
  },
  deleteButton: {
    height: "40px",
    minWidth: "10px",
  },
  enginesContainer: {
    flexBasis: "44%",
    ...shorthands.gap("8px"),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  headerTitle: {
    ...shorthands.padding("0", "10px"),
    ...shorthands.borderLeft("5px", "solid", tokens.colorBrandBackground),
  },
  uploadSuccessIcon: {
    color: tokens.colorPaletteGreenForeground1,
    ...shorthands.padding("0", "10px"),
  },
  optionsTextArea: {
    height: "200px",
  },
  metadataTextArea: {
    height: "100px",
  },
});

interface IUploadingFileDialogProps {
  isOpen: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

interface Rules {
  id: number;
  segmentName: string | undefined;
  rule: string | undefined;
}

type UploadFileType = "Manifest" | "API" | "Config";

const defaultOptionsObject: {
  url: string;
  option_sets: string;
  options: object;
} = {
  url: "https://services.bingapis.com/sydney",
  option_sets: "nocache,h3imaginative,dv3sugg",
  options: {
    DeepLeoTimeout: "0:10:00",
    DeepLeoUrl: {
      default:
        "https://prom-d-nd40.southcentralus.inference.ml.azure.com/v1/engines/davinci/completions",
    },
  },
};

export const BingQuerySetGenerationDialog = observer(
  (props: IUploadingFileDialogProps) => {
    const styles = useStyle();
    const toaster = useToast();
    const { isOpen } = props;
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [generatedJob, setGeneratedJob] = React.useState<BingGenerateTestSet>(
      {},
    );

    const [isSingleTurn, setIsSingleTurn] = React.useState(true);
    const [singleRule, setSingleRule] = React.useState<Rules[]>([
      { id: 0, rule: undefined, segmentName: undefined },
    ]);
    const [multiRule, setMultiRule] = React.useState<Rules[]>([
      { id: 0, rule: undefined, segmentName: undefined },
    ]);

    const [genName, setGenName] = React.useState<string>("");
    const [isIDEntry, setIsIdEntry] = React.useState(false);
    const [taskId, setTaskId] = React.useState("");

    const [manifestPath, setManifestPath] = React.useState<string | undefined>(
      undefined,
    );

    const [APIPath, setAPIPath] = React.useState<string | undefined>(undefined);

    const manifestInputRef = useRef<HTMLInputElement>(null);
    const APIInputRef = useRef<HTMLInputElement>(null);

    const [selectedManifestFile, setSelectedManifestFile] = React.useState<
      File | undefined
    >(undefined);

    const [selectedAPIFile, setSelectedAPIFile] = React.useState<
      File | undefined
    >(undefined);

    const [manifestIsUploaded, setManifestIsUploaded] = React.useState(false);
    const [APIIsUploaded, setAPIIsUploaded] = React.useState(false);

    const [currentTemplate, setCurrentTemlate] = React.useState<
      JobTemplate | undefined
    >(undefined);

    const [jsonData, setJSONData] = React.useState("{}");

    const [isRAIOverride, setIsRAIOverride] = React.useState(false);

    const [RAIMetric, setRAIMetric] = React.useState<string | undefined>(
      undefined,
    );

    const [queryNum, setQueryNum] = React.useState<number>(150);

    const [metadata, setMetadata] = React.useState<string | undefined>(
      undefined,
    );

    const [metaJsonData, setMetaJSONData] = React.useState("{}");

    useEffect(() => {
      if (!isOpen) {
        return;
      }

      setIsSubmitted(false);
      setGenName("");
      setTaskId(generateFormattedDateString());
      setGeneratedJob({});
      setManifestPath(undefined);
      setAPIPath(undefined);
      setManifestIsUploaded(false);
      setAPIIsUploaded(false);
      setSingleRule([{ id: 0, segmentName: undefined, rule: undefined }]);
      setMultiRule([{ id: 0, segmentName: undefined, rule: undefined }]);
      const defaultOption = {
        DeepLeoTimeout: "0:10:00",
        DeepLeoUrl: {
          default:
            "https://prom-d-nd40.southcentralus.inference.ml.azure.com/v1/engines/davinci/completions",
        },
      };
      setEngine({
        url: "https://services.bingapis.com/sydney",
        option_sets: "nocache,h3imaginative,dv3sugg",
        options: defaultOption,
      });
      setJSONData(JSON.stringify(defaultOption, null, 2));
      getJobTemplates().then((jobTemplates) => {
        const generator = jobTemplates.find(
          (item) => item.Name === "Bing_Test_Set_Generator",
        );
        setCurrentTemlate(generator);
      });
      setRAIMetric(undefined);
    }, [isOpen]);

    const [engine, setEngine] = React.useState(defaultOptionsObject);

    const handleDialogCancel = () => {
      props.onCancel();
    };

    const renderTurnRule = (isSingleTurnMode: boolean) => {
      if (isSingleTurn && !isSingleTurnMode) {
        return <></>;
      }
      const currentRules = isSingleTurnMode ? singleRule : multiRule;
      const maxId = Math.max(
        ...currentRules.map((item) =>
          item.id === currentRules.length ? item.id + 1 : item.id,
        ),
        currentRules.length,
      );
      const addRule = () => {
        if (isSingleTurnMode) {
          setSingleRule((prev) => [
            ...prev,
            { id: maxId, segmentName: undefined, rule: undefined },
          ]);
        } else {
          setMultiRule((prev) => [
            ...prev,
            { id: maxId, segmentName: undefined, rule: undefined },
          ]);
        }
      };

      const removeRule = (id: number) => {
        const newRule = currentRules.filter((item) => item.id !== id);
        isSingleTurnMode ? setSingleRule(newRule) : setMultiRule(newRule);
      };
      return (
        <div>
          <div>
            <strong>
              {isSingleTurnMode ? "Single Turn Rules" : "Multi Turn Rules"}
            </strong>
          </div>
          {currentRules.map((rule) => (
            <div
              key={rule.id}
              className={mergeClasses(styles.rowWithSmallGap, styles.ruleRow)}
            >
              <div className={styles.inputWidth}>
                <Field className={styles.subtitleField} label={"Segment Name"}>
                  <Input
                    placeholder="<Segment Name>"
                    value={rule.segmentName}
                    onChange={(e) => {
                      const newRule = currentRules.map((item) => {
                        if (item.id === rule.id) {
                          return { ...item, segmentName: e.target.value };
                        }
                        return item;
                      });
                      isSingleTurnMode
                        ? setSingleRule(newRule)
                        : setMultiRule(newRule);
                    }}
                  />
                </Field>
                <Field className={styles.subtitleField} label={"Rule"}>
                  <Textarea
                    placeholder="<Rule>"
                    value={rule.rule}
                    onChange={(e) => {
                      const newRule = currentRules.map((item) => {
                        if (item.id === rule.id) {
                          return { ...item, rule: e.target.value };
                        }
                        return item;
                      });
                      isSingleTurnMode
                        ? setSingleRule(newRule)
                        : setMultiRule(newRule);
                    }}
                  />
                </Field>
              </div>
              <Button
                className={styles.deleteButton}
                icon={<Delete16Regular />}
                onClick={() => removeRule(rule.id)}
              ></Button>
            </div>
          ))}
          <Button className={styles.margin} onClick={() => addRule()}>
            Add Rule
          </Button>
        </div>
      );
    };

    const renderTargetExp = () => {
      if (isSingleTurn) {
        return <></>;
      }
      return (
        <div>
          <div>
            <strong>
              Sydney Configuration
              <Tip
                content="Target Sydney details for the feature for which query set is needed - Creative mode by default"
                relationship="label"
              >
                <Button
                  icon={<Info16Regular />}
                  appearance="transparent"
                  size="small"
                />
              </Tip>
            </strong>
          </div>
          <div className={styles.enginesContainer}>
            <InfoLabel required className={styles.subtitleField}>
              Endpoint:
              <Tip
                content="Sydney endpoint corresponding to target tone - Creative by default."
                relationship="label"
              >
                <Button
                  icon={<Info16Regular />}
                  appearance="transparent"
                  size="small"
                />
              </Tip>
            </InfoLabel>

            <Input
              aria-label="The url used for scraping."
              size="medium"
              value={engine["url"]}
              onChange={(_, data) => {
                setEngine({
                  ...engine,
                  url: data.value,
                });
              }}
            />
            <InfoLabel className={styles.subtitleField}>
              OptionSet:
              <Tip
                content="OptionSet to enable for your target feature - Creative optionsets by default"
                relationship="label"
              >
                <Button
                  icon={<Info16Regular />}
                  appearance="transparent"
                  size="small"
                />
              </Tip>
            </InfoLabel>
            <Input
              aria-label="The option set for this engine."
              size="medium"
              value={engine.option_sets}
              onChange={(_, data) => {
                setEngine({
                  ...engine,
                  option_sets: data.value,
                });
              }}
            />
            <InfoLabel className={styles.subtitleField}>
              Options:
              <Tip
                content="Options if needed to enable for your target feature - Creative options by default"
                relationship="label"
              >
                <Button
                  icon={<Info16Regular />}
                  appearance="transparent"
                  size="small"
                />
              </Tip>
            </InfoLabel>
            <Field
              className={styles.subtitleField}
              validationMessage={
                parseJsonStrToObject(jsonData) !== undefined
                  ? undefined
                  : "Invalid json"
              }
              validationState={
                parseJsonStrToObject(jsonData) !== undefined ? "none" : "error"
              }
            >
              <Textarea
                className={styles.optionsTextArea}
                aria-label="The option information in json format."
                size="medium"
                value={jsonData}
                onChange={(_, data) => {
                  const options = parseJsonStrToObject(data.value);
                  if (options !== undefined) {
                    setEngine({
                      ...engine,
                      options,
                    });
                  }
                  setJSONData(data.value);
                }}
              />
            </Field>
          </div>
        </div>
      );
    };

    const renderIdEntry = () => {
      if (!isIDEntry) {
        return <></>;
      }
      return (
        <div>
          <Field className={styles.subtitleField} required label={"Plugin Id"}>
            <Input
              placeholder="Plugin Id"
              value={generatedJob.plugin_id ?? ""}
              onChange={(e) => {
                setGeneratedJob({
                  ...generatedJob,
                  plugin_id: e.target.value,
                });
              }}
            />
          </Field>
          <Field
            className={styles.subtitleField}
            required
            label={"Plugin Version"}
          >
            <Input
              placeholder="Plugin Version"
              value={generatedJob.plugin_version ?? ""}
              onChange={(e) => {
                setGeneratedJob({
                  ...generatedJob,
                  plugin_version: e.target.value,
                });
              }}
            />
          </Field>
          <Field
            className={styles.subtitleField}
            required
            label={"Plugin Source"}
          >
            <Input
              placeholder="Plugin Source"
              value={generatedJob.plugin_source ?? ""}
              onChange={(e) => {
                setGeneratedJob({
                  ...generatedJob,
                  plugin_source: e.target.value,
                });
              }}
            />
          </Field>
        </div>
      );
    };

    const onUploadClick = (
      fileName: string,
      file: File,
      type: UploadFileType,
    ) => {
      toaster.onToastStart("Uploading " + fileName);
      uploadFile(
        fileName,
        file,
        "Bing",
        () => {
          switch (type) {
            case "Manifest":
              setManifestIsUploaded(true);
              break;
            case "API":
              setAPIIsUploaded(true);
              break;
          }
          toaster.onToastSuccess(fileName + " uploaded successfully!");
        },
        () => {
          toaster.onToastFailure(fileName + " uploaded successfully!");
        },
      );
    };

    const getFileName = (fileName: string) => {
      return `${folderPath_bingQueryGenerate}/${store.account?.localAccountId}/${taskId}_${genName}/${fileName}`;
    };

    const renderFileEntry = () => {
      if (isIDEntry) {
        return <></>;
      }
      return (
        <div>
          <Field
            className={styles.subtitleField}
            required
            label={"Plugin manifest path"}
          >
            <div>
              <input
                ref={manifestInputRef}
                type="file"
                accept="json"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const file = event.target?.files?.[0];
                  if (file) {
                    setManifestIsUploaded(false);
                    setSelectedManifestFile(file);
                    if (manifestInputRef && manifestInputRef.current) {
                      manifestInputRef.current.value = "";
                    }
                    setManifestPath(getFileName(file.name));
                  }
                }}
                style={{ display: "none" }}
              />
              <Button
                appearance="primary"
                onClick={() => {
                  manifestInputRef.current?.click();
                }}
              >
                Select File
              </Button>
            </div>
            <div>
              <Body1 className={styles.titleDescription}>
                {manifestPath ?? ""}
              </Body1>
              {manifestPath && selectedManifestFile && !manifestIsUploaded && (
                <Button
                  appearance="primary"
                  onClick={() => {
                    onUploadClick(
                      manifestPath,
                      selectedManifestFile,
                      "Manifest",
                    );
                  }}
                >
                  Upload
                </Button>
              )}
              {manifestIsUploaded && (
                <CloudCheckmark16Regular className={styles.uploadSuccessIcon} />
              )}
            </div>
          </Field>
          <Field className={styles.subtitleField} label={"Plugin API path"}>
            <div>
              <input
                ref={APIInputRef}
                type="file"
                accept="json"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const file = event.target?.files?.[0];
                  if (file) {
                    setAPIIsUploaded(false);
                    setSelectedAPIFile(file);
                    if (APIInputRef && APIInputRef.current) {
                      APIInputRef.current.value = "";
                    }
                    setAPIPath(getFileName(file.name));
                  }
                }}
                style={{ display: "none" }}
              />
              <Button
                appearance="primary"
                onClick={() => {
                  APIInputRef.current?.click();
                }}
              >
                Select File
              </Button>
            </div>
            <div>
              <Body1 className={styles.titleDescription}>{APIPath ?? ""}</Body1>
              {APIPath && selectedAPIFile && !APIIsUploaded && (
                <Button
                  appearance="primary"
                  onClick={() => {
                    onUploadClick(APIPath, selectedAPIFile, "API");
                  }}
                >
                  Upload
                </Button>
              )}
              {APIIsUploaded && (
                <CloudCheckmark16Regular className={styles.uploadSuccessIcon} />
              )}
            </div>
          </Field>
        </div>
      );
    };

    const renderRules = () => {
      if (isRAIOverride) {
        return (
          <Field className={styles.subtitleField} label={"Metric"} required>
            <Dropdown>
              {BingQuerySetGenerationRAIMetrics.map((option) => (
                <Option key={option} onClick={() => setRAIMetric(option)}>
                  {option}
                </Option>
              ))}
            </Dropdown>
          </Field>
        );
      }

      return (
        <div>
          <Body1 className={styles.titleDescription}>
            Choose type of dataset to generate
            <div
              className={mergeClasses(styles.rowWithSmallGap, styles.margin)}
            >
              <Button
                shape="circular"
                appearance={isSingleTurn ? "primary" : "secondary"}
                onClick={() => {
                  isSingleTurn ? undefined : setIsSingleTurn(true);
                }}
              >
                Single Turn
              </Button>
              <Button
                shape="circular"
                appearance={!isSingleTurn ? "primary" : "secondary"}
                onClick={() => {
                  isSingleTurn ? setIsSingleTurn(false) : undefined;
                }}
              >
                Multi Turn
              </Button>
            </div>
          </Body1>
          <Body1 className={styles.titleDescription}>
            Provide your rules for dataset generation.
          </Body1>
          {renderTurnRule(true)}
          {renderTurnRule(false)}
          {renderTargetExp()}
        </div>
      );
    };

    const renderOtherField = () => {
      return (
        <div className={styles.inputWidth}>
          <Field className={styles.subtitleField} label={"Query Count"}>
            <Input
              type="number"
              value={queryNum.toString()}
              placeholder="Query Count"
              onChange={(_, data) => {
                setQueryNum(Number(data.value));
              }}
            />
          </Field>
          {!isRAIOverride && (
            <Field
              className={styles.subtitleField}
              validationMessage={
                parseJsonStrToObject(metaJsonData) !== undefined
                  ? undefined
                  : "Invalid json"
              }
              validationState={
                parseJsonStrToObject(metaJsonData) !== undefined
                  ? "none"
                  : "error"
              }
              label="Metadata"
            >
              <Textarea
                className={styles.metadataTextArea}
                aria-label="The option information in json format."
                size="medium"
                value={metaJsonData}
                onChange={(_, data) => {
                  if (parseJsonStrToObject(data.value) !== undefined) {
                    setMetadata(JSON.parse(data.value));
                  }
                  setMetaJSONData(data.value);
                }}
              />
            </Field>
          )}
        </div>
      );
    };

    const IsValidJobName = () => {
      const regexStr = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
      return regexStr.test(genName);
    };

    const renderQuerySetUploadingDialogIfNeeded = () => {
      if (isSubmitted) {
        return <></>;
      }
      return (
        <>
          <DialogTitle>{`Query Set Generation`}</DialogTitle>
          <DialogContent>
            <Body1 className={styles.titleDescription}>
              Supports dataset generation for any task under any
              constrains/rules for both single turn & multi turn. You can upload
              json files explaining your scenario, be it open ai manifest/api,
              microsoft maniest or custom json file. Alternatively, you can also
              pass plugin details which can be used to fetch files from manifest
              store. These files will than be used with your rules to generate
              querysets for your scenario.
            </Body1>
            <Field
              className={styles.subtitleField}
              required
              label={"Name"}
              validationState={IsValidJobName() ? "none" : "error"}
              validationMessage={
                IsValidJobName()
                  ? ""
                  : "Data set name should start with a letter or an underscore and contain only letters, numbers, and underscores"
              }
            >
              <Input
                value={genName}
                placeholder="New data set name"
                onChange={(_, data) => {
                  setGenName(data.value);
                }}
              />
            </Field>
            <div className={styles.ruleTitleRow}>
              <h3 className={styles.headerTitle}>
                Task details
                <Tip
                  content="Details describing the target task. Can be Flux optionset file, openai manifest/api, any other valid json or Plugin details."
                  relationship="label"
                >
                  <Button
                    icon={<Info16Regular />}
                    appearance="transparent"
                    size="small"
                  />
                </Tip>
              </h3>
              <Tip
                content={"Enables RAI Query Set Generation"}
                relationship="label"
              >
                <Switch
                  checked={isRAIOverride}
                  onChange={(ev) => setIsRAIOverride(ev.currentTarget.checked)}
                  label={"RAI Override"}
                  labelPosition="before"
                ></Switch>
              </Tip>
            </div>
            <Body1 className={styles.titleDescription}>
              <strong>[At least 1 is required] </strong>Either pass ID or
              Files/File (Content must be valid json)
            </Body1>
            <div
              className={mergeClasses(styles.rowWithSmallGap, styles.margin)}
            >
              <Button
                shape="circular"
                appearance={!isIDEntry ? "primary" : "secondary"}
                onClick={() => {
                  isIDEntry ? setIsIdEntry(false) : undefined;
                }}
              >
                Files
              </Button>
              <Button
                shape="circular"
                appearance={isIDEntry ? "primary" : "secondary"}
                onClick={() => {
                  isIDEntry ? undefined : setIsIdEntry(true);
                }}
              >
                Plugin details
              </Button>
            </div>
            {renderIdEntry()}
            {renderFileEntry()}
            {renderOtherField()}
            <h3 className={styles.headerTitle}>
              Rules
              <Tip
                content="Optional rules determining what queries will look like. Can be used to model queries or seed conversations in based on scenario requirements."
                relationship="label"
              >
                <Button
                  icon={<Info16Regular />}
                  appearance="transparent"
                  size="small"
                />
              </Tip>
            </h3>

            {renderRules()}
          </DialogContent>
          <DialogActions>
            <Button appearance="primary" onClick={onSubmitClick}>
              Submit
            </Button>
            <Button onClick={handleDialogCancel} appearance="secondary">
              Close
            </Button>
          </DialogActions>
        </>
      );
    };

    const onOkButtonClick = () => {
      props.onSuccess();
    };

    const onSubmitClick = () => {
      toaster.onToastStart("Submitting...");
      setIsSubmitted(true);
      if (isRAIOverride) {
        setMetadata(undefined);
        setMetaJSONData("{}");
      }
      submitFinalJob();
    };

    const validateInput = () => {
      if (currentTemplate === undefined) {
        toaster.onToastFailure("Task template not found.");
        setIsSubmitted(false);
        return false;
      }

      if (parseJsonStrToObject(jsonData) === undefined) {
        toaster.onToastFailure(
          "Invalid json in target experiment options field.",
        );
        setIsSubmitted(false);
        return false;
      }

      if (genName.trim().length === 0) {
        toaster.onToastFailure("New data set name not provided!");
        setIsSubmitted(false);
        return false;
      }

      if (!IsValidJobName()) {
        toaster.onToastFailure(
          "New data set name should start with a letter or an underscore and contain only letters, numbers, and underscores",
        );
        setIsSubmitted(false);
        return false;
      }

      if (isIDEntry) {
        const allInputValid =
          generatedJob.plugin_id !== undefined &&
          generatedJob.plugin_id.trim().length != 0 &&
          generatedJob.plugin_source !== undefined &&
          generatedJob.plugin_source.trim().length != 0 &&
          generatedJob.plugin_version !== undefined &&
          generatedJob.plugin_version.trim().length != 0;
        if (!allInputValid) {
          toaster.onToastFailure("Plugin details not provided!");
          setIsSubmitted(false);
          return false;
        }
      }

      if (!isIDEntry) {
        if (!manifestIsUploaded || manifestPath === undefined) {
          toaster.onToastFailure("Manifest file not provided!");
          setIsSubmitted(false);
          return false;
        }
      }

      const isEmptyEngine = engine.url.trim() === "" && !isSingleTurn;

      if (isEmptyEngine && !isRAIOverride) {
        toaster.onToastFailure("Sydney Configuration not provided!");
        setIsSubmitted(false);
        return false;
      }

      if (isRAIOverride && RAIMetric === undefined) {
        toaster.onToastFailure("RAI metric not provided!");
        setIsSubmitted(false);
        return false;
      }

      return true;
    };

    const submitFinalJob = () => {
      if (!validateInput()) {
        return;
      }

      const currentSingleTurn: { [k: string]: any } = {};
      singleRule.forEach((item) => {
        if (item.segmentName === undefined) {
          return;
        }
        currentSingleTurn[item.segmentName] = item.rule;
      });
      const currentMultiTurn: { [k: string]: any } = {};
      multiRule.forEach((item) => {
        if (item.segmentName === undefined) {
          return;
        }
        currentMultiTurn[item.segmentName] = item.rule;
      });

      const generateJobObj = () => {
        if (!isRAIOverride) {
          if (isSingleTurn) {
            return {
              ...generatedJob,
              singleturn: {
                querygen_rules: currentSingleTurn,
              },
              num_queries: queryNum,
              query_metadata: metadata,
            };
          }
          return {
            ...generatedJob,
            singleturn: {
              querygen_rules: currentSingleTurn,
            },
            multiturn: {
              querygen_rules: currentMultiTurn,
              sydney: engine,
            },
            num_queries: queryNum,
            query_metadata: metadata,
          };
        }
        return {
          ...generatedJob,
          rai_metric_override: RAIMetric,
          num_queries: queryNum,
        };
      };

      const blob = new Blob([JSON.stringify(generateJobObj(), null, 2)], {
        type: "text/plain",
      });
      const configFile = new File([blob], genName + ".json", {
        type: "text/plain",
      });

      const uploadConfigPath = getFileName(genName + ".json");

      onUploadClick(uploadConfigPath, configFile, "Config");

      const outputPath = `${bingAzureStorageUrlRoot}${folderPath_bingQuerySet}/${store.account?.localAccountId}/${genName}_${taskId}.tsv`;

      const calcJobConfig = () => {
        if (isIDEntry) {
          return {
            config_file: {
              type: "uri_file",
              path: bingAzureStorageUrlRoot + uploadConfigPath,
            },
            output_path: {
              type: "string",
              default: outputPath,
            },
          };
        }

        const requiredConfig = {
          config_file: {
            type: "uri_file",
            path: bingAzureStorageUrlRoot + uploadConfigPath,
          },
          manifest_file: {
            type: "uri_file",
            path: bingAzureStorageUrlRoot + manifestPath,
          },
          output_path: {
            type: "string",
            default: outputPath,
          },
        };

        if (APIPath !== undefined) {
          return {
            ...requiredConfig,
            api_file: {
              type: "uri_file",
              path: bingAzureStorageUrlRoot + APIPath,
            },
          };
        }

        return requiredConfig;
      };

      const jobConfig = calcJobConfig();

      const jobParameters = {
        JobName: genName + "-" + taskId,
        CreatedBy: store.account?.name ?? "Unknown",
        ExperimentName: currentTemplate?.ExperimentName ?? "",
        JobTemplateId: currentTemplate?.Id ?? 0,
        Settings: JSON.stringify(jobConfig),
      };

      telemetryHelper.logUserActionEvent("SubmitBingQuerySetGenerationJob");

      createJob(jobParameters)
        .then(() => {
          toaster.onToastSuccess(
            "Successfully submitted Test Set Generation Task!",
          );
          telemetryHelper.logUserActionEvent(
            "SubmitBingQuerySetGenerationJobSuccess",
          );
        })
        .catch(() => {
          toaster.onToastFailure("Failed to submit Test Set Generation Task!");
          telemetryHelper.logUserActionEvent(
            "SubmitBingQuerySetGenerationJobFailure",
          );
        });
      return jobConfig;
    };

    const renderQuerySetSubmittedDialogIfNeeded = () => {
      if (!isSubmitted) {
        return <></>;
      }

      return (
        <>
          <DialogTitle>
            <Checkmark24Regular className={styles.checkMarkIcon} />
            {`Query set generation submitted`}
          </DialogTitle>
          <DialogContent className={styles.submittedDescription}>
            <Body1>
              Query Set generation process has been submitted. Please be patient
              and give it a little time to work it. You can keep an eye on the
              progress by checking the Query Set list.
            </Body1>
            <br />
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={onOkButtonClick} appearance="primary">
              OK
            </Button>
          </DialogActions>
        </>
      );
    };

    return (
      <>
        <Dialog
          open={isOpen}
          onOpenChange={(_, data) => {
            if (data.open === false) {
              props.onCancel();
            }
          }}
        >
          <DialogSurface>
            <DialogBody>
              {renderQuerySetUploadingDialogIfNeeded()}
              {renderQuerySetSubmittedDialogIfNeeded()}
            </DialogBody>
          </DialogSurface>
        </Dialog>
      </>
    );
  },
);
