import type {
  OptionOnSelectData,
  SelectTabData,
  SelectTabEvent,
  SelectionEvents,
  TabValue,
} from "@fluentui/react-components";
import {
  Button,
  Caption1,
  Checkbox,
  Dropdown,
  Input,
  Option,
  OptionGroup,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Tab,
  TabList,
  Text,
} from "@fluentui/react-components";
import {
  ArrowExportRegular,
  ArrowImportRegular,
  ArrowSwap24Regular,
  QuestionCircle20Regular,
} from "@fluentui/react-icons";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Tip } from "../../../../../components/Shared/Tip";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { telemetryHelper } from "../../../../../helpers/telemetryHelper";
import {
  exportQueriesToTSV,
  exportSnapshot,
  exportTaggedSet,
  getEditedSets,
  importSnapshot,
  validateImportFileSchema,
} from "../../../helpers/queryManagementHelper";
import { useQueryManagementStyles } from "../styles/QueryManagementStyles";
import type { SelectOptions } from "../types/Display";
import type { Query, TaggedAssertions } from "../types/Query";

type QueryManagementImportExportPopoverProps = {
  fullFileInputRef: React.RefObject<HTMLInputElement>;
  importErrorMessage: string;
  queries: Query[];
  taggedAssertions: TaggedAssertions[];
  checkedQueries: Set<Query>;
  selectOptions: SelectOptions;
  setInitialQueries: React.Dispatch<React.SetStateAction<Query[]>>;
  setImportErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  renderFullErrorDialog: () => JSX.Element;
  setQueries: React.Dispatch<React.SetStateAction<Query[]>>;
  setTaggedAssertions: React.Dispatch<React.SetStateAction<TaggedAssertions[]>>;
  setExportComplete: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const QueryManagementImportExportPopover = observer(
  (props: QueryManagementImportExportPopoverProps) => {
    const {
      fullFileInputRef,
      importErrorMessage,
      queries,
      taggedAssertions,
      checkedQueries,
      selectOptions,
      setInitialQueries,
      setImportErrorMessage,
      renderFullErrorDialog,
      setQueries,
      setTaggedAssertions,
      setExportComplete,
      setLoading,
    } = props;
    const styles = useQueryManagementStyles();
    const toast = useToast();

    const [selectedTab, setSelectedTab] = useState<TabValue>("import");

    const [tsvName, setTsvName] = useState("");
    const [snapshotName, setSnapshotName] = useState("");

    const [includeDrafts, setIncludeDrafts] = useState({
      queries: false,
      assertions: false,
    });

    const [taggedSetsToExport, setTaggedSetsToExport] = useState<string[]>([]);
    const [taggedSetsToExportString, setTaggedSetsToExportString] =
      useState("");

    useEffect(() => {
      if (taggedSetsToExport.includes("All")) {
        setTaggedSetsToExportString("All");
      } else if (taggedSetsToExport.includes("Edited only")) {
        setTaggedSetsToExportString("Edited only");
      } else {
        setTaggedSetsToExportString(taggedSetsToExport.join(", "));
      }
    }, [taggedSetsToExport]);

    const [onlyDraftsChecked, setOnlyDraftsChecked] = useState(false);

    useEffect(() => {
      const isOnlyDrafts =
        checkedQueries.size > 0 &&
        Array.from(checkedQueries).every((query) => query.draft);

      setOnlyDraftsChecked(isOnlyDrafts);
    }, [checkedQueries]);

    const onTabSelect = (_: SelectTabEvent, data: SelectTabData) => {
      setSelectedTab(data.value);
    };

    const resetExportFields = () => {
      setTsvName("");
      setSnapshotName("");
      setIncludeDrafts({
        queries: false,
        assertions: false,
      });
    };

    const renderErrorMessage = () => {
      if (importErrorMessage) {
        return (
          <div
            data-testid="importErrorMessage"
            className={styles.errorMessageContainer}
          >
            <Caption1 className={styles.importErrorMessage}>
              {importErrorMessage}
            </Caption1>
            {renderFullErrorDialog()}
          </div>
        );
      }
    };

    const handleTaggedSetsToExportOptionSelect = (
      _: SelectionEvents,
      option: OptionOnSelectData,
    ) => {
      const value = option.optionValue;
      if (value) {
        if (value === "All") {
          if (taggedSetsToExportString === "All") {
            setTaggedSetsToExport([]);
          } else {
            setTaggedSetsToExport(["All", ...selectOptions.sets]);
          }
        } else if (value === "Edited only") {
          const editedSets = getEditedSets(queries);
          if (taggedSetsToExportString === "Edited only") {
            setTaggedSetsToExport([]);
          } else {
            setTaggedSetsToExport(["Edited only", ...editedSets]);
          }
        } else {
          if (taggedSetsToExport.includes(value)) {
            setTaggedSetsToExport(
              taggedSetsToExport.filter(
                (set) =>
                  set !== value && set !== "All" && set !== "Edited only",
              ),
            );
          } else {
            setTaggedSetsToExport(
              [...taggedSetsToExport, value].filter(
                (set) => set !== "All" && set !== "Edited only",
              ),
            );
          }
        }
      }
    };

    const renderExportPanel = () => (
      <>
        <div className={styles.exportTypes}>
          <Input
            className={styles.exportInput}
            placeholder="Snapshot (always exported)"
            value={snapshotName}
            onChange={(event) =>
              setSnapshotName(event.target.value.replace(/[^a-zA-Z0-9]/g, "_"))
            }
            contentAfter={<Text>.yaml</Text>}
            data-testid="snapshot-input"
          />
          <Tip
            withArrow
            relationship="label"
            content="File name for snapshot YAML. If empty, default to Snapshot.yaml."
          >
            <QuestionCircle20Regular />
          </Tip>
        </div>
        <div className={styles.exportTypes}>
          <Input
            className={styles.exportInput}
            placeholder={`Query set (${checkedQueries.size} queries selected)`}
            value={tsvName}
            onChange={(event) =>
              setTsvName(event.target.value.replace(/[^a-zA-Z0-9]/g, "_"))
            }
            contentAfter={<Text>.tsv</Text>}
            data-testid="queryset-input"
          />
          <Tip
            withArrow
            relationship="label"
            content="File name for query set TSV. If empty, default to QuerySet.tsv."
          >
            <QuestionCircle20Regular />
          </Tip>
        </div>
        <div className={styles.exportTypes}>
          <Dropdown
            className={styles.exportInput}
            id="tagged-sets"
            placeholder="Tagged query sets"
            aria-label="Select predfined query sets to export"
            multiselect
            value={taggedSetsToExportString}
            selectedOptions={taggedSetsToExport}
            onOptionSelect={handleTaggedSetsToExportOptionSelect}
            data-testid="tagged-sets-dropdown"
          >
            <OptionGroup>
              <Option value="All" data-testid="all-tagged-sets">
                All
              </Option>
              <Option value="Edited only" data-testid="edited-only-tagged-sets">
                Edited only
              </Option>
            </OptionGroup>
            <OptionGroup>
              {selectOptions.sets.map((set, index) => (
                <Option key={index} value={set} data-testid={set}>
                  {set}
                </Option>
              ))}
            </OptionGroup>
          </Dropdown>
          <Tip
            withArrow
            relationship="label"
            content="Export tagged query sets, e.g. CIQ, 1k GoldenSet, and Canary."
          >
            <QuestionCircle20Regular />
          </Tip>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Checkbox
            checked={includeDrafts.queries}
            label="Include query drafts"
            onChange={() =>
              setIncludeDrafts({
                ...includeDrafts,
                queries: !includeDrafts.queries,
              })
            }
          />
          <Checkbox
            checked={includeDrafts.assertions}
            label="Include assertion drafts"
            onChange={() =>
              setIncludeDrafts({
                ...includeDrafts,
                assertions: !includeDrafts.assertions,
              })
            }
          />
        </div>
        <Button
          appearance="primary"
          disabled={onlyDraftsChecked && !includeDrafts.queries}
          onClick={() => {
            const queriesList = Array.from(checkedQueries, (query) =>
              cloneDeep(query),
            );

            if (queriesList.length > 0) {
              exportQueriesToTSV(queriesList, tsvName, includeDrafts.queries);
              telemetryHelper.logUserActionEvent(
                "QueryManagementToolExportTSV",
                {
                  queryCount: queriesList.length,
                  includeQueryDrafts: includeDrafts.queries,
                },
              );
            }

            if (taggedSetsToExport.length > 0) {
              taggedSetsToExport.forEach((set) => {
                if (set !== "All" && set !== "Edited only") {
                  exportTaggedSet(
                    queries,
                    taggedAssertions,
                    set,
                    includeDrafts.queries,
                    includeDrafts.assertions,
                  );
                  telemetryHelper.logUserActionEvent(
                    "QueryManagementToolExportTaggedSet",
                    {
                      taggedSet: set,
                      includeQueryDrafts: includeDrafts.queries,
                      includeAssertionDrafts: includeDrafts.assertions,
                    },
                  );
                }
              });
            }

            exportSnapshot(
              "",
              queries,
              taggedAssertions,
              includeDrafts.queries,
              includeDrafts.assertions,
            );
            telemetryHelper.logUserActionEvent(
              "QueryManagementToolExportSnapshot",
            );

            setExportComplete(true);
            resetExportFields();
          }}
          data-testid="export-button"
        >
          Export
        </Button>
      </>
    );

    return (
      <Popover closeOnScroll withArrow positioning="below">
        <PopoverTrigger>
          <Button
            aria-label="Import/Export Queries"
            className={styles.queryButtons}
            appearance="secondary"
            icon={<ArrowSwap24Regular />}
          >
            Import/Export
          </Button>
        </PopoverTrigger>
        <PopoverSurface className={styles.importExportPopover}>
          <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
            <Tab
              id="Import"
              icon={<ArrowImportRegular />}
              value="import"
              className={styles.tabs}
              data-testid="import-tab"
            >
              Import
            </Tab>
            <Tab
              id="Export"
              icon={<ArrowExportRegular />}
              value="export"
              className={styles.tabs}
              data-testid="export-tab"
            >
              Export
            </Tab>
          </TabList>
          <div style={{ minWidth: "100%" }}>
            {selectedTab === "import" && (
              <div className={styles.panel}>
                <Button
                  appearance="primary"
                  onClick={() => fullFileInputRef.current?.click()}
                >
                  Browse
                </Button>
                {renderErrorMessage()}
                <input
                  data-testid="fileInput"
                  ref={fullFileInputRef}
                  type="file"
                  accept=".yaml,.yml"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const file = event.target?.files?.[0];
                    if (file) {
                      setLoading(true);
                      toast.onToastStart("Importing file...");
                      validateImportFileSchema(file)
                        .then((selectedFile) => importSnapshot(selectedFile))
                        .then((data) => {
                          setInitialQueries(data.queries);
                          setQueries(data.queries);
                          setTaggedAssertions(data.taggedAssertions);
                          setImportErrorMessage("");
                          toast.onToastSuccess("File imported successfully.");
                          telemetryHelper.logUserActionEvent(
                            "QueryManagementToolImportQuerySet",
                          );
                        })
                        .catch((error) => {
                          setImportErrorMessage(error.message);
                          toast.onToastFailure(
                            "An error occurred during import.",
                          );
                          telemetryHelper.logUserActionEvent(
                            "QueryManagementToolImportError",
                            {
                              error: error.message,
                            },
                          );
                        })
                        .finally(() => {
                          if (fullFileInputRef.current) {
                            fullFileInputRef.current.value = "";
                          }
                          setLoading(false);
                        });
                    }
                  }}
                  style={{ display: "none" }}
                />
              </div>
            )}
            {selectedTab === "export" && (
              <div className={styles.panel}>{renderExportPanel()}</div>
            )}
          </div>
        </PopoverSurface>
      </Popover>
    );
  },
);
