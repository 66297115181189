import type { SEVALPerfMetrics } from "../models/KustoPerfResult";
import type { MetricsData } from "./metricsHelper";

/**
 * Convert primary table to metric data
 * "Flight","P50","P75","P90","P95","Volume","Ratio","pValue"
  "Treatment","22249","29462","29462","29462","2","50.0%",""
  "Control","18433","22407","22407","22407","2","50.0%",""
  "Delta","3816","7055","7055","7055","","",""
  "Delta%","20.7","31.49","31.49","31.49","","",""
  "pValue","","","","","","","1"

  MetricsData {
  category: string;
  categoryIndex: number;
  group: MetricsGroup;
  metric: string;
  segment: string;
  control: string;
  treatment: string;
  delta: string;
  n: string;
  droppedNarrows: string;
  pValue: string;
}
 */

const getMetricProp = (column: string) => {
  switch (column) {
    case "Control":
      return "control";
    case "Treatment":
      return "treatment";
    case "Delta":
      return "delta";
    case "pValue":
      return "pValue";
    default:
      return undefined;
  }
};

interface RecordResult {
  metric: string;
  control?: string | undefined;
  treatment?: string | undefined;
  delta?: string | undefined;
  pValue?: string | undefined;
}

export const converPerfMetricsData = (
  currentMetric: string,
  data: SEVALPerfMetrics[],
): MetricsData[] => {
  if (data.length === 0) {
    return [];
  }

  const newTable = new Map<string, RecordResult>();
  data.forEach((recordset) => {
    const property = getMetricProp(recordset.Flight);
    if (property) {
      Object.entries(recordset).forEach((item) => {
        if (item[0] === "Flight" || item[0] === "pValue") {
          return;
        }
        if (item[1] !== undefined) {
          const metric_key = `${currentMetric}_${item[0]}`;
          if (newTable.has(metric_key)) {
            const current = newTable.get(metric_key);
            const newRecord: RecordResult = {
              ...current,
              metric: metric_key,
              [`${property}`]: item[1],
            };
            newTable.set(metric_key, newRecord);
          } else {
            const newRecord: RecordResult = {
              metric: metric_key,
              [`${property}`]: item[1],
            };
            newTable.set(metric_key, newRecord);
          }
          return;
        }
      });
    }
  });

  const allData = Array.from(newTable.values());
  return allData.map((recordset: RecordResult) => {
    const { metric, control, treatment, delta, pValue } = recordset;
    return {
      category: currentMetric,
      categoryIndex: 0,
      group: "Debug Metric",
      metric,
      segment: "",
      control: control ?? "",
      treatment: treatment ?? "",
      delta: delta ?? "",
      n: "",
      droppedNarrows: "",
      pValue: pValue ?? "",
    };
  });
};
