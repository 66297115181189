import { bool, num, object, optional, str } from "sydneyeval-shared";

// https://msasg.visualstudio.com/WIT%20Engineering/_git/SydneyEvaluation?path=/Enterprise/src/CompliantSydneyEval/dbo/Tables/JobTemplates.sql
export const JobTemplate = object({
  Id: num,
  Name: str,
  Settings: str,
  Type: str,
  PipelineId: str,
  PipelineEndpoint: str,
  IsActive: bool,
  CreatedAt: str,
  ExperimentName: str,
  EnvironmentId: num,
  IsProductionTemplate: optional(bool),
  Datasets: optional(str),
  SettingsSchema: optional(str),
  BatchEndpointName: optional(str),
  BuildVersion: optional(str),
  ProductId: optional(str),
  ProductPipelineId: optional(str),
});

export type JobTemplate = ReturnType<typeof JobTemplate>;
