import { Input } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import {
  convertLocalDatetimeToUTC,
  datetimeLocalFormat,
} from "../../../helpers/formatHelper";

interface IDateTimeInputProps {
  isDisabled?: boolean;
  isoDateTime: string;
  onChange: (dateTime: string) => void;
}

export const DateTimeInput = observer((props: IDateTimeInputProps) => {
  return (
    <Input
      disabled={props.isDisabled}
      size="medium"
      type="datetime-local"
      value={datetimeLocalFormat(props.isoDateTime)}
      onChange={(_, data) => {
        if (data?.value === "") {
          return;
        }
        props.onChange(convertLocalDatetimeToUTC(data?.value));
      }}
    />
  );
});
