import { makeStyles, mergeClasses } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import type { MetricsData } from "../../../helpers/metricsHelper";
import { checkMetricIsPositiveIndicator } from "../../../helpers/metricsMapping";
import { useSharedTableStyles } from "./shardTableStyles";

const useStyles = makeStyles({
  categoryCell: {
    backgroundColor: "white",
  },
  lightGreen: {
    backgroundColor: "#CAEAD8",
  },
  darkGreen: {
    backgroundColor: "#55B17E",
  },
  lightRed: {
    backgroundColor: "#F9D9D9",
  },
  darkRed: {
    backgroundColor: "#E87979",
  },
});

const useResponsiveStyles = makeResponsiveStyles(
  {
    categoryColumn: {
      width: "100px",
    },
    metricColumn: {
      width: "250px",
      minWidth: "230px",
    },
    controlColumn: {
      width: "80px",
    },
    treatmentColumn: {
      width: "80px",
    },
  },
  {
    xs: {
      categoryColumn: { maxWidth: "10%" },
      metricColumn: { maxWidth: "30%" },
      controlColumn: { maxWidth: "10%" },
      treatmentColumn: { maxWidth: "10%" },
    },
    lg: {
      categoryColumn: { maxWidth: "15%" },
      metricColumn: { maxWidth: "30%" },
      controlColumn: { maxWidth: "15%" },
      treatmentColumn: { maxWidth: "15%" },
    },
  },
);

type ScorecardTableProps = {
  metricsData: MetricsData[];
};

export const ScorecardTable = observer((props: ScorecardTableProps) => {
  const sharedStyles = useSharedTableStyles();
  const styles = useStyles();
  const responsiveStyles = useResponsiveStyles();
  const { metricsData } = props;

  const getPValueCell = (metrics: string, delta: string, val: string) => {
    if (val.length === 0) {
      return sharedStyles.cell;
    }
    const checkMetric = checkMetricIsPositiveIndicator(metrics);
    const deltaVal =
      Number(delta) === 0 ? undefined : Number(delta) > 0 ? 1 : -1;
    const pVal = Math.abs(Number(val));

    if (deltaVal === undefined) {
      return sharedStyles.cell;
    }

    const isPositive = deltaVal * checkMetric > 0;
    if (!isPositive) {
      if (pVal <= 0.001) {
        return mergeClasses(sharedStyles.cell, styles.darkRed);
      }
      if (pVal <= 0.05) {
        return mergeClasses(sharedStyles.cell, styles.lightRed);
      }
    }
    if (isPositive) {
      if (pVal <= 0.001) {
        return mergeClasses(sharedStyles.cell, styles.darkGreen);
      }
      if (pVal <= 0.05) {
        return mergeClasses(sharedStyles.cell, styles.lightGreen);
      }
    }
    return sharedStyles.cell;
  };

  return (
    <table className={sharedStyles.table}>
      <thead className={sharedStyles.thead}>
        <tr className={sharedStyles.rowStyle}>
          <th className={responsiveStyles.categoryColumn}>Category</th>
          <th className={responsiveStyles.metricColumn}>Metric</th>
          <th className={responsiveStyles.controlColumn}>Control</th>
          <th className={responsiveStyles.treatmentColumn}>Treatment</th>
          <th className={sharedStyles.cell}>Delta</th>
          <th className={sharedStyles.cell}>Paired N</th>
          <th className={sharedStyles.cell}>P value</th>
        </tr>
      </thead>
      <tbody className={sharedStyles.tbody}>
        {metricsData.map((row, index) => (
          <tr key={index} className={sharedStyles.rowStyle}>
            {index === 0 || metricsData[index - 1].category !== row.category ? (
              <td
                className={mergeClasses(
                  sharedStyles.cell,
                  styles.categoryCell,
                  responsiveStyles.categoryColumn,
                )}
                rowSpan={
                  metricsData.filter((d) => d.category === row.category).length
                }
              >
                {row.category}
              </td>
            ) : null}
            <td
              className={mergeClasses(
                sharedStyles.cell,
                styles.categoryCell,
                responsiveStyles.metricColumn,
              )}
            >
              {row.metric}
            </td>
            <td
              className={mergeClasses(
                sharedStyles.cell,
                responsiveStyles.controlColumn,
              )}
            >
              {row.control}
            </td>
            <td
              className={mergeClasses(
                sharedStyles.cell,
                responsiveStyles.treatmentColumn,
              )}
            >
              {row.treatment}
            </td>
            <td className={sharedStyles.cell}>{row.delta}</td>
            <td className={sharedStyles.cell}>
              {Number(row.n) - Number(row.droppedNarrows)}
            </td>
            <td className={getPValueCell(row.metric, row.delta, row.pValue)}>
              {row.pValue}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
});
