import { Body1, makeStyles } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles({
  jsonView: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
  },
});
type JsonViewProps = {
  content: string;
};

export const JsonView = observer((props: JsonViewProps) => {
  const styles = useStyles();
  const { content } = props;

  const formatJson = (jsonStr: string) => {
    try {
      return JSON.stringify(JSON.parse(jsonStr), null, 2);
    } catch (e) {
      return jsonStr;
    }
  };

  const renderJson = () => {
    return formatJson(content)
      .split("\n")
      .map((line, index) => {
        return (
          <Body1 key={index} tabIndex={0}>
            {`${line.replaceAll(" ", "\u00a0")}`}
            <br />
          </Body1>
        );
      });
  };

  return <div className={styles.jsonView}>{renderJson()}</div>;
});
