import { createStore } from "satcheljs";
import type { UserProfileResult } from "sydneyeval-shared";
import type { SubstrateSingleUserInfor } from "../../../models/SearchUserByIdResponse";

type JobShareStore = {
  userProfile: UserProfileResult | undefined;
  isLoading: boolean;
  selectUser: SubstrateSingleUserInfor[];
  isDialogOpen: boolean;
  isCopyLink: boolean;
  permissionList: SubstrateSingleUserInfor[];
  sevalUserList: SubstrateSingleUserInfor[];
  invalidErrorMessage: string | undefined;
};

export const initJobShareStore: JobShareStore = {
  userProfile: undefined,
  isLoading: true,
  selectUser: [],
  isDialogOpen: false,
  isCopyLink: false,
  permissionList: [],
  sevalUserList: [],
  invalidErrorMessage: undefined,
};

export const jobShareStore = createStore<JobShareStore>(
  "jobShareStore",
  Object.assign({}, initJobShareStore),
)();
