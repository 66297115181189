import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  makeStyles,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";

type SettingsDialogProps = {
  isOpen: boolean;
  onComplete: () => void;
  renderMenu: () => JSX.Element;
  renderContent: () => JSX.Element;
  renderButtons: () => JSX.Element;
};

const useStyle = makeStyles({
  dialog: {
    width: "1400px",
    height: "800px",
    maxWidth: "100%",
  },
  body: {
    height: "100%",
  },
  horizontalWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    height: "100%",
  },
  leftWrapper: {
    paddingTop: "60px",
    flexBasis: "200px",
    flexGrow: 0,
    flexShrink: 0,
  },
  rightWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    height: "100%",
  },
});

export const SettingsDialog = observer((props: SettingsDialogProps) => {
  const styles = useStyle();

  return (
    <Dialog
      open={props.isOpen}
      onOpenChange={() => {
        props.onComplete();
      }}
    >
      <DialogSurface className={styles.dialog}>
        <DialogBody className={styles.body}>
          <DialogContent>
            <div className={styles.horizontalWrapper}>
              <div className={styles.leftWrapper}>{props.renderMenu()}</div>
              <div className={styles.rightWrapper}>{props.renderContent()}</div>
            </div>
          </DialogContent>
          <DialogActions>{props.renderButtons()}</DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
});
