// transfrom seconds to mm:ss. Usually be used in countdown timer
export function formatTime(seconds: number) {
  const date = new Date(seconds * 1000);
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
  const isoString = date.toISOString();
  if (isoString.length === 24) {
    // YYYY-MM-DDTHH:mm:ss.sssZ
    return isoString.substring(14, 19);
  } else {
    // ±YYYYYY-MM-DDTHH:mm:ss.sssZ
    return isoString.substring(17, 22);
  }
}

export function FormatDateToString(date: Date, formatStr: string) {
  const o: any = {
    "M+": date.getMonth() + 1, //Month
    "d+": date.getDate(), //Day
    "h+": date.getHours(), //Hour
    "m+": date.getMinutes(), //Minute
    "s+": date.getSeconds(), //Second
    "q+": Math.floor((date.getMonth() + 3) / 3), //Quarter
    S: date.getMilliseconds(), //Millisecond
  };
  if (/(y+)/.test(formatStr))
    formatStr = formatStr.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length),
    );
  for (const k in o)
    if (new RegExp("(" + k + ")").test(formatStr))
      formatStr = formatStr.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length),
      );
  return formatStr;
}
