import { Button } from "@fluentui/react-components";
import type { FunctionComponent } from "react";

export const Pagination: FunctionComponent<{
  totalPages: number;
  currentPage: number;
  onChange: (page: number) => void;
}> = ({ totalPages, currentPage, onChange }) => {
  return (
    <div style={{ display: "flex", gap: "1.5em" }}>
      <Button onClick={() => onChange(1)}>First Page</Button>
      <Button
        disabled={currentPage === 1}
        onClick={() => onChange(currentPage - 1)}
      >
        Previous
      </Button>

      <Button
        disabled={currentPage === totalPages}
        onClick={() => onChange(currentPage + 1)}
      >
        Next
      </Button>
      <Button onClick={() => onChange(totalPages)}>Last Page</Button>
    </div>
  );
};
