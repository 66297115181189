import { Button } from "@fluentui/react-button";
import { Field, Label, Radio, RadioGroup } from "@fluentui/react-components";
import { Eraser24Regular } from "@fluentui/react-icons";
import type { FunctionComponent } from "react";
import { useStyles } from "../styles";

export const ClearableChoiceGroup: FunctionComponent<{
  label: string;
  choiceGroupLabel?: string;
  options: string[]; // option key stored in feedback
  optionDisplayValues?: string[]; // option displayed in UI. Index is matched with options
  selectedKey: string | undefined;
  onChoiceChange: (option?: string) => void;
  disabled?: boolean;
}> = ({
  label,
  choiceGroupLabel,
  options,
  optionDisplayValues,
  selectedKey,
  onChoiceChange,
  disabled,
}) => {
  // Default value of selectedKey is null. Do not use undefined because it will cause the component to be uncontrolled.
  // https://github.com/microsoft/fluentui/issues/23466

  const styles = useStyles();

  return (
    <div>
      <Label className={styles.label}>{label}</Label>
      <Field label={choiceGroupLabel}>
        <RadioGroup
          disabled={disabled}
          value={selectedKey?.toString() ?? ""}
          onChange={(_ev, option) => {
            onChoiceChange(option.value);
          }}
        >
          {options.map((option, index) => (
            <Radio
              key={option}
              value={optionDisplayValues?.[index] ?? option}
              label={option}
            />
          ))}
        </RadioGroup>
      </Field>
      <Button
        disabled={disabled}
        icon={<Eraser24Regular />}
        appearance="transparent"
        onClick={() => {
          onChoiceChange(undefined);
        }}
      ></Button>
      Clear selection
    </div>
  );
};
