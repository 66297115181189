import { Body1, makeStyles, shorthands } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { Tip } from "../../../../../components/Shared/Tip";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("8px"),
  },
  body: {
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
  },
});

type TextCellProps = {
  text: string;
};

export const TextCell = observer((props: TextCellProps) => {
  const styles = useStyles();
  const { text } = props;
  return (
    <div className={styles.root}>
      <Tip withArrow content={text} relationship="label">
        <Body1 className={styles.body}>{text}</Body1>
      </Tip>
    </div>
  );
});
