export const checkBizChatFileName = (
  value: string | undefined,
  path: string,
) => {
  if (value === undefined) {
    return { path, message: "Input file name should not be undefined!" };
  }

  if (value === "") {
    return { path, message: "Input file name should not be empty!" };
  }

  return undefined;
};
