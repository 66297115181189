import {
  FluentProvider,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import "./App.css";
import { AppBar } from "./components/AppBar/AppBar";
import { AppSubTab } from "./components/AppBar/AppSubTab";
import { OngoingIssueBanner } from "./components/Banner/OngoingIssueBanner";
import { MainContentView } from "./components/MainContentView";
import { M365LightTheme } from "./components/Theme/M365Theme";
import { AccountProvider } from "./components/Wrappers/AccountProvider";
import { AuthProvider } from "./components/Wrappers/AuthProvider";
import { ContributionProvider } from "./components/Wrappers/ContributionProvider";
import { ErrorBoundaryProvider } from "./components/Wrappers/ErrorBoundaryProvider";
import { ResponsiveProvider } from "./components/Wrappers/ResponsiveProvider";
import { ScrollContainer } from "./components/Wrappers/ScrollerContainer";
import { SidePaneProvider } from "./components/Wrappers/SidePaneProvider";
import { ToasterProvider } from "./components/Wrappers/ToasterProvider";

// Orchestrators
require("./orchestrators/index");

// Feature Contributions
require("./partners/index");

const useStyles = makeStyles({
  mainViewContainer: {
    position: "relative",
    flexGrow: 1,
    boxSizing: "border-box",
    width: "100%",
    ...shorthands.overflow("hidden"),
    backgroundImage: `radial-gradient(33.33% 33.33% at 18.46% 79.87%, rgba(211, 220, 252, 0.30) 0%, rgba(211, 225, 252, 0.00) 100%), radial-gradient(22.46% 39.44% at 77.83% 17.91%, rgba(212, 218, 252, 0.30) 0%, rgba(211, 225, 252, 0.00) 92.19%), linear-gradient(140deg, rgba(238, 219, 246, 0.34) 0%, rgba(209, 239, 252, 0.39) 100%)`,
    display: "flex",
    flexDirection: "column",
  },
});

const App = observer(() => {
  const styles = useStyles();

  return (
    <ErrorBoundaryProvider>
      <AuthProvider>
        <FluentProvider theme={M365LightTheme}>
          <ResponsiveProvider>
            <AccountProvider>
              <ToasterProvider>
                <ContributionProvider>
                  <OngoingIssueBanner />
                  <AppBar />
                  <div className={styles.mainViewContainer}>
                    <SidePaneProvider>
                      <AppSubTab />
                      <ScrollContainer>
                        <MainContentView />
                      </ScrollContainer>
                    </SidePaneProvider>
                  </div>
                </ContributionProvider>
              </ToasterProvider>
            </AccountProvider>
          </ResponsiveProvider>
        </FluentProvider>
      </AuthProvider>
    </ErrorBoundaryProvider>
  );
});

export default App;
