import {
  Field,
  Label,
  Radio,
  RadioGroup,
  Textarea,
  mergeClasses,
} from "@fluentui/react-components";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import type { MetricDefinition } from "../MetricDefinition";
import type { StewieLeoRow } from "../bizChatEvalDataProvider";
import {
  ControllableNav,
  renderRawData,
} from "../groundleo/GroundLeoDefinition";
import { CollapsedResource } from "../sharedComponents/CollapsedResource";
import { useStyles } from "../styles";
import { uuidv4 } from "../utils/Guid";
import { getUserAlias } from "../utils/utilities";

const RenderHumanLabel: FunctionComponent<{
  row: StewieLeoRow;
  triggerNavRerender: () => void;
  saveFeedbackTrigger: number;
  disabled?: boolean;
}> = ({ row, triggerNavRerender, saveFeedbackTrigger, disabled }) => {
  const [comment, setComment] = useState<string>(""); // <Textarea> doesn't work when the initial value is undefined. So we use empty string instead.
  const [relevanceLabel, setRelevanceLabel] = useState<string | undefined>();
  const [engagementLabel, setEngagementLabel] = useState<string | undefined>();
  const [detailLabel, setDetailLabel] = useState<string | undefined>();
  const [clarityLabel, setClarityLabel] = useState<string | undefined>();
  const [stewieleoLabel, setStewieleoLabel] = useState<string | undefined>();

  const metricMap = {
    relevance: relevanceLabel,
    engagement: engagementLabel,
    detail: detailLabel,
    clarity: clarityLabel,
    stewieleo: stewieleoLabel,
  };

  const setMetricMap = {
    relevance: setRelevanceLabel,
    engagement: setEngagementLabel,
    detail: setDetailLabel,
    clarity: setClarityLabel,
    stewieleo: setStewieleoLabel,
  };

  type metricNameType = keyof typeof metricMap;

  useEffect(() => {
    setComment(row.human_comment || "");
    setRelevanceLabel(row.label_relevance_label || "");
    setEngagementLabel(row.label_engagement_label || "");
    setDetailLabel(row.label_detail_label || "");
    setClarityLabel(row.label_clarity_label || "");
    setStewieleoLabel(row.label_stewieleo_label || "");
  }, [row]);

  useEffect(() => {
    if (saveFeedbackTrigger === 0) return;
    saveHumanFeedback();
  }, [saveFeedbackTrigger]);

  function saveHumanFeedback() {
    row.human_comment = comment;
    row.submitted = true;
    row.last_labelled_by = getUserAlias();
    row.label_relevance_label = relevanceLabel;
    row.label_engagement_label = engagementLabel;
    row.label_detail_label = detailLabel;
    row.label_clarity_label = clarityLabel;
    row.label_stewieleo_label = stewieleoLabel;
    triggerNavRerender();
  }

  const styles = useStyles();

  // column should be one of metricMap's key
  function renderLabelLabel(column: metricNameType) {
    const lableColumnName = `label_${column}_label`;
    return (
      <div
        className={styles.stackHorizontal}
        key={lableColumnName + row.ConversationId}
      >
        <Label className={styles.label} style={{ width: "10em" }}>
          {column}
        </Label>
        <RadioGroup
          layout="horizontal"
          onChange={(e, data) => setMetricMap[column](data.value || "")}
          value={metricMap[column]}
          disabled={disabled}
        >
          <Radio value="Perfect" label="Perfect" />
          <Radio value="Good" label="Good" />
          <Radio value="Fair" label="Fair" />
          <Radio value="Poor" label="Poor" />
          <Radio value="Bad" label="Bad" />
        </RadioGroup>
      </div>
    );
  }

  return (
    <>
      <div>You are labeling the conversation, not labeling the LLM label.</div>
      <div
        className={mergeClasses(
          styles.stackVertical,
          styles.stackVerticalWithGap,
        )}
        style={{ margin: "1em 1em 0 0", flexWrap: "wrap" }}
      >
        {Object.keys(metricMap).map((name) => {
          return renderLabelLabel(name as metricNameType);
        })}
      </div>
      <Field label="Comment" style={{ margin: "1em 1em 0 0" }}>
        <Textarea
          disabled={disabled}
          resize="vertical"
          value={comment}
          onChange={(ev, value) => setComment(value.value)}
        ></Textarea>
      </Field>
    </>
  );
};

const renderLLMLabel = (row: StewieLeoRow) => {
  const stewieLeoRow = row as any;
  const scoreNames = ["relevance", "engagement", "detail", "clarity"];
  const responses = [];
  for (const name of scoreNames) {
    const response = stewieLeoRow[`response_${name}`];
    const responsePrefix =
      stewieLeoRow[`prompt_${name}`]?.split("# Output\n")[1];
    const fullResponse = responsePrefix + response;
    responses.push({
      name: name,
      response: fullResponse,
      score: stewieLeoRow[name],
    });
  }

  return (
    <div>
      <b>StewieLeo score {(stewieLeoRow as StewieLeoRow).stewieleo_score} </b>
      {responses.map((response) => {
        return (
          <CollapsedResource
            key={uuidv4()}
            title={`${response.name} ${response.score}`}
          >
            <ReactMarkdown linkTarget="_blank">
              {response.response}
            </ReactMarkdown>
          </CollapsedResource>
        );
      })}
    </div>
  );
};

const getCustomizedExportData = (rows: StewieLeoRow[]) => {
  const data = rows.map((row) => {
    return {
      conversation_id: row.ConversationId,
      engagement_label: row.engagement,
      label_engagement_label: row.label_engagement_label,
      relevance_label: row.relevance,
      label_relevance_label: row.label_relevance_label,
      detail_label: row.detail,
      label_detail_label: row.label_detail_label,
      clarity_label: row.clarity,
      label_clarity_label: row.label_clarity_label,
      stewieleo_score: row.stewieleo_score,
      label_stewieleo_label: row.label_stewieleo_label,
      comment: row.human_comment,
      last_labelled_by: row.last_labelled_by,
    };
  });
  return data;
};

export const StewieLeoDefinition: MetricDefinition<StewieLeoRow> = {
  name: "StewieLeo",
  url: "stewieleo",
  fetchData: undefined,
  nav: ControllableNav,
  renderHumanLabel: RenderHumanLabel,
  renderLLMLabel: renderLLMLabel,
  renderRawData: renderRawData,
  allowLocalUpload: true,
  getCustomizedExportData: getCustomizedExportData,
  humanLabelFieldNames: [
    "human_comment",
    "label_relevance_label",
    "label_engagement_label",
    "label_detail_label",
    "label_clarity_label",
    "label_stewieleo_label",
  ],
};
