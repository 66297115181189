import { useMsal } from "@azure/msal-react";
import { Body2, Button } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { loginAction } from "../../actions/loginAction";
import { store } from "../../store/store";

type UnauthenticatedViewProps = {
  isLogout: boolean;
};

export const UnauthenticatedView = observer(
  (props: UnauthenticatedViewProps) => {
    const { instance } = useMsal();
    useEffect(() => {
      setTimeout(() => {
        if (
          store.account === undefined &&
          store.tokenRefreshError === undefined &&
          props.isLogout === false
        ) {
          loginAction(instance, "redirect");
        }
      }, 500);
    }, [store.account]);

    return (
      <>
        {props.isLogout && (
          <Body2>You have been logged out. You can close this window.</Body2>
        )}
        {!props.isLogout && (
          <Body2>You are not signed in. Please sign in to continue.</Body2>
        )}
        <br />
        <br />
        <Button
          appearance="primary"
          onClick={() => {
            loginAction(instance, "redirect");
          }}
        >
          Sign in
        </Button>
      </>
    );
  },
);
