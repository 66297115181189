"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobConfiguration = exports.BizChatExp = exports.locationInfo = exports.Configure3pPluginsKeys = exports.BizChatMetricsKeys = exports.BingTestSetList = exports.BingTestSet = exports.BingSydneyEngine = exports.BingSydneyExp = void 0;
const tools_1 = require("../tools");
exports.BingSydneyExp = (0, tools_1.strictObject)({
    url: tools_1.str,
    option_sets: tools_1.str,
    options: (0, tools_1.undefinedOr)(tools_1.anyObject),
});
exports.BingSydneyEngine = (0, tools_1.strictObject)({
    exp_name: tools_1.str,
    baseline_names: (0, tools_1.array)(tools_1.str),
    sydney: exports.BingSydneyExp,
    extra_fields: (0, tools_1.undefinedOr)(tools_1.anyObject),
});
exports.BingTestSet = (0, tools_1.strictObject)({
    metrics: (0, tools_1.undefinedOr)((0, tools_1.array)(tools_1.str)),
    input_folder: (0, tools_1.undefinedOr)(tools_1.str),
    options: (0, tools_1.undefinedOr)((0, tools_1.array)(tools_1.str)),
    data_uri: (0, tools_1.undefinedOr)(tools_1.str),
    previous_scrape_output: (0, tools_1.undefinedOr)(tools_1.str),
});
exports.BingTestSetList = (0, tools_1.mapOf)(exports.BingTestSet);
exports.BizChatMetricsKeys = [
    "scleo",
    "apology",
    "stewieleo",
    "pileo",
    "groundleo",
    "lmchecklist",
    "stewieleosbs",
    "tcr",
    "groundleo_claimbreak",
    "sbsleov2",
    "sbsleo",
    "reasoningleo",
    "teamsgroundleo",
    "stewieleov5",
    "codeleo",
];
const BizChatMetrics = exports.BizChatMetricsKeys.reduce((acc, key) => {
    acc[key] = (0, tools_1.undefinedOr)(tools_1.bool);
    return acc;
}, {});
exports.Configure3pPluginsKeys = [
    "sideload_3p_plugins",
    "unsideload_3p_plugins",
];
const Configure3pPlugins = exports.Configure3pPluginsKeys.reduce((acc, key) => {
    acc[key] = (0, tools_1.undefinedOr)(tools_1.bool);
    return acc;
}, {});
exports.locationInfo = (0, tools_1.strictObject)({
    country: (0, tools_1.undefinedOr)(tools_1.str),
    countryConfidence: (0, tools_1.undefinedOr)(tools_1.num),
    state: (0, tools_1.undefinedOr)(tools_1.str),
    city: (0, tools_1.undefinedOr)(tools_1.str),
    cityConfidence: (0, tools_1.undefinedOr)(tools_1.num),
    zipCode: (0, tools_1.undefinedOr)(tools_1.str),
    timeZoneOffset: (0, tools_1.undefinedOr)(tools_1.num),
    dma: (0, tools_1.undefinedOr)(tools_1.num),
    latitude: (0, tools_1.undefinedOr)(tools_1.num),
    longitude: (0, tools_1.undefinedOr)(tools_1.num),
});
exports.BizChatExp = (0, tools_1.strictObject)({
    exp_name: (0, tools_1.undefinedOr)(tools_1.str),
    is_baseline: (0, tools_1.undefinedOr)(tools_1.bool),
    sydney: (0, tools_1.undefinedOr)((0, tools_1.strictObject)({
        url: (0, tools_1.undefinedOr)(tools_1.str),
        option_sets: (0, tools_1.undefinedOr)(tools_1.str),
        variants: (0, tools_1.undefinedOr)(tools_1.str),
        options: (0, tools_1.undefinedOr)(tools_1.anyObject),
        locationInfo: (0, tools_1.undefinedOr)(exports.locationInfo),
        market: (0, tools_1.undefinedOr)(tools_1.str),
        verbosity: tools_1.unknown,
    })),
});
exports.JobConfiguration = (0, tools_1.undefinedOr)((0, tools_1.strictObject)({
    // BizChat Schema
    create_sbs_experiment: (0, tools_1.undefinedOr)(tools_1.bool),
    // This config if set will enable auto sideloading and unsideloading of mocked 3p plugins as part of job execution
    ...Configure3pPlugins,
    configs_in_json: (0, tools_1.undefinedOr)((0, tools_1.strictObject)({
        ...BizChatMetrics,
        options: (0, tools_1.undefinedOr)((0, tools_1.array)(tools_1.str)),
        sydney_client_secret: (0, tools_1.undefinedOr)(tools_1.str),
        conversations: (0, tools_1.undefinedOr)((0, tools_1.strictObject)({
            data_generation_max_turns: (0, tools_1.undefinedOr)(tools_1.num),
            gpt_identifier: (0, tools_1.undefinedOr)(tools_1.str),
            lmchecklist_input_file: (0, tools_1.undefinedOr)(tools_1.str),
            user_id: (0, tools_1.undefinedOr)(tools_1.str),
            input_file: (0, tools_1.undefinedOr)(tools_1.str),
            input_folder: (0, tools_1.undefinedOr)(tools_1.str),
            exp_configs: (0, tools_1.undefinedOr)((0, tools_1.array)(exports.BizChatExp)),
            eval_time_override: (0, tools_1.undefinedOr)(tools_1.str),
        })),
    })),
    // Bing Schema
    exp_configs: (0, tools_1.undefinedOr)((0, tools_1.array)(exports.BingSydneyEngine)),
    multi_turn: (0, tools_1.undefinedOr)(exports.BingTestSetList),
    single_turn: (0, tools_1.undefinedOr)(exports.BingTestSetList),
    cluster: (0, tools_1.undefinedOr)(tools_1.str),
    scenario: (0, tools_1.undefinedOr)(tools_1.str),
    user_id: (0, tools_1.undefinedOr)(tools_1.str),
}));
