import type { CheckboxProps } from "@fluentui/react-components";
import {
  Button,
  Checkbox,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Input,
  makeStyles,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  useId,
} from "@fluentui/react-components";
import React from "react";
import {
  addShareByPassedUsers,
  getShareByPassedUsers,
  removeShareByPassedUsers,
} from "../../helpers/apiHelper";

type ManageShareByPassedUsersDialogProps = {
  isOpen: boolean;
  onComplete: () => void;
};

const useStyles = makeStyles({
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  tableContainer: {
    position: "relative",
    maxHeight: "50vh",
    minHeight: "20vh",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  buttonGroup: {
    display: "flex",
    gap: "1rem",
  },
});
const columns = ["user", "action"];

export const ManageShareByPassedUsersDialog = (
  props: ManageShareByPassedUsersDialogProps,
) => {
  const styles = useStyles();
  const [shareByPassedUsers, setShareByPassedUsers] = React.useState<string[]>(
    [],
  );
  const [loading, setLoading] = React.useState(false);
  const [inputUsers, setInputUsers] = React.useState("");
  const [selectedUsers, setSelectedUsers] = React.useState<string[]>([]);
  const handleCheckboxChange = (
    user: string,
    checked: CheckboxProps["checked"],
  ) => {
    setSelectedUsers((prevSelectedUsers) =>
      checked
        ? [...prevSelectedUsers, user]
        : prevSelectedUsers.filter((u) => u !== user),
    );
  };
  const executeAction = async (action: () => Promise<void>) => {
    setLoading(true);
    try {
      await action();
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false);
    }
  };
  const inputId = useId("input-users");
  React.useEffect(() => {
    if (props.isOpen) {
      executeAction(async () => {
        const res = await getShareByPassedUsers();
        setShareByPassedUsers(res);
      });
    }
  }, [props.isOpen]);

  return (
    <Dialog open={props.isOpen} onOpenChange={() => props.onComplete()}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{`Manage Share By Passed Users`}</DialogTitle>
          <DialogContent>
            <div className={styles.tableContainer}>
              {loading && (
                <div className={styles.overlay}>
                  <Spinner label="Loading..." />
                </div>
              )}
              <div>
                <div className={styles.buttonContainer}>
                  <Input
                    value={inputUsers}
                    placeholder="Input email, use ',' split multiple users"
                    id={inputId}
                    onChange={(_, data) => {
                      setInputUsers(data.value);
                    }}
                  />
                  <div className={styles.buttonGroup}>
                    <Button
                      appearance="primary"
                      disabled={loading}
                      onClick={() =>
                        executeAction(async () => {
                          const users = inputUsers.split(",");
                          await addShareByPassedUsers({ users });
                          const updatedUsers = await getShareByPassedUsers();
                          setShareByPassedUsers(updatedUsers);
                          setInputUsers("");
                        })
                      }
                    >
                      Add
                    </Button>

                    <Button
                      appearance="secondary"
                      disabled={loading}
                      onClick={() =>
                        executeAction(async () => {
                          await removeShareByPassedUsers({
                            users: selectedUsers,
                          });
                          const updatedUsers = await getShareByPassedUsers();
                          setShareByPassedUsers(updatedUsers);
                          setSelectedUsers([]);
                        })
                      }
                    >
                      Remove Selected
                    </Button>
                  </div>
                </div>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHeaderCell key="select-all">
                        <Checkbox
                          label="select all"
                          checked={
                            selectedUsers.length === shareByPassedUsers.length
                          }
                          onChange={(e, data) => {
                            if (data.checked) {
                              setSelectedUsers(shareByPassedUsers);
                            } else {
                              setSelectedUsers([]);
                            }
                          }}
                        />
                      </TableHeaderCell>
                      {columns.map((column) => (
                        <TableHeaderCell key={column}>{column}</TableHeaderCell>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {shareByPassedUsers.map((user) => (
                      <TableRow key={user}>
                        <TableCell>
                          <Checkbox
                            checked={selectedUsers.includes(user)}
                            onChange={(e, data) =>
                              handleCheckboxChange(user, data.checked)
                            }
                          />
                        </TableCell>
                        <TableCell>{user}</TableCell>
                        <TableCell>
                          <Button
                            appearance="secondary"
                            disabled={loading}
                            onClick={() =>
                              executeAction(async () => {
                                await removeShareByPassedUsers({
                                  users: [user],
                                });
                                const updatedUsers =
                                  await getShareByPassedUsers();
                                setShareByPassedUsers(updatedUsers);
                              })
                            }
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
