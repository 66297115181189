import { observer } from "mobx-react-lite";
import type { FeatureSettingsDialogContributionProps } from "../../../../contribution/FeatureSettingsContribution";
import { getTokenName } from "../../../../helpers/accountHelper";
import { store } from "../../../../store/store";
import { GuidanceLinkTeamsCli } from "../Other/GuidanceLink";
import { UploadRefreshTokenDialog } from "./UploadRefreshTokenDialog";

export const UploadTeamsCliRefreshTokenDialog = observer(
  (props: FeatureSettingsDialogContributionProps) => {
    const tokenName =
      getTokenName(store.account?.username ?? "", "teamscli") ?? "";
    return (
      <UploadRefreshTokenDialog
        tokenType={"Teams CLI refresh token"}
        tokenName={tokenName}
        dialogTitle={"Upload Teams CLI Refresh Token"}
        loggingSource={"UploadTeamsCliRefreshTokenDialog"}
        guidanceLink={<GuidanceLinkTeamsCli />}
        {...props}
      />
    );
  },
);
