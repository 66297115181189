import { Button, makeStyles, shorthands } from "@fluentui/react-components";
import type { FluentIcon } from "@fluentui/react-icons/lib/utils/createFluentIcon";
import { observer } from "mobx-react-lite";

const useStyle = makeStyles({
  button: {
    marginRight: "20px",
    fontSize: "12px",
    fontWeight: 400,
    color: "white",
    ...shorthands.padding("0px"),
  },
  icon: {
    lineHeight: "20px",
    color: "white",
    height: "20px",
    width: "20px",
  },
});

type Props = {
  ariaLabel: string;
  onClick: () => void;
  Icon: FluentIcon;
  text: string;
};

export const AppBarButton = observer((props: Props) => {
  const { ariaLabel, onClick, Icon, text } = props;
  const styles = useStyle();

  return (
    <>
      <Button
        aria-label={ariaLabel}
        className={styles.button}
        appearance="transparent"
        onClick={onClick}
        icon={<Icon className={styles.icon} />}
      >
        {text}
      </Button>
      <div
        style={{
          marginRight: "20px",
          width: "1px",
          height: "11px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
        }}
      />
    </>
  );
});
