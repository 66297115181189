"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BingMetaDataResponse = void 0;
const tools_1 = require("../tools");
const BingMetrics = (0, tools_1.object)({
    singleturn: (0, tools_1.optional)((0, tools_1.array)(tools_1.str)),
    multiturn: (0, tools_1.optional)((0, tools_1.array)(tools_1.str)),
});
exports.BingMetaDataResponse = (0, tools_1.object)({
    metrics: BingMetrics,
    scenarios: (0, tools_1.optional)((0, tools_1.array)(tools_1.str)),
    datasets: (0, tools_1.optional)((0, tools_1.array)(tools_1.str)),
    options: (0, tools_1.optional)((0, tools_1.array)(tools_1.str)),
    group_to_template: (0, tools_1.optional)((0, tools_1.mapOf)((0, tools_1.array)(tools_1.str))),
});
