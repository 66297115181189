import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { store } from "../../../store/store";
import { ContentPanel } from "./ContentPanel";
import type { MetricDefinition } from "./MetricDefinition";
import type {
  LLMJudgeEvaluationTaskData,
  LLMJudgeEvaluationTaskStatus,
} from "./evaluationTaskDashboard/models/LLMJudgeEvaluationTaskData";
import {
  GetTaskHistory,
  GetTaskStatistics,
} from "./evaluationTaskDashboard/sydneyEvalManagement";
import { GroundLeoClaimBreakDefinition } from "./groundleo/GroundLeoClaimBreakDefinition";
import { GroundLeoDefinition } from "./groundleo/GroundLeoDefinition";
import { TaskType } from "./models/TaskType";
import { ReasoningLeoDefinition } from "./reasoningleo/ReasoningLeoDefinition";
import { SbsLeoDefinition } from "./sbsleo/SbsLeoDefinition";
import { StewieLeoDefinition } from "./stewieleo/StewieLeoDefinition";
import { TaskCompleteDefinition } from "./taskcomplete/TaskCompleteDefinition";
import { SCLeoDefinition } from "./scleo/SCLeoDefinition";
import { NDCGDefinition } from "./ndcg/NDCGDefinition";

// Add new customized metrics here
const supportedMetricDefinitions: MetricDefinition<any>[] = [
  GroundLeoDefinition,
  GroundLeoClaimBreakDefinition,
  TaskCompleteDefinition,
  StewieLeoDefinition,
  SbsLeoDefinition,
  ReasoningLeoDefinition,
  SCLeoDefinition,
  NDCGDefinition,
];

export function getDefinitionByName(name: string) {
  if (name === "SBS") return SbsLeoDefinition;
  return supportedMetricDefinitions.find((d) => d.name === name);
}

export function getAllowScraping(name: string, urlParameters: string) {
  if (name === "SBS") return true;
  const searchParams = new URLSearchParams(urlParameters);
  return searchParams.get("allowScraping") === "true";
}

export function getTaskId() {
  const searchParams = new URLSearchParams(window.location.search);
  const taskIdFromSearch = searchParams.get("taskId");
  return taskIdFromSearch ? taskIdFromSearch : useParams().taskId;
}

export const TaskAnnotationMainLayout = () => {
  const [evaluationTask, setEvaluationTask] =
    useState<LLMJudgeEvaluationTaskData>();
  const [metricDefinition, setMetricDefinition] =
    useState<MetricDefinition<any>>();
  const [userStatistics, setUserStatistics] =
    useState<LLMJudgeEvaluationTaskStatus>();
  const [hasPermission, setHasPermission] = useState<boolean>(true);
  const [allowScraping, setAllowScraping] = useState<boolean>(false);

  // get task id from search params
  const taskId = getTaskId();

  if (!taskId) {
    alert("No taskId provided");
    return <></>;
  }
  useEffect(() => {
    const job = async () => {
      try {
        const evaluationTask = await GetTaskHistory(taskId);
        if (evaluationTask.length === 0) {
          alert("No task found with taskId: " + taskId);
          return <></>;
        }
        setEvaluationTask(evaluationTask[0]);

        const metricDefinition = getDefinitionByName(evaluationTask[0].type);
        if (!metricDefinition) {
          alert("Unsupported metric type: " + evaluationTask[0].type);
          return <></>;
        }
        setMetricDefinition(metricDefinition);
        setAllowScraping(
          getAllowScraping(
            evaluationTask[0].type,
            evaluationTask[0].url_parameters || "",
          ),
        );

        const userUpn = store.account?.username;
        if (
          !userUpn ||
          (!evaluationTask[0].allow_anyone_to_label &&
            evaluationTask[0].created_user_upn !== userUpn &&
            !evaluationTask[0].administrators?.includes(userUpn) &&
            !evaluationTask[0].label_contributors?.includes(userUpn))
        ) {
          setHasPermission(false);
          return;
        }

        if (evaluationTask[0].task_type === TaskType.Collection) {
          // No task statistics and user statistics for collection task
          return;
        }

        const taskStatistics = await GetTaskStatistics(
          taskId,
          store.account?.username,
        );
        setUserStatistics(taskStatistics);
      } catch (e: any) {
        alert("Failed to get task history or task statistics. " + e.toString());
      }
    };
    job();
  }, []);

  return (
    <div style={{ padding: ".5em" }}>
      {evaluationTask && metricDefinition && (
        <ContentPanel
          metricDefinition={metricDefinition}
          task={evaluationTask}
          taskMode={true}
          taskStatistics={userStatistics}
          hasPermission={hasPermission}
          allowScraping={allowScraping}
          allowUserQuery={
            allowScraping && evaluationTask.task_type === TaskType.Collection
          }
        />
      )}
    </div>
  );
};
