import type {
  IFullSearchResponse,
  IPluginSearchInput,
  IPluginSearchOutput,
  ISearchResult,
  ISydneyMetric,
  ISydneyResponse,
  IWebLogOutput,
} from "./SydneyResponse";

export function safe_json_load(json_string: string, defaultValue?: object) {
  try {
    return JSON.parse(json_string);
  } catch {
    return defaultValue;
  }
}

export function get_web_results_for_reasoning_iteration(
  response: ISydneyResponse,
  start: number,
  end: number,
) {
  const web_logs = response.telemetry.metrics
    .slice(start, end)
    .filter(
      (web_log) => web_log.serviceName === "ExtensionRunner:ext:search-web",
    )
    .map((web_log) => extract_web_results(web_log))
    .flat();

  const result: Record<string, Record<string, object>> = {};
  for (const web_result of web_logs) {
    if (!result[web_result.PluginName]) {
      result[web_result.PluginName] = {};
    }

    result[web_result.PluginName][web_result.Query] = web_result;
  }

  return result;
}

function extract_web_results(web_log: ISydneyMetric) {
  const output = safe_json_load(web_log.output, {}) as IWebLogOutput;
  const response = output.responses.find(
    (r) => r.messageType === "InternalSearchResult",
  );
  const grounding_info = response?.groundingInfo;

  if (!grounding_info) {
    return [];
  }

  for (const group of Object.values(grounding_info)) {
    if (group.every((web_result) => web_result.index !== null)) {
      group.sort((a, b) => a.index - b.index);
    }
  }

  const query = get_query_from_regex(response.hiddenText);

  return Object.entries(grounding_info).map(([plugin_name, web_results]) => ({
    PluginName: plugin_name,
    Query: query,
    Results: web_results,
  }));
}

function get_query_from_regex(text: string) {
  try {
    const full_query = text.match(/search_web\('[\w\s]+'\)/)?.[0];
    return full_query?.replace(/search_web\('/, "").replace("')", "") || "";
  } catch {
    return "";
  }
}

export function get_search_results_for_reasoning_iteration(
  response: ISydneyResponse,
  start: number,
  end: number,
) {
  const search_logs = response.telemetry.metrics
    .slice(start, end)
    .filter((search_log) => search_log.serviceName === "SubstrateSearchService")
    .map((search_log) => process_plugin_search(search_log));

  const result: Record<string, Record<string, object>> = {};
  for (const search_result of search_logs) {
    if (!result[search_result.PluginName]) {
      result[search_result.PluginName] = {};
    }

    result[search_result.PluginName][
      get_clean_query_string(search_result.Query)
    ] = search_result;
  }

  return result;
}

function get_clean_query_string(query_string: string) {
  // Remove any unicode characters from the query string
  const s = query_string.replace(/\\+u\w+/g, "");
  // Remove any non-word characters from the query string
  return s.replace(/[^\w]/g, "");
}

function process_plugin_search(log: ISydneyMetric) {
  const input = safe_json_load(log.input, {}) as IPluginSearchInput;
  const output = parse_output_search(log) as IPluginSearchOutput &
    IFullSearchResponse;
  return {
    Query: get_query_string(input),
    Results: get_results(output),
    PluginExecuted: true,
    PluginName: get_plugin_name(output),
    LuAlteredQueries: extract_lu_altered_queries(output),
    LlmLuOutput: extract_llm_lu_output(output),
  };
}

function extract_llm_lu_output(output: IPluginSearchOutput) {
  // Sydcomp has ExtendedData at top level, Avalon needs to look in SubstrateSearchServiceResponse for full 3S repsonse
  let extended_data = output.ExtendedData;
  if (!extended_data) {
    try {
      extended_data = (
        JSON.parse(output.SubstrateSearchServiceResponse) as IPluginSearchOutput
      ).ExtendedData;
    } catch {
      return null;
    }
  }

  return extended_data?.Info.LlmLuOutput;
}

function extract_lu_altered_queries(output: IPluginSearchOutput) {
  // Sydcomp has ExtendedData at top level, Avalon needs to look in SubstrateSearchServiceResponse for full 3S repsonse
  let extended_data = output.ExtendedData;
  if (!extended_data) {
    try {
      extended_data = (
        JSON.parse(output.SubstrateSearchServiceResponse) as IPluginSearchOutput
      ).ExtendedData;
    } catch {
      return null;
    }
  }

  return extended_data?.Info.LuAlteredQueries;
}

function get_plugin_name(output: IPluginSearchOutput) {
  return output.PluginName || "search_enterprise";
}

function get_query_string(input: IPluginSearchInput) {
  const answers_requested = input.AnswerEntityRequests || [];
  const entities_requested = input.EntityRequests || [];

  const query_strings = [...answers_requested, ...entities_requested]
    .map((request) => request.Query?.DisplayQueryString)
    .filter((query_string) => query_string);

  if (
    query_strings.length === 0 ||
    query_strings.every((query_string) => !query_string)
  ) {
    return "";
  }

  return query_strings[0] || "";
}

function parse_output_search(log: ISydneyMetric) {
  const output_jsons = log.output
    .match(/\{.*\}/g)
    ?.map((json_string) => safe_json_load(json_string));

  return (
    output_jsons?.find(
      (output) =>
        output.EntitySets || output.AnswerEntitySets || output.MsResults,
    ) || {}
  );
}

function get_results(output: IFullSearchResponse) {
  return output.MsResults || parse_final_response_wpr(output);
}

function parse_final_response_wpr(full_search_response: IFullSearchResponse) {
  const entities = (full_search_response.EntitySets ?? []).flatMap(
    (entity_set) =>
      entity_set.ResultSets.flatMap((result_set) => result_set.Results),
  );
  const answers = (full_search_response.AnswerEntitySets ?? []).flatMap(
    (answer_set) =>
      answer_set.ResultSets.flatMap((result_set) => result_set.Results),
  );

  const results: Record<string, ISearchResult> = {};
  for (const entity of [...entities, ...answers]) {
    results[entity.Id] = entity;
  }

  const wpr_ids = new Set(
    (full_search_response.WholePageRankings ?? []).flatMap((wpr) =>
      wpr.Cards.flatMap((card) =>
        card.EntityCards.flatMap((entity_card) => entity_card.EntityIds),
      ),
    ),
  );

  return Array.from(wpr_ids)
    .map((wpr_id) => results[wpr_id])
    .sort((a, b) => a.Rank - b.Rank);
}

export function get_plugins_for_reasoning_iteration(log: ISydneyMetric) {
  const output_json = log.output.match(/\{[\s\S]*\}/g);
  if (!output_json) {
    return { plugins_data: {}, plugin_calls: [] };
  }

  const output = safe_json_load(output_json[0]) as {
    text?: string;
    choices?: { text?: string; message?: { content?: string } }[];
  };

  const output_text = (output.text ||
    output.choices?.[0]?.text ||
    output.choices?.[0]?.message?.content ||
    "") as string;

  const plugin_calls = output_text
    .split("\n")
    .filter((line) => line.match(/search[_\w]*\(query=.+?\)/));

  const plugin_tuples = plugin_calls
    .map((plugin_call) => get_plugin_tuple(plugin_call))
    .filter((plugin_tuple) => plugin_tuple);

  const result: Record<string, Record<string, object>> = {};
  for (const plugin_tuple of plugin_tuples) {
    if (!plugin_tuple) continue;
    const { plugin_name, plugin_query } = plugin_tuple;
    if (!result[plugin_name]) {
      result[plugin_name] = {};
    }
    if (!plugin_query) continue;

    result[plugin_name][get_clean_query_string(plugin_query)] = {
      Query: plugin_query,
      Results: [],
      PluginExecuted: false,
    };
  }

  return { plugins_data: result, plugin_calls };
}

function get_plugin_tuple(plugin_call: string) {
  try {
    const plugin_name = plugin_call.split("(")[0];
    const plugin_query = plugin_call.match(
      /(?<=query=\\").+(?=\\")|(?<=query=").+(?=")|(?<=query=').+(?=')/,
    )?.[0];
    return { plugin_name, plugin_query };
  } catch {
    return undefined;
  }
}

export function get_reasoning_logs(response: ISydneyResponse) {
  const reasoning_logs = get_telemetry_metric(response, [
    "DeepLeo",
    "ChatCompletions",
    "DeepLeoImprovedNetworking",
  ]);

  const search_pattern = /search[_\w]*\(query=.+?\)/;
  return reasoning_logs.filter((log) => search_pattern.test(log.metric.output));
}

function get_telemetry_metric(
  response: ISydneyResponse,
  service_names: string | string[],
) {
  if (!Array.isArray(service_names)) {
    service_names = [service_names];
  }

  return response.telemetry.metrics
    .map((metric, index) => ({ metric, index }))
    .filter(({ metric }) => service_names.includes(metric.serviceName));
}
