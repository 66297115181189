import { Field, Input, shorthands, tokens } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { updateJobNameAction } from "../../../actions/jobActions";
import { getJobCreationError } from "../../../selectors/creationError/getJobCreationError";
import { jobStore } from "../../../store/jobStore";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobNameView = observer(() => {
  const styles = useStyles();

  return (
    <div className={styles.block}>
      <Field
        required
        label={<span className={styles.blockTitle}>Job Name</span>}
        validationState={getJobCreationError.get().jobNameError.state}
        validationMessage={getJobCreationError.get().jobNameError.message}
      >
        <Input
          size="medium"
          placeholder="Enter experiment name"
          value={jobStore.jobName}
          onChange={(_, data) => {
            updateJobNameAction(data.value);
          }}
        />
      </Field>
    </div>
  );
});
