import type { IPublicClientApplication } from "@azure/msal-browser";
import { action } from "satcheljs";

export const loginAction = action(
  "loginAction",
  (instance: IPublicClientApplication, actionType: "popup" | "redirect") => ({
    instance,
    actionType,
  }),
);
