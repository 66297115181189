export const cachable = <T>(
  generator: () => Promise<T>,
  clean: () => void = () => {
    return;
  },
): {
  get: () => Promise<T>;
  cleanOnError: <U>(promise: Promise<U>) => Promise<U>;
} => {
  let cachedPromise: Promise<T> | undefined = undefined;

  return {
    get: () => {
      if (cachedPromise !== undefined) {
        return cachedPromise;
      }

      const promise = generator().catch((error) => {
        cachedPromise = undefined;
        throw error;
      });

      cachedPromise = promise;
      return promise;
    },
    cleanOnError: (p) => {
      return p.catch((e) => {
        cachedPromise = undefined;
        clean?.();
        throw e;
      });
    },
  };
};
