export const checkExpName = (value: string | undefined, path: string) => {
  if (value === undefined) {
    return { path, message: "Experiment name should not be undefined!" };
  }

  if (value === "") {
    return { path, message: "Experiment name should not be empty!" };
  }

  const validNames = ["control", "experiment"];
  if (validNames.includes(value) === false) {
    return {
      path,
      message: `Experiment name should be "control" or "experiment", currently "${value}"`,
    };
  }

  return undefined;
};

export const checkExpNameNotEmpty = (
  value: string | undefined,
  path: string,
) => {
  if (value === undefined) {
    return { path, message: "Experiment name should not be undefined!" };
  }

  if (value === "") {
    return { path, message: "Experiment name should not be empty!" };
  }
  return undefined;
};
