import { mutatorAction } from "satcheljs";
import type { QuerySetFile } from "../../../models/QuerySetFile";
import type { QuerySetTableFilter } from "../models/QuerySets";
import { querySetsStore } from "../store/querySetsStore";

export const updateQuerySetsLoadingStatus = mutatorAction(
  "updateQuerySetsLoadingStatus",
  (isLoading: boolean) => {
    querySetsStore.isLoading = isLoading;
  },
);

export const updateSelectedProduct = mutatorAction(
  "updateSelectedProduct",
  (selectedProduct: "MChat" | "Bing") => {
    querySetsStore.selectedProduct = selectedProduct;
  },
);

export const updateSelectedTab = mutatorAction(
  "updateSelectedTab",
  (selectedTab: QuerySetTableFilter) => {
    querySetsStore.selectedTab = selectedTab;
  },
);

export const updateMChatPublicQuerySets = mutatorAction(
  "updateMChatPublicQuerySets",
  (querySets: QuerySetFile[]) => {
    querySetsStore.MChatPublicQuerySets = querySets;
  },
);

export const updateMChatCustomQuerySets = mutatorAction(
  "updateMChatCustomQuerySets",
  (querySets: QuerySetFile[]) => {
    querySetsStore.MChatCustomQuerySets = querySets;
  },
);

export const updateMChatGeneratedQuerySets = mutatorAction(
  "updateMChatGeneratedQuerySets",
  (querySets: QuerySetFile[]) => {
    querySetsStore.MChatGeneratedQuerySets = querySets;
  },
);

export const updateBingCustomQuerySets = mutatorAction(
  "updateBingCustomQuerySets",
  (querySets: QuerySetFile[]) => {
    querySetsStore.BingCustomQuerySets = querySets;
  },
);

export const updateBingGeneratedQuerySets = mutatorAction(
  "updateBingGeneratedQuerySets",
  (querySets: QuerySetFile[]) => {
    querySetsStore.BingGeneratedQuerySets = querySets;
  },
);
