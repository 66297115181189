import type { TableColumnDefinition } from "@fluentui/react-components";
import {
  Button,
  Checkbox,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Label,
  Link,
  MessageBar,
  TableCellLayout,
  Textarea,
  createTableColumn,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import {
  ArrowDownload24Regular,
  Copy24Regular,
  Edit24Regular,
} from "@fluentui/react-icons";
import Papa from "papaparse";
import React from "react";
import { useLocation } from "react-router-dom";
import { telemetryHelper } from "../../../../helpers/telemetryHelper";
import { store } from "../../../../store/store";
import {
  GetDownloadDataFromRows,
  GetSimplifiedRow,
  fetchDataFromSQLForCollectionTask,
  fetchFeedbackFromSQL,
  mergeSbsLeoRow,
  mergeSbsLeoTsvRow,
  isFromCsv,
} from "../ContentPanel";
import { getDefinitionByName, getTaskId } from "../TaskAnnoationMainLayout";
import { TaskType } from "../models/TaskType";
import { DeleteTaskDialog } from "../sharedComponents/DeleteTaskDialog";
import { downloadFile } from "../utils/DownloadUtils";
import { FormatDateToString } from "../utils/FormatTime";
import {
  checkInputNumberValidity,
  checkInputUpnValidity,
} from "../utils/InputUtils";
import type { MessageBarType } from "../utils/MessageBarType";
import { getColumnKeys } from "../utils/RowParseUtils";
import {
  LLMJudgeEvaluationTaskDashboardClass,
  taskDateFormat,
} from "./LLMJudgeEvaluationTaskDashboard";
import type {
  LLMJudgeEvaluationTaskData,
  LLMJudgeEvaluationTaskJudges,
} from "./models/LLMJudgeEvaluationTaskData";
import {
  DeleteEvaluationTaskById,
  GetAnnotationRawData,
  GetTaskHistory,
  GetTaskJudgesList,
  UpdateEvaluationTask,
} from "./sydneyEvalManagement";
import MetricsBoard from "../metrics/MetricsBoard";
import {
  allowToCheckCompleteness,
  getUrlParameterValue,
} from "../utils/utilities";

interface StatusMessage {
  message: string;
  status?: MessageBarType;
}

interface AvailableNumberDisplayMap {
  userUpn: string;
  taskType: string;
  availableNumber: number;
}

const initialState = {
  statusMessage: { message: "Loading...", status: "info" } as
    | StatusMessage
    | undefined,
  taskOverview: undefined as LLMJudgeEvaluationTaskData | undefined,
  taskJudges: undefined as LLMJudgeEvaluationTaskJudges[] | undefined,
  taskJudgesToShow: undefined as LLMJudgeEvaluationTaskJudges[] | undefined,
  availableNumberDisplay: [] as AvailableNumberDisplayMap[],
  editMode: false,
  isCompletionDateCalendarVisible: false,
  desiredCompletionDate: "",
  labelContributors: "" as string | undefined,
  hasPermission: true as boolean | undefined,
  enableEditLabelContributor: false,
  customizedData: undefined as Record<string, string | number>[] | undefined,
  urlParametersMap: undefined as Record<string, string> | undefined,
};

const taskTimeFormat = "yyyy-MM-dd hh:mm:ss UTC";

type LLMJudgeEvaluationTaskDetailProps = {
  location: {
    search: string;
  };
  taskId: string;
};

type FeedbackType = "all" | "customized";

type VariantsType = "Control" | "Exp";

export function getVariantsByType(variants: string, type: VariantsType) {
  const variantsList = variants.split("|");
  if (variantsList.length === 0) {
    return "";
  }
  if (type === "Control") {
    return variantsList[0];
  } else if (variantsList.length === 1) {
    // If there is only one variant, it shouldn't have exp variants, just return empty string
    return "";
  }
  return variantsList[1];
}

export class LLMJudgeEvaluationTaskDetailClass extends React.Component<
  LLMJudgeEvaluationTaskDetailProps,
  typeof initialState
> {
  private newTaskInfo = {
    description: "",
    targetConversationNumber: "",
    allowAnyoneToLabel: false,
    administrators: "",
  };

  private columns: TableColumnDefinition<LLMJudgeEvaluationTaskJudges>[] = [];

  private path = window.location.pathname;

  private taskOverview = undefined as LLMJudgeEvaluationTaskData | undefined;

  constructor(props: LLMJudgeEvaluationTaskDetailProps) {
    super(props);
    this.state = {
      ...initialState,
    };

    this.columns = [
      createTableColumn<LLMJudgeEvaluationTaskJudges>({
        columnId: "JudgeName",
        renderHeaderCell: () => {
          return "Judge Name";
        },
        renderCell: (judge) => {
          return <TableCellLayout>{judge.user_display_name}</TableCellLayout>;
        },
      }),
      createTableColumn<LLMJudgeEvaluationTaskJudges>({
        columnId: "Email",
        renderHeaderCell: () => {
          return "Email";
        },
        renderCell: (judge) => {
          const email = `mailto:${judge.user_upn}`;
          return (
            <TableCellLayout>
              <Link target="_blank" href={email} rel="noopener noreferrer">
                {judge.user_upn}
              </Link>
            </TableCellLayout>
          );
        },
      }),
      createTableColumn<LLMJudgeEvaluationTaskJudges>({
        columnId: "Completed",
        renderHeaderCell: () => {
          return "Completed";
        },
        renderCell: (judge) => {
          return <TableCellLayout>{judge.complete_number}</TableCellLayout>;
        },
      }),
      createTableColumn<LLMJudgeEvaluationTaskJudges>({
        columnId: "Cancelled",
        renderHeaderCell: () => {
          return "Cancelled";
        },
        renderCell: (judge) => {
          return <TableCellLayout>{judge.cancel_number}</TableCellLayout>;
        },
      }),
      createTableColumn<LLMJudgeEvaluationTaskJudges>({
        columnId: "InProgress",
        renderHeaderCell: () => {
          return "In Progress";
        },
        renderCell: (judge) => {
          return <TableCellLayout>{judge.in_progress_number}</TableCellLayout>;
        },
      }),
    ];
  }

  public componentDidMount() {
    if (!this.props.taskId) {
      alert("taskId is needed in URL parameter");
      return;
    }

    telemetryHelper.logDiagnosticEvent("HumanCorrelationStudyVisitPage", {
      message: "Visited Human Correlation Study task detail page.",
      path: this.path,
    });

    this.initPage();
  }

  render() {
    const {
      taskOverview,
      taskJudgesToShow,
      statusMessage,
      editMode,
      hasPermission,
      desiredCompletionDate,
      labelContributors,
      enableEditLabelContributor,
      customizedData,
      urlParametersMap,
    } = this.state;

    const taskStartingDateTime = taskOverview?.start_datetime
      ? new Date(taskOverview.start_datetime)
      : undefined;
    const taskCompletionDateTime = taskOverview?.end_datetime
      ? new Date(taskOverview.end_datetime)
      : undefined;
    const taskStartingTimeDisplay = taskStartingDateTime
      ? FormatDateToString(taskStartingDateTime, taskTimeFormat)
      : "NA";
    const taskCompletionTimeDisplay = taskCompletionDateTime
      ? FormatDateToString(taskCompletionDateTime, taskTimeFormat)
      : "NA";
    const taskCompletionDuration = 0;
    const targetLabelledConversationCount =
      taskOverview?.target_labelled_conversation_count;

    return (
      <div style={{ margin: "2em" }}>
        <h2>Task Detail</h2>

        {/*overview section*/}

        {statusMessage && (
          <MessageBar intent={statusMessage.status}>
            {statusMessage.message}
          </MessageBar>
        )}

        {hasPermission && (
          <div>
            <div style={{ display: "flex" }}>
              <h4 style={{ marginBottom: "1em" }}>Overview</h4>
              <Button
                icon={<Edit24Regular />}
                appearance="transparent"
                title="Edit"
                onClick={() => this.setState({ editMode: true })}
              ></Button>
            </div>
            <div
              style={{
                width: "auto",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 3fr",
                    gridAutoRows: "max(2.6rem, auto)",
                    justifyItems: "start",
                    gap: "10px",
                  }}
                >
                  <Label>Task Name</Label>
                  <div>{taskOverview?.task_name}</div>

                  <Label>Task Id</Label>
                  <div>{taskOverview?.task_id}</div>

                  <Label>Task Description</Label>
                  {editMode ? (
                    <Textarea
                      resize="vertical"
                      style={{ width: "18em" }}
                      defaultValue={taskOverview?.description}
                      onChange={(_e, newValue) => {
                        this.newTaskInfo.description = newValue.value || "";
                      }}
                    />
                  ) : (
                    <div>{taskOverview?.description}</div>
                  )}
                  {taskOverview?.task_type === TaskType.Distribution && (
                    <>
                      <Label>Desired Completion Date</Label>
                      {editMode ? (
                        <DatePicker
                          value={new Date(desiredCompletionDate)}
                          onSelectDate={(newDate) => {
                            if (newDate) {
                              const newDateString = FormatDateToString(
                                newDate,
                                taskDateFormat,
                              );
                              this.setState({
                                desiredCompletionDate: newDateString,
                              });
                            }
                          }}
                          placeholder="Select a date..."
                        />
                      ) : (
                        <div>{taskOverview?.desired_completion_date}</div>
                      )}
                      <Label>Status</Label>
                      <div>{taskOverview?.judge_status}</div>
                    </>
                  )}

                  <Label>Task Type</Label>
                  <div>{taskOverview?.task_type}</div>

                  <Label>Metric Type</Label>
                  <div>{taskOverview?.type}</div>

                  <Label>Created User</Label>
                  <div>{taskOverview?.created_user_display_name}</div>

                  <Label>Created Time</Label>
                  <div>{taskOverview?.created_datetime}</div>

                  <Label>Scraping enabled</Label>
                  <div>
                    {urlParametersMap && urlParametersMap["allowScraping"]}
                  </div>

                  {allowToCheckCompleteness(taskOverview?.type ?? "") && (
                    <>
                      <Label>Check completeness enabled</Label>
                      <div>
                        {urlParametersMap &&
                          urlParametersMap["checkCompleteness"]}
                      </div>
                    </>
                  )}

                  {taskOverview?.type === "SBSLeo" && (
                    <>
                      <Label>Randomize order enabled</Label>
                      <div>
                        {urlParametersMap && urlParametersMap["randomizeOrder"]}
                      </div>
                    </>
                  )}

                  {urlParametersMap &&
                    urlParametersMap["allowScraping"] === "true" && (
                      <>
                        <Label>Force input variants</Label>
                        <div style={{ display: "flex" }}>
                          <div>{urlParametersMap["forceInputVariants"]}</div>
                          {urlParametersMap["forceInputVariants"] ===
                            "false" && (
                            <Dialog modalType="modal">
                              <DialogTrigger>
                                <Button
                                  style={{ marginLeft: "0.5em" }}
                                  title="Show variants"
                                  size="small"
                                >
                                  Show detailed variants
                                </Button>
                              </DialogTrigger>
                              <DialogSurface>
                                <DialogBody>
                                  <DialogContent>
                                    <Label>
                                      {taskOverview?.type === "SBSLeo"
                                        ? "Control variants"
                                        : "Variants"}
                                    </Label>
                                    <Textarea
                                      style={{ width: "100%" }}
                                      disabled
                                      value={getVariantsByType(
                                        urlParametersMap["variants"],
                                        "Control",
                                      )}
                                    ></Textarea>
                                    {taskOverview?.type === "SBSLeo" && (
                                      <>
                                        <Label>Exp variants</Label>
                                        <Textarea
                                          style={{ width: "100%" }}
                                          disabled
                                          value={getVariantsByType(
                                            urlParametersMap["variants"],
                                            "Exp",
                                          )}
                                        ></Textarea>
                                      </>
                                    )}
                                  </DialogContent>
                                </DialogBody>
                              </DialogSurface>
                            </Dialog>
                          )}
                        </div>
                      </>
                    )}
                </div>
              </div>
              <div>
                {taskOverview?.task_type === TaskType.Distribution && (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2.5fr",
                      gridAutoRows: "max(2.6rem, auto)",
                      justifyItems: "start",
                      gap: "10px",
                    }}
                  >
                    <Label>Number of Conversations to be Labeled</Label>
                    {editMode ? (
                      <Textarea
                        defaultValue={
                          targetLabelledConversationCount?.toString() ?? ""
                        }
                        onChange={(_e, newValue) => {
                          this.newTaskInfo.targetConversationNumber =
                            newValue.value || "";
                        }}
                      />
                    ) : (
                      <div>{targetLabelledConversationCount}</div>
                    )}

                    <Label>Number of Judges per Conversation</Label>
                    <div>
                      {taskOverview?.target_label_count_per_conversation}
                    </div>
                  </div>
                )}

                <div style={{ marginBottom: "10px" }} />
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2.5fr",
                    gridAutoRows: "max(2.6rem, auto)",
                    justifyItems: "start",
                    gap: "10px",
                  }}
                >
                  <Label>Admin List</Label>
                  {editMode ? (
                    <Textarea
                      resize="vertical"
                      style={{ width: "30em" }}
                      defaultValue={taskOverview?.administrators}
                      placeholder="Input UPN split by comma. e.g. aliasA@microsoft.com,aliasB@microsoft.com"
                      onChange={(_e, newValue) => {
                        this.newTaskInfo.administrators = newValue.value || "";
                      }}
                    />
                  ) : (
                    <div style={{ maxWidth: "30em", wordWrap: "break-word" }}>
                      {taskOverview?.administrators}
                    </div>
                  )}

                  <Label>Allow all SEVAL users to label this task</Label>
                  {editMode ? (
                    <Checkbox
                      defaultChecked={!!taskOverview?.allow_anyone_to_label}
                      onChange={(ev, data) => {
                        this.newTaskInfo.allowAnyoneToLabel = !!data.checked;
                        if (data.checked) {
                          this.setState({ labelContributors: "" });
                        }
                        this.setState({
                          enableEditLabelContributor: !data.checked,
                        });
                      }}
                      label=""
                    />
                  ) : (
                    <div>{taskOverview?.allow_anyone_to_label?.toString()}</div>
                  )}

                  {!editMode &&
                    taskOverview &&
                    !taskOverview.allow_anyone_to_label && (
                      <>
                        <Label>Authorized Annotators</Label>
                        <div
                          style={{ maxWidth: "30em", wordWrap: "break-word" }}
                        >
                          {taskOverview?.label_contributors}
                        </div>
                      </>
                    )}

                  {editMode && enableEditLabelContributor && (
                    <>
                      <Label>Authorized Annotators</Label>
                      <Textarea
                        style={{ width: "30em" }}
                        resize="vertical"
                        value={labelContributors}
                        placeholder="Input UPN split by comma. e.g. aliasA@microsoft.com,aliasB@microsoft.com"
                        onChange={(_e, newValue) => {
                          this.setState({ labelContributors: newValue.value });
                        }}
                      ></Textarea>
                    </>
                  )}

                  <Label>Task Guideline</Label>
                  <Button
                    icon={<Copy24Regular />}
                    appearance="transparent"
                    title="Copy task guideline to clipboard"
                    onClick={() =>
                      navigator.clipboard.writeText(
                        taskOverview?.task_guideline ?? "",
                      )
                    }
                  ></Button>

                  <Label>Annotation URL</Label>
                  <Button
                    icon={<Copy24Regular />}
                    appearance="transparent"
                    title="Copy task annotation URL to clipboard"
                    onClick={() =>
                      LLMJudgeEvaluationTaskDashboardClass.copyTaskUrl(
                        taskOverview?.task_url ?? "",
                      )
                    }
                  ></Button>

                  <Label>Export Feedback in CSV (all)</Label>
                  <Button
                    icon={<ArrowDownload24Regular />}
                    appearance="transparent"
                    title="Export Feedback in CSV (all)"
                    onClick={() => this.downloadAllFeedback()}
                  ></Button>

                  <Label>Export Customized CSV</Label>
                  <Button
                    icon={<ArrowDownload24Regular />}
                    appearance="transparent"
                    title="Export Customized CSV"
                    onClick={() => this.downloadCustomizedFeedback()}
                  ></Button>

                  <Label>Download Annotation file (detail.csv)</Label>
                  <Button
                    icon={<ArrowDownload24Regular />}
                    appearance="transparent"
                    title="Download Annotation file (detail.csv)"
                    onClick={() => this.downloadAnnoationRawData()}
                  ></Button>
                </div>
              </div>
              {editMode && (
                <div style={{ display: "flex" }}>
                  <Dialog modalType="non-modal">
                    <DialogTrigger disableButtonEnhancement>
                      <Button
                        style={{ width: "6em", marginRight: "1em" }}
                        appearance="primary"
                      >
                        Save
                      </Button>
                    </DialogTrigger>
                    <DialogSurface>
                      <DialogBody>
                        <DialogTitle>
                          Are you sure to save the edit?
                        </DialogTitle>
                        <DialogContent>
                          The previous settings would be overwritten
                        </DialogContent>
                        <DialogActions>
                          <DialogTrigger disableButtonEnhancement>
                            <Button
                              appearance="primary"
                              onClick={() => this.onSaveEdit()}
                            >
                              Save
                            </Button>
                          </DialogTrigger>
                          <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                          </DialogTrigger>
                        </DialogActions>
                      </DialogBody>
                    </DialogSurface>
                  </Dialog>
                  <Button
                    style={{ width: "6em" }}
                    onClick={() => this.setState({ editMode: false })}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </div>

            <h4 style={{ marginTop: "3em" }}>Evaluation Status</h4>
            <div
              style={{
                width: "auto",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              <div>
                {taskOverview?.task_type === TaskType.Distribution && (
                  <>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2.5fr",
                        gridAutoRows: "max(2.6rem, auto)",
                        justifyItems: "start",
                        gap: "10px",
                      }}
                    >
                      <Label>Total Uploaded Conversations</Label>
                      <div>
                        {taskOverview?.total_uploaded_conversation_count}
                      </div>
                    </div>
                    <div style={{ marginBottom: "10px" }} />

                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2.5fr",
                        gridAutoRows: "max(2.6rem, auto)",
                        justifyItems: "start",
                        gap: "10px",
                      }}
                    >
                      <Label>
                        Required Number of Conversations to be Labeled
                      </Label>
                      <div>
                        {taskOverview?.target_labelled_conversation_count}
                      </div>
                    </div>

                    <div style={{ marginBottom: "10px" }} />

                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 2.5fr",
                        gridAutoRows: "max(2.6rem, auto)",
                        justifyItems: "start",
                        gap: "10px",
                      }}
                    >
                      <Label>
                        Number of Conversations Completed for Annotation
                      </Label>
                      <div>{taskOverview?.annotation_complete_number ?? 0}</div>

                      <Label>
                        Number of Conversations In Progress for Annotation
                      </Label>
                      <div>
                        {taskOverview?.annotation_in_progress_number ?? 0}
                      </div>

                      <Label>Task Starting Time</Label>
                      <div>{taskStartingTimeDisplay}</div>

                      <Label>Task Completion Time</Label>
                      <div>{taskCompletionTimeDisplay}</div>

                      <Label>Task Completion Duration</Label>
                      <div>{taskCompletionDuration}</div>
                    </div>
                  </>
                )}
                {taskOverview?.task_type === TaskType.Collection && (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 2.5fr",
                      gridAutoRows: "max(2.6rem, auto)",
                      justifyItems: "start",
                      gap: "10px",
                    }}
                  >
                    <Label>Number of Feedback</Label>
                    <div>{taskOverview?.total_feedback_number ?? 0}</div>

                    <Label>Number of Conversations which have feedback</Label>
                    <div>
                      {taskOverview?.conversation_with_feedback_number ?? 0}
                    </div>
                  </div>
                )}
                {taskOverview?.type === "SBSLeo" && (
                  <MetricsBoard
                    style={{ marginTop: "3em" }}
                    customizedData={customizedData}
                  />
                )}
              </div>
            </div>
            {taskJudgesToShow && (
              <div style={{ marginTop: "1em" }}>
                <DataGrid
                  items={taskJudgesToShow}
                  columns={this.columns}
                  getRowId={(judge) => judge.user_upn}
                >
                  <DataGridHeader>
                    <DataGridRow>
                      {({ renderHeaderCell }) => (
                        <DataGridHeaderCell>
                          {renderHeaderCell()}
                        </DataGridHeaderCell>
                      )}
                    </DataGridRow>
                  </DataGridHeader>
                  <DataGridBody<LLMJudgeEvaluationTaskJudges>>
                    {({ item, rowId }) => (
                      <DataGridRow<LLMJudgeEvaluationTaskJudges> key={rowId}>
                        {({ renderCell }) => (
                          <DataGridCell>{renderCell(item)}</DataGridCell>
                        )}
                      </DataGridRow>
                    )}
                  </DataGridBody>
                </DataGrid>
              </div>
            )}
          </div>
        )}
        {taskOverview && (
          <DeleteTaskDialog
            buttonProps={{
              children: "Delete Task",
              style: { backgroundColor: "red", color: "white" },
            }}
            onDelete={this.DeleteTask}
          ></DeleteTaskDialog>
        )}
        <div style={{ marginBottom: "2em" }} />
        <p>
          Report any feedback to{" "}
          <Link
            target="_blank"
            href="mailto:HumanCorrelationDev@microsoft.com"
            rel="noopener noreferrer"
          >
            HumanCorrelationDev@microsoft.com
          </Link>
        </p>
      </div>
    );
  }

  private updateParametersMap = () => {
    const urls = [
      window.location.search,
      this.taskOverview?.url_parameters ?? "",
    ];

    const scrapeEnabled = getUrlParameterValue(urls, "allowScraping", "false");
    const checkCompletenessEnabled = getUrlParameterValue(
      urls,
      "checkCompleteness",
      "false",
    );
    const randomOrderEnabled = getUrlParameterValue(
      urls,
      "randomizeOrder",
      "false",
    );
    const forceInputVariants = getUrlParameterValue(
      urls,
      "forceInputVariants",
      "false",
    );
    const variants = getUrlParameterValue(urls, "variants", "");

    this.setState({
      urlParametersMap: {
        allowScraping: scrapeEnabled,
        checkCompleteness: checkCompletenessEnabled,
        randomizeOrder: randomOrderEnabled,
        forceInputVariants: forceInputVariants,
        variants: variants,
      },
    });
  };

  private initPage = async () => {
    await this.loadDataAsync();

    this.updateParametersMap();

    // fetch customized data to calculate the metrics
    await this.fetchCustomizedData();
  };

  private fetchFeedback = async (feedbackType: FeedbackType) => {
    let data: any;
    const taskOverview = this.taskOverview;
    if (!taskOverview) return;
    const taskId = taskOverview.task_id;
    if (taskOverview.task_type === TaskType.Distribution) {
      // Download feedback for distribution task
      data = await fetchFeedbackFromSQL(taskId, true);
    } else {
      // Download feedback and raw data for collection task.
      // So the downloaded data from annotation page and task detail page will be the same.
      const allScraping =
        taskOverview.url_parameters?.includes("allowScraping=true");
      const rows = await fetchDataFromSQLForCollectionTask(
        taskId,
        taskOverview.type,
        allScraping,
        true,
      );
      if (!rows) {
        this.setState({
          statusMessage: {
            message: `No feedback data found!`,
            status: "error",
          },
        });
        return;
      }
      if (taskOverview.type === "SBSLeo") {
        // scraping rows don't need to be merged, it has been merged when saved to db
        if (!allScraping && isFromCsv(rows)) {
          data = mergeSbsLeoRow(rows);
        } else if (!allScraping) {
          const mergeDataList = mergeSbsLeoTsvRow(rows);
          data = mergeDataList[1];
        }
      }
      data = GetDownloadDataFromRows(data ?? rows);
    }

    // There're some post processing for the feedback data for different metric types. e.g. SbsLeo
    const metricDefinition = getDefinitionByName(taskOverview?.type ?? "");
    if (!metricDefinition) {
      this.setState({
        statusMessage: {
          message: `Not supported task type "${taskOverview?.type}"!`,
          status: "error",
        },
      });
      return;
    }

    if (feedbackType === "customized") {
      if (metricDefinition.getCustomizedExportData) {
        data = metricDefinition.getCustomizedExportData(data);
      } else {
        this.setState({
          statusMessage: {
            message: `Not supported customized export for task type "${taskOverview?.type}"!`,
            status: "error",
          },
        });
      }
    }
    for (const row of data) {
      // random_flip is for LLM label, it's useless now
      delete row.random_flip;
    }
    return data;
  };

  private downloadAllFeedback = async () => {
    this.setState({
      statusMessage: {
        message: `Downloading all feedback. It might take some time`,
        status: "info",
      },
    });

    try {
      const data = await this.fetchFeedback("all");
      // Unparse the data to csv and download it
      this.downloadFeedbackHelper(data, "all");
      this.setState({
        statusMessage: {
          message: `Download all feedback csv successfully!`,
          status: "success",
        },
      });
    } catch (e: any) {
      this.setState({
        statusMessage: {
          message: `Download all feedback failed! ${e.toString()}`,
          status: "error",
        },
      });
    }
  };

  private downloadCustomizedFeedback = async () => {
    this.setState({
      statusMessage: {
        message: `Downloading customized feedback. It might take some time`,
        status: "info",
      },
    });

    try {
      const customizedData: Record<string, string | number>[] =
        await this.fetchCustomizedData();
      this.downloadFeedbackHelper(customizedData, "customized");
      this.setState({
        statusMessage: {
          message: `Download customized feedback csv successfully!`,
          status: "success",
        },
      });
    } catch (e: any) {
      this.setState({
        statusMessage: {
          message: `Download customized feedback failed! ${e.toString()}`,
          status: "error",
        },
      });
    }
  };

  private downloadFeedbackHelper(data: any, feedbackType: FeedbackType) {
    const { taskOverview } = this.state;
    const taskName = taskOverview?.task_name;

    const simplifiedRows = data.map((dataRow: any) =>
      GetSimplifiedRow(dataRow),
    );
    const columnKeys = getColumnKeys(simplifiedRows);
    const csvString = Papa.unparse(simplifiedRows, { columns: columnKeys });
    const fileName = `feedback_${feedbackType}_${taskName}.csv`;
    const blob = new Blob([csvString], { type: "text/tab-separated-values" });
    downloadFile(blob, fileName);
  }

  private async downloadAnnoationRawData() {
    const { taskOverview } = this.state;
    const taskId = taskOverview?.task_id;
    if (!taskId) return;

    this.setState({
      statusMessage: {
        message: `Downloading conversation data file . It might take some time`,
        status: "info",
      },
    });

    try {
      const data = await GetAnnotationRawData(taskId);

      if (!data) {
        this.setState({
          statusMessage: {
            message: `No conversation data found!`,
            status: "error",
          },
        });
        return;
      }

      const fileName = `${taskOverview.type}_detail_${taskId}.csv`;

      const blob = new Blob([data], { type: "text/tab-separated-values" });
      downloadFile(blob, fileName);
      this.setState({
        statusMessage: {
          message: `Download conversation data file successfully!`,
          status: "success",
        },
      });
    } catch (e) {
      this.setState({
        statusMessage: {
          message: `Download conversation data file feedback failed! ${e}`,
          status: "error",
        },
      });
    }
  }

  // For update api. If the old and new value are the same, do not send it
  private getSendValue(oldValue?: string, newValue?: string) {
    return oldValue === newValue ? undefined : newValue;
  }

  private onSaveEdit = async () => {
    const { taskOverview, desiredCompletionDate, labelContributors } =
      this.state;
    const { taskId } = this.props;

    const isDistributionTask =
      taskOverview?.task_type === TaskType.Distribution;

    const sendDesiredConversationNumber = isDistributionTask
      ? this.getSendValue(
          taskOverview?.target_labelled_conversation_count?.toString(),
          this.newTaskInfo.targetConversationNumber,
        )
      : undefined;
    const sendDescription = this.getSendValue(
      taskOverview?.description,
      this.newTaskInfo.description,
    );
    const sendDesiredCompletionDate = isDistributionTask
      ? this.getSendValue(
          taskOverview?.desired_completion_date,
          desiredCompletionDate,
        )
      : undefined;

    this.newTaskInfo.administrators =
      this.newTaskInfo.administrators.replaceAll(" ", "");
    const sendAdministrators = this.getSendValue(
      taskOverview?.administrators,
      this.newTaskInfo.administrators,
    );

    const sendAllowAnyoneToLabel = this.getSendValue(
      taskOverview?.allow_anyone_to_label?.toString(),
      this.newTaskInfo.allowAnyoneToLabel?.toString(),
    );

    const trimmedLabelContributors = labelContributors?.replaceAll(" ", "");
    const sendLabelContributors = this.getSendValue(
      taskOverview?.label_contributors,
      trimmedLabelContributors,
    );

    if (
      (sendDesiredConversationNumber !== undefined &&
        !checkInputNumberValidity(
          this.newTaskInfo.targetConversationNumber,
          "Number of Conversations to be Labeled",
          true,
        )) ||
      (sendAdministrators !== undefined &&
        !checkInputUpnValidity(
          this.newTaskInfo.administrators,
          "Admin List",
        )) ||
      (sendLabelContributors !== undefined &&
        !checkInputUpnValidity(
          trimmedLabelContributors,
          "Authorized Annotators",
        ))
    )
      return;

    if (
      sendDescription !== undefined &&
      sendDesiredCompletionDate !== undefined &&
      sendDesiredConversationNumber !== undefined &&
      sendAdministrators !== undefined &&
      sendAllowAnyoneToLabel !== undefined &&
      sendLabelContributors !== undefined
    ) {
      this.setState({ editMode: false });
      return;
    }

    try {
      this.setState({
        statusMessage: { message: `Saving edit`, status: "info" },
      });
      const result = await UpdateEvaluationTask(
        taskId,
        sendDescription,
        sendDesiredCompletionDate,
        sendDesiredConversationNumber,
        sendAdministrators,
        sendAllowAnyoneToLabel,
        sendLabelContributors,
      );
      const taskJudges = (await GetTaskJudgesList(taskId))?.response;
      this.setState({ taskJudges: taskJudges, taskJudgesToShow: taskJudges });
      if (result) {
        this.setState({
          editMode: false,
          taskOverview: result,
          desiredCompletionDate: result.desired_completion_date,
          labelContributors: result.label_contributors,
          statusMessage: {
            message: `Edit saved!`,
            status: "success",
          },
        });
      }
    } catch (e: any) {
      alert(e.toString());
      this.setState({
        statusMessage: {
          message: `Fail to save edit! ${e.toString()}`,
          status: "error",
        },
      });
    }
  };

  private fetchCustomizedData = async (): Promise<
    Record<string, string | number>[]
  > => {
    const { customizedData } = this.state;
    if (customizedData) {
      return customizedData;
    }
    const data: Record<string, string | number>[] = await this.fetchFeedback(
      "customized",
    );
    this.setState({ customizedData: data });
    return data;
  };

  private loadDataAsync = async () => {
    const { taskId } = this.props;
    try {
      const taskOverview = await GetTaskHistory(taskId);
      if (!taskOverview || taskOverview.length === 0) {
        this.setState({
          statusMessage: {
            message: "The task is invalid or no longer exists.",
            status: "error",
          },
        });
        return;
      }
      const userUpn = store.account?.username;
      const hasPermission =
        !!userUpn &&
        (userUpn === taskOverview[0].created_user_upn ||
          taskOverview[0].administrators?.includes(userUpn));
      if (!hasPermission) {
        this.setState({
          hasPermission,
          statusMessage: {
            message: `You have no access to this task. Please contact the task owner ${taskOverview[0].created_user_upn}`,
            status: "error",
          },
        });
        return;
      }

      // Remove InProgress and Cancelled columns for collection task
      if (taskOverview[0].task_type === TaskType.Collection) {
        this.columns = this.columns.filter(
          (column) =>
            column.columnId !== "InProgress" && column.columnId !== "Cancelled",
        );
      }

      const taskJudges = (await GetTaskJudgesList(taskId))?.response;
      this.setState({
        taskOverview: taskOverview[0],
        taskJudges: taskJudges,
        taskJudgesToShow: taskJudges,
        desiredCompletionDate: taskOverview[0]?.desired_completion_date,
        labelContributors: taskOverview[0]?.label_contributors,
        enableEditLabelContributor: !taskOverview[0]?.allow_anyone_to_label,
        statusMessage: {
          message: "Successfully get task detail information.",
          status: "success",
        },
      });
      this.newTaskInfo = {
        description: taskOverview[0]?.description ?? "",
        targetConversationNumber:
          taskOverview[0]?.target_labelled_conversation_count?.toString() ?? "",
        allowAnyoneToLabel: !!taskOverview[0]?.allow_anyone_to_label,
        administrators: taskOverview[0]?.administrators ?? "",
      };
      this.taskOverview = taskOverview[0];
    } catch (e: any) {
      this.setState({
        statusMessage: {
          message: `Failed to get task detail. ${e.toString()}`,
          status: "error",
        },
      });
    }
  };

  private DeleteTask = async () => {
    const { taskOverview } = this.state;
    if (!taskOverview) return;
    this.setState({
      statusMessage: {
        message: `Deleting the task ${taskOverview?.task_id}. It might take some time`,
        status: "info",
      },
    });

    try {
      await DeleteEvaluationTaskById(taskOverview.task_id);

      telemetryHelper.logDiagnosticEvent("HumanCorrelationStudyDeleteTask", {
        message: `Deleted Human Correlation Study task. Task ID=${taskOverview.task_id}`,
        path: this.path,
      });
    } catch (e: any) {
      this.setState({
        statusMessage: {
          message: e.toString(),
          status: "error",
        },
      });
    }

    this.setState({
      statusMessage: {
        message: `Delete the task ${taskOverview.task_name} successfully.`,
        status: "success",
      },
    });
    setTimeout(() => {
      this.setState({ ...initialState, statusMessage: undefined });
      this.initPage();
    }, 3000);
  };
}

export const LLMJudgeEvaluationTaskDetail = () => {
  const taskId = getTaskId();

  return (
    <LLMJudgeEvaluationTaskDetailClass
      location={useLocation()}
      taskId={taskId ?? ""}
    />
  );
};
