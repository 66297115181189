import { makeStyles } from "@fluentui/react-components";

export const useStyles = makeStyles({
  "tooltip-visible-on-parent-hover": {
    "> .tooltipbutton": {
      opacity: 0,
    },
    ":hover > .tooltipbutton": {
      opacity: 1,
    },
  },
});
