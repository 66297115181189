import type { JobConfigurationUpdateAction } from "../../../models/JobConfigurationAction";
import { getValue } from "../../getJobPropV2";

export const checkNotEmpty = <T extends JobConfigurationUpdateAction["prop"]>(
  value: T,
  path: string,
) => {
  return getValue(value) === undefined
    ? {
        path,
        message: value + ": This field should not be empty!",
      }
    : undefined;
};
