import { Button } from "@fluentui/react-button";
import type { OptionOnSelectData } from "@fluentui/react-combobox";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Dropdown,
  Field,
  Input,
  Label,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  MessageBar,
  MessageBarBody,
  Option,
  Spinner,
  Switch,
  Textarea,
  Toast,
  ToastBody,
  ToastTitle,
  ToastTrigger,
  Toaster,
  mergeClasses,
  tokens,
  useId,
  useToastController,
} from "@fluentui/react-components";

import Papa from "papaparse";

import { Bottom } from "./sharedComponents/Bottom";

import type { Dispatch } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import type { MetricDefinition } from "./MetricDefinition";
import type {
  IBasicRow,
  IConversation,
  IRowExtendedProperties,
  NDCGRow,
} from "./bizChatEvalDataProvider";
import { type SbsLeoRow } from "./bizChatEvalDataProvider";

import {
  AddCircle24Regular,
  ArrowExport24Regular,
  ArrowExportRtl24Regular,
  BookInformation24Regular,
  CheckmarkCircle20Filled,
  ChevronDown24Regular,
  ChevronUp24Regular,
  Copy24Regular,
  Search24Regular,
  Share24Regular,
  TextBulletListSquareEdit20Regular,
} from "@fluentui/react-icons";
import { telemetryHelper } from "../../../helpers/telemetryHelper";
import { store } from "../../../store/store";
import { ShareTaskDashboard } from "./ShareTaskDashboard";
import type {
  ICompleteConversationResponse,
  IFeedback,
  IFeedbackResponse,
  LLMJudgeEvaluationTaskData,
  LLMJudgeEvaluationTaskStatus,
} from "./evaluationTaskDashboard/models/LLMJudgeEvaluationTaskData";
import {
  CancelConversation,
  CompleteConversation,
  GetAnnotationRawData,
  GetFeedback,
  GetFeedbackForSharedTask,
  GetNextConversation,
  GetValidFeedback,
  SaveFeedback,
} from "./evaluationTaskDashboard/sydneyEvalManagement";
import { TaskType } from "./models/TaskType";
import { useStyles } from "./styles";
import type { MessageBarType } from "./utils/MessageBarType";
import { getColumnKeys } from "./utils/RowParseUtils";
import { getUserAlias } from "./utils/utilities";
import SplitView from "./sharedComponents/SplitView";
import { RenderConversation } from "./groundleo/GroundLeoDefinition";
import MetricsBoard from "./metrics/MetricsBoard";
import { getHash } from "./utils/HashHelper";
import type { IKeyValue } from "./models/CommonModels";
import { QueryGenerator } from "./QueryGenerator";
import NDCGPage from "./ndcg/NDCGPage";

const filterableProperties: (keyof IBasicRow<any>)[] = ["exp_name"];
const forceFilteredProperties = ["exp_name"];

function getConversationIds() {
  const searchParams = new URLSearchParams(window.location.search);
  const conversationIds = searchParams.get("conversationId")?.split(",") || [];
  return conversationIds;
}

// If showAllConversations is true or no conversation id is provided, we will show all conversations
// Otherwise, we will only show the conversation with the given id by default
function getShowAllConversations() {
  const searchParams = new URLSearchParams(window.location.search);
  const showAllConversations =
    searchParams.get("showAllConversations")?.toLowerCase() === "true";
  return showAllConversations;
}

interface IContentPanelProps<TRow extends IRowExtendedProperties<TRow>> {
  metricDefinition: MetricDefinition<TRow>;
  taskMode?: boolean;
  task?: LLMJudgeEvaluationTaskData;
  taskStatistics?: LLMJudgeEvaluationTaskStatus;
  hasPermission?: boolean;
  allowUserQuery?: boolean; // When true, user can add query
  allowScraping?: boolean; // When true, user can call sydney to scrape conversation
}

export async function fetchFeedbackFromSQL(
  taskId: string,
  needComplete?: boolean,
) {
  let response;
  if (needComplete) {
    response = await GetFeedback(taskId, true);
  } else {
    response = await GetValidFeedback(taskId);
  }

  const feedbacksInOrder = response.sort(
    (a, b) =>
      new Date(a.feedback_time).getTime() - new Date(b.feedback_time).getTime(),
  );
  const rows = feedbacksInOrder.map((r) => {
    const row = Papa.parse<IBasicRow<any>>(r.feedback, { header: true })
      .data[0];
    row.feedback_id = r.id;
    return row;
  });
  return rows;
}

export async function getTemplateId(feedback: string) {
  const row = Papa.parse<IBasicRow<object>>(feedback, { header: true }).data[0];
  return await getHash(row.query);
}

export function parseFeedback(feedbackStr: string) {
  return Papa.parse<IBasicRow<object>>(feedbackStr, { header: true }).data[0];
}

export async function fetchDataFromSQLForCollectionTask(
  taskId: string,
  metricType: string,
  allowUserQuery?: boolean,
  fetchAllFeedback?: boolean,
) {
  const annotation_raw_data = await GetAnnotationRawData(taskId);
  if (!annotation_raw_data) {
    return;
  }

  const rows = Papa.parse<IBasicRow<object>>(annotation_raw_data, {
    header: true,
  }).data;

  // user's feedback is stored in row
  // Other user's feedback is stored in row.unchangableFeedbacks
  const userUpn = store.account?.username;
  const feedbacks = await GetFeedbackForSharedTask(
    taskId,
    allowUserQuery,
    fetchAllFeedback ? undefined : userUpn,
  );
  const uploadedQueryIdSet = new Set<string>();
  let feedbackRows: IBasicRow<object>[];
  // unique_key contains exp_name and ConversationId. It's used in cases where multiple rows have the same ConversationId.
  const useUniqueKey = metricType === "SBSLeo" || metricType === "SCLeo";
  if (useUniqueKey) {
    feedbackRows = feedbacks.map((feedback) =>
      parseFeedback(feedback.feedback),
    );
  }

  for (const index in rows) {
    let row = rows[index];

    const userFeedbackRow = feedbacks.find((feedback, feedbackIndex) => {
      let result = false;
      if (useUniqueKey && feedbackRows[feedbackIndex].unique_key) {
        const feedbackRow = feedbackRows[feedbackIndex];
        result =
          feedbackRow.unique_key === row.unique_key &&
          feedback.user_upn === userUpn;
      } else {
        result =
          feedback.raw_conversation_id === row.ConversationId &&
          feedback.user_upn === userUpn;
      }
      if (result) {
        uploadedQueryIdSet.add(feedback.id);
      }
      return result;
    }) as IFeedbackResponse;

    const otherFeedbackRows = feedbacks.filter((feedback, feedbackIndex) => {
      let result = false;
      if (useUniqueKey && feedbackRows[feedbackIndex].unique_key) {
        const feedbackRow = feedbackRows[feedbackIndex];
        result =
          feedbackRow.unique_key === row.unique_key &&
          feedback.user_upn !== userUpn;
      } else {
        result =
          feedback.raw_conversation_id === row.ConversationId &&
          feedback.user_upn !== userUpn;
      }
      if (result) {
        uploadedQueryIdSet.add(feedback.id);
      }
      return result;
    }) as IFeedbackResponse[];

    if (userFeedbackRow) {
      rows[index] = parseFeedback(userFeedbackRow.feedback);
      row = rows[index];
      row.feedback_id = userFeedbackRow.id;
    }

    row.unchangableFeedbacks = otherFeedbackRows.map((feedback) => {
      const feedbackRow = parseFeedback(feedback.feedback);
      feedbackRow.feedback_id = feedback.id;
      if (feedbackRow.ControlRowData) {
        feedbackRow.ControlRow = JSON.parse(
          feedbackRow.ControlRowData,
        ) as object;
      }
      return feedbackRow;
    });
  }

  // Add user added query
  if (allowUserQuery) {
    // The added query should use template id to match other feedbacks, so need to get template id first
    const feedbacksWithTemplateId = await Promise.all(
      feedbacks.map(async (feedback) => {
        if (!feedback.templateId) {
          feedback.templateId = await getTemplateId(feedback.feedback);
        }
        return feedback;
      }),
    );

    const addedTemplateIdSet = new Set<string>();
    for (const feedback of feedbacksWithTemplateId) {
      if (uploadedQueryIdSet.has(feedback.id)) {
        continue;
      }
      // Because not matched feedback are all from free query. If having added the same query, skip
      if (feedback.templateId) {
        if (addedTemplateIdSet.has(feedback.templateId)) {
          continue;
        }
        addedTemplateIdSet.add(feedback.templateId);
      }

      let feedbackRow = parseFeedback(feedback.feedback);
      // compatible with old feedback which doesn't have templateId
      if (!feedbackRow.templateId) {
        feedbackRow.templateId = await getHash(feedbackRow.query);
      }

      const currentUserFeedbackRow = feedbacksWithTemplateId.find((f) => {
        return (
          f.user_upn === userUpn &&
          f.templateId &&
          feedbackRow.templateId === f.templateId
        );
      });
      if (currentUserFeedbackRow === undefined) {
        feedbackRow = cleanMergedRow(feedbackRow);
        feedbackRow.submitted = false;
        // after clean the row, feedback_id is "", so need to delete it
        delete feedbackRow.feedback_id;
      } else {
        feedbackRow = parseFeedback(currentUserFeedbackRow.feedback);
        // generate the feedbackRow from feedback again, so need to set feedback_id
        feedbackRow.feedback_id = feedback.id;
      }
      if (!feedbackRow.templateId) {
        feedbackRow.templateId = feedback.templateId;
      }

      // need to find others' feedback for the same query template id
      const otherFeedbackRows = feedbacksWithTemplateId.filter((f) => {
        return (
          f.user_upn !== userUpn &&
          f.templateId &&
          feedbackRow.templateId === f.templateId
        );
      });
      feedbackRow.unchangableFeedbacks = otherFeedbackRows.map((f) => {
        const row = parseFeedback(f.feedback);
        row.feedback_id = f.id;
        if (row.ControlRowData) {
          row.ControlRow = JSON.parse(row.ControlRowData) as object;
        }
        return row;
      });
      rows.push(feedbackRow);
    }
  }
  return rows;
}

export function GetSimplifiedRow(row: IBasicRow<any>) {
  const simplifiedRow: any = { ...row };
  delete simplifiedRow.nextRow;
  delete simplifiedRow.lastRow;
  delete simplifiedRow.navLinkKey;
  delete simplifiedRow.setGroupExpandedTrue;
  delete simplifiedRow.task_conversation_id;
  delete simplifiedRow.unchangableFeedbacks;
  delete simplifiedRow.ControlRow;
  delete simplifiedRow.noLLMLabel;
  delete simplifiedRow.placeholderValues;
  delete simplifiedRow.scrapingPromise;
  return simplifiedRow;
}

// Collection task supports downloading data from other people. So we need to recover other people's feedback from unchangable feedbacks.
export function GetDownloadDataFromRows<TRow extends IBasicRow<TRow>>(
  rows: TRow[],
) {
  const feedbacks = [] as TRow[];
  for (const row of rows) {
    if (
      row.submitted ||
      !row.unchangableFeedbacks ||
      row.unchangableFeedbacks.length === 0
    ) {
      feedbacks.push(GetSimplifiedRow(row)); // if current user has labeled, or there are no other people's label, add this row (user's feedback or pure conversation) to downloaded data
    }
    for (const feedbackRow of row.unchangableFeedbacks || []) {
      feedbacks.push(GetSimplifiedRow(feedbackRow)); // Add other user's feedback
    }
  }
  return feedbacks;
}

// exp_name in sbsleo is like: Baseline([control experiment name]) or Experiment([treatment experiment name]: [control experiment name])
// get control experiment name from exp_name
export function getSBSControlExperimentalName(exp_name: string) {
  if (exp_name.startsWith("Baseline")) {
    return exp_name.split("(")[1].split(")")[0];
  } else {
    return exp_name.split(":")[1].split(")")[0];
  }
}

export function isFromCsv(rows: IBasicRow<object>[]): boolean {
  const fromCsv = rows.find(
    (row) =>
      row.unique_key !== undefined &&
      (row.exp_name.startsWith("Baseline(") ||
        row.exp_name.startsWith("Experiment(")), // if data is from uploaded csv, its exp_name will have such prefix, and it has non-undefined unique_key field
  );

  return fromCsv !== undefined;
}

export function mergeSbsLeoTsvRow(
  rows: IBasicRow<object>[],
): [IBasicRow<object>[], IBasicRow<object>[]] {
  // half of the rows are control rows, the other half are treatment rows
  const half = rows.length / 2;
  const controlRows = rows.slice(0, half);
  const treatmentRows = rows.slice(half);
  treatmentRows.forEach((row, index) => {
    row.ControlRow = controlRows[index];
    row.noLLMLabel = true;
  });

  return [controlRows, treatmentRows];
}

export function mergeSbsLeoRow(_rows: IBasicRow<object>[]) {
  _rows.sort((a, b) => {
    const startsWithBaselineA = !!a.exp_name?.startsWith("Baseline");
    const startsWithBaselineB = !!b.exp_name?.startsWith("Baseline");

    // If both are control rows, maintain their relative position
    if (
      (startsWithBaselineA && startsWithBaselineB) ||
      (!startsWithBaselineA && !startsWithBaselineB)
    ) {
      return 0;
    }
    // Put control rows in front
    else if (startsWithBaselineA && !startsWithBaselineB) {
      return -1;
    }
    // Put treatment rows in the back
    else {
      return 1;
    }
  });

  const mergedRows = [];
  const controlRowsMap = new Map<string, object>();
  for (let i = 0; i < _rows.length; i += 1) {
    const _row = _rows[i];
    if (_row.query === "") continue;
    const controlExpName = getSBSControlExperimentalName(_row.exp_name);
    const querySegmentKey = _row.query + _row.segment + controlExpName;
    if (!_row.unique_key || _row.unique_key === "") {
      controlRowsMap.set(querySegmentKey, _row);
    } else {
      if (_row.ControlRowData) {
        _row.ControlRow = JSON.parse(_row.ControlRowData) as object; // if the row already has ControlRowData, it means it is read from feedback which already has control row info
      }
      if (!_row.ControlRow) {
        _row.ControlRow = controlRowsMap.get(querySegmentKey);
        _row.ControlRowData = JSON.stringify(_row.ControlRow);
      }
      if (_row.ControlRow) mergedRows.push(_row); // put control row into treatment row as a field
    }
  }
  return mergedRows;
}

export function setUniqueKeyForRows<TRow>(rows: IBasicRow<TRow>[]) {
  rows.forEach((row) => {
    if (!row.unique_key) {
      row.unique_key = row.exp_name + "_" + row.ConversationId;
    }
  });
}

export function cleanRowProperties(
  row: IBasicRow<object> | object,
  keepColumns: Set<string>,
) {
  const allKeys = Object.keys(row);
  const jsonObj = row as unknown as IKeyValue;
  for (const key of allKeys) {
    if (keepColumns.has(key)) {
      continue;
    }
    jsonObj[key] = "";
  }
  return jsonObj as unknown as IBasicRow<object>;
}

export function cleanMergedRow(
  row: IBasicRow<object>,
  keepColumns?: Set<string>,
) {
  if (!keepColumns) {
    keepColumns = new Set([
      "query",
      "query_metadata",
      "segment",
      "filledQuery",
      "exp_name",
      "config",
      "ConversationId",
      "ControlRowData",
      "templateId",
    ]);
  }

  if (row.segment === undefined || row.segment.trim() === "") {
    row.segment = "default";
  }

  // need to clean control row as well, otherwise, will cause merge action later
  if (row.ControlRowData) {
    let controlRow = JSON.parse(row.ControlRowData) as IBasicRow<object>;
    controlRow = cleanRowProperties(controlRow, keepColumns);
    row.ControlRowData = JSON.stringify(controlRow);
  }

  return cleanRowProperties(row, keepColumns);
}

export async function formatScrapingRows(
  rows: IBasicRow<object>[],
  keepColumns?: Set<string>,
) {
  const notEmptyRows = rows.filter(
    (row) => row.query && row.query.trim() !== "",
  );

  const newRows = [];
  if (!keepColumns) {
    keepColumns = new Set([
      "query",
      "exp_name",
      "segment",
      "ConversationId",
      "ControlRow",
      "templateId",
      "unique_key",
    ]);
  }

  for (const row of notEmptyRows) {
    if (row.segment === undefined || row.segment.trim() === "") {
      row.segment = "default";
    }

    const queryTemplateId = await getHash(row.query);
    if (row.ControlRow) {
      // backward compatibility
      if (typeof row.ControlRow === "string") {
        row.ControlRow = JSON.parse(
          row.ControlRowData ?? "{}",
        ) as IBasicRow<object>;
      }
      row.ControlRow = {
        ...cleanRowProperties(row.ControlRow, keepColumns),
        ConversationId: queryTemplateId,
        templateId: queryTemplateId,
      };
    }
    const newRow: IBasicRow<object> = {
      ...row,
      exp_name: row.exp_name ?? "All", // exp_name is required, because total count is calculated based on exp_name
      ConversationId: queryTemplateId,
      templateId: queryTemplateId,
    };
    newRows.push(cleanRowProperties(newRow, keepColumns));
  }

  return newRows;
}

export function convertToMergedRows(rows: IBasicRow<object>[]) {
  const controlData = rows.map((row) => {
    return {
      query: row.query,
      query_metadata: "",
      segment: row.segment,
      exp_name: "control",
      config: "",
      human: "",
      turn: "",
      conversation: "",
      ConversationId: row.ConversationId,
      unique_key: "control_" + row.ConversationId,
      detail: "",
      random_flip: "",
      metric_key: "",
      axis: "",
      prompt_detail: "",
      response: "",
      engagement: "",
      prompt_engagement: "",
      clarity: "",
      prompt_clarity: "",
      "perceived intelligence": "",
      "prompt_perceived intelligence": "",
      relevance: "",
      prompt_relevance: "",
      sbsleo_score: "",
      reply_length: "",
      num_turns: "",
      num_searches: "",
      search_turns: "",
    } as IBasicRow<object>;
  });
  const expData = controlData.map((row) => {
    return {
      ...row,
      exp_name: "exp",
      unique_key: "exp_" + row.ConversationId,
    } as IBasicRow<object>;
  });
  const allData = [...controlData, ...expData];
  return mergeSbsLeoTsvRow(allData)[1];
}

export function formatNdcgRows(rows: IBasicRow<object>[]) {
  return rows.map((row) => {
    return {
      ...row,
      label_ndcg: undefined, // clear original feedback for the current row
    };
  });
}

export function findNewDisplayedRow<TRow>(
  rows: IBasicRow<TRow>[],
  currentRow: IBasicRow<TRow> | undefined,
): TRow | undefined {
  if (!currentRow) return undefined;
  return rows.find(
    (row) =>
      row.query === currentRow.query && row.segment === currentRow.segment,
  ) as TRow | undefined;
}

export function getCurrentConfig<TRow>(
  currentRow: IBasicRow<TRow> | undefined,
  currentVariants: string,
) {
  let config: Record<string, string>;
  try {
    config = JSON.parse(currentRow?.config ?? "{}") as Record<string, string>;
  } catch (e) {
    config = {};
  }

  if (!config["exp_name"]) {
    config["exp_name"] = currentRow?.exp_name ?? "";
  }
  config["variants"] = currentVariants;
  return config;
}

export function checkRequiredValues(
  token: string | undefined,
  allowScraping: boolean | undefined,
  forceInputVariants: boolean,
  metricDefinitionName: string,
  controlVariants: string,
  expVariants?: string,
  callAvalonAutomatically?: boolean,
) {
  if (allowScraping && !token && !callAvalonAutomatically) {
    alert("Please input token");
    return false;
  }
  if (allowScraping && forceInputVariants) {
    if (
      !controlVariants ||
      (metricDefinitionName === "SBSLeo" && !expVariants)
    ) {
      alert("Please input variants");
      return false;
    }
  }

  return true;
}

export function ContentPanel<TRow extends IBasicRow<TRow>>(
  props: IContentPanelProps<TRow>,
) {
  const [running, setRunning] = useState<boolean>(true);
  const [rows, setRows] = useState<TRow[]>(); // Current displayed rows after filtering
  const [allRows, setAllRows] = useState<TRow[]>(); // All rows
  const [currentRow, setCurrentRow] = useState<TRow>(); // Selected Row in the nav bar
  const [currentFeedbackRow, setCurrentFeedbackRow] = useState<
    TRow | undefined
  >({} as TRow); // Selected Row in the feedback panel
  const [selectedLabeler, setSelectedLabeler] = useState<string>("");
  const [showRawData, setShowRawData] = useState<boolean>(false);
  const [localUpload, setLocalUpload] = useState<boolean>(false);
  const [navSelectedKey, setNavSelectedKey] = useState<string>();
  const [showNav, setShowNav] = useState<boolean>(true);
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [metricRows, setMetricRows] = useState<any[]>();
  const [feedbackUpdateCount, setFeedbackUpdateCount] = useState<number>(0);
  const [searchBarFilter, setSearchBarFilter] = useState<string>("");
  const [searchBarFilterLc, setSearchBarFilterLc] = useState<string>("");
  const [searchBarFilteredRows, setSearchBarFilteredRows] = useState<any[]>();
  const [userCompleteNumber, setUserCompleteNumber] = useState<number>(
    props.taskStatistics?.user_complete_number ?? 0,
  );
  const [userAvailableNumber, setUserAvailableNumber] = useState<number>(
    props.taskStatistics?.user_available_number ?? 0,
  );
  const [totalCompleteNumber, setTotalCompleteNumber] = useState<number>(
    props.task?.annotation_complete_number ?? 0,
  );
  const [totalRequiredNumber, setTotalRequiredNumber] = useState<number>(
    props.task?.target_labelled_conversation_count ?? 0,
  );
  const [inProgressTaskConversationId, setInProgressTaskConversationId] =
    useState<string>();
  const [isTsv, setIsTsv] = useState<boolean | undefined>(undefined); // true: tsv file, false: csv file, undefined: not uploaded
  const [expandedClaims, setExpandedClaims] = useState<Set<string>>(new Set());
  const [debuggingMode, setDebuggingMode] = useState<boolean>(true);
  const [showVerifiableExternallyOption, setShowVerifiableExternallyOption] =
    useState<boolean>(false);
  const [needCheckCompleteness, setNeedCheckCompleteness] =
    useState<boolean>(false);
  const [token, setToken] = useState<string>("");
  const [controlVariants, setControlVariants] = useState<string>("");
  const [expVariants, setExpVariants] = useState<string>("");
  const [scrapingCount, setScrapingCount] = useState<number>(0);

  useEffect(() => {
    setUserCompleteNumber(props.taskStatistics?.user_complete_number ?? 0);
    setUserAvailableNumber(props.taskStatistics?.user_available_number ?? 0);
  }, [props.taskStatistics]);

  const rowToBeAdded = useRef<TRow>(); // Hold the new added row. Switch to this row after nav bar is updated.
  const rowToBeSwitchedTo = useRef<TRow>(); // Hold the row to be switched to. Switch to this row after nav bar is updated.
  const timer = useRef<NodeJS.Timeout>();
  const pendingSaveOperation = useRef<() => void>();
  const pendingSaveOperationName = useRef<string>(); // Save / Complete. Used to judge whether to check completeness
  const uploadedCsvString = useRef<string>();
  const currentRowRef = useRef<TRow>(); // Store the same value as currentRow state. Only ref can give the correct value when applying new filters.
  const userQuery = useRef<string>();
  const actionNameRef = useRef<string | undefined>(undefined);
  const [actionName, setActionName] = useState<string | undefined>(undefined);

  let searchBarFilterProperties: (keyof TRow)[] = ["query", "ConversationId"];
  searchBarFilterProperties = searchBarFilterProperties.concat(
    props.metricDefinition.humanLabelFieldNames || [],
  );

  const filters = useRef<Partial<Record<keyof TRow, string>>>({});
  // cache control and treatment rows for sbsleo with tsv files
  const controlRows = useRef<TRow[]>([]);
  const treatmentRows = useRef<TRow[]>([]);

  const styles = useStyles();

  const taskId = props.task?.task_id;

  const path = window.location.pathname;

  const isDistributionTask =
    props.taskMode && props.task?.task_type === TaskType.Distribution;

  const isCollectionTask =
    props.taskMode && props.task?.task_type === TaskType.Collection;

  const showLabelColumn =
    props.metricDefinition.renderLLMLabel ||
    props.metricDefinition.renderHumanLabel;

  const DefaultLabeler = getUserAlias();
  const forceInputVariants =
    getUrlParameterValue("forceInputVariants", "false") === "true";
  const callAvalonAutomatically =
    getUrlParameterValue("callAvalonAutomatically", "false") === "true";

  const toasterId = useId("SystemToaster");
  const { dispatchToast } = useToastController(toasterId);

  function updateActionName(name: string | undefined) {
    actionNameRef.current = name;
    setActionName(name);
  }

  function hasRequiredValues() {
    return checkRequiredValues(
      token,
      props.allowScraping,
      forceInputVariants,
      props.metricDefinition.name,
      controlVariants,
      expVariants,
      callAvalonAutomatically,
    );
  }

  function checkAndSetCurrentRow(row: TRow) {
    // if row has conversation in scraping mode, just show it. Otherwise, check if it has required values
    if ((props.allowScraping && row.conversation) || hasRequiredValues()) {
      setCurrentRow(row);
    }
  }

  function showSystemToast(message: string, intentType: MessageBarType) {
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          System Info
        </ToastTitle>
        <ToastBody>{message}</ToastBody>
      </Toast>,
      { position: "top-end", intent: intentType },
    );
  }

  function parseRowsToCustomizedData(data: TRow[]) {
    let simplifiedRows = GetDownloadDataFromRows(data);
    if (props.metricDefinition.getCustomizedExportData) {
      simplifiedRows = props.metricDefinition.getCustomizedExportData(
        simplifiedRows || [],
      ) as unknown as TRow[];
    }
    return simplifiedRows;
  }

  function generateExportFeedbackCSV() {
    if (!rows) return;
    const downloadFeedbackCSV = (data: TRow[], needCustomize?: boolean) => {
      // Log the event
      const message = `Conversation count: ${data.length}. Feedback count: ${
        data.filter((row) => row.submitted).length
      }.`;
      telemetryHelper.logDiagnosticEvent(
        "HumanCorrelationStudyDownloadFeedbackCSV",
        {
          message,
          path,
        },
      );

      // Download the CSV
      let simplifiedRows = GetDownloadDataFromRows(data);
      if (needCustomize && props.metricDefinition.getCustomizedExportData) {
        simplifiedRows = props.metricDefinition.getCustomizedExportData(
          simplifiedRows || [],
        );
      }
      if (!simplifiedRows || simplifiedRows.length === 0) return;

      const columnKeys = getColumnKeys(simplifiedRows);

      const csvString = Papa.unparse(simplifiedRows, { columns: columnKeys });
      const blob = new Blob([csvString], { type: "text/tab-separated-values" });
      const elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = `${getUserAlias()}_${
        props.metricDefinition.name
      }_with_feedback.csv`;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    };

    const downloadConversationTSV = (data: any[]) => {
      // Log the event
      const message = `Conversation count: ${data.length}.`;
      telemetryHelper.logDiagnosticEvent(
        "HumanCorrelationStudyConversationDownloadTSV",
        {
          message,
          path,
        },
      );

      // Download the TSV
      const conversationRows = data.map((row) => {
        const tsvRow = [
          row.query,
          row.segment,
          row.exp_name,
          row.config,
          row.human,
          1,
          row.conversation,
        ];
        if (row.ControlRow) {
          tsvRow.push(row.ControlRow.exp_name);
          tsvRow.push(row.ControlRow.config);
          tsvRow.push(row.ControlRow.human);
          tsvRow.push(row.ControlRow.conversation);
        }
        return tsvRow;
      });
      const tsvString = Papa.unparse(conversationRows, { delimiter: "\t" });
      const blob = new Blob([tsvString], { type: "text/tab-separated-values" });
      const elem = window.document.createElement("a");
      elem.href = window.URL.createObjectURL(blob);
      elem.download = `${getUserAlias()}_${
        props.metricDefinition.name
      }_conversation.tsv`;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    };

    return (
      <>
        <Menu>
          <MenuTrigger>
            <MenuButton>Download</MenuButton>
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              {rows.length !== (allRows?.length || 0) && (
                <MenuItem
                  onClick={() => {
                    downloadFeedbackCSV(rows);
                  }}
                >
                  Export Feedback in CSV (filtered only)
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  downloadFeedbackCSV(allRows || []);
                }}
              >
                Export Feedback in CSV (all)
              </MenuItem>
              {props.metricDefinition.getCustomizedExportData && (
                <MenuItem
                  onClick={() => {
                    downloadFeedbackCSV(allRows || [], true);
                  }}
                >
                  Export Customized CSV
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  downloadConversationTSV(allRows || []);
                }}
              >
                Export conversation.tsv
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </>
    );
  }

  const filtersElement = useMemo(() => generateFilters(), [allRows]);

  function initializeFromData(data: TRow[]) {
    data = data.filter((row) => row.exp_name); // trim empty rows
    data.forEach((row) => {
      row.completed = true;
    });
    if (props.metricDefinition.name === "SBSLeo") {
      let alreadyMerged = true;
      data.forEach((row) => {
        if (row.ControlRowData) {
          row.ControlRow = JSON.parse(row.ControlRowData) as TRow;
        } else {
          alreadyMerged = false;
        }
      });
      if (!alreadyMerged) {
        if (isFromCsv(data)) {
          data = mergeSbsLeoRow(data) as TRow[]; // merge rows to pairs for sbsleo
        } else {
          const [cRows, tRows] = mergeSbsLeoTsvRow(data);
          controlRows.current = cRows as TRow[];
          treatmentRows.current = tRows as TRow[];
          data = treatmentRows.current;
        }
      }
    }
    setAllRows(data); // set allRows first. When allRows is updated, generateFilters will be called and initial rows will be set
    setRunning(false);
  }

  function initializeFromControlAndTreatmentData() {
    const mergedRows = [];
    const controlRowsMap = new Map<string, TRow>(
      controlRows.current.map((row) => [row.query + row.segment, row]),
    );

    for (const row of treatmentRows.current) {
      const controlRow = controlRowsMap.get(row.query + row.segment);
      if (controlRow) {
        row.ControlRow = controlRow;
        mergedRows.push(row);
      }
    }

    // Transform merged rows to csv. Control rows are before treatment rows.
    const expandedRows = [
      ...mergedRows.map((row) => row.ControlRow as TRow),
      ...mergedRows,
    ];
    const expandedRowsSimpiified = expandedRows.map((row) =>
      GetSimplifiedRow(row),
    );
    uploadedCsvString.current = Papa.unparse(expandedRowsSimpiified, {
      header: true,
    });

    setAllRows(mergedRows);
    setRunning(false);
  }

  function processTsvString(
    tsvString: string,
    rowsRef: React.MutableRefObject<TRow[]>,
  ) {
    let data: TRow[] = [];
    const header =
      "query\tsegment\texp_name\tconfig\thuman\tturn\tconversation\n";

    data = Papa.parse(header + tsvString, {
      delimiter: "\t",
      header: true,
    }).data as TRow[];

    rowsRef.current = data;
    data.forEach((row) => {
      row.noLLMLabel = true;
      if (
        row.segment &&
        row.segment.startsWith("{") &&
        row.segment.endsWith("}")
      ) {
        try {
          const metadata = JSON.parse(row.segment) as { segment: string };
          row.segment = metadata.segment;
        } catch (e) {
          // Do nothing
        }
      }
      if (row.conversation) {
        const conversations = JSON.parse(row.conversation) as IConversation[];
        row.ConversationId = conversations[0].ConversationId;
      }
    });
    if (props.metricDefinition.name === "SBSLeo") {
      initializeFromControlAndTreatmentData();
    } else {
      if (props.metricDefinition.name === "SCLeo") {
        setUniqueKeyForRows(data);
      }
      uploadedCsvString.current = Papa.unparse(data, {
        header: true,
      });
      initializeFromData(data);
    }

    setIsTsv(true);
    setRunning(false);
    // Log the upload detail file event
    telemetryHelper.logDiagnosticEvent("HumanCorrelationStudyUploadTSV", {
      message: `Uploaded tsv file`,
      path,
    });
  }

  // apply search bar filters and dropdown filters
  function applyNewFilters() {
    const newRows = (allRows || []).filter((row) => {
      for (const property in filters.current) {
        if (
          filters.current[property] !== "All" &&
          row[property] !== filters.current[property]
        ) {
          return false;
        }
      }

      if (searchBarFilterLc) {
        for (const property of searchBarFilterProperties) {
          if (
            row[property]
              ?.toString()
              ?.toLowerCase()
              ?.includes(searchBarFilterLc)
          ) {
            return true;
          }
          for (const otherRows of row.unchangableFeedbacks || []) {
            if (
              otherRows[property]
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchBarFilterLc)
            ) {
              return true;
            }
          }
        }
      } else {
        return true;
      }
      return false;
    });

    if (searchBarFilterLc) {
      setSearchBarFilteredRows(newRows);
    } else {
      setSearchBarFilteredRows(undefined);
    }
    setRows(newRows);
    // Looking for new displayed row. Use currentRowRef to get the correct value
    setCurrentRow(findNewDisplayedRow(newRows, currentRowRef.current));
  }

  useEffect(() => {
    applyNewFilters();
  }, [searchBarFilterLc]);

  // This function is only called when allRows is updated
  function generateFilters() {
    const filterElements = [];
    const oldFilters = filters.current;
    filters.current = {};
    const onChange = (
      propertyName?: keyof IBasicRow<any>,
      option?: OptionOnSelectData,
    ) => {
      if (propertyName && option) {
        filters.current[propertyName] = option.optionValue as string;
      }
      applyNewFilters();
    };

    for (const property of filterableProperties) {
      const options = new Set<string>();

      // Generate filter options. If the property is not in forceFilteredProperties, add an "All" option
      if (!forceFilteredProperties.includes(property)) options.add("All");
      for (const row of allRows || []) {
        options.add(row[property]?.trim() || "");
      }

      const dropdownOptions = [...options].map((option) => {
        return {
          key: option,
          text: option,
        };
      });

      if (dropdownOptions.length === 1 || dropdownOptions.length === 0)
        continue; // Skip if there is only one option (i.e. all rows have the same value for this property

      // Set default filter to the first option
      let defaultFilter = oldFilters[property];
      if (
        !oldFilters[property] ||
        !options.has(oldFilters[property] as string)
      ) {
        defaultFilter = dropdownOptions[0].key;
      }
      filters.current[property] = defaultFilter;

      const ele = (
        <div>
          <Label className={styles.label} required>
            {property}
          </Label>
          <Dropdown
            key={property + "dropdowndefault" + defaultFilter}
            defaultValue={defaultFilter}
            onOptionSelect={(ev, option) => onChange(property, option)}
            style={{ width: "35em" }}
          >
            {dropdownOptions.map((option) => (
              <Option key={option.key}>{option.key}</Option>
            ))}
          </Dropdown>
        </div>
      );
      filterElements.push(ele);
    }

    onChange(); // Apply initial filters and trigger rerender

    return (
      <div>
        <div>{filterElements}</div>
      </div>
    );
  }

  function getSimplifiedRow(row: TRow) {
    const simplified = GetSimplifiedRow(row);
    return simplified as TRow;
  }

  function triggerNavGroupRerender() {
    setRows([...(rows ?? [])]);
  }

  function triggerConversationRerender(_expandedClaims: Set<string>) {
    setExpandedClaims(new Set(_expandedClaims));
  }

  // get the value of a parameter by following order: 1. url setting, 2. task url_parameter setting, 3. default value
  function getUrlParameterValue(parameterName: string, defaultValue: string) {
    const search = new URLSearchParams(window.location.search);
    const urlParameterValue = search.get(parameterName);
    const taskUrlParameters = new URLSearchParams(
      props.task?.url_parameters ?? "",
    );
    const taskUrlParameterValue = taskUrlParameters.get(parameterName);
    let parameterValue = defaultValue;
    if (urlParameterValue) {
      // get the value of this parameter from task url_parameters
      parameterValue = urlParameterValue;
    } else {
      if (taskUrlParameterValue) {
        parameterValue = taskUrlParameterValue;
      }
    }
    return parameterValue;
  }

  // set parameters that are decided by url/task url parameter settings
  function setAllUrlParameterValues() {
    // set debugging mode
    let defaultDebuggingMode = "true";
    if (
      props.metricDefinition.name === "GroundLeoClaimBreak" ||
      props.metricDefinition.name === "SBSLeo"
    ) {
      defaultDebuggingMode = "false";
    }
    const _debuggingMode = getUrlParameterValue(
      "debuggingMode",
      defaultDebuggingMode,
    );
    setDebuggingMode(_debuggingMode === "true");

    const _showVerifiableExternallyOption = getUrlParameterValue(
      "showVerifiableExternallyOption",
      "false",
    );
    setShowVerifiableExternallyOption(
      _showVerifiableExternallyOption === "true",
    );

    const _checkCompleteness = getUrlParameterValue(
      "checkCompleteness",
      "false",
    );
    setNeedCheckCompleteness(_checkCompleteness === "true");

    const _hideQueryPanel = getUrlParameterValue("hideQueryPanel", "false");
    setShowNav(_hideQueryPanel !== "true");

    const _variants = getUrlParameterValue("variants", "");
    if (props.metricDefinition.name !== "SBSLeo") {
      setControlVariants(_variants);
    } else {
      const variantsList = _variants.split("|");
      setControlVariants(variantsList[0] || "");
      setExpVariants(variantsList[1] || "");
    }
  }

  const addUserQueryRow = async () => {
    if (!token && !callAvalonAutomatically) {
      alert("Please input token");
      return;
    }
    const exp_name = filters.current["exp_name"] ?? allRows?.[0]?.exp_name;
    // for SBSLeo variants, expVariants is in config column, controlVariants is in ControlRowData column
    const variants =
      props.metricDefinition.name === "SBSLeo" ? expVariants : controlVariants;
    const exp_config = {
      ...(JSON.parse(allRows?.[0]?.config ?? "{}") as Record<string, string>),
      exp_name: exp_name,
      variants: variants,
    };

    const newRow = {
      query: userQuery.current,
      exp_name: exp_name,
      config: JSON.stringify(exp_config),
      segment: "default",
      noLLMLabel: true,
      templateId: await getHash(userQuery.current ?? ""),
    } as TRow;
    if (props.metricDefinition.name === "SBSLeo") {
      const controlExpName = rows?.[0]?.ControlRow?.exp_name || "control";
      const controlConfig = {
        ...(JSON.parse(rows?.[0]?.ControlRow?.config || "{}") as Record<
          string,
          string
        >),
        exp_name: controlExpName,
        variants: controlVariants,
      };
      newRow.ControlRow = {
        query: userQuery.current,
        exp_name: controlExpName,
        config: JSON.stringify(controlConfig),
        noLLMLabel: true,
      } as TRow;
      if (!exp_name) {
        newRow.exp_name = "treatment";
      }
    }

    // because we aggregate feedback by templateId, so we cannot add same queries
    const existingRow = findNewDisplayedRow(allRows ?? [], newRow);
    if (existingRow) {
      alert("This query already exists.");
      return;
    }

    addRow(newRow);
    setSearchBarFilterLc("");
    setSearchBarFilter("");
    userQuery.current = "";
  };

  useEffect(() => {
    // Log usage of the page
    telemetryHelper.logDiagnosticEvent("HumanCorrelationStudyVisitPage", {
      message: "Visited Human Correlation Study page.",
      path,
    });

    setAllUrlParameterValues();

    // Fetch data
    const fetchData = async () => {
      setWarningMessage("");
      let data: TRow[] = [];
      try {
        if (isDistributionTask) {
          data = (await fetchFeedbackFromSQL(taskId ?? "")) as TRow[];
        } else if (isCollectionTask) {
          data = (await fetchDataFromSQLForCollectionTask(
            taskId ?? "",
            props.metricDefinition.name,
            props.allowUserQuery,
          )) as TRow[];
        } else {
          data = (await props.metricDefinition.fetchData?.()) || [];
        }
      } catch (e: any) {
        if (!localUpload) {
          setWarningMessage("Failed to load data: " + e.message);
        }
        setRunning(false);
      }

      // If we are using local upload, we don't need the result from fetchData
      if (!localUpload && data) {
        const conversationIds = getConversationIds();
        const showAllConversations = getShowAllConversations();
        if (conversationIds.length > 0 && !showAllConversations) {
          data = data.filter((row) =>
            conversationIds.includes(row.ConversationId),
          );
        }
        initializeFromData(data);
      }
    };

    fetchData();

    // unmount
    return () => {
      timer.current && clearInterval(timer.current);
    };
  }, []);

  useEffect(() => {
    // If conversation ids are specified, set the current row to the first conversation
    const conversationIds = getConversationIds();
    if (conversationIds.length > 0) {
      setCurrentRow(
        rows?.find((row) => row.ConversationId === conversationIds[0]),
      );
      if (rows?.length === 1) {
        setShowNav(false);
      }
      return;
    }

    // Switch to newly added row
    if (rowToBeAdded.current) {
      if (rows?.find((row) => row === rowToBeAdded.current)) {
        setCurrentRow(rowToBeAdded.current);
        rowToBeAdded.current = undefined;
      }
    }
    // Switch to neareast row when deleting happens
    if (rowToBeSwitchedTo.current) {
      if (rows?.find((row) => row === rowToBeSwitchedTo.current)) {
        setCurrentRow(rowToBeSwitchedTo.current);
        rowToBeSwitchedTo.current = undefined;
      }
    }
    // first time open distribution mode. Swith to last row. Otherwise an empty page without any query will confuse users.
    if (
      rows &&
      rows.length > 0 &&
      !currentRow &&
      getUrlParameterValue("hideQueryPanel", "false") &&
      props.task?.task_type === TaskType.Distribution
    ) {
      setCurrentRow(rows[rows.length - 1]);
    }

    if (!rows || rows.length === 0) {
      setCurrentRow(undefined);
      return;
    }
    setExpandedClaims(new Set());
  }, [rows]);

  useEffect(() => {
    currentRowRef.current = currentRow;
    if (currentRow) {
      telemetryHelper.logDiagnosticEvent(
        "HumanCorrelationStudySwitchConversation",
        {
          message: "Switch conversation.",
          path,
        },
      );

      setNavSelectedKey(currentRow.navLinkKey);
      setFeedbackUpdateCount(0);

      // Default show current user's feedback. If not exist, show other's feedback
      if (currentRow.submitted) {
        setSelectedLabeler(DefaultLabeler);
        setCurrentFeedbackRow(currentRow);
      } else if (
        currentRow.unchangableFeedbacks &&
        currentRow.unchangableFeedbacks.length > 0
      ) {
        setSelectedLabeler(
          currentRow.unchangableFeedbacks[0].last_labelled_by || "",
        );
        setCurrentFeedbackRow(currentRow.unchangableFeedbacks[0]);
      } else setCurrentFeedbackRow(currentRow);
    }
    setExpandedClaims(new Set());
  }, [currentRow]);

  useEffect(() => {
    if (inProgressTaskConversationId) {
      const row = rows?.find(
        (r) => r.task_conversation_id === inProgressTaskConversationId,
      );
      setCurrentRow(row);
      if (
        currentRow &&
        currentRow.task_conversation_id === inProgressTaskConversationId
      ) {
        currentRow.completed = false;
      }
    }
  }, [inProgressTaskConversationId]);

  function generateShareLinks() {
    if (!currentRow) return;
    const url = new URL(window.location.href);
    const conversationId = currentRow.ConversationId;
    url.searchParams.set("conversationId", conversationId);
    url.searchParams.set("showAllConversations", "false");
    const shareLink_current = url.toString();
    url.searchParams.set("showAllConversations", "true");
    const shareLink_all = url.toString();
    return (
      <div>
        <Label className={styles.label}>Share link (all)</Label>
        It will automaticlly navigate to current conversation opening the shared
        link. Other conversations will be available.
        <div>
          <div>
            <Field>
              <Textarea value={shareLink_all} readOnly />
            </Field>
          </div>
          <Button
            icon={<Copy24Regular />}
            onClick={() => navigator.clipboard.writeText(shareLink_all)}
          />
        </div>
        <Label className={styles.label}>Share link (current)</Label>
        Only current conversation will be shared.
        <div>
          <div>
            <Field>
              <Textarea value={shareLink_current} readOnly />
            </Field>
          </div>
          <Button
            icon={<Copy24Regular />}
            onClick={() => navigator.clipboard.writeText(shareLink_current)}
          />
        </div>
      </div>
    );
  }

  const shareConversationPanel = (
    <div>
      {!localUpload && <div>{generateShareLinks()}</div>}
      {localUpload && (
        <div>
          <p>
            Click the button below to download the conversation as a csv file.
            Ask other people to upload it via this page.
          </p>
          <Button
            appearance="primary"
            onClick={() => {
              if (currentRow === undefined) {
                return;
              }
              const simplifiedRows = [getSimplifiedRow(currentRow)];
              const csvString = Papa.unparse(simplifiedRows);
              const blob = new Blob([csvString], {
                type: "text/tab-separated-values",
              });
              const elem = window.document.createElement("a");
              elem.href = window.URL.createObjectURL(blob);
              elem.download = `${props.metricDefinition.name}_${currentRow["ConversationId"]}_with_feedback.csv`;
              document.body.appendChild(elem);
              elem.click();
              document.body.removeChild(elem);
            }}
          >
            Download Conversation
          </Button>
        </div>
      )}
    </div>
  );

  function addRow(newRow?: TRow) {
    if (!newRow) return;
    newRow.segment = "default";
    newRow.exp_name = newRow.exp_name ?? "demo";
    filters.current["exp_name"] = newRow.exp_name;
    const newAllRows = [...(allRows || []), newRow];
    rowToBeAdded.current = newRow;
    setAllRows(newAllRows);
  }

  function addNextConversation(response?: ICompleteConversationResponse) {
    if (!response) return;
    if (props.allowScraping && !token && !callAvalonAutomatically) {
      alert("Please input token");
      return;
    }
    if (props.allowScraping && forceInputVariants) {
      if (
        !controlVariants ||
        (props.metricDefinition.name === "SBSLeo" && !expVariants)
      ) {
        alert("Please input variants");
        return;
      }
    }
    let hasSameId = false;
    if (response.next_conversation?.id) {
      if (allRows) {
        for (const row of allRows) {
          if (
            row.ConversationId === response.next_conversation?.conversation_id
          ) {
            hasSameId = true;
            row.task_conversation_id = response.next_conversation.id;
            row.completed = false;
            setInProgressTaskConversationId(row.task_conversation_id);
            break;
          }
        }
      }
      if (hasSameId) return; // if the conversation row already exists in current displayed rows, do not add it again

      const rows = Papa.parse<TRow>(
        response.next_conversation?.annotation_raw_data.replace(
          "\r\n\n",
          "\r\n",
        ),
        { header: true },
      ).data;
      const newRow = rows[0];
      if (props.metricDefinition.name === "SBSLeo") {
        newRow.ControlRow = JSON.parse(newRow?.ControlRowData ?? "{}") as TRow;
      }
      newRow.task_conversation_id = response.next_conversation.id;
      addRow(newRow);
    }
  }

  async function fetchNextConversation() {
    if (!taskId) return;
    if (actionNameRef.current) return;
    updateActionName("New");
    const response = await GetNextConversation(taskId);
    addNextConversation(response);
    updateActionName(undefined);
  }

  function getSQLFeedback() {
    if (!currentRow || !props.taskMode) return;
    const feedback: IFeedback = {
      ConversationId: currentRow.task_conversation_id,
      TaskId: taskId,
      Type: props.metricDefinition.name,
      Feedback: Papa.unparse([getSimplifiedRow(currentRow)]),
      FeedbackTime: new Date().toISOString(),
      FeedbackId: currentRow.feedback_id,
      RawConversationId: currentRow.ConversationId,
    };
    return feedback;
  }

  async function saveCurrentRowToSQL() {
    if (!currentRow || !props.taskMode) return;
    const sqlFeedback = getSQLFeedback();
    if (!sqlFeedback) {
      showSystemToast("Feedback is empty", "warning");
      return;
    }
    if (actionNameRef.current) return;
    updateActionName("Save");
    const response = await SaveFeedback([sqlFeedback]);
    currentRow.feedback_id = response[0].id;
    showSystemToast("Feedback saved", "success");
    setSelectedLabeler(DefaultLabeler);
    updateActionName(undefined);
  }

  async function completeConversation(need_next: boolean, actionName: string) {
    if (!currentRow || !isDistributionTask) return;
    if (!currentRow.task_conversation_id) return;
    if (actionNameRef.current) return;
    updateActionName(actionName);
    const response = await CompleteConversation(
      currentRow.task_conversation_id,
      need_next,
      currentRow.feedback_id,
      getSQLFeedback(),
    );
    currentRow.task_conversation_id = undefined; // flag this row as completed
    currentRow.feedback_id = response.feedback_id;
    currentRow.completed = true;
    setUserCompleteNumber(response.user_complete_number);
    setUserAvailableNumber(response.user_available_number);
    setTotalCompleteNumber(response.total_complete_number);
    setTotalRequiredNumber(response.total_required_number);
    setInProgressTaskConversationId(undefined);
    showSystemToast("Conversation completed and feedback saved", "success");
    updateActionName(undefined);
    return response;
  }

  async function ExecuteWithErrorHandeling(operation: () => void) {
    try {
      await operation();
    } catch (e: any) {
      showSystemToast(e.toString(), "error");
      updateActionName(undefined);
    }
  }

  function getLabeledByOptions() {
    if (!currentRow) return [];

    if (currentRow.submitted) {
      // put current user's feedback at the first
      return [<Option key={DefaultLabeler}>{DefaultLabeler}</Option>].concat(
        currentRow.unchangableFeedbacks?.map((row) => (
          <Option key={row.feedback_id}>{row.last_labelled_by || ""}</Option>
        )) || [],
      );
    } else
      return currentRow.unchangableFeedbacks
        ?.map((row) => (
          <Option key={row.feedback_id}>{row.last_labelled_by || ""}</Option>
        ))
        .concat(<Option key={"default"}>{`${DefaultLabeler}`}</Option>);
  }

  function checkCompleteness() {
    let passedCheck = true;
    if (
      needCheckCompleteness &&
      props.metricDefinition.checkCompleteness &&
      currentRow
    ) {
      const checkMissingItems =
        props.metricDefinition.checkCompleteness(currentRow);
      if (checkMissingItems) {
        passedCheck = false;
        alert(
          `Please complete labeling the following items:\n${checkMissingItems}`,
        );
      }
    }
    return passedCheck;
  }

  // Why we should really save feedback after feedbackUpdateCount change?
  // feedbackUpdateCount change => props.saveFeedbackTrigger change for <props.metricDefinition.renderHumanLabel>
  // => feedback really updated in row
  // We can only save feedback after all above steps are done, or we save wrong feedback
  //
  // Should we remove save button for non-task mode? Then we can remove this saveFeedbackTrigger to make logic clearer.
  // But if we remove the button, the status in nav bar will be wrong if user accidentally change the feedback.
  useEffect(() => {
    if (pendingSaveOperation.current) {
      if (pendingSaveOperationName.current === "Complete") {
        // only check completeness when clicked "Complete and New" or "Complete"
        const passedCheckCompleteness = checkCompleteness();
        if (!passedCheckCompleteness) return;
      }
      ExecuteWithErrorHandeling(pendingSaveOperation.current);
      pendingSaveOperation.current = undefined;
      pendingSaveOperationName.current = undefined;
    }
  }, [feedbackUpdateCount]);

  const cancelCurrentConversation = async (needNext: boolean) => {
    try {
      const response = await CancelConversation(
        currentRow?.task_conversation_id ?? "",
        needNext,
      );
      setInProgressTaskConversationId(undefined);
      setCurrentRow(allRows?.[allRows.length - 2]);
      allRows?.pop();
      setAllRows([...(allRows ?? [])]);
      if (currentRow !== undefined) {
        currentRow.task_conversation_id = undefined;
      }
      if (needNext) {
        addNextConversation(response);
      }
      showSystemToast("Conversation canceled", "success");
    } catch (e: any) {
      showSystemToast(e.toString(), "error");
    }
  };

  const FeedbackAndTaskButtons = (
    <div
      className={styles.stackHorizontal}
      style={{ flexWrap: "wrap", zIndex: 100 }}
    >
      <Button
        icon={actionName === "Save" ? <Spinner size="extra-tiny" /> : undefined}
        style={{ marginTop: ".5em" }}
        onClick={() => {
          setFeedbackUpdateCount((prev) => prev + 1);
          pendingSaveOperation.current = async () => {
            props.taskMode && (await saveCurrentRowToSQL());
          };
          pendingSaveOperationName.current = "Save";
        }}
        disabled={currentRow !== currentFeedbackRow}
      >
        Save
      </Button>

      {isDistributionTask && (
        <>
          {
            <Button
              icon={
                actionName === "CompleteAndNew" ? (
                  <Spinner size="extra-tiny" />
                ) : undefined
              }
              className="completeAndNewButton"
              disabled={currentRow?.task_conversation_id === undefined}
              onClick={() => {
                pendingSaveOperation.current = async () => {
                  const response = await completeConversation(
                    true,
                    "CompleteAndNew",
                  );
                  addNextConversation(response);
                };
                pendingSaveOperationName.current = "Complete";
                setFeedbackUpdateCount((prev) => prev + 1); // local save
              }}
              appearance="primary"
            >
              Complete And New
            </Button>
          }

          {
            <Button
              icon={
                actionName === "Complete" ? (
                  <Spinner size="extra-tiny" />
                ) : undefined
              }
              disabled={currentRow?.task_conversation_id === undefined}
              onClick={() => {
                pendingSaveOperation.current = async () => {
                  await completeConversation(false, "Complete");
                };
                pendingSaveOperationName.current = "Complete";
                setFeedbackUpdateCount((prev) => prev + 1);
              }}
              appearance="primary"
            >
              Complete
            </Button>
          }

          {
            <Button
              icon={
                actionName === "Cancel" ? (
                  <Spinner size="extra-tiny" />
                ) : undefined
              }
              disabled={currentRow?.task_conversation_id === undefined}
              onClick={async () => {
                if (actionNameRef.current) return;
                updateActionName("Cancel");
                await cancelCurrentConversation(false);
                updateActionName(undefined);
              }}
            >
              Cancel
            </Button>
          }

          {
            <Button
              icon={
                actionName === "CancelAndNew" ? (
                  <Spinner size="extra-tiny" />
                ) : undefined
              }
              disabled={currentRow?.task_conversation_id === undefined}
              onClick={async () => {
                if (actionNameRef.current) return;
                updateActionName("CancelAndNew");
                await cancelCurrentConversation(true);
                updateActionName(undefined);
              }}
            >
              Cancel And New
            </Button>
          }

          {
            <Button
              icon={
                actionName === "New" ? <Spinner size="extra-tiny" /> : undefined
              }
              disabled={currentRow?.task_conversation_id !== undefined}
              onClick={fetchNextConversation}
              appearance="primary"
            >
              New
            </Button>
          }
        </>
      )}
    </div>
  );

  const renderLLMLabel = () => {
    const hideLlmLabel =
      getUrlParameterValue("hideLlmLabel", "false") === "true";
    if (
      currentRow &&
      !currentRow.noLLMLabel &&
      props.metricDefinition.renderLLMLabel &&
      !hideLlmLabel
    ) {
      return (
        <div>
          <h3>LLM label</h3>
          {props.metricDefinition.renderLLMLabel(currentRow)}
        </div>
      );
    } else return <></>;
  };

  const renderHumanLabel = () => {
    if (props.metricDefinition.renderHumanLabel) {
      return (
        <div>
          <div
            className={mergeClasses(
              styles.stackHorizontal,
              styles.stackHorizontalWithGap,
            )}
          >
            {props.metricDefinition.guidelineLink && (
              <Button
                icon={<BookInformation24Regular />}
                appearance="transparent"
                href={props.metricDefinition.guidelineLink}
                target="_blank"
              >
                <Link
                  href={props.metricDefinition.guidelineLink}
                  target="_blank"
                >
                  guideline
                </Link>
              </Button>
            )}
          </div>
          {currentFeedbackRow && (
            <props.metricDefinition.renderHumanLabel
              row={currentFeedbackRow}
              triggerNavRerender={triggerNavGroupRerender}
              saveFeedbackTrigger={feedbackUpdateCount}
              triggerConversationRerender={triggerConversationRerender}
              expandedClaims={expandedClaims}
              disabled={currentRow !== currentFeedbackRow}
              showVerifiableExternallyOption={showVerifiableExternallyOption}
            ></props.metricDefinition.renderHumanLabel>
          )}
          {FeedbackAndTaskButtons}
        </div>
      );
    } else return <></>;
  };

  const renderConversation = (
    row: TRow | undefined,
    renderShareLink = true,
    variants?: string,
  ) => {
    if (row && currentRow) {
      const _simplifiedRow = getSimplifiedRow(row);
      return (
        <div>
          <div className={styles.stackHorizontal}>
            {renderShareLink && (
              <Dialog>
                <DialogTrigger>
                  <Button
                    title="Share conversation"
                    icon={<Share24Regular />}
                    appearance="transparent"
                  ></Button>
                </DialogTrigger>
                <DialogSurface>
                  <DialogBody>
                    <DialogTitle>
                      Share the conversation with other people
                    </DialogTitle>
                    <DialogContent>{shareConversationPanel}</DialogContent>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
            )}
          </div>
          <RenderConversation
            metricName={props.metricDefinition.name}
            row={row}
            debuggingMode={debuggingMode}
            expandedClaims={expandedClaims}
            allowScraping={props.allowScraping}
            token={token}
            variants={variants || controlVariants}
            triggerScraping={scrapingCount}
          />
          <div>
            <div
              style={{
                display: "flex",
                whiteSpace: "pre-wrap",
              }}
            >
              <Label className={styles.label}>Raw data</Label>
              <Button
                onClick={() => {
                  setShowRawData(!showRawData);
                }}
                icon={
                  showRawData ? (
                    <ChevronUp24Regular />
                  ) : (
                    <ChevronDown24Regular />
                  )
                }
                appearance="transparent"
              ></Button>
            </div>
            {showRawData && _simplifiedRow && (
              <props.metricDefinition.renderRawData row={_simplifiedRow} />
            )}
          </div>
        </div>
      );
    } else return <></>;
  };

  const renderConversationForSBSLeo = () => {
    if (!currentFeedbackRow) {
      return <></>;
    }
    let leftRow = currentFeedbackRow?.ControlRow;
    let rightRow: TRow | undefined = currentFeedbackRow;
    let leftVariants = controlVariants;
    let rightVariants = expVariants;

    const sbsRow = currentRow as unknown as SbsLeoRow;
    // If the row doesn't have feedback, then randomize the order if the url parameter is set
    // if sbsRow.flip already exists, then don't change it.
    if (
      !sbsRow.submitted &&
      getUrlParameterValue("randomizeOrder", "false") === "true" &&
      !sbsRow.flip
    ) {
      sbsRow.flip = Math.floor(Math.random() * 2).toString();
    }
    if (sbsRow.flip === "1") {
      leftRow = currentFeedbackRow;
      leftVariants = expVariants;
      rightRow = currentFeedbackRow?.ControlRow;
      rightVariants = controlVariants;
    }
    return (
      <>
        <div
          className={mergeClasses(
            styles.stackHorizontal,
            styles.stackHorizontalWithGap,
          )}
          style={{ overflowX: "hidden" }}
        >
          <div
            style={{
              width: "50%",
              paddingLeft: "2px",
            }}
          >
            <div style={{ overflowX: "auto" }}>
              {renderConversation(leftRow, false, leftVariants)}
            </div>
          </div>
          <div
            style={{
              width: "50%",
              paddingLeft: "2px",
            }}
          >
            <div style={{ overflowX: "auto" }}>
              {renderConversation(rightRow, false, rightVariants)}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderConversationAndFeedback = (metricDefinitionName: string) => {
    if (currentRow === undefined) {
      return <></>;
    }
    if (
      metricDefinitionName === "SBSLeo" ||
      metricDefinitionName === "Conversation"
    ) {
      return (
        <SplitView
          left={
            <div style={{ overflowY: "auto", height: "100%" }}>
              {props.allowScraping && currentRow === currentFeedbackRow && (
                <QueryGenerator
                  row={currentRow}
                  metricDefinition={props.metricDefinition}
                  triggerScraping={() => {
                    if (hasRequiredValues()) {
                      if (forceInputVariants) {
                        // variants may be changed in each query
                        const expConfig = getCurrentConfig(
                          currentRow,
                          expVariants,
                        );
                        currentRow.config = JSON.stringify(expConfig);
                        if (currentRow?.ControlRow) {
                          const controlConfig = getCurrentConfig(
                            currentRow.ControlRow,
                            controlVariants,
                          );
                          currentRow.ControlRow.config =
                            JSON.stringify(controlConfig);
                        }
                      }
                      setScrapingCount((prev) => prev + 1);
                    }
                  }}
                ></QueryGenerator>
              )}
              <hr />
              {props.metricDefinition.name === "SBSLeo"
                ? renderConversationForSBSLeo()
                : renderConversation(currentFeedbackRow)}
            </div>
          }
          leftStyle={
            props.metricDefinition.name === "SBSLeo"
              ? { overflow: "hidden" } // SBSLeo has two columns. Handle overflow foreach the column in renderConversationForSBSLeo
              : {}
          }
          right={
            <div>
              {renderLLMLabel()}
              {renderHumanLabel()}
            </div>
          }
          orientation="vertical"
          height={"80vh"}
        ></SplitView>
      );
    } else if (metricDefinitionName !== "NDCG") {
      return (
        <div style={{ flexGrow: 1, paddingLeft: "1em", overflow: "auto" }}>
          <div></div>
          {/*Need an empty element here to make sure it always grow even there's no conversation */}
          {(currentRow.conversation || props.allowScraping) && (
            <div
              className={mergeClasses(
                styles.stackHorizontal,
                styles.stackHorizontalWithGap,
              )}
              style={{ width: "100%", height: "100%" }}
            >
              <div
                style={{
                  width: showLabelColumn ? "50%" : "100%",
                  height: "100%",
                  overflow: showLabelColumn ? "auto" : "visible",
                }}
              >
                {props.allowScraping && currentRow === currentFeedbackRow && (
                  <QueryGenerator
                    row={currentRow}
                    metricDefinition={props.metricDefinition}
                    triggerScraping={() => {
                      if (hasRequiredValues()) {
                        if (forceInputVariants) {
                          const config = getCurrentConfig(
                            currentRow,
                            controlVariants,
                          );
                          currentRow.config = JSON.stringify(config);
                        }
                        setScrapingCount((prev) => prev + 1);
                      }
                    }}
                  ></QueryGenerator>
                )}
                {renderConversation(currentFeedbackRow)}
              </div>
              <div
                hidden={!showLabelColumn}
                style={{
                  width: "50%",
                  height: "100%",
                  overflow: "auto",
                }}
              >
                <div
                  className={mergeClasses(
                    styles.stackVertical,
                    styles.stackVerticalWithGap,
                  )}
                >
                  {renderLLMLabel()}
                  {renderHumanLabel()}
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <>
          <NDCGPage
            key={currentRow.filledQuery || currentRow.query}
            debuggingMode={debuggingMode}
            expandedClaims={expandedClaims}
            token={token}
            allowScraping={props.allowScraping}
            forceInputVariants={forceInputVariants}
            metricDefinition={
              props.metricDefinition as unknown as MetricDefinition<NDCGRow>
            }
            task={props.task}
            currentRow={currentRow as NDCGRow}
            controlVariants={controlVariants}
            saveFeedbackTrigger={feedbackUpdateCount}
          />
          {FeedbackAndTaskButtons}
        </>
      );
    }
  };

  return (
    <div>
      <div
        className={mergeClasses(
          styles.stackHorizontal,
          styles.stackHorizontalWithGap,
        )}
        style={{ flexWrap: "wrap" }}
      >
        <h2>{props.metricDefinition.name}</h2>
        {!props.taskMode && !props.allowScraping && (
          <>
            <Button
              title="Free query mode"
              appearance="transparent"
              icon={<TextBulletListSquareEdit20Regular />}
              onClick={() => {
                // open a new tab to /llmjudge/bizchat/{props.metricDefinition.name}
                window.open(
                  `/llmjudge/bizchat/${props.metricDefinition.url}`,
                  "_blank",
                );
              }}
            ></Button>
            <div>
              <Label className={styles.label} required>
                {props.metricDefinition.name === "SBSLeo"
                  ? "Choose sbsleo_details.csv or conversation.tsv for baseline(control)"
                  : `Choose ${props.metricDefinition.name.toLowerCase()}_details.csv or conversations.tsv`}
              </Label>
              {props.metricDefinition.allowLocalUpload && (
                <input
                  className="detailCsvUpload"
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = (ev) => {
                        setLocalUpload(true);
                        let data: TRow[] = [];

                        if (file.name.endsWith(".tsv")) {
                          const tsvString = ev.target?.result as string;
                          processTsvString(tsvString, controlRows);
                        } else {
                          const csvString = ev.target?.result as string;
                          data = Papa.parse(csvString, { header: true })
                            .data as TRow[];
                          if (props.metricDefinition.name === "SCLeo") {
                            setUniqueKeyForRows(data);
                          }
                          uploadedCsvString.current = Papa.unparse(data, {
                            header: true,
                          });
                          setIsTsv(false);
                          initializeFromData(data);
                          if (data.length === 1) {
                            setCurrentRow(data[0]);
                            setShowNav(false);
                          }
                          // Log the upload detail file event
                          telemetryHelper.logDiagnosticEvent(
                            "HumanCorrelationStudyUploadCSV",
                            {
                              message: `Uploaded csv file`,
                              path,
                            },
                          );
                        }

                        setRunning(false);
                      };
                      reader.readAsText(file);
                    }
                  }}
                ></input>
              )}
            </div>
            {props.metricDefinition.name === "SBSLeo" &&
              isTsv &&
              !props.allowScraping && (
                <div>
                  <Label className={styles.label} required>
                    Choose conversations.tsv for treatment
                  </Label>
                  {props.metricDefinition.allowLocalUpload && (
                    <input
                      id="treatmentTsvUpload"
                      type="file"
                      onChange={(e) => {
                        const file = e.target.files?.[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.onload = (ev) => {
                            const tsvString = ev.target?.result as string;
                            processTsvString(tsvString, treatmentRows);
                          };

                          reader.readAsText(file);
                        }
                      }}
                    ></input>
                  )}
                </div>
              )}
          </>
        )}
        {!props.taskMode && isTsv === false && (
          <div>
            <Label className={styles.label}>
              {`(Optional) Choose csv like ${props.metricDefinition.name.toLocaleLowerCase()}_metrics.csv`}
            </Label>
            {props.metricDefinition.allowLocalUpload && (
              <input
                type="file"
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    const reader = new FileReader();
                    reader.onload = (error) => {
                      const csvString = error.target?.result as string;
                      const rows = Papa.parse<TRow>(csvString, {
                        header: true,
                      }).data;
                      setMetricRows(rows);
                    };
                    reader.readAsText(file);
                  }
                }}
              ></input>
            )}
          </div>
        )}
        {!props.taskMode && !props.allowUserQuery && (
          <Dialog modalType="alert">
            <DialogTrigger>
              <Button
                title="Share conversation"
                icon={<Share24Regular />}
                appearance="transparent"
                style={{ height: "50px" }}
              >
                Share All
              </Button>
            </DialogTrigger>
            <DialogSurface>
              <DialogBody>
                <DialogTitle action={null}>
                  Share all conversations with other people
                </DialogTitle>
                <DialogContent>
                  <ShareTaskDashboard
                    metricType={props.metricDefinition.name}
                    csvString={uploadedCsvString.current || ""}
                    feedbackRows={allRows?.filter((row) => row.submitted) || []}
                  ></ShareTaskDashboard>
                </DialogContent>
                <DialogActions>
                  <DialogTrigger disableButtonEnhancement>
                    <Button>Close</Button>
                  </DialogTrigger>
                </DialogActions>
              </DialogBody>
            </DialogSurface>
          </Dialog>
        )}
        {props.taskMode && (
          <div>
            <div style={{ margin: ".5em" }}>
              Task name: {props.task?.task_name}
            </div>
            {isDistributionTask && (
              <>
                <div style={{ margin: ".5em" }}>
                  Task status: {totalCompleteNumber} / {totalRequiredNumber}
                </div>
                <div style={{ margin: ".5em" }}>
                  You have completed {userCompleteNumber} conversations. You can
                  access {userAvailableNumber} conversations.
                </div>
              </>
            )}
          </div>
        )}
        <Switch
          label={"Debugging mode"}
          checked={debuggingMode}
          onChange={(e, data) => {
            setDebuggingMode(data.checked);
          }}
        ></Switch>
        {props.metricDefinition.name === "GroundLeoClaimBreak" && (
          <Switch
            label={"Show 'verifiable using external search' label option"}
            checked={showVerifiableExternallyOption}
            onChange={(e, data) => {
              setShowVerifiableExternallyOption(data.checked);
            }}
          ></Switch>
        )}
        {(!props.taskMode || props.hasPermission) && (
          <div style={{ flexGrow: "1" }}>
            <div
              className={styles.stackHorizontal}
              style={{ justifyContent: "end", gap: "1em" }}
            >
              <a
                href="https://msasg.visualstudio.com/Cortana/_wiki/wikis/M365%20Chat.wiki/185519/Human-Correlation-Study"
                target="_blank"
                rel="noopener noreferrer"
              >
                User Manual
              </a>
              {props.allowScraping && (
                <>
                  {!callAvalonAutomatically && (
                    <Dialog modalType="modal">
                      <DialogTrigger>
                        <Button title="Upload sydney token">Set token</Button>
                      </DialogTrigger>
                      <DialogSurface>
                        <DialogBody>
                          <DialogTitle action={null}>
                            Your sydney token
                          </DialogTitle>
                          <DialogContent>
                            <Textarea
                              style={{ width: "100%" }}
                              placeholder="Paste your sydney token here"
                              onChange={(e, data) => {
                                setToken(data.value);
                              }}
                              value={token}
                            ></Textarea>
                          </DialogContent>
                        </DialogBody>
                      </DialogSurface>
                    </Dialog>
                  )}
                  {(!props.taskMode || forceInputVariants) && (
                    <Dialog modalType="modal">
                      <DialogTrigger>
                        <Button title="Set variants">Set variants</Button>
                      </DialogTrigger>
                      <DialogSurface>
                        <DialogBody>
                          <DialogTitle action={null}>Set variants</DialogTitle>
                          <DialogContent>
                            <Label>
                              {props.metricDefinition.name === "SBSLeo"
                                ? "Left variants" // we don't want to show "Control variants" and "Experiment variants" to cause bias, so use left and right instead
                                : "Variants"}
                            </Label>
                            <Textarea
                              style={{ width: "100%" }}
                              placeholder="input variants separated by comma. Example: feature.responseTokenLimit700,feature.PreserveOriginalReferences"
                              onChange={(e, data) => {
                                setControlVariants(data.value || "");
                              }}
                              value={controlVariants}
                            ></Textarea>
                            {props.metricDefinition.name === "SBSLeo" && (
                              <>
                                <Label>Right variants</Label>
                                <Textarea
                                  style={{ width: "100%" }}
                                  placeholder="input variants separated by comma. Example: feature.responseTokenLimit700,feature.PreserveOriginalReferences"
                                  onChange={(e, data) => {
                                    setExpVariants(data.value || "");
                                  }}
                                  value={expVariants}
                                ></Textarea>
                              </>
                            )}
                          </DialogContent>
                        </DialogBody>
                      </DialogSurface>
                    </Dialog>
                  )}
                </>
              )}
              {generateExportFeedbackCSV()}
              {props.task?.type === "SBSLeo" && isCollectionTask && (
                <>
                  <Dialog modalType="modal">
                    <DialogTrigger>
                      <Button title="Show metrics">Show metrics</Button>
                    </DialogTrigger>
                    <DialogSurface>
                      <DialogBody>
                        <DialogContent style={{ minWidth: "800px" }}>
                          <MetricsBoard
                            style={{ width: "100%" }}
                            customizedData={
                              parseRowsToCustomizedData(
                                allRows || [],
                              ) as unknown as Record<string, string | number>[]
                            }
                          />
                        </DialogContent>
                      </DialogBody>
                    </DialogSurface>
                  </Dialog>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {rows && rows.length > 0 && props.metricDefinition.overallGuideline && (
        <div
          style={{
            marginTop: "2em",
            color: tokens.colorNeutralForeground2BrandHover,
          }}
        >
          {props.metricDefinition.overallGuideline}
        </div>
      )}
      <div
        className={mergeClasses(
          styles.stackHorizontal,
          styles.stackHorizontalWithGap,
        )}
      >
        {((allRows && allRows.length > 0) || props.allowUserQuery) &&
          showNav && (
            <div
              style={{
                marginTop: "25px",
                height: "min-content",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Input
                style={{ width: props.allowUserQuery ? "13vw" : "18vw" }}
                placeholder={"search by query/id/label"}
                onChange={(e, data) => {
                  userQuery.current = data.value;
                  setSearchBarFilterLc(data.value.toLowerCase().trim());
                  setSearchBarFilter(data.value);
                }}
                value={searchBarFilter}
                contentBefore={<Search24Regular />}
              ></Input>
              {props.allowUserQuery && (
                <Dialog modalType="modal">
                  <DialogTrigger>
                    <Button
                      title="Add"
                      className="AddQueryButton"
                      icon={<AddCircle24Regular />}
                      style={{ marginLeft: "0.5em" }}
                    >
                      Add query
                    </Button>
                  </DialogTrigger>
                  <DialogSurface>
                    <DialogBody>
                      <DialogTitle>Your query</DialogTitle>
                      <DialogContent>
                        <Textarea
                          className={"AddQueryTextArea"}
                          style={{ width: "100%" }}
                          placeholder="Input your customized query"
                          onChange={(e, data) => {
                            userQuery.current = data.value;
                          }}
                        ></Textarea>
                      </DialogContent>
                      <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                          <Button appearance="secondary">Close</Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                          <Button
                            className={"AddQueryConfirmButton"}
                            appearance="primary"
                            onClick={() => {
                              if (!userQuery.current) {
                                alert("Query cannot be empty!");
                                return;
                              }
                              if (hasRequiredValues()) {
                                addUserQueryRow();
                              }
                            }}
                          >
                            Query
                          </Button>
                        </DialogTrigger>
                      </DialogActions>
                    </DialogBody>
                  </DialogSurface>
                </Dialog>
              )}
            </div>
          )}
        {filtersElement}
      </div>
      {running && (
        <div>
          Loading evaluation data <Spinner></Spinner>
        </div>
      )}
      {warningMessage && (
        <MessageBar intent={"error"}>
          <MessageBarBody>{warningMessage}</MessageBarBody>
        </MessageBar>
      )}
      <div className={styles.stackHorizontal} style={{ height: "90vh" }}>
        <div style={{ height: "100%" }}>
          <div className={styles.stackVertical} style={{ height: "100%" }}>
            {!running &&
              isDistributionTask &&
              (props.hasPermission ? (
                (!allRows || allRows.length === 0) && (
                  <Button onClick={fetchNextConversation}>Start Task</Button>
                )
              ) : (
                <div>
                  <div>
                    You do not have access to this task. Please contact task
                    admins:
                  </div>
                  <div>
                    {props.task?.created_user_upn +
                      "," +
                      props.task?.administrators}
                  </div>
                </div>
              ))}
            <div style={{ flexGrow: 1, overflowY: "auto" }}>
              <div hidden={!showNav} style={{ width: "20vw" }}>
                {rows && (
                  <props.metricDefinition.nav
                    data={rows}
                    expandedData={searchBarFilteredRows}
                    metrics={metricRows}
                    setCurrentRow={
                      checkAndSetCurrentRow as Dispatch<IBasicRow<any>>
                    }
                    selectedKey={navSelectedKey}
                  ></props.metricDefinition.nav>
                )}
              </div>
            </div>
            <div
              className={styles.stackVertical}
              style={{ alignContent: "end" }}
            >
              <hr style={{ width: "100%" }} />
              <div style={{ flexGrow: 1 }}>
                <div
                  className={styles.stackHorizontal}
                  style={{ justifyContent: "end" }}
                >
                  {showNav && (
                    <>
                      <CheckmarkCircle20Filled className={styles.treeIconOwn} />
                      current user
                      <CheckmarkCircle20Filled className={styles.treeIcon} />
                      other users
                      <Button
                        icon={<ArrowExportRtl24Regular />}
                        appearance="transparent"
                        title="Hide query list"
                        onClick={() => setShowNav(false)}
                      ></Button>
                    </>
                  )}
                  {!showNav && (
                    <Button
                      icon={<ArrowExport24Regular />}
                      appearance="transparent"
                      title="Show query list"
                      onClick={() => setShowNav(true)}
                    ></Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            flexGrow: 1,
            flexShrink: 100, // When horinzonal space is not enough, shrink this column
            minWidth: 0,
            height: "100%",
          }}
        >
          {currentRow && (
            <div className={styles.stackVertical} style={{ height: "100%" }}>
              {renderConversationAndFeedback(props.metricDefinition.name)}

              <div style={{ flexGrow: 1 }}>
                <div
                  className={mergeClasses(
                    styles.stackHorizontal,
                    styles.stackHorizontalWithGap,
                  )}
                  style={{ padding: ".5em", justifyContent: "end" }}
                >
                  {((currentRow.unchangableFeedbacks &&
                    currentRow.unchangableFeedbacks.length > 0) ||
                    currentRow.submitted) && (
                    <>
                      <Label className={styles.label}>Labeled by</Label>
                      <Dropdown
                        selectedOptions={[selectedLabeler]}
                        value={selectedLabeler}
                        onOptionSelect={(e, data) => {
                          setSelectedLabeler(data.optionText || "");
                          if (data.optionText === DefaultLabeler) {
                            setCurrentFeedbackRow(currentRow);
                            return;
                          }
                          const row = currentRow.unchangableFeedbacks?.find(
                            (_row) =>
                              _row.last_labelled_by === data.optionValue,
                          );
                          setCurrentFeedbackRow(row);
                        }}
                      >
                        {getLabeledByOptions()}
                      </Dropdown>
                    </>
                  )}

                  <Button
                    disabled={!currentRow.lastRow}
                    onClick={() =>
                      currentRow.lastRow && setCurrentRow(currentRow.lastRow)
                    }
                  >
                    Previous
                  </Button>
                  <Button
                    disabled={!currentRow.nextRow}
                    onClick={() =>
                      currentRow.nextRow && setCurrentRow(currentRow.nextRow)
                    }
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Bottom emailAddress={"HumanCorrelationDev@microsoft.com"}></Bottom>
      <div></div>
      <Toaster toasterId={toasterId} />
    </div>
  );
}
