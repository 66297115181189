import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Input,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import type { FeatureSettingsRefreshTokenDialogContributionProps } from "../../../../contribution/FeatureSettingsContribution";
import { telemetryHelper } from "../../../../helpers/telemetryHelper";
import { uploadToken } from "../../actions/uploadToken";

export const UploadRefreshTokenDialog = observer(
  (props: FeatureSettingsRefreshTokenDialogContributionProps) => {
    const { tokenName, tokenType, dialogTitle, loggingSource, guidanceLink } =
      props;

    const [tokenValue, setTokenValue] = React.useState<string>("");
    const toast = useToast();

    const handleUploadToken = () => {
      toast.onToastStart("Uploading token...");

      telemetryHelper.logUserActionEvent("SetToken", {
        inputType: "Refreshtoken",
        source: loggingSource,
      });

      uploadToken(
        tokenType,
        tokenName,
        tokenValue,
        () => {
          telemetryHelper.logUserActionEvent("SetTokenSuccess", {
            inputType: "Refreshtoken",
            source: loggingSource,
          });

          toast.onToastSuccess("Token uploaded successfully");
          props.close();
        },
        (error) => {
          telemetryHelper.logUserActionEvent("SetTokenFailure", {
            inputType: "Refreshtoken",
            message: error.message,
            source: loggingSource,
          });

          toast.onToastFailure(
            `Token upload failed with message: ${error.message}`,
          );
          props.close();
        },
      );
    };

    useEffect(() => {
      if (props.isOpen) {
        setTokenValue("");
      }
    }, [props.isOpen]);

    const guidanceElement = guidanceLink && (
      <>
        <br />
        <Body1>{" For the set up guidance, please check "}</Body1>
        {guidanceLink}
        <Body1>{"."}</Body1>
      </>
    );

    return (
      <Dialog
        open={props.isOpen}
        onOpenChange={() => {
          props.close();
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
              <Field
                required
                label={
                  <>
                    <Body1>{`You can input your token here.`}</Body1>
                    <br />
                    <Body1>{`This token will be stored as secret with name `}</Body1>
                    <strong>{`${tokenName}`}</strong>
                    <Body1>{` in Azure Key Vault.`}</Body1>
                    {guidanceElement}
                  </>
                }
                validationState={tokenValue.trim() === "" ? "error" : "success"}
                validationMessage={
                  tokenValue.trim() === ""
                    ? "token value can not be empty"
                    : undefined
                }
              >
                <Input
                  style={{ width: "100%" }}
                  size="medium"
                  value={tokenValue}
                  onChange={(_, data) => {
                    setTokenValue(data.value);
                  }}
                />
              </Field>
            </DialogContent>
            <DialogActions>
              <Button
                disabled={tokenValue.trim() === ""}
                onClick={() => {
                  handleUploadToken();
                }}
                appearance="primary"
              >
                Confirm
              </Button>
              <Button
                onClick={() => {
                  props.close();
                }}
                appearance="secondary"
              >
                Close
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);
