"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseJsonStrToObject = exports.parseJsonStrWithDefault = exports.parseJsonStrOptional = exports.parseJsonStr = void 0;
const typeHelper_1 = require("../tools/typeHelper");
const parseJsonStr = (jsonString, TypeGuard, trace = "parseJsonString") => {
    return TypeGuard(JSON.parse(jsonString), trace);
};
exports.parseJsonStr = parseJsonStr;
const parseJsonStrOptional = (jsonString, TypeGuard) => {
    if (jsonString === undefined) {
        return undefined;
    }
    try {
        return (0, exports.parseJsonStr)(jsonString, TypeGuard, "parseJsonStringOptional");
    }
    catch {
        return undefined;
    }
};
exports.parseJsonStrOptional = parseJsonStrOptional;
const parseJsonStrWithDefault = (jsonString, TypeGuard, defaultValue) => {
    if (jsonString === undefined) {
        return defaultValue;
    }
    try {
        return (0, exports.parseJsonStr)(jsonString, TypeGuard, "parseJsonStrWithDefault");
    }
    catch {
        return defaultValue;
    }
};
exports.parseJsonStrWithDefault = parseJsonStrWithDefault;
const parseJsonStrToObject = (jsonString) => {
    return (0, exports.parseJsonStrOptional)(jsonString, typeHelper_1.anyObject);
};
exports.parseJsonStrToObject = parseJsonStrToObject;
