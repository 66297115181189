import { computed } from "mobx";
import { getJSONSchemaError } from "./getJSONSchemaError";

export const getConfigPathErrorsBySchema = computed(() => {
  const schemaErrorMessage = getJSONSchemaError.get();

  if (schemaErrorMessage === undefined) {
    return [];
  }

  const errorMessages = schemaErrorMessage
    .split("[Error]")
    .filter((_) => _ !== "")
    .map((_) => _.split("[Message]"))
    .filter((_) => _.length === 2)
    .map((_) => ({
      path: _[0],
      message: _[1],
    }));

  return errorMessages;
});
