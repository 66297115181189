import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import {
  onCancelClick,
  onDeleteProductClick,
} from "../../actions/productSettingsActions";
import { productSettingsStore } from "../../store/productSettingsStore";

export const ProductDeleteDialog = observer(() => {
  const product = (() => {
    if (productSettingsStore.scenario.scenarioType !== "Listing") {
      return undefined;
    }
    return productSettingsStore.scenario.isDeleting;
  })();

  const [productName, setProductName] = useState("");

  useEffect(() => {
    if (product !== undefined) {
      setProductName(product.displayName);
    }
  }, [product]);

  return (
    <Dialog open={product !== undefined} onOpenChange={onCancelClick}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{"Confirm Deletion?"}</DialogTitle>
          <DialogContent>
            {`Are you sure to delete product ${productName}?`}
          </DialogContent>
          <DialogActions>
            <Button
              appearance="primary"
              onClick={
                product && onDeleteProductClick.bind(null, product, true)
              }
            >
              Yes, delete
            </Button>
            <Button appearance="secondary" onClick={onCancelClick}>
              Cancel
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
});
