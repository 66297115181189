"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobPermissionInfo = exports.JobPermissionSideEffectInfo = exports.JobPermissionUIInfo = exports.JobPermissionUserInfo = exports.JobPermissionBasicInfo = void 0;
const tools_1 = require("../tools");
exports.JobPermissionBasicInfo = (0, tools_1.object)({
    userTid: tools_1.str,
    userOid: tools_1.str,
    jobId: tools_1.num,
    jobGuid: tools_1.str,
    jobStatus: tools_1.str,
    isOwner: tools_1.bool,
    isScrapingOwner: tools_1.bool,
    isDevImmunity: tools_1.bool,
    isSharableJob: tools_1.bool,
    isTestTenantJob: tools_1.bool,
    isBingJob: tools_1.bool,
    isCompletedBingJob: tools_1.bool,
    hasMetrics: tools_1.bool,
    environmentId: (0, tools_1.optional)(tools_1.num),
});
// TODO: those info is only related to the job itself, and not related to the current user,
// in the long term we can split this into multi processors.
exports.JobPermissionUserInfo = (0, tools_1.object)({
    hasReadPermissionByOwner: tools_1.bool,
    hasReadPermissionByBing: tools_1.bool,
    hasReadPermissionByNotSharable: tools_1.bool,
    hasReadPermissionByTestTenant: tools_1.bool,
    hasReadPermissionByDevImmunity: tools_1.bool,
    hasReadPermissionByNonShare: tools_1.bool,
    hasReadPermissionByShare: tools_1.bool,
    hasReadPermission: tools_1.bool,
    hasSharePermission: tools_1.bool,
    hasRerunPermission: tools_1.bool,
    hasCancelPermission: tools_1.bool,
    hasBingDownloadPermission: tools_1.bool,
    hasGeneralDownloadPermission: tools_1.bool,
});
exports.JobPermissionUIInfo = (0, tools_1.object)({
    shouldShowBingDownloadButton: tools_1.bool,
    shouldShowGeneralDownloadButton: tools_1.bool,
});
exports.JobPermissionSideEffectInfo = (0, tools_1.object)({
    shouldLogDevImmunityEvent: tools_1.bool,
});
exports.JobPermissionInfo = (0, tools_1.object)({
    userTid: tools_1.str,
    userOid: tools_1.str,
    jobId: tools_1.num,
    jobGuid: tools_1.str,
    jobStatus: tools_1.str,
    isOwner: tools_1.bool,
    isScrapingOwner: tools_1.bool,
    isDevImmunity: tools_1.bool,
    isSharableJob: tools_1.bool,
    isTestTenantJob: tools_1.bool,
    isBingJob: tools_1.bool,
    isCompletedBingJob: tools_1.bool,
    hasMetrics: tools_1.bool,
    environmentId: (0, tools_1.optional)(tools_1.num),
    hasReadPermissionByOwner: tools_1.bool,
    hasReadPermissionByBing: tools_1.bool,
    hasReadPermissionByNotSharable: tools_1.bool,
    hasReadPermissionByTestTenant: tools_1.bool,
    hasReadPermissionByDevImmunity: tools_1.bool,
    hasReadPermissionByNonShare: tools_1.bool,
    hasReadPermissionByShare: tools_1.bool,
    hasReadPermission: tools_1.bool,
    hasSharePermission: tools_1.bool,
    hasRerunPermission: tools_1.bool,
    hasCancelPermission: tools_1.bool,
    hasBingDownloadPermission: tools_1.bool,
    hasGeneralDownloadPermission: tools_1.bool,
    shouldShowBingDownloadButton: tools_1.bool,
    shouldShowGeneralDownloadButton: tools_1.bool,
    shouldLogDevImmunityEvent: tools_1.bool,
});
