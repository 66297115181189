import { computed } from "mobx";
import { TemplateType } from "sydneyeval-shared";
import { jobStore } from "../../store/jobStore";
import { getTemplateType } from "../getTemplateType";

export const getJobNameError = computed(() => {
  if (jobStore.jobName.trim().length > 0) {
    const templateType = getTemplateType.get();
    if (
      templateType === TemplateType.BingV2 ||
      templateType === TemplateType.CWC
    ) {
      const regexStr = /^[a-zA-Z_][a-zA-Z0-9_]*$/;
      if (regexStr.test(jobStore.jobName)) {
        return undefined;
      }
      return "Job Name should start with a letter or an underscore and contain only letters, numbers, and underscores!";
    }

    return undefined;
  } else {
    return "Job Name should not be empty!";
  }
});
