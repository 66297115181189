import {
  Button,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Share16Regular } from "@fluentui/react-icons";
import clipboardCopy from "clipboard-copy";
import { observer } from "mobx-react";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { resultStore } from "../../../store/resultStore";
import { useDetailPageStyles } from "../sharedStyles";

const useStyles = makeStyles({
  buttonContainer: {
    height: "20px",
    justifyContent: "center",
    alignjobs: "center",
    display: "flex",
    flexDirection: "row",
    backgroundColor: tokens.colorBrandBackground2,
    ...shorthands.borderRadius("4px"),
    ...shorthands.borderWidth("0px"),
  },
  buttonText: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "16px",
    fontStyle: "normal",
    opacity: 1,
    color: tokens.colorBrandForegroundLink,
  },
  shareContainer: {
    height: "20px",
    width: "20px",
    justifyContent: "center",
    alignItems: "center",
    alignjobs: "center",
    display: "flex",
    flexDirection: "row",
    ...shorthands.borderRadius("4px"),
    ...shorthands.borderWidth("0px"),
    ...shorthands.padding("0px", "10px"),
    ":hover": {
      color: tokens.colorBrandForegroundLink,
    },
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
  },
});

export const StudioURLButton = observer(() => {
  const styles = useDetailPageStyles();
  const toast = useToast();
  const buttonStyles = useStyles();

  const job = resultStore.resultJob;

  if (job?.StudioUrl === undefined) {
    return <></>;
  }

  return (
    <>
      <div className={styles.infoTitle}>Studio URL</div>
      <div className={buttonStyles.buttonRow}>
        <Button
          className={buttonStyles.buttonContainer}
          onClick={() => {
            const sanitizedUrl = encodeURI(job?.StudioUrl ?? "");
            window.open(sanitizedUrl, "_blank");
          }}
        >
          <span className={buttonStyles.buttonText}>Studio URL</span>
        </Button>
        <div
          className={buttonStyles.shareContainer}
          onClick={() => {
            toast.onToastStart("copy...");
            clipboardCopy(job?.StudioUrl ?? "");
            toast.onToastSuccess("Studio url copied!");
          }}
        >
          <Share16Regular />
        </div>
      </div>
    </>
  );
});
