import { action } from "satcheljs";
import type { ClientFeatureFlag } from "sydneyeval-shared";

export const setFeatureFlagOverrideAction = action(
  "setFeatureFlagOverrideAction",
  (featureFlag: ClientFeatureFlag, isEnabled: boolean) => ({
    featureFlag,
    isEnabled,
  }),
);

export const resetFeatureFlagOverridesAction = action(
  "resetFeatureFlagOverridesAction",
);
