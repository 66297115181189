import {
  Field,
  InfoLabel,
  Input,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { getValue } from "../../../selectors/getJobPropV2";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

const prop = "configs_in_json.conversations.gpt_identifier";

export const JobGPTIdentifierView = observer(() => {
  const styles = useStyles();

  const gptIdentifier = getValue(prop);

  if (gptIdentifier === undefined) {
    return <></>;
  }

  return (
    <div className={styles.block}>
      <InfoLabel
        className={styles.blockTitle}
        info={"The user GPT ID used for scraping."}
      >
        GPT ID:
      </InfoLabel>
      <Field>
        <Input
          size="medium"
          value={gptIdentifier}
          onChange={(_, data) => {
            updatePropValueActionV2({
              prop,
              newData: data.value,
            });
          }}
        />
      </Field>
    </div>
  );
});
