import type {
  TableColumnDefinition,
  TableColumnSizingOptions,
} from "@fluentui/react-components";
import {
  Button,
  Checkbox,
  DataGrid,
  DataGridBody,
  DataGridCell,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridRow,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Dropdown,
  Label,
  Link,
  MessageBar,
  Option,
  Spinner,
  Switch,
  TableCellLayout,
  Textarea,
  Tooltip,
  createTableColumn,
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import {
  AddCircle24Regular,
  ArrowSync24Regular,
  CheckmarkCircle24Filled,
  ClockAlarm24Filled,
  Copy24Regular,
  Delete24Regular,
  ErrorCircle24Filled,
  Info24Regular,
  SubtractCircle24Regular,
} from "@fluentui/react-icons";
import Papa from "papaparse";
import type { FunctionComponent } from "react";
import React from "react";
import { useLocation } from "react-router-dom";
import { telemetryHelper } from "../../../../helpers/telemetryHelper";
import { store } from "../../../../store/store";
import { CreateTaskConsentText } from "../ConsentConstants";
import {
  convertToMergedRows,
  formatNdcgRows,
  formatScrapingRows,
  mergeSbsLeoRow,
  setUniqueKeyForRows,
} from "../ContentPanel";
import type { IBasicRow } from "../bizChatEvalDataProvider";
import { TaskType } from "../models/TaskType";
import { DeleteTaskDialog } from "../sharedComponents/DeleteTaskDialog";
import { Pagination } from "../sharedComponents/Pagination";
import { FormatDateToString } from "../utils/FormatTime";
import {
  checkInputNumberValidity,
  checkInputQueryConfig,
} from "../utils/InputUtils";
import type { MessageBarType } from "../utils/MessageBarType";
import { concatUpn } from "../utils/UpnHelper";
import type { LLMJudgeEvaluationTaskData } from "./models/LLMJudgeEvaluationTaskData";
import {
  DeleteEvaluationTaskById,
  GetTaskHistoryByFilterAndPagination,
  SaveCreateTaskInfo,
} from "./sydneyEvalManagement";
import { getFileExtension } from "../utils/FileHelper";
import { allowToCheckCompleteness, parseJsonStr } from "../utils/utilities";
import type { IKeyValue } from "../models/CommonModels";

export interface StatusMessage {
  message: string;
  status?: MessageBarType;
}

export const taskMonthFormat = "yyyy-MM";
export const taskDateFormat = "yyyy-MM-dd";

const ITEMS_PER_PAGE = 10;

const initialState = {
  desiredCompletionDate: "" as string,
  taskUploadStatusMessage: undefined as StatusMessage | undefined,
  historyViewStatusMessage: undefined as StatusMessage | undefined,
  selectedCompletionDate: undefined as Date | undefined,
  isCompletionDateCalendarVisible: undefined as boolean | undefined,
  taskHistory: undefined as LLMJudgeEvaluationTaskData[] | undefined,
  createLoading: false,
  showCreateSection: false,
  currentPageNumber: 1 as number,
  hasClickedExpandCreateSection: false,
  allowAnyoneToLabel: true,
  taskType: TaskType.Distribution as TaskType,
  metricType: "GroundLeoClaimBreak",
  createdByMe: false,
  createdUserUpnFilter: "",
  taskTypeFilter: undefined as TaskType | undefined,
  columns: [] as TableColumnDefinition<LLMJudgeEvaluationTaskData>[],
  expNameOptions: [] as string[],
  conversationCount: 0,
  selectedExpName: "",
  readingUploadedFile: false,
  converationNumberToBeLabeled: "0",
  checkCompleteness: true,
  getConversationInOrder: false,
  allowScraping: false,
  forceInputVariants: false,
};

const metricTypeOptions = [
  "Conversation",
  "GroundLeoClaimBreak",
  "StewieLeo",
  "TaskComplete",
  "SBSLeo",
  "ReasoningLeo",
  "SCLeo",
  "NDCG",
];

const taskStatusOptions = [
  "Incomplete",
  "All",
  "New",
  "In Progress",
  "Complete",
];

const taskTypeOptions = ["Distribution", "Collection", "All"];

// type LLMJudgeEvaluationTaskDashboardProps = RouteComponentProps<any>;
type LLMJudgeEvaluationTaskDashboardProps = {
  location: {
    search: string;
  };
  debuggingMode: boolean;
};

function filterRowsToEvaluate(rows: IBasicRow<object>[], expName: string) {
  if (expName === "All") return rows;
  else return rows.filter((row) => row.exp_name === expName);
}

export class LLMJudgeEvaluationTaskDashboardClass extends React.Component<
  LLMJudgeEvaluationTaskDashboardProps,
  typeof initialState
> {
  private defaultTaskName = "test";
  private defaultLabelCountPerConversation = "1";
  private defaultMetricType = "GroundLeoClaimBreak";
  private defaultTaskStatusFilter = "Incomplete";
  private defaultTaskTypeFilter = "All";
  private defaultHideLlmLabel = true;
  private defaultRandomizeOrder = true;
  private defaultHideQueryPanel = true;

  private taskName: string;
  private labelCountPerConversation: string;
  private description: string;
  private conversationFile: File | undefined;
  private guideline: string;
  private adminList = "";
  private labelContributorList = ""; // who can label the task
  private hideLlmLabel: boolean;
  private randomizeOrder: boolean;
  private hideQueryPanel: boolean;
  private controlConfig = "";
  private expConfig = "";

  private deletingTask = undefined as LLMJudgeEvaluationTaskData | undefined;
  private taskNameFilter = "";
  private taskStatusFilter = "";
  private taskTypeFilter: string | undefined = undefined;
  private createdUserUpnFilter = "";

  private columns: TableColumnDefinition<LLMJudgeEvaluationTaskData>[] = [];
  private rows: IBasicRow<object>[] = [];

  private path = window.location.pathname.replace("/debuggingMode", "");

  constructor(props: LLMJudgeEvaluationTaskDashboardProps) {
    super(props);
    const today = new Date();
    this.state = {
      ...initialState,
      desiredCompletionDate: FormatDateToString(
        new Date(today.getTime() + 60 * 60 * 24 * 7 * 1000),
        taskDateFormat,
      ) as string,
      selectedCompletionDate: new Date(
        today.getTime() + 60 * 60 * 24 * 7 * 1000,
      ) as Date,
    };
    this.taskName = this.defaultTaskName;
    this.labelCountPerConversation = this.defaultLabelCountPerConversation;
    this.description = "";
    this.guideline = "";
    this.taskStatusFilter = this.defaultTaskStatusFilter;
    this.hideLlmLabel = this.defaultHideLlmLabel;
    this.randomizeOrder = this.defaultRandomizeOrder;
    this.hideQueryPanel = this.defaultHideQueryPanel;

    this.columns = [
      createTableColumn<LLMJudgeEvaluationTaskData>({
        columnId: "TaskName",
        renderHeaderCell: () => {
          return "Task Name";
        },
        renderCell: (task) => {
          const userUpn = store.account?.username;
          const hasPermission =
            !!userUpn &&
            (userUpn === task.created_user_upn ||
              task.administrators?.includes(userUpn));
          const detailUrl = `/llmjudge/task/detail/${task.task_id}`;
          return hasPermission ? (
            <TableCellLayout>
              <Link target="_blank" href={detailUrl} rel="noopener noreferrer">
                {task.task_name}
              </Link>
            </TableCellLayout>
          ) : (
            <TableCellLayout>{task.task_name}</TableCellLayout>
          );
        },
      }),
      createTableColumn<LLMJudgeEvaluationTaskData>({
        columnId: "TaskType",
        renderHeaderCell: () => {
          return "Task Type";
        },
        renderCell: (task) => {
          return (
            <TableCellLayout>
              <span>{task.task_type}</span>
            </TableCellLayout>
          );
        },
      }),
      createTableColumn<LLMJudgeEvaluationTaskData>({
        columnId: "MetricType",
        renderHeaderCell: () => {
          return "Metric Type";
        },
        renderCell: (task) => {
          return (
            <TableCellLayout>
              <span>{task.type}</span>
            </TableCellLayout>
          );
        },
      }),
      createTableColumn<LLMJudgeEvaluationTaskData>({
        columnId: "AnnotationProgress",
        renderHeaderCell: () => {
          return "Annotation Progress";
        },
        renderCell: (task) => {
          const annotationCompletedNumber =
            task.annotation_complete_number ?? 0;
          const annotationRequiredNumber =
            task.target_labelled_conversation_count ?? 0;
          const percentage = Math.round(
            ((annotationCompletedNumber * 1.0) / annotationRequiredNumber) *
              100,
          );
          return (
            <TableCellLayout>
              <span>
                {annotationCompletedNumber} / {annotationRequiredNumber} (
                {percentage}
                %)
              </span>
            </TableCellLayout>
          );
        },
      }),
      createTableColumn<LLMJudgeEvaluationTaskData>({
        columnId: "Emergency",
        renderHeaderCell: () => {
          return "Emergency";
        },
        renderCell: (task) => {
          const desiredCompletionDate = new Date(task.desired_completion_date);
          const currentDate = new Date();
          const dateDifference = Math.ceil(
            (desiredCompletionDate.getTime() - currentDate.getTime()) /
              (1000 * 3600 * 24),
          );
          if (
            dateDifference > 3 ||
            !task.desired_completion_date ||
            task.judge_status === "Complete"
          ) {
            return (
              <TableCellLayout>
                <div style={{ display: "flex" }}>
                  <CheckmarkCircle24Filled style={{ color: "#0178D4" }} />
                  <div style={{ marginLeft: "0.5em" }}>Normal</div>
                </div>
              </TableCellLayout>
            );
          } else if (dateDifference < 0) {
            // if already out of date
            return (
              <TableCellLayout>
                <div style={{ display: "flex" }}>
                  <ErrorCircle24Filled style={{ color: "red" }} />
                  <div style={{ marginLeft: "0.5em" }}>Timeout</div>
                </div>
              </TableCellLayout>
            );
          } else {
            // if date is less than 3 days
            return (
              <TableCellLayout>
                <div style={{ display: "flex" }}>
                  <ClockAlarm24Filled style={{ color: "orange" }} />
                  <div style={{ marginLeft: "0.5em" }}>Near Deadline</div>
                </div>
              </TableCellLayout>
            );
          }
        },
      }),
      createTableColumn<LLMJudgeEvaluationTaskData>({
        columnId: "Status",
        renderHeaderCell: () => {
          return "Status";
        },
        renderCell: (task) => {
          return <TableCellLayout>{task.judge_status}</TableCellLayout>;
        },
      }),
      createTableColumn<LLMJudgeEvaluationTaskData>({
        columnId: "TaskCreateTime",
        renderHeaderCell: () => {
          return "Task Create Time";
        },
        renderCell: (task) => {
          return <TableCellLayout>{task.created_datetime}</TableCellLayout>;
        },
      }),
      createTableColumn<LLMJudgeEvaluationTaskData>({
        columnId: "DesiredCompletionDate",
        renderHeaderCell: () => {
          return "Desired Complete Date";
        },
        renderCell: (task) => {
          return (
            <TableCellLayout>{task.desired_completion_date}</TableCellLayout>
          );
        },
      }),
      createTableColumn<LLMJudgeEvaluationTaskData>({
        columnId: "CreatedUser",
        renderHeaderCell: () => {
          return "Created User";
        },
        renderCell: (task) => {
          return (
            <TableCellLayout>{task.created_user_display_name}</TableCellLayout>
          );
        },
      }),
      createTableColumn<LLMJudgeEvaluationTaskData>({
        columnId: "AnnotationURL",
        renderHeaderCell: () => {
          return "Annotation URL";
        },
        renderCell: (task) => {
          return (
            <TableCellLayout>
              <Button
                icon={<Copy24Regular />}
                title="Copy task annotation URL to clipboard"
                appearance="transparent"
                onClick={() =>
                  LLMJudgeEvaluationTaskDashboardClass.copyTaskUrl(
                    task.task_url ?? "",
                  )
                }
              />
            </TableCellLayout>
          );
        },
      }),
      createTableColumn<LLMJudgeEvaluationTaskData>({
        columnId: "Action",
        renderHeaderCell: () => {
          return "Action";
        },
        renderCell: (task) => {
          const userUpn = store.account?.username;
          const hasPermission =
            !!userUpn &&
            (userUpn === task.created_user_upn ||
              task.administrators?.includes(userUpn));
          return !hasPermission ? (
            <TableCellLayout></TableCellLayout>
          ) : (
            <TableCellLayout>
              <DeleteTaskDialog
                onDelete={() =>
                  this.DeleteTask(this.deletingTask, this.state.taskHistory)
                }
                buttonProps={{
                  icon: <Delete24Regular />,
                  title: "Delete the task",
                  appearance: "transparent",
                  onClick: () => {
                    this.deletingTask = task;
                  },
                }}
              ></DeleteTaskDialog>
            </TableCellLayout>
          );
        },
      }),
    ];
  }

  public componentDidMount() {
    try {
      this.loadDataAsync();
      telemetryHelper.logDiagnosticEvent("HumanCorrelationStudyVisitPage", {
        message: "Visited Human Correlation Study task dashboard.",
        path: this.path,
      });
    } catch (e) {
      alert(
        `Failed to get your access info. Please refresh to try again!\nDetail: ${
          e instanceof Error ? e.message : e
        }`,
      );
    }
  }

  public renderCreateTaskLoading() {
    return (
      <Dialog open={true}>
        <DialogSurface>
          <DialogBody>
            <DialogContent>
              <div style={{ height: "4em" }}>
                <Spinner size="medium" label="Creating Task..." />
              </div>
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  }

  private static DistributionTaskSpecificColumns = [
    "AnnotationProgress",
    "Emergency",
    "Status",
    "DesiredCompletionDate",
  ];

  private getColumns(taskType?: string) {
    if (taskType === TaskType.Distribution || !taskType) {
      return this.columns;
    } else {
      return this.columns.filter(
        (column) =>
          !LLMJudgeEvaluationTaskDashboardClass.DistributionTaskSpecificColumns.includes(
            column.columnId as string,
          ),
      );
    }
  }

  private static ColumnSizingOptions: TableColumnSizingOptions = {
    TaskName: { defaultWidth: 300 },
    TaskType: { defaultWidth: 80 },
    MetricType: { defaultWidth: 150 },
    AnnotationProgress: { defaultWidth: 100 },
    Emergency: { defaultWidth: 100 },
    Status: { defaultWidth: 80 },
    TaskCreateTime: { defaultWidth: 150 },
    DesiredCompletionDate: { defaultWidth: 100 },
    CreatedUser: { defaultWidth: 150 },
    AnnotationURL: { defaultWidth: 80 },
    Action: { defaultWidth: 50 },
  };

  private updateUpn(aliasString: string, userType: string) {
    if (userType === "admin") {
      this.adminList = concatUpn(aliasString);
    } else {
      this.labelContributorList = concatUpn(aliasString);
    }
  }

  render() {
    const {
      taskHistory,
      historyViewStatusMessage,
      taskUploadStatusMessage,
      createLoading,
      showCreateSection,
      currentPageNumber,
      hasClickedExpandCreateSection,
      allowAnyoneToLabel,
      checkCompleteness,
      taskType,
      metricType,
      getConversationInOrder,
      allowScraping,
      forceInputVariants: forceInputVariants,
    } = this.state;

    const totalTaskNumber = taskHistory?.[0]?.total ?? 0;

    return (
      <div style={{ margin: "2em" }}>
        {createLoading && this.renderCreateTaskLoading()}
        <h2>Copilot Human Correlation Study Task Panel</h2>
        <a
          href="https://msasg.visualstudio.com/Cortana/_wiki/wikis/M365%20Chat.wiki/185519/Human-Correlation-Study"
          target="_blank"
          rel="noopener noreferrer"
        >
          User Manual
        </a>
        {/*parameter settings panel*/}

        <div
          style={{
            borderColor: "rgb(237, 235, 233)",
            borderStyle: "solid",
            borderWidth: "1px",
            padding: ".6rem 1.2rem",
            borderRadius: "5px",
            marginTop: "2rem",
            backgroundColor: "#fff",
          }}
        >
          <div style={{ display: "flex" }}>
            <h2 style={{ fontWeight: "600" }}>Create Task</h2>
            {showCreateSection ? (
              <Button
                icon={<SubtractCircle24Regular />}
                title="hide"
                appearance="transparent"
                onClick={() => this.setState({ showCreateSection: false })}
              />
            ) : hasClickedExpandCreateSection ? (
              <Button
                icon={<AddCircle24Regular />}
                title="show"
                appearance="transparent"
                onClick={() => this.setState({ showCreateSection: true })}
              ></Button>
            ) : (
              <Dialog>
                <DialogTrigger>
                  <Button
                    icon={<AddCircle24Regular />}
                    title="show"
                    appearance="transparent"
                  ></Button>
                </DialogTrigger>
                <DialogSurface>
                  <DialogBody>
                    <DialogTitle>Note</DialogTitle>
                    <DialogContent>{CreateTaskConsentText}</DialogContent>
                    <DialogActions>
                      <DialogTrigger>
                        <Button
                          appearance="primary"
                          onClick={() =>
                            this.setState({
                              hasClickedExpandCreateSection: true,
                              showCreateSection: true,
                            })
                          }
                        >
                          OK
                        </Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
            )}
          </div>
          {showCreateSection && (
            <>
              <div
                style={{
                  marginTop: "1em",
                  width: "auto",
                  display: "grid",
                  gridTemplateColumns: "1.2fr 1fr",
                }}
              >
                <div>
                  <div style={{ display: "flex", marginTop: "0.5em" }}>
                    <Label required style={{ width: "12em" }}>
                      Task Name
                    </Label>
                    <Textarea
                      style={{ width: "15em" }}
                      defaultValue={this.defaultTaskName}
                      onChange={(_e, newValue) => {
                        this.taskName = newValue.value || "";
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", marginTop: "0.5em" }}>
                    <Label style={{ width: "12em" }}>Task Description</Label>
                    <Textarea
                      style={{ width: "15em" }}
                      resize="vertical"
                      onChange={(_e, newValue) => {
                        this.description = newValue.value || "";
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", marginTop: ".5em" }}>
                    <Label required style={{ width: "12em" }}>
                      Metric Type
                    </Label>
                    <Dropdown
                      style={{
                        width: "15em",
                        textAlign: "left",
                        marginRight: "1em",
                      }}
                      id="metricTypeDropDown"
                      onOptionSelect={(_, o) => {
                        this.setState({ metricType: o?.optionValue || "" });
                      }}
                      defaultValue={this.defaultMetricType}
                    >
                      {metricTypeOptions.map((option) => (
                        <Option key={option}>{option}</Option>
                      ))}
                    </Dropdown>
                  </div>
                  {metricType === "SBSLeo" && (
                    <div style={{ display: "flex", marginTop: ".5em" }}>
                      <Label
                        required
                        style={{ width: "12em", display: "flex" }}
                      >
                        Randomize Order
                        <div style={{ margin: "-0.3em 0 0 0" }}>
                          <Button
                            icon={<Info24Regular />}
                            title="Randomize the order of control and treatment conversation pairs to avoid order bias"
                            appearance="transparent"
                          />
                        </div>
                      </Label>
                      <Checkbox
                        defaultChecked={this.defaultRandomizeOrder}
                        onChange={(ev, data) => {
                          this.randomizeOrder = !!data.checked;
                        }}
                        label=""
                      />
                    </div>
                  )}
                  <div style={{ display: "flex", marginTop: "0.5em" }}>
                    <Label required style={{ width: "12em", display: "flex" }}>
                      Task Type
                      <div style={{ margin: "-0.3em 0 0 0" }}>
                        <Button
                          icon={<Info24Regular />}
                          title="Distribution: Annotators get conversation one by one. 
                          Collection: Annotators get all conversations at once."
                          appearance="transparent"
                        />
                      </div>
                    </Label>
                    <Dropdown value={taskType}>
                      <Option
                        onClick={() => {
                          this.setState({ taskType: TaskType.Distribution });
                        }}
                      >
                        Distribution
                      </Option>
                      <Option
                        onClick={() => {
                          this.setState({ taskType: TaskType.Collection });
                        }}
                      >
                        Collection
                      </Option>
                    </Dropdown>
                  </div>
                  <div style={{ display: "flex", marginTop: "0.5em" }}>
                    <div style={{ display: "flex", width: "18em" }}>
                      <Label>Allow scraping</Label>
                      <Tooltip
                        content="If checked, user can get the response and conversation details in real time according to the query and perform annotation"
                        relationship="description"
                      >
                        <Button
                          style={{ height: "1.4em" }}
                          icon={<Info24Regular />}
                          appearance="transparent"
                        />
                      </Tooltip>
                    </div>
                    <Checkbox
                      checked={allowScraping}
                      onChange={(ev, data) => {
                        this.setState(
                          {
                            allowScraping: !!data.checked,
                          },
                          () => {
                            // if there is already an uploaded file, need to parse it again.
                            if (this.conversationFile) {
                              this.onChangeFile(this.conversationFile);
                            }
                          },
                        );
                      }}
                      label=""
                      className="allowScrapingCheckbox"
                    />
                  </div>
                  {taskType === TaskType.Distribution && (
                    <>
                      <div style={{ display: "flex", marginTop: "0.5em" }}>
                        <Label
                          required
                          style={{ width: "12em", display: "flex" }}
                        >
                          Desired Completion Date
                          <div style={{ margin: "-0.3em 0 0 0" }}>
                            <Button
                              icon={<Info24Regular />}
                              title="The desired completion date (in UTC timezone)"
                              appearance="transparent"
                            />
                          </div>
                        </Label>
                        <DatePicker
                          value={this.state.selectedCompletionDate}
                          onSelectDate={(newDate) => {
                            if (newDate) {
                              this.setState({
                                selectedCompletionDate: newDate,
                                desiredCompletionDate: FormatDateToString(
                                  newDate,
                                  taskDateFormat,
                                ),
                              });
                            }
                          }}
                          placeholder="Select a date..."
                        />
                      </div>
                      <div style={{ display: "flex", marginTop: ".5em" }}>
                        <Label
                          required
                          style={{ width: "12em", display: "flex" }}
                        >
                          Hide query panel by default
                          <div style={{ margin: "-0.3em 0 0 0" }}>
                            <Button
                              icon={<Info24Regular />}
                              title="Hide query panel by default to save more space. User can still exapnd it by clicking button on the page."
                              appearance="transparent"
                            />
                          </div>
                        </Label>
                        <Checkbox
                          defaultChecked={this.defaultHideQueryPanel}
                          onChange={(ev, data) => {
                            this.hideQueryPanel = !!data.checked;
                          }}
                          label=""
                        />
                      </div>
                    </>
                  )}
                  <div style={{ display: "flex", marginTop: "0.5em" }}>
                    <Label required style={{ width: "12em" }}>
                      Conversation data file
                    </Label>
                    <input
                      style={{ marginTop: "1em" }}
                      type="file"
                      id="file"
                      accept={".csv" + (allowScraping ? ",.tsv" : "")}
                      onChange={(changeEvent) =>
                        this.onChangeFile(changeEvent.target.files?.[0])
                      }
                    ></input>
                  </div>
                  {this.state.readingUploadedFile && (
                    <div style={{ display: "flex", marginTop: "0.5em" }}>
                      <Spinner size="small" label="Reading uploaded file..." />
                    </div>
                  )}
                  {this.state.expNameOptions.length > 0 && (
                    <div>
                      <div style={{ display: "flex", marginTop: "0.5em" }}>
                        <Label required style={{ width: "12em" }}>
                          Experiment name
                        </Label>
                        <Dropdown
                          style={{ width: "30em", textAlign: "left" }}
                          id="expNameDropDown"
                          onOptionSelect={(_e, option) => {
                            if (option.optionValue) {
                              this.setState({
                                selectedExpName: option.optionValue,
                              });
                              const selectedRows = filterRowsToEvaluate(
                                this.rows,
                                option.optionValue,
                              );
                              this.setState({
                                conversationCount: selectedRows.length,
                                converationNumberToBeLabeled:
                                  selectedRows.length.toString(),
                              });
                            }
                            option.optionValue &&
                              this.setState({
                                selectedExpName: option.optionValue,
                              });
                          }}
                          value={this.state.selectedExpName}
                        >
                          {this.state.expNameOptions.map((option) => (
                            <Option key={option}>{option}</Option>
                          ))}
                        </Dropdown>
                      </div>
                      <div>
                        You select&nbsp;
                        <span style={{ color: "green" }}>
                          {this.state.conversationCount}
                        </span>
                        {` ${
                          metricType === "SBSLeo" ? " pairs of" : " "
                        } conversations in ${this.state.selectedExpName}`}
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  {taskType === TaskType.Distribution && (
                    <>
                      <div style={{ display: "flex", marginTop: "0.5em" }}>
                        <Label required style={{ width: "18em" }}>
                          {metricType === "SBSLeo"
                            ? "Number of Conversation Pairs to be Labeled"
                            : "Number of Conversations to be Labeled"}
                        </Label>
                        <Textarea
                          style={{ width: "15em" }}
                          value={this.state.converationNumberToBeLabeled.toString()}
                          onChange={(_e, newValue) => {
                            this.setState({
                              converationNumberToBeLabeled:
                                newValue.value || "",
                            });
                          }}
                        />
                      </div>
                      <div style={{ display: "flex", marginTop: "0.5em" }}>
                        <Label required style={{ width: "18em" }}>
                          Number of Judges per Conversation
                        </Label>
                        <Textarea
                          style={{ width: "15em" }}
                          defaultValue={this.defaultLabelCountPerConversation}
                          onChange={(_e, newValue) => {
                            this.labelCountPerConversation =
                              newValue.value || "";
                          }}
                        />
                      </div>
                      {allowToCheckCompleteness(metricType) && (
                        <div style={{ display: "flex", marginTop: "0.5em" }}>
                          <div style={{ display: "flex", width: "18em" }}>
                            <Label>Enforce label completeness</Label>
                            <Tooltip
                              content="If checked, user is forced to complete all human label questions in a conversation before they can move on to label the next conversation"
                              relationship="description"
                            >
                              <Button
                                style={{ height: "1.4em" }}
                                icon={<Info24Regular />}
                                appearance="transparent"
                              />
                            </Tooltip>
                          </div>
                          <Checkbox
                            checked={checkCompleteness}
                            onChange={(ev, data) => {
                              this.setState({
                                checkCompleteness: !!data.checked,
                              });
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                  <div style={{ display: "flex", marginTop: "0.5em" }}>
                    <Label style={{ width: "18em" }}>Task Guideline</Label>
                    <Textarea
                      style={{ width: "15em" }}
                      resize="vertical"
                      onChange={(_e, newValue) => {
                        this.guideline = newValue.value || "";
                      }}
                    ></Textarea>
                  </div>
                  {!allowScraping && (
                    <div style={{ display: "flex", marginTop: "0.5em" }}>
                      <Label style={{ width: "18em" }}>
                        Hide LLM label results
                      </Label>
                      <Checkbox
                        defaultChecked={this.defaultHideLlmLabel}
                        onChange={(ev, data) => {
                          this.hideLlmLabel = !!data.checked;
                        }}
                        label=""
                      />
                    </div>
                  )}
                  <div style={{ display: "flex", marginTop: "0.5em" }}>
                    <div style={{ display: "flex", width: "18em" }}>
                      <Label>Admin List</Label>
                      <Tooltip
                        content="Admin of this task can view annotation detail, and delete this task"
                        relationship="description"
                      >
                        <Button
                          style={{ height: "1.4em" }}
                          icon={<Info24Regular />}
                          appearance="transparent"
                        />
                      </Tooltip>
                    </div>
                    <Textarea
                      style={{ width: "15em" }}
                      resize="vertical"
                      placeholder="Input alias split by comma. e.g. aliasA,aliasB"
                      onChange={(_e, newValue) => {
                        this.updateUpn(newValue.value, "admin");
                      }}
                    ></Textarea>
                  </div>
                  <div style={{ display: "flex", marginTop: "0.5em" }}>
                    <Label style={{ width: "18em" }}>
                      Allow all SEVAL users to label this task
                    </Label>
                    <Checkbox
                      checked={allowAnyoneToLabel}
                      onChange={(ev, data) => {
                        this.setState({ allowAnyoneToLabel: !!data.checked });
                        if (data.checked) {
                          this.labelContributorList = "";
                        }
                      }}
                      label=""
                      className="allowAnyoneToLabelCheckbox"
                    />
                  </div>
                  {!allowAnyoneToLabel && (
                    <>
                      <div style={{ display: "flex", marginTop: "0.5em" }}>
                        <div style={{ display: "flex", width: "18em" }}>
                          <Label>Authorized Annotators</Label>
                          <Tooltip
                            content="Authorized annotators can annotate the task"
                            relationship="description"
                          >
                            <Button
                              style={{ height: "1.4em" }}
                              icon={<Info24Regular />}
                              appearance="transparent"
                            />
                          </Tooltip>
                        </div>
                        <Textarea
                          style={{ width: "15em" }}
                          resize="vertical"
                          placeholder="Input alias split by comma. e.g. aliasA,aliasB"
                          onChange={(_e, newValue) => {
                            this.updateUpn(newValue.value, "labelContributor");
                          }}
                        ></Textarea>
                      </div>
                    </>
                  )}
                  {taskType === TaskType.Distribution && (
                    <>
                      <div style={{ display: "flex", marginTop: "0.5em" }}>
                        <Label style={{ width: "18em" }}>
                          Get conversation in the same order
                        </Label>
                        <Checkbox
                          checked={getConversationInOrder}
                          onChange={(ev, data) => {
                            this.setState({
                              getConversationInOrder: !!data.checked,
                            });
                          }}
                          label=""
                          className="getConversationInOrderCheckbox"
                        />
                      </div>
                    </>
                  )}
                  {allowScraping && (
                    <>
                      <div style={{ display: "flex", marginTop: "0.5em" }}>
                        <div style={{ display: "flex", width: "18em" }}>
                          <Label>Force input variants</Label>
                          <Tooltip
                            content="Force judge to input variants every time a judge enters the annotation page"
                            relationship="description"
                          >
                            <Button
                              style={{ height: "1.4em" }}
                              icon={<Info24Regular />}
                              appearance="transparent"
                            />
                          </Tooltip>
                        </div>
                        <Checkbox
                          checked={forceInputVariants}
                          onChange={(ev, data) => {
                            this.setState({
                              forceInputVariants: !!data.checked,
                            });
                          }}
                          label=""
                          className="forceInputVariantsCheckbox"
                        />
                      </div>
                      {metricType === "SBSLeo" && !forceInputVariants && (
                        <div
                          className="controlConfigArea"
                          style={{ display: "flex", marginTop: "0.5em" }}
                        >
                          <div style={{ display: "flex", width: "18em" }}>
                            <Label required>Control Configuration</Label>
                            <Tooltip
                              content='Like {"exp_name": "control", "variants": "feature.UseSahara16k,feature.enableRaiModel", "option_sets": "enterprise_with_diagnostics,enterprise_flux_work""}'
                              relationship="description"
                            >
                              <Button
                                style={{ height: "1.4em" }}
                                icon={<Info24Regular />}
                                appearance="transparent"
                              />
                            </Tooltip>
                          </div>
                          <Textarea
                            style={{ width: "15em" }}
                            resize="vertical"
                            placeholder="Input config json string, contains exp_name, variants, option_sets, etc."
                            onChange={(_e, newValue) => {
                              this.controlConfig = newValue.value || "";
                            }}
                          ></Textarea>
                        </div>
                      )}
                      {!forceInputVariants && (
                        <div
                          className="expConfigArea"
                          style={{ display: "flex", marginTop: "0.5em" }}
                        >
                          <div style={{ display: "flex", width: "18em" }}>
                            <Label required>
                              {metricType === "SBSLeo"
                                ? "Experiment Configuration"
                                : "Query Configuration"}
                            </Label>
                            <Tooltip
                              content='Like {"exp_name": "exp", "variants": "3s.enablelssqfp,feature.IsEaPEnabled", "option_sets": "enterprise_with_diagnostics,enterprise_flux_work"}'
                              relationship="description"
                            >
                              <Button
                                style={{ height: "1.4em" }}
                                icon={<Info24Regular />}
                                appearance="transparent"
                              />
                            </Tooltip>
                          </div>
                          <Textarea
                            style={{ width: "15em" }}
                            resize="vertical"
                            placeholder="Input config json string, contains exp_name, variants, option_sets, etc."
                            onChange={(_e, newValue) => {
                              this.expConfig = newValue.value || "";
                            }}
                          ></Textarea>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div style={{ display: "flex", marginTop: "1em" }}>
                <Button
                  style={{ width: "12em" }}
                  onClick={this.onClickCreateTaskButton}
                  appearance="primary"
                >
                  Create Task
                </Button>
              </div>
            </>
          )}
          {taskUploadStatusMessage && (
            <MessageBar intent={taskUploadStatusMessage.status}>
              {taskUploadStatusMessage.message}
            </MessageBar>
          )}
        </div>

        {/*View history panel*/}
        <div
          style={{
            borderColor: "rgb(237, 235, 233)",
            borderStyle: "solid",
            borderWidth: "1px",
            padding: ".6rem 1.2rem",
            borderRadius: "5px",
            marginTop: "2rem",
            backgroundColor: "#fff",
          }}
        >
          <div style={{ display: "flex" }}>
            <h2 style={{ fontWeight: "600" }}>View Created Tasks</h2>
            <Button
              icon={<ArrowSync24Regular />}
              appearance="transparent"
              onClick={this.loadDataAsync}
            />
          </div>
          {historyViewStatusMessage && (
            <MessageBar intent={historyViewStatusMessage.status}>
              <div style={{ whiteSpace: "pre-line" }}>
                {historyViewStatusMessage.message}
              </div>
            </MessageBar>
          )}
          <div
            style={{
              display: "flex",
              marginTop: "1em",
              flexWrap: "wrap",
            }}
          >
            <Label style={{ marginTop: ".5em" }}>Task Name</Label>
            <Textarea
              style={{ width: "11em", height: "2.5em", marginLeft: ".5em" }}
              defaultValue=""
              onChange={(_e, newValue) => {
                this.taskNameFilter = newValue.value ?? "";
              }}
            />
            <Label style={{ marginLeft: "2em", marginTop: ".5em" }}>
              Task Type
            </Label>
            <Dropdown
              style={{ textAlign: "left", marginLeft: ".5em" }}
              id="taskTypeDropDown"
              onOptionSelect={(_e, option) => {
                this.taskTypeFilter =
                  option.optionValue === "All"
                    ? undefined
                    : option?.optionValue;
                this.setState({
                  taskTypeFilter: this.taskTypeFilter as TaskType | undefined,
                });
              }}
              defaultValue={this.defaultTaskTypeFilter}
            >
              {taskTypeOptions.map((option) => (
                <Option key={option}>{option}</Option>
              ))}
            </Dropdown>
            {this.state.taskTypeFilter !== TaskType.Collection && (
              <>
                <Label style={{ marginLeft: "2em", marginTop: ".5em" }}>
                  Task Status
                </Label>
                <Dropdown
                  style={{
                    width: "5em",
                    textAlign: "left",
                    marginLeft: ".5em",
                  }}
                  id="taskStatusDropDown"
                  onOptionSelect={(_e, option) =>
                    (this.taskStatusFilter =
                      option?.optionValue ?? this.defaultTaskStatusFilter)
                  }
                  defaultValue={this.defaultTaskStatusFilter}
                >
                  {taskStatusOptions.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </Dropdown>
              </>
            )}
            <Label style={{ marginLeft: "2em", marginTop: ".5em" }}>
              Created by (upn/alias)
            </Label>
            <Textarea
              style={{ width: "11em", height: "2.5em", marginLeft: ".5em" }}
              value={this.state.createdUserUpnFilter}
              disabled={this.state.createdByMe}
              onChange={(_e, newValue) => {
                this.createdUserUpnFilter = newValue.value ?? "";
                this.setState({ createdUserUpnFilter: newValue.value ?? "" });
              }}
            />
            <Switch
              label={"me"}
              checked={this.state.createdByMe}
              onChange={() => {
                this.createdUserUpnFilter = this.state.createdByMe
                  ? ""
                  : store.account?.username ?? "";
                this.setState({
                  createdByMe: !this.state.createdByMe,
                  createdUserUpnFilter: this.createdUserUpnFilter,
                });
              }}
            ></Switch>
            <Button
              style={{ marginLeft: "2em" }}
              onClick={() => this.FilterTask()}
              appearance="primary"
            >
              Filter
            </Button>
          </div>
          {taskHistory && (
            <div style={{ marginTop: "1em" }}>
              <DataGrid
                items={taskHistory}
                columns={this.state.columns}
                getRowId={(task) => task.task_id}
                resizableColumns
                columnSizingOptions={
                  LLMJudgeEvaluationTaskDashboardClass.ColumnSizingOptions
                }
              >
                <DataGridHeader>
                  <DataGridRow>
                    {({ renderHeaderCell }) => (
                      <DataGridHeaderCell>
                        {renderHeaderCell()}
                      </DataGridHeaderCell>
                    )}
                  </DataGridRow>
                </DataGridHeader>
                <DataGridBody<LLMJudgeEvaluationTaskData>>
                  {({ item, rowId }) => (
                    <DataGridRow<LLMJudgeEvaluationTaskData> key={rowId}>
                      {({ renderCell }) => (
                        <DataGridCell>{renderCell(item)}</DataGridCell>
                      )}
                    </DataGridRow>
                  )}
                </DataGridBody>
              </DataGrid>
            </div>
          )}
          <div style={{ display: "flex", marginTop: "1em" }}>
            <span style={{ margin: "0.3em 1.5em 0 0" }}>
              Showing {ITEMS_PER_PAGE * (currentPageNumber - 1) + 1}-
              {Math.min(ITEMS_PER_PAGE * currentPageNumber, totalTaskNumber)} of{" "}
              {totalTaskNumber} results
            </span>
            <Pagination
              totalPages={Math.ceil(totalTaskNumber / ITEMS_PER_PAGE)}
              currentPage={currentPageNumber}
              onChange={(page) => this.onChangePage(page)}
            />
          </div>
        </div>

        <p>
          Report any feedback to{" "}
          <Link
            target="_blank"
            href="mailto:HumanCorrelationDev@microsoft.com"
            rel="noopener noreferrer"
          >
            HumanCorrelationDev@microsoft.com
          </Link>
        </p>
      </div>
    );
  }

  private onChangeFile = (file: File | undefined) => {
    const { allowScraping } = this.state;
    this.setState({ readingUploadedFile: true });
    this.setState({ conversationCount: 0, converationNumberToBeLabeled: "0" });
    this.setState({ selectedExpName: "" });
    this.setState({ expNameOptions: [] });
    const fileExtension = getFileExtension(file?.name);
    const allowedExtensions = ["csv", ...(allowScraping ? ["tsv"] : [])];
    this.conversationFile =
      allowedExtensions.indexOf(fileExtension) !== -1 ? file : undefined;
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const csvString = e.target?.result as string;
        let rows = Papa.parse(csvString, { header: true })
          .data as IBasicRow<object>[];
        if (allowScraping) {
          rows = await formatScrapingRows(rows);
        }

        if (this.state.metricType === "NDCG") {
          rows = formatNdcgRows(rows);
        }

        if (this.state.metricType === "SBSLeo") {
          if (fileExtension === "tsv") {
            rows = convertToMergedRows(rows);
          } else {
            rows = mergeSbsLeoRow(rows) as IBasicRow<object>[]; // store the merged rows in sql
          }
        }
        if (this.state.metricType === "SCLeo") {
          setUniqueKeyForRows(rows);
        }

        this.rows = rows;
        const expNameOptions = Array.from(
          new Set(rows.map((row) => row.exp_name)),
        ).filter((expName) => expName) as string[];
        // scraping task has been processed in formatScrapingRows
        if (this.state.taskType !== TaskType.Distribution && !allowScraping) {
          expNameOptions.unshift("All");
        }
        this.setState({ expNameOptions });
        const defaultExpName = expNameOptions[0] ?? "";
        this.setState({ selectedExpName: defaultExpName });
        const conversationCount =
          defaultExpName === "All"
            ? rows.length
            : rows.filter((row) => row.exp_name === defaultExpName).length;
        this.setState({
          conversationCount,
          converationNumberToBeLabeled: conversationCount.toString(),
        });
        this.setState({ readingUploadedFile: false });
      } catch (error) {
        if (error instanceof Error) {
          alert("Fail to parse uploaded file. Error:" + error.message);
        }
        this.conversationFile = undefined;
        this.setState({ readingUploadedFile: false });
      }
    };
    file && reader.readAsText(file);
  };

  private concatUrlParameters() {
    const {
      metricType,
      checkCompleteness,
      taskType,
      getConversationInOrder,
      allowScraping,
      forceInputVariants: forceInputVariants,
    } = this.state;
    let urlParameters = `?hideLlmLabel=${this.hideLlmLabel}`;
    if (allowToCheckCompleteness(metricType)) {
      urlParameters += `&checkCompleteness=${checkCompleteness}`;
    }
    if (metricType === "SBSLeo") {
      urlParameters += `&randomizeOrder=${this.randomizeOrder}`;
    }
    if (taskType === TaskType.Distribution) {
      urlParameters += `&getConversationInOrder=${getConversationInOrder}`;
      urlParameters += `&hideQueryPanel=${this.hideQueryPanel}`;
    }
    if (allowScraping) {
      urlParameters += "&allowScraping=true";
      // allow user to add query for collection mode
      if (taskType === TaskType.Collection) {
        urlParameters += "&allowUserQuery=true";
      }
      if (forceInputVariants) {
        urlParameters += "&forceInputVariants=true";
      } else {
        const expConfig = JSON.parse(this.expConfig) as IKeyValue;
        if (metricType === "SBSLeo") {
          const controlConfig = JSON.parse(this.controlConfig) as IKeyValue;
          urlParameters += `&variants=${controlConfig["variants"]}|${expConfig["variants"]}`;
        } else {
          urlParameters += `&variants=${expConfig["variants"]}`;
        }
      }
    }
    return urlParameters;
  }

  private updateScrapingRows = (rows: IBasicRow<object>[]) => {
    const { metricType, forceInputVariants } = this.state;
    if (metricType !== "SBSLeo") {
      if (!forceInputVariants && !this.expConfig) {
        throw new Error("Please input query variants");
      }
    } else if (
      !forceInputVariants &&
      (!this.controlConfig || !this.expConfig)
    ) {
      throw new Error("Please input control and experiment variants");
    }
    for (const row of rows) {
      const expConfig: IKeyValue | undefined = parseJsonStr(
        this.expConfig,
        undefined,
      );
      row.config = JSON.stringify({
        exp_name: "exp",
        ...expConfig,
      });
      row.exp_name = "exp";
      row.unique_key = `exp_${row.ConversationId}`;

      if (row.ControlRow && metricType === "SBSLeo") {
        const controlConfig: IKeyValue | undefined = parseJsonStr(
          this.controlConfig,
          undefined,
        );
        const newControlRow = {
          ...row.ControlRow,
          config: JSON.stringify({
            exp_name: "control",
            ...controlConfig,
          }),
          exp_name: "control",
          unique_key: `control_${row.ConversationId}`,
        };
        row.ControlRow = undefined;
        // need to
        row.ControlRowData = JSON.stringify(newControlRow);
      }
    }
  };

  private onClickCreateTaskButton = async () => {
    const {
      desiredCompletionDate,
      allowAnyoneToLabel,
      taskType,
      metricType,
      allowScraping,
      forceInputVariants,
    } = this.state;
    const requiredInputValidityPassed = this.checkRequiredInput(
      this.taskName,
      "Task Name",
    );

    if (!requiredInputValidityPassed) return;

    if (taskType === TaskType.Distribution) {
      const inputNumberValidityPassed =
        checkInputNumberValidity(
          this.state.converationNumberToBeLabeled,
          "Number of Conversations to be Labeled",
          true,
        ) &&
        checkInputNumberValidity(
          this.labelCountPerConversation,
          "Number of Judges per Conversation",
          true,
        );
      if (!inputNumberValidityPassed) return;
    }
    if (allowScraping && !forceInputVariants) {
      let alertMsg = checkInputQueryConfig(
        this.expConfig,
        metricType === "SBSLeo"
          ? "Experiment Configuration"
          : "Query Configuration",
      );

      // SBSLeo also need to check controlConfig
      if (metricType === "SBSLeo" && !alertMsg && !this.controlConfig) {
        alertMsg = checkInputQueryConfig(
          this.controlConfig,
          "Control Configuration",
        );
      }
      if (alertMsg) {
        alert(alertMsg);
        return;
      }
    }

    const uploadFileNotEmpty = !!this.conversationFile;
    if (!uploadFileNotEmpty) {
      alert("Please upload valid conversation data file");
      return;
    }

    this.setState({ createLoading: true });

    try {
      if (this.conversationFile === undefined) {
        throw new Error("Conversation data file is empty!");
      }

      const selectedRows = filterRowsToEvaluate(
        this.rows,
        allowScraping ? "All" : this.state.selectedExpName,
      );
      if (allowScraping) {
        this.updateScrapingRows(selectedRows);
      }
      // convert selectedRows to file
      const selectedRowsString = Papa.unparse(selectedRows, { header: true });
      const selectedRowsBlob = new Blob([selectedRowsString], {
        type: "text/csv",
      });
      const selectedRowsFile = new File([selectedRowsBlob], "selectedRows.csv");
      const urlParameters = this.concatUrlParameters();
      const newTask = (await SaveCreateTaskInfo(
        selectedRowsFile,
        this.description,
        this.taskName,
        desiredCompletionDate,
        metricType.toString(),
        this.state.converationNumberToBeLabeled,
        this.labelCountPerConversation,
        this.guideline,
        this.adminList,
        allowAnyoneToLabel,
        this.labelContributorList,
        urlParameters,
        taskType,
      )) as LLMJudgeEvaluationTaskData;

      telemetryHelper.logDiagnosticEvent("HumanCorrelationStudyCreateTask", {
        message: `Created Human Correlation Study task. Task ID=${newTask.task_id}`,
        path: this.path,
      });

      this.setState({ createLoading: false });
      this.loadDataAsync();
    } catch (e) {
      if (e instanceof Error) {
        alert(e.message);
      }
      this.setState({ createLoading: false });
    }
  };

  // make sure all required fields have value
  private checkRequiredInput(inputField: string, fieldName: string) {
    if (inputField === "") {
      alert(`Missing input for ${fieldName}`);
      return false;
    }
    return true;
  }

  private onChangePage = (page: number) => {
    this.setState({ currentPageNumber: page });
    this.FilterTask(page);
  };

  public static copyTaskUrl(taskUrl?: string) {
    const url = `${window.location.origin}${taskUrl}`;
    navigator.clipboard.writeText(url);
  }

  private FilterTask = async (pageNumber = 1) => {
    try {
      this.setState({
        historyViewStatusMessage: { message: "Refreshing list..." },
      });
      const nameFilter = this.taskNameFilter ?? undefined;
      const statusFilter =
        this.taskStatusFilter === "All" ? undefined : this.taskStatusFilter;
      const taskHistory = await GetTaskHistoryByFilterAndPagination(
        pageNumber,
        ITEMS_PER_PAGE,
        nameFilter,
        statusFilter,
        this.taskTypeFilter,
        concatUpn(this.createdUserUpnFilter),
        this.props.debuggingMode,
      );
      this.setState({
        taskHistory: taskHistory,
        historyViewStatusMessage: {
          message: "Successfully refresh task history list.",
          status: "success",
        },
        columns: this.getColumns(this.taskTypeFilter),
      });
    } catch (e) {
      this.setState({
        historyViewStatusMessage: {
          message: e instanceof Error ? e.message : "Failed to refresh list.",
          status: "error",
        },
      });
    }
  };

  private DeleteTask = async (
    task: LLMJudgeEvaluationTaskData | undefined,
    tasks: LLMJudgeEvaluationTaskData[] | undefined,
  ) => {
    const { currentPageNumber } = this.state;

    if (task === undefined) {
      return;
    }
    this.deletingTask = undefined;
    this.setState({
      historyViewStatusMessage: {
        message: `Deleting the task ${task.task_name}. It might take some time`,
        status: "info",
      },
    });
    if (task && tasks) {
      try {
        await DeleteEvaluationTaskById(task.task_id);

        telemetryHelper.logDiagnosticEvent("HumanCorrelationStudyDeleteTask", {
          message: `Deleted Human Correlation Study task. Task ID=${task.task_id}`,
          path: this.path,
        });

        this.loadDataAsync(currentPageNumber);
      } catch (e) {
        this.setState({
          historyViewStatusMessage: {
            message: e instanceof Error ? e.message : "Failed to delete task.",
            status: "error",
          },
        });
      }
    }
    this.setState({
      historyViewStatusMessage: {
        message: `Delete the task ${task.task_name} successfully.`,
        status: "success",
      },
    });
    setTimeout(() => {
      this.setState({ historyViewStatusMessage: undefined });
    }, 3000);
  };

  private loadDataAsync = async (event?: any, pageNumber = 1) => {
    try {
      this.setState({
        historyViewStatusMessage: { message: "Refreshing list..." },
      });
      const nameFilter = this.taskNameFilter ?? undefined;
      const statusFilter =
        this.taskStatusFilter === "All" ? undefined : this.taskStatusFilter;

      const taskHistory = await GetTaskHistoryByFilterAndPagination(
        pageNumber,
        ITEMS_PER_PAGE,
        nameFilter,
        statusFilter,
        this.taskTypeFilter,
        concatUpn(this.createdUserUpnFilter),
        this.props.debuggingMode,
      );

      this.setState({
        taskHistory: taskHistory,
        historyViewStatusMessage: {
          message: "Successfully refresh task history list.",
          status: "success",
        },
        currentPageNumber: pageNumber,
        columns: this.getColumns(this.taskTypeFilter),
      });
    } catch (e) {
      this.setState({
        historyViewStatusMessage: {
          message: e instanceof Error ? e.message : "Failed to refresh list.",
          status: "error",
        },
      });
    }
  };
}

export const LLMJudgeEvaluationTaskDashboard: FunctionComponent<{
  debuggingMode?: boolean;
}> = ({ debuggingMode }) => {
  return (
    <LLMJudgeEvaluationTaskDashboardClass
      location={useLocation()}
      debuggingMode={!!debuggingMode}
    />
  );
};
