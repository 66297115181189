import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Divider,
  Spinner,
} from "@fluentui/react-components";
import { Document20Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { parseJsonStrOptional } from "sydneyeval-shared";
import { getAzureMLFileContent } from "../../../../../helpers/apiHelper";
import {
  downloadFile,
  prettyDownloadSetting,
} from "../../../helpers/downloadHelper";
import { BingConfigFileSchema } from "../../../models/BingJobConfig";
import { JobFileRenderView } from "./JobFileRenderView";

interface IJobDetailSettingViewProps {
  settings: string;
}

interface ConfigFilePath {
  name: string;
  path: string;
  content: string;
}

export const JobDetailSettingView = observer(
  (props: IJobDetailSettingViewProps) => {
    const { settings } = props;
    const [configFiles, setConfigFiles] = React.useState<ConfigFilePath[]>([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const getAllFilePaths = () => {
      const allConfigFiles: ConfigFilePath[] = [];
      const parsedConfigFiles = parseJsonStrOptional(
        settings,
        BingConfigFileSchema,
      );
      if (parsedConfigFiles === undefined) {
        return [];
      }
      if (parsedConfigFiles.config_file) {
        allConfigFiles.push({
          name: "Config file",
          path: parsedConfigFiles.config_file.path,
          content: "Loading...",
        });
      }

      if (parsedConfigFiles.manifest_file) {
        allConfigFiles.push({
          name: "Manifest file",
          path: parsedConfigFiles.manifest_file.path,
          content: "Loading...",
        });
      }

      if (parsedConfigFiles.api_file) {
        allConfigFiles.push({
          name: "Api file",
          path: parsedConfigFiles.api_file.path,
          content: "Loading...",
        });
      }

      return allConfigFiles;
    };

    useEffect(() => {
      if (configFiles.length > 0) {
        return;
      }
      const allConfigFiles = getAllFilePaths();
      if (allConfigFiles.length > 0) {
        const fetchedAllFiles = allConfigFiles.map(async (item) => {
          const fetchResult: ConfigFilePath = await getAzureMLFileContent(
            item.path,
          )
            .then((json) => {
              try {
                const jsonObject = JSON.parse(json);
                const jsonStr = JSON.stringify(jsonObject, null, 2);
                return {
                  ...item,
                  content: jsonStr,
                };
              } catch (e) {
                return {
                  ...item,
                  content: json,
                };
              }
            })
            .catch(() => {
              return {
                ...item,
                content: "Fail to load.",
              };
            });
          return fetchResult;
        });

        Promise.all(fetchedAllFiles).then((files) => {
          setConfigFiles(files);
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    }, [settings]);

    if (isLoading) {
      return <Spinner />;
    }

    if (configFiles.length > 0) {
      if (configFiles.length === 1) {
        const item = configFiles[0];
        return (
          <JobFileRenderView
            fileName={item.name + ".json"}
            content={item.content}
            download={() =>
              downloadFile(
                prettyDownloadSetting(item.content),
                item.name + ".json",
              )
            }
          />
        );
      }
      return (
        <Accordion collapsible={true}>
          {configFiles.map((item) => {
            return (
              <AccordionItem value={item.name} key={item.name}>
                <Divider style={{ padding: "10px 0" }} />
                <AccordionHeader>
                  <Document20Regular /> {item.name}
                </AccordionHeader>
                <AccordionPanel>
                  <JobFileRenderView
                    fileName={item.name + ".json"}
                    content={item.content}
                    download={() =>
                      downloadFile(
                        prettyDownloadSetting(item.content),
                        item.name + ".json",
                      )
                    }
                  />
                </AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      );
    }

    return (
      <JobFileRenderView
        fileName="Settings.json"
        content={settings}
        download={() =>
          downloadFile(prettyDownloadSetting(settings), "Settings.json")
        }
      />
    );
  },
);
