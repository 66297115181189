import { Field, Input, Switch, Textarea } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import {
  onPipelineNameChange,
  onPipelineSettingsChange,
} from "../../actions/productSettingsActions";
import {
  getCurrentDefaultSettings,
  getPipelineNameError,
  getPipelineSettingsError,
} from "../../selectors/productSelectors";
import { productSettingsStore } from "../../store/productSettingsStore";
import { useStyle } from "./sharedStyles";

export const ProductPipelineEditView = observer(() => {
  const styles = useStyle();
  const scenario = productSettingsStore.scenario;

  if (
    scenario.scenarioType !== "EditingProduct" ||
    scenario.currentPipeline === undefined
  ) {
    return <></>;
  }

  return (
    <div className={styles.card}>
      <Field
        required
        label={<div className={styles.fieldLabel}>Pipeline Name</div>}
        validationMessage={getPipelineNameError.get()}
        validationState={getPipelineNameError.get() ? "error" : undefined}
      >
        <Input
          className={styles.input}
          size="medium"
          value={scenario.currentPipeline.displayName}
          onChange={(_, data) => onPipelineNameChange.bind(null, data.value)()}
        />
      </Field>
      <Field
        required
        label={
          <div className={styles.switchRow}>
            <div className={styles.switchWrapper}>
              <Switch
                checked={
                  scenario.currentPipeline.settingsOverride !== undefined
                }
                onChange={(_, data) =>
                  onPipelineSettingsChange.bind(
                    null,
                    data.checked ? getCurrentDefaultSettings.get() : undefined,
                  )()
                }
              />
              <div className={styles.fieldLabel}>JSON Configuration</div>
            </div>
          </div>
        }
        validationMessage={getPipelineSettingsError.get()}
        validationState={getPipelineSettingsError.get() ? "error" : undefined}
      >
        <Textarea
          disabled={scenario.currentPipeline.settingsOverride === undefined}
          className={styles.textarea}
          appearance="outline"
          value={scenario.currentPipeline.settingsOverride ?? ""}
          onChange={(_, data) =>
            onPipelineSettingsChange.bind(null, data.value)()
          }
        />
      </Field>
    </div>
  );
});
