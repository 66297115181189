import type {
  IConversation,
  IFilteredSearch,
  IPluginReasoning,
  ISydneySearch,
} from "../bizChatEvalDataProvider";
import type {
  ICardJson,
  IIterationResult,
  ISearchServiceResponse,
  ISydneyResponse,
  PolymerLLMInput,
} from "./SydneyResponse";
import {
  get_plugins_for_reasoning_iteration,
  get_reasoning_logs,
  get_search_results_for_reasoning_iteration,
  get_web_results_for_reasoning_iteration,
} from "./parse_helper";

export function parse_business_sydney_response(response: ISydneyResponse) {
  let is_valid = false;
  const result: IConversation = {
    ConversationId: "",
    Time: "",
    Location: {},
    Human: "",
    completions: [],
    Iterations: [],
    SydneyReply: [],
    SydneySearches: [],
    SydneySuggestion: [],
    SydneyWebSearchReasonings: [],
    SydneyCitations: [],
    SydneyAdsQuery: [],
    status_code: 200,
  } as IConversation;
  const source_url_list = [];

  for (const message of response.messages || []) {
    if (message.sourceAttributions) {
      for (const entry of message.sourceAttributions) {
        if (entry.cardJson) {
          const cardJson = JSON.parse(entry.cardJson) as ICardJson;
          if (cardJson.appId && cardJson.content) {
            continue;
          }
        }
        source_url_list.push(entry.seeMoreUrl);
      }
    }

    if (message.author === "user") {
      result.Time = message.createdAt;
      result.Human = message.text;
    } else if (message.messageType) {
      if (
        message.messageType === "ChomskyFinalResponse" ||
        message.messageType === "Chat"
      ) {
        if (
          message.messageType === "Chat" &&
          message.adaptiveCards &&
          message.adaptiveCards[0].body &&
          message.adaptiveCards[0].body[0]
        ) {
          for (const bodyItem of message.adaptiveCards[0].body) {
            if (bodyItem.type && bodyItem.type === "TextBlock") {
              if (!bodyItem.id || bodyItem.id !== "AttributionsTextBlockID") {
                bodyItem.text && result.SydneyReply.push(bodyItem.text);
                break;
              }
            }
          }
        } else if (message.text) {
          result.SydneyReply.push(message.text);
        }
      }
      if (message.messageType === "AdsQuery" && message.text) {
        result.SydneyAdsQuery.push(message.text);
      }

      if (message.hiddenText) {
        if (message.messageType === "InternalSearchQuery") {
          result.SydneySearches.push({
            Query: message.hiddenText,
            SearchResults: "[]",
          });
        }
      }
      if (message.text) {
        if (message.messageType === "InternalSearchQuery") {
          result.SydneySearches.push({ Query: "", SearchResults: "" });
          result.SydneySearches[result.SydneySearches.length - 1].Query =
            message.text;
        }
        if (message.messageType === "Internal" && message.invocation) {
          result.SydneySearches[
            result.SydneySearches.length - 1
          ].SearchResults = message.text;
        }
      }
      if (message.suggestedResponses) {
        result.SydneySuggestion = message.suggestedResponses;
      }
    } else {
      if (
        message.adaptiveCards &&
        message.adaptiveCards[0] &&
        message.adaptiveCards[0].body
      ) {
        for (const bodyItem of message.adaptiveCards[0].body) {
          if (bodyItem.type === "TextBlock") {
            if (!bodyItem.id || bodyItem.id !== "AttributionsTextBlockID") {
              if (bodyItem.text) {
                result.SydneyReply.push(bodyItem.text);
              }
              break;
            }
          }
        }
      } else if (message.text) {
        result.SydneyReply.push(message.text);
      }

      if (message.suggestedResponses) {
        result.SydneySuggestion = message.suggestedResponses;
      }
    }

    for (const attribute of message.sourceAttributions || []) {
      if (!attribute.sourceType || attribute.sourceType === "CITATION") {
        result.SydneyCitations?.push(attribute);
      }
    }
  }

  const servicePromptDict: Record<string, string> = {};
  let filtered_search_results: IFilteredSearch[] = [];

  if (response.telemetry && response.telemetry.metrics) {
    for (const metric of response.telemetry.metrics) {
      if (metric.serviceName === "DeepLeo") {
        let prompt = "";
        try {
          prompt = (JSON.parse(metric.input) as PolymerLLMInput).prompt;
          const completion = metric.output;
          result.completions?.push({
            prompt: prompt,
            completion: completion,
            start_time: metric.startTime,
          });
        } catch (e) {
          continue;
        }

        if (
          !prompt.includes("[assistant](#suggestions)") &&
          !prompt.includes("## RECAP (Suggestions)")
        ) {
          servicePromptDict[metric.startTime] = prompt;
        }
      }
    }

    filtered_search_results = extract_filtered_search(servicePromptDict);

    result.ConversationId = response.conversationId || "";

    const sydneySearches = getSydneySearches(response);
    if (sydneySearches.length > 0) {
      result.SydneySearches = sydneySearches;
    }

    if (filtered_search_results.length > 0) {
      result.filtered_search = filtered_search_results;
    }

    const reasoning_iterations = get_reasoning_iterations(response);
    result.PluginReasoningIterations = reasoning_iterations.map((iteration) => {
      return {
        PluginsReasonings: Object.values(iteration).flatMap(
          (plugins) => plugins as IPluginReasoning[],
        ),
      };
    });

    if (result.SydneyReply.length > 0 && result.SydneySearches.length > 0) {
      is_valid = true;
    }

    return { result, is_valid };
  }
}

function get_reasoning_iterations(response: ISydneyResponse) {
  const max_index_telemetry_metrics = response.telemetry.metrics.length;
  const reasoning_logs = get_reasoning_logs(response);

  const iterations: Record<string, object>[] = [];
  for (let i = 0; i < reasoning_logs.length; i++) {
    const deep_leo_log = reasoning_logs[i];
    const { plugins_data } = get_plugins_for_reasoning_iteration(
      deep_leo_log.metric,
    );

    const iteration_start_index = deep_leo_log.index || 0;
    let iteration_end_index = 0;
    try {
      iteration_end_index =
        reasoning_logs[i + 1].index || max_index_telemetry_metrics;
    } catch {
      iteration_end_index = max_index_telemetry_metrics;
    }

    const search_results = get_search_results_for_reasoning_iteration(
      response,
      iteration_start_index,
      iteration_end_index,
    );

    const web_results = get_web_results_for_reasoning_iteration(
      response,
      iteration_start_index,
      iteration_end_index,
    );

    let iteration_result: IIterationResult = {};

    for (const [plugin_name, plugin_queries] of Object.entries(plugins_data)) {
      for (const [plugin_query, plugin_data] of Object.entries(
        plugin_queries,
      )) {
        if (!iteration_result[plugin_name]) {
          iteration_result[plugin_name] = {} as Record<string, object>;
        }
        iteration_result[plugin_name][plugin_query] = {
          ...plugin_data,
          ...(search_results[plugin_name]?.[plugin_query] || {}),
        };
      }
    }

    if (web_results) {
      delete iteration_result["search_web"];
      iteration_result = { ...iteration_result, ...web_results };
    }

    iterations[i] = {};
    for (const [plugin_name, plugin_results] of Object.entries(
      iteration_result,
    )) {
      iterations[i][plugin_name] = Object.values(plugin_results);
    }
  }

  return iterations;
}

function extract_filtered_search(servicePromptDict: Record<string, string>) {
  const servicePrompts: string[] = [];
  let responding_prompt = "";
  let prompt = "";
  for (const key of Object.keys(servicePromptDict).sort()) {
    if (
      servicePromptDict[key].includes(
        "## On my predefined tools to help me respond to the users:",
      ) ||
      servicePromptDict[key].includes(
        "## On my predefined internal tools which help me respond",
      )
    ) {
      responding_prompt = servicePromptDict[key];
      continue;
    }
    servicePrompts.push(servicePromptDict[key]);
  }
  if (responding_prompt !== "") {
    servicePrompts.push(responding_prompt);
  }
  if (servicePrompts.length > 0) {
    prompt = servicePrompts[servicePrompts.length - 1];
  } else {
    prompt = "";
  }

  let promptResults: IFilteredSearch[] = [];
  if (
    prompt.includes(
      "## On my predefined tools to help me respond to the users:",
    ) ||
    prompt.includes("## On my predefined internal tools which help me respond")
  ) {
    promptResults = extract_search_from_responding_prompt_flux(servicePrompts);
    const memoryResults = extract_turn_memory_from_prompt(
      servicePrompts,
    ) as IFilteredSearch[];
    if (memoryResults.length > promptResults.length) {
      promptResults = memoryResults;
    }
  } else {
    promptResults = extract_search_from_prompt(servicePrompts);
  }

  return promptResults;
}

function extractEmbeddedJson(obj: string | object) {
  return typeof obj === "string" ? JSON.parse(obj) : obj;
}

function getSydneySearches(response: ISydneyResponse) {
  const sydneySearches: ISydneySearch[] = [];
  if (response.telemetry && response.telemetry.metrics) {
    for (const metric of response.telemetry.metrics) {
      if (
        metric.serviceName &&
        metric.output &&
        metric.serviceName === "ExtensionRunner:ext:deep-leo"
      ) {
        for (const search_service_response of (extractEmbeddedJson(
          metric.output,
        ).responses as ISearchServiceResponse[]) || []) {
          if (
            search_service_response.messageType === "InternalSearchResult" ||
            search_service_response.messageType === "Internal"
          ) {
            const sydneySearch: ISydneySearch = {} as ISydneySearch;
            if (search_service_response.invocation) {
              sydneySearch.Query = search_service_response.invocation;
            }
            if (search_service_response.text) {
              sydneySearch.SearchResults = search_service_response.text;
            } else if (search_service_response.hiddenText) {
              sydneySearch.SearchResults = search_service_response.hiddenText;
            }
            if ("Query" in sydneySearch && "SearchResults" in sydneySearch) {
              sydneySearches.push(sydneySearch);
            }
          }
        }
      }
    }
  }
  return sydneySearches;
}

function extract_search_from_prompt(turn_prompts: string[]) {
  if (turn_prompts.length < 1) return [];
  const search_tag_escaped = "\\[assistant\\]\\(#search_results\\)";
  const front_tag = search_tag_escaped.replace("\\", "") + "\n```json";
  const system_tag = "\n[system](#context)\n";
  const search_format = `${search_tag_escaped}
\`\`\`json
.*
\`\`\``;
  const conversation =
    turn_prompts[turn_prompts.length - 1].split(system_tag)[1];

  const string_searches = [
    ...conversation.matchAll(new RegExp(search_format, "g")),
  ];

  const searches = [];
  for (const match of string_searches) {
    if (!match.index) continue;
    const search_string = conversation.slice(
      match.index,
      match.index + match[0].length,
    );
    const search_query = conversation
      .slice(0, match.index)
      .trim()
      .split("\n")
      .pop();
    try {
      const search = JSON.parse(search_string.replace(front_tag, "")) as Record<
        string,
        { index?: string }[]
      >;
      for (const result_type of Object.keys(search)) {
        for (const element of search[result_type]) {
          if ("index" in element) {
            delete element.index;
          }
        }
      }
      searches.push({
        tool_invocation: `search("${search_query}")`,
        result: JSON.stringify(search, undefined, 2),
      });
    } catch (e) {
      continue;
    }
  }

  return searches;
}

function extract_search_from_responding_prompt_flux(turn_prompts: string[]) {
  if (turn_prompts.length < 1) return [];
  const search_tag_escaped = "\\[assistant\\]\\(#[^\\(\\n]+\\([^\\)\\n]*\\)\\)";
  const system_tag = "- Time at the start of this conversation";

  const conversation = turn_prompts[turn_prompts.length - 1]
    ?.split(system_tag)
    ?.pop()
    ?.replace("[assistant]", "[assistant][assistant]");
  if (!conversation) return [];

  const searches: IFilteredSearch[] = [];
  for (const match of conversation.matchAll(
    new RegExp(search_tag_escaped, "g"),
  )) {
    try {
      if (!match.index) continue;
      const tool_invocation = conversation.slice(
        match.index,
        match.index + match[0].length,
      );

      let result = "";
      for (const line of conversation
        .slice(match.index + match[0].length + 1)
        .split("\n")) {
        if (
          line.slice(0, "[assistant]".length) !== "[assistant]" &&
          line.slice(0, "[user]".length) !== "[user]" &&
          line.slice(0, "<|im_end|>".length) !== "<|im_end|>" &&
          line.slice(0, "<|im_start|>".length) !== "<|im_start|>"
        ) {
          result += line + "\n";
        } else {
          break;
        }
      }
      result = result.trim();
      searches.push({
        tool_invocation: tool_invocation
          .replace("[assistant](", "")
          .replace(")", ""),
        result: result,
      });
    } catch (e) {
      continue;
    }
  }

  return searches;
}

function extract_turn_memory_from_prompt(turn_prompts: string[]) {
  if (turn_prompts.length < 2) return [];
  const front_tag = "turn_memory =";
  const turn_memorylist =
    turn_prompts[turn_prompts.length - 2].match(`${front_tag}.*`) || [];
  if (turn_memorylist.length < 1) return [];
  const turn_memory = turn_memorylist[turn_memorylist.length - 1];
  return JSON.parse(turn_memory.replace(front_tag, ""));
}
