import { ADDED_CLAIM_TURN_KEY } from "../groundleo/GroundLeoClaimBreakDefinition";

// extract column keys to be header of custom download feedback
export function getColumnKeys(simplifiedRows: object[]) {
  const columnKeys = new Set<string>();
  const addedClaimColumnKeys = new Set<string>();
  // merge all row keys
  for (const row of simplifiedRows) {
    for (const key of Object.keys(row)) {
      if (!columnKeys.has(key) && !addedClaimColumnKeys.has(key)) {
        if (key.startsWith("turn_" + ADDED_CLAIM_TURN_KEY)) {
          addedClaimColumnKeys.add(key);
        } else {
          columnKeys.add(key);
        }
      }
    }
  }

  return Array.from(new Set([...columnKeys, ...addedClaimColumnKeys])); //append added claim keys to the end
}
