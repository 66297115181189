import {
  Spinner,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import InfiniteScroll from "react-infinite-scroll-component";

const useStyles = makeStyles({
  tableContainer: {
    width: "100%",
    overflowX: "scroll",
    transform: "rotateX(180deg)",
    "&::-webkit-scrollbar": {
      height: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      ...shorthands.borderRadius("10px"),
      backgroundColor: "#d6dee1",
      boxShadow: " 0 4px 28px rgba(123,151,158,.25)",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#a8bbbf",
    },
  },
  table: {
    transform: "rotateX(180deg)",
    minWidth: "100%",
    display: "inline-block",
  },
  headerRow: {
    backgroundColor: "rgb(250, 249, 248)",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    ...shorthands.overflow("hidden"),
    ...shorthands.borderRadius("6px", "6px", "0px", "0px"),
    ...shorthands.borderTop("1px", "solid", "rgb(237, 235, 233)"),
    ...shorthands.borderLeft("1px", "solid", "rgb(237, 235, 233)"),
    ...shorthands.borderRight("1px", "solid", "rgb(237, 235, 233)"),
    ...shorthands.borderBottom(
      tokens.strokeWidthThin,
      "solid",
      tokens.colorNeutralStroke2,
    ),
  },
  bodyRow: {
    backgroundColor: "white",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    ...shorthands.borderLeft("1px", "solid", "rgb(237, 235, 233)"),
    ...shorthands.borderRight("1px", "solid", "rgb(237, 235, 233)"),
    ...shorthands.borderBottom(
      tokens.strokeWidthThin,
      "solid",
      tokens.colorNeutralStroke2,
    ),
  },
  td: {
    boxSizing: "border-box",
    height: "53px",
    display: "flex",
    justifyItems: "center",
  },
  th: {
    boxSizing: "border-box",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "18px",
    fontStyle: "normal",
  },
  contentWrapper: {
    ...shorthands.flex(1),
    boxSizing: "border-box",
    maxWidth: "100%",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  firstContentWrapper: {
    paddingLeft: "20px",
  },
  lastContentWrapper: {
    paddingRight: "20px",
  },
  spinner: {
    marginTop: "20px",
    height: "100px",
  },
});

type GeneralTableProps<T> = {
  loadMore: () => void;
  hasMore: boolean;
  rows: T[];
  columns: {
    title: string;
    render: (item: T) => JSX.Element;
    weight: number;
    minWidth: string;
    maxWidth?: string;
  }[];
};

export const GeneralTable = observer(
  <T extends object>(props: GeneralTableProps<T>) => {
    const { columns } = props;
    const styles = useStyles();

    const getContentWrapperStyles = (index: number) => {
      const wrapperStyles = [
        styles.contentWrapper,
        index === 0 ? styles.firstContentWrapper : undefined,
        index === columns.length - 1 ? styles.lastContentWrapper : undefined,
      ];

      return mergeClasses(...wrapperStyles);
    };

    return (
      <div className={styles.tableContainer}>
        <div className={styles.table}>
          <div className={styles.headerRow}>
            {columns.map((column, index) => (
              <div
                className={styles.th}
                key={index}
                style={{
                  flexGrow: column.weight,
                  flexShrink: column.weight,
                  flexBasis: 0,
                  minWidth: column.minWidth ?? "0px",
                  maxWidth: column.maxWidth,
                }}
              >
                <div className={getContentWrapperStyles(index)}>
                  {column.title}
                </div>
              </div>
            ))}
          </div>

          <InfiniteScroll
            scrollThreshold={"50px"}
            dataLength={props.rows.length}
            next={props.loadMore}
            hasMore={props.hasMore}
            loader={
              <div className={styles.spinner}>
                <Spinner />
              </div>
            }
            scrollableTarget={"mainViewScrollContainer"}
          >
            {props.rows.map((row, rowIndex) => (
              <div key={rowIndex} className={styles.bodyRow}>
                {columns.map((column, columnIndex) => (
                  <div
                    className={styles.td}
                    key={columnIndex}
                    style={{
                      flexGrow: column.weight,
                      flexShrink: column.weight,
                      flexBasis: 0,
                      minWidth: column.minWidth ?? "0px",
                      maxWidth: column.maxWidth,
                    }}
                  >
                    <div className={getContentWrapperStyles(columnIndex)}>
                      {column.render(row)}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  },
);
