import { MessageBar, MessageBarBody } from "@fluentui/react-components";
import JSON5 from "json5";
import { type FunctionComponent } from "react";
import type {
  ISydneySearchBaseSnippet,
  IWebSearchResult,
} from "../bizChatEvalDataProvider";
import { CollapsedResource } from "../sharedComponents/CollapsedResource";
import { ResourceCard } from "./ResourceCard";

interface QueryResultPairProps {
  snippets: SnippetProps[] | undefined;
  exceptionMessage?: string;
}

interface SnippetProps {
  source: "web" | "sydney" | "other";
  snippet: ISydneySearchBaseSnippet | IWebSearchResult | string;
  tags?: string[];
}

export function convertFilteredSearchSnippet(snippet: Record<string, unknown>) {
  const convertedSnippet: Record<string, unknown> = {};
  for (const key in snippet) {
    // key's first letter to upper case
    const convertedKey = key.charAt(0).toUpperCase() + key.slice(1);
    const snippetValue = snippet[key] as string;
    if (
      typeof snippetValue === "string" &&
      snippetValue.startsWith("{") &&
      snippetValue.endsWith("}")
    ) {
      try {
        convertedSnippet[convertedKey] = JSON5.parse(snippetValue);
      } catch (e) {
        convertedSnippet[convertedKey] = snippet[key];
      }
    } else {
      convertedSnippet[convertedKey] = snippet[key];
    }
  }
  return convertedSnippet;
}

export function extractQueryResultPair(
  searchResult: string,
): QueryResultPairProps {
  const modifiedItem =
    searchResult
      ?.replace(/None/g, "null")
      ?.replace(/False/g, "false")
      ?.replace(/True/g, "true") || "{}";
  let exceptionMessage = "";
  let parsedItem;
  let snippets: SnippetProps[] = [];
  if (modifiedItem.startsWith("{") && modifiedItem.endsWith("}")) {
    try {
      parsedItem = JSON5.parse(modifiedItem);

      for (const key in parsedItem) {
        if (key === "Enterprises" || key === "Enterprise") {
          for (const snippet of parsedItem[key] || []) {
            if (snippet) {
              snippets.push({
                source: "sydney",
                snippet: snippet as ISydneySearchBaseSnippet | string,
                tags: ["Enterprises"],
              });
            }
          }
        } else if (key === "results") {
          // For searches in prompt (filtered_search)
          for (const snippet of parsedItem[key] || []) {
            if (snippet) {
              const convertedSnippet = convertFilteredSearchSnippet(
                snippet as Record<string, unknown>,
              );
              snippets.push({
                source: "sydney",
                snippet:
                  convertedSnippet as unknown as ISydneySearchBaseSnippet,
                tags: ["results"],
              });
            }
          }
        } else {
          if (Array.isArray(parsedItem[key])) {
            for (const snippet of parsedItem[key] || []) {
              if (snippet) {
                snippets.push({
                  source: "web",
                  snippet: snippet as IWebSearchResult | string,
                  tags: [key],
                });
              }
            }
          } else {
            snippets.push({
              source: "other",
              snippet: JSON.stringify(parsedItem[key]),
              tags: [key],
            });
          }
        }
      }
    } catch (e: any) {
      exceptionMessage = `Failed to parse json. ${e.toString()}\nRaw data: ${modifiedItem}`;
    }
  } else {
    snippets = [{ source: "other", snippet: modifiedItem }];
  }

  return {
    snippets,
    exceptionMessage,
  };
}

export const QueryResultPair: FunctionComponent<{
  query: string;
  result: string;
  indexOffset?: number;
}> = ({ query, result, indexOffset }) => {
  const queryResultPair = extractQueryResultPair(result);
  const { snippets, exceptionMessage } = queryResultPair;

  return (
    <CollapsedResource
      title={`${query} (${snippets?.length || 0})`}
      bold={false}
    >
      <div style={{ width: "100%" }}>
        {exceptionMessage && (
          <MessageBar intent="error">
            <MessageBarBody>{exceptionMessage}</MessageBarBody>
          </MessageBar>
        )}
        {snippets?.map((snippet, i) => {
          return (
            <div style={{ margin: "1em" }} key={snippet.source + i}>
              <ResourceCard
                source={snippet.source}
                snippet={snippet.snippet}
                tags={snippet.tags}
                index={i + 1 + (indexOffset || 0)}
              ></ResourceCard>
            </div>
          );
        })}
      </div>
    </CollapsedResource>
  );
};
