import { observer } from "mobx-react-lite";
import type { FeatureSettingsDialogContributionProps } from "../../../../contribution/FeatureSettingsContribution";
import { getTokenName } from "../../../../helpers/accountHelper";
import { store } from "../../../../store/store";
import { GuidanceLinkSEVAL } from "../Other/GuidanceLink";
import { UploadRefreshTokenDialog } from "./UploadRefreshTokenDialog";

export const UploadSEVALRefreshTokenDialog = observer(
  (props: FeatureSettingsDialogContributionProps) => {
    const tokenName =
      getTokenName(store.account?.username ?? "", "seval") ?? "";
    return (
      <UploadRefreshTokenDialog
        tokenType={"SEVAL refresh token"}
        tokenName={tokenName}
        dialogTitle={"Upload SEVAL Refresh Token"}
        loggingSource={"UploadSEVALRefreshTokenDialog"}
        guidanceLink={<GuidanceLinkSEVAL />}
        {...props}
      />
    );
  },
);
