import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Textarea,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import { getTokenName } from "../../../../helpers/accountHelper";
import { setToken } from "../../../../helpers/apiHelper";
import { telemetryHelper } from "../../../../helpers/telemetryHelper";
import { GuidanceLink3S, GuidanceLinkTeamsCli } from "../Other/GuidanceLink";

type TokenUploadingDialogProps = {
  isOpen: boolean;
  email: string;
  shouldUploadSssToken: boolean;
  shouldUploadTeamsCliToken: boolean;
  onSuccess: () => void;
  onCancel: () => void;
};

const useStyles = makeStyles({
  dialog: {
    maxWidth: "672px",
  },
  dialogContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tokenTitle: {
    marginTop: "20px",
  },
  tokenInput: {
    backgroundColor: "#FAF9F8",
    ...shorthands.borderRadius("6px"),
    ...shorthands.border("1px", "solid", "#C8C6C4"),
  },
  footer: {
    color: "rgba(96, 94, 92, 1)",
  },
});

export const TokenUploadingDialog = observer(
  (props: TokenUploadingDialogProps) => {
    const styles = useStyles();
    const toast = useToast();

    const [sssToken, setSssToken] = useState<string>("");
    const [teamsCliToken, setTeamsCliToken] = useState<string>("");

    const [isSssTokenSet, setIsSssTokenSet] = useState(
      !props.shouldUploadSssToken,
    );
    const [isTeamsCliTokenSet, setIsTeamsCliTokenSet] = useState(
      !props.shouldUploadTeamsCliToken,
    );

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
      setSssToken("");
      setTeamsCliToken("");
      setIsSubmitting(false);
    }, [props.isOpen]);

    const preProcess = (
      tokenShortName: string,
      tokenValue: string,
    ): Promise<{ tokenName: string; token: string } | undefined> => {
      if (tokenValue === "") {
        return Promise.reject(new Error(`No ${tokenShortName} token found!`));
      }

      const tokenName = getTokenName(props.email, tokenShortName.toLowerCase());

      if (tokenName === undefined || tokenName === "") {
        return Promise.reject(
          new Error(`No ${tokenShortName} token name found!`),
        );
      }

      return Promise.resolve({
        tokenName,
        token: tokenValue,
      });
    };

    const onSubmit = async () => {
      toast.onToastStart("Uploading tokens...");
      setIsSubmitting(true);
      // These are to track verified status values in current rendering
      let isSssTokenSetLocal = isSssTokenSet;
      let isTeamsCliTokenSetLocal = isTeamsCliTokenSet;

      if (!isSssTokenSet) {
        try {
          const result = await preProcess("3S", sssToken);
          if (result != undefined) {
            await setToken({
              SecretName: result.tokenName,
              SecretValue: sssToken,
              TokenType: "3S refresh token",
            });
            setIsSssTokenSet(true);
            isSssTokenSetLocal = true;
          }
        } catch (error) {
          handleOnSubmitFailure("3S", error);
        }
      }

      if (!isTeamsCliTokenSet) {
        try {
          const result = await preProcess("TeamsCli", teamsCliToken);
          if (result != undefined) {
            await setToken({
              SecretName: result.tokenName,
              SecretValue: teamsCliToken,
              TokenType: "Teams CLI refresh token",
            });
            setIsTeamsCliTokenSet(true);
            isTeamsCliTokenSetLocal = true;
          }
        } catch (error) {
          handleOnSubmitFailure("TeamsCli", error);
        }
      }

      if (isSssTokenSetLocal && isTeamsCliTokenSetLocal) {
        props.onSuccess();
      }

      setIsSubmitting(false);
    };

    const handleOnSubmitFailure = (tokenShortName: string, error: unknown) => {
      const parsedError = (() => {
        if (error instanceof Error) {
          return error.message;
        } else {
          return JSON.stringify(error);
        }
      })();

      telemetryHelper.logUserActionEvent("SetTokenFailure", {
        inputType: "Refreshtoken",
        message: parsedError,
      });

      toast.onToastFailure(
        `${tokenShortName} token upload failed with message: ${parsedError}`,
      );
    };

    const tokenInputComponent = (tokenShortName: string) => {
      let tokenFullName;
      let token;
      let tokenGuidanceLink;
      let tokenStateSetter: (data: string) => void;

      if (tokenShortName == "3S") {
        tokenFullName = "Substrate Search Service";
        token = sssToken;
        tokenGuidanceLink = <GuidanceLink3S />;
        tokenStateSetter = setSssToken;
      } else {
        tokenFullName = "Teams Command Line Interface";
        token = teamsCliToken;
        tokenGuidanceLink = <GuidanceLinkTeamsCli />;
        tokenStateSetter = setTeamsCliToken;
      }

      return (
        <div>
          <br />
          <Body1>
            {`Please set up the ${tokenShortName} (${tokenFullName}) tokens of this email to ensure a safe and seamless experience.`}
          </Body1>

          <Field
            required
            className={styles.tokenTitle}
            label={
              <Body1 className={styles.tokenTitle}>
                {`Please provide the ${tokenShortName} refresh token of ${props.email}:`}
              </Body1>
            }
            validationMessage={
              token.trim() === ""
                ? `${tokenShortName} refresh token can not be empty`
                : undefined
            }
          >
            <Textarea
              disabled={isSubmitting}
              size="large"
              className={styles.tokenInput}
              onChange={(_, data) => {
                tokenStateSetter(data.value);
              }}
            />
          </Field>
          <div className={styles.tokenTitle}>
            <Body1 className={styles.footer}>
              {`${tokenShortName} Refresh Token will be stored as secret with name <username>-<tokentype>-token in Azure Key Vault. For the set up guidance, please check `}
            </Body1>
            <Body1 className={styles.footer}>{", "}</Body1>
            {tokenGuidanceLink}
            <Body1 className={styles.footer}>{"."}</Body1>
          </div>
        </div>
      );
    };

    const disableUploadAndCreate =
      (!isSssTokenSet && sssToken.trim() === "") ||
      (!isTeamsCliTokenSet && teamsCliToken.trim() === "") ||
      isSubmitting;

    return (
      <Dialog
        modalType="alert"
        open={props.isOpen}
        onOpenChange={() => {
          props.onCancel();
        }}
      >
        <DialogSurface className={styles.dialog}>
          <DialogBody>
            <DialogTitle>{"Onboarding Setup"}</DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <Body1>
                {`You are trying to create a job with email `}
                <strong>{props.email}</strong>
                {`, but the related tokens are not set up yet.`}
              </Body1>
              {!isSssTokenSet && tokenInputComponent("3S")}
              {!isTeamsCliTokenSet && tokenInputComponent("TeamsCli")}
            </DialogContent>

            <DialogActions>
              <Button
                style={{ width: "200px" }}
                onClick={onSubmit}
                disabled={disableUploadAndCreate}
                appearance="primary"
              >
                Upload Token And Create
              </Button>
              <Button onClick={props.onCancel} appearance="secondary">
                Cancel
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  },
);
