import {
  ErrorCode,
  constStr,
  object,
  optional,
  str,
  unknown,
} from "sydneyeval-shared";

export const ApiErrorResponse = optional(
  object({
    status: constStr("error"),
    message: optional(str),
    code: ErrorCode,
  }),
);

export const ApiSuccessResponse = object({
  status: constStr("success"),
  data: unknown,
});
