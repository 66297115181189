import { constStr, num, object, optional, or, str } from "sydneyeval-shared";

export const QuerySetFile = object({
  name: str,
  createdOn: optional(str),
  lastModified: optional(str),
  status: optional(str),
  id: optional(num),
  type: optional(or([constStr("prod"), constStr("staging")])),
});

export type QuerySetFile = ReturnType<typeof QuerySetFile>;
