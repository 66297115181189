"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServerFeatureFlagConfig = exports.ServerFeatureFlagDefaults = exports.ServerFeatureFlagSets = void 0;
const typeHelper_1 = require("../tools/typeHelper");
exports.ServerFeatureFlagSets = (0, typeHelper_1.object)({
    "redact-secret": (0, typeHelper_1.optional)(typeHelper_1.bool),
    "enable-mise": (0, typeHelper_1.optional)(typeHelper_1.bool),
    "bot-framework": (0, typeHelper_1.optional)(typeHelper_1.bool),
});
exports.ServerFeatureFlagDefaults = {
    "redact-secret": false,
    "enable-mise": false,
    "bot-framework": false,
};
exports.ServerFeatureFlagConfig = (0, typeHelper_1.object)({
    seval: (0, typeHelper_1.object)({
        server: exports.ServerFeatureFlagSets,
    }),
});
