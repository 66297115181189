export function getFileExtension(filename?: string): string {
  if (!filename) {
    return "";
  }

  const parts = filename.split(".");
  if (parts.length > 1) {
    return parts.pop()?.toLowerCase() || "";
  }
  return "";
}
