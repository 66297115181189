export const schedulerNameTip = `Scheduler name should be unique. ` as const;
export const referJobTip =
  "Only your own completed jobs can be used for regular runs." as const;
export const startTimeTip =
  "If the start time is in the past, the scheduler kicks off instantly once successfully created." as const;

export const emailListTip =
  `Add user email address in this list to grant editing access; separating each with a comma.` as const;
export const intervalTip =
  `The interval should range from 24 to 8760 hours.` as const;

export const quotaLimitTip = `Each user can have up to 6 schedulers.` as const;

export const minInterval = 24 as const;

export const maxInterval = 8760 as const;

export const minIntervalForSchedulerManager = 2 as const;

export const quatoExceedTip =
  `You've reached your limit of 6 active schedulers. Please pause or delete one before activating a new one.` as const;

export const maxActiveScheduler = 6 as const;
