"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScheduledJobResponse = exports.ScheduledJob = void 0;
const typeHelper_1 = require("../tools/typeHelper");
// https://msasg.visualstudio.com/WIT%20Engineering/_git/SydneyEvaluation?path=/Enterprise/src/CompliantSydneyEval/dbo/Tables/ScheduledJobs.sql
exports.ScheduledJob = (0, typeHelper_1.object)({
    Id: typeHelper_1.num,
    Name: typeHelper_1.str,
    Settings: typeHelper_1.str,
    Status: typeHelper_1.str,
    IntervalInHours: typeHelper_1.num,
    StartTime: typeHelper_1.str,
    EndTime: (0, typeHelper_1.optional)(typeHelper_1.str),
    LastExecuteTime: (0, typeHelper_1.optional)(typeHelper_1.str),
    LastUpdateTime: (0, typeHelper_1.optional)(typeHelper_1.str),
    LastUpdateBy: (0, typeHelper_1.optional)(typeHelper_1.str),
    CreatedBy: (0, typeHelper_1.optional)(typeHelper_1.str),
    CreatedAt: (0, typeHelper_1.optional)(typeHelper_1.str),
    TemplateName: (0, typeHelper_1.optional)(typeHelper_1.str),
    Type: (0, typeHelper_1.optional)(typeHelper_1.str),
    EmailSubscriptionList: (0, typeHelper_1.optional)(typeHelper_1.str),
    Message: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.ScheduledJobResponse = (0, typeHelper_1.array)(exports.ScheduledJob);
