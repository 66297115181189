import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useSharedTabStyles = makeStyles({
  tab: {
    height: "45px",
    backgroundColor: "transparent",
    position: "relative",
    ...shorthands.border("none"),
    ":hover": {
      backgroundColor: "transparent",
    },
  },
  activeTab: {
    "::after": {
      content: "''",
      position: "absolute",
      bottom: "0px",
      left: "0px",
      right: "0px",
      width: "100%",
      height: "3px",
      backgroundColor: tokens.colorBrandBackground,
      transform: "matrix(1, 0, 0, 1, 0, 0)",
      transformOrigin: "0px 1.5px",
    },
  },
  inactiveTab: {
    ":hover:after": {
      content: "''",
      position: "absolute",
      bottom: "0px",
      left: "0px",
      width: "100%",
      height: "3px",
      backgroundColor: tokens.colorNeutralStroke1Hover,
      transform: "matrix(1, 0, 0, 1, 0, 0)",
      transformOrigin: "0px 1.5px",
    },
  },
});
