import {
  anyObject,
  array,
  mapOf,
  nil,
  num,
  object,
  optional,
  or,
  str,
} from "sydneyeval-shared";

const BingDiagnosisSummary_Experiment = object({
  sbs_views: array(str),
});

const BingDiagnosisSummary_Segment = mapOf(BingDiagnosisSummary_Experiment);

const BingDiagnosisSummary_Metric = mapOf(BingDiagnosisSummary_Segment);

export const BingDiagnosisSummary = mapOf(BingDiagnosisSummary_Metric);

export type BingDiagnosisSummary = ReturnType<typeof BingDiagnosisSummary>;

const FilteredSearch = object({
  tool_invocation: optional(str),
  result: optional(str),
  source: optional(num),
});

export const UtteranceResult = object({
  Request: str,
  Response: str,
  "Filtered Search": array(FilteredSearch),
  ConversationId: optional(str),
});

const MetricReasoning = object({
  Reasoning: optional(or([anyObject, str, nil])),
  Score: or([num, nil]),
});

export const BingDiagnosisMetricReasoningGroup = object({
  treatment: or([MetricReasoning, nil]),
  baseline: MetricReasoning,
  delta: or([num, nil]),
});

export const BingDiagnosisMetricsData = mapOf(
  or([BingDiagnosisMetricReasoningGroup, array(UtteranceResult), nil]),
);
export const BingDiagnosisSBSView = object({
  treatment: or([array(UtteranceResult), nil]),
  baseline: array(UtteranceResult),
});

export type BingDiagnosisMetricsData = ReturnType<
  typeof BingDiagnosisMetricsData
>;
export type BingDiagnosisSBSView = ReturnType<typeof BingDiagnosisSBSView>;

export type BingDiagnosisLoadedItem = {
  Key: string;
  Conversation: string;
  Metrics: BingDiagnosisMetricsData;
};

export type BingDiagnosisMetricReasoningGroup = ReturnType<
  typeof BingDiagnosisMetricReasoningGroup
>;

export type BingDiagnosisUtteranceResult = ReturnType<typeof UtteranceResult>;
