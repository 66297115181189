import { Spinner } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { productSettingsStore } from "../../store/productSettingsStore";
import { ProductCreateView } from "./ProductCreateView";
import { ProductEditView } from "./ProductEditView";
import { ProductErrorView } from "./ProductErrorView";
import { ProductListView } from "./ProductListView";
import { ProductPipelineEditView } from "./ProductPipelineEditView";
import { useStyle } from "./sharedStyles";

const renderContent = () => {
  switch (productSettingsStore.scenario.scenarioType) {
    case "Loading":
      return <Spinner />;
    case "Listing":
      return <ProductListView />;
    case "Creating":
      return <ProductCreateView />;
    case "EditingProduct":
      return (
        <>
          <ProductEditView />
          <ProductPipelineEditView />
        </>
      );
    case "Error":
      return <ProductErrorView />;
  }
};

export const ProductContentView = observer(() => {
  const styles = useStyle();
  return <div className={styles.contentContainer}>{renderContent()}</div>;
});
