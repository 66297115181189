import { orchestrator } from "satcheljs";
import {
  consentGrantedAction,
  getConsentStatusAction,
  revokeConsentAction,
} from "../actions/consentAction";
import { CONSENT_VERSION } from "../constants";
import { getConsent, setConsent } from "../helpers/apiHelper";
import { perfWrapper } from "../helpers/telemetryHelper";
import { updateConsentStatus } from "../mutators/updateConsentStatus";
import { store } from "../store/store";

orchestrator(consentGrantedAction, () => {
  const userId = store.account?.localAccountId;

  if (!userId) {
    return;
  }

  setConsent({ User: userId, Consent: { Version: CONSENT_VERSION } }).then(
    () => {
      updateConsentStatus(CONSENT_VERSION);
    },
  );
});

orchestrator(getConsentStatusAction, () => {
  const userId = store.account?.localAccountId;

  if (!userId) {
    return;
  }

  perfWrapper(
    "LoadUserConsentStatus",
    getConsent({ User: userId }).then((consent) => {
      updateConsentStatus(consent.Version);
    }),
  );
});

orchestrator(revokeConsentAction, () => {
  const userId = store.account?.localAccountId;
  const version = 0;

  if (!userId) {
    return;
  }

  setConsent({ User: userId, Consent: { Version: version } });
});
