import type { UserProfile } from "sydneyeval-shared";
import { isVendorUser } from "sydneyeval-shared";
import { searchUserSelfProfile } from "../../../helpers/apiHelper";
import { getUserAlias } from "../../HumanCorrelationStudy/components/utils/utilities";

export const getUserProfileResponse = () => {
  const invalidResponse: UserProfile = {
    jobTitle: "N/A",
    level: "N/A",
    department: "N/A",
  };
  if (isVendorUser(getUserAlias())) {
    return Promise.resolve<UserProfile>(invalidResponse);
  }

  return searchUserSelfProfile().catch(() => {
    return invalidResponse;
  });
};
