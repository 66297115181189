import { Checkbox, Field } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { BizChatMetricsKeys } from "sydneyeval-shared";
import { ResponsiveRow } from "../../../../../components/Responsive/ResponsiveRow";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { getBizChatMetrics } from "../../../selectors/getJobPropV2";
import { useStyles } from "./JobBlockView.styles";

export const JobMetricsBlockView = observer(() => {
  const styles = useStyles();

  const metrics = getBizChatMetrics.get();

  const metricsArray = BizChatMetricsKeys.map((key) => {
    return {
      key,
      value: metrics[key],
    };
  }).filter((item) => item.value !== undefined);

  if (metricsArray.length === 0) {
    return null;
  }

  return (
    <div className={styles.block}>
      <Field
        label={
          <>
            <div className={styles.blockTitle}>Metrics</div>
          </>
        }
      >
        <div className={styles.metricsContainer}>
          <ResponsiveRow maxColumnCount={4} maxColumnCountSmall={2}>
            {metricsArray.map((metric) => {
              return (
                <div key={metric.key} className={styles.checkboxContainer}>
                  <Checkbox
                    className={styles.checkbox}
                    id={metric.key}
                    key={metric.key}
                    checked={metric.value}
                    onChange={(event) => {
                      const checked = event.target.checked;
                      updatePropValueActionV2({
                        prop: `configs_in_json.${metric.key}`,
                        newData: checked,
                      });
                    }}
                    label={metric.key}
                  />
                </div>
              );
            })}
          </ResponsiveRow>
        </div>
      </Field>
    </div>
  );
});
