import { mutatorAction } from "satcheljs";
import type { BingMetaDataResponse } from "sydneyeval-shared";
import {
  initJobCreationStore,
  jobCreationStore,
} from "../store/jobCreationStore";

export const resetJobCreationStore = mutatorAction(
  "resetJobCreationStore",
  () => {
    Object.assign(jobCreationStore, {
      ...initJobCreationStore,
    });
  },
);

export const updateStatusIsLoading = mutatorAction(
  "updateStatusIsLoading",
  (isLoading: boolean) => {
    jobCreationStore.isLoading = isLoading;
  },
);

export const updateStatusIsLoadingContentView = mutatorAction(
  "updateStatusIsLoadingContentView",
  (isLoading: boolean) => {
    jobCreationStore.isLoadingContentView = isLoading;
  },
);

export const updateStatusIsSubmitting = mutatorAction(
  "updateStatusIsSubmitting",
  (isSubmitting: boolean) => {
    jobCreationStore.isSubmitting = isSubmitting;
  },
);

export const updateStatusIsVerifying = mutatorAction(
  "updateStatusIsVerifying",
  (isVerifying: boolean) => {
    jobCreationStore.isVerifying = isVerifying;
  },
);

export const updateStatusIsTokenDialogOpen = mutatorAction(
  "updateStatusIsTokenDialogOpen",
  (isOpen: boolean) => {
    jobCreationStore.isTokenDialogOpen = isOpen;
  },
);

export const updateTokenDialogEmail = mutatorAction(
  "updateTokenDialogEmail",
  (content: string) => {
    jobCreationStore.tokenDialogEmail = content;
  },
);

export const updateStatusSubstrateToken = mutatorAction(
  "updateStatusSubstrateToken",
  (token: boolean) => {
    jobCreationStore.substrateTokenVerifyFailed = token;
  },
);

export const updateStatusTeamsCliToken = mutatorAction(
  "updateStatusTeamsCliToken",
  (token: boolean) => {
    jobCreationStore.teamsCliTokenVerifyFailed = token;
  },
);

export const updateBingMetaData = mutatorAction(
  "updateBingMetaData",
  (bingMetaData: BingMetaDataResponse | undefined) => {
    jobCreationStore.bingMetaData = bingMetaData;
  },
);

export const updateIsDispatchedErrorToast = mutatorAction(
  "updateIsDispatchedErrorToast",
  (isDispatched: boolean) => {
    jobCreationStore.isDispatchedErrorToast = isDispatched;
  },
);

export const updateUserUploadedTestSet = mutatorAction(
  "updateUserUploadedTestSet",
  (list: string[]) => {
    jobCreationStore.userUploadedSets = list;
  },
);

export const updateIsPreValidating = mutatorAction(
  "updateUserUploadedTestSet",
  (isPreValidating: boolean) => {
    jobCreationStore.isPreValidating = isPreValidating;
  },
);

export const updateIsOpenProductSelectDialog = mutatorAction(
  "updateIsOpenProductSelectDialog",
  (isOpen: boolean) => {
    jobCreationStore.isOpenProductSelectDialog = isOpen;
  },
);
