import {
  Button,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Eye16Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react";
import { Tip } from "../../../../../components/Shared/Tip";
import { getAppEnv } from "../../../../../helpers/appEnvHelper";
import type { Job } from "../../../models/Job";
import { updateJobIdToJob } from "../../../mutators/updateCaches";

type JobMetricsCellProps = {
  job: Job;
};

const useStyles = makeStyles({
  button: {
    ...shorthands.borderRadius("4px"),
    ...shorthands.padding("2px", "8px"),
    ...shorthands.border("1px", "solid", "#D1D1D1"),
    height: "24px",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    fontStyle: "normal",
  },
});

export const JobMetricsCell = observer((props: JobMetricsCellProps) => {
  const { job } = props;
  const styles = useStyles();

  if (job.HasMetrics === undefined || job.HasMetrics === false) {
    return <></>;
  }

  const hasPermission = job.Properties?.HasReadPermission === true;

  const buttonElement = (
    <Button
      disabled={!hasPermission}
      aria-label="View metrics"
      className={styles.button}
      size="small"
      onClick={() => {
        updateJobIdToJob(job.ID, job);
        window.open(getAppEnv().webEndpoint + `/job/${job.ID}`, "_blank");
      }}
      icon={<Eye16Regular />}
    >
      Metrics
    </Button>
  );

  return hasPermission ? (
    buttonElement
  ) : (
    <Tip
      content={"You don't have permission, please ask job owner."}
      relationship="label"
    >
      {buttonElement}
    </Tip>
  );
});
