import {
  Body1,
  Button,
  MessageBar,
  Spinner,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import {
  ArrowLeft24Filled,
  ArrowLeft24Regular,
  ChevronDown12Filled,
  ChevronDown12Regular,
  ChevronUp12Filled,
  ChevronUp12Regular,
  Copy24Filled,
  Copy24Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import type { MetricJudgementRecord } from "sydneyeval-shared";
import {
  constStr,
  getConversationHistory,
  getTheLastQueryInMultiturnMode,
  or,
} from "sydneyeval-shared";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { getDetailsByJobId } from "../../../../../helpers/apiHelper";
import { perfWrapper } from "../../../../../helpers/telemetryHelper";
import { closeSidePane } from "../../../../../mutators/sidePaneMutators";
import { TreeComponent } from "./TreeComponent";

export const MetricJudgementViewMetricKey = or([
  constStr("groundleo"),
  constStr("tcr"),
  constStr("stewieleo"),
  constStr("stewieleosbs"),
  constStr("sbsleo"),
  constStr("sbsleov2"),
  constStr("reasoningleo"),
  constStr("pileo"),
  constStr("contextleo"),
  constStr("opgsummleo"),
  constStr("groundleo_scorebreak"),
  constStr("teamsgroundleo"),
  constStr("codeleo"),
  constStr("scleo"),
]);

export type MetricJudgementViewMetricKey = ReturnType<
  typeof MetricJudgementViewMetricKey
>;

type MetricJudgementViewProps = {
  jobId: string;
  jobCreator: string | undefined;
  metricKey: MetricJudgementViewMetricKey;
  query: string;
  jobIdForShare?: string;
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleRowLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  backButton: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  title: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
    ...shorthands.margin("0", "0", "0", "9px"),
  },
  utteranceRow: {
    marginTop: "37px",
    display: "flex",
    flexDirection: "column",
  },
  utteranceTitle: {
    color: "#1B1A19",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  utteranceContent: {
    color: "#000",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  details: {
    marginTop: "12px",
    ...shorthands.borderRadius("6px"),
    ...shorthands.border("1px", "solid", "#EDEBE9"),
    ...shorthands.overflow("hidden"),
    backgroundColor: "#FFF",
    "& .fui-TreeItemLayout__main": {
      boxSizing: "border-box",
      maxWidth: "100%",
      lineHeight: "20px",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      color: "#11100F",
    },
  },
  detailsTitle: {
    height: "32px",
    backgroundColor: "#EFF6FC",
    ...shorthands.padding("0", "16px"),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("8px"),
  },
  detailsTitleContent: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32px",
  },
  spinner: {
    marginTop: "20px",
  },
});

const BackIcon = bundleIcon(ArrowLeft24Filled, ArrowLeft24Regular);
const ArrowUpIcon = bundleIcon(ChevronUp12Filled, ChevronUp12Regular);
const ArrowDownIcon = bundleIcon(ChevronDown12Filled, ChevronDown12Regular);
const CopyLinkIcon = bundleIcon(Copy24Filled, Copy24Regular);

export const MetricJudgementView = observer(
  (props: MetricJudgementViewProps) => {
    const styles = useStyles();
    const toaster = useToast();
    const [noRecords, setNoRecords] = React.useState<boolean>(false);
    const [isDataError, setDataError] = React.useState<boolean>(false);
    const displayQuery =
      getTheLastQueryInMultiturnMode(props.query) ?? props.query;
    const conversationHistory = getConversationHistory(props.query);

    useEffect(() => {
      setRecords([]);
      setNoRecords(false);
      perfWrapper(
        "LoadMChatMetricjudgement",
        getDetailsByJobId({
          JobId: props.jobId,
          JobCreator: props.jobCreator ?? "",
          Query: props.query,
          MetricKey: props.metricKey,
        })
          .then((data) => {
            // if multiTurn, then add conversationHistory to the data content
            if (
              conversationHistory !== undefined &&
              conversationHistory !== "" &&
              conversationHistory !== "[]"
            ) {
              data.forEach((record) => {
                if (record.type === "Score") {
                  record.content =
                    record.content === undefined
                      ? undefined
                      : {
                          ...record.content,
                          ConversationHistory: conversationHistory,
                        };
                }
              });
            }
            if (data.length === 0) {
              setNoRecords(true);
              return;
            }
            setRecords(data);
          })
          .catch((e) => {
            setDataError(true);
            throw e;
          }),
      );
    }, [props.jobId, props.query, props.metricKey]);

    const [records, setRecords] = React.useState<MetricJudgementRecord[]>([]);
    const [isExpanded, setIsExpanded] = React.useState<Map<string, boolean>>(
      new Map(),
    );

    return (
      <div className={styles.root}>
        <div className={styles.titleRow}>
          <div className={styles.titleRowLeft}>
            <div
              className={styles.backButton}
              role="button"
              aria-label="Back Button"
              onClick={() => {
                closeSidePane();
              }}
            >
              <BackIcon />
            </div>
            <Body1
              className={styles.title}
            >{`Metric judgement (${props.metricKey})`}</Body1>
          </div>

          <Button
            data-testid="copy-link-button"
            size={"medium"}
            onClick={() => {
              if (props.jobIdForShare !== undefined) {
                toaster.onToastStart("Copying the link to clipboard...");
                const url = new URL(window.location.href);
                const shareLink = `${url.protocol}//${url.host}/detail/${
                  props.jobIdForShare
                }/${encodeURIComponent("Metric Diagnosis")}/${
                  props.metricKey
                }/${encodeURIComponent(displayQuery)}`;
                navigator.clipboard.writeText(shareLink);
                toaster.onToastSuccess(
                  "The link has been copied to your clipboard.",
                );
              }
            }}
            icon={<CopyLinkIcon />}
          >
            Copy Link
          </Button>
        </div>
        <div className={styles.utteranceRow}>
          <Body1 className={styles.utteranceTitle}>Utterance:</Body1>
          <Body1 className={styles.utteranceContent}>{displayQuery}</Body1>
        </div>
        {isDataError && (
          <MessageBar intent="error">Error found in data!</MessageBar>
        )}
        {!isDataError && noRecords && <h2>No records found!</h2>}
        {!isDataError && !noRecords && records.length === 0 && (
          <Spinner className={styles.spinner} />
        )}
        {records.length !== 0 &&
          records.map((record) => {
            const recordKey = `${record.exp}-${record.type}`;
            switch (record.type) {
              case "Score":
                if (record.content === undefined) {
                  return <React.Fragment key={recordKey}></React.Fragment>;
                }

                return (
                  <div className={styles.details} key={recordKey}>
                    <div className={styles.detailsTitle}>
                      <Body1 className={styles.detailsTitleContent}>
                        {`${record.exp} conversation`}
                      </Body1>
                    </div>
                    <TreeComponent
                      content={record.content}
                      parent={`${record.exp} conversation`}
                    />
                  </div>
                );

              case "Reasoning":
                if (record.content === undefined || record.content === "") {
                  return <React.Fragment key={recordKey}></React.Fragment>;
                }

                return (
                  <div className={styles.details} key={recordKey}>
                    <div
                      className={styles.detailsTitle}
                      onClick={() => {
                        const isExpandedKey = `${record.type}-${record.exp}`;
                        const newIsExpanded = new Map(isExpanded);
                        newIsExpanded.set(
                          isExpandedKey,
                          !isExpanded.get(isExpandedKey),
                        );
                        setIsExpanded(newIsExpanded);
                      }}
                    >
                      {isExpanded.get(`${record.type}-${record.exp}`) ? (
                        <ArrowDownIcon />
                      ) : (
                        <ArrowUpIcon />
                      )}
                      <Body1 className={styles.detailsTitleContent}>
                        {`${record.exp} reasoning`}
                      </Body1>
                    </div>

                    {isExpanded.get(`${record.type}-${record.exp}`) && (
                      <TreeComponent
                        content={record.content}
                        parent={`${record.exp} reasoning`}
                      />
                    )}
                  </div>
                );
            }
          })}
      </div>
    );
  },
);
