import {
  Field,
  InfoLabel,
  Input,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { ResponsiveRow } from "../../../../../components/Responsive/ResponsiveRow";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { getJobErrorByPath } from "../../../selectors/creationError/getJobCreationError";
import { getValue } from "../../../selectors/getJobPropV2";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    columnWithSmallerGap: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      ...shorthands.gap("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    experimentationFieldContainer: {
      display: "flex",
      flexDirection: "row",
      ...shorthands.gap("8px"),
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    experimentationFieldTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      width: "110px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobSimplifyPairExperimentationView = observer(() => {
  const styles = useStyles();
  const experiments = getValue("configs_in_json.conversations.exp_configs");

  if (experiments === undefined || experiments.length !== 2) {
    return <></>;
  }

  const controlError = getJobErrorByPath(
    "configs_in_json.conversations.exp_configs[0].sydney.variants",
  );
  const treatError = getJobErrorByPath(
    "configs_in_json.conversations.exp_configs[1].sydney.variants",
  );

  return (
    <ResponsiveRow
      maxColumnCount={2}
      maxColumnCountSmall={1}
      columnGap={32}
      rowGap={20}
    >
      <div className={styles.block}>
        <div className={styles.blockTitle}>{experiments[0].exp_name}</div>

        <div className={styles.columnWithSmallerGap}>
          <div className={styles.experimentationFieldContainer}>
            <InfoLabel
              className={styles.experimentationFieldTitle}
              info={"The feature variants of the flight."}
            >
              Variants:
            </InfoLabel>
            <Field
              style={{ flexGrow: 1 }}
              validationState={controlError === undefined ? "none" : "error"}
              validationMessage={controlError?.message}
            >
              <Input
                aria-label="The feature variants of the flight."
                size="medium"
                value={experiments[0]?.sydney?.variants}
                onChange={(_, data) => {
                  updatePropValueActionV2({
                    prop: "configs_in_json.conversations.exp_configs[0].sydney.variants",
                    newData: data.value,
                  });
                }}
              />
            </Field>
          </div>
        </div>
      </div>
      <div className={styles.block}>
        <div className={styles.blockTitle}>{experiments[1].exp_name}</div>

        <div className={styles.columnWithSmallerGap}>
          <div className={styles.experimentationFieldContainer}>
            <InfoLabel
              className={styles.experimentationFieldTitle}
              info={"The feature variants of the flight."}
            >
              Variants:
            </InfoLabel>
            <Field
              style={{ flexGrow: 1 }}
              validationState={treatError === undefined ? "none" : "error"}
              validationMessage={treatError?.message}
            >
              <Input
                aria-label="The feature variants of the flight."
                size="medium"
                value={experiments[1]?.sydney?.variants}
                onChange={(_, data) => {
                  updatePropValueActionV2({
                    prop: "configs_in_json.conversations.exp_configs[1].sydney.variants",
                    newData: data.value,
                  });
                }}
              />
            </Field>
          </div>
        </div>
      </div>
    </ResponsiveRow>
  );
});
