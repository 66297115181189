import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import {
  MoreHorizontal16Filled,
  MoreHorizontal16Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import clipboardCopy from "clipboard-copy";
import { observer } from "mobx-react";
import React from "react";
import { isValidSettings, parseJsonStrOptional } from "sydneyeval-shared";
import { useScrollContext } from "../../../../../components/Wrappers/ScrollerContainer";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { deprecatedUrlPathPrefixForBing } from "../../../../../constants/constants";
import {
  cancelJob,
  createJob,
  rerunJob,
} from "../../../../../helpers/apiHelper";
import { getAppEnv } from "../../../../../helpers/appEnvHelper";
import { telemetryHelper } from "../../../../../helpers/telemetryHelper";
import { updateCurrentPath } from "../../../../../mutators/updateContributions";
import { isFeatureEnabled } from "../../../../../selectors/features";
import { store } from "../../../../../store/store";
import { openJobShareDialog } from "../../../actions/jobShareActions";
import { updatedUrl_bingJobConfig } from "../../../helpers/bingFileInBlobHelper";
import { BingConfigFileSchema } from "../../../models/BingJobConfig";
import type { Job } from "../../../models/Job";
import type { JobTemplate } from "../../../models/JobTemplate";
import { updateResultJob } from "../../../mutators/jobResultMutators";
import { setIsOpenShareDialog } from "../../../mutators/jobShareMutators";
import { quickCreateScheduleJob } from "../../../selectors/scheduleJobSelector";

const MoreIcon = bundleIcon(MoreHorizontal16Filled, MoreHorizontal16Regular);

type JobActionsCellProps = {
  item: Job;
  jobTemplates: JobTemplate[];
  onJobSettingsClick: () => void;
  onRefreshJobList: () => void;
};

export const JobActionsCell = observer((props: JobActionsCellProps) => {
  const { item, jobTemplates } = props;
  const toast = useToast();
  // Only owner can see the cancel/rerun button.
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const scrollContext = useScrollContext();

  React.useEffect(() => {
    if (!scrollContext.canShowTips) {
      setIsMenuOpen(false);
    }
  }, [scrollContext.canShowTips]);

  const hasPermission = item.Properties?.HasReadPermission === true;

  const relatedTemplate = jobTemplates.find(
    (template) => template.Name === item.ExperimentName,
  );

  const isSchemaValid = isValidSettings(
    item.Settings,
    relatedTemplate?.SettingsSchema,
  );

  const isRetryJob = () => {
    const oldSettings = parseJsonStrOptional(
      item.Settings,
      BingConfigFileSchema,
    );

    if (oldSettings === undefined) {
      return item.JobName?.endsWith("retry");
    }

    return oldSettings?.resume_from !== undefined;
  };

  const hasBingOperateAccess =
    item.Properties?.IsOwner === true || store.permission?.isBingAdmin === true;

  const isScheduledJob = item.JobType === "Scheduled";
  const isOwner =
    item.Properties?.IsOwner === true ||
    (item.Properties?.IsBingJob && hasBingOperateAccess);

  const showRetryButton =
    item.Properties?.IsBingJob &&
    !isScheduledJob &&
    hasBingOperateAccess &&
    !isRetryJob();

  const isBingScheduledJob = item.Properties?.IsBingJob && isScheduledJob;
  const showCloneButton =
    item.ExperimentName && isSchemaValid && relatedTemplate !== undefined;

  const supportQuickSchedule =
    item.Properties?.IsOwner === true &&
    isFeatureEnabled("schedule-job-management") &&
    item.Status === "Completed";

  const isOldBingJob = () => {
    return (
      item.Properties?.IsBingJob &&
      item.Settings?.includes(deprecatedUrlPathPrefixForBing)
    );
  };

  // Refer: https://msasg.visualstudio.com/WIT%20Engineering/_git/SydneyEvaluation?path=/Enterprise/src/CompliantSydneyEval/dbo/StoredProcedures/sp_RerunJob.sql
  const shouldShowRerunButton =
    (isSchemaValid || isBingScheduledJob) &&
    (item.Status === "Failed" || item.Status === "SubmitFailed") &&
    isOldBingJob() === false;

  const hasValidUrl = !(
    item.StudioUrl === undefined ||
    item.StudioUrl === null ||
    item.StudioUrl === ""
  );

  const convertOldBingJobsIfNeeded = () => {
    if (isOldBingJob() === false) {
      return item.Settings;
    }
    const oldSettings = parseJsonStrOptional(
      item.Settings,
      BingConfigFileSchema,
    );

    if (oldSettings?.config_file && oldSettings?.config_file.path) {
      oldSettings.config_file.path = updatedUrl_bingJobConfig(
        oldSettings.config_file.path,
      );
    }

    return JSON.stringify(oldSettings);
  };

  const retryBingJob = () => {
    try {
      toast.onToastStart("Copy this job...");
      const job = item;
      if (
        job.Settings === undefined ||
        job.CreatedBy === undefined ||
        job.JobTemplateId === undefined ||
        job.ExperimentName === undefined
      ) {
        throw new Error("Fail to load job");
      }
      if (job?.StudioUrl === undefined) {
        throw new Error("No studio url found, fail to retry job.");
      }
      const oldSettings = parseJsonStrOptional(
        job.Settings,
        BingConfigFileSchema,
      );

      if (oldSettings?.config_file && oldSettings?.config_file.path) {
        oldSettings.config_file.path = updatedUrl_bingJobConfig(
          oldSettings.config_file.path,
        );
      }
      const id = job.StudioUrl.split("?")[0].split("runs/")[1];
      const newSettings = {
        ...oldSettings,
        resume_from: {
          type: "string",
          default: id,
        },
      };
      const retryJob = {
        JobName: job.JobName + "_retry",
        CreatedBy: job.CreatedBy,
        CreatorSmtpAddress: job.CreatorSmtpAddress,
        ExperimentName: job.ExperimentName,
        JobTemplateId: job.JobTemplateId,
        Settings: JSON.stringify(newSettings),
      };
      createJob(retryJob).then(
        () => {
          toast.onToastSuccess("Retry job successfully.");
          props.onRefreshJobList();
        },
        (error) => {
          toast.onToastFailure(`Retry Job Failed: ${error.message}`);
        },
      );
      telemetryHelper.logUserActionEvent("Retry", {
        jobId: job.ID,
        isOldBingJob: isOldBingJob(),
      });
    } catch (e) {
      if (e instanceof Error) {
        toast.onToastFailure(`Retry Job Failed: ${e.message}`);
      }
    }
  };

  const isCancelSupported = () => {
    return (
      isOwner &&
      (item.Status === "Running" ||
        item.Status === "Created" ||
        item.Status === "Submitted")
    );
  };

  return (
    <Menu
      open={isMenuOpen}
      onOpenChange={(_, data) => {
        if (data.open) {
          scrollContext.isTipTriggered();
          updateResultJob(item);
        }
        setIsMenuOpen(data.open ?? false);
      }}
    >
      <MenuTrigger disableButtonEnhancement>
        <Button
          aria-label="More operations"
          appearance="subtle"
          icon={<MoreIcon />}
          data-testid="mock-job-actions-button"
        />
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          {hasPermission && hasValidUrl && (
            <MenuItem onClick={() => window.open(item.StudioUrl, "_blank")}>
              View results
            </MenuItem>
          )}
          <MenuItem
            data-testid="mock-menuItem-button-setting"
            onClick={() => {
              props.onJobSettingsClick();
            }}
          >
            View settings
          </MenuItem>

          <MenuItem
            onClick={() => {
              toast.onToastStart("Job link coping...");
              clipboardCopy(getAppEnv().webEndpoint + `/detail/${item.ID}`);
              toast.onToastSuccess("Job link copied!");
            }}
          >
            Copy job link
          </MenuItem>
          {isOwner && (
            <>
              {isCancelSupported() && (
                <MenuItem
                  data-testid="mock-menuItem-button-cancel-job"
                  onClick={() => {
                    // Currently if user want to cancel the job,
                    // we need to update the job status to "Deleted" in the database.
                    // And the job manager will do cancel operation for it.
                    cancelJob({
                      JobId: item.ID,
                    }).then(() => {
                      props.onRefreshJobList();
                    });
                    telemetryHelper.logUserActionEvent("Cancel", {
                      jobId: item.ID,
                      jobType: item.JobType,
                    });
                  }}
                >
                  Cancel eval
                </MenuItem>
              )}
              {shouldShowRerunButton && (
                <MenuItem
                  data-testid="mock-menuItem-button-rerun-job"
                  onClick={() => {
                    rerunJob({
                      ID: item.ID,
                    }).then(() => {
                      props.onRefreshJobList();
                    });

                    telemetryHelper.logUserActionEvent("Rerun", {
                      jobId: item.ID,
                      jobType: item.JobType,
                    });
                  }}
                >
                  Rerun eval
                </MenuItem>
              )}
            </>
          )}
          {hasPermission && showCloneButton && (
            <MenuItem
              onClick={() => {
                updateCurrentPath("/create", {
                  clonedJobTemplateType: item.JobTemplateType,
                  clonedExperimentName: item.ExperimentName,
                  clonedTemplateSettings: convertOldBingJobsIfNeeded(),
                });

                telemetryHelper.logUserActionEvent("Clone", {
                  jobId: item.ID,
                  jobType: item.JobType,
                  isOldBingJob: isOldBingJob(),
                });
              }}
            >
              Duplicate eval
            </MenuItem>
          )}
          {showRetryButton && (
            <MenuItem
              onClick={() => {
                retryBingJob();
              }}
            >
              Retry eval
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              openJobShareDialog();
              setIsOpenShareDialog(true);
            }}
          >
            Share job
          </MenuItem>
          {supportQuickSchedule && (
            <MenuItem
              onClick={() => {
                quickCreateScheduleJob(item);
              }}
            >
              Set up regular run
            </MenuItem>
          )}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
});
