import { createStore } from "satcheljs";

import type {
  FeatureContribution,
  FeatureContributionPathRegistry,
  FeatureContributionSubTab,
} from "../contribution/FeatureContribution";

interface IContributionStore {
  selectedContribution?: FeatureContribution;
  selectedContributionSubTab?: FeatureContributionSubTab;
  selectedContributionPathRegistry?: FeatureContributionPathRegistry;
  selectedContributionDialogId?: string;

  currentPath: string;
  currentPathState: object | undefined;
}

export const initialStore: IContributionStore = {
  selectedContribution: undefined,
  selectedContributionSubTab: undefined,
  selectedContributionPathRegistry: undefined,
  selectedContributionDialogId: undefined,

  currentPath: "/",
  currentPathState: undefined,
};

export const contributionStore = createStore<IContributionStore>(
  "contributionStore",
  Object.assign({}, initialStore),
)();
