import { orchestrator } from "satcheljs";
import { setToken } from "../../../helpers/apiHelper";
import { uploadToken } from "../actions/uploadToken";

orchestrator(uploadToken, (actionMessage) => {
  const { tokenType, tokenName, tokenValue, onSuccess, onError } =
    actionMessage;
  const tokenNameIsEmpty = tokenName === "";
  const tokenValueIsEmpty = tokenValue === "";

  if (tokenNameIsEmpty || tokenValueIsEmpty) {
    return;
  }

  setToken({
    SecretName: tokenName,
    SecretValue: tokenValue,
    TokenType: tokenType,
  })
    .then(onSuccess)
    .catch(onError);
});
