import { Body1, mergeClasses, Spinner } from "@fluentui/react-components";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Tip } from "../../../../../../components/Shared/Tip";
import { mapMetricsKey } from "../../../../helpers/metricsHelper";
import {
  getMChatUtteranceDetail,
  getReasoningMetricKey,
} from "../../../../helpers/renderMetricsHelper";
import type {
  MChatUtteranceMetrics,
  MChatUtteranceMetricsSingleData,
} from "../../../../store/jobDetailStore";
import { jobDetailStore } from "../../../../store/jobDetailStore";
import { useSharedTableStyles } from "../shardTableStyles";
import { MChatMetricsValueCell } from "./MChatMetricsValueCell";
import { tableCellSharedStyle } from "./tableCellSharedStyles";

interface IMChatMetricsSubTable {
  utterance: MChatUtteranceMetrics;
}

export const MChatMetricsSubTable = observer((props: IMChatMetricsSubTable) => {
  const { utterance } = props;
  const finalRecords =
    jobDetailStore.utteranceMetricDetail.get(utterance.query_hash) ?? [];
  const rows = Math.ceil(finalRecords.length / 3);
  const rowIndecies = Array.from({ length: rows }, (_, i) => i);

  const sharedStyles = useSharedTableStyles();
  const styles = tableCellSharedStyle();

  const valueCell = mergeClasses(
    sharedStyles.cell,
    sharedStyles.valueCell,
    sharedStyles.rightCell,
  );

  const metricNameCell = mergeClasses(
    sharedStyles.cell,
    sharedStyles.leftCell,
    styles.metricNameCell,
  );

  const subTableMetricHeaderCell = mergeClasses(
    sharedStyles.cell,
    styles.subTitleCell,
    styles.metricNameCell,
  );
  const subTableHeaderCell = mergeClasses(
    sharedStyles.cell,
    styles.subTitleCell,
    sharedStyles.centerCell,
  );

  const getClassName = (
    record: MChatUtteranceMetricsSingleData | undefined,
  ) => {
    if (record === undefined) {
      return valueCell;
    }

    const isPositive = Number(record.treatment) > Number(record.control);
    const isNegtive = Number(record.treatment) < Number(record.control);

    const className = mergeClasses(
      valueCell,
      isPositive ? styles.positiveCell : undefined,
      isNegtive ? styles.negativeCell : undefined,
    );

    return className;
  };

  useEffect(() => {
    if (
      jobDetailStore.utteranceMetricDetail.get(utterance.query_hash) ===
      undefined
    ) {
      getMChatUtteranceDetail(utterance.query_hash);
    }
  }, [utterance.query_hash]);

  if (
    jobDetailStore.utteranceMetricDetail.get(utterance.query_hash) === undefined
  ) {
    return <Spinner />;
  }

  return (
    <table className={sharedStyles.table}>
      <thead className={sharedStyles.thead}>
        <tr className={sharedStyles.rowStyle}>
          <th className={subTableMetricHeaderCell}>Metric</th>
          <th className={subTableHeaderCell}>Control</th>
          <th className={subTableHeaderCell}>Treatment</th>
          <th className={subTableMetricHeaderCell}>Metric</th>
          <th className={subTableHeaderCell}>Control</th>
          <th className={subTableHeaderCell}>Treatment</th>
          <th className={subTableMetricHeaderCell}>Metric</th>
          <th className={subTableHeaderCell}>Control</th>
          <th className={subTableHeaderCell}>Treatment</th>
        </tr>
      </thead>
      <tbody className={styles.tbody}>
        {rowIndecies.map((row) => {
          return (
            <tr key={"subtable_" + row} className={sharedStyles.rowStyle}>
              {[0, 1, 2].map((diff, index) => {
                const record = finalRecords[row * 3 + diff];

                if (record === undefined) {
                  return (
                    <React.Fragment
                      key={"subtable_undefined_" + row + "_" + index}
                    >
                      <td className={metricNameCell}></td>
                      <td className={valueCell}></td>
                      <td className={valueCell}></td>
                    </React.Fragment>
                  );
                }

                const reasoningMetricKey = getReasoningMetricKey(
                  mapMetricsKey(record.key),
                );

                return (
                  <React.Fragment key={"subtable_row_" + reasoningMetricKey}>
                    <td className={metricNameCell}>
                      <Tip withArrow content={record.key} relationship="label">
                        <div className={sharedStyles.longTextWrapper}>
                          <Body1 className={sharedStyles.longText}>
                            {mapMetricsKey(record.key)}
                          </Body1>
                        </div>
                      </Tip>
                    </td>

                    <td className={valueCell}>
                      <MChatMetricsValueCell
                        metricKey={record.key}
                        expName="control"
                        content={record.control.toFixed(2) ?? "N/A"}
                        reasoningMetricKey={reasoningMetricKey}
                        query={utterance.query}
                      />
                    </td>
                    <td className={getClassName(record)}>
                      <MChatMetricsValueCell
                        metricKey={record.key}
                        expName="treatment"
                        content={record.treatment.toFixed(2) ?? "N/A"}
                        reasoningMetricKey={reasoningMetricKey}
                        query={utterance.query}
                      />
                    </td>
                  </React.Fragment>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});
