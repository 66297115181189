import { useEffect, useState } from "react";

import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Spinner,
  Textarea,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import {
  ArrowLeft24Filled,
  ArrowLeft24Regular,
  ChevronDown12Filled,
  ChevronDown12Regular,
  ChevronUp12Filled,
  ChevronUp12Regular,
  PersonFeedback24Filled,
  PersonFeedback24Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import type { PrivacyFilterTemplateContent } from "sydneyeval-shared";
import { uploadQuerySet } from "../../../../../helpers/apiHelper";
import { closeSidePane } from "../../../../../mutators/sidePaneMutators";
import { getUserAlias } from "../../../../HumanCorrelationStudy/components/utils/utilities";
import { jobDetailStore } from "../../../store/jobDetailStore";

type PrivacyFilterJudgementViewProps = {
  jobId: string;
  query: string;
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  backButton: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  title: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
    ...shorthands.margin("0", "0", "0", "9px"),
  },
  utteranceRow: {
    marginTop: "37px",
    display: "flex",
    flexDirection: "column",
  },
  utteranceTitle: {
    color: "#1B1A19",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  utteranceContent: {
    color: "#000",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  details: {
    marginTop: "12px",
    ...shorthands.borderRadius("6px"),
    ...shorthands.border("1px", "solid", "#EDEBE9"),
    ...shorthands.overflow("auto", "auto"),
    backgroundColor: "#FFF",
    boxSizing: "border-box",
    maxWidth: "100%",
    maxHeight: "400px",
    lineHeight: "20px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    color: "#11100F",
  },
  detailsTitle: {
    height: "32px",
    backgroundColor: "#EFF6FC",
    ...shorthands.padding("0", "16px"),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("8px"),
  },
  detailsTitleContent: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32px",
  },
  spinner: {
    marginTop: "20px",
  },
  body: {
    whiteSpace: "pre",
  },
  icon: {
    lineHeight: "20px",
    color: "white",
    height: "20px",
    width: "20px",
  },
  button: {
    marginRight: "8px",
  },
  buttonContainer: {
    position: "absolute",
    bottom: 0,
    left: "20px",
  },
  line: {
    position: "absolute",
    left: 0,
  },
  subtitleField: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
  },
  commentRow: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "column",
  },
});

const FeedbackIcon = bundleIcon(
  PersonFeedback24Filled,
  PersonFeedback24Regular,
);

const BackIcon = bundleIcon(ArrowLeft24Filled, ArrowLeft24Regular);

const ArrowUpIcon = bundleIcon(ChevronUp12Filled, ChevronUp12Regular);
const ArrowDownIcon = bundleIcon(ChevronDown12Filled, ChevronDown12Regular);

export const PrivacyFilterJudgementView = observer(
  (props: PrivacyFilterJudgementViewProps) => {
    const styles = useStyles();
    const [content, setContent] = useState("");
    const [rawContent, setRawContent] = useState(
      [] as PrivacyFilterTemplateContent[],
    );
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isFilterContentAccurate, setIsFilterContentAccurate] = useState("");
    const [commentInput, setCommentInput] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);
    const [noContent, setNoContent] = useState<boolean>(false);

    useEffect(() => {
      const result = jobDetailStore.privacyFilterResponse;
      if (result === undefined || result.length === 0) {
        setNoContent(true);
        return;
      }

      result.forEach((item) => {
        try {
          const redacted_content = item.redacted_content;
          if (typeof redacted_content === "string") {
            item.redacted_content = JSON.parse(redacted_content);
          }
        } catch (e) {
          //do nothing, keep original plain text content
        }
      });
      const matchedResult = result.filter((item) => item.query === props.query);
      setRawContent(matchedResult);
      const jsonString = JSON.stringify(matchedResult, null, 2);
      setContent(jsonString);
    }, []);

    const renderJson = (filteredcontent: string) => {
      return filteredcontent.split("\n").map((line, index) => {
        return (
          <Body1 key={index} tabIndex={0} className={styles.body}>
            {`${line}`}
            <br />
          </Body1>
        );
      });
    };

    const timeStamp = new Date().toISOString().replace(/:/g, "-");

    const generateFeedbackFileName = () => {
      return `${props.jobId}_${getUserAlias()}_${timeStamp}.json`;
    };

    const createFeedbackFile = () => {
      const feedback = {
        userAlias: getUserAlias(),
        query: props.query,
        jobId: props.jobId,
        isFilterContentAccurate: isFilterContentAccurate,
        comment: commentInput,
        content: rawContent,
        timeStamp: new Date().toISOString(),
      };
      const createdFile = new File(
        [JSON.stringify(feedback, null, 2)],
        generateFeedbackFileName(),
        {
          type: "text/plain",
        },
      );
      return createdFile;
    };

    const handleCancelClick = () => {
      closeSidePane();
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsFilterContentAccurate(event.target.value);
    };

    return (
      <div className={styles.root}>
        <div className={styles.titleRow}>
          <div
            className={styles.backButton}
            role="button"
            aria-label="Back Button"
            onClick={() => {
              closeSidePane();
            }}
          >
            <BackIcon />
          </div>
          <Body1 className={styles.title}>{`Content Hit Privacy Filter`}</Body1>
        </div>
        <div className={styles.utteranceRow}>
          <Body1 className={styles.utteranceTitle}>Utterance:</Body1>
          <Body1 className={styles.utteranceContent}>{props.query}</Body1>
        </div>
        {noContent && <h2>No records found!</h2>}
        {!noContent && content.length === 0 && (
          <Spinner className={styles.spinner} />
        )}
        {content.length !== 0 && (
          <>
            <div className={styles.utteranceRow}>
              <Body1 className={styles.utteranceTitle}>
                Content Hit Privacy Filter
              </Body1>
            </div>
            <Field className={styles.details}>{renderJson(content)}</Field>
            <div className={styles.buttonContainer}>
              <Button
                className={styles.button}
                appearance="primary"
                icon={<FeedbackIcon className={styles.icon} />}
                onClick={() => {
                  setIsDialogOpen(true);
                }}
              >
                Report over filter
              </Button>
              <Button onClick={handleCancelClick}>Cancel</Button>
            </div>
          </>
        )}
        <Dialog
          open={isDialogOpen}
          onOpenChange={() => {
            setIsDialogOpen(false);
          }}
        >
          <DialogSurface>
            <DialogBody>
              <DialogTitle>{`Report over filter`}</DialogTitle>
              <DialogContent>
                <Field
                  className={styles.subtitleField}
                  required
                  label={"Is the filter content accurate?"}
                ></Field>
                <input
                  type="radio"
                  value="yes"
                  checked={isFilterContentAccurate === "yes"}
                  onChange={handleRadioChange}
                />
                Yes.
                <br></br>
                <input
                  type="radio"
                  value="no"
                  checked={isFilterContentAccurate === "no"}
                  onChange={handleRadioChange}
                />
                No.
                <br></br>
                <div className={styles.commentRow}>
                  <label className={styles.utteranceTitle}>Comments</label>
                  <Textarea
                    appearance="outline"
                    placeholder="Let us know your feedback and why the filter result is accurate or not, so we can keep improving the filter."
                    value={commentInput}
                    onChange={(_, data) => {
                      setCommentInput(data.value);
                    }}
                  ></Textarea>
                </div>
                <div className={styles.details}>
                  <div
                    className={styles.detailsTitle}
                    onClick={() => {
                      setIsExpanded(!isExpanded);
                    }}
                  >
                    {isExpanded ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    <Body1 className={styles.detailsTitleContent}>
                      {`Content Hit Privacy Filter`}
                    </Body1>
                  </div>

                  {isExpanded && renderJson(content)}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  className={styles.button}
                  appearance={
                    isFilterContentAccurate === "yes" ||
                    isFilterContentAccurate === "no"
                      ? "primary"
                      : "secondary"
                  }
                  disabled={isFilterContentAccurate === ""}
                  onClick={() => {
                    uploadQuerySet({
                      File: createFeedbackFile(),
                      FileName:
                        "privacyFilter/feedback/" + generateFeedbackFileName(),
                      FileType: "MChat",
                    });
                    setIsFilterContentAccurate("");
                    setCommentInput("");
                    setIsDialogOpen(false);
                  }}
                >
                  Submit
                </Button>
                <Button
                  onClick={() => {
                    setIsFilterContentAccurate("");
                    setCommentInput("");
                    setIsDialogOpen(false);
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      </div>
    );
  },
);
