import type { JobConfigurationValue } from "../../getJobPropV2";

export const checkMultiTurnConfigs = (
  value: JobConfigurationValue<"configs_in_json">,
  path: string,
) => {
  if (value === undefined || value.options === undefined) {
    return undefined;
  }
  if (value.options === undefined || value.options.length === 0) {
    return {
      path,
      message: "Options should not be empty for multi-turn",
    };
  }

  for (const option of value.options) {
    if (
      option === "bot_turn_data_generation_echo" &&
      value.conversations?.data_generation_max_turns !== undefined
    ) {
      return {
        path,
        message:
          "Error configuration! Options bot_turn_data_generation_echo can not set data_generation_max_turns",
      };
    }
    if (
      option === "bot_turn_data_generation_sc" &&
      value.conversations?.data_generation_max_turns === undefined
    ) {
      return {
        path,
        message:
          "Error configuration! Options bot_turn_data_generation_sc must set data_generation_max_turns",
      };
    }
  }
  return undefined;
};
