import { computed } from "mobx";
import {
  displayLMChecklistPassFailRatesAreLoading,
  useLMChecklistPassFailRates,
} from "../helpers/lmchecklistMetricsHelper";
import { jobDetailStore } from "../store/jobDetailStore";

export const getGeneralMetrics = computed(() => {
  if (jobDetailStore.generalMetricsResponse === undefined) {
    return undefined;
  }

  return jobDetailStore.generalMetricsResponse
    .map((record) => {
      if (jobDetailStore.lmchecklistDetailsResponse) {
        switch (record.metrics_key) {
          case "lmchecklist_critical":
            return jobDetailStore.lmchecklistAssertionsResponse
              ? useLMChecklistPassFailRates(
                  record,
                  jobDetailStore.lmchecklistAssertionsResponse,
                  "critical",
                )
              : displayLMChecklistPassFailRatesAreLoading(record);

          case "lmchecklist_expected":
            return jobDetailStore.lmchecklistAssertionsResponse
              ? useLMChecklistPassFailRates(
                  record,
                  jobDetailStore.lmchecklistAssertionsResponse,
                  "expected",
                )
              : displayLMChecklistPassFailRatesAreLoading(record);

          default:
            return record;
        }
      } else {
        return record;
      }
    })
    .filter((record) => {
      return (
        (record.score_control || record.score_experiment) &&
        record.metrics_key !== "lmchecklist_aspirational" &&
        record.metrics_key !== "lmchecklist_score"
      );
    });
});

export const allUtterances = computed(() => {
  const utterances = Array.from(jobDetailStore.utteranceList.values());
  return utterances;
});

export const filteredUtterances = computed(() => {
  const isFiltered = jobDetailStore.filters.length === 0;
  const computedUtts = isFiltered
    ? allUtterances.get()
    : Array.from(jobDetailStore.utteranceList.values())?.filter((_) =>
        jobDetailStore.filters.includes(_.segment),
      );
  return computedUtts ?? [];
});

export const uttToDisplay = computed(() => {
  const uttsToUse = filteredUtterances.get();
  return uttsToUse.slice(0, jobDetailStore.utterNum);
});
