import { Spinner, makeStyles, shorthands } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import {
  consentGrantedAction,
  getConsentStatusAction,
} from "../../actions/consentAction";
import { store } from "../../store/store";
import { ConsentDialog } from "../Dialog/ConsentDialog";
import type { WrapperProps } from "./WrapperProps";

const useStyles = makeStyles({
  spinner: {
    width: "100%",
    ...shorthands.margin("10px", "0", "0", "0"),
  },
});

export const OnboardingProvider = observer((props: WrapperProps) => {
  const styles = useStyles();

  useEffect(() => {
    getConsentStatusAction();
  }, [store.account]);

  const shouldShowSpinner = () => {
    return store.consented === undefined;
  };

  const shouldShowConsentPage = () => {
    return store.consented !== true;
  };

  const onAgreeClick = () => {
    consentGrantedAction();
  };

  const renderConsentPageIfNeeded = () => {
    return (
      shouldShowConsentPage() && <ConsentDialog onAgreeClick={onAgreeClick} />
    );
  };

  if (shouldShowSpinner()) {
    return (
      <Spinner
        className={styles.spinner}
        labelPosition="below"
        label="Checking Status..."
      />
    );
  }

  return (
    <>
      {props.children}
      {renderConsentPageIfNeeded()}
    </>
  );
});
