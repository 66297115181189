import { computed } from "mobx";
import { jobDetailStore } from "../store/jobDetailStore";

export const getPrivacyFilterSet = computed(() => {
  return (
    jobDetailStore.privacyFilterResponse
      ?.filter((record) => record.query !== "" && record.isRedacted !== "")
      .map((record) => record.query) || []
  );
});

export const getRedactedCount = computed(() => {
  const uniqueRedactedQueries = jobDetailStore.privacyFilterResponse
    ?.filter(
      (record) =>
        record.query !== "" && record.isRedacted.toLowerCase() === "true",
    )
    .map((record) => record.query);
  return new Set(uniqueRedactedQueries).size;
});
