import { makeStyles, shorthands } from "@fluentui/react-components";

export const useSharedTableStyles = makeStyles({
  table: {
    borderCollapse: "collapse",
    ...shorthands.borderRadius("6px"),
    ...shorthands.border("1px", "none", "#EDEBE9"),
    ...shorthands.overflow("hidden"),
    width: "100%",
  },
  thead: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    color: "#323130",
    backgroundColor: "#F3F2F1",
  },
  tbody: {
    "> tr:nth-child(odd)": {
      backgroundColor: "white",
    },
    "> tr:nth-child(even)": {
      backgroundColor: "#F8F7F9",
    },
  },
  expand: {
    width: "20px",
    height: "20px",
    ...shorthands.border("1px", "solid", "#EDEBE9"),
    ...shorthands.borderRadius("6px"),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ":hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  expanded: {
    transform: "rotate(180deg)",
  },
  rowStyle: {
    height: "36px",
  },
  cell: {
    boxSizing: "border-box",
    ...shorthands.padding("0.25rem", "0.25rem"),
    ...shorthands.border("1px", "solid", "#EDEBE9"),
  },
  longTextWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  longText: {
    width: "0",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    ...shorthands.flex(1),
    marginLeft: "13px",
  },
  valueCell: {
    width: "100px",
  },
  rightCell: {
    textAlign: "right",
  },
  leftCell: {
    textAlign: "left",
  },
  centerCell: {
    textAlign: "center",
  },
  subTitleCell: {
    fontSize: "12px",
  },
  button: {
    ...shorthands.borderRadius("6px"),
    ...shorthands.padding("2px", "8px"),
    ...shorthands.border("1px", "solid", "#EDEBE9"),
    height: "24px",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    fontStyle: "normal",
    width: "100%",
  },
  sortHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  filterBar: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  filtersGroup: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  filterIcon: {
    marginRight: "10px",
  },
  filterBarItem: {
    marginRight: "10px",
    minWidth: "350px",
    ...shorthands.margin("5px"),
  },
  reset: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.borderRadius("6px"),
    fontWeight: 400,
  },
  resetIcon: {
    marginRight: "5px",
  },
  horizontalTable: {
    width: "fit-content",
    maxWidth: "100%",
    display: "block",
    ...shorthands.overflow("auto"),
  },
  centerAlign: {
    textAlign: "center",
  },
  stickyColumn: {
    position: "sticky",
    left: 0,
    zIndex: 1,
    backgroundColor: "white",
  },
  rowWithSmallerGap: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    ...shorthands.gap("8px"),
  },
  thickBorder: {
    ...shorthands.borderLeft("3px", "solid", "#DBDBDB"),
  },
  preLine: {
    whiteSpace: "pre-line",
  },
});
