import { Button } from "@fluentui/react-components";
import {
  Edit16Regular,
  Pause16Filled,
  PlayFilled,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import type { ScheduledJob } from "sydneyeval-shared";
import { Tip } from "../../../../../components/Shared/Tip";
import { onScheduleJobAction } from "../../../actions/scheduleJobActions";
import {
  getSchedulerQuotaError,
  hasActivateSchedulerAccess,
  hasEditSchedulerAccess,
} from "../../../selectors/scheduleJobSelector";
import { quatoExceedTip } from "../constants";
import { sharedStyles } from "../sharedStyles";

interface ISchedulerActionButtonProps {
  scheduler: ScheduledJob | undefined;
  action: "Edit" | "Stop" | "Enable";
}
export const SchedulerActionButton = observer(
  (props: ISchedulerActionButtonProps) => {
    const { scheduler } = props;
    const styles = sharedStyles();

    if (!scheduler) {
      return <></>;
    }
    switch (props.action) {
      case "Edit":
        if (
          scheduler.Status !== "Completed" &&
          hasEditSchedulerAccess(scheduler)
        ) {
          return (
            <Button
              size="small"
              icon={<Edit16Regular />}
              onClick={() => {
                onScheduleJobAction(scheduler, "Edit");
              }}
            >
              Edit
            </Button>
          );
        }
        return <></>;
      case "Stop":
        if (
          scheduler.Status === "Active" &&
          hasEditSchedulerAccess(scheduler)
        ) {
          return (
            <Button
              size="small"
              icon={<Pause16Filled className={styles.stopColor} />}
              onClick={() => {
                onScheduleJobAction(scheduler, "Stop");
              }}
            >
              Pause
            </Button>
          );
        }

        return <></>;
      case "Enable":
        if (
          scheduler.Status !== "Active" &&
          hasActivateSchedulerAccess(scheduler)
        ) {
          if (getSchedulerQuotaError.get() === undefined) {
            return (
              <Button
                size="small"
                icon={<PlayFilled className={styles.enableColor} />}
                onClick={() => {
                  onScheduleJobAction(scheduler, "Enable");
                }}
              >
                Activate
              </Button>
            );
          } else {
            return (
              <Tip content={quatoExceedTip} relationship="label">
                <Button
                  size="small"
                  icon={<PlayFilled className={styles.enableColor} />}
                  disabled
                >
                  Activate
                </Button>
              </Tip>
            );
          }
        }
        return <></>;
    }
  },
);
