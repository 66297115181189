import { Button, Tooltip } from "@fluentui/react-components";
import { Info24Regular } from "@fluentui/react-icons";

interface ITooltipButtonProps {
  content: React.ReactNode;
}

export const TooltipButton = (props: ITooltipButtonProps) => {
  return (
    <Tooltip
      content={
        <div
          style={{
            whiteSpace: "pre-line",
            lineHeight: "1.5",
            fontSize: "14px",
          }}
        >
          {props.content}
        </div>
      }
      relationship="description"
      positioning="before"
      withArrow
    >
      <Button
        style={{ height: "1.4em" }}
        icon={<Info24Regular />}
        appearance="transparent"
      />
    </Tooltip>
  );
};
