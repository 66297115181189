import { createStore } from "satcheljs";
import { type ScheduledJob } from "sydneyeval-shared";
import type { Job } from "../models/Job";
import type { JobTemplate } from "../models/JobTemplate";

interface IScheduleStore {
  pageMode: "View" | "Edit" | "Create" | "ReadOnly";
  isLoading: boolean;
  searchCriteria: Map<string, string>;
  activeScheduleJobs: ScheduledJob[];
  selectedScheduledJob: ScheduledJob | undefined;
  jobtemplates: JobTemplate[];
  errorMessage: string | undefined;
  displayJobListLength: number;
  recentCompletedJobs: Job[];
  ownedSchedulerCount: number;
}

export const initScheduleJobStore: IScheduleStore = {
  pageMode: "View",
  isLoading: true,
  activeScheduleJobs: [],
  selectedScheduledJob: undefined,
  jobtemplates: [],
  errorMessage: undefined,
  displayJobListLength: 20,
  searchCriteria: new Map<string, string>(),
  recentCompletedJobs: [],
  ownedSchedulerCount: 0,
};

export const scheduleJobStore = createStore<IScheduleStore>(
  "scheduleJobStore",
  Object.assign({}, initScheduleJobStore),
)();
