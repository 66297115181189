import { array, num, object, optional, str } from "sydneyeval-shared";

export const LMChecklistSydneyReply = object({
  control: optional(str),
  experiment: optional(str),
});

export const LMChecklistDetails = object({
  query: str,
  queryHash: str,
  segment: str,
  criticalControl: optional(num),
  criticalExperiment: optional(num),
  expectedControl: optional(num),
  expectedExperiment: optional(num),
  aspirationalControl: optional(num),
  aspirationalExperiment: optional(num),
  tControl: optional(num),
  tExperiment: optional(num),
  sydneyReply: LMChecklistSydneyReply,
});

export const LMChecklistDetailsResponse = array(LMChecklistDetails);

export type LMChecklistSydneyReply = ReturnType<typeof LMChecklistSydneyReply>;
export type LMChecklistDetails = ReturnType<typeof LMChecklistDetails>;
export type LMChecklistDetailsResponse = ReturnType<
  typeof LMChecklistDetailsResponse
>;
