import { Button, makeStyles, tokens } from "@fluentui/react-components";
import { AddCircle16Filled, ArrowLeftFilled } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { onBackClick } from "../../actions/productSettingsActions";
import { updateProductSettingScenario } from "../../mutators/updateProductSettings";
import { productSettingsStore } from "../../store/productSettingsStore";

const useStyle = makeStyles({
  row: {
    width: "100%",
    display: "flex",
    marginTop: "52px",
  },
  title: {
    flex: 1,
    fontSize: tokens.fontSizeBase600,
    lineHeight: tokens.lineHeightBase600,
    fontWeight: tokens.fontWeightBold,
  },
});

const renderBackButton = () => {
  switch (productSettingsStore.scenario.scenarioType) {
    case "Creating":
    case "EditingProduct":
      return (
        <Button
          icon={<ArrowLeftFilled />}
          appearance="transparent"
          onClick={() => onBackClick()}
        />
      );
    default:
      return <></>;
  }
};

const renderAddButton = () => {
  switch (productSettingsStore.scenario.scenarioType) {
    case "Listing":
      break;
    default:
      return <></>;
  }

  return (
    <Button
      icon={<AddCircle16Filled />}
      appearance="primary"
      onClick={() => {
        updateProductSettingScenario({
          scenarioType: "Creating",
          currentProductName: "NewProduct",
          currentPipelines: [],
        });
      }}
    >
      Add new product
    </Button>
  );
};

const getTitle = () => {
  switch (productSettingsStore.scenario.scenarioType) {
    case "Loading":
      return "";
    case "Listing":
      return "Product List";
    case "Creating":
      return "Add New Product";
    case "EditingProduct":
      if (productSettingsStore.scenario.currentPipeline !== undefined) {
        return `Edit Pipeline ${productSettingsStore.scenario.currentPipeline.pipelineExpName}`;
      } else {
        return `Edit Product ${productSettingsStore.scenario.selectedProduct.displayName}`;
      }
  }
};

export const ProductTitleView = observer(() => {
  const styles = useStyle();

  return (
    <div className={styles.row}>
      {renderBackButton()}
      <div className={styles.title}>{getTitle()}</div>
      {renderAddButton()}
    </div>
  );
});
