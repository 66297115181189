import { createStore } from "satcheljs";
import type { SearchUserSuccessResponse } from "../models/SearchUserByIdResponse";

type UserCache =
  | {
      status: "success";
      data: SearchUserSuccessResponse;
    }
  | {
      status: "loading";
    };

interface IStore {
  userCache: Record<string, UserCache>;
}

const initialStore: IStore = {
  userCache: {},
};

export const substrateStore = createStore<IStore>(
  "substrateStore",
  Object.assign({}, initialStore),
)();
