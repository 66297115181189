import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Link,
  Textarea,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  setCachedAvalonAccessToken,
  setCachedHeronAccessToken,
  setCachedSubstrateToken,
} from "../../helpers/cacheHelper";
import {
  updateIsSettingAvalonAccessToken,
  updateIsSettingHeronAccessToken,
  updateIsSettingSubstrateToken,
} from "../../mutators/updateDevelopStatus";
import { store } from "../../store/store";

type Props = {
  type: "SubstrateAccessToken" | "HeronAccessToken" | "AvalonAccessToken";
};

const LocalTokenInputDialog = observer((props: Props) => {
  const [token, setToken] = React.useState<string>("");

  const getTiTle = () => {
    switch (props.type) {
      case "SubstrateAccessToken":
        return "Set Substrate Search Access Token";
      case "HeronAccessToken":
        return "Set Heron Access Token";
      case "AvalonAccessToken":
        return "Set Avalon Access Token";
    }
  };

  const getContent = () => {
    switch (props.type) {
      case "SubstrateAccessToken":
        return (
          <Body1>
            {
              "Input Your Substarte Search Access Token Manually For Local Development "
            }
            <Link href="https://dev.azure.com/msasg/Cortana/_wiki/wikis/M365%20Chat.wiki/150434/SEVAL-Portal-Development">
              (How to get your token?)
            </Link>
          </Body1>
        );

      case "HeronAccessToken":
        return (
          <Body1>
            {"Input Your Heron Access Token Manually For Local Development."}
            <br />
            {
              "Please go to SEVAL Production, open Dev Center, and click 'Get Managed Identity Token' and use scope '3e9313d0-debc-4f0d-85a7-171f92333ba1' to get your token."
            }
          </Body1>
        );
      case "AvalonAccessToken":
        return (
          <Body1>
            {"Input Your Avalon Access Token Manually For Local Development."}
            <br />
            {
              "Please go to SEVAL Production, open Dev Center, and click 'Get User Access Token' and use scope 'https://substrate.office.com/sydney/.default' to get your token."
            }
          </Body1>
        );
    }
  };

  const isOpen = () => {
    switch (props.type) {
      case "SubstrateAccessToken":
        return store.develop.isSettingSubstrateToken;
      case "HeronAccessToken":
        return store.develop.isSettingHeronAccessToken;
      case "AvalonAccessToken":
        return store.develop.isSettingAvalonAccessToken;
    }
  };

  const onOpenChange = () => {
    updateIsSettingSubstrateToken(false);
    updateIsSettingHeronAccessToken(false);
    store.develop.tokenResolver?.("");
  };

  const onConfirm = () => {
    switch (props.type) {
      case "SubstrateAccessToken":
        updateIsSettingSubstrateToken(false);
        setCachedSubstrateToken(token);
        store.develop.tokenResolver?.(token);
        break;
      case "HeronAccessToken":
        updateIsSettingHeronAccessToken(false);
        setCachedHeronAccessToken(token);
        store.develop.tokenResolver?.(token);
        break;
      case "AvalonAccessToken":
        updateIsSettingAvalonAccessToken(false);
        setCachedAvalonAccessToken(token);
        store.develop.tokenResolver?.(token);
        break;
    }
  };

  return (
    <Dialog open={isOpen()} onOpenChange={onOpenChange}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{getTiTle()}</DialogTitle>
          <DialogContent>
            {getContent()}
            <Field label={"Token"}>
              <Textarea
                style={{ width: "100%" }}
                size="medium"
                value={token}
                onChange={(_, data) => {
                  setToken(data.value);
                }}
              />
            </Field>
          </DialogContent>
          <DialogActions>
            <Button appearance="primary" onClick={onConfirm}>
              Set
            </Button>
            <Button onClick={onOpenChange}>Close</Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
});

export const SubstrateTokenInputDialog = () => (
  <LocalTokenInputDialog type="SubstrateAccessToken" />
);

export const HeronTokenInputDialog = () => (
  <LocalTokenInputDialog type="HeronAccessToken" />
);

export const AvalonTokenInputDialog = () => (
  <LocalTokenInputDialog type="AvalonAccessToken" />
);
