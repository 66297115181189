"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getExpNames = exports.isValidSettingsThrowError = exports.isValidSettings = void 0;
const json_schema_library_1 = require("json-schema-library");
const typeHelper_1 = require("../tools/typeHelper");
const parseHelper_1 = require("./parseHelper");
const getJsonErrors = (setting, schema) => {
    if (schema === "" ||
        schema === "null" ||
        schema === undefined ||
        setting === undefined) {
        return [];
    }
    const schemaStr = schema.replaceAll("(?i)", "");
    const schemaVer = (0, parseHelper_1.parseJsonStr)(schemaStr, JobSchema).$schema;
    const schemaObj = (0, parseHelper_1.parseJsonStr)(schemaStr, typeHelper_1.anyObject);
    const settingObj = (0, parseHelper_1.parseJsonStr)(setting, typeHelper_1.anyObject);
    const jsonSchema = schemaVer === "http://json-schema.org/draft-04/schema#"
        ? new json_schema_library_1.Draft04(schemaObj)
        : new json_schema_library_1.Draft06(schemaObj);
    const errors = jsonSchema.validate(settingObj);
    return errors;
};
const isValidSettings = (setting, schema) => {
    try {
        const errors = getJsonErrors(setting, schema);
        return errors.length === 0;
    }
    catch (e) {
        return false;
    }
};
exports.isValidSettings = isValidSettings;
const Parser = (0, typeHelper_1.optional)((0, typeHelper_1.array)((0, typeHelper_1.object)({
    data: (0, typeHelper_1.object)({
        pointer: typeHelper_1.str,
    }),
    message: typeHelper_1.str,
})));
const isValidSettingsThrowError = (setting, schema) => {
    const errors = getJsonErrors(setting, schema);
    if (errors.length > 0) {
        const getRootError = (e) => {
            const allErrors = e.data.errors;
            const parsedAllErrors = Parser(allErrors, "Parser");
            if (parsedAllErrors === undefined) {
                return "[Error]" + e.data.pointer + "[Message]" + e.message;
            }
            return parsedAllErrors
                .map((element) => "[Error]" +
                element.data.pointer +
                "[Message]" +
                element.message +
                ",")
                .join(";");
        };
        const message = errors?.map((e) => getRootError(e)).toString();
        throw new Error(message);
    }
    return errors.length === 0;
};
exports.isValidSettingsThrowError = isValidSettingsThrowError;
const JobSchema = (0, typeHelper_1.object)({
    $schema: (0, typeHelper_1.optional)(typeHelper_1.str),
});
const JobSettingsSchema = (0, typeHelper_1.object)({
    configs_in_json: (0, typeHelper_1.object)({
        conversations: (0, typeHelper_1.object)({
            exp_configs: (0, typeHelper_1.array)((0, typeHelper_1.object)({
                exp_name: typeHelper_1.str,
                is_baseline: (0, typeHelper_1.optional)(typeHelper_1.bool),
            })),
        }),
    }),
});
const getExpNames = (jobSettngs) => {
    const config = (0, parseHelper_1.parseJsonStrOptional)(jobSettngs, JobSettingsSchema);
    if (config === undefined ||
        config.configs_in_json.conversations.exp_configs.length !== 2) {
        return {
            control: "control",
            experiment: "experiment",
        };
    }
    const firstExp = config.configs_in_json.conversations.exp_configs[0];
    const secondExp = config.configs_in_json.conversations.exp_configs[1];
    if (firstExp.is_baseline === true) {
        return {
            control: firstExp.exp_name,
            experiment: secondExp.exp_name,
        };
    }
    if (secondExp.is_baseline === true) {
        return {
            control: secondExp.exp_name,
            experiment: firstExp.exp_name,
        };
    }
    return {
        control: firstExp.exp_name,
        experiment: secondExp.exp_name,
    };
};
exports.getExpNames = getExpNames;
