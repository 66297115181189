import {
  object,
  optional,
  parseJsonStrOptional,
  str,
  unknown,
} from "sydneyeval-shared";
import { getAzureMLFileContent } from "../../../helpers/apiHelper";
import { isFeatureEnabled } from "../../../selectors/features";
import { updateQuickTemplateTypeFilterAction } from "../actions/jobActions";
import type { JobTemplate } from "../models/JobTemplate";
import { updateProductFilter } from "../mutators/jobMutators";
import { getQuickFilterDefaultTemplate } from "../selectors/getQuickFilterDefaultTemplate";
import { jobStore } from "../store/jobStore";
import { productSettingsStore } from "../store/productSettingsStore";
import { updatedJobSettingsIfNeeded } from "./bingFileInBlobHelper";
import { convertTemplateTypeToProduct } from "./productHelper";

const CloneJobStateParser = optional(
  object({
    clonedJobTemplateType: str,
    clonedExperimentName: str,
    clonedTemplateSettings: str,
  }),
);

const WrappedConfigParser = optional(
  object({
    config_file: object({
      path: str,
    }),
  }),
);

const WrappedSerializedConfigParser = optional(
  object({
    serialized_config: object({
      default: unknown,
    }),
  }),
);

export const getDefaultTemplate = async (
  state: unknown,
): Promise<JobTemplate> => {
  const templates = jobStore.availableTemplates;
  const parsedState = CloneJobStateParser(state, "CloneJobStateParser");

  // If is cloning from a job
  if (parsedState !== undefined) {
    const clonedTemplate = templates.find(
      (template) =>
        template.ExperimentName === parsedState.clonedExperimentName ||
        "Scheduled_" + template.ExperimentName ===
          parsedState.clonedExperimentName,
    );

    if (clonedTemplate !== undefined) {
      // Update the selected product filter
      if (!isFeatureEnabled("product-registration")) {
        updateQuickTemplateTypeFilterAction(
          convertTemplateTypeToProduct(clonedTemplate.Type),
        );
      } else {
        const targetProduct = productSettingsStore.products.find(
          (_) =>
            _.templates.find(
              (template) =>
                template.pipelineExpName === parsedState.clonedExperimentName,
            ) !== undefined,
        );
        if (targetProduct !== undefined) {
          updateProductFilter(targetProduct.id);
        }
      }

      // If the Settings can be parsed with WrappedConfigParser,
      // then we will try to get the real settings content from AzureML (Basically for BingV2 and CWC)
      const wrappedJson = parseJsonStrOptional(
        parsedState.clonedTemplateSettings || clonedTemplate.Settings,
        WrappedConfigParser,
      );

      if (wrappedJson !== undefined) {
        try {
          const result = await getAzureMLFileContent(
            wrappedJson.config_file.path,
          );

          const compatibleSettings = updatedJobSettingsIfNeeded(result);
          return {
            ...clonedTemplate,
            Settings: compatibleSettings ?? "{}",
          };
        } catch (e) {
          return {
            ...clonedTemplate,
            Settings: JSON.stringify(wrappedJson) ?? "{}",
          };
        }
      }

      // If the Settings can be parsed with WrappedSerializedConfigParser
      // then we will just return the Settings (Basically for BingV2 and CWC)
      const wrappedSerializedConfig = parseJsonStrOptional(
        parsedState.clonedTemplateSettings || clonedTemplate.Settings,
        WrappedSerializedConfigParser,
      );

      if (wrappedSerializedConfig !== undefined) {
        return {
          ...clonedTemplate,
          Settings:
            JSON.stringify(wrappedSerializedConfig.serialized_config.default) ??
            "{}",
        };
      }

      // If the Settings cannot be parsed with WrappedSettingsParser or WrappedSerializedConfigParser
      // then we will just return the Settings
      return {
        ...clonedTemplate,
        Settings: parsedState.clonedTemplateSettings,
      };
    }
  }

  return getQuickFilterDefaultTemplate.get();
};
