import type { UserProfile, UserProfileErrorResult } from "sydneyeval-shared";

export const isUserProfileSuccessResponse = (
  result: UserProfile | UserProfileErrorResult,
): result is UserProfile => {
  return "jobTitle" in result;
};

export const isUserProfileErrorResponse = (
  result: UserProfile | UserProfileErrorResult,
): result is UserProfileErrorResult => {
  return "error" in result;
};
