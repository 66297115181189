import {
  Button,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react";
import { resultStore } from "../../../store/resultStore";
import { useDetailPageStyles } from "../sharedStyles";

const useStyles = makeStyles({
  flexRow: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("20px"),
  },
  buttonContainer: {
    height: "20px",
    justifyContent: "center",
    alignjobs: "center",
    display: "flex",
    flexDirection: "row",
    backgroundColor: tokens.colorBrandBackground2,
    ...shorthands.borderRadius("4px"),
    ...shorthands.borderWidth("0px"),
    marginLeft: "10px",
  },
  buttonText: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "16px",
    fontStyle: "normal",
    opacity: 1,
    color: tokens.colorBrandForegroundLink,
  },
  shareContainer: {
    height: "20px",
    width: "20px",
    justifyContent: "center",
    alignItems: "center",
    alignjobs: "center",
    display: "flex",
    flexDirection: "row",
    ...shorthands.borderRadius("4px"),
    ...shorthands.borderWidth("0px"),
    ...shorthands.padding("0px", "10px"),
    ":hover": {
      color: tokens.colorBrandForegroundLink,
    },
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
  },
});

export const SBSLinksButton = observer(() => {
  const styles = useDetailPageStyles();
  const buttonStyles = useStyles();

  return (
    <div className={buttonStyles.flexRow}>
      <div className={styles.infoTitle}>SBS Experiments</div>
      <div className={buttonStyles.buttonRow}>
        {resultStore.sbsLinks &&
          resultStore.sbsLinks.map((_) => {
            return (
              <Button
                key={_.text}
                className={buttonStyles.buttonContainer}
                onClick={() => {
                  const sanitizedUrl = encodeURI(_.link ?? "");
                  window.open(sanitizedUrl, "_blank");
                }}
              >
                <span className={buttonStyles.buttonText}>{_.text}</span>
              </Button>
            );
          })}
      </div>
    </div>
  );
});
