import { store } from "../../../../store/store";
import type { IKeyValue } from "../models/CommonModels";

const permaLinks = [
  {
    link: "/llmjudge/stewieleo?uniqueId=StewieLeoTmp_Deepleo",
    pathName: "/llmjudge/stewieleo",
    uniqueId: "StewieLeoTmp_Deepleo",
    detailFile: {
      siteId: "06644c6c-d0c6-410e-b6db-6ef76e8fa566",
      uniqueId: "a55d8c02-76ad-4bee-a5a8-6fdadcd1bb93",
      sharepointLink:
        "https://microsoft.sharepoint-df.com/:x:/r/teams/MicrosoftSearchRelevance/_layouts/15/Doc.aspx?sourcedoc=%7BA55D8C02-76AD-4BEE-A5A8-6FDADCD1BB93%7D&file=stewieleo_details.csv&action=default&mobileredirect=true&share=IQECjF2lrXbuS6Wob9rc0buTATrgj0zLOYMG0RgjLL3PLCQ&cid=7a5561de-e04c-4490-a4c8-33d57be8d089",
    },
    metricFile: {
      siteId: "06644c6c-d0c6-410e-b6db-6ef76e8fa566",
      uniqueId: "1ecd1ca8-6343-4352-b055-a33757438219",
      sharepointLink:
        "https://microsoft.sharepoint-df.com/:x:/r/teams/MicrosoftSearchRelevance/_layouts/15/Doc.aspx?sourcedoc=%7B1ECD1CA8-6343-4352-B055-A33757438219%7D&file=stewieleo_metrics.csv&action=default&mobileredirect=true&share=IQGoHM0eQ2NSQ7BVozdXQ4IZAQaujObaNc1MjwOfcef_sOU&cid=a35ccfd7-a34a-41ef-882e-81af8a6ff1dd",
    },
  },
];

export function getPermaLink() {
  const searchParams = new URLSearchParams(window.location.search);
  const permaLink = permaLinks.find(
    (link) =>
      link.pathName === window.location.pathname &&
      searchParams.get("uniqueId") === link.uniqueId,
  );
  return permaLink;
}

export function getUserAlias() {
  return store.account?.username?.split("@")?.[0] ?? "";
}

// Get the value of a parameter from the URL list. Will return the first value found in the list
export function getUrlParameterValue(
  urls: string[],
  parameterName: string,
  defaultValue: string,
) {
  for (const url of urls) {
    if (!url) {
      continue;
    }
    const urlSearchParams = new URLSearchParams(url);
    const value = urlSearchParams.get(parameterName);
    if (value) {
      return value;
    }
  }
  return defaultValue;
}

export function allowToCheckCompleteness(metricType: string) {
  return metricType === "GroundLeoClaimBreak" || metricType === "SBSLeo";
}

export function parseJsonStr(
  jsonStr: string,
  defaultValue: IKeyValue | undefined,
) {
  try {
    return JSON.parse(jsonStr) as IKeyValue;
  } catch (e) {
    return defaultValue;
  }
}
