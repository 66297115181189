import { JobConfiguration, parseJsonStrWithDefault } from "sydneyeval-shared";
import { store } from "../../../store/store";
import { resultStore } from "../store/resultStore";

export const downloadFile = (content: string, fileName: string) => {
  // Create a Blob object and create a download link
  const blob = new Blob([content]);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileName}`;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const getFileBasename = (path: string) => {
  return path.split("/").pop() ?? "";
};

export const prettyDownloadSetting = (settings: string) => {
  try {
    const jsonObj = JSON.parse(settings);
    return JSON.stringify(jsonObj, null, 2);
  } catch {
    return settings;
  }
};

const checkDownloadPermission = () => {
  if (resultStore.resultJob?.CreatorSmtpAddress === store.account?.username) {
    // return true if the user is the creator
    return true;
  }
  const config = parseJsonStrWithDefault(
    resultStore.resultJob?.Settings,
    JobConfiguration,
    {},
  );
  const userIdInConfig = config?.configs_in_json?.conversations?.user_id;
  if (
    userIdInConfig === undefined ||
    !userIdInConfig.endsWith("@microsoft.com")
  ) {
    // for public job, no limitation for download
    return true;
  }
  // for personal job, need to check if the user is the creator
  return userIdInConfig === store.account?.username;
};

export const hasDownloadPermission = () => {
  const hasReadPermission =
    resultStore.resultJob?.Properties?.HasReadPermission === true;

  const isValidForOwner = checkDownloadPermission();

  const isValidJobType =
    resultStore.resultJob?.ExperimentName !==
      "BizChat-Personalized-Query-Set-Generator-V2" &&
    resultStore.resultJob?.JobTemplateType !== "BingV2" &&
    resultStore.resultJob?.JobTemplateType !== "CWC" &&
    resultStore.resultJob?.JobTemplateType !== "BingGenerator";

  return isValidJobType && isValidForOwner && hasReadPermission;
};
