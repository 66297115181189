import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const sharedStyles = makeStyles({
  root: {
    width: "100%",
  },
  detailContainer: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("20px"),
    ...shorthands.padding("24px", "87px", "24px", "87px"),
    backgroundColor: "white",
    ...shorthands.border("1px", "solid", "#EDEBE9"),
    ...shorthands.borderRadius("8px"),
  },
  inforContainer: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("0px"),
    ...shorthands.padding("24px", "87px", "24px", "87px"),
    backgroundColor: "white",
    ...shorthands.border("1px", "solid", "#EDEBE9"),
    ...shorthands.borderRadius("8px"),
  },
  headerTitleContainer: {
    alignItems: "center",
    display: "flex",
  },
  headerTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "24px",
    lineHeight: "32px",
    fontWeight: 600,
  },
  rowTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    ...shorthands.gap("32px"),
    ...shorthands.margin("8px", "0", "8px", "0"),
    fontSize: "14px",
    alignItems: "center",
  },
  rowReverse: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
    ...shorthands.gap("32px"),
    ...shorthands.margin("8px", "0", "8px", "0"),
  },
  endtimeLabel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  rowSpaceBetwween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    ...shorthands.gap("32px"),
    ...shorthands.margin("8px", "0", "8px", "0"),
    alignItems: "center",
  },
  listFilter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    ...shorthands.gap("32px"),
    ...shorthands.margin("8px", "0", "8px", "0"),
    alignItems: "end",
  },
  filterGroup: {
    flex: 1,
  },
  newTriggerButton: {
    ...shorthands.padding("10px", "12px"),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    width: "171px",
    height: "44px",
    color: "white",
    marginLeft: "auto",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  },
  rowLayout: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    marginTop: "20px",
    ...shorthands.gap("10px"),
  },
  columnLayout: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  flexEnd: {
    justifyContent: "flex-end",
  },
  block: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  section: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("32px"),
  },
  blockSectionTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontWeight: 600,
  },
  blockTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "14px",
    fontWeight: 600,
  },
  label: {
    ...shorthands.margin("5px", "0", "5px", "0"),
    fontFamily: tokens.fontFamilyBase,
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    fontStyle: "normal",
    color: "#605E5C",
  },
  textArea: {
    height: "300px",
  },
  dropdownMenu: {
    backgroundColor: "#F8F7F9",
  },
  listbox: {
    maxHeight: "300px",
  },
  cellRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("8px"),
  },
  tableCell: {
    width: "100%",
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
  },
  title: {
    height: "24px",
    fontSize: "18px",
    color: tokens.colorBrandBackground,
    fontWeight: 600,
    fontFamily: "Segoe UI, sans-serif",
    lineHeight: "20px",
  },
  marginBotton: {
    marginBottom: "20px",
  },
  infoTitle: {
    fontWeight: 600,
    maxWidth: "200px",
  },
  infoContent: {
    maxWidth: "300px",
  },
  inforBreak: {
    lineBreak: "anywhere",
  },
  stopColor: {
    color: tokens.colorPaletteRedForeground1,
  },
  enableColor: {
    color: tokens.colorPaletteGreenForeground1,
  },
  infoGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    ...shorthands.gap("10px"),
    fontWeight: 400,
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("10px"),
  },
});
