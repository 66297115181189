export function groupBy<T>(src: T[], key: (item: T) => string) {
  const result: { [key: string]: T[] } = {};
  src.forEach((i) => {
    const k = key(i);
    if (!result[k]) {
      result[k] = [];
    }
    result[k].push(i);
  });
  return result;
}
