import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  link: {
    color: "rgba(0, 120, 212, 1)",
  },
});

export const GuidanceLink3S = () => {
  const styles = useStyles();
  return (
    <a
      className={styles.link}
      target="_blank"
      href="https://dev.azure.com/msasg/Cortana/_wiki/wikis/M365 Chat.wiki/281358/Generate-SEVAL-Refresh-Token"
      rel="noreferrer"
    >
      {"Prepare for 3S Token"}
    </a>
  );
};

export const GuidanceLinkSEVAL = () => {
  const styles = useStyles();
  return (
    <a
      className={styles.link}
      target="_blank"
      href="https://dev.azure.com/msasg/Cortana/_wiki/wikis/M365 Chat.wiki/281358/Generate-SEVAL-Refresh-Token"
      rel="noreferrer"
    >
      {"Prepare for Sydney User Refresh Token"}
    </a>
  );
};

export const GuidanceLinkTeamsCli = () => {
  const styles = useStyles();
  return (
    <a
      className={styles.link}
      target="_blank"
      href="https://dev.azure.com/msasg/Cortana/_wiki/wikis/M365%20Chat.wiki/272722/Generate-Teams-CLI-Refresh-Token"
      rel="noreferrer"
    >
      {"Prepare for Teams CLI Token"}
    </a>
  );
};
