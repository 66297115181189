import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useQueryManagementStyles = makeStyles({
  tableContainer: {
    width: "100%",
    overflowX: "scroll",
    transform: "rotateX(180deg)",
    "&::-webkit-scrollbar": {
      height: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      ...shorthands.borderRadius("10px"),
      backgroundColor: "#d6dee1",
      boxShadow: " 0 4px 28px rgba(123,151,158,.25)",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#a8bbbf",
    },
  },
  table: {
    width: "100%",
    display: "inline-block",
    borderCollapse: "separate",
    borderSpacing: "0px",
  },
  scrollContainer: {
    transform: "rotateX(180deg)",
    minWidth: "fit-content",
    width: "100%",
  },
  headerColor: {
    backgroundColor: "rgb(250, 249, 248)",
  },
  bodyRow: {
    backgroundColor: "white",
    height: "53px",
    cursor: "pointer",
    width: "100%",
  },
  archived: {
    opacity: "0.5",
  },
  selected: {
    backgroundColor: tokens.colorPaletteBerryBackground1,
  },
  th: {
    boxSizing: "border-box",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "18px",
    fontStyle: "normal",
  },
  columnWrapper: {
    ...shorthands.flex(1),
    boxSizing: "border-box",
    maxWidth: "100%",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    paddingLeft: "20px",
    paddingRight: "8px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  spinner: {
    marginTop: "20px",
    height: "100px",
  },
  cell: {
    ...shorthands.borderRight("none"),
    ...shorthands.borderLeft("none"),
    paddingLeft: "20px",
  },
  toolBar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "8px",
  },
  filter: {
    display: "flex",
    flexDirection: "column",
    "& > ul": {
      marginBottom: "5px",
    },
  },
  label: {
    marginBottom: "5px",
    fontFamily: tokens.fontFamilyBase,
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    fontStyle: "normal",
    color: "#605E5C",
  },
  filterPopover: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      ...shorthands.margin("8px"),
    },
  },
  importExportPopover: {
    display: "flex",
    flexDirection: "column",
    rowGap: "20px",
    width: "360px",
  },
  exportTypes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    columnGap: "5px",
    alignItems: "center",
  },
  exportInput: {
    width: "100%",
  },
  tabs: {
    width: "50%",
  },
  tagsList: {
    listStyleType: "none",
    marginBottom: tokens.spacingVerticalXXS,
    marginTop: 0,
    paddingLeft: 0,
    display: "flex",
    gridGap: tokens.spacingHorizontalXXS,
  },
  tag: {
    display: "inline-flex",
    alignItems: "center",
    ...shorthands.padding("2px", "4px"),
    ...shorthands.margin("0", "6px", "4px", "0"),
    ...shorthands.borderRadius("4px"),
    backgroundColor: "#eaeaea",
    fontSize: "12px",
    width: "auto",
    height: "16px",
  },
  query: {
    justifyContent: "space-between",
    ":hover Button": {
      display: "inline-flex",
      color: tokens.colorBrandBackground,
    },
    ":not(:hover) Button": {
      display: "none",
    },
  },
  queryAction: {
    marginLeft: "auto",
  },
  queryButtons: {
    ...shorthands.padding("10px", "12px"),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    marginLeft: "8px",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
    height: "40px",
  },
  icon: {
    opacity: 0.5,
    color: "#fff",
  },
  noQueries: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
    width: "100%",
    color: "#605E5C",
  },
  assertions: {
    backgroundColor: "#F8F7F9",
    minWidth: "100%",
  },
  noMarginLeft: {
    marginLeft: 0,
  },
  noResults: {
    ...shorthands.padding("6px", "28px"),
    color: "#605E5C",
  },
  assertionExamplesButton: {
    ...shorthands.borderRadius("4px"),
    ...shorthands.padding("2px", "8px"),
    ...shorthands.border("1px", "solid", "#D1D1D1"),
    height: "24px",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    fontStyle: "normal",
  },
  boldLabel: {
    fontWeight: 600,
  },
  panel: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    rowGap: "10px",
    maxHeight: "calc(100vh - 200px)",
  },
  formGroup: {
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    "& > *": {
      display: "flex",
      flexDirection: "column",
      rowGap: "10px",
      width: "100%",
    },
  },
  requiredSymbol: {
    color: "#d33d40",
  },
  formLabel: {
    fontFamily: tokens.fontFamilyBase,
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    fontStyle: "normal",
    color: "#605E5C",
  },
  unexported: {
    display: "flex",
    flexDirection: "column",
  },
  unexportedText: {
    fontFamily: tokens.fontFamilyBase,
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    fontStyle: "normal",
    color: "#d33d40",
    marginBottom: "10px",
  },
  rowStyle: {
    height: "46px",
  },
  headerCell: {
    textAlign: "left",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "18px",
    fontStyle: "normal",
    paddingLeft: "8px",
  },
  assertionActionButton: {
    ...shorthands.padding("10px", "8px"),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    marginTop: tokens.spacingVerticalXXS,
    marginBottom: tokens.spacingVerticalXXS,
    width: "fit-content",
  },
  assertionEditField: {
    width: "100%",
  },
  cancelButton: {
    ...shorthands.padding(0),
    marginTop: tokens.spacingVerticalXXS,
    marginBottom: tokens.spacingVerticalXXS,
    width: "fit-content",
    ":hover": {
      ...shorthands.textDecoration("underline"),
      backgroundColor: "transparent",
    },
  },
  addIcon: {
    opacity: 0.5,
  },
  queryDialog: {
    minWidth: "900px",
  },
  assertionsTable: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: "0px",
  },
  assertionCell: {
    width: "80%",
  },
  assertionText: {
    justifyContent: "space-between",
    ":hover Button": {
      display: "inline-flex",
      color: tokens.colorBrandBackground,
    },
    ":not(:hover) Button": {
      display: "none",
    },
  },
  assertionProp: {
    display: "inline-flex",
    alignItems: "center",
    ...shorthands.padding("2px", "4px"),
    ...shorthands.margin("0", "6px", "4px", "0"),
    ...shorthands.borderRadius("4px"),
    backgroundColor: "#eaeaea",
    fontSize: "12px",
    width: "auto",
    height: "16px",
  },
  exportTabsContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    columnGap: "10px",
  },
  activeTab: {
    width: "100%",
    height: "32px",
    ...shorthands.border(0),
    backgroundColor: "rgba(50, 49, 48, 1)",
    color: "rgba(255, 255, 255, 1)",
    "&:hover": {
      backgroundColor: "rgba(50, 49, 48, 1)",
      color: "rgba(255, 255, 255, 1)",
    },
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  },
  inactiveTab: {
    width: "100%",
    height: "32px",
    ...shorthands.border(0),
    backgroundColor: "rgba(0, 0, 0, 0.06)",
    color: "#242424",
    "&:hover": {
      backgroundColor: "rgba(50, 49, 48, 1)",
      color: "rgba(255, 255, 255, 1)",
    },
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
  },
  importView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    height: "100%",
    width: "100%",
  },
  disabledInstructions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    rowGap: "4px",
  },
  wiki: {
    bottom: "20px",
    right: "30px",
    position: "fixed",
    color: tokens.colorBrandBackground,
    height: "16px",
    display: "flex",
    alignItems: "center",
    columnGap: "2px",
  },
  link: {
    color: tokens.colorBrandBackground,
    ...shorthands.textDecoration("none"),
    ":hover": {
      ...shorthands.textDecoration("underline"),
    },
  },
  dialogActions: {
    display: "flex",
    alignItems: "center",
  },
  importErrorMessage: {
    width: "0",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    ...shorthands.flex(1),
    color: "#d33d40",
  },
  errorMessageContainer: {
    width: "500px",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  errorButton: {
    ...shorthands.borderRadius("4px"),
    ...shorthands.padding("2px", "8px"),
    height: "24px",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "12px",
  },
  annotation: {
    ...shorthands.padding("4px"),
    ...shorthands.border("1px solid #ccc"),
    ...shorthands.borderRadius("5px"),
  },
  truncatedText: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "200px",
  },
});
