import {
  Body1,
  Link,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react";
import { Tip } from "../../../../../components/Shared/Tip";
import { getAppEnv } from "../../../../../helpers/appEnvHelper";
import type { Job } from "../../../models/Job";
import { updateJobIdToJob } from "../../../mutators/updateCaches";

type JobNameCellProps = {
  job: Job;
};

const useStyles = makeStyles({
  jobNameCellBody: {
    display: "flex",
    maxLines: 2,
    ...shorthands.overflow("hidden"),
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    textOverflow: "ellipsis",
    textAlign: "start",
  },
  baseText: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    fontStyle: "normal",
  },
  jobName: {
    whiteSpace: "nowrap",
    ...shorthands.overflow("hidden"),
    textOverflow: "ellipsis",
  },
});

export const JobNameCell = observer((props: JobNameCellProps) => {
  const styles = useStyles();
  const { job } = props;

  return (
    <Tip content={job.JobName ?? ""} relationship="label">
      <div className={mergeClasses(styles.jobNameCellBody, styles.baseText)}>
        <Link
          onClick={() => {
            updateJobIdToJob(job.ID, job);
            window.open(
              getAppEnv().webEndpoint + `/detail/${job.ID}`,
              "_blank",
            );
          }}
        >
          <Body1 className={styles.jobName}>{`${job.JobName}`}</Body1>
        </Link>
      </div>
    </Tip>
  );
});
