import type { GriffelStyle } from "@fluentui/react-components";
import { makeStyles } from "@fluentui/react-components";
import merge from "lodash/merge";
import type { DeviceSize } from "../Wrappers/ResponsiveProvider";
import { useDeviceSize } from "../Wrappers/ResponsiveProvider";

export const makeResponsiveStyles = <Slots extends string | number>(
  styles: Record<Slots, GriffelStyle>,
  override: Partial<Record<DeviceSize, Partial<Record<Slots, GriffelStyle>>>>,
): (() => Record<Slots, string>) => {
  const xs = makeStyles(merge({}, styles, override.xs));
  const sm = makeStyles(merge({}, styles, override.sm));
  const md = makeStyles(merge({}, styles, override.md));
  const lg = makeStyles(merge({}, styles, override.lg));

  const Current = () => {
    const { deviceSize } = useDeviceSize();
    switch (deviceSize) {
      case "xs":
        return xs();
      case "sm":
        return sm();
      case "md":
        return md();
      case "lg":
        return lg();
    }
  };

  return Current;
};
