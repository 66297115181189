import EcsClient, { Models as EcsClientModels } from "@skype/ecsclient";
import { ClientFeatureFlagConfig } from "sydneyeval-shared";
import type { STPromise } from "synctasks";
import { Resolved } from "synctasks";
import { updateFeatureFlagFlights } from "../mutators/updateFeatures";
import { getAppEnv } from "./appEnvHelper";
import { setFlightedFeatureFlags } from "./cacheHelper";
import { telemetryHelper } from "./telemetryHelper";

interface SevalEcsPayload extends EcsClientModels.EcsResponseBase {
  Seval: ClientFeatureFlagConfig;
}

type SevalECSPortalEnvironment = "Staging" | "Prod";

let isEcsInitialized = false;

const ecsClient = new EcsClient<SevalEcsPayload>();
const ecsConfigMap = new Map<string, string>();

const setECSConfig = (key: string, config: string) => {
  ecsConfigMap.set(key, config);
};

const getECSConfig = (key: string) => {
  return ecsConfigMap.get(key);
};

export const getECSPersistantCacheKey = (
  userId: string,
  tenantId: string,
): string => {
  const localStorageKey = "SevalFlights";
  return localStorageKey + userId + tenantId;
};

export const getSEVALEnvironment = (): SevalECSPortalEnvironment => {
  switch (getAppEnv().type) {
    case "LOCALHOST":
      return "Staging";
    case "STAGING":
      return "Staging";
    case "PROD":
      return "Prod";
  }
};

const getEcsParameters = (
  userId: string,
  tenantId: string,
): STPromise<{ [key: string]: string | number }> =>
  Resolved({
    agents: "Seval",
    TenantID: tenantId,
    UserID: userId,
    SEVALEnvironment: getSEVALEnvironment(),
    Scenario: "PortalClient",
  });

export const initializeEcsClient = (
  userId: string,
  tenantId: string,
): Promise<void> => {
  if (isEcsInitialized) {
    return Promise.resolve();
  }

  isEcsInitialized = true;

  const ecsClientConfig: EcsClientModels.ConfigModel<SevalEcsPayload> = {
    hosts: ["https://config.edge.skype.net"],
    clientName: "Seval",
    clientVersion: "1.0.0.0",
    configsToFetch: [
      EcsClientModels.EcsConfigType.Default,
      EcsClientModels.EcsConfigType.User,
    ],
    getEcsParameters: () => getEcsParameters(userId, tenantId),
    initialAppActiveState: true,
    databaseInterface: {
      putData: (_, config) => {
        telemetryHelper.logNetworkEvent("GetECSConfig", config.config.Seval);

        const flightedFeatureFlags = (() => {
          try {
            const parsedConfig = ClientFeatureFlagConfig(
              config.config.Seval,
              "parsedConfig",
            );
            return parsedConfig.portal;
          } catch {
            return {};
          }
        })();

        updateFeatureFlagFlights(flightedFeatureFlags);
        setFlightedFeatureFlags(flightedFeatureFlags);

        setECSConfig(
          getECSPersistantCacheKey(userId, tenantId),
          JSON.stringify(config),
        );
        return Resolved();
      },
      getData: () => {
        const config = getECSConfig(getECSPersistantCacheKey(userId, tenantId));

        try {
          if (config) {
            return Resolved(JSON.parse(config));
          }
        } catch {
          // Ignore error
        }

        return Resolved();
      },
    },
  };

  return new Promise((resolve, reject): void => {
    ecsClient.initialize(ecsClientConfig).then(
      () => {
        const subscription = ecsClient.configUpdated.subscribe(() => {
          subscription.unsubscribe();
          resolve();
        });
        ecsClient.requestUpdate();
      },
      () => {
        telemetryHelper.logErrorEvent("ECSInitError");
        reject();
      },
    );
  });
};
