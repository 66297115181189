import { mutatorAction } from "satcheljs";
import { store } from "../store/store";

export const updateTokenRefreshError = mutatorAction(
  "updateTokenRefreshError",
  (error: string) => {
    store.tokenRefreshError = error;
  },
);

export const resetTokenRefreshError = mutatorAction(
  "resetTokenRefreshError",
  () => {
    store.tokenRefreshError = undefined;
  },
);
