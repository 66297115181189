import {
  Button,
  Popover,
  PopoverSurface,
  PopoverTrigger,
} from "@fluentui/react-components";
import { Info20Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react";
import { getTimeDuration } from "../../../helpers/formatHelper";
import { resultStore } from "../../../store/resultStore";
import { useDetailPageStyles } from "../sharedStyles";

export const InforButtonView = observer(() => {
  const styles = useDetailPageStyles();

  const job = resultStore.resultJob;
  return (
    <Popover>
      <PopoverTrigger disableButtonEnhancement>
        <Button icon={<Info20Regular />}>Info</Button>
      </PopoverTrigger>

      <PopoverSurface tabIndex={-1}>
        <div className={styles.infoGrid}>
          {job?.StartTime && job?.EndTime && (
            <>
              <div className={styles.infoTitle}>Job Duration</div>
              <div>{getTimeDuration(job.StartTime, job.EndTime)}</div>
            </>
          )}
          <div className={styles.infoTitle}>Job Template</div>
          <div>
            [{job?.JobTemplateId}] {job?.ExperimentName}
          </div>
          <div className={styles.infoTitle}>Build Version</div>
          <div>{job?.TemplateBuildVersion}</div>
        </div>
      </PopoverSurface>
    </Popover>
  );
});
