import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  makeStyles,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { updateSBSConfirmationResolve } from "../../../mutators/jobMutators";
import { jobStore } from "../../../store/jobStore";

const useStyles = makeStyles({
  dialogContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
});

export const JobSBSConfirmationDialog = observer(() => {
  const styles = useStyles();

  return (
    <Dialog
      modalType="alert"
      open={jobStore.SBSConfirmationResolve !== undefined}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            {"Privacy Consent Form for Turning on SBS Experiment:"}
          </DialogTitle>
          <DialogContent className={styles.dialogContent}>
            <Body1>
              {`The SBS experiment is needed for BizChat flight review and debugging Search NDCG. \
              Creating SBS experiment means that it will share your scrape data on SBS Tool, which will be viewed by Experiment Judges. \
              If you consent to sharing with SBS user, please click "Accept", otherwise click "Reject".`}
            </Body1>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                jobStore.SBSConfirmationResolve?.(true);
                updateSBSConfirmationResolve(undefined);
              }}
              appearance="primary"
            >
              Accept
            </Button>
            <Button
              onClick={() => {
                jobStore.SBSConfirmationResolve?.(false);
                updateSBSConfirmationResolve(undefined);
              }}
              appearance="secondary"
            >
              Reject
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
});
