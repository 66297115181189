export const checkInputFolder = (value: string | undefined, path: string) => {
  if (value === undefined) {
    return undefined;
  }

  if (value === "") {
    return { path, message: "Input folder should not be empty!" };
  }

  return undefined;
};
