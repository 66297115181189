import { action } from "satcheljs";
import type { UploadTokenType } from "../models/Types";

export const uploadToken = action(
  "uploadToken",
  (
    tokenType: UploadTokenType,
    tokenName: string,
    tokenValue: string,
    onSuccess: () => void,
    onError: (error: any) => void,
  ) => ({
    tokenType,
    tokenName,
    tokenValue,
    onSuccess,
    onError,
  }),
);
