import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Input,
  tokens,
} from "@fluentui/react-components";
import React, { useEffect } from "react";
import {
  getKeyVaultSecretByKey,
  setKeyVaultSecretByKey,
} from "../../helpers/apiHelper";
import { telemetryHelper } from "../../helpers/telemetryHelper";
import { useToast } from "../Wrappers/ToasterProvider";

type KeyVaultUpdatingDialogProps = {
  isOpen: boolean;
  onComplete: () => void;
};

export const KeyVaultUpdatingDialog = (props: KeyVaultUpdatingDialogProps) => {
  const [keyVaultKey, setKeyVaultKey] = React.useState<string>("");
  const [keyVaultValue, setKeyVaultValue] = React.useState<string>("");
  const toaster = useToast();

  useEffect(() => {
    setKeyVaultKey("");
    setKeyVaultValue("");
  }, [props.isOpen]);

  return (
    <Dialog
      open={props.isOpen}
      onOpenChange={() => {
        props.onComplete();
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>
            <div>{`Update KeyVault Secret Value`}</div>
          </DialogTitle>
          <DialogContent>
            <Field
              required
              label={"KeyVault Secret Key"}
              validationMessage={
                keyVaultKey.trim().length === 0
                  ? "KeyVault Secret Key cannot be empty."
                  : undefined
              }
            >
              <Input
                style={{ width: "100%" }}
                size="medium"
                value={keyVaultKey}
                onChange={(_, data) => {
                  setKeyVaultKey(data.value);
                }}
              />
            </Field>
            <Field label={"KeyVault Secret Value"}>
              <Input
                style={{ width: "100%" }}
                size="medium"
                value={keyVaultValue}
                onChange={(_, data) => {
                  setKeyVaultValue(data.value);
                }}
              />
            </Field>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={keyVaultKey.trim().length === 0}
              appearance="primary"
              onClick={() => {
                toaster.onToastStart("Getting KeyVault secret value...");
                getKeyVaultSecretByKey({
                  SecretName: keyVaultKey,
                })
                  .then((value) => {
                    toaster.onToastSuccess("Got KeyVault secret value.");
                    setKeyVaultValue(value);
                    telemetryHelper.logDevEvent("GetKeyVaultValue", {
                      key: keyVaultKey,
                    });
                  })
                  .catch((error) => {
                    toaster.onToastFailure(error.message);
                  });
              }}
            >
              Get Secret Value
            </Button>
            <Button
              disabled={keyVaultValue.trim().length === 0}
              appearance="primary"
              style={{ backgroundColor: tokens.colorStatusDangerForeground1 }}
              onClick={() => {
                toaster.onToastStart("Setting KeyVault secret value...");
                setKeyVaultSecretByKey({
                  SecretName: keyVaultKey,
                  SecretValue: keyVaultValue,
                })
                  .then(() => {
                    toaster.onToastSuccess("Set KeyVault secret value.");
                    telemetryHelper.logDevEvent("SetKeyVaultValue", {
                      key: keyVaultKey,
                    });
                  })
                  .catch((error) => {
                    toaster.onToastFailure(error.message);
                  });
              }}
            >
              Set Secret Value
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
