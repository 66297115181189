"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TelemetryLogger = void 0;
const webjs_sdk_1 = require("@aria/webjs-sdk");
class TelemetryLogger {
    constructor(telemetryInfos) {
        this.telemetryInfos = telemetryInfos;
        this.initializeAriaLogger();
        if (typeof window !== "undefined") {
            window.addEventListener("beforeunload", webjs_sdk_1.AWTLogManager.flushAndTeardown);
        }
    }
    logBasicEvent(basicInfo, dataBag) {
        if (this.telemetryInfos.appEnvType === "LOCALHOST") {
            return;
        }
        let dataBagString = "";
        if (dataBag) {
            dataBagString = JSON.stringify(dataBag);
        }
        const eventData = new Map([
            ["AccountID", basicInfo.localAccountId],
            ["Event", basicInfo.eventType],
            ["DataBag", dataBagString],
            ["AppName", this.telemetryInfos.appEnvType],
            ["eventName", basicInfo.eventName],
            ["sessionId", basicInfo.sessionId],
            ["reuqestId", basicInfo.requestId],
            ["scenarioId", basicInfo.scenarioId],
        ]);
        this.logData(eventData);
    }
    logData(eventData) {
        webjs_sdk_1.AWTLogManager.getLogger(this.telemetryInfos.ariaToken)?.logEvent(this.getPropertiesFromEvent(eventData));
    }
    getPropertiesFromEvent(eventData) {
        if (!webjs_sdk_1.AWTLogManager.getLogger(this.telemetryInfos.ariaToken)) {
            this.initializeAriaLogger();
        }
        const properties = new webjs_sdk_1.AWTEventProperties(this.telemetryInfos.ariaTableName);
        for (const [propertyName, propertyValue] of eventData) {
            if (propertyValue === undefined) {
                continue;
            }
            let propertyType = webjs_sdk_1.AWTPropertyType.Unspecified;
            if (typeof propertyValue === "number") {
                propertyType = webjs_sdk_1.AWTPropertyType.Int64;
            }
            else if (typeof propertyValue === "string") {
                propertyType = webjs_sdk_1.AWTPropertyType.String;
            }
            else if (typeof propertyValue === "boolean") {
                propertyType = webjs_sdk_1.AWTPropertyType.Boolean;
            }
            properties.setProperty(propertyName, propertyValue, propertyType);
        }
        return properties;
    }
    initializeAriaLogger() {
        webjs_sdk_1.AWTLogManager.setTransmitProfile(webjs_sdk_1.AWT_REAL_TIME);
        webjs_sdk_1.AWTLogManager.initialize(this.telemetryInfos.ariaToken);
        const context = webjs_sdk_1.AWTLogManager.getSemanticContext();
        if (typeof window !== "undefined") {
            context.setAppLanguage(window.navigator.language);
        }
        else {
            context.setAppLanguage("en-US");
        }
    }
}
exports.TelemetryLogger = TelemetryLogger;
