import { Button } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import {
  onNextClick,
  onReleaseClick,
  onSaveClick,
} from "../../actions/productSettingsActions";
import {
  getCurrentPipelinesError,
  getPipelineNameError,
  getPipelineSettingsError,
  getProductNameError,
} from "../../selectors/productSelectors";
import { productSettingsStore } from "../../store/productSettingsStore";
import { useStyle } from "./sharedStyles";

/** Selectors to calculate the button status */

const isSaveButtonVisible = () => {
  const scenario = productSettingsStore.scenario;
  return (
    scenario.scenarioType === "Creating" ||
    (scenario.scenarioType === "EditingProduct" &&
      scenario.currentPipeline === undefined &&
      scenario.selectedProduct.status === "draft")
  );
};

const isSaveButtonEnabled = () => {
  return (
    getProductNameError.get() === undefined &&
    getCurrentPipelinesError.get() === undefined
  );
};

const isNextButtonVisible = () => {
  const scenario = productSettingsStore.scenario;
  return scenario.scenarioType === "Creating";
};

const isNextButtonEnabled = () => {
  return isSaveButtonEnabled();
};

const isReleaseButtonVisible = () => {
  const scenario = productSettingsStore.scenario;
  return (
    scenario.scenarioType === "EditingProduct" &&
    scenario.currentPipeline === undefined
  );
};

const isReleaseButtonEnabled = () => {
  return (
    getProductNameError.get() === undefined &&
    getCurrentPipelinesError.get() === undefined
  );
};

const isSavePipelineButtonVisible = () => {
  const scenario = productSettingsStore.scenario;
  return (
    scenario.scenarioType === "EditingProduct" &&
    scenario.currentPipeline !== undefined
  );
};

const isSavePipelineButtonEnabled = () => {
  return (
    getPipelineNameError.get() === undefined &&
    getPipelineSettingsError.get() === undefined
  );
};

export const ProductButtons = observer(() => {
  const styles = useStyle();
  const scenario = productSettingsStore.scenario;
  switch (scenario.scenarioType) {
    case "Listing":
    case "Loading":
      return <></>;

    default:
      break;
  }

  return (
    <div className={styles.buttonRow}>
      {isSaveButtonVisible() && (
        <Button
          disabled={!isSaveButtonEnabled()}
          appearance="secondary"
          onClick={onSaveClick}
        >
          Save as draft
        </Button>
      )}
      {isNextButtonVisible() && (
        <Button
          disabled={!isNextButtonEnabled()}
          appearance="primary"
          onClick={onNextClick}
        >
          Next
        </Button>
      )}
      {isReleaseButtonVisible() && (
        <Button
          disabled={!isReleaseButtonEnabled()}
          appearance="primary"
          onClick={onReleaseClick.bind(null, false)}
        >
          Release
        </Button>
      )}
      {isSavePipelineButtonVisible() && (
        <Button
          disabled={!isSavePipelineButtonEnabled()}
          appearance="primary"
          onClick={onSaveClick}
        >
          Save
        </Button>
      )}
    </div>
  );
});
