import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Link,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { useState } from "react";

const useStyles = makeStyles({
  additionalLinkText: {
    fontSize: "12px",
    fontWeight: 400,
    color: tokens.colorStatusWarningForeground1,
  },
});

export const NoAccessView = observer(() => {
  const styles = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Link
        className={styles.additionalLinkText}
        target="_blank"
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        {"(No Access?)"}
      </Link>
      <Dialog
        open={isDialogOpen}
        onOpenChange={() => {
          setIsDialogOpen(false);
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{`Do not have access to user manual?`}</DialogTitle>
            <DialogContent>
              <Body1>Request ReadWrite access to </Body1>
              <Link
                target="_blank"
                href="https://coreidentity.microsoft.com/manage/Entitlement/entitlement/convplatform-amih"
              >
                ConvPlatformADOAccess
              </Link>
              <Body1>. This gives access to ADO and other resources</Body1>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setIsDialogOpen(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
});
