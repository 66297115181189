export interface IDimensionConfig {
  name: string;
  nameNoun: string;
  tooltip: string;
  scales: IScaleConfig[];
  decisionDrivers: IdecisionDrivers[];
}

interface IScaleConfig {
  value: "verypoor" | "notgreat" | "prettygood" | "excellent" | string; // The label stored in feedback
  label: string; // The user-friendly name user sees
  tooltip: string;
}

interface IdecisionDrivers {
  name: string;
  tooltip: string;
}

export const ScaleValueUserFriendlyMap: Record<string, boolean> = {
  verypoor: false,
  notgreat: false,
  prettygood: true,
  excellent: true,
};

export const SBSDimensionConfigs: IDimensionConfig[] = [
  {
    name: "accurate",
    nameNoun: "accuracy",
    tooltip:
      "truthfulness; logic and reasoning; language matching; citation availability for verification; appropriate sentiment interpretation",
    scales: [
      {
        value: "verypoor",
        label: "Completely Inaccurate",
        tooltip: `- complete hallucinations, inaccurate citations, or logical flaws that invalidate the response
- Example: Submitted a request to Copilot, but the response is returned in a completely different language (without user prompting for a different language). `,
      },
      {
        value: "notgreat",
        label: "Notable Inaccuracy",
        tooltip: `- response contains incorrect or untruthful information that impacts the overall message
- Example: Asked Copilot to summarize emails and order them by priority, and Copilot attributes one of the emails to the wrong sender. `,
      },
      {
        value: "dontKnow",
        label: "N/A",
        tooltip: "I don't know",
      },
      {
        value: "prettygood",
        label: "Minor Inaccurate",
        tooltip: `- response contains small errors that don't impact the overall message
- Example: Asked Copilot for an ordered list of the people that you work with the most, and the most relevant person is not listed first. The list is accurate, but the order (the way it is presented) is incorrect. `,
      },
      {
        value: "excellent",
        label: "Completely Accurate",
        tooltip: `- completely accurate information and flawless logic. `,
      },
    ],
    decisionDrivers: [
      {
        name: "truthfulness",
        tooltip: "response is accurate with no hallucinated information",
      },
      {
        name: "logic and reasoning",
        tooltip: "response is logical; reasoning is easy to follow",
      },
      {
        name: "language matching",
        tooltip:
          "language of response matches language of query, unless requested",
      },
      {
        name: "verifiable citations",
        tooltip: "citations are included and info can be easily verified",
      },
      {
        name: "Grammatical accuracy and appropriateness",
        tooltip:
          "grammar is correct; wording and terminology are appropriate (non-EN languages)",
      },
      {
        name: "sentiment interpretation",
        tooltip:
          "response matches tone, including appropriate interpretation of sarcasm",
      },
      {
        name: "other_accurate",
        tooltip: "other reasons for accuracy",
      },
    ],
  },
  {
    name: "complete",
    nameNoun: "completeness",
    tooltip:
      "comprehensiveness; transparency about which information is included/excluded",
    scales: [
      {
        value: "verypoor",
        label: "Critically Incomplete",
        tooltip: `- response is missing significant information, the system does not acknowledge that it is missing, and the response is not usable as given
- Example: Asked Copilot to summarize emails from a specific person (and you know you have recently received emails from that person). Copilot responds with an apology, indicating that it can't find any emails from that person. `,
      },
      {
        value: "notgreat",
        label: "Missing Relevant Information",
        tooltip: `- response leaves out critical information, and the system acknowledges missing information (but does not provide easy access to additional information), so the response contains some usable content but is still missing key components
- Example: Asked Copilot to summarize the latest developments on a specific (and ongoing) project, and it summarized only the developments from the previous month. `,
      },
      {
        value: "dontKnow",
        label: "N/A",
        tooltip: "I don't know",
      },
      {
        value: "prettygood",
        label: "Mostly Complete",
        tooltip: `- small pieces of information are missing, but including them would not significantly impact the response
- Example: Asked Copilot to show all Teams messages received yesterday from a specific person. Copilot accurately says that you received seven messages from that person, lists out five of them, and asks if you would like to see the other two messages. `,
      },
      {
        value: "excellent",
        label: "Perfectly Complete",
        tooltip: `- response completely answers the question and includes all relevant information. `,
      },
    ],
    decisionDrivers: [
      {
        name: "comprehensive",
        tooltip:
          "response completely answers the question with no additional information required",
      },
      {
        name: "transparent",
        tooltip:
          "if information is missing, the system acknowledges the missing information and may provide access to it",
      },
      {
        name: "other_complete",
        tooltip: "other reasons for completeness",
      },
    ],
  },
  {
    name: "relevant",
    nameNoun: "relevance",
    tooltip: "topicality; freshness; localization; repetition; redundancy",
    scales: [
      {
        value: "verypoor",
        label: "Completely Irrelevant",
        tooltip: `- response has nothing to do with the question asked
- Example: Asked Copilot to summarize outstanding tasks for a current project, but Copilot summarized tasks for a completely different project. `,
      },
      {
        value: "notgreat",
        label: "Mostly Irrelevant",
        tooltip: `- more than half of the response is unrelated to the question
- Example: Asked Copilot to summarize emails received in the last 24 hours. Of the ten emails listed in the response, seven of them are from outside of the specified period. `,
      },
      {
        value: "dontKnow",
        label: "N/A",
        tooltip: "I don't know",
      },
      {
        value: "prettygood",
        label: "Mostly Relevant",
        tooltip: `- most of the response is related, but there is extra information that is not relevant
- Example: Asked Copilot for instructions on how to save a document in Microsoft Word. Copilot responded with the correct instructions and cited a link to a helpful website; however, the response also included a citation to a completely irrelevant Word document that the user wrote recently. `,
      },
      {
        value: "excellent",
        label: "Completely Relevant",
        tooltip: `- response completely answers the question with little to no unrelated information. `,
      },
    ],
    decisionDrivers: [
      {
        name: "topicality",
        tooltip:
          "response answers the user’s question; the intent is satisfied",
      },
      {
        name: "freshness",
        tooltip:
          "response includes current information and does not include unnecessary or outdated information",
      },
      {
        name: "localization",
        tooltip:
          "response includes information that is specific to the user’s general location or specified requirement",
      },
      {
        name: "repetition",
        tooltip: "response is streamlined and lacks repetition",
      },
      {
        name: "redundancy",
        tooltip: "response is comprehensive but simple, and lacks redundancy",
      },
      {
        name: "other_relevant",
        tooltip: "other reasons for relevance",
      },
    ],
  },
  {
    name: "effortless",
    nameNoun: "effortlessness",
    tooltip:
      "legibility and formatting; structure and flow; verbosity; tone or style appropriateness; link availability for further reading; detail and specificity",
    scales: [
      {
        value: "verypoor",
        label: "Completely Effortful",
        tooltip: `- illegible, difficult to understand, tone does not match request
- Example: User asked Copilot to explain some project they are onboarding to. The response contains references to 6 different acronyms with no expansions, and several technical terms that are internal to the project without sufficient context to grasp their meaning. `,
      },
      {
        value: "notgreat",
        label: "Mostly Effortful",
        tooltip: `- helpful information is buried among mostly unnecessary and confusing information
- Example: Asked Copilot which out of two systems is faster, A or B; the response includes many details about the two systems' performance and latency, and one of the facts close to the end of the response mentions that A is faster than B. `,
      },
      {
        value: "dontKnow",
        label: "N/A",
        tooltip: "I don't know",
      },
      {
        value: "prettygood",
        label: "Mostly Effortless",
        tooltip: `- response is good, but there are obvious edits that the user can do to make it clearer
- Example: Asked Copilot to summarize the outcomes from a recent meeting in order to be shared with the meeting participants. The list is complete and accurate, but some of the tasks are worded in a way that sounds pompous. The user must do some light editing before sending out the list. `,
      },
      {
        value: "excellent",
        label: "Completely Effortless",
        tooltip: `- response is detailed but succinct, easy to follow and interpret, and it can be used almost exactly as-is with little to no user edits. `,
      },
    ],
    decisionDrivers: [
      {
        name: "legibility and formatting",
        tooltip: "response is logically formatted and easy to read",
      },
      {
        name: "structure and flow",
        tooltip:
          "response is structured in a way that flows easily and is simple to follow",
      },
      {
        name: "verbosity",
        tooltip:
          "response is an appropriate length, and answers the question without being overly verbose or too brief",
      },
      {
        name: "appropriate style and tone",
        tooltip: "style and tone of response are aligned with the request",
      },
      {
        name: "link availability",
        tooltip:
          "if additional information is available, then links are provided",
      },
      {
        name: "detail and specificity",
        tooltip:
          "level of detail and specificity in the response is appropriate for the request, and includes enough information to answer the question without being overwhelming",
      },
      {
        name: "other_effortless",
        tooltip: "other reasons for effortlessness",
      },
    ],
  },
  {
    name: "exceptional",
    nameNoun: "exceptionality",
    tooltip:
      "intent understanding and interpretation; novelty; engagement and chit chat; human vs. robotic behavior; task complexity",
    scales: [
      {
        value: "verypoor",
        label: "Not at all Exceptional",
        tooltip: `- response is not impressive, and information is presented in a way that is not exciting or original and very machine-like
- Example: A user asks Copilot about the leave policy for an illness or injury, saying “tell me how I can use my vacation days to accommodate an illness or injury.” Copilot responds with the correct policy information but ends the answer with suggestions for vacation destinations and “enjoy your vacation!” `,
      },
      {
        value: "notgreat",
        label: "Not Very Exceptional",
        tooltip: `- response is somewhat helpful, but presented in a way that it is clearly not from a competent human being or an intelligent system
- Example:  Asked Copilot for help writing a self-evaluation, providing feedback from colleagues, examples of projects, and an assessment of the user's quality of work. Copilot's response is very robotic, and the tone and words used make it clear that the response was not generated by a human or intelligent system. Most of the assessment needs to be rewritten. `,
      },
      {
        value: "dontKnow",
        label: "N/A",
        tooltip: "I don't know",
      },
      {
        value: "prettygood",
        label: "Mostly Exceptional",
        tooltip: `- response answers the question in a mostly human-like way, but requires a few adjustments to tone and content before using
- Example: Asked Copilot to rewrite a portion of a document to make it more professional. The result is better than the original version, but still needs some (reasonable amount of) tweaking to be ready to use. `,
      },
      {
        value: "excellent",
        label: "Completely Exceptional",
        tooltip: `- response is original, novel, and exciting, and the tone matches the nature of the request; or the response demonstrates exceptional intelligence
- Example: Participants in a meeting are Dana, Jim, Steve and Alice. The user asks the system what action items were assigned to Alex's team in that meeting. Copilot responds that there was no Alex in the meeting, but perhaps the user meant Alice who has a similar name? and lists the action items assigned to Alice's team. `,
      },
    ],
    decisionDrivers: [
      {
        name: "understanding",
        tooltip:
          "system correctly understands and interprets the user’s intent, and the response is aligned with user expectations",
      },
      {
        name: "novelty",
        tooltip: "response is exciting, impressive and novel",
      },
      {
        name: "engagement",
        tooltip:
          "the system responds in a way that is conversational and engaging",
      },
      {
        name: "human behavior",
        tooltip:
          "the system responds in a way that is more human-like than robot-like",
      },
      {
        name: "task complexity",
        tooltip:
          "regardless of the complexity of the request, the response is impressive and seems like it was generated by a highly capable person",
      },
      {
        name: "other_exceptional",
        tooltip: "other reasons for exceptionality",
      },
    ],
  },
];
