"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTemplateType = exports.TemplateType = void 0;
var TemplateType;
(function (TemplateType) {
    TemplateType["JSON"] = "JSON";
    TemplateType["Normal"] = "Normal";
    TemplateType["BingV2"] = "BingV2";
    TemplateType["OPG"] = "OPG";
    TemplateType["BizChatFlightReview"] = "BizChatFlightReview";
    TemplateType["GeneralSkipScraping"] = "GeneralSkipScraping";
    TemplateType["Batch"] = "Batch";
    TemplateType["CWC"] = "CWC";
})(TemplateType || (exports.TemplateType = TemplateType = {}));
const getTemplateType = (templateType) => {
    switch (templateType) {
        case undefined:
            return undefined;
        case "BingV2":
            return TemplateType.BingV2;
        case "CWC":
            return TemplateType.CWC;
        case "RAI":
            return TemplateType.JSON;
        case "Default":
            return TemplateType.JSON;
        case "OPG":
            return TemplateType.OPG;
        case "BizChatFlightReview":
            return TemplateType.BizChatFlightReview;
        case "GeneralSkipScraping":
            return TemplateType.GeneralSkipScraping;
        default:
            return TemplateType.Normal;
    }
};
exports.getTemplateType = getTemplateType;
