import {
  Button,
  Checkbox,
  Combobox,
  Dropdown,
  Input,
  Label,
  Option,
  Popover,
  PopoverSurface,
  PopoverTrigger,
} from "@fluentui/react-components";
import {
  ArrowReset24Regular,
  Dismiss12Regular,
  Filter24Regular,
  Search20Regular,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { getMatchingOptions } from "../../../helpers/queryManagementHelper";
import { useQueryManagementStyles } from "../styles/QueryManagementStyles";
import type { Filters, SelectOptions } from "../types/Display";

type QueryManagementFilterPopoverProps = {
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  selectOptions: SelectOptions;
  emptyFilters: () => boolean;
};

export const QueryManagementFilterPopover = observer(
  (props: QueryManagementFilterPopoverProps) => {
    const { filters, setFilters, selectOptions, emptyFilters } = props;
    const styles = useQueryManagementStyles();

    const [comboboxValue, setComboboxValue] = useState({
      segmentTwo: "",
      sets: "",
      freOrSparklePrompt: "",
    });

    const onTagClick = (
      filter: "segmentTwo" | "sets" | "freOrSparklePrompt",
      option: string,
    ) => {
      setFilters({
        ...filters,
        [filter]: filters[filter].filter((value) => value !== option),
      });
    };

    const onMultiselectComboboxChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      setComboboxValue({
        ...comboboxValue,
        [event.target.id]: event.target.value,
      });
    };

    const onMultiselectOptionSelect = (
      filter: "segmentTwo" | "sets" | "freOrSparklePrompt",
      options: string[],
    ) => {
      if (options.every((option) => !option)) {
        return;
      }

      setFilters({
        ...filters,
        [filter]: options,
      });

      setComboboxValue({
        ...comboboxValue,
        [filter]: "",
      });
    };

    const resetFilters = () => {
      setFilters({
        segmentTwo: [],
        utterance: "",
        queryOwner: "",
        environment: "",
        term: "",
        freOrSparklePrompt: [],
        sets: [],
        assertionOwner: "",
        showArchived: false,
        selectedOnly: false,
      });
    };

    const renderFilters = () => (
      <>
        <Button
          aria-label="Clear Filters"
          appearance="secondary"
          icon={<ArrowReset24Regular />}
          onClick={resetFilters}
          disabled={emptyFilters()}
        >
          Clear Filters
        </Button>
        <div className={styles.filter}>
          <Label
            className={styles.label}
            htmlFor="query"
            data-testid="query-label"
          >
            Query
          </Label>
          <Input
            id="query"
            data-testid="query-input"
            autoComplete="off"
            placeholder="Search"
            aria-label="Filter by Query"
            value={filters.utterance}
            contentAfter={<Search20Regular />}
            onChange={(_, data) =>
              setFilters({
                ...filters,
                utterance: data.value,
              })
            }
          />
        </div>
        <div className={styles.filter}>
          <Label
            className={styles.label}
            htmlFor="segmentTwo"
            data-testid="seg2-label"
          >
            Segment 2
          </Label>
          {filters.segmentTwo.length ? (
            <ul className={styles.tagsList}>
              {filters.segmentTwo.map((segment) => (
                <li key={segment}>
                  <Button
                    size="small"
                    shape="circular"
                    appearance="primary"
                    icon={<Dismiss12Regular />}
                    iconPosition="after"
                    onClick={() => onTagClick("segmentTwo", segment)}
                  >
                    {segment.split(" - ")[0]}
                  </Button>
                </li>
              ))}
            </ul>
          ) : null}
          <Combobox
            autoComplete="off"
            id="segmentTwo"
            placeholder="Select multiple"
            aria-label="Filter by Segment 2"
            multiselect
            freeform
            positioning="below-start"
            value={comboboxValue.segmentTwo}
            selectedOptions={filters.segmentTwo}
            onChange={onMultiselectComboboxChange}
            onOptionSelect={(_, data) => {
              onMultiselectOptionSelect("segmentTwo", data.selectedOptions);
            }}
          >
            {getMatchingOptions(
              comboboxValue.segmentTwo,
              selectOptions.segmentTwo,
              "No results",
            )}
          </Combobox>
        </div>
        <div className={styles.filter}>
          <Label
            className={styles.label}
            htmlFor="queryOwner"
            data-testid="queryOwner-label"
          >
            Query Owner
          </Label>
          <Input
            id="queryOwner"
            data-testid="queryOwner-input"
            autoComplete="off"
            placeholder="Search"
            aria-label="Filter by Query Owner"
            value={filters.queryOwner}
            contentAfter={<Search20Regular />}
            onChange={(_, data) =>
              setFilters({
                ...filters,
                queryOwner: data.value,
              })
            }
          />
        </div>
        <div className={styles.filter}>
          <Label
            className={styles.label}
            htmlFor="environment"
            data-testid="env-label"
          >
            Environment
          </Label>
          <Dropdown
            id="environment"
            placeholder="Select"
            aria-label="Filter by Environment"
            value={filters.environment}
            selectedOptions={[filters.environment]}
            onOptionSelect={(_, option) => {
              setFilters({
                ...filters,
                environment: option.optionValue ?? "",
              });
            }}
          >
            {selectOptions.environment.map((environment, index) => (
              <Option key={index} value={environment}>
                {environment}
              </Option>
            ))}
          </Dropdown>
        </div>
        <div className={styles.filter}>
          <Label
            className={styles.label}
            htmlFor="term"
            data-testid="term-label"
          >
            Term
          </Label>
          <Dropdown
            id="term"
            placeholder="Select"
            aria-label="Filter by term"
            value={filters.term}
            selectedOptions={[filters.term]}
            onOptionSelect={(_, option) => {
              setFilters({
                ...filters,
                term: option.optionValue ?? "",
              });
            }}
          >
            {selectOptions.term.map((t, index) => (
              <Option key={index} value={t}>
                {t}
              </Option>
            ))}
          </Dropdown>
        </div>
        <div className={styles.filter}>
          <Label
            className={styles.label}
            htmlFor="freOrSparklePrompt"
            data-testid="fre-label"
          >
            FRE
          </Label>
          {filters.freOrSparklePrompt.length ? (
            <ul className={styles.tagsList}>
              {filters.freOrSparklePrompt.map((fre) => (
                <li key={fre}>
                  <Button
                    size="small"
                    shape="circular"
                    appearance="primary"
                    icon={<Dismiss12Regular />}
                    iconPosition="after"
                    onClick={() => onTagClick("freOrSparklePrompt", fre)}
                  >
                    {fre}
                  </Button>
                </li>
              ))}
            </ul>
          ) : null}
          <Combobox
            autoComplete="off"
            freeform
            id="freOrSparklePrompt"
            placeholder="Select multiple"
            aria-label="Filter by FRE"
            multiselect
            positioning="below-start"
            value={comboboxValue.freOrSparklePrompt}
            selectedOptions={filters.freOrSparklePrompt}
            onChange={onMultiselectComboboxChange}
            onOptionSelect={(_, data) => {
              onMultiselectOptionSelect(
                "freOrSparklePrompt",
                data.selectedOptions,
              );
            }}
          >
            {getMatchingOptions(
              comboboxValue.freOrSparklePrompt,
              selectOptions.freOrSparklePrompt,
              "No results",
            )}
          </Combobox>
        </div>
        <div className={styles.filter}>
          <Label
            className={styles.label}
            htmlFor="sets"
            data-testid="sets-label"
          >
            Sets
          </Label>
          {filters.sets.length ? (
            <ul className={styles.tagsList}>
              {filters.sets.map((set) => (
                <li key={set}>
                  <Button
                    size="small"
                    shape="circular"
                    appearance="primary"
                    icon={<Dismiss12Regular />}
                    iconPosition="after"
                    onClick={() => onTagClick("sets", set)}
                  >
                    {set}
                  </Button>
                </li>
              ))}
            </ul>
          ) : null}
          <Combobox
            autoComplete="off"
            freeform
            id="sets"
            placeholder="Select multiple"
            aria-label="Filter by sets"
            multiselect
            positioning="below-start"
            value={comboboxValue.sets}
            selectedOptions={filters.sets}
            onChange={onMultiselectComboboxChange}
            onOptionSelect={(_, data) => {
              onMultiselectOptionSelect("sets", data.selectedOptions);
            }}
          >
            {getMatchingOptions(
              comboboxValue.sets,
              selectOptions.sets,
              "No results",
            )}
          </Combobox>
        </div>
        <div className={styles.filter}>
          <Label
            className={styles.label}
            htmlFor="assertionOwner"
            data-testid="assertionOwner-label"
          >
            Assertion Owner
          </Label>
          <Input
            id="assertionOwner"
            data-testid="assertionOwner-input"
            autoComplete="off"
            placeholder="Search"
            aria-label="Filter by Assertion Owner"
            value={filters.assertionOwner}
            contentAfter={<Search20Regular />}
            onChange={(_, data) =>
              setFilters({
                ...filters,
                assertionOwner: data.value,
              })
            }
          />
        </div>
        <div className={styles.filter}>
          <Checkbox
            label="Show archived"
            checked={filters.showArchived}
            onChange={() =>
              setFilters((prevFilter) => {
                return {
                  ...prevFilter,
                  showArchived: !prevFilter.showArchived,
                };
              })
            }
          />
        </div>
        <div className={styles.filter}>
          <Checkbox
            label="Show selected only"
            checked={filters.selectedOnly}
            onChange={() =>
              setFilters((prevFilter) => {
                return {
                  ...prevFilter,
                  selectedOnly: !prevFilter.selectedOnly,
                };
              })
            }
          />
        </div>
      </>
    );

    return (
      <Popover closeOnScroll withArrow positioning="below-start">
        <PopoverTrigger>
          <Button className={styles.queryButtons} icon={<Filter24Regular />}>
            Filters
          </Button>
        </PopoverTrigger>
        <PopoverSurface className={styles.filterPopover}>
          {renderFilters()}
        </PopoverSurface>
      </Popover>
    );
  },
);
