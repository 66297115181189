import type { OptionOnSelectData } from "@fluentui/react-combobox";
import {
  Dropdown,
  Label,
  Option,
  mergeClasses,
} from "@fluentui/react-components";
import type { FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import { updateCurrentPath } from "../../../mutators/updateContributions";
import { ContentPanel } from "./ContentPanel";
import type { MetricDefinition } from "./MetricDefinition";
import { GroundLeoClaimBreakDefinition } from "./groundleo/GroundLeoClaimBreakDefinition";
import { GroundLeoDefinition } from "./groundleo/GroundLeoDefinition";
import { ReasoningLeoDefinition } from "./reasoningleo/ReasoningLeoDefinition";
import { SbsLeoDefinition } from "./sbsleo/SbsLeoDefinition";
import { StewieLeoDefinition } from "./stewieleo/StewieLeoDefinition";
import { useStyles } from "./styles";
import { TaskCompleteDefinition } from "./taskcomplete/TaskCompleteDefinition";
import { getPermaLink } from "./utils/utilities";
import { SCLeoDefinition } from "./scleo/SCLeoDefinition";
import { NDCGDefinition } from "./ndcg/NDCGDefinition";

// Add new customized metrics here
const supportedMetricDefinitions: MetricDefinition<any>[] = [
  GroundLeoDefinition,
  GroundLeoClaimBreakDefinition,
  TaskCompleteDefinition,
  StewieLeoDefinition,
  SbsLeoDefinition,
  ReasoningLeoDefinition,
  SCLeoDefinition,
  NDCGDefinition,
];

const dropdownOptions = supportedMetricDefinitions.map(
  (metricDefinition) => metricDefinition.name,
);

const routes = supportedMetricDefinitions.flatMap((metricDefinition) => {
  return [
    <Route
      key={metricDefinition.url}
      path={metricDefinition.url}
      element={
        <ContentPanel
          key={"contentpanel_" + metricDefinition.url}
          metricDefinition={metricDefinition}
        />
      }
    />,
    <Route
      key={"/bizchat/" + metricDefinition.url}
      path={"/bizchat/" + metricDefinition.url}
      element={
        <ContentPanel
          key={"contentpanel_" + "bizchat_" + metricDefinition.url}
          metricDefinition={metricDefinition}
          allowScraping={true}
          allowUserQuery={true} // in task mode, allow user to query
        />
      }
    />,
  ];
});

routes.push(
  <Route
    key="default"
    path="*"
    element={
      <ContentPanel
        key="contentpanel_default"
        metricDefinition={GroundLeoDefinition}
      />
    }
  />,
);

routes.push(
  <Route
    key="default"
    path="/bizchat/*"
    element={
      <ContentPanel
        key="contentpanel_default_bizchat"
        metricDefinition={GroundLeoDefinition}
        allowScraping={true}
        allowUserQuery={true} // in non-task mode, allow user to query
      />
    }
  />,
);

function generateHint() {
  const permaLink = getPermaLink();
  if (permaLink) {
    return (
      <div>
        We load data from this&nbsp;
        <a href={permaLink.detailFile.sharepointLink} target="_blank">
          SharePoint page
        </a>
        . If it fails to load data, please check if you have access to the page.
      </div>
    );
  }
  return (
    <div>
      You can load data from local by clicking the &quot;Choose File&quot;
      button below.
      <br />
      File can be downloaded after running job on&nbsp;
      <a href="https://aka.ms/SEVAL" target="_blank">
        SEVAL
      </a>
      <br />
      Expect csv like groundleoclaimwise_details.csv. Or scrapping output
      conversations.tsv. &nbsp;
      <a
        href="https://msasg.visualstudio.com/Cortana/_wiki/wikis/M365%20Chat.wiki/185519/Human-Correlation-Study"
        target="_blank"
        rel="noopener noreferrer"
      >
        User Manual
      </a>
    </div>
  );
}

function isScrapingMode() {
  const path = window.location.pathname;
  return path.includes("/bizchat");
}

const LLMJudge: FunctionComponent = () => {
  const onChangeMetricDefinition = (ev: any, data: OptionOnSelectData) => {
    const selectedMetric = supportedMetricDefinitions.find(
      (metric) => metric.name === data.optionValue,
    );
    if (selectedMetric && selectedMetric.url) {
      updateCurrentPath("/llmjudge/" + selectedMetric.url);
    }
  };

  const permaLink = getPermaLink();
  const styles = useStyles();
  const allowScraping = isScrapingMode();

  return (
    <div style={{ marginLeft: "-60px", marginRight: "-60px" }}>
      <div
        className={mergeClasses(
          styles.stackHorizontal,
          styles.stackHorizontalWithGap,
        )}
        style={{ marginBottom: "1.5em" }}
      >
        {!allowScraping && (
          <>
            {!permaLink && (
              <div>
                <Label className={styles.label}>
                  Select a metric definition:
                </Label>
                <Dropdown
                  style={{ width: "20em" }}
                  onOptionSelect={onChangeMetricDefinition}
                >
                  {dropdownOptions.map((option) => (
                    <Option key={option}>{option}</Option>
                  ))}
                </Dropdown>
              </div>
            )}
            {generateHint()}
          </>
        )}
      </div>
      <Routes>{routes}</Routes>
    </div>
  );
};

export default LLMJudge;
