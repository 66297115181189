import type { SinglePageFeatureContribution } from "../../contribution/FeatureContribution";
import { HumanCorrelationStudyEntry } from "./components/MainEntry";

export const humanCorrelationStudyContribution: SinglePageFeatureContribution =
  {
    type: "single-page",
    name: "Human Correlation Study",
    isEnabled: () => false,
    defaultPath: "/llmjudge",
    pathRegistry: {
      "/llmjudge/*": {
        render: () => <HumanCorrelationStudyEntry />,
      },
    },
  };
