import { computed } from "mobx";
import { resultStore } from "../store/resultStore";

export const getRAIDropdownValues = computed(() => {
  const getValuesForColumn = (column: string) => {
    const allValues = new Set<string>(
      resultStore.conciseTable?.map((record) => record[column].toString()),
    );

    return allValues.size > 0 ? Array.from(allValues) : [];
  };

  const dropdownOptionsMap = new Map<string, string[]>();
  if (!resultStore.manifestItem) {
    return dropdownOptionsMap;
  }
  resultStore.manifestItem.dropdownOptions?.forEach((column) => {
    dropdownOptionsMap.set(column, getValuesForColumn(column));
  });

  return dropdownOptionsMap;
});

export const getSummaryTableDisplayColumns = computed(() => {
  if (!resultStore.manifestItem) {
    return [];
  }
  const fixedColumns =
    resultStore.manifestItem.fixedColumnsInSummary?.map((column) =>
      column.toString(),
    ) ?? [];
  const appendedColumns =
    resultStore.manifestItem.highlightMetrics?.map(
      (column) => column.displayName ?? column.metric_key.toString(),
    ) ?? [];
  return [...fixedColumns, ...appendedColumns];
});

export const getSummaryTableColumns = computed(() => {
  if (!resultStore.manifestItem) {
    return [];
  }
  const fixedColumns =
    resultStore.manifestItem.fixedColumnsInSummary?.map((column) =>
      column.toString(),
    ) ?? [];
  const appendedColumns =
    resultStore.manifestItem.highlightMetrics?.map((column) =>
      column.metric_key.toString(),
    ) ?? [];
  return [...fixedColumns, ...appendedColumns];
});

export const getSummaryTableData = computed(() => {
  if (!resultStore.summaryTable) {
    return [];
  }

  const filters = Array.from(resultStore.selectedFilter.keys());
  if (!resultStore.selectedFilter || filters.length === 0) {
    return resultStore.summaryTable;
  }
  return resultStore.summaryTable.filter((record) => {
    return filters.every(
      (filter) =>
        record[filter] === resultStore.selectedFilter.get(filter) ||
        !resultStore.selectedFilter.get(filter),
    );
  });
});

export const getConciseTableColumns = computed(() => {
  if (!resultStore.manifestItem) {
    return [];
  }
  const fixedColumns =
    resultStore.manifestItem.fixedColumnsInConcise?.map((column) =>
      column.toString(),
    ) ?? [];
  const appendedColumns = ["metric_key", "value"];
  return [...fixedColumns, ...appendedColumns];
});

export const getConciseTableData = computed(() => {
  if (!resultStore.conciseTable) {
    return [];
  }

  const filters = Array.from(resultStore.selectedFilter.keys());

  if (!resultStore.selectedFilter || filters.length === 0) {
    return resultStore.conciseTable;
  }

  return resultStore.conciseTable.filter((record) => {
    return filters.every(
      (filter) =>
        record[filter] === resultStore.selectedFilter.get(filter) ||
        !resultStore.selectedFilter.get(filter),
    );
  });
});
