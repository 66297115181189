import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Input,
} from "@fluentui/react-components";
import React, { useEffect } from "react";
import { getAuthOverrides, setAuthOverrides } from "../../helpers/cacheHelper";

type AuthOverrideDialogProps = {
  isOpen: boolean;
  onComplete: () => void;
};

export const AuthOverrideDialog = (props: AuthOverrideDialogProps) => {
  const [applicationId, setApplicationId] = React.useState("");
  const [directoryId, setDirectoryId] = React.useState("");
  const [scope, setScope] = React.useState("");

  useEffect(() => {
    if (props.isOpen) {
      const authOverrides = getAuthOverrides();
      setApplicationId(authOverrides?.applicationId ?? "");
      setDirectoryId(authOverrides?.directoryId ?? "");
      setScope(authOverrides?.scope ?? "");
    }
  }, [props.isOpen]);

  return (
    <Dialog
      open={props.isOpen}
      onOpenChange={() => {
        props.onComplete();
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{`Generate Managed Identity Token`}</DialogTitle>
          <DialogContent>
            <Field required label={"Application Id"}>
              <Input
                style={{ width: "100%" }}
                size="medium"
                value={applicationId}
                onChange={(_, data) => {
                  setApplicationId(data.value);
                }}
              />
            </Field>
            <Field required label={"Directory Id"}>
              <Input
                style={{ width: "100%" }}
                size="medium"
                value={directoryId}
                onChange={(_, data) => {
                  setDirectoryId(data.value);
                }}
              />
            </Field>
            <Field required label={"Scope"}>
              <Input
                style={{ width: "100%" }}
                size="medium"
                value={scope}
                onChange={(_, data) => {
                  setScope(data.value);
                }}
              />
            </Field>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={
                applicationId.trim() === "" ||
                directoryId.trim() === "" ||
                scope.trim() === ""
              }
              appearance="primary"
              onClick={() => {
                setAuthOverrides({
                  applicationId: applicationId.trim(),
                  directoryId: directoryId.trim(),
                  scope: scope.trim(),
                });

                window.location.reload();
              }}
            >
              Override
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
