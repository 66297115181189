import { mutatorAction } from "satcheljs";
import type { Permission } from "../models/Permission";
import { store } from "../store/store";

export const updatePermission = mutatorAction(
  "updatePermission",
  (permission: Permission) => {
    store.permission = permission;
  },
);
