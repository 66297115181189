import {
  Field,
  Label,
  Slider,
  Textarea,
  mergeClasses,
} from "@fluentui/react-components";
import type { FunctionComponent } from "react";
import React, { useEffect, useState } from "react";
import type { MetricDefinition } from "../MetricDefinition";
import type { TaskCompleteRow } from "../bizChatEvalDataProvider";
import {
  ControllableNav,
  renderRawData,
} from "../groundleo/GroundLeoDefinition";
import { useStyles } from "../styles";
import { getUserAlias } from "../utils/utilities";

const RenderLLMLabel = (row: TaskCompleteRow) => {
  return (
    <div>
      <b>TCR score</b>
      <div>{row.tcr_score}</div>
      <b>Response</b>
      <div>{row.response}</div>
    </div>
  );
};

const RenderHumanLabel: FunctionComponent<{
  row: TaskCompleteRow;
  triggerNavRerender: () => void;
  saveFeedbackTrigger: number;
  disabled?: boolean;
}> = ({ row, triggerNavRerender, saveFeedbackTrigger, disabled }) => {
  const [scoreValue, setScoreValue] = React.useState<number>();
  const [comment, setComment] = useState<string>(""); // <Textarea> doesn't work when the initial value is undefined. So we use empty string instead.

  useEffect(() => {
    setScoreValue(row.human_label ?? 10);
    setComment(row.human_comment || "");
  }, [row]);

  useEffect(() => {
    if (saveFeedbackTrigger === 0) return;
    saveHumanFeedback();
  }, [saveFeedbackTrigger]);

  function saveHumanFeedback() {
    row.human_comment = comment;
    row.human_label = scoreValue;
    row.submitted = true;
    row.last_labelled_by = getUserAlias();
    triggerNavRerender();
  }

  const styles = useStyles();

  return (
    <>
      <div
        className={mergeClasses(
          styles.stackHorizontal,
          styles.stackHorizontalWithGap,
        )}
      >
        <div>
          <Label className={styles.label}>Select the score</Label>
          Give a rating between 0-10 to indicate how much BizChat&apos;s actions
          and reply helped the user complete what they were trying to do.
          <div style={{ display: "flex" }}>
            <Slider
              disabled={disabled}
              key={"humanLabel" + row.ConversationId}
              style={{ width: "20em" }}
              min={0}
              max={10}
              value={scoreValue}
              onChange={(ev, value) => setScoreValue(value.value)}
            ></Slider>
            <div style={{ margin: ".5em 0 0 1em" }}>{scoreValue}</div>
          </div>
        </div>
      </div>
      <Field label="Comment" style={{ marginBottom: "1em" }}>
        <Textarea
          disabled={disabled}
          resize="vertical"
          value={comment}
          onChange={(_ev, value) => setComment(value.value)}
        ></Textarea>
      </Field>
    </>
  );
};

const getCustomizedExportData = (rows: TaskCompleteRow[]) => {
  const data = rows.map((row) => {
    return {
      conversation_id: row.ConversationId,
      score: row.human_label,
      comment: row.human_comment,
      last_labelled_by: row.last_labelled_by,
    };
  });
  return data;
};

export const TaskCompleteDefinition: MetricDefinition<TaskCompleteRow> = {
  name: "TaskComplete",
  url: "taskcomplete",
  nav: ControllableNav,
  renderHumanLabel: RenderHumanLabel,
  guidelineLink:
    "https://microsoft-my.sharepoint.com/:w:/p/troyappel/EUVWcKajgRpMk7MHnGtI8EwB3T7sz8zrcaDrnA8MnaiI-Q?e=yZeKcH",
  renderLLMLabel: RenderLLMLabel,
  renderRawData: renderRawData,
  allowLocalUpload: true,
  getCustomizedExportData: getCustomizedExportData,
  humanLabelFieldNames: ["human_label", "human_comment"],
};
