import { orchestrator } from "satcheljs";
import { uploadQuerySet } from "../../../helpers/apiHelper";
import { uploadFile } from "../actions/uploadFile";

orchestrator(uploadFile, (actionMessage) => {
  const { fileName, file, fileType, onSuccess, onError } = actionMessage;
  const name = fileName.trim();

  if (name === "") {
    return;
  }

  uploadQuerySet({
    FileName: name,
    File: file,
    FileType: fileType,
  })
    .then(onSuccess)
    .catch(onError);
});
