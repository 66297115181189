import { makeStyles, tokens } from "@fluentui/react-components";

export const useStyle = makeStyles({
  contentContainer: {
    marginTop: "26px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  card: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingTop: "32px",
    paddingBottom: "32px",
    paddingLeft: "60px",
    paddingRight: "60px",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13)",
    gap: "32px",
  },
  table: {
    width: "100%",
  },
  borderContainer: {
    boxSizing: "border-box",
    marginTop: "16px",
    width: "100%",
    borderRadius: "8px",
    border: "1px solid rgba(225, 223, 221, 1)",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "12px",
    paddingRight: "12px",
    maxHeight: "400px",
    overflow: "scroll",
  },
  checkbox: {
    overflow: "hidden",
    "> label": {
      fontSize: tokens.fontSizeBase300,
      lineHeight: tokens.lineHeightBase300,
      fontWeight: tokens.fontWeightRegular,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  fieldLabelRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  fieldLabel: {
    flex: 1,
    fontWeight: tokens.fontWeightBold,
    fontSize: "16px",
    lineHeight: "32px",
    display: "inline-block",
  },
  input: {
    marginTop: "16px",
    padding: "9px",
    width: "100%",
    fontWeight: tokens.fontWeightRegular,
    fontSize: "14px",
    lineHeight: "18px",
    border: "1px solid rgba(225, 223, 221, 1)",
  },
  textarea: {
    marginTop: "16px",
    height: "300px",
    "> textarea": {
      maxHeight: "inherit",
    },
  },
  buttonRow: {
    marginTop: "48px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
  },
  pipelineButtonsRow: {
    gap: "24px",
    display: "flex",
    justifyContent: "flex-end",
  },
  menu: {
    height: "300px",
  },
  switchRow: {
    display: "inline-block",
  },
  switchWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});
