import {
  Button,
  Field,
  Label,
  Link,
  MessageBar,
  MessageBarBody,
  Spinner,
  Tag,
  Textarea,
  Tree,
  TreeItem,
  TreeItemLayout,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import {
  CheckmarkCircle20Filled,
  CheckmarkCircle20Regular,
  ChevronDown24Regular,
  ChevronUp24Regular,
  Circle20Regular,
} from "@fluentui/react-icons";
import JSON5 from "json5";
import type { FunctionComponent } from "react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import type { PluggableList } from "react-markdown/lib/react-markdown";
import "react18-json-view/src/style.css";
import rehypeRaw from "rehype-raw";
import type { MetricDefinition } from "../MetricDefinition";
import type {
  GroundLeoRow,
  IBasicRow,
  IConversation,
} from "../bizChatEvalDataProvider";
import {
  QueryResultPair,
  extractQueryResultPair,
} from "../conversation/QueryResultPair";
import { ResourceCard } from "../conversation/ResourceCard";
import type { IQuery } from "../models/SydneyData";
import { ClearableChoiceGroup } from "../sharedComponents/ClearableChoiceGroup";
import { CollapsedResource } from "../sharedComponents/CollapsedResource";
import { useStyles } from "../styles";
import { groupBy } from "../utils/ArrayExtension";
import { uuidv4 } from "../utils/Guid";
import { getUserAlias } from "../utils/utilities";
import { ScrapeConversation } from "../scraping/sydney";
import { parse_business_sydney_response } from "../scraping/business_parser";
import type { IKeyValue } from "../models/CommonModels";

const useTreeStyles = makeStyles({
  selectedTreeItem: {
    fontWeight: "500",
    backgroundColor: tokens.colorBrandBackgroundInvertedHover,
    color: tokens.colorNeutralForeground2BrandHover,
  },
  treeItem: {
    fontWeight: "400",
  },
  treeIcon: {
    color: tokens.colorNeutralForeground2BrandHover,
  },
  treeIconOwn: {
    color: tokens.colorPaletteGreenForeground1,
  },
});

const getConversationTitle = (query: string) => {
  let conversationTitle = query;
  if (query.startsWith("[{") && query.endsWith("}]")) {
    try {
      const parseTitle: IQuery[] = JSON5.parse(query);
      conversationTitle = parseTitle[0]?.text || query;
    } catch {
      // ignore parsing error
    }
  }
  return conversationTitle;
};

export const ControllableNav: FunctionComponent<{
  data: IBasicRow<any>[];
  expandedData?: IBasicRow<any>[]; // A subset of data. Open all branches of this subset.
  metrics?: any[];
  setCurrentRow: React.Dispatch<IBasicRow<any>>;
  selectedKey?: string;
}> = ({ data, expandedData, metrics, setCurrentRow, selectedKey }) => {
  const [treeComponent, setTreeComponent] = useState<JSX.Element[]>();

  useEffect(() => {
    let lastRow: IBasicRow<any> | undefined;
    const newTreeBranches = Object.entries(
      groupBy(data, (row) => row.segment),
    ).map((group, sindex) => {
      let name = `(${group[1].length}) ${group[0]}`;
      if (metrics) {
        const metric = metrics.find(
          (m) => m.segment === group[0] && m.exp_name === group[1][0].exp_name,
        );
        const score = metric
          ? Object.entries(metric)?.[2]?.[1] ||
            metric["sbsleo_score"] ||
            metric["sbsleomultiturn_score"]
          : undefined;
        name = `(${group[1].length})(${score}) ${group[0]}`;
      }
      return (
        <SegmentGroupTree
          key={"branch_segment" + sindex}
          group={group}
          expandedData={expandedData}
          name={name}
          sindex={sindex}
          lastRow={lastRow}
          selectedKey={selectedKey}
          setCurrentRow={setCurrentRow}
        />
      );
    });
    setTreeComponent(newTreeBranches);
  }, [data, metrics, setCurrentRow, selectedKey]);

  return (
    <Tree appearance="subtle-alpha" aria-label="ConversationList">
      {treeComponent}
    </Tree>
  );
};

function hasFeedback(row: IBasicRow<any>) {
  return (
    row.submitted ||
    (row.unchangableFeedbacks && row.unchangableFeedbacks.length > 0)
  );
}

function hasOwnFeedback(row: IBasicRow<any>) {
  return row.submitted;
}

const SegmentGroupTree: FunctionComponent<{
  group: [string, IBasicRow<any>[]];
  expandedData?: IBasicRow<any>[]; // A subset of data. Open all branches of this subset.
  name: string;
  sindex: number;
  lastRow?: IBasicRow<any>;
  selectedKey?: string;
  setCurrentRow: (value: IBasicRow<any>) => void;
}> = ({
  group,
  expandedData,
  name,
  sindex,
  lastRow,
  selectedKey,
  setCurrentRow,
}) => {
  const treeStyles = useTreeStyles();
  const [open, setOpen] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setOpen(
      expandedData
        ? !!group[1].find((row) => expandedData.includes(row))
        : false,
    );
  }, [expandedData]);

  return (
    <TreeItem itemType="branch" open={open}>
      <TreeItemLayout
        onClick={() => setOpen(!open)}
        iconBefore={
          group[1].every((row) => hasFeedback(row)) ? (
            <CheckmarkCircle20Filled className={treeStyles.treeIcon} />
          ) : group[1].find((row) => hasFeedback(row)) ? (
            <CheckmarkCircle20Regular className={treeStyles.treeIcon} />
          ) : (
            <Circle20Regular className={treeStyles.treeIcon} />
          )
        }
      >
        {name}
      </TreeItemLayout>
      <Tree>
        {group[1].map((row, qindex) => {
          if (lastRow) lastRow.nextRow = row;
          row.lastRow = lastRow;
          row.nextRow = undefined; // reset next row because we support delete row now.
          lastRow = row;
          const navLinkKey = "segment" + sindex + "query" + qindex;
          row.navLinkKey = navLinkKey;

          const conversationTitle = getConversationTitle(row.query);

          return (
            <TreeItem
              itemType="leaf"
              key={"leaf_segment" + sindex + "query" + qindex}
            >
              <TreeItemLayout
                className={
                  selectedKey === navLinkKey
                    ? treeStyles.selectedTreeItem
                    : treeStyles.treeItem
                }
                key={navLinkKey}
                iconBefore={
                  hasOwnFeedback(row) ? (
                    <CheckmarkCircle20Filled
                      className={treeStyles.treeIconOwn}
                    />
                  ) : hasFeedback(row) ? (
                    <CheckmarkCircle20Filled className={treeStyles.treeIcon} />
                  ) : (
                    <></>
                  )
                }
                onClick={() => {
                  setCurrentRow(row);
                }}
              >
                {conversationTitle}
              </TreeItemLayout>
            </TreeItem>
          );
        })}
      </Tree>
    </TreeItem>
  );
};

function LinkRenderer(props: any) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}

// in glcw, additional highlight md symbol is added in SydneyReply, need to remove it to show the initial reply.
export function parseInitialSydneyReply(
  reply: string,
  expandedClaimIndexes?: Set<string>,
) {
  // the added highlight symbol is like <mark--{claimindex}>text</mark--{claimindex}>. Remove it from reply if the corresponding claim is not expanded.
  let parsedReply = reply;
  const claimIndexRegex = /<mark--(\d+)>/g;
  let match;
  while ((match = claimIndexRegex.exec(reply)) !== null) {
    const claimIndex = match[1];
    if (!expandedClaimIndexes?.has(claimIndex)) {
      parsedReply = parsedReply.replace(match[0], "");
      parsedReply = parsedReply.replace(`</mark--${claimIndex}>`, "");
    } else {
      parsedReply = parsedReply.replace(match[0], "<mark>");
      parsedReply = parsedReply.replace(`</mark--${claimIndex}>`, "</mark>");
    }
  }
  return parsedReply;
}

export const RenderConversationByTurn: FunctionComponent<{
  metricName: string;
  conversation: IConversation;
  turn: number;
  totalTurns: number;
  expandedClaimIndexes?: Set<string>;
  debuggingMode: boolean;
}> = ({
  metricName,
  conversation,
  turn,
  totalTurns,
  expandedClaimIndexes,
  debuggingMode,
}) => {
  const [showSearchResults, setShowSearchResults] = useState(false);
  let isEmptySydneySearchesComponent = true;
  const sydneySearchesComponent = (
    <>
      {conversation.SydneySearches?.map((s, i) => {
        if (
          (s.Query === "" && s.SearchResults === "") ||
          (!s.SearchResults && !s.Query)
        )
          return <Fragment key={"queryresultpair_" + i}></Fragment>;
        isEmptySydneySearchesComponent = false;
        return (
          <QueryResultPair
            key={"queryresultpair_" + i}
            query={s.Query}
            result={s.SearchResults as string}
          />
        );
      })}
    </>
  );
  const renderFilteredSearchesComponent = () => {
    const searchResults = conversation.filtered_search?.filter(
      (search) =>
        !search.tool_invocation.startsWith("hint(M365Copilot_language"),
    );
    if (!searchResults || searchResults.length === 0) return <></>;
    let indexOffset = 0;
    return (
      <>
        <Label className={styles.label}>Searches in Prompt</Label>
        {searchResults.map((item, i) => {
          const element = (
            <QueryResultPair
              key={"filteredsearchresultpair_" + i}
              query={item.tool_invocation}
              result={item.result}
              indexOffset={indexOffset}
            />
          );
          const queryResultPair = extractQueryResultPair(item.result);
          indexOffset += queryResultPair.snippets?.length || 0;
          return element;
        })}
      </>
    );
  };

  // parse language from string. example: "hint(M365Copilot_language=\"Chinese\")" => "Chinese"
  const language = conversation.filtered_search
    ?.find((search) => search.tool_invocation.includes("M365Copilot_language"))
    ?.tool_invocation?.match(/(?<=M365Copilot_language=").*?(?=")/)?.[0];

  interface IUserProfile {
    Name: string;
    "Job Title": string;
    Manager: string;
    "Skip Manager": string;
  }

  // parse user_profile from conversation.completions.prompt. example:
  // \n\nuser_profile = {'Name': 'Megan Bowen','Job Title':'Sales Manager','Manager':'Miriam Graham','Skip Manager':'Patti Fernandez'}\n\n
  // => {'Name': 'Megan Bowen','Job Title':'Sales Manager','Manager':'Miriam Graham','Skip Manager':'Patti Fernandez'}
  // Pick the longest user_profile string as the most informative one.
  let userProfile: IUserProfile | string | undefined = conversation.completions
    ?.map((c) => {
      const userProfileMatch = c.prompt.match(/(?<=user_profile = ).*?(?=\n)/);
      return userProfileMatch?.[0];
    })
    .filter((u) => u) // remove undefined
    .sort((a, b) => (b as string).length - (a as string).length)[0];

  try {
    if (userProfile) {
      userProfile = JSON5.parse(userProfile);
    }
  } catch {
    // ignore parsing error
  }

  const styles = useStyles();

  return (
    <>
      <Label className={styles.label}>
        {totalTurns === 1 ? `Human` : `Human (Turn ${turn + 1})`}
      </Label>
      <ReactMarkdown components={{ a: LinkRenderer }}>
        {conversation.Human}
      </ReactMarkdown>
      <div style={{ display: "flex" }}>
        <Label className={styles.label}>
          {totalTurns === 1 ? `Copilot` : `Copilot (Turn ${turn + 1})`}
        </Label>
        {language && (
          <Tag
            style={{
              marginLeft: "1em",
            }}
          >
            [{language}]
          </Tag>
        )}
      </div>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw] as PluggableList}
        components={{ a: LinkRenderer }}
      >
        {parseInitialSydneyReply(
          conversation.SydneyReply[0],
          expandedClaimIndexes,
        )}
      </ReactMarkdown>
      <div>
        <div
          style={{
            display: "flex",
            whiteSpace: "pre-wrap",
          }}
        >
          <Label className={styles.label}>Search & Context</Label>
          <Button
            className="searchResultsExpandButton"
            onClick={() => {
              setShowSearchResults(!showSearchResults);
            }}
            icon={
              showSearchResults ? (
                <ChevronUp24Regular />
              ) : (
                <ChevronDown24Regular />
              )
            }
            appearance="transparent"
          ></Button>
        </div>
      </div>

      {showSearchResults && (
        <>
          {(conversation.PluginReasoningIterations?.length ?? 0) > 0 &&
            debuggingMode && (
              <div>
                <Label className={styles.label}>Plugin Reasonings</Label>
                {conversation.PluginReasoningIterations?.flatMap(
                  (i) => i.PluginsReasonings,
                ).map((s) => {
                  const iterationKey = "pluginreasoning" + s.Query + uuidv4();
                  return (
                    <div key={iterationKey}>
                      <CollapsedResource
                        title={
                          s.PluginName +
                          `(query="${s.Query}")` +
                          ` (${s.Results?.length || 0})`
                        }
                        bold={false}
                      >
                        {Object.entries(s).map(([key, value]) => {
                          if (key === "Results") return null;
                          if (!value) return null;
                          const valueString = JSON.stringify(value);
                          if (valueString.length > 200) {
                            return (
                              <div key={key}>
                                <b>{key}: </b>
                                {valueString.slice(0, 200)}...
                              </div>
                            );
                          } else {
                            return (
                              <div key={key}>
                                <b>{key}: </b>
                                {valueString}
                              </div>
                            );
                          }
                        })}
                        <div>
                          <b>Plugin Results: </b>
                        </div>
                        <div style={{ width: "100%" }}>
                          {s.Results?.map((result, i) => {
                            let snippet = result;
                            if (result.Source) {
                              snippet = {
                                ...result.Source,
                                ...snippet,
                              };
                            }
                            return (
                              <div style={{ margin: "1em" }} key={"plugin" + i}>
                                <ResourceCard
                                  source="substrate"
                                  snippet={snippet}
                                  index={i + 1}
                                ></ResourceCard>
                              </div>
                            );
                          })}
                        </div>
                      </CollapsedResource>
                    </div>
                  );
                })}
              </div>
            )}

          {sydneySearchesComponent &&
            React.Children.count(sydneySearchesComponent.props.children) > 0 &&
            !isEmptySydneySearchesComponent &&
            debuggingMode && (
              <>
                <Label className={styles.label}>Sydney Searches</Label>
                {sydneySearchesComponent}
                <br />
              </>
            )}
          {(conversation.SydneyWebSearchReasonings?.length ?? 0) > 0 &&
            debuggingMode && (
              <div>
                <Label className={styles.label}>
                  Sydney Web Search Reasonings
                </Label>
                {conversation.SydneyWebSearchReasonings?.map((r) => (
                  <CollapsedResource
                    title={r.search}
                    key={r.search + uuidv4()}
                    bold={false}
                  >
                    <div>
                      <b>search: </b>
                      {r.search}
                    </div>
                    <div>
                      <b>reasoning: </b>
                      {r.reasoning}
                    </div>
                  </CollapsedResource>
                ))}
              </div>
            )}

          {(conversation.Iterations?.length ?? 0) > 0 && debuggingMode && (
            <div>
              <Label className={styles.label}>Substrate Search</Label>
              {conversation.Iterations?.flatMap((i) => i.SubstrateSearches).map(
                (s) => {
                  const iterationKey = "substratesearch" + s.Query + uuidv4();
                  if (!s.Query && !s.ResolvedUserUtterance)
                    return <React.Fragment key={iterationKey}></React.Fragment>;
                  return (
                    <div key={iterationKey}>
                      <CollapsedResource
                        title={s.Query + ` (${s.Results?.length || 0})`}
                        bold={false}
                      >
                        <div>
                          <b>Query: </b>
                          {s.Query}
                        </div>
                        <div>
                          <b>ResolvedUserUtterance: </b>
                          {s.ResolvedUserUtterance}
                        </div>
                        <div>
                          <b>LlmLuOutput: </b>
                          {s.LlmLuOutput}
                        </div>
                        <div>
                          <b>SearchResults: </b>
                        </div>
                        <div style={{ width: "100%" }}>
                          {s.Results.map((result, i) => {
                            return (
                              <div
                                style={{ margin: "1em" }}
                                key={"substrate" + i}
                              >
                                <ResourceCard
                                  source="substrate"
                                  snippet={result}
                                  index={i + 1}
                                ></ResourceCard>
                              </div>
                            );
                          })}
                        </div>
                      </CollapsedResource>
                    </div>
                  );
                },
              )}
            </div>
          )}

          {renderFilteredSearchesComponent()}

          {(metricName === "SCLeo" || debuggingMode) &&
            conversation.SydneySuggestion && (
              <div>
                <Label className={styles.label}>Sydney Suggestions</Label>
                <CollapsedResource title="" defaultCollapsed bold={false}>
                  {getSydneySuggetion(conversation).map((s) => (
                    <li key={uuidv4()}>{s}</li>
                  ))}
                </CollapsedResource>
              </div>
            )}

          {(conversation.SydneyCitations?.length ?? 0) > 0 && (
            <div>
              <Label className={styles.label}>Sydney Citations</Label>
              {
                <CollapsedResource title="" defaultCollapsed bold={false}>
                  <div style={{ maxHeight: "40vh", overflow: "auto" }}>
                    <ol>
                      {conversation.SydneyCitations?.map((c) => (
                        <li key={c.providerDisplayName + uuidv4()}>
                          {c.providerDisplayName},&nbsp;
                          <Link
                            href={c.seeMoreUrl}
                            target="_blank"
                            style={{ wordBreak: "break-all" }}
                          >
                            {c.seeMoreUrl}
                          </Link>
                        </li>
                      ))}
                    </ol>
                  </div>
                </CollapsedResource>
              }
            </div>
          )}

          {userProfile && (
            <div>
              <Label className={styles.label}>User profile</Label>
              <div>
                {userProfile && (
                  <CollapsedResource title="" defaultCollapsed bold={false}>
                    {typeof userProfile === "string" && (
                      <span>{userProfile}</span>
                    )}

                    {typeof userProfile === "object" && (
                      <>
                        <div style={{ display: "flex", marginBottom: "1em" }}>
                          <span>{userProfile.Name}</span>
                          <span
                            style={{
                              marginLeft: "1em",
                              fontWeight: "lighter",
                            }}
                          >
                            {userProfile["Job Title"]}
                          </span>
                        </div>
                        {Object.entries(userProfile).map(
                          ([key, value]) =>
                            key !== "Name" &&
                            key !== "Job Title" && (
                              <div key={key}>
                                <label
                                  style={{ width: "20em", fontWeight: 500 }}
                                >
                                  {key}{" "}
                                </label>
                                {typeof value === "string"
                                  ? value
                                  : JSON.stringify(value)}
                              </div>
                            ),
                        )}
                      </>
                    )}
                  </CollapsedResource>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const RenderScraping = <T,>(props: {
  row: IBasicRow<T>;
  variants: string;
  token: string;
  setConversations: (conversitions: IConversation[]) => void;
  triggerScraping?: number;
}) => {
  const [scraping, setScraping] = useState<boolean>(false);
  const cancelFunc = useRef<() => void>();

  const scrape = () => {
    let cancel = false; // Already switched to other row
    cancelFunc.current = () => {
      cancel = true;
    };

    if (scraping) {
      // we use spinner to indicate scraping, so we don't need to scrape again
      return; // Do not allow multiple scraping for the same query at the same time.
    }
    props.setConversations([]);
    props.row.conversation = "";
    setScraping(true);

    if (!props.row.scrapingPromise) {
      props.row.scrapingPromise = ScrapeConversation(
        props.row.filledQuery || props.row.query,
        props.variants,
        props.token,
        props.row.config,
      );
    }

    props.row.scrapingPromise
      .then((result) => {
        const parsedResult = parse_business_sydney_response(result);
        const conversation = parsedResult?.result;
        if (!props.row.ConversationId) {
          // Only add conversation id if it's not already set. Which means it's an user added query.
          props.row.ConversationId = conversation?.ConversationId || "";
        }
        props.row.conversation = JSON.stringify([conversation]);
        if (!cancel) {
          conversation && props.setConversations([conversation]);
          setScraping(false);
        }
      })
      .catch((e) => {
        const error = e as Error;
        props.row.conversation = JSON.stringify([{ error: error.message }]);
        if (!cancel) {
          props.setConversations([{ error: error.message } as IConversation]);
          setScraping(false);
        }
      });
  };

  useEffect(() => {
    setScraping(false);
    cancelFunc.current && cancelFunc.current();
    return () => {
      cancelFunc.current && cancelFunc.current();
    };
  }, [props.row]);

  // Effect hook order
  // 1. GroundLeoDefinition [props.row]   Cancel last row's scraping (Do not means canceling the http request. Cancel means cancel state update)
  // 2. ContentPanel [currentRow]  auto update scrapingCount if ready for auto trigger scraping
  // 3. GroundLeoDefinition [props.triggerScraping]  trigger scraping

  useEffect(() => {
    if (props.triggerScraping === 0) return;
    scrape();
  }, [props.triggerScraping]); // Scraping is only triggered from ContentPanel. Pass triggerScraping to force scraping.

  return <div>{scraping && <Spinner />}</div>;
};

export const RenderConversation: FunctionComponent<{
  metricName: string;
  row: IBasicRow<any>;
  expandedClaims?: Set<string>;
  debuggingMode: boolean;
  allowScraping?: boolean;
  token?: string;
  variants?: string;
  triggerScraping?: number;
  onConversationsChanged?: (conversations: IConversation[]) => void;
}> = ({
  metricName,
  row,
  expandedClaims,
  debuggingMode,
  allowScraping,
  token,
  variants,
  triggerScraping,
  onConversationsChanged,
}) => {
  const [conversations, setConversations] = useState<IConversation[]>([]);
  const [parseFailMessage, setParseFailMessage] = useState<
    string | undefined
  >();

  useEffect(() => {
    onConversationChange([]);
    setParseFailMessage(undefined);
    if (!row.conversation) return;
    try {
      onConversationChange(JSON5.parse(row.conversation) as IConversation[]);
    } catch (e: any) {
      setParseFailMessage(e.toString());
    }
  }, [row]);

  const onConversationChange = (newConversations: IConversation[]) => {
    setConversations(newConversations);
    if (onConversationsChanged) {
      onConversationsChanged(newConversations);
    }
  };

  return (
    <>
      {allowScraping && (
        <RenderScraping
          row={row}
          variants={variants || ""}
          token={token || ""}
          setConversations={onConversationChange}
          triggerScraping={triggerScraping}
        />
      )}
      {parseFailMessage && (
        <MessageBar intent="error">
          <MessageBarBody>
            Fail to parse conversation. {parseFailMessage}
          </MessageBarBody>
        </MessageBar>
      )}
      {conversations.map((conversation, turn) => {
        if (conversation.error) {
          return (
            <>
              Fail to call sydney! Error: {JSON.stringify(conversation.error)}
            </>
          );
        }
        return (
          <RenderConversationByTurn
            metricName={metricName}
            key={"conversation_turn" + turn}
            conversation={conversation}
            turn={turn}
            totalTurns={conversations.length}
            expandedClaimIndexes={expandedClaims}
            debuggingMode={debuggingMode}
          />
        );
      })}
    </>
  );
};

export const removeExpName = (configStr: string) => {
  try {
    const config: IKeyValue = JSON.parse(configStr) as IKeyValue;
    delete config["exp_name"];
    return JSON.stringify(config);
  } catch (_) {
    return configStr;
  }
};

export const renderRawData: FunctionComponent<{
  row: IBasicRow<any>;
}> = ({ row }) => {
  const styles = useStyles();
  return (
    <>
      {Object.entries(row).map(([key, value]) => {
        if (["ControlRowData", "exp_name"].includes(key)) return null;
        let displayValue = value as string;
        if (key === "config") {
          displayValue = removeExpName(value);
        }
        return (
          <div key={key}>
            <Label className={styles.label}>{key}</Label>
            <span>{displayValue}</span>
          </div>
        );
      })}
    </>
  );
};

export const getSydneySuggetion = (conversation: IConversation): string[] => {
  if (!conversation.SydneySuggestion) return [];
  if (typeof conversation.SydneySuggestion === "string") {
    return [conversation.SydneySuggestion];
  }
  return conversation.SydneySuggestion.map((s) => s.text);
};

export const RenderHumanLabel: FunctionComponent<{
  row: GroundLeoRow;
  triggerNavRerender: () => void;
  saveFeedbackTrigger: number;
  disabled?: boolean;
}> = ({ row, triggerNavRerender, saveFeedbackTrigger, disabled }) => {
  const [comment, setComment] = useState<string>("");
  const [human_label, setHumanLabel] = useState<string>("");

  useEffect(() => {
    setHumanLabel(row.human_label || "");
    setComment(row.human_comment || "");
  }, [row]);

  useEffect(() => {
    if (saveFeedbackTrigger === 0) return;
    saveHumanFeedback();
  }, [saveFeedbackTrigger]);

  function saveHumanFeedback() {
    row.human_label = human_label || "";
    row.human_comment = comment || "";
    row.submitted = true;
    row.last_labelled_by = getUserAlias();
    triggerNavRerender();
  }

  return (
    <>
      <ClearableChoiceGroup
        label="Label"
        choiceGroupLabel="If Sydney's response fails to meet your expectations, please tag the reasons for this conversation"
        options={["Language hint", "Synthesis prompt", "Search"]}
        selectedKey={human_label}
        onChoiceChange={(option) => {
          setHumanLabel(option || "");
        }}
        disabled={disabled}
      ></ClearableChoiceGroup>
      <br />
      <Field style={{ marginBottom: "1em" }} label="Comment">
        <Textarea
          resize="vertical"
          key={"humancomment" + row.ConversationId}
          onChange={(ev, value) => setComment(value.value)}
          value={comment}
          disabled={disabled}
        ></Textarea>
      </Field>
    </>
  );
};

const getCustomizedExportData = (rows: GroundLeoRow[]) => {
  const data = rows.map((row) => {
    return {
      conversation_id: row.ConversationId,
      human_label: row.human_label,
      comment: row.human_comment,
      last_labelled_by: row.last_labelled_by,
    };
  });
  return data;
};

export const GroundLeoDefinition: MetricDefinition<GroundLeoRow> = {
  name: "Conversation",
  url: "conversation",
  fetchData: undefined,
  nav: ControllableNav,
  renderRawData: renderRawData,
  renderHumanLabel: RenderHumanLabel,
  allowLocalUpload: true,
  getCustomizedExportData: getCustomizedExportData,
  humanLabelFieldNames: ["human_comment", "human_label"],
};
