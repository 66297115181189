import type { UserLicense } from "../models/UserLicenseResponse";
import { UserLicenseResponse } from "../models/UserLicenseResponse";
import { getGraphAccessToken } from "./accessTokenHelper";
import { getRandomUUID } from "./getRandomUUID";
import { retryable } from "./retryable";

const allowedSkuId = "639dec6b-bb19-468b-871c-c5c441c4b0cb";
const allowedSkuPartNumber = "Microsoft_365_Copilot";
const allowedServicePlanId = "3f30311c-6b1e-48a4-ab79-725b469da960";
const alloedServicePlanName = "M365_COPILOT_BUSINESS_CHAT";
const allowedAcceptedProvisioningStatus = "SUCCESS";

const getLicenseDetails = (
  email: string,
  accessToken: string,
): Promise<UserLicense[]> => {
  const graphUrl = `https://graph.microsoft.com/v1.0/users/${email}/licenseDetails`;

  const headers = new Headers();
  headers.append("Authorization", `Bearer ${accessToken}`);

  const options = {
    method: "GET",
    headers: headers,
  };

  const newPromise = retryable(
    () =>
      fetch(graphUrl, options).then((response) => {
        if (response.status !== 200) {
          throw new Error(`Get license error: ${response.statusText}`);
        } else {
          return response.json().then((licenseJsonObj) => {
            return UserLicenseResponse(licenseJsonObj, "UserLicenseResponse")
              .value;
          });
        }
      }),
    {
      api: "getUserLicenseDetails",
      url: graphUrl,
      scenarioId: getRandomUUID(),
    },
  );

  return newPromise;
};

// Please refer to the config file below for the license we used in M365 Chat.
// https://msasg.visualstudio.com/WIT%20Engineering/_git/TuringAtWork?path=%2Fservices%2FTuringSearchService%2Fsrc%2FService%2FConfig%2FLicenseCheckConfig.ini
export const hasM365CopilotLicense = (email: string): Promise<boolean> => {
  return getGraphAccessToken()
    .then((accessToken) => {
      return getLicenseDetails(email, accessToken);
    })
    .then((licenses) => {
      // Check the skus
      const targetSku = licenses.find(
        (value) =>
          value.skuId.toLowerCase() === allowedSkuId.toLowerCase() &&
          value.skuPartNumber.toLowerCase() ===
            allowedSkuPartNumber.toLowerCase(),
      );

      // Check valid service plans
      if (targetSku !== undefined && targetSku.servicePlans.length > 0) {
        const targetServicePlan = targetSku.servicePlans.find(
          (value) =>
            value.servicePlanId.toLowerCase() ===
              allowedServicePlanId.toLowerCase() &&
            value.servicePlanName.toLowerCase() ===
              alloedServicePlanName.toLowerCase() &&
            value.provisioningStatus.toLowerCase() ===
              allowedAcceptedProvisioningStatus.toLowerCase(),
        );

        return targetServicePlan !== undefined;
      }

      return false;
    })
    .then((hasLicense) => {
      return hasLicense;
    });
};
