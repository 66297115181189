// make sure sample numbers input by user are valid
import type { IKeyValue } from "../models/CommonModels";

export function checkInputNumberValidity(
  inputField: string,
  fieldName: string,
  nonZero?: boolean,
) {
  const inputToNumber = Number(inputField);
  if (
    inputField === "" ||
    isNaN(inputToNumber) ||
    inputToNumber < 0 ||
    (nonZero && inputToNumber === 0)
  ) {
    alert(`Please input a valid integer for ${fieldName}`);
    return false;
  }
  return true;
}

// make sure the input field is the correct format of upns split by comma
export function checkInputUpnValidity(
  inputField: string | undefined,
  fieldName: string,
) {
  if (!inputField || inputField === "") {
    return true;
  }
  const upnList = inputField.split(",");
  const upnRegex = /^[a-zA-Z0-9._]+@microsoft.com$/;
  for (const upn of upnList) {
    if (!upnRegex.test(upn)) {
      alert(
        `Please input a valid upn list for ${fieldName}!\n The format should be UPN split by comma. Example:\naliasA@microsoft.com,aliasB@microsoft.com,aliasC@microsoft.com`,
      );
      return false;
    }
  }
  return true;
}

export function checkInputQueryConfig(
  inputField: string | undefined,
  fieldName: string,
) {
  if (!inputField || inputField === "") {
    return `Please input a valid query config for ${fieldName}`;
  }
  try {
    const config = JSON.parse(inputField) as IKeyValue;
    if (!config.variants) {
      return `Missing variants in ${fieldName}`;
    }
  } catch (e) {
    return `Please input a valid query config for ${fieldName}`;
  }
  return undefined;
}
