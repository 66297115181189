"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IncidentList = exports.Incident = void 0;
const tools_1 = require("../tools");
exports.Incident = (0, tools_1.object)({
    product: tools_1.str,
    title: tools_1.str,
    description: tools_1.str,
    creator: tools_1.str,
    createdAt: tools_1.str,
});
exports.IncidentList = (0, tools_1.array)(exports.Incident);
