import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Switch,
  makeStyles,
  mergeClasses,
} from "@fluentui/react-components";

import { observer } from "mobx-react-lite";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { parseRationale } from "../../../helpers/lmchecklistMetricsHelper";
import type { LMChecklistAssertion } from "../../../models/LMChecklistAssertionsResponse";
import { useSharedTableStyles } from "./shardTableStyles";

type SingleAssertionTableProps = {
  assertion: LMChecklistAssertion;
};

const useStyles = makeStyles({
  expNameHeader: {
    width: "80px",
  },
  dialogSurface: {
    wordWrap: "break-word",
    maxWidth: "750px",
    width: "100%",
  },
  owner: {
    marginTop: "5px",
    marginBottom: "5px",
    fontWeight: "bold",
  },
});

export const SingleAssertionTable = observer(
  (props: SingleAssertionTableProps) => {
    const styles = useStyles();
    const sharedStyles = useSharedTableStyles();

    const [isMarkdown, setIsMarkdown] = useState<boolean>(true);

    const assertionTableHeaderCell = mergeClasses(
      sharedStyles.cell,
      sharedStyles.centerCell,
    );

    const expNameHeaderCell = mergeClasses(
      assertionTableHeaderCell,
      styles.expNameHeader,
    );

    const renderAssertionOwner = (owner: string | undefined) => {
      return owner ? owner : "None";
    };

    const renderPromptDialog = (
      prompt: string | undefined,
      treatment: string,
    ) => {
      if (prompt === undefined) {
        return "N/A";
      }

      return (
        <Dialog>
          <DialogTrigger disableButtonEnhancement>
            <Button
              className={sharedStyles.button}
              data-testid={`prompt-btn-${treatment}`}
            >
              View
            </Button>
          </DialogTrigger>
          <DialogSurface className={styles.dialogSurface}>
            <DialogBody>
              <DialogTitle>Prompt ({treatment})</DialogTitle>
              <DialogContent>
                {isMarkdown ? (
                  <ReactMarkdown>{prompt}</ReactMarkdown>
                ) : (
                  <div>{prompt}</div>
                )}
              </DialogContent>
              <DialogActions>
                <Switch
                  checked={isMarkdown}
                  onChange={() => setIsMarkdown(!isMarkdown)}
                  label="Markdown"
                />
                <DialogTrigger disableButtonEnhancement>
                  <Button>Close</Button>
                </DialogTrigger>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      );
    };

    const renderScore = (score: number | undefined) =>
      score !== undefined ? score : "N/A";

    const renderRationale = (response: string | undefined) => (
      <ReactMarkdown>{parseRationale(response)}</ReactMarkdown>
    );

    return (
      <>
        <div className={styles.owner}>
          Owner: {renderAssertionOwner(props.assertion.owner)}
        </div>
        <table className={sharedStyles.table}>
          <thead className={sharedStyles.thead}>
            <tr className={sharedStyles.rowStyle}>
              <th className={expNameHeaderCell}>Exp Name</th>
              <th className={assertionTableHeaderCell}>Score</th>
              <th className={assertionTableHeaderCell}>Rationale</th>
              <th className={assertionTableHeaderCell}>Prompt</th>
            </tr>
          </thead>
          <tbody className={sharedStyles.tbody}>
            <tr className={sharedStyles.rowStyle}>
              <td className={sharedStyles.cell}>Control</td>
              <td className={sharedStyles.cell}>
                {renderScore(props.assertion.score.control)}
              </td>
              <td className={sharedStyles.cell}>
                {renderRationale(props.assertion.response.control)}
              </td>
              <td className={sharedStyles.cell}>
                {renderPromptDialog(props.assertion.prompt.control, "Control")}
              </td>
            </tr>
            <tr className={sharedStyles.rowStyle}>
              <td className={sharedStyles.cell}>Treatment</td>
              <td className={sharedStyles.cell}>
                {renderScore(props.assertion.score.experiment)}
              </td>
              <td className={sharedStyles.cell}>
                {renderRationale(props.assertion.response.experiment)}
              </td>
              <td className={sharedStyles.cell}>
                {renderPromptDialog(
                  props.assertion.prompt.experiment,
                  "Treatment",
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  },
);
