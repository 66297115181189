import { Button } from "@fluentui/react-components";

import React from "react";
import { BasicDialog } from "../../../../components/Shared/BasicDialog";
import { BasicInput } from "../../../../components/Shared/BasicInput";
import type { FeatureDebugDialogContributionProps } from "../../../../contribution/FeatureDebugContribution";

export const ProductAssignmentDialog = (
  props: FeatureDebugDialogContributionProps,
) => {
  const [expType, setExpType] = React.useState<string>("");
  const [expName, setExpName] = React.useState<string>("");
  const [productId, setProductId] = React.useState<string>("");

  const renderContent = () => (
    <>
      <div>
        This action will find all existed jobs with specific ExperimentName and
        Experiment Type, if the job's product id is empty, it will assign the
        target product id to the job.
      </div>
      <br />
      <BasicInput
        required
        label={"Job Experiment Type"}
        value={expType}
        onChange={setExpType}
      />
      <BasicInput
        required
        label={"Job Experiment Name"}
        value={expName}
        onChange={setExpName}
      />
      <BasicInput
        required
        label={"Product Id"}
        value={productId}
        onChange={setProductId}
      />
    </>
  );

  const renderButtons = () => (
    <>
      <Button
        appearance="primary"
        onClick={() => {
          return;
        }}
      >
        Update
      </Button>
    </>
  );

  return (
    <BasicDialog
      {...props}
      title={"Assign Product Id To Existed Jobs"}
      renderContent={renderContent}
      renderButtons={renderButtons}
    />
  );
};
