import { makeStyles, shorthands, tokens } from "@fluentui/react-components";

export const useDetailPageStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  settingTitleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  settingTitle: {
    height: "24px",
    fontSize: "18px",
    fontWeight: 600,
    fontFamily: "Segoe UI, sans-serif",
    lineHeight: "20px",
  },
  backButton: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
    ...shorthands.margin("10px"),
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  title: {
    height: "24px",
    fontSize: "18px",
    color: tokens.colorBrandBackground,
    fontWeight: 600,
    fontFamily: "Segoe UI, sans-serif",
    lineHeight: "20px",
  },
  infoCard: {
    backgroundColor: "white",
    width: "fill-available",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    ...shorthands.borderRadius("6px"),
    ...shorthands.padding("20px"),
    marginBottom: "20px",
  },
  infoGridLayout: {
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "1fr",
  },
  infoGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 5fr",
    ...shorthands.gap("10px"),
    fontWeight: 400,
  },
  infoTitle: {
    fontWeight: 600,
  },
  statusContainer: {
    width: "100px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    ...shorthands.borderRadius("4px"),
  },
  cardTabs: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    ...shorthands.gap("8px"),
    marginBottom: "20px",
  },
  sectionTitle: {
    fontSize: "18px",
    fontWeight: 600,
  },
  errorTableHeader: {
    width: "50px",
  },
  operationButtons: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("8px"),
  },
});
