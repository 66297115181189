import React, { useState, useEffect } from "react";
import {
  type VoteType,
  calculateMetrics,
  type MetricsModel,
} from "../utils/metricsHelper";
import { Button, Label } from "@fluentui/react-components";
import { Info24Regular } from "@fluentui/react-icons";

type MetricsBoardProps = {
  customizedData: Record<string, string | number>[] | undefined;
  style?: Record<string, string>;
};

const calculateRateResult = (
  numerator: number | undefined,
  denominator: number | undefined,
) => {
  const details = ` (${numerator ?? "N/A"} / ${denominator ?? "N/A"})`;
  if (
    denominator === 0 ||
    numerator === undefined ||
    denominator === undefined
  ) {
    return "N/A" + details;
  }
  return `${(numerator / denominator).toFixed(2)}${details}`;
};

const MetricsBoard = (props: MetricsBoardProps) => {
  const [voteType, setVoteType] = useState("Majority" as VoteType);
  const [metrics, setMetrics] = useState({
    isReady: false,
    errorMsg: "",
  } as MetricsModel);

  useEffect(() => {
    calculateMetricsWithType(voteType);
  }, [props.customizedData, voteType]);

  const calculateMetricsWithType = (voteTypeParam: VoteType) => {
    const { customizedData } = props;

    if (!customizedData) {
      return;
    }

    try {
      const newMetrics = calculateMetrics(customizedData, voteTypeParam);
      setMetrics(newMetrics);
    } catch (e) {
      const newMetrics = {
        isReady: true,
        errorMsg: `Failed to calculate Surplus. ${
          e instanceof Error ? e.message : e
        }`,
      };
      setMetrics(newMetrics);
    }
  };

  const renderMetricsItem = (
    label: string,
    value: string,
    tooltip?: string,
  ) => {
    return (
      <>
        <Label>
          {label}
          {tooltip && (
            <Button
              icon={<Info24Regular />}
              appearance="transparent"
              title={tooltip}
            />
          )}
        </Label>
        <div className={"MetricsValue"}>{value}</div>
      </>
    );
  };

  return (
    <div style={{ ...props.style }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h4>Metrics</h4>
        {metrics.isMultiLabels && (
          <div>
            <label>
              <input
                type="radio"
                value="Majority"
                checked={voteType === "Majority"}
                onChange={() => {
                  setVoteType("Majority");
                  calculateMetricsWithType("Majority");
                }}
              />
              Majority voting
            </label>
            <label>
              <input
                type="radio"
                value="Consensus"
                checked={voteType === "Consensus"}
                onChange={() => {
                  setVoteType("Consensus");
                  calculateMetricsWithType("Consensus");
                }}
              />
              Consensus voting
            </label>
          </div>
        )}
      </div>
      <div className="MetricsArea">
        {metrics.isReady && !metrics.errorMsg && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 3fr",
              gridAutoRows: "max(2.6rem, auto)",
              justifyItems: "start",
              gap: "10px",
            }}
          >
            {renderMetricsItem(
              "Total",
              `${metrics.total}`,
              "The number of different conversations with a comparison result.",
            )}
            {renderMetricsItem(
              "Treatment Wins Rate",
              `${calculateRateResult(metrics.treatmentWins, metrics.total)}`,
              voteType === "Majority"
                ? "Count of TreatmentWins is not less than ControlWins, and TreatmentWins is not 0 for a conversation"
                : "Count of TreatmentWins is greater than 0, and ControlWins is 0 for a conversation",
            )}
            {renderMetricsItem(
              "Control Wins Rate",
              `${calculateRateResult(metrics.controlWins, metrics.total)}`,
              voteType === "Majority"
                ? "Count of ControlWins is greater than TreatmentWins for a conversation"
                : "Count of ControlWins is greater than 0, and TreatmentWins is 0 for a conversation",
            )}
            {renderMetricsItem(
              "Tie Rate",
              `${calculateRateResult(metrics.same, metrics.total)}`,
              'All labels for a conversation are "Same"',
            )}
            {renderMetricsItem(
              "Surplus",
              `${calculateRateResult(
                (metrics.treatmentWins ?? 0) - (metrics.controlWins ?? 0),
                metrics.total,
              )}`,
              "Surplus = TreatmentWinsRate - ControlWinsRate",
            )}
          </div>
        )}
        {!metrics.isReady && <div>Calculating...</div>}
        {metrics.isReady && metrics.errorMsg && <div>{metrics.errorMsg}</div>}
      </div>
    </div>
  );
};

export default MetricsBoard;
