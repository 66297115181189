import { makeAutoObservable } from "mobx";
import type {
  IConversation,
  IPluginItem,
  IPluginReasoning,
  NDCGRow,
} from "../bizChatEvalDataProvider";
import type { IKeyValue } from "../models/CommonModels";
import type { IPluginItemWithStatus } from "./NDCGPage";

export const ndcgStore = makeAutoObservable({
  conversation: undefined as IConversation | undefined,
  feedback: undefined as IKeyValue[] | undefined,

  cleanCache() {
    this.conversation = undefined;
    this.feedback = undefined;
  },

  hasConversation() {
    return this.conversation !== undefined;
  },

  setConversation(conversation: IConversation | undefined) {
    this.conversation = conversation;
  },

  getIterationCount(conversation: IConversation | undefined) {
    if (
      conversation === undefined ||
      conversation.PluginReasoningIterations === undefined
    ) {
      return 0;
    } else {
      return conversation.PluginReasoningIterations.length;
    }
  },

  getPlugins(
    conversation: IConversation | undefined,
    iterationNo: number | undefined,
  ) {
    if (conversation === undefined || iterationNo === undefined) {
      return [];
    }

    const iterationCount = this.getIterationCount(conversation);
    if (iterationNo >= iterationCount) {
      return [];
    }
    return conversation.PluginReasoningIterations?.[
      iterationNo
    ].PluginsReasonings.filter((plugin) =>
      this.isValidPlugin(plugin.PluginName),
    );
  },

  getResources(
    row: NDCGRow | undefined,
    iterationNo: number | undefined,
    plugin: IPluginReasoning,
  ) {
    const snippets: IPluginItemWithStatus[] = [];
    if (row === undefined || iterationNo === undefined) {
      return snippets;
    }

    this.checkAndInitFeedback(row, iterationNo, plugin);

    const currentIterationFeedback = this.feedback?.[iterationNo];
    const pluginFeedback: IKeyValue[] = currentIterationFeedback?.[
      plugin.PluginName
    ] as IKeyValue[];

    (plugin.Results ?? []).forEach((result, index) => {
      if (result) {
        let is_edit = false;
        if (pluginFeedback && pluginFeedback[index]) {
          const pluginItemFeedback = pluginFeedback[index];
          for (const key in pluginItemFeedback) {
            if (pluginItemFeedback[key] !== "") {
              is_edit = true;
            }
          }
        }
        const snippet: IPluginItemWithStatus = {
          ...result.Source,
          ...result,
          is_edit: is_edit,
        };
        snippets.push(snippet);
      }
    });

    return snippets;
  },

  getResourceTitle(plugin: IPluginReasoning, resource: IPluginItem) {
    if (plugin.PluginName === "search_enterprise_chat") {
      return resource.Source?.Title as string;
    } else if (plugin.PluginName === "search_enterprise_emails") {
      return resource.Source?.Subject as string;
    } else if (plugin.PluginName === "search_enterprise_meetings") {
      return (resource.Source?.Subject ?? "No subject") as string;
    } else if (plugin.PluginName === "search_enterprise_people") {
      return (resource.Source?.Person as Record<string, string>).DisplayName;
    } else if (
      plugin.PluginName === "search_enterprise_files" ||
      plugin.PluginName === "search_enterprise_personal_files"
    ) {
      return resource.Source?.Title as string;
    } else {
      return "Unknown resource type";
    }
  },

  checkAndInitFeedback(
    row: NDCGRow,
    iterationNo: number,
    plugin: IPluginReasoning,
  ) {
    if (this.feedback === undefined) {
      try {
        this.feedback = JSON.parse(row.label_ndcg ?? "[]") as IKeyValue[];
      } catch (e) {
        this.feedback = [] as IKeyValue[];
      }
      const iterationCount = this.getIterationCount(this.conversation);
      if (this.feedback.length < iterationCount) {
        for (let i = this.feedback.length; i < iterationCount; i++) {
          const emptyIterationFeedback: IKeyValue = {};
          this.feedback.push(emptyIterationFeedback);
        }
      }
    }
    const currentIterationFeedback = this.feedback[iterationNo];
    const pluginFeedback = currentIterationFeedback[plugin.PluginName];
    if (pluginFeedback === undefined) {
      const pluginLabels: IKeyValue[] = [];
      (plugin.Results ?? []).forEach(() => {
        const emptyLabel: IKeyValue = {
          iterativeQueryRelevance: "",
          utteranceQueryRelevance: "",
          comment: "",
        };
        pluginLabels.push(emptyLabel);
      });
      currentIterationFeedback[plugin.PluginName] = pluginLabels;
    }
  },

  setResourceLabel(
    row: NDCGRow | undefined,
    iterationNo: number | undefined,
    plugin: IPluginReasoning | undefined,
    pluginIndex: number | undefined,
    key: string,
    value: string,
  ) {
    const pluginFeedback = this.getPluginFeedback(
      row,
      iterationNo,
      plugin,
      pluginIndex,
    );
    if (pluginFeedback === undefined) {
      return undefined;
    }
    if (
      pluginFeedback &&
      pluginIndex !== undefined &&
      pluginFeedback[pluginIndex]
    ) {
      pluginFeedback[pluginIndex][key] = value;
    }
    if (row !== undefined) {
      row.label_ndcg = JSON.stringify(this.feedback);
    }
  },

  getResourceLabel(
    row: NDCGRow | undefined,
    iterationNo: number | undefined,
    plugin: IPluginReasoning | undefined,
    pluginIndex: number | undefined,
    key: string,
  ) {
    const pluginFeedback = this.getPluginFeedback(
      row,
      iterationNo,
      plugin,
      pluginIndex,
    );
    if (pluginFeedback === undefined || pluginIndex === undefined) {
      return undefined;
    }
    return pluginFeedback?.[pluginIndex]?.[key] as string;
  },

  getPluginFeedback(
    row: NDCGRow | undefined,
    iterationNo: number | undefined,
    plugin: IPluginReasoning | undefined,
    pluginIndex: number | undefined,
  ) {
    if (
      row === undefined ||
      iterationNo === undefined ||
      plugin === undefined ||
      pluginIndex === undefined
    ) {
      return undefined;
    }

    this.checkAndInitFeedback(row, iterationNo, plugin);

    const currentIterationFeedback = this.feedback?.[iterationNo];
    const pluginFeedback: IKeyValue[] = currentIterationFeedback?.[
      plugin.PluginName
    ] as IKeyValue[];

    return pluginFeedback;
  },

  isValidPlugin(pluginName: string) {
    const validPluginNames = [
      "search_enterprise_chat",
      "search_enterprise_emails",
      "search_enterprise_meetings",
      "search_enterprise_people",
      "search_enterprise_files",
      "search_enterprise_personal_files",
    ];
    return validPluginNames.includes(pluginName);
  },
});
