import {
  Button,
  Title3,
  makeStyles,
  mergeClasses,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import React from "react";
import { registeredContributions } from "../../contribution/register";
import { updateSelectedContribution } from "../../mutators/updateContributions";
import { contributionStore } from "../../store/contributionStore";
import { store } from "../../store/store";
import { useSharedTabStyles } from "../Shared/SharedTabStyles";

const useStyles = makeStyles({
  root: {
    marginLeft: "10px",
    height: "45px",
  },
  selectedIcon: {
    color: "rgba(255, 255, 255, 1)",
  },
  normalIcon: {
    color: "rgba(255, 255, 255, 0.5)",
  },
  selectedTitle: {
    color: "#ffffff",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 600,
  },
  normalTitle: {
    color: "#B3B0AD",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400,
  },
});

export const AppBarTabs = observer(() => {
  const styles = useStyles();
  const sharedStyles = useSharedTabStyles();

  const getTitleStyle = (name: string) => {
    return contributionStore.selectedContribution?.name === name
      ? styles.selectedTitle
      : styles.normalTitle;
  };

  if (store.permission?.isUser !== true) {
    return <></>;
  }

  const getStyle = (name: string) => {
    return contributionStore.selectedContribution?.name === name
      ? mergeClasses(sharedStyles.tab, sharedStyles.activeTab)
      : mergeClasses(sharedStyles.tab, sharedStyles.inactiveTab);
  };

  const renderContributions = () => {
    return (
      <div className={styles.root}>
        {registeredContributions
          .filter((_) => _.isEnabled())
          .map((contribution) => {
            return (
              <Button
                tabIndex={0}
                shape="square"
                className={getStyle(contribution.name)}
                aria-label={contribution.name}
                key={contribution.name}
                value={contribution.name}
                onClick={() => {
                  updateSelectedContribution(contribution);
                }}
              >
                <Title3 className={getTitleStyle(contribution.name)}>
                  {contribution.name}
                </Title3>
              </Button>
            );
          })}
      </div>
    );
  };

  return renderContributions();
});
