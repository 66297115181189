import {
  Dialog,
  DialogTrigger,
  Button,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
  type ButtonProps,
} from "@fluentui/react-components";
import type { FunctionComponent } from "react";

export const DeleteTaskDialog: FunctionComponent<{
  onDelete: () => void;
  buttonProps?: ButtonProps;
}> = ({ onDelete, buttonProps }) => {
  return (
    <Dialog>
      <DialogTrigger>
        <Button {...buttonProps} />
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Are you sure to delete this task?</DialogTitle>
          <DialogContent>
            You will lost the task which you select, and the deletion action
            can't be undone!
          </DialogContent>
          <DialogActions>
            <DialogTrigger>
              <Button onClick={onDelete} appearance="primary">
                Delete
              </Button>
            </DialogTrigger>
            <DialogTrigger>
              <Button appearance="secondary">Cancel</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
