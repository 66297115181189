import { num, object, optional, str } from "sydneyeval-shared";

export const UtteranceConversationId = object({
  conversationId: str,
  requestId: optional(str),
  time: str,
  version: num,
});

export type UtteranceConversationId = ReturnType<
  typeof UtteranceConversationId
>;
