import { Field, Radio, RadioGroup, Textarea } from "@fluentui/react-components";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";
import type { MetricDefinition } from "../MetricDefinition";
import type { IConversation, SCLeoRow } from "../bizChatEvalDataProvider";
import {
  ControllableNav,
  getSydneySuggetion,
  renderRawData,
} from "../groundleo/GroundLeoDefinition";
import { getUserAlias } from "../utils/utilities";
import JSON5 from "json5";

interface ISuggestionFeedback {
  suggestion: string;
  human_label: string;
  llm_label?: string;
}

const RenderHumanLabel: FunctionComponent<{
  row: SCLeoRow;
  triggerNavRerender: () => void;
  saveFeedbackTrigger: number;
  disabled?: boolean;
}> = ({ row, triggerNavRerender, saveFeedbackTrigger, disabled }) => {
  const [comment, setComment] = useState<string>("");
  const [scleoScore, setScleoScore] = useState<number>(0);
  const [suggestionsFeedback, setSuggestionsFeedback] = useState<
    ISuggestionFeedback[]
  >([]);

  useEffect(() => {
    setComment(row.human_comment || "");
    setScleoScore(row.label_scleo_score || 0);
    if (row.label_suggestionlevel_merge) {
      const feedback = JSON.parse(
        row.label_suggestionlevel_merge || "[]",
      ) as ISuggestionFeedback[];
      setSuggestionsFeedback(feedback);
    } else {
      const llmFeedbacks = getSydneySuggestionLLMFeedback(row.response);
      const newSuggestionsFeedback = getSuggestions().map((suggestion) => {
        const feedback: ISuggestionFeedback = {
          suggestion: suggestion,
          human_label: "",
        };
        const index = llmFeedbacks.findIndex(
          (f) => f.suggestion === suggestion,
        );

        if (index !== -1) {
          feedback.llm_label = llmFeedbacks[index].label;
        }

        // remove the llm feedback from the list to avoid same suggestions in multiple turns
        llmFeedbacks.splice(index, 1);
        return feedback;
      });
      setSuggestionsFeedback(newSuggestionsFeedback);
    }
  }, [row]);

  useEffect(() => {
    if (saveFeedbackTrigger === 0) return;
    saveHumanFeedback(row, comment, scleoScore, suggestionsFeedback);
    triggerNavRerender();
  }, [saveFeedbackTrigger]);

  function getSuggestions() {
    let conversations: IConversation[];
    try {
      conversations = JSON5.parse(row.conversation) as IConversation[];
    } catch {
      return [];
    }
    const suggestions = conversations.flatMap((conversation) => {
      return getSydneySuggetion(conversation);
    });
    return suggestions;
  }

  return (
    <>
      {getSuggestions().map((suggestion, index) => (
        <div key={index}>
          <b>Suggestion {index + 1}:</b> {suggestion}
          <Field>
            <RadioGroup
              layout="horizontal"
              disabled={disabled}
              onChange={(_e, data) => {
                suggestionsFeedback[index].human_label = data.value || "";
                setSuggestionsFeedback([...suggestionsFeedback]);
              }}
              value={suggestionsFeedback[index]?.human_label}
            >
              <Radio value="Perfect" label="Perfect" />
              <Radio value="Good" label="Good" />
              <Radio value="Fair" label="Fair" />
              <Radio value="Poor" label="Poor" />
              <Radio value="Bad" label="Bad" />
            </RadioGroup>
          </Field>
        </div>
      ))}

      <Field label="Comment" style={{ margin: "1em 1em 0 0" }}>
        <Textarea
          disabled={disabled}
          resize="vertical"
          value={comment}
          onChange={(ev, value) => setComment(value.value)}
        ></Textarea>
      </Field>
    </>
  );
};

export const saveHumanFeedback = (
  row: SCLeoRow,
  comment: string,
  scleoScore: number,
  suggestionsFeedback: ISuggestionFeedback[],
) => {
  row.human_comment = comment;
  row.submitted = true;
  row.last_labelled_by = getUserAlias();
  row.label_scleo_score = scleoScore;
  row.label_suggestionlevel_merge = JSON.stringify(suggestionsFeedback);
};

export interface ISuggestionLLMFeedback {
  suggestion: string;
  label: string;
  reason: string;
}

export const getSydneySuggestionLLMFeedback = (feedbackStr: string) => {
  if (!feedbackStr) return [];
  const feedbacks = feedbackStr.split("],[").map((f) => {
    if (f.startsWith("[")) f = f.slice(1);
    if (f.endsWith("]")) f = f.slice(0, -1);
    const parts = f.split(" -- ");
    return {
      suggestion: parts[0],
      label: parts[1],
      reason: parts[2],
    } as ISuggestionLLMFeedback;
  });
  return feedbacks;
};

const renderLLMLabel = (row: SCLeoRow) => {
  const response = row.response;

  return (
    <div>
      <b>
        SCLeo score&nbsp;
        {row.scleo_score}
      </b>
      {getSydneySuggestionLLMFeedback(response).map((feedback, index) => (
        <div key={index} style={{ marginTop: "1em" }}>
          <div>
            <b>Suggestion {index + 1}:</b> {feedback.suggestion}
          </div>
          <div>
            <b>Label:</b> {feedback.label}
          </div>
          <div>
            <b>Reason:</b> {feedback.reason}
          </div>
        </div>
      ))}
    </div>
  );
};

export const getCustomizedExportData = (rows: SCLeoRow[]) => {
  const data = rows.map((row) => {
    const result: Record<string, string | number | undefined> = {
      conversation_id: row.ConversationId,
      scleo_score: row.scleo_score,
      label_scleo_score: row.label_scleo_score,
      label_suggestionlevel_merge: row.label_suggestionlevel_merge,
      comment: row.human_comment,
      last_labelled_by: row.last_labelled_by,
    };
    const feedback = JSON.parse(
      row.label_suggestionlevel_merge || "[]",
    ) as ISuggestionFeedback[];

    feedback.forEach((item, index) => {
      result[`suggestion_${index + 1}`] = item.suggestion;
      result[`llm_label_${index + 1}`] = item.llm_label;
      result[`human_label_${index + 1}`] = item.human_label;
    });

    return result;
  });
  return data;
};

export const SCLeoDefinition: MetricDefinition<SCLeoRow> = {
  name: "SCLeo",
  url: "scleo",
  fetchData: undefined,
  nav: ControllableNav,
  renderHumanLabel: RenderHumanLabel,
  renderLLMLabel: renderLLMLabel,
  renderRawData: renderRawData,
  allowLocalUpload: true,
  getCustomizedExportData: getCustomizedExportData,
  humanLabelFieldNames: [
    "human_comment",
    "label_scleo_score",
    "label_suggestionlevel_merge",
  ],
};
