"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProfileResponse = exports.UserProfileErrorResponse = exports.UserProfileSuccessResponse = void 0;
const tools_1 = require("../tools");
exports.UserProfileSuccessResponse = (0, tools_1.object)({
    jobTitle: tools_1.str,
    level: tools_1.str,
    department: tools_1.str,
});
exports.UserProfileErrorResponse = (0, tools_1.object)({
    error: (0, tools_1.object)({
        code: tools_1.str,
        message: tools_1.str,
    }),
});
exports.UserProfileResponse = (0, tools_1.or)([
    exports.UserProfileSuccessResponse,
    exports.UserProfileErrorResponse,
]);
