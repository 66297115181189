import { Button } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { refreshProductSettings } from "../../actions/productSettingsActions";
import { productSettingsStore } from "../../store/productSettingsStore";

export const ProductErrorView = observer(() => {
  const scenario = productSettingsStore.scenario;
  switch (scenario.scenarioType) {
    case "Error":
      break;

    default:
      return <></>;
  }

  return (
    <>
      <div>Someting went wrong, please click button to refresh</div>
      <div>{scenario.errorMessage}</div>
      <Button
        disabled={false}
        appearance="primary"
        onClick={refreshProductSettings}
      >
        Refresh
      </Button>
    </>
  );
});
