import { orchestrator } from "satcheljs";
import {
  resetAppConfiguration,
  selectAppConfigurationFile,
  selectAppConfigurationSnapshot,
  updateAppConfigurationContent,
} from "../actions/appConfigurationActions";
import {
  editConfiguration,
  getConfiguration,
  listConfiguration,
} from "../helpers/apiHelper";
import { updateAppConfiguration } from "../mutators/updateAppConfigutation";
import {
  isConfigEditHistory,
  isConfigWithIdentifiers,
  isCurrentConfig,
} from "../selectors/appConfiguration";
import {
  appConfigurationStore,
  isSelectedSnapshot,
} from "../store/appConfigurationStore";

const SnapshotUnavailablePlaceholder = "No snapshots available";

orchestrator(resetAppConfiguration, () => {
  updateAppConfiguration({
    ...appConfigurationStore,
    selectedFileName: undefined,
    snapshots: undefined,
    selectedSnapshot: undefined,
    selectedSnapshotContent: undefined,
    showConfigEditHistory: true,
    showConfigWithIdentifiers: false,
  });
});

orchestrator(selectAppConfigurationFile, ({ fileName }) => {
  updateAppConfiguration({
    ...appConfigurationStore,
    selectedFileName: fileName,
    snapshots: undefined,
    selectedSnapshot: undefined,
  });

  listConfiguration({
    FileName: fileName,
    IncludeEditHistory: appConfigurationStore.showConfigEditHistory,
    IncludeIdentifier: appConfigurationStore.showConfigWithIdentifiers,
  }).then((snapshots) => {
    const currentSnapshot = snapshots.find((_) => isCurrentConfig(_));
    const configEditHistory = snapshots.filter((_) => isConfigEditHistory(_));
    const configWithIdentifiers = snapshots.filter((_) =>
      isConfigWithIdentifiers(_),
    );
    const configSnapshots = [
      ...configEditHistory,
      ...configWithIdentifiers,
    ].sort(
      (a, b) =>
        new Date(b.properties.lastModified).getTime() -
        new Date(a.properties.lastModified).getTime(),
    );

    const finalSnapshots =
      currentSnapshot !== undefined
        ? [currentSnapshot, ...configSnapshots]
        : configSnapshots;

    if (finalSnapshots.length !== 0) {
      updateAppConfiguration({
        ...appConfigurationStore,
        snapshots: finalSnapshots,
      });
    } else {
      updateAppConfiguration({
        ...appConfigurationStore,
        snapshots: [
          {
            name: SnapshotUnavailablePlaceholder,
            snapshot: undefined,
            properties: {
              createdOn: "",
              lastModified: "",
            },
            blobTags: {
              blobTagSet: [],
            },
          },
        ],
      });
    }
  });
});

orchestrator(selectAppConfigurationSnapshot, ({ snapshot }) => {
  if (isSelectedSnapshot(snapshot)) {
    updateAppConfiguration({
      ...appConfigurationStore,
      selectedSnapshot: undefined,
      selectedSnapshotContent: undefined,
    });
  } else {
    updateAppConfiguration({
      ...appConfigurationStore,
      selectedSnapshot: snapshot,
      selectedSnapshotContent: undefined,
    });

    if (appConfigurationStore.selectedFileName !== undefined) {
      if (
        appConfigurationStore.selectedSnapshot?.name ===
        SnapshotUnavailablePlaceholder
      ) {
        updateAppConfiguration({
          ...appConfigurationStore,
          selectedSnapshotContent: "{}",
        });
      } else {
        getConfiguration({
          FileName: appConfigurationStore.selectedFileName,
          Snapshot: snapshot.snapshot,
          Identifier: snapshot.blobTags?.blobTagSet?.find(
            (_) => _.key === "identifier",
          )?.value,
        }).then((content) => {
          updateAppConfiguration({
            ...appConfigurationStore,
            selectedSnapshotContent: content,
          });
        });
      }
    }
  }
});

orchestrator(updateAppConfigurationContent, () => {
  if (appConfigurationStore.selectedFileName === undefined) {
    return;
  }
  if (appConfigurationStore.selectedSnapshotContent === undefined) {
    return;
  }
  if (!isCurrentConfig(appConfigurationStore.selectedSnapshot)) {
    return;
  }

  const targetFileName = appConfigurationStore.selectedFileName;
  const targetFileContent = appConfigurationStore.selectedSnapshotContent;

  updateAppConfiguration({
    ...appConfigurationStore,
    snapshots: undefined,
    selectedSnapshot: undefined,
    selectedSnapshotContent: undefined,
  });

  editConfiguration({
    FileName: targetFileName,
    FileContent: targetFileContent,
  }).then(() => {
    selectAppConfigurationFile(targetFileName);
  });
});
