import { action } from "satcheljs";

import type { SubstrateSingleUserInfor } from "../../../models/SearchUserByIdResponse";

export const openJobShareDialog = action("openJobShareDialog", () => ({}));

export const inviteUsersAction = action("inviteUsersAction", () => ({}));

export const updateSelectUsersAction = action(
  "updateSelectUsersAction",
  (users: SubstrateSingleUserInfor[]) => ({ users }),
);
