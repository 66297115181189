"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivacyFilterTemplateContentArray = exports.PrivacyFilterTemplateContent = void 0;
const tools_1 = require("../tools");
exports.PrivacyFilterTemplateContent = (0, tools_1.object)({
    query: tools_1.str,
    exp_name: tools_1.str,
    segment: tools_1.str,
    isRedacted: tools_1.str,
    redacted_category: tools_1.str,
    redacted_reasoning: tools_1.str,
    redacted_content: tools_1.unknown,
});
exports.PrivacyFilterTemplateContentArray = (0, tools_1.array)(exports.PrivacyFilterTemplateContent);
