import type { DataBag } from "sydneyeval-shared";
import { TelemetryLogger } from "sydneyeval-shared";
import { SEVAL_ARIA_TABLE_NAME } from "../constants";
import type {
  DevTelemetryEventName,
  DiagnosticTelemetryEventName,
  ErrorTelemetryEventName,
  NetworkTelemetryEventName,
  PerfTelemetryEventName,
  TelemetryEventName,
  TelemetryEventType,
  UserActionTelemetryEventName,
} from "../constants/telemetryName";
import { store } from "../store/store";
import { getAriaToken } from "./apiHelper";
import { getAppEnv } from "./appEnvHelper";

type TelemetryRequestInfo = {
  scenarioId?: string;
  requestId?: string;
  parentRequestId?: string;
};

type TelemetrySettings = {
  skipTelemetry?: boolean;
  skipSuccessRate?: boolean;
};

export type TelemetryDataBag = DataBag &
  TelemetryRequestInfo &
  TelemetrySettings;

export class TelemetryLoggerHelper {
  _telemetryLogger: TelemetryLogger | undefined = undefined;
  _telemetryLoggerPromise: Promise<TelemetryLogger> | undefined = undefined;

  initializeTelemetryLoggerIfNeeded = () => {
    if (this._telemetryLogger) {
      return Promise.resolve(this._telemetryLogger);
    }
    if (this._telemetryLoggerPromise) {
      return this._telemetryLoggerPromise;
    }

    this._telemetryLoggerPromise = getAriaToken()
      .then((ariaToken) => {
        return {
          appEnvType: getAppEnv().type,
          ariaToken: ariaToken,
          ariaTableName: SEVAL_ARIA_TABLE_NAME,
        };
      })
      .then((initInfos) => {
        return new TelemetryLogger(initInfos);
      })
      .then((logger) => {
        this._telemetryLogger = logger;
        this._telemetryLoggerPromise = undefined;
        return logger;
      })
      .catch((error) => {
        this._telemetryLogger = undefined;
        this._telemetryLoggerPromise = undefined;
        return Promise.reject(error);
      });

    return this._telemetryLoggerPromise;
  };

  _logEvent = (
    eventType: TelemetryEventType,
    eventName: TelemetryEventName,
    dataBag?: TelemetryDataBag,
  ) => {
    if (dataBag?.skipTelemetry === true) {
      return;
    }

    this.initializeTelemetryLoggerIfNeeded().then((logger) => {
      logger.logBasicEvent(
        {
          eventType,
          eventName,
          sessionId: store.sessionId,
          requestId: dataBag?.requestId,
          scenarioId: dataBag?.scenarioId,
          localAccountId: store.account?.localAccountId ?? "",
        },
        dataBag,
      );
    });
  };

  logNetworkEvent = (
    eventName: NetworkTelemetryEventName,
    dataBag?: TelemetryDataBag,
  ) => {
    this._logEvent("Network", eventName, dataBag);
  };

  logErrorEvent = (
    eventName: ErrorTelemetryEventName,
    dataBag?: TelemetryDataBag,
  ) => {
    this._logEvent("Error", eventName, dataBag);
  };

  logUserActionEvent = (
    eventName: UserActionTelemetryEventName,
    dataBag?: DataBag,
  ) => {
    this._logEvent("UserAction", eventName, dataBag);
  };

  logDevEvent = (eventName: DevTelemetryEventName, dataBag?: DataBag) => {
    this._logEvent("Dev", eventName, dataBag);
  };

  logDiagnosticEvent = (
    eventName: DiagnosticTelemetryEventName,
    dataBag?: DataBag,
  ) => {
    this._logEvent("Diagnostic", eventName, dataBag);
  };

  logPerfEvent = (eventName: PerfTelemetryEventName, dataBag?: DataBag) => {
    this._logEvent("Perf", eventName, dataBag);
  };
}

export const telemetryHelper = new TelemetryLoggerHelper();

export const perfWrapper = <T>(
  eventName: PerfTelemetryEventName,
  promise: Promise<T>,
): Promise<T> => {
  const startTime = Date.now();
  return promise.then((result) => {
    telemetryHelper.logPerfEvent(eventName, {
      latency: Date.now() - startTime,
    });
    return result;
  });
};
