import { Button, makeStyles, shorthands } from "@fluentui/react-components";

import { observer } from "mobx-react-lite";

const useStyles = makeStyles({
  activeTab: {
    height: "32px",
    ...shorthands.border(0),
    backgroundColor: "rgba(50, 49, 48, 1)",
    color: "rgba(255, 255, 255, 1)",
    "&:hover": {
      backgroundColor: "rgba(50, 49, 48, 1)",
      color: "rgba(255, 255, 255, 1)",
    },
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  },
  inactiveTab: {
    height: "32px",
    ...shorthands.border(0),
    backgroundColor: "rgba(0, 0, 0, 0.06)",
    color: "#242424",
    "&:hover": {
      backgroundColor: "rgba(50, 49, 48, 1)",
      color: "rgba(255, 255, 255, 1)",
    },
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
  },
});

export interface FilterButtonProps {
  value: string;
  text: string;
  selectedTab: string;
  activeIcon: any;
  inactionIcon: any;
  clickTab: () => void;
}

export const FilterButton = observer((props: FilterButtonProps) => {
  const styles = useStyles();

  const { value, text, selectedTab, activeIcon, inactionIcon, clickTab } =
    props;

  const getIcon = () => {
    return selectedTab === value ? activeIcon : inactionIcon;
  };

  return (
    <Button
      className={selectedTab === value ? styles.activeTab : styles.inactiveTab}
      icon={getIcon()}
      shape="circular"
      onClick={() => clickTab()}
    >
      {text}
    </Button>
  );
});
