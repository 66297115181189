import type { UserProfileResult } from "sydneyeval-shared";
import { isRestrictedSharingUser } from "sydneyeval-shared";
import {
  isUserProfileErrorResponse,
  isUserProfileSuccessResponse,
} from "../../../models/UserProfileResponse";

export const isRestrictedSharing = (
  userAlias: string,
  response: UserProfileResult,
) => {
  if (isUserProfileErrorResponse(response)) {
    return true;
  }
  if (isUserProfileSuccessResponse(response)) {
    return isRestrictedSharingUser(userAlias, response);
  }
  return true;
};
