"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LMChecklistIncidentList = exports.LMChecklistIncident = void 0;
const tools_1 = require("../tools");
exports.LMChecklistIncident = (0, tools_1.object)({
    IncidentId: tools_1.num,
    Assertion: tools_1.str,
    AssertionHash: tools_1.str,
    Query: tools_1.str,
    QueryHash: tools_1.str,
});
exports.LMChecklistIncidentList = (0, tools_1.array)(exports.LMChecklistIncident);
