import { getInputFile, getValue } from "../selectors/getJobPropV2";
import { jobStore } from "../store/jobStore";

export const getMissingFiles = (
  files: File[],
  isXlsxFiles: boolean,
): { missingFiles: string[]; missingFolder: string[] } => {
  const fileNames = files.map((file) => file.name);
  const options = getValue("configs_in_json.options") ?? [];
  const experiments =
    getValue("configs_in_json.conversations.exp_configs") ?? [];

  const inputFile = getInputFile.get() ?? "";
  const xlsxFiles = experiments.map((exp) => `${exp.exp_name}.xlsx`);
  const tsvFiles = experiments.map(
    (exp) => `${exp.exp_name}_conversations.tsv`,
  );

  let targetFiles = xlsxFiles;
  const missingFolder = [];

  if (!isXlsxFiles) {
    if (options.includes("skip_scraping")) {
      targetFiles = [...tsvFiles, inputFile];
      // Add SydneyResponseXXX folder and conversation_status_log.tsv file check.
      if (
        jobStore.selectedTemplate?.Name.includes(
          "BizChat_Evaluation_All_Metrics_Skip_Scraping",
        )
      ) {
        if (
          !files
            .map((file) => file.webkitRelativePath)
            .some((path) => path.includes("SydneyResponse"))
        ) {
          missingFolder.push("SydneyResponses");
        }
        targetFiles.push("conversation_status_log.jsonl");
      }
    } else {
      targetFiles = tsvFiles;
    }
  }
  const missingFiles = targetFiles.filter(
    (file: string) => !fileNames.includes(file),
  );
  return { missingFiles, missingFolder };
};
