import { mutatorAction } from "satcheljs";
import { store } from "../store/store";
import { CONSENT_VERSION } from "../constants";

export const updateConsentStatus = mutatorAction(
  "updateConsentStatus",
  (version: number) => {
    store.consented = version >= CONSENT_VERSION;
  },
);
