export enum Environment {
  MSIT = "MSIT",
  TestTenant = "Test Tenant",
}

export enum GroundingDataSource {
  GPT = "GPT Only",
  Web = "Web",
  Work = "Work",
  WebWork = "Web+Work",
}

export enum Term {
  Short = "Short Term",
  Medium = "Medium Term",
  None = "None",
}

export enum FRE {
  FRE = "FRE",
  FREi18n = "FRE i18n",
  FREProd = "FRE Prod",
  FRESDFMSIT = "FRE SDF/MSIT",
}
