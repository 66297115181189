import {
  Field,
  Label,
  Radio,
  RadioGroup,
  Textarea,
} from "@fluentui/react-components";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import type { MetricDefinition } from "../MetricDefinition";
import type { SbsLeoRow } from "../bizChatEvalDataProvider";
import {
  ControllableNav,
  renderRawData,
} from "../groundleo/GroundLeoDefinition";
import { CollapsedResource } from "../sharedComponents/CollapsedResource";
import { uuidv4 } from "../utils/Guid";
import { getUserAlias } from "../utils/utilities";
import {
  type IDimensionConfig,
  SBSDimensionConfigs,
  ScaleValueUserFriendlyMap,
} from "./DimensionConfig";
import { TooltipButton } from "../sharedComponents/TooltipButton";
import { useStyles } from "./styles";
import type { IKeyValue } from "../models/CommonModels";

const RenderHumanLabel: FunctionComponent<{
  row: SbsLeoRow;
  triggerNavRerender: () => void;
  saveFeedbackTrigger: number;
  disabled?: boolean;
}> = ({ row, triggerNavRerender, saveFeedbackTrigger, disabled }) => {
  const [comment, setComment] = useState<string | undefined>("");
  const [comparisonResult, setComparisonResult] = useState<
    string | undefined
  >();
  const [dimensionsFeedbacks, setDimensionsFeedbacks] =
    useState<IDimensionsFeedbacks>({});

  // the dimension that the decision driver belongs to
  const [decisionDriverDimension, setDecisionDriverDimension] = useState<
    string | undefined
  >("");
  const [decisionDriver, setDecisionDriver] = useState<string | undefined>("");
  // the values of "other" option in 5 dimensions filled by user
  const [decisionDriverOtherReason, setDecisionDriverOtherReason] = useState<
    (string | undefined)[]
  >(new Array(SBSDimensionConfigs.length));

  const styles = useStyles();

  interface IDimensionFeedback {
    userFriendly?: string;
    rate?: string;
  }

  interface IDimensionsFeedbacks {
    [dimension: string]: {
      [position: string]: IDimensionFeedback | string | undefined;
      sidePreference: string | undefined;
    };
  }

  function createEmptyDimensionsFeedbacks(): IDimensionsFeedbacks {
    const _dimensionsFeedbacks = {} as IDimensionsFeedbacks;
    for (const dimensionConig of SBSDimensionConfigs) {
      _dimensionsFeedbacks[dimensionConig.name] = {
        left: {},
        right: {},
        sidePreference: undefined,
      };
    }
    return _dimensionsFeedbacks;
  }

  useEffect(() => {
    setComment(row.human_comment || "");
    setComparisonResult(row.label_comparison_result);
    setDecisionDriverDimension(row.label_decision_driver_dimension || "");
    setDecisionDriver(row.label_decision_driver || "");
    const driverDimensionIndex = SBSDimensionConfigs.findIndex(
      (dimensionConfig) => {
        return (
          dimensionConfig.decisionDrivers.find(
            (driver) => driver.name === row.label_decision_driver,
          ) !== undefined
        );
      },
    );

    if (
      driverDimensionIndex !== -1 &&
      row.label_decision_driver ===
        "other_" + SBSDimensionConfigs[driverDimensionIndex].name
    ) {
      decisionDriverOtherReason[driverDimensionIndex] =
        row.label_decision_driver_other_reason;
      setDecisionDriverOtherReason([...decisionDriverOtherReason]);
    } else {
      setDecisionDriverOtherReason(new Array(SBSDimensionConfigs.length));
    }

    let _dimensionsFeedbacks: IDimensionsFeedbacks;
    if (!row.label_dimensions_feedbacks) {
      _dimensionsFeedbacks = createEmptyDimensionsFeedbacks();
    } else {
      _dimensionsFeedbacks = JSON.parse(
        row.label_dimensions_feedbacks,
      ) as IDimensionsFeedbacks;
      // there are very few wrong dimensionFeedback has no keys, so we need to check if it is empty
      if (Object.keys(_dimensionsFeedbacks).length === 0) {
        _dimensionsFeedbacks = createEmptyDimensionsFeedbacks();
      }
    }
    setDimensionsFeedbacks(_dimensionsFeedbacks);
  }, [row]);

  useEffect(() => {
    if (saveFeedbackTrigger === 0) return;
    saveHumanFeedback();
  }, [saveFeedbackTrigger]);

  function saveHumanFeedback() {
    row.human_comment = comment;
    row.submitted = true;
    row.last_labelled_by = getUserAlias();
    row.label_comparison_result = comparisonResult;
    row.label_decision_driver_dimension = decisionDriverDimension;
    row.label_decision_driver = decisionDriver;
    const driverDimensionIndex = SBSDimensionConfigs.findIndex(
      (dimensionConfig) => {
        return (
          dimensionConfig.decisionDrivers.find(
            (driver) => driver.name === decisionDriver,
          ) !== undefined
        );
      },
    );
    if (
      driverDimensionIndex !== -1 &&
      decisionDriver ===
        "other_" + SBSDimensionConfigs[driverDimensionIndex].name
    ) {
      row.label_decision_driver_other_reason =
        decisionDriverOtherReason[driverDimensionIndex];
    }
    row.label_dimensions_feedbacks = JSON.stringify(dimensionsFeedbacks);
    row.ControlRowData = JSON.stringify(row.ControlRow);
    triggerNavRerender();
  }

  function renderDecisionDriverDimensionOptions(
    dimensionConfig: IDimensionConfig,
    dimensionIndex: number,
  ) {
    const dimensionName = dimensionConfig.name;
    return (
      <div key={"driversection_" + dimensionName + row.unique_key}>
        <Label>{dimensionName}</Label>
        <div>
          {dimensionConfig.decisionDrivers.map((driver, index) => {
            if (driver.name !== "other_" + dimensionName) {
              return (
                <div
                  className={styles["tooltip-visible-on-parent-hover"]}
                  key={`driveroption_${dimensionName}_${index}`}
                  style={{ display: "flex" }}
                >
                  <Radio value={driver.name} label={driver.name} />
                  <div className="tooltipbutton">
                    <TooltipButton content={driver.tooltip} />{" "}
                  </div>
                </div>
              );
            } else return undefined;
          })}
          <div key={"driver_other_" + dimensionName + row.unique_key}>
            <Radio value={"other_" + dimensionName} label="other:" />
            <Textarea
              value={decisionDriverOtherReason[dimensionIndex]}
              resize="both"
              disabled={decisionDriver !== "other_" + dimensionName}
              onChange={(_e, newValue) => {
                if (decisionDriver === "other_" + dimensionName) {
                  decisionDriverOtherReason[dimensionIndex] = newValue.value;
                  setDecisionDriverOtherReason([...decisionDriverOtherReason]);
                }
              }}
            ></Textarea>
          </div>
        </div>
      </div>
    );
  }

  function renderDimensionQuestions(
    dimensionConfig: IDimensionConfig,
    position: string,
  ) {
    const userFriendlyValue = (
      dimensionsFeedbacks?.[dimensionConfig.name]?.[
        position
      ] as IDimensionFeedback
    )?.userFriendly;
    const isUserFriendly = userFriendlyValue === "yes";
    const isNotUserFriendly = userFriendlyValue === "no";
    return (
      <div>
        <Field
          label={
            <Label>
              Based on how <b>{dimensionConfig.name}</b> this response is alone,
              would you put it in front of a user? ({position})
            </Label>
          }
        >
          <RadioGroup
            layout="horizontal"
            onChange={(e, data) => {
              const prevUserFriendly = userFriendlyValue;
              if (
                prevUserFriendly !== data.value &&
                prevUserFriendly !== undefined
              ) {
                // clear the rate if user changes the userFriendly value from yes to no or no to yes
                (
                  dimensionsFeedbacks[dimensionConfig.name][
                    position
                  ] as IDimensionFeedback
                ).rate = undefined;
              }
              (
                dimensionsFeedbacks[dimensionConfig.name][
                  position
                ] as IDimensionFeedback
              ).userFriendly = data.value || "";
              // dontKnow has no sub questions, so we need to set rate manually
              if (data.value === "dontKnow") {
                (
                  dimensionsFeedbacks[dimensionConfig.name][
                    position
                  ] as IDimensionFeedback
                ).rate = "dontKnow";
              }
              setDimensionsFeedbacks({ ...dimensionsFeedbacks });
            }}
            value={
              (
                dimensionsFeedbacks?.[dimensionConfig.name]?.[
                  position
                ] as IDimensionFeedback
              )?.userFriendly
            }
            disabled={disabled}
          >
            <Radio value="yes" label="yes" />
            <Radio value="no" label="no" />
            <Radio value="dontKnow" label="N/A" />
          </RadioGroup>
        </Field>
        {userFriendlyValue !== "dontKnow" && (
          <Field
            label={
              <Label>
                How would you rate the <b>{dimensionConfig.nameNoun}</b> of this
                response ({position})
              </Label>
            }
          >
            <RadioGroup
              onChange={(e, data) => {
                (
                  dimensionsFeedbacks[dimensionConfig.name][
                    position
                  ] as IDimensionFeedback
                ).rate = data.value || "";
                setDimensionsFeedbacks({ ...dimensionsFeedbacks });
              }}
              value={
                (
                  dimensionsFeedbacks?.[dimensionConfig.name]?.[
                    position
                  ] as IDimensionFeedback
                )?.rate
              }
              layout="horizontal"
              disabled={disabled}
            >
              {dimensionConfig.scales.map((scale) => {
                return (
                  scale.value in ScaleValueUserFriendlyMap && // don't show dontKnow
                  ((ScaleValueUserFriendlyMap[scale.value] &&
                    !isNotUserFriendly) ||
                    (!ScaleValueUserFriendlyMap[scale.value] &&
                      !isUserFriendly)) && (
                    <div
                      className={styles["tooltip-visible-on-parent-hover"]}
                      style={{ display: "flex" }}
                      key={dimensionConfig.name + scale.value}
                    >
                      <Radio value={scale.value} label={scale.label} />
                      <div className="tooltipbutton">
                        <TooltipButton
                          content={
                            <span>
                              <b>{scale.label}</b> {scale.tooltip}
                            </span>
                          }
                        />
                        <div style={{ flexGrow: 1 }}></div>
                      </div>
                    </div>
                  )
                );
              })}
            </RadioGroup>
          </Field>
        )}
      </div>
    );
  }

  function renderDimensionResultThumbnail(
    dimensionConfig: IDimensionConfig,
    position: string,
  ) {
    const selectedValue = (
      dimensionsFeedbacks?.[dimensionConfig.name]?.[
        position
      ] as IDimensionFeedback
    )?.rate;
    const selectedScale = dimensionConfig.scales.find(
      (scale) => scale.value === selectedValue,
    );
    const marginLeft = position === "left" ? "0" : "-4.7em";
    return (
      <div style={{ marginLeft: marginLeft }}>
        <Radio
          disabled
          checked
          key={"thumbnail" + dimensionConfig.name + selectedValue}
          label={selectedScale?.label}
        />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Field label="Which side is better?">
          <RadioGroup
            layout="horizontal"
            onChange={(e, data) => setComparisonResult(data.value)}
            value={comparisonResult}
            disabled={disabled}
          >
            <Radio value="LeftBetter" label="Left much better" />
            <Radio value="LeftSlightlyBetter" label="Left slightly better" />
            <Radio value="Same" label="Same" />
            <Radio value="RightSlightlyBetter" label="Right slightly better" />
            <Radio value="RightBetter" label="Right much better" />
          </RadioGroup>
        </Field>
      </div>
      <br />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CollapsedResource
          key={"collapse overallreason" + row.unique_key}
          title={
            "Which of the following aspects played the most critical role in your overall preference decision making? Select one area and category:"
          }
        >
          <Field>
            <RadioGroup
              onChange={(e, data) => {
                const driverValue = data.value;
                setDecisionDriver(driverValue);
                // find which dimension this driver belongs to
                for (const dimensionConfig of SBSDimensionConfigs) {
                  if (
                    dimensionConfig.decisionDrivers.find(
                      (driver) => driver.name === driverValue,
                    )
                  ) {
                    setDecisionDriverDimension(dimensionConfig.name);
                    break;
                  }
                }
              }}
              value={decisionDriver}
              disabled={disabled}
            >
              <br />
              <div
                style={{
                  width: "auto",
                  display: "flex",
                  gap: "1px",
                }}
              >
                {SBSDimensionConfigs.map((dimensionConfig, index) => {
                  return renderDecisionDriverDimensionOptions(
                    dimensionConfig,
                    index,
                  );
                })}
              </div>
            </RadioGroup>
          </Field>
        </CollapsedResource>
      </div>

      <br />
      {SBSDimensionConfigs.map((dimensionConfig, index) => {
        const dimensionFeedback =
          dimensionsFeedbacks?.[SBSDimensionConfigs[index].name];
        const dimensionFeedbackLeft = dimensionFeedback?.["left"] as
          | IDimensionFeedback
          | undefined;
        const dimensionFeedbackRight = dimensionFeedback?.["right"] as
          | IDimensionFeedback
          | undefined;
        const hasSameDimensionLabelInTwoSides =
          !!dimensionFeedbackLeft?.rate &&
          !!dimensionFeedbackLeft?.userFriendly &&
          dimensionFeedbackLeft?.rate === dimensionFeedbackRight?.rate &&
          dimensionFeedbackLeft?.userFriendly ===
            dimensionFeedbackRight?.userFriendly;
        const finishSubQuestion = !!(
          (
            dimensionsFeedbacks?.[dimensionConfig.name]?.[
              "left"
            ] as IDimensionFeedback
          )?.rate &&
          (
            dimensionsFeedbacks?.[dimensionConfig.name]?.[
              "right"
            ] as IDimensionFeedback
          )?.rate
        );
        const thumbnail = (
          <>
            <TooltipButton content={dimensionConfig.tooltip} />
            {finishSubQuestion && (
              <div
                style={{
                  width: "auto",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  flexGrow: 1,
                }}
              >
                {renderDimensionResultThumbnail(dimensionConfig, "left")}
                {renderDimensionResultThumbnail(dimensionConfig, "right")}
              </div>
            )}
          </>
        );

        const title = dimensionConfig.name;

        return (
          <CollapsedResource
            key={"collapse" + dimensionConfig.name + row.unique_key}
            title={title}
            thumbnail={thumbnail}
          >
            <div
              style={{
                width: "auto",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
              }}
            >
              {renderDimensionQuestions(dimensionConfig, "left")}
              {renderDimensionQuestions(dimensionConfig, "right")}
            </div>
            {hasSameDimensionLabelInTwoSides &&
              dimensionFeedbackLeft?.userFriendly !== "dontKnow" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1em",
                  }}
                >
                  <Field
                    label={`If you had to choose, which response would you say is more ${dimensionConfig.name}?`}
                  >
                    <RadioGroup
                      onChange={(e, data) => {
                        dimensionsFeedbacks[
                          dimensionConfig.name
                        ].sidePreference = data.value || "";
                        setDimensionsFeedbacks({ ...dimensionsFeedbacks });
                      }}
                      value={
                        dimensionsFeedbacks?.[dimensionConfig.name]
                          ?.sidePreference
                      }
                      layout="horizontal"
                      disabled={disabled}
                    >
                      <Radio value="left" label="left" />
                      <Radio value="same" label="about the same" />
                      <Radio value="right" label="right" />
                    </RadioGroup>
                  </Field>
                </div>
              )}
          </CollapsedResource>
        );
      })}

      <Field label="Comment" style={{ margin: "1em 1em 0 0" }}>
        <Textarea
          disabled={disabled}
          resize="vertical"
          value={comment}
          onChange={(ev, value) => setComment(value.value)}
        ></Textarea>
      </Field>
    </>
  );
};

const renderLLMLabel = (row: SbsLeoRow) => {
  const sbsLeoRow = row as any;
  if (!sbsLeoRow["response"]) return <></>;
  const rawResponse = JSON.parse(sbsLeoRow["response"]);
  const scoreNames = [
    "relevance",
    "engagement",
    "detail",
    "clarity",
    "perceived intelligence",
  ];
  const responses = [];
  for (const name of scoreNames) {
    const response = rawResponse[`response_${name}`];
    const responsePrefix = sbsLeoRow[`prompt_${name}`]?.split("# Output\n")[1];
    const fullResponse = responsePrefix + response;
    responses.push({
      name: name,
      response: fullResponse,
      score: sbsLeoRow[name],
    });
  }

  return (
    <div>
      <b>
        SBSLeo score&nbsp;
        {(sbsLeoRow as SbsLeoRow).sbsleo_score ||
          (sbsLeoRow as SbsLeoRow).sbsleomultiturn_score}
      </b>
      {responses.map((response) => {
        return (
          <CollapsedResource
            key={uuidv4()}
            title={`${response.name} ${response.score}`}
          >
            <ReactMarkdown linkTarget="_blank">
              {response.response}
            </ReactMarkdown>
          </CollapsedResource>
        );
      })}
    </div>
  );
};

export const extractPropertiesFromFeedback = (
  row: SbsLeoRow,
  position: string,
  exp_name: string,
) => {
  const feedbacks = JSON.parse(
    row.label_dimensions_feedbacks || "{}",
  ) as IKeyValue;
  const result: Record<string, string> = {};
  for (const dimension of SBSDimensionConfigs) {
    const dimensionName = dimension.name;
    const feedback = feedbacks[dimensionName] as IKeyValue;
    if (feedback && feedback[position] !== undefined) {
      const positionLabel = feedback[position] as IKeyValue;
      const newPropertyName = `${dimensionName}_${exp_name}`;
      result[newPropertyName] = positionLabel["rate"] as string;
    }
  }
  return result;
};

export const normalizeComparsionResult = (
  result: string | undefined,
  expPosition: string,
  controlPostition: string,
) => {
  if (!result) {
    return result;
  }

  const lowerCaseResult = result.toLowerCase();
  if (lowerCaseResult.startsWith(expPosition)) {
    return "Exp" + result.slice(expPosition.length);
  } else if (lowerCaseResult.startsWith(controlPostition)) {
    return "Control" + result.slice(controlPostition.length);
  } else {
    return result; // "Same"
  }
};

export const getCustomizedExportData = (rows: SbsLeoRow[]) => {
  const data = rows.map((row) => {
    const expPosition = row.flip === "1" ? "left" : "right";
    const controlPosition = row.flip === "1" ? "right" : "left";

    const flatExpProperties = extractPropertiesFromFeedback(
      row,
      expPosition,
      "exp",
    );
    const flatControlProperties = extractPropertiesFromFeedback(
      row,
      controlPosition,
      "control",
    );

    return {
      query: row.query,
      segment: row.segment,
      config: row.config as string,
      unique_key_treatment:
        row.unique_key ?? `${row.exp_name}-${row.ConversationId}`, // when uploading with tsv, the unique_key is null, need to generate it
      engagement_score: row.engagement,
      relevance_score: row.relevance,
      detail_score: row.detail,
      clarity_score: row.clarity,
      perceived_intelligence_score: row["perceived intelligence"],
      sbsleo_score: row.sbsleo_score || row.sbsleomultiturn_score,
      comparison_result: normalizeComparsionResult(
        row.label_comparison_result,
        expPosition,
        controlPosition,
      ),
      decision_driver_dimension: row.label_decision_driver_dimension,
      decision_driver: row.label_decision_driver,
      decision_driver_other_reason: row.label_decision_driver_other_reason,
      comment: row.human_comment,
      last_labelled_by: row.last_labelled_by,
      dimensions_feedbacks: row.label_dimensions_feedbacks,
      flip: row.flip,
      templateId: row.templateId,
      ...flatExpProperties,
      ...flatControlProperties,
    };
  });
  return data;
};

export function checkCompleteness(row: SbsLeoRow) {
  const missingItems = [];

  if (!row.label_comparison_result) {
    missingItems.push("Overall comparison level");
  }
  // if the comparison result is not "Same", then the decision driver should be selected
  if (
    row.label_comparison_result !== "Same" &&
    (!row.label_decision_driver_dimension || !row.label_decision_driver)
  ) {
    missingItems.push("Overall performance decision");
  }

  const sbsLeoLabels = JSON.parse(
    row.label_dimensions_feedbacks ?? "{}",
  ) as IKeyValue;
  const allDimensions = SBSDimensionConfigs.map((dimension) => dimension.name);
  for (const dimensionName of allDimensions) {
    if (dimensionName in sbsLeoLabels) {
      const dimensionLabels = sbsLeoLabels[dimensionName] as IKeyValue;
      for (const position of ["left", "right"]) {
        const positionLabel = dimensionLabels[position] as IKeyValue;
        if (!positionLabel || Object.keys(positionLabel).length !== 2) {
          missingItems.push(`${position} of Dimension ${dimensionName}`);
        }
      }
    } else {
      missingItems.push(`Dimension ${dimensionName}`);
    }
  }

  return missingItems.join("\n");
}

export const SbsLeoDefinition: MetricDefinition<SbsLeoRow> = {
  name: "SBSLeo",
  url: "sbsleo",
  fetchData: undefined,
  nav: ControllableNav,
  renderHumanLabel: RenderHumanLabel,
  renderLLMLabel: renderLLMLabel,
  renderRawData: renderRawData,
  allowLocalUpload: true,
  getCustomizedExportData: getCustomizedExportData,
  humanLabelFieldNames: ["label_comparison_result", "human_comment"],
  overallGuideline:
    "For each query, you will see two possible Copilot responses. Your task is twofold: to select which one of the responses you as a user would prefer to see and selecting the main decision driver; and evaluate each response on 5 dimensions individually.",
  checkCompleteness: checkCompleteness,
};
