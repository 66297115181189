import { Button } from "@fluentui/react-components";

import React from "react";
import { BasicDialog } from "../../../../components/Shared/BasicDialog";
import { BasicInput } from "../../../../components/Shared/BasicInput";
import type { FeatureDebugDialogContributionProps } from "../../../../contribution/FeatureDebugContribution";

export const ProductMigrationDialog = (
  props: FeatureDebugDialogContributionProps,
) => {
  const [oldProductId, setOldProductId] = React.useState<string>("");
  const [newProductId, setNewProductId] = React.useState<string>("");

  const renderContent = () => (
    <>
      <div>
        This action will find all existed jobs with specific old product id and
        then assign the new product id to the job.
      </div>
      <br />
      <BasicInput
        required
        label={"Old Product Id"}
        value={oldProductId}
        onChange={setOldProductId}
      />
      <BasicInput
        required
        label={"New Product Id"}
        value={newProductId}
        onChange={setNewProductId}
      />
    </>
  );

  const renderButtons = () => (
    <>
      <Button
        disabled={!oldProductId || !newProductId}
        appearance="primary"
        onClick={() => {
          return;
        }}
      >
        Migrate
      </Button>
    </>
  );

  return (
    <BasicDialog
      {...props}
      title={"Migrate Product Id"}
      renderContent={renderContent}
      renderButtons={renderButtons}
    />
  );
};
