import { action } from "satcheljs";

export const uploadFile = action(
  "uploadFile",
  (
    fileName: string,
    file: File,
    fileType: "Bing" | "MChat",
    onSuccess?: () => void,
    onError?: (error: any) => void,
  ) => ({
    fileName,
    file,
    fileType,
    onSuccess,
    onError,
  }),
);
