import { Button, shorthands, tokens } from "@fluentui/react-components";
import { ArrowDownload24Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    button: {
      ...shorthands.margin("5px"),
    },
    conversationHeader: {
      width: "1000px",
      textOverflow: "ellipsis",
      ...shorthands.overflow("hidden"),
      whiteSpace: "nowrap",
    },
    operationBar: {
      display: "flex",
      flexDirection: "row-reverse",
    },
    tableContent: {
      width: "100%",
      ...shorthands.overflow("auto"),
    },
    tableContainer: {
      ...shorthands.borderRadius("8px"),
      ...shorthands.border("2px", "solid", "#EDEBE9"),
      borderCollapse: "collapse",
    },
    th: {
      backgroundColor: "rgb(250, 249, 248)",
      ...shorthands.padding("10px"),
      ...shorthands.border("2px", "solid", "#EDEBE9"),
    },
    td: {
      ...shorthands.padding("10px"),
      ...shorthands.border("2px", "solid", "#EDEBE9"),
      ...shorthands.padding("10px", "12px"),
    },
    preContainer: {
      whiteSpace: "pre-wrap",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

interface IJobFileRenderViewProps {
  fileName: string;
  content: string;
  download: () => void;
}

export const JobFileRenderView = observer((props: IJobFileRenderViewProps) => {
  const styles = useStyles();
  const { fileName, content, download } = props;

  const renderDownloadButton = () => {
    return (
      <div className={styles.operationBar}>
        <Button
          className={styles.button}
          onClick={() => download()}
          icon={<ArrowDownload24Regular />}
        >
          Download {fileName}
        </Button>
      </div>
    );
  };

  const isJson = (str: unknown) => {
    try {
      if (typeof str === "string") {
        JSON.parse(str);
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
    return true;
  };

  const serializedContent = isJson(content ?? "{}")
    ? JSON.stringify(JSON.parse(content ?? "{}"), null, 2)
    : content;

  return (
    <div>
      {renderDownloadButton()}
      <pre className={styles.preContainer}>{serializedContent}</pre>
    </div>
  );
});
