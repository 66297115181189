import { makeStyles, shorthands } from "@fluentui/react-components";
import { observer } from "mobx-react";

type QuerySetFileViewProps = {
  status: string | undefined;
};

const useStyles = makeStyles({
  root: {
    height: "20px",
    width: "100px",
    backgroundColor: "#F3F2F1",
    ...shorthands.borderRadius("2px"),
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export const QuerySetStatusView = observer((props: QuerySetFileViewProps) => {
  const styles = useStyles();

  const getText = () => {
    switch (props.status) {
      case "Running":
      case "Created":
      case "Submitted":
        return "In Progress";
      case "Completed":
        return "Completed";
      default:
        return props.status;
    }
  };

  const getTextColor = () => {
    switch (props.status) {
      case "Running":
      case "Created":
      case "Submitted":
        return `rgb(0, 94, 166)`;
      case "Completed":
        return `rgb(13, 102, 53)`;
      default:
        return undefined;
    }
  };

  const text = getText();
  if (text === undefined) {
    return <></>;
  }

  return (
    <div className={styles.root} style={{ color: getTextColor() }}>
      <div>{getText()}</div>
    </div>
  );
});
