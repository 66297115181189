"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubstrateResponse = exports.SubstrateObject = void 0;
const typeHelper_1 = require("../tools/typeHelper");
exports.SubstrateObject = (0, typeHelper_1.object)({
    ObjectId: typeHelper_1.str,
    PrimaryShardAddress: (0, typeHelper_1.optional)(typeHelper_1.str),
    RecipientType: (0, typeHelper_1.optional)(typeHelper_1.str),
    RecipientTypeDetails: (0, typeHelper_1.optional)(typeHelper_1.str),
    PrimarySmtpAddress: (0, typeHelper_1.optional)(typeHelper_1.str),
    DisplayName: (0, typeHelper_1.optional)(typeHelper_1.str),
    UserType: (0, typeHelper_1.optional)(typeHelper_1.str),
    TenantId: (0, typeHelper_1.optional)(typeHelper_1.str),
    ImmediateParentId: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.SubstrateResponse = (0, typeHelper_1.object)({
    value: (0, typeHelper_1.array)(exports.SubstrateObject),
});
