import { array, parseJsonStr, Product } from "sydneyeval-shared";

export const convertTemplateTypeToProduct = (
  templateType: string,
): "CWC" | "OPG" | "BizChat" | "All" => {
  switch (templateType) {
    case "CWC":
      return "CWC";
    case "OPG":
      return "OPG";
    default:
      return "BizChat";
  }
};

export const checkProductBackupFile = (file: File) => {
  return file.text().then((_) => parseJsonStr(_, array(Product)));
};

export const tryFormat = (jsonString: string | undefined) => {
  try {
    return jsonString === undefined
      ? undefined
      : JSON.stringify(JSON.parse(jsonString), null, 2);
  } catch {
    return jsonString;
  }
};
