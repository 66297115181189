import { observer } from "mobx-react-lite";
import React, { createContext } from "react";
import { useMediaQuery } from "react-responsive";
import type { WrapperProps } from "./WrapperProps";

export const SharedToasterPrefix = "shared-prefix-";
export const SharedToasterId = "shared-toaster";

export type DeviceSize = "xs" | "sm" | "md" | "lg";

const ResponsiveContext = createContext<{
  deviceSize: DeviceSize;
}>({
  deviceSize: "md",
});

export const useDeviceSize = () => {
  return React.useContext(ResponsiveContext);
};

export const ResponsiveProvider = observer((props: WrapperProps) => {
  const isExtraSmall = useMediaQuery({ query: "(max-width: 768px)" });
  const isSmall = useMediaQuery({ query: "(max-width: 1024px)" });
  const isMedium = useMediaQuery({ query: "(max-width: 1200px)" });

  const deviceSize = (() => {
    if (isExtraSmall) {
      return "xs";
    }

    if (isSmall) {
      return "sm";
    }

    if (isMedium) {
      return "md";
    }

    return "lg";
  })();

  return (
    <ResponsiveContext.Provider
      value={{
        deviceSize,
      }}
    >
      {props.children}
    </ResponsiveContext.Provider>
  );
});
