import type { AccountInfo } from "@azure/msal-browser";
import { mutatorAction } from "satcheljs";
import { store } from "../store/store";

export const updateAccountInfo = mutatorAction(
  "updateAccountInfo",
  (account: AccountInfo) => {
    store.account = account;
  },
);
