import {
  PersonFeedback24Filled,
  PersonFeedback24Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { telemetryHelper } from "../../helpers/telemetryHelper";
import { AppBarButton } from "./AppBarButton";

const FeedbackIcon = bundleIcon(
  PersonFeedback24Filled,
  PersonFeedback24Regular,
);

export const AppBarFeedback = observer(() => {
  return (
    <AppBarButton
      ariaLabel={"Settings Menu Trigger"}
      onClick={() => {
        window.open("https://aka.ms/sevalbug", "_blank");
        telemetryHelper.logUserActionEvent("ClickProvideFeedback");
      }}
      Icon={FeedbackIcon}
      text={"Provide Feedback"}
    />
  );
});
