import { Body1 } from "@fluentui/react-components";
import {
  ArrowLeft24Filled,
  ArrowLeft24Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import { observer } from "mobx-react";
import { closeSidePane } from "../../../../../mutators/sidePaneMutators";
import { resultStore } from "../../../store/resultStore";
import { useDetailPageStyles } from "../sharedStyles";
import { JobDetailSettingView } from "./JobDetailSettingView";

const BackIcon = bundleIcon(ArrowLeft24Filled, ArrowLeft24Regular);

export const SettingPane = observer(() => {
  const styles = useDetailPageStyles();

  const job = resultStore.resultJob;

  return (
    <div className={styles.root}>
      <div className={styles.settingTitleRow}>
        <div
          className={styles.backButton}
          role="button"
          aria-label="Back Button"
          onClick={() => {
            closeSidePane();
          }}
        >
          <BackIcon />
        </div>
        <Body1 className={styles.settingTitle}>Config file</Body1>
      </div>
      <div>
        <JobDetailSettingView settings={job?.Settings ?? ""} />
      </div>
    </div>
  );
});
