import { MChatDisplayedMetrics } from "../../../constants/MChatDisplayedMetrics";
import {
  queryMChatMetricsbyHash,
  queryMChatMetricsbyMetrics,
  queryMChatSValue,
} from "../../../helpers/apiHelper";
import { perfWrapper } from "../../../helpers/telemetryHelper";
import type {
  MChatMetricsByHashResponse,
  MChatMetricsByMetricsResponse,
} from "../models/UtteranceResponse";
import {
  updatedAnnotationDebuglinkList,
  updatedDebugLinkList,
  updatedMetricsToDisplay,
  updatedselectedMChatMetrics,
  updatedUtteranceDetail,
  updatedUtteranceList,
} from "../mutators/jobDetailsMutators";
import {
  addLoadingMessage,
  removeLoadingMessage,
} from "../mutators/jobResultMutators";
import type {
  MChatUtteranceMetrics,
  MChatUtteranceMetricsSingleData,
} from "../store/jobDetailStore";
import { jobDetailStore } from "../store/jobDetailStore";
import { resultStore } from "../store/resultStore";

export const getReasoningMetricKey = (key: string) => {
  switch (key) {
    case "groundleo":
      return "groundleo" as const;
    case "stewieleo":
      return "stewieleo" as const;
    case "tcr_score":
      return "tcr" as const;
    case "stewieleosbs_score":
      return "stewieleosbs" as const;
    case "sbsleo_score":
      return "sbsleo" as const;
    case "pileo":
      return "pileo" as const;
    case "contextleo_score":
      return "contextleo" as const;
    case "opgsummleo_score":
      return "opgsummleo" as const;
    case "groundleo_claimbreak_score":
      return "groundleo_scorebreak" as const;
    case "teamsgroundleo_score":
      return "teamsgroundleo" as const;
    default:
      return undefined;
  }
};

export const getSBSDebugLink = (query: string) => {
  if (jobDetailStore.debuglinkList.length === 0) {
    return "";
  }
  return (
    jobDetailStore.debuglinkList.find(
      (item) => item.query === query && item.exp_name === "control",
    )?.value ?? ""
  );
};

export const getAnnotationDebugLink = (query: string, expName: string) => {
  if (jobDetailStore.annotationDebuglinkList.length === 0) {
    return "";
  }

  const checkExpName = (itemExpName: string) => {
    if (expName === "control") {
      return itemExpName === "control";
    } else {
      return itemExpName !== "control";
    }
  };
  return (
    jobDetailStore.annotationDebuglinkList.find(
      (item) => item.query === query && checkExpName(item.exp_name),
    )?.value ?? ""
  );
};

export const getMetricFeedbackLink = (templateId: string) => {
  return `https://msasg.visualstudio.com/Bing_and_IPG/_workitems/create/Bug?templateId=${templateId}&ownerId=660944f7-d720-417e-97b8-0f9af65015e4`;
};

export const segmentFilterValue = (originalSegments: string[]) =>
  originalSegments.map((s) => s.split(" - ")[0]).join(", ");

const newMetricRecord = (
  isControl: boolean,
  metricKey: string,
  value: number,
) => ({
  key: metricKey,
  control: isControl ? value : 0,
  treatment: isControl ? 0 : value,
});

export const MChatMetricsByMetricsResponseConvertHelper = (
  data: MChatMetricsByMetricsResponse,
) => {
  const finalRecords = Object.assign(
    new Map<string, MChatUtteranceMetrics>(),
    jobDetailStore.utteranceList,
  );

  data.forEach((item) => {
    const mapKey = `${item.segment}-${item.query_hash}`;
    const isControl = item.exp_name === "control";
    const record = finalRecords.get(mapKey);
    if (
      item.metric_key &&
      jobDetailStore.metricsToDisplay.includes(item.metric_key) === false
    ) {
      updatedMetricsToDisplay(item.metric_key);
    }
    if (record === undefined) {
      finalRecords.set(mapKey, {
        segment: item.segment,
        query_hash: item.query_hash,
        query: item.query,
        metrics:
          item.metric_key && item.value
            ? [newMetricRecord(isControl, item.metric_key, item.value)]
            : [],
      });
    } else if (item.metric_key && item.value) {
      const metricRecord = record.metrics.find(
        (m) => m.key === item.metric_key,
      );
      if (metricRecord === undefined) {
        finalRecords.set(mapKey, {
          ...record,
          metrics: [
            ...record.metrics,
            newMetricRecord(isControl, item.metric_key, item.value),
          ],
        });
      } else {
        const newRecord = record.metrics.map((m) => {
          if (m.key === item.metric_key) {
            return {
              ...m,
              [isControl ? "control" : "treatment"]: item.value,
            };
          } else {
            return m;
          }
        });
        finalRecords.set(mapKey, {
          ...record,
          metrics: newRecord,
        });
      }
    }
  });

  updatedselectedMChatMetrics(
    jobDetailStore.metricsToDisplay
      .sort(
        (a, b) =>
          MChatDisplayedMetrics.indexOf(a) - MChatDisplayedMetrics.indexOf(b),
      )
      .slice(0, 3),
  );
  return finalRecords;
};

export const getMChatMetrics = () => {
  const jobId = resultStore.resultJob?.JobId;

  if (!jobId) {
    return;
  }
  addLoadingMessage("Loading MChat Metric Diagnosis Utterance List");
  perfWrapper(
    "LoadMChatMetricDiagnosisUtteranceList",
    Promise.all([
      queryMChatMetricsbyMetrics({
        jobId,
        metricKey: MChatDisplayedMetrics,
      }).then((res) => {
        updatedUtteranceList(MChatMetricsByMetricsResponseConvertHelper(res));
        removeLoadingMessage("Loading MChat Metric Diagnosis Utterance List");
      }),
    ]),
  );

  addLoadingMessage("Loading MChat Metric Diagnosis SValue");
  perfWrapper(
    "LoadMChatMetricDiagnosisSValue",
    Promise.all([
      queryMChatSValue({
        jobId,
        expName: "control",
        metricKey: "sbs_debuglink",
      }).then((res) => {
        updatedDebugLinkList(res);
      }),
      queryMChatSValue({
        jobId,
        expName: "control",
        metricKey: "annotation_debuglink",
      }).then((res) => {
        updatedAnnotationDebuglinkList(res);
        removeLoadingMessage("Loading MChat Metric Diagnosis SValue");
      }),
    ]),
  );
};

export const MChatMetricsByHashResponseConvertHelper = (
  res: MChatMetricsByHashResponse,
) => {
  const shouldBeRemoved = (key: string): boolean => {
    // remove end with _number
    if (key.match(/_\d+$/)) {
      return true;
    }

    const otherIgnoreKeys = [
      "filename",
      "groundleo_binary",
      "mean_EE-Success",
      "mean_Optimistic_Success@10",
    ];

    if (otherIgnoreKeys.includes(key)) {
      return true;
    }

    return false;
  };

  const filtered = res.filter((_) => !shouldBeRemoved(_.metric_key));

  let convertedData: MChatUtteranceMetricsSingleData[] = [];
  for (const item of filtered) {
    const record = convertedData.find((_) => _.key === item.metric_key);
    const isControl = item.exp_name === "control";
    if (record === undefined) {
      convertedData.push(
        newMetricRecord(isControl, item.metric_key, item.value),
      );
    } else {
      convertedData = convertedData.map((_) => {
        if (_.key === item.metric_key) {
          return isControl
            ? { ..._, control: item.value }
            : {
                ..._,
                treatment: item.value,
              };
        }
        return _;
      });
    }
  }
  return convertedData;
};

export const getMChatUtteranceDetail = (queryHash: string) => {
  const jobId = resultStore.resultJob?.JobId;

  if (!jobId) {
    return;
  }

  perfWrapper(
    "LoadMChatMetricDiagnosisUtteranceDetail",
    queryMChatMetricsbyHash({
      jobId,
      queryHash,
    }).then((res) => {
      const convertedData = MChatMetricsByHashResponseConvertHelper(res);
      updatedUtteranceDetail(queryHash, convertedData);
    }),
  );
};
