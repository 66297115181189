import { observer } from "mobx-react-lite";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import type { FeatureSettingsDialogContributionProps } from "../../../../contribution/FeatureSettingsContribution";
import { UploadingFileDialog } from "./UploadingFileDialog";

export const UploadQuerySetDialog = observer(
  (props: FeatureSettingsDialogContributionProps) => {
    const toast = useToast();

    return (
      <UploadingFileDialog
        type="Query"
        isOpen={props.isOpen}
        onCancel={() => {
          props.close();
        }}
        onStart={() => {
          props.close();
          toast.onToastStart("Uploading file...");
        }}
        onSuccess={() => {
          toast.onToastSuccess("File uploaded successfully");
        }}
        onFailure={(error) => {
          toast.onToastFailure(
            `File upload failed with message: ${error.message}`,
          );
        }}
      />
    );
  },
);
