import { Link, mergeClasses } from "@fluentui/react-components";
import type { FunctionComponent } from "react";
import { useStyles } from "../styles";

export const Bottom: FunctionComponent<{ emailAddress: string }> = ({
  emailAddress,
}) => {
  const styles = useStyles();
  return (
    <div
      className={mergeClasses(
        styles.stackHorizontal,
        styles.stackHorizontalWithGap,
      )}
    >
      <div>
        Report any feedback to&nbsp;
        <Link
          target="_blank"
          href={`mailto:${emailAddress}`}
          rel="noopener noreferrer"
        >
          {emailAddress}
        </Link>
      </div>
      <div>
        <Link
          target="_blank"
          href="https://portal.microsofticm.com/imp/v3/incidents/create?tmpl=B3f3sv"
        >
          create incident&nbsp;
        </Link>
        for urgent issues
      </div>
      <div style={{ flexGrow: 1 }}>
        <div
          className={styles.stackHorizontal}
          style={{ justifyContent: "end" }}
        >
          <p>
            <Link
              href="http://aka.ms/dpn"
              target="_blank"
              rel="noopener noreferrer"
            >
              Data Protection Notice
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
