import {
  Body1,
  Button,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import {
  ArrowLeft24Filled,
  ArrowLeft24Regular,
  Copy16Filled,
  Copy16Regular,
  Copy24Filled,
  Copy24Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import React from "react";
import { useToast } from "../../../../../components/Wrappers/ToasterProvider";
import { closeSidePane } from "../../../../../mutators/sidePaneMutators";
import { getLoadedMetricsWithCitations } from "../../../helpers/bingResultHelper";
import { bingRequestFormatter } from "../../../helpers/formatHelper";
import type {
  BingDiagnosisMetricReasoningGroup,
  BingDiagnosisUtteranceResult,
} from "../../../models/BingDiagnosisFile";
import { resultStore } from "../../../store/resultStore";
import { TreeComponent } from "./TreeComponent";

type BingSBSViewProp = {
  sbsKey: string;
  jobIdForShare?: string;
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  titleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleRowLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  backButton: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  title: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
    ...shorthands.margin("0", "0", "0", "9px"),
  },
  utteranceRow: {
    marginTop: "37px",
    display: "flex",
    flexDirection: "column",
  },
  utteranceTitle: {
    color: "#1B1A19",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",
  },
  utteranceContent: {
    color: "#000",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  details: {
    marginTop: "12px",
    ...shorthands.borderRadius("6px"),
    ...shorthands.border("1px", "solid", "#EDEBE9"),
    ...shorthands.overflow("hidden"),
    backgroundColor: "#FFF",
    "& .fui-TreeItemLayout__main": {
      boxSizing: "border-box",
      maxWidth: "100%",
      lineHeight: "20px",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      color: "#11100F",
    },
  },
  detailsTitle: {
    height: "32px",
    backgroundColor: "#EFF6FC",
    ...shorthands.padding("0", "16px"),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("8px"),
  },
  detailsTitlewithConvId: {
    height: "50px",
    backgroundColor: "#EFF6FC",
    ...shorthands.padding("0", "0", "0", "16px"),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("8px"),
  },
  detailsTitleContent: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32px",
  },
  detailsTitleContentwithConvId: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "22px",
  },
  spinner: {
    marginTop: "20px",
  },
  convid: {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    color: "grey",
  },
  copyconvidbtn: {
    border: "none",
    backgroundColor: "#EFF6FC",
    color: "grey",
  },
});

const BackIcon = bundleIcon(ArrowLeft24Filled, ArrowLeft24Regular);
const CopyLinkIcon = bundleIcon(Copy24Filled, Copy24Regular);
const CopyConvIdIcon = bundleIcon(Copy16Filled, Copy16Regular);

export const BingSBSView = observer((props: BingSBSViewProp) => {
  const styles = useStyles();
  const toaster = useToast();

  const sbsKey = props.sbsKey;
  const loadedMetrics = resultStore.loadedMetrics.get(sbsKey);
  const loadedSBSViews = resultStore.loadedSBSViews.get(sbsKey);

  const renderTitle = (obj: unknown): string => {
    return typeof obj === "object" &&
      obj !== null &&
      "Request" in obj &&
      typeof obj.Request === "string"
      ? bingRequestFormatter(obj.Request)
      : "Object";
  };

  const renderScore = (node: unknown, parent: string): string => {
    if (typeof node === "string" && parent.includes("response_")) {
      const responseType = parent.match(/.*response_(.*)/);
      if (responseType && responseType.length > 1) {
        const regex = new RegExp(
          `.*${
            responseType[1].charAt(0).toUpperCase() + responseType[1].slice(1)
          }(.*)`,
        );
        const score = node.match(regex);
        if (score && score.length > 1) {
          return score[1];
        }
      }
    }
    return "";
  };

  const renderConversationTitle = (key: string) => {
    return (
      <div className={styles.detailsTitle}>
        <Body1 className={styles.detailsTitleContent}>
          {`${key} conversation`}
        </Body1>
      </div>
    );
  };

  const renderTitleWithConvId = (key: string, conversationId: string) => {
    return (
      <div className={styles.detailsTitlewithConvId}>
        <Body1 className={styles.detailsTitleContentwithConvId}>
          {`${key} conversation`}
          <div className={styles.convid}>
            <span>{`Conversation ID: ${conversationId}`}</span>
            <Button
              data-testid="copy-convid-button"
              size={"small"}
              className={styles.copyconvidbtn}
              onClick={() => {
                toaster.onToastStart(
                  "Copying the ConversationID to clipboard...",
                );
                navigator.clipboard.writeText(conversationId);
                toaster.onToastSuccess(
                  "The conversationID has been copied to your clipboard.",
                );
              }}
              icon={<CopyConvIdIcon />}
            />
          </div>
        </Body1>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.titleRow}>
        <div className={styles.titleRowLeft}>
          <div
            className={styles.backButton}
            role="button"
            aria-label="Back Button"
            onClick={() => {
              closeSidePane();
            }}
          >
            <BackIcon />
          </div>
          <Body1 className={styles.title}>{`Metric judgement`}</Body1>
        </div>

        <Button
          data-testid="copy-link-button"
          size={"medium"}
          onClick={() => {
            if (props.jobIdForShare !== undefined) {
              toaster.onToastStart("Copying the link to clipboard...");
              const url = new URL(window.location.href);
              const shareLink = `${url.protocol}//${url.host}/detail/${
                props.jobIdForShare
              }/${encodeURIComponent(
                "Metric Diagnosis",
              )}/metricKey/${encodeURIComponent(
                loadedSBSViews?.baseline[0].Request ?? "No utterance found!",
              )}`;
              navigator.clipboard.writeText(shareLink);
              toaster.onToastSuccess(
                "The link has been copied to your clipboard.",
              );
            }
          }}
          icon={<CopyLinkIcon />}
        >
          Copy Link
        </Button>
      </div>
      <div className={styles.utteranceRow}>
        <Body1 className={styles.utteranceTitle}>Utterance:</Body1>
        <Body1 className={styles.utteranceContent}>
          {loadedSBSViews?.baseline[0].Request ?? "No utterance found!"}
        </Body1>
      </div>
      {loadedMetrics &&
        Object.keys(loadedMetrics).map((key) => {
          let currentItem:
            | BingDiagnosisMetricReasoningGroup
            | BingDiagnosisUtteranceResult[]
            | null = loadedMetrics[key];

          if (currentItem === null || currentItem === undefined) {
            return <React.Fragment key={key}></React.Fragment>;
          }

          if (Array.isArray(currentItem)) {
            currentItem = getLoadedMetricsWithCitations(key, currentItem);
            const convId =
              currentItem.length > 0
                ? currentItem[currentItem.length - 1].ConversationId
                : "";
            const hasConvId = convId !== undefined && convId !== "";
            return (
              <div className={styles.details} key={key}>
                {!hasConvId && renderConversationTitle(key)}
                {hasConvId && renderTitleWithConvId(key, convId)}
                <TreeComponent
                  content={currentItem}
                  parent={`${key} conversation`}
                  renderTitle={(item) => renderTitle(item)}
                />
              </div>
            );
          } else {
            return (
              <div className={styles.details} key={key}>
                <div className={styles.detailsTitle}>
                  <Body1 className={styles.detailsTitleContent}>
                    {`${key} Control: ${currentItem.baseline.Score?.toFixed(
                      2,
                    )}, Treatment: ${currentItem.treatment?.Score?.toFixed(2)}`}
                  </Body1>
                </div>

                <TreeComponent
                  content={currentItem}
                  parent={`${sbsKey}-${key}`}
                  renderScore={(node, parent) => renderScore(node, parent)}
                />
              </div>
            );
          }
        })}
    </div>
  );
});
