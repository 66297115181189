import { Button, makeStyles } from "@fluentui/react-components";
import { Warning20Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";

const useStyles = makeStyles({
  button: {
    backgroundColor: "rgba(221, 66, 66, 0.4)",
    "&:hover": {
      backgroundColor: "rgba(221, 66, 66, 0.5)",
    },
  },
  warningIcon: {
    marginRight: "2px",
  },
});

type LMChecklistCriticalFailureBannerProps = {
  handleBannerClick?: () => void;
  checkingForFailures?: boolean;
};

export const LMChecklistCriticalFailureBanner = observer(
  (props: LMChecklistCriticalFailureBannerProps) => {
    const { handleBannerClick, checkingForFailures } = props;
    const styles = useStyles();

    const bannerText = checkingForFailures
      ? "Checking for critical LMChecklist regressions..."
      : "Warning: This job has critical LM Checklist regressions. Click to view.";

    return (
      <Button
        className={!checkingForFailures ? styles.button : undefined}
        onClick={handleBannerClick}
        disabled={checkingForFailures}
      >
        {!checkingForFailures && (
          <Warning20Regular
            data-testid="warning-icon"
            className={styles.warningIcon}
          />
        )}
        <span>{bannerText}</span>
      </Button>
    );
  },
);
