import { useMsal } from "@azure/msal-react";
import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import React from "react";
import { loginAction } from "../../actions/loginAction";
import { TENANT_ID } from "../../constants";
import { getSubstrateToken } from "../../helpers/accessTokenHelper";
import { getDynamicPermission } from "../../helpers/apiHelper";
import { clearLocalStorgeExceptLocalCache } from "../../helpers/cacheHelper";
import { initializeEcsClient } from "../../helpers/flightHelper";
import { getRandomUUID } from "../../helpers/getRandomUUID";
import { perfWrapper } from "../../helpers/telemetryHelper";
import { updateAccountInfo } from "../../mutators/updateAccount";
import { updatePermission } from "../../mutators/updatePermission";
import { resetTokenRefreshError } from "../../mutators/updateTokenRefreshError";
import { store } from "../../store/store";
import {
  AvalonTokenInputDialog,
  HeronTokenInputDialog,
  SubstrateTokenInputDialog,
} from "../Other/LocalTokenInputDialog";
import type { WrapperProps } from "./WrapperProps";

export const AccountProvider = observer((props: WrapperProps) => {
  const { instance, accounts } = useMsal();

  React.useEffect(() => {
    const accountInfo = accounts[0];
    if (accountInfo) {
      updateAccountInfo(accountInfo);

      perfWrapper(
        "LoadUserPermission",
        getSubstrateToken()
          .then((_) =>
            getDynamicPermission({
              SubstrateToken: _,
              CallerFileNameLine: "AccountProvider",
              ClientRequestId: getRandomUUID(),
              ProcessName: "SEVAL",
            }),
          )
          .then((dynamicPermission) => {
            updatePermission(dynamicPermission);
          }),
      );

      initializeEcsClient(accountInfo.idTokenClaims?.oid ?? "", TENANT_ID);
    }
  }, [accounts]);

  return (
    <>
      {props.children}
      <Dialog
        open={store.tokenRefreshError !== undefined}
        onOpenChange={() => {
          resetTokenRefreshError();
        }}
      >
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{`Token Refresh Failed`}</DialogTitle>
            <DialogContent>
              <Body1>{`Please try to refresh the login status by clicking the button below.`}</Body1>
              <br />
              <Body1>{store.tokenRefreshError}</Body1>
            </DialogContent>
            <DialogActions>
              <Button
                appearance="primary"
                onClick={() => {
                  clearLocalStorgeExceptLocalCache();
                  loginAction(instance, "redirect");
                }}
              >
                Refresh
              </Button>
              <Button onClick={() => resetTokenRefreshError()}>Close</Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      <SubstrateTokenInputDialog />
      <HeronTokenInputDialog />
      <AvalonTokenInputDialog />
    </>
  );
});
