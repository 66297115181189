import { computed } from "mobx";
import { isValidSettingsThrowError } from "sydneyeval-shared";
import { jobStore } from "../../store/jobStore";
import { getJSONConfigError } from "./getJSONConfigError";

export const getJSONSchemaError = computed(() => {
  // If the current JSON config is not valid, then we don't need to validate the schema
  if (getJSONConfigError.get() !== undefined) {
    return undefined;
  }

  try {
    !isValidSettingsThrowError(
      jobStore.configurationJsonString,
      jobStore.selectedTemplate?.SettingsSchema,
    );
  } catch (e) {
    if (e instanceof Error) {
      return e.message;
    } else {
      return "Error configuration! Please match the schema of the selected template.";
    }
  }
});
