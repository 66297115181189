import { mutatorAction } from "satcheljs";
import type { AppConfigurationStore } from "../store/appConfigurationStore";
import { appConfigurationStore } from "../store/appConfigurationStore";

export const updateAppConfiguration = mutatorAction(
  "updateAppConfiguration",
  (store: AppConfigurationStore) => {
    appConfigurationStore.selectedFileName = store.selectedFileName;
    appConfigurationStore.snapshots = store.snapshots;
    appConfigurationStore.selectedSnapshot = store.selectedSnapshot;
    appConfigurationStore.selectedSnapshotContent =
      store.selectedSnapshotContent;
    appConfigurationStore.showConfigEditHistory = store.showConfigEditHistory;
    appConfigurationStore.showConfigWithIdentifiers =
      store.showConfigWithIdentifiers;
  },
);
