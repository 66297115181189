import {
  Body1,
  Button,
  Caption1Strong,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Tree,
  TreeItem,
  TreeItemLayout,
} from "@fluentui/react-components";
import { Eye16Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { Tip } from "../../../../../components/Shared/Tip";
import { GeneralTable } from "../../JobList/GeneralTable";
import { useSharedTableStyles } from "../../ResultsComparison/Table/shardTableStyles";
import { useQueryManagementStyles } from "../styles/QueryManagementStyles";
import type {
  Annotation,
  Assertion,
  AssertionProperty,
  Query,
} from "../types/Query";

type QueryManagementExpandedProps = {
  query: Query;
  renderTagLikeObjects: (
    objects: string[] | AssertionProperty[] | undefined,
  ) => JSX.Element;
  renderDraftIcon: () => JSX.Element;
};

export const QueryManagementExpanded = observer(
  (props: QueryManagementExpandedProps) => {
    const { query, renderTagLikeObjects, renderDraftIcon } = props;

    const styles = useQueryManagementStyles();
    const sharedTableStyles = useSharedTableStyles();

    const renderAssertionExamples = (assertion: Assertion) =>
      assertion.examples ? (
        <Dialog>
          <DialogTrigger>
            <Button
              icon={<Eye16Regular />}
              className={styles.assertionExamplesButton}
            >
              Examples
            </Button>
          </DialogTrigger>
          <DialogSurface>
            <DialogBody>
              <DialogTitle>Assertion examples</DialogTitle>
              <DialogContent>
                <Body1>
                  <span className={styles.boldLabel}>Assertion:</span>{" "}
                  {assertion.text}
                </Body1>
                <Tree style={{ marginTop: "6px" }}>
                  {assertion.examples.map((example, index) => (
                    <TreeItem key={index} itemType="branch">
                      <TreeItemLayout>Example {index + 1}</TreeItemLayout>
                      <Tree>
                        <TreeItem itemType="leaf">
                          <TreeItemLayout>
                            <span className={styles.boldLabel}>Response:</span>{" "}
                            {example.response}
                          </TreeItemLayout>
                        </TreeItem>
                        <TreeItem itemType="leaf">
                          <TreeItemLayout>
                            <span className={styles.boldLabel}>Score:</span>{" "}
                            {example.score}
                          </TreeItemLayout>
                        </TreeItem>
                        <TreeItem itemType="leaf">
                          <TreeItemLayout>
                            <span className={styles.boldLabel}>Rationale:</span>{" "}
                            {example.rationale}
                          </TreeItemLayout>
                        </TreeItem>
                      </Tree>
                    </TreeItem>
                  ))}
                </Tree>
              </DialogContent>
              <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                  <Button appearance="secondary">Close</Button>
                </DialogTrigger>
              </DialogActions>
            </DialogBody>
          </DialogSurface>
        </Dialog>
      ) : (
        <></>
      );

    const renderAnnotations = (annotations: Annotation[] | undefined) => {
      return (
        <>
          <Caption1Strong>Annotations</Caption1Strong>
          {annotations === undefined ? (
            <div style={{ marginTop: "8px", marginBottom: "8px" }}>
              <pre>None</pre>
            </div>
          ) : (
            <GeneralTable
              loadMore={() => {
                return;
              }}
              hasMore={false}
              rows={annotations}
              columns={[
                {
                  title: "Text",
                  render: (item) => <Body1>{item.text}</Body1>,
                  weight: 1,
                  minWidth: "200px",
                },
                {
                  title: "Query Annotation Type",
                  render: (item) => <Body1>{item.queryAnnotationType}</Body1>,
                  weight: 1,
                  minWidth: "200px",
                },
              ]}
            />
          )}
        </>
      );
    };

    const renderAssertions = (assertions: Assertion[] | undefined) => {
      return (
        <>
          <Caption1Strong>Assertions</Caption1Strong>
          {assertions === undefined ? (
            <div style={{ marginTop: "8px" }}>
              <pre>None</pre>
            </div>
          ) : (
            <GeneralTable
              loadMore={() => {
                return;
              }}
              hasMore={false}
              rows={assertions}
              columns={[
                {
                  title: "Text",
                  render: (item) => (
                    <div className={sharedTableStyles.longTextWrapper}>
                      {item.draft && renderDraftIcon()}
                      <Tip withArrow content={item.text} relationship="label">
                        <Body1 className={sharedTableStyles.longText}>
                          {item.text}
                        </Body1>
                      </Tip>
                    </div>
                  ),
                  weight: 2,
                  minWidth: "300px",
                },
                {
                  title: "Level",
                  render: (item) =>
                    item.level ? (
                      <Tip withArrow content={item.level} relationship="label">
                        <Body1>{item.level}</Body1>
                      </Tip>
                    ) : (
                      <></>
                    ),
                  weight: 1,
                  minWidth: "150px",
                },
                {
                  title: "Owner(s)",
                  render: (item) =>
                    item.owner ? (
                      <Tip withArrow content={item.owner} relationship="label">
                        <Body1>{item.owner}</Body1>
                      </Tip>
                    ) : (
                      <></>
                    ),
                  weight: 1,
                  minWidth: "150px",
                },
                {
                  title: "Properties",
                  render: (item) => renderTagLikeObjects(item.properties),
                  weight: 2,
                  minWidth: "300px",
                },
                {
                  title: "Examples",
                  render: (item) => renderAssertionExamples(item),
                  weight: 2,
                  minWidth: "150px",
                },
                {
                  title: "Tag",
                  render: (item) =>
                    item.fromTag ? (
                      <Tip
                        withArrow
                        content={item.fromTag}
                        relationship="label"
                      >
                        <Body1>{item.fromTag}</Body1>
                      </Tip>
                    ) : (
                      <></>
                    ),
                  weight: 1,
                  minWidth: "200px",
                },
              ]}
            />
          )}
        </>
      );
    };

    const renderJsonContent = (title: string, data: string | undefined) => {
      const { parsedString, errorString } = parseJson(data);

      return (
        <>
          <Caption1Strong>{title}</Caption1Strong>
          <div style={{ marginTop: "8px" }}>
            {errorString !== undefined && (
              <div style={{ color: "red" }}>{errorString}</div>
            )}
            <pre>{parsedString}</pre>
          </div>
        </>
      );
    };

    return (
      <div style={{ marginBottom: "16px" }}>
        {renderAssertions(query.assertions)}
        {renderAnnotations(query.annotations)}
        {renderJsonContent("Label", query.label)}
        {renderJsonContent("Plugins", query.plugins)}
        {renderJsonContent("Plugin Options", query.pluginoptions)}
      </div>
    );
  },
);

export const parseJson = (jsonString: string | undefined) => {
  if (jsonString === undefined) {
    return {
      parsedString: "None",
      errorString: undefined,
    };
  } else {
    try {
      return {
        parsedString: JSON.stringify(JSON.parse(jsonString), null, 2),
        errorString: undefined,
      };
    } catch (exception) {
      return {
        parsedString: jsonString,
        errorString:
          exception instanceof SyntaxError
            ? exception.message
            : "JSON parsing error",
      };
    }
  }
};
