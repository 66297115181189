import { makeStyles } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import React from "react";
import {
  registeredDebugGroupContributions,
  registeredSettingsContribution,
} from "../../contribution/register";
import { updateSelectedContributionDialogId } from "../../mutators/updateContributions";
import { contributionStore } from "../../store/contributionStore";
import type { WrapperProps } from "./WrapperProps";

const useStyles = makeStyles({
  root: {
    width: "100vw",
    height: "100vh",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
});

export const ContributionProvider = observer((props: WrapperProps) => {
  const styles = useStyles();

  const settingsDialogs = registeredSettingsContribution
    .filter((_) => _.featureContribution.isEnabled())
    .map((_) => {
      switch (_.type) {
        case "click":
          return <React.Fragment key={_.guid} />;
        case "dialog":
          return (
            <React.Fragment key={_.guid}>
              {_.render({
                isOpen:
                  contributionStore.selectedContributionDialogId === _.guid,
                close: () => {
                  updateSelectedContributionDialogId(undefined);
                },
              })}
            </React.Fragment>
          );
      }
    });

  const debugDialogs = registeredDebugGroupContributions
    .filter((_) => _.isEnabled())
    .flatMap((_) => _.debugContributions)
    .filter((_) => _.featureContribution.isEnabled())
    .map((_) => {
      switch (_.type) {
        case "click":
          return <React.Fragment key={_.guid} />;
        case "dialog":
          return (
            <React.Fragment key={_.guid}>
              {_.render({
                isOpen:
                  contributionStore.selectedContributionDialogId === _.guid,
                close: () => {
                  updateSelectedContributionDialogId(undefined);
                },
              })}
            </React.Fragment>
          );
      }
    });

  return (
    <div className={styles.root}>
      {props.children}
      {settingsDialogs}
      {debugDialogs}
    </div>
  );
});
