import type { JobConfigurationValue } from "../../getJobPropV2";

export const checkExpConfigs = (
  value: JobConfigurationValue<"configs_in_json.conversations.exp_configs">,
  path: string,
) => {
  if (value === undefined) {
    return undefined;
  }

  if (value.length !== 2) {
    return {
      path,
      message: "Exp Configs should have 2 elements",
    };
  }

  if (value[0].exp_name === value[1].exp_name) {
    return {
      path,
      message: `Exp Configs should have different exp names, currently both are "${value[0].exp_name}"`,
    };
  }

  return undefined;
};
