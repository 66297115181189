import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Dropdown,
  Field,
  Option,
  Textarea,
  Tooltip,
  mergeClasses,
  tokens,
} from "@fluentui/react-components";
import { DeleteRegular, InfoRegular } from "@fluentui/react-icons";
import JSON5 from "json5";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";
import type { MetricDefinition } from "../MetricDefinition";
import type {
  GroudLeoClaimBreakRow,
  IConversation,
} from "../bizChatEvalDataProvider";
import { extractQueryResultPair } from "../conversation/QueryResultPair";
import { convertResourceToCardProps } from "../conversation/ResourceCard";
import {
  ClaimImportanceOptions,
  GroundLabelOptions,
} from "../models/LabelConstant";
import { ClearableChoiceGroup } from "../sharedComponents/ClearableChoiceGroup";
import { CollapsedResource } from "../sharedComponents/CollapsedResource";
import { useStyles } from "../styles";
import { getUserAlias } from "../utils/utilities";
import { ControllableNav, renderRawData } from "./GroundLeoDefinition";

export interface IClaim {
  claim: string;
  response: string;
  claim_importance: number;
  groundleo_claimbreak_score: number;
  supporting_citations?: any;
}

export interface ILLMResponse {
  [key: string]: { [key: string]: IClaim | number } | number;
  conversation_score: number;
}

interface IClaimFeedback {
  claim: string;
  llm_score?: number;
  label_groundness: string;
  label_verifiable_externally?: boolean;
  label_importance: string;
  label_evidence_resources?: string[];
}

export interface IClaimBreakFeedback {
  [key: string]: { [key: string]: IClaimFeedback };
}

export const ADDED_CLAIM_TURN_KEY = "Added";

// extract text under "Index supporting each piece of information" section
export function extractSupportIndexInfo(rawResponse: string) {
  const startSplitter = "## Index supporting each piece of information\n";
  const endSplitter = "\n\n## Reasoning";
  const indexInfo = rawResponse
    .split(startSplitter)?.[1]
    ?.split(endSplitter)?.[0];
  if (indexInfo === "- None") return undefined;
  return "Maybe: " + indexInfo;
}

const hardcodeClaimEnd =
  'If it is not, write final score at the end as "Final score\\nN/A".'; // if the claim ends with this hardcode string, remove it from the display

export function createEmptyFeedback(
  llmResponse: ILLMResponse,
): IClaimBreakFeedback {
  const result: IClaimBreakFeedback = {};
  Object.entries(llmResponse)
    .filter(([turnKey]) => !Number.isNaN(Number(turnKey)))
    .forEach(([turnKey, value]) => {
      const claims = value as unknown as { [key: string]: IClaim | number };
      const turnFeedback: { [key: string]: IClaimFeedback } = {};
      Object.entries(claims)
        .filter(([claimKey]) => !Number.isNaN(Number(claimKey)))
        .forEach(([claimKey, value]) => {
          const claim = value as unknown as IClaim;
          if (!claim.claim.endsWith(hardcodeClaimEnd)) {
            // do not add the hard coded claim to feedback since it's not shown in ui
            const claimFeedback: IClaimFeedback = {
              claim: claim.claim,
              label_groundness: "",
              label_verifiable_externally: false,
              label_importance: "",
              llm_score: claim.groundleo_claimbreak_score,
            };
            turnFeedback[claimKey] = claimFeedback;
          }
        });
      if (Object.keys(turnFeedback).length > 0) {
        result[turnKey] = turnFeedback;
      }
    });
  result[ADDED_CLAIM_TURN_KEY] = {};
  return result;
}

export const RenderHumanLabel: FunctionComponent<{
  row: GroudLeoClaimBreakRow;
  triggerNavRerender: () => void;
  saveFeedbackTrigger: number;
  triggerConversationRerender?: (expandedClaims: Set<string>) => void;
  expandedClaims?: Set<string>;
  disabled?: boolean;
  showVerifiableExternallyOption?: boolean;
}> = ({
  row,
  triggerNavRerender,
  saveFeedbackTrigger,
  triggerConversationRerender,
  expandedClaims,
  disabled,
  showVerifiableExternallyOption,
}) => {
  const styles = useStyles();
  const [feedback, setFeedback] = useState<IClaimBreakFeedback>({});
  const _llmResponse: ILLMResponse = row.response
    ? JSON.parse(row.response)
    : { conversation_score: 0 };
  const [llmResponse, setLlmResponse] = useState<ILLMResponse>(_llmResponse);
  const [comment, setComment] = useState<string>(""); // <Textarea> doesn't work when the initial value is undefined. So we use empty string instead.
  const [humanLabel, setHumanLabel] = useState<string | undefined>();
  const [claimBreakdownIssueLabel, setClaimBreakdownIssueLabel] =
    useState<boolean>(false);
  const [newlyInputAddedClaim, setNewlyInputAddedClaim] = useState<string>(""); // what user typed in "Add missing claim" callout textbox
  const [resourceTitles, setResourceTitles] = useState<string[]>([]);

  useEffect(() => {
    const llmResponse: ILLMResponse = row.response
      ? JSON.parse(row.response)
      : { conversation_score: 0 };
    setLlmResponse(llmResponse);
    let feedback: IClaimBreakFeedback;
    if (!row.label_claimlevel_merge) {
      feedback = createEmptyFeedback(llmResponse);
    } else {
      // deep copy. Feedback is an object to store unsaved change. It doesn't reflect on row until submit button is clicked.
      // ref, commentRef, lowScoreReasonComponentRef are also used to store unsaved change.
      feedback = JSON.parse(row.label_claimlevel_merge);
    }
    setFeedback(feedback);
    setComment(row.human_comment || "");
    setHumanLabel(row.label_overall);
    const hasLabelClaimBreakDownIssue =
      row.label_claimbreakdown_issue?.toString()?.toLowerCase() === "true";
    setClaimBreakdownIssueLabel(hasLabelClaimBreakDownIssue);

    setResourceTitles(getEvidenceResourceInfo());
  }, [row]);

  useEffect(() => {
    if (saveFeedbackTrigger === 0) return;
    saveHumanFeedback();
  }, [saveFeedbackTrigger]);

  function saveHumanFeedback() {
    row.label_overall = humanLabel || "";
    row.human_comment = comment || "";
    row.submitted = true;
    row.last_labelled_by = getUserAlias();
    row.label_claimlevel_merge = JSON.stringify(feedback);
    row.label_claimbreakdown_issue = claimBreakdownIssueLabel || false;
    triggerNavRerender();
  }

  function getEvidenceResourceInfo() {
    let conversations: IConversation[];
    try {
      conversations = JSON5.parse(row.conversation) as IConversation[];
    } catch {
      return [];
    }

    const _resourceTitles = [] as string[];
    for (const conversation of conversations) {
      const promptSearches = conversation.filtered_search;
      if (promptSearches) {
        for (const searchItem of promptSearches) {
          const searchResults = searchItem.result;
          if (!searchItem.tool_invocation.includes("M365Copilot_language")) {
            const queryResultPair = extractQueryResultPair(searchResults);
            const { snippets } = queryResultPair;
            snippets?.map((snippet) => {
              const resourceProps = convertResourceToCardProps(
                snippet.source,
                snippet.snippet,
              );
              const { title } = resourceProps;
              _resourceTitles.push(title);
            });
          }
        }
      }
    }
    return _resourceTitles;
  }

  function onClickExpandClaim(
    claimKey: string,
    expanded: boolean,
    turnKey?: string,
  ) {
    if (turnKey === ADDED_CLAIM_TURN_KEY) return;
    const _expandedClaims = new Set<string>(expandedClaims);
    if (expanded) {
      _expandedClaims.add(claimKey);
    } else {
      _expandedClaims.delete(claimKey);
    }
    triggerConversationRerender?.(_expandedClaims);
  }

  function renderResourceInDropdown(turnKey: string, claimKey: string) {
    return (
      <Field label="Add evidence resources">
        <Dropdown
          key={row.ConversationId + turnKey + claimKey}
          multiselect={true}
          style={{ width: "50em" }}
          selectedOptions={
            feedback[turnKey]?.[claimKey]?.label_evidence_resources || []
          }
          value={
            feedback[turnKey]?.[claimKey]?.label_evidence_resources?.join(
              ",",
            ) ?? ""
          }
          onOptionSelect={(ev, data) => {
            feedback[turnKey][claimKey].label_evidence_resources =
              data.selectedOptions;
            setFeedback({ ...feedback });
          }}
        >
          <div style={{ maxHeight: "25em" }}>
            {resourceTitles.map((title, index) => {
              const trimmedTitle =
                (title?.length || 0) > 85
                  ? title.substring(0, 85) + "..."
                  : title;
              return (
                <Option
                  key={`${trimmedTitle} (${index + 1})`}
                  title={`${title} (${index + 1})`}
                >{`${trimmedTitle} (${index + 1})`}</Option>
              );
            })}
          </div>
        </Dropdown>
      </Field>
    );
  }

  function renderClaimQuestions(
    claim: IClaim,
    turnKey: string,
    claimKey: string,
    supportingIndexInfo?: string,
  ) {
    const claimText = claim.claim;
    return (
      <CollapsedResource
        title={"Claim " + claimKey}
        key={claimKey}
        itemKey={claimKey}
        turnKey={turnKey}
        onClickExpand={onClickExpandClaim}
      >
        <div>
          <div style={{ display: "flex", gap: "2em" }}>
            {claimText}
            {turnKey === ADDED_CLAIM_TURN_KEY && (
              <Dialog>
                <DialogTrigger>
                  <Tooltip
                    content="Remove this added claim"
                    relationship="label"
                  >
                    <Button icon={<DeleteRegular />} />
                  </Tooltip>
                </DialogTrigger>
                <DialogSurface>
                  <DialogBody>
                    <DialogTitle>Remove this claim?</DialogTitle>
                    <DialogContent>{claimText}</DialogContent>
                    <DialogActions>
                      <DialogTrigger>
                        <Button appearance="secondary">Cancel</Button>
                      </DialogTrigger>
                      <DialogTrigger>
                        <Button
                          appearance="primary"
                          onClick={() => {
                            delete feedback[turnKey][claimKey];
                            setFeedback({ ...feedback });
                          }}
                        >
                          Remove
                        </Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
            )}
          </div>
          {supportingIndexInfo && (
            <Tooltip
              content={supportingIndexInfo}
              relationship="label"
              positioning="below"
            >
              <Button appearance="transparent" icon={<InfoRegular />} />
            </Tooltip>
          )}
        </div>
        <ClearableChoiceGroup
          label=""
          choiceGroupLabel=""
          options={GroundLabelOptions.map((option) => option.label)}
          optionDisplayValues={GroundLabelOptions.map((option) => option.value)}
          selectedKey={feedback?.[turnKey]?.[claimKey]?.label_groundness}
          onChoiceChange={(option) => {
            feedback[turnKey][claimKey].label_groundness = option || "";
            setFeedback({ ...feedback });
          }}
        ></ClearableChoiceGroup>
        {showVerifiableExternallyOption && (
          <Checkbox
            checked={feedback[turnKey][claimKey].label_verifiable_externally}
            onChange={(ev, data) => {
              feedback[turnKey][claimKey].label_verifiable_externally =
                !!data.checked;
              setFeedback({ ...feedback });
            }}
            label="Verifiable using external search"
          />
        )}
        <br />
        <br />
        <ClearableChoiceGroup
          label=""
          choiceGroupLabel="Rate this claim's importance in relation to the query:"
          options={ClaimImportanceOptions.map((option) => option.label)}
          optionDisplayValues={ClaimImportanceOptions.map(
            (option) => option.value,
          )}
          selectedKey={feedback?.[turnKey]?.[claimKey]?.label_importance}
          onChoiceChange={(option) => {
            feedback[turnKey][claimKey].label_importance = option || "";
            setFeedback({ ...feedback });
          }}
        ></ClearableChoiceGroup>
        <br />
        {renderResourceInDropdown(turnKey, claimKey)}
      </CollapsedResource>
    );
  }

  function renderOverallLabelSection() {
    return (
      <div>
        <h4>Overall Label</h4>
        <div
          className={mergeClasses(
            styles.stackVertical,
            styles.stackVerticalWithGap,
          )}
          style={{ flexWrap: "wrap" }}
        >
          {row.response && (
            <Checkbox
              checked={claimBreakdownIssueLabel}
              onChange={(ev, data) =>
                setClaimBreakdownIssueLabel(!!data.checked)
              }
              label="There is an issue with the claim breakdown compared to the original response"
            />
          )}
          <ClearableChoiceGroup
            label=""
            choiceGroupLabel="Based on your evidence research for this response, what is your overall perception of its groundedness level?"
            options={GroundLabelOptions.map((option) => option.label)}
            optionDisplayValues={GroundLabelOptions.map(
              (option) => option.value,
            )}
            selectedKey={humanLabel}
            onChoiceChange={(option) => {
              setHumanLabel(option);
            }}
          ></ClearableChoiceGroup>
        </div>
        <Field style={{ margin: "1em 0" }} label="Tell us more">
          <Textarea
            disabled={disabled}
            resize="vertical"
            onChange={(ev, value) => setComment(value.value)}
            value={comment}
          ></Textarea>
        </Field>
      </div>
    );
  }

  const userAddedClaims = Object.entries(
    feedback?.[ADDED_CLAIM_TURN_KEY] ?? [],
  );

  return (
    <>
      {row.response && (
        <div style={{ color: tokens.colorNeutralForeground2BrandHover }}>
          Label the claim as grounded (accurate) if you find at least one source
          in the search results list supporting it. <br />
          Some claims may display source indices that are can potentially
          validate the claim. <br />
          If those are not helpful, continue to search for evidence in the rest
          of the sources until validation was found or source list (all search
          types) is exhausted.
        </div>
      )}
      <h4>Claim Breakdown</h4>
      {Object.entries(llmResponse as ILLMResponse)
        .filter(([turnKey, claims]) => {
          if (Number.isNaN(Number(turnKey))) {
            return false;
          }
          // filter out turns that only has one claim, and that claim is the invalid hard coded value
          let hasValidClaim = true;
          const _claimKVPairs = Object.entries(claims).filter(
            ([claimKey]) => !Number.isNaN(Number(claimKey)),
          ) as [string, IClaim][];
          if (
            _claimKVPairs.length === 1 &&
            _claimKVPairs[0][1].claim.endsWith(hardcodeClaimEnd)
          ) {
            hasValidClaim = false;
          }
          return hasValidClaim;
        })
        .map(([turnKey, value]) => {
          const claims = value as unknown as { [key: string]: IClaim | number };
          return (
            <div key={turnKey} style={{ paddingLeft: "1em" }}>
              <CollapsedResource
                title={"Turn " + turnKey}
                key={turnKey}
                defaultCollapsed={false}
              >
                <div style={{ paddingLeft: "1em" }}>
                  {Object.entries(claims)
                    .filter(([claimKey]) => !Number.isNaN(Number(claimKey)))
                    .map(([claimKey, value]) => {
                      const claim = value as unknown as IClaim;
                      const supportingIndexInfo = extractSupportIndexInfo(
                        claim.response,
                      );
                      return renderClaimQuestions(
                        claim,
                        turnKey,
                        claimKey,
                        supportingIndexInfo,
                      );
                    })}
                </div>
              </CollapsedResource>
            </div>
          );
        })}
      {userAddedClaims.length > 0 && (
        <div style={{ paddingLeft: "1em" }}>
          <CollapsedResource title={"Added Claims"} defaultCollapsed={false}>
            <div style={{ paddingLeft: "1em" }}>
              {userAddedClaims.map(([claimKey, value]) => {
                const claim = value as unknown as IClaim;
                return renderClaimQuestions(
                  claim,
                  ADDED_CLAIM_TURN_KEY,
                  claimKey,
                  undefined,
                );
              })}
            </div>
          </CollapsedResource>
        </div>
      )}

      <Dialog>
        <DialogTrigger>
          <Button className="AddClaimTrigger">Add missing claim</Button>
        </DialogTrigger>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Add missing claim</DialogTitle>
            <DialogContent>
              <Textarea
                style={{ display: "flex", height: "8em", marginBottom: ".5em" }}
                placeholder="claim"
                resize="vertical"
                value={newlyInputAddedClaim}
                onChange={(ev, data) => {
                  setNewlyInputAddedClaim(data.value);
                }}
              />
            </DialogContent>
            <DialogActions>
              <DialogTrigger>
                <Button appearance="secondary">Cancel</Button>
              </DialogTrigger>
              <DialogTrigger>
                <Button
                  className="AddClaimConfirm"
                  appearance="primary"
                  onClick={() => {
                    const newClaimFeedback: IClaimFeedback = {
                      claim: newlyInputAddedClaim,
                      label_groundness: "",
                      label_verifiable_externally: false,
                      label_importance: "",
                    };
                    const currentClaimKeys = Object.keys(
                      feedback?.[ADDED_CLAIM_TURN_KEY],
                    );
                    const keysAsNumber = currentClaimKeys?.map(Number);
                    let currentMaxKey = -1;
                    if (keysAsNumber && keysAsNumber.length > 0) {
                      currentMaxKey = Math.max(...keysAsNumber);
                    }
                    feedback[ADDED_CLAIM_TURN_KEY][currentMaxKey + 1] =
                      newClaimFeedback;
                    setFeedback({ ...feedback });
                  }}
                >
                  Add
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      {renderOverallLabelSection()}
    </>
  );
};

export function checkCompleteness(row: GroudLeoClaimBreakRow) {
  const missingItems = [];
  if (!row.label_overall || row.label_overall === "") {
    missingItems.push("overall groundness level");
  }
  try {
    const claimBreakdownLabels = JSON.parse(
      row.label_claimlevel_merge,
    ) as IClaimBreakFeedback;
    for (const turnKey of Object.keys(claimBreakdownLabels)) {
      for (const claimKey of Object.keys(claimBreakdownLabels[turnKey])) {
        const claimFeedback = claimBreakdownLabels[turnKey][claimKey];
        if (
          !claimFeedback.label_groundness ||
          claimFeedback.label_groundness === ""
        ) {
          missingItems.push(
            `groundness level for${
              turnKey !== ADDED_CLAIM_TURN_KEY ? " Turn" : ""
            } ${turnKey} Claim ${claimKey}`,
          );
        }
        if (
          !claimFeedback.label_importance ||
          claimFeedback.label_importance === ""
        ) {
          missingItems.push(
            `claim importance for${
              turnKey !== ADDED_CLAIM_TURN_KEY ? " Turn" : ""
            } ${turnKey} Claim ${claimKey}`,
          );
        }
      }
    }
  } catch (e) {
    if (e instanceof Error && !row.label_claimlevel_merge) {
      missingItems.push("all claim breakdown labels");
    }
  }
  return missingItems.join(", ");
}

const getCustomizedExportData = (rows: GroudLeoClaimBreakRow[]) => {
  const data = rows.map((row) => {
    const result = {
      conversation_id: row.ConversationId,
      config: row.config as string,
      comment: row.human_comment,
      last_labelled_by: row.last_labelled_by,
      label_claimbreakdown_issue: row.label_claimbreakdown_issue,
      label_overall: row.label_overall,
    } as any;

    if (row.label_claimlevel_merge) {
      const feedback = JSON.parse(
        row.label_claimlevel_merge,
      ) as IClaimBreakFeedback;
      Object.entries(feedback).forEach(([turnKey, value]) => {
        Object.entries(value).forEach(([claimKey, claimFeedback]) => {
          result[`turn_${turnKey}_claim_${claimKey}_claim`] =
            claimFeedback.claim;
          result[`turn_${turnKey}_claim_${claimKey}_llm_score`] =
            claimFeedback.llm_score;
          result[`turn_${turnKey}_claim_${claimKey}_label_groundness`] =
            claimFeedback.label_groundness;
          result[
            `turn_${turnKey}_claim_${claimKey}_label_verifiable_externally`
          ] = claimFeedback.label_verifiable_externally;
          result[`turn_${turnKey}_claim_${claimKey}_label_importance`] =
            claimFeedback.label_importance;
          result[`turn_${turnKey}_claim_${claimKey}_label_evidence_resources`] =
            claimFeedback.label_evidence_resources;
        });
      });
    }
    return result;
  });
  return data;
};

export const GroundLeoClaimBreakDefinition: MetricDefinition<GroudLeoClaimBreakRow> =
  {
    name: "GroundLeoClaimBreak",
    url: "groundleoclaimbreak",
    fetchData: undefined,
    nav: ControllableNav,
    renderHumanLabel: RenderHumanLabel,
    renderLLMLabel: undefined,
    renderRawData: renderRawData,
    allowLocalUpload: true,
    getCustomizedExportData: getCustomizedExportData,
    humanLabelFieldNames: [
      "label_overall",
      "label_claimlevel_merge",
      "human_comment",
    ],
    overallGuideline:
      "For each query on the left pane, judge its claims for accuracy based on the available sources. In your assessment, check for hallucinations and logical leaps.",
    checkCompleteness: checkCompleteness,
  };
