"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingFileName = exports.SettingFileValues = exports.AMLWorkSpaceSettings = void 0;
const typeHelper_1 = require("../tools/typeHelper");
exports.AMLWorkSpaceSettings = (0, typeHelper_1.strictObject)({
    feature: (0, typeHelper_1.undefinedOr)((0, typeHelper_1.mapOf)(typeHelper_1.bool)),
    job_manager: (0, typeHelper_1.undefinedOr)((0, typeHelper_1.strictObject)({
        rerun_rules_over_period_of_time: (0, typeHelper_1.undefinedOr)((0, typeHelper_1.array)((0, typeHelper_1.strictObject)({
            error_code: (0, typeHelper_1.undefinedOr)(typeHelper_1.str),
            error_message: (0, typeHelper_1.undefinedOr)(typeHelper_1.str),
            component_name: (0, typeHelper_1.undefinedOr)(typeHelper_1.str),
            start_time_utc: typeHelper_1.dateStr,
            end_time_utc: typeHelper_1.dateStr,
            isEnabled: typeHelper_1.bool,
        }))),
    })),
});
exports.SettingFileValues = [
    ["aml_eyeson_staging", exports.AMLWorkSpaceSettings],
    ["aml_eyeson_prod", exports.AMLWorkSpaceSettings],
    ["aml_eyesoff_staging", exports.AMLWorkSpaceSettings],
    ["aml_eyesoff_prod", exports.AMLWorkSpaceSettings],
];
exports.SettingFileName = (0, typeHelper_1.or)(exports.SettingFileValues.map((_) => (0, typeHelper_1.constStr)(_[0])));
