import {
  Button,
  Spinner,
  makeStyles,
  mergeClasses,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { ArrowDown16Regular, ArrowUp16Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";
import { openSidePane } from "../../../../../mutators/sidePaneMutators";
import type { BingDiagnosisLoadedItem } from "../../../models/BingDiagnosisFile";
import {
  updateDiagnosisSortBy,
  updateDiagnosisSortByOrder,
} from "../../../mutators/jobResultMutators";
import { resultStore } from "../../../store/resultStore";
import { BingSBSView } from "../SidePane/BingSBSView";

const useStyles = makeStyles({
  tableContainer: {
    width: "100%",
    overflowX: "scroll",
    transform: "rotateX(180deg)",
    "&::-webkit-scrollbar": {
      height: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      ...shorthands.borderRadius("10px"),
      backgroundColor: "#d6dee1",
      boxShadow: " 0 4px 28px rgba(123,151,158,.25)",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#a8bbbf",
    },
  },
  table: {
    transform: "rotateX(180deg)",
    width: "100%",
    display: "inline-block",
  },
  headerRow: {
    backgroundColor: "rgb(250, 249, 248)",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    ...shorthands.overflow("hidden"),
    ...shorthands.borderRadius("6px", "6px", "0px", "0px"),
    ...shorthands.borderTop("1px", "solid", "rgb(237, 235, 233)"),
    ...shorthands.borderLeft("1px", "solid", "rgb(237, 235, 233)"),
    ...shorthands.borderRight("1px", "solid", "rgb(237, 235, 233)"),
    ...shorthands.borderBottom(
      tokens.strokeWidthThin,
      "solid",
      tokens.colorNeutralStroke2,
    ),
  },
  bodyRow: {
    backgroundColor: "white",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    ...shorthands.borderLeft("1px", "solid", "rgb(237, 235, 233)"),
    ...shorthands.borderRight("1px", "solid", "rgb(237, 235, 233)"),
    ...shorthands.borderBottom(
      tokens.strokeWidthThin,
      "solid",
      tokens.colorNeutralStroke2,
    ),
    cursor: "pointer",
    ":hover": {
      backgroundColor: tokens.colorNeutralBackground2Hover,
    },
  },
  metricsHeader: {
    cursor: "pointer",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
  },
  td: {
    boxSizing: "border-box",
    height: "53px",
    display: "flex",
    justifyItems: "center",
  },
  leftBorder: {
    ...shorthands.borderLeft("1px", "solid", "rgb(237, 235, 233)"),
  },
  th: {
    boxSizing: "border-box",
    fontFamily: tokens.fontFamilyBase,
    fontSize: "13px",
    fontWeight: 600,
    lineHeight: "18px",
    fontStyle: "normal",
  },
  contentWrapper: {
    ...shorthands.flex(1),
    boxSizing: "border-box",
    maxWidth: "100%",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    paddingLeft: "8px",
    paddingRight: "8px",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  firstContentWrapper: {
    paddingLeft: "20px",
  },
  lastContentWrapper: {
    paddingRight: "20px",
  },
  spinner: {
    marginTop: "20px",
    height: "100px",
  },
  sortBy: {
    color: tokens.colorBrandForeground1,
    fontWeight: 600,
  },
});

type BingDiagnosisTableProps = {
  loadMore: () => void;
  hasMore: boolean;
  rows: BingDiagnosisLoadedItem[];
  columns: {
    title: string;
    render: (item: BingDiagnosisLoadedItem) => JSX.Element;
    weight: number;
    minWidth: string;
    maxWidth?: string;
  }[];
};

export const BingDiagnosisTable = observer((props: BingDiagnosisTableProps) => {
  const { columns } = props;
  const styles = useStyles();
  const { jobId, uttquery } = useParams();
  const [autoOpenSidePane, setAutoOpenSidePane] = React.useState(
    jobId !== undefined && uttquery !== undefined,
  );
  const getContentWrapperStyles = (index: number) => {
    const wrapperStyles = [
      styles.contentWrapper,
      index === 0 ? styles.firstContentWrapper : undefined,
      index === columns.length - 1 ? styles.lastContentWrapper : undefined,
    ];

    return mergeClasses(...wrapperStyles);
  };

  const isMetricsColumns = (key: string) => key !== "Conversation";

  const onClickItem = useCallback((item: BingDiagnosisLoadedItem) => {
    openSidePane(() => <BingSBSView sbsKey={item.Key} jobIdForShare={jobId} />);
  }, []);

  const getColumnHeader = (column: string, index: number) => {
    if (index === 0) {
      return getContentWrapperStyles(index);
    }
    if (
      column === (resultStore.diagnosisSortBy?.column ?? columns?.[1].title)
    ) {
      return mergeClasses(
        getContentWrapperStyles(index),
        styles.sortBy,
        styles.metricsHeader,
      );
    }
    return mergeClasses(getContentWrapperStyles(index), styles.metricsHeader);
  };

  const renderOrderIcon = (column: string) => {
    if (column === "Conversation") {
      return <></>;
    }
    const isDescColumn =
      resultStore.diagnosisSortBy?.column === column &&
      resultStore.diagnosisSortBy?.direction === "desc";
    return (
      <Button
        size="small"
        appearance="transparent"
        onClick={(e) => {
          if (isDescColumn) {
            updateDiagnosisSortByOrder("asc", column);
          } else {
            updateDiagnosisSortByOrder("desc", column);
          }
          e.stopPropagation();
        }}
        icon={isDescColumn ? <ArrowDown16Regular /> : <ArrowUp16Regular />}
      ></Button>
    );
  };

  React.useEffect(() => {
    if (autoOpenSidePane) {
      if (uttquery === undefined) {
        return;
      }
      try {
        const item = props.rows.find((row) => row.Conversation === uttquery);
        if (item !== undefined) {
          setAutoOpenSidePane(false);
          onClickItem(item);
        }
      } catch {
        return;
      }
    }
  }, [uttquery, props.rows]);

  return (
    <div className={styles.tableContainer}>
      <div className={styles.table}>
        <div className={styles.headerRow}>
          {columns.map((column, index) => (
            <div
              className={
                index > 0
                  ? mergeClasses(styles.leftBorder, styles.th)
                  : styles.th
              }
              key={index}
              style={{
                flexGrow: column.weight,
                flexShrink: column.weight,
                flexBasis: 0,
                minWidth: column.minWidth ?? "0px",
                maxWidth: column.maxWidth,
              }}
            >
              <div
                className={getColumnHeader(column.title, index)}
                onClick={() => {
                  if (isMetricsColumns(column.title)) {
                    updateDiagnosisSortBy(column.title);
                  }
                }}
              >
                {column.title}
                {isMetricsColumns(column.title) && "(Delta)"}
                {renderOrderIcon(column.title)}
              </div>
            </div>
          ))}
        </div>

        <InfiniteScroll
          scrollThreshold={"50px"}
          dataLength={props.rows.length}
          next={props.loadMore}
          hasMore={props.hasMore}
          loader={
            <div className={styles.spinner}>
              <Spinner />
            </div>
          }
          scrollableTarget={"mainViewScrollContainer"}
        >
          {props.rows.map((row, rowIndex) => (
            <div
              key={rowIndex}
              className={styles.bodyRow}
              onClick={() => onClickItem(row)}
            >
              {columns.map((column, columnIndex) => (
                <div
                  className={
                    columnIndex > 0
                      ? mergeClasses(styles.leftBorder, styles.td)
                      : styles.td
                  }
                  key={columnIndex}
                  style={{
                    flexGrow: column.weight,
                    flexShrink: column.weight,
                    flexBasis: 0,
                    minWidth: column.minWidth ?? "0px",
                    maxWidth: column.maxWidth,
                  }}
                >
                  <div className={getContentWrapperStyles(columnIndex)}>
                    {column.render(row)}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
});
