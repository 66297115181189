import { array, object, str } from "sydneyeval-shared";

export const JobSBSExpResponse = array(
  object({
    JobId: str,
    experiments: str,
  }),
);

export type JobSBSExpResponse = ReturnType<typeof JobSBSExpResponse>;
