import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";

type Props = {
  isOpen: boolean;
  close: () => void;
  title: string;
  renderContent: () => JSX.Element;
  renderButtons: () => JSX.Element;
};

export const BasicDialog = (props: Props) => {
  const { isOpen, close, title, renderContent, renderButtons } = props;

  return (
    <Dialog open={isOpen} onOpenChange={close}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{renderContent()}</DialogContent>
          <DialogActions>{renderButtons()}</DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
