import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import { refreshProductSettings } from "../../actions/productSettingsActions";
import { updateToaster } from "../../mutators/updateProductSettings";
import { ProductButtons } from "./ProductButtons";
import { ProductContentView } from "./ProductContentView";
import { ProductDeleteDialog } from "./ProductDeleteDialog";
import { ProductReleaseDialog } from "./ProductReleaseDialog";
import { ProductTitleView } from "./ProductTitleView";

export const ProductView = observer(() => {
  const toaster = useToast();

  useEffect(() => {
    refreshProductSettings();
    updateToaster(toaster);
  }, []);

  return (
    <>
      <ProductTitleView />
      <ProductContentView />
      <ProductButtons />
      <ProductDeleteDialog />
      <ProductReleaseDialog />
    </>
  );
});
